export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 187.9,
    "out:Primary Energy": 135.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 64.02,
    "out:Total CO2/m2": 64.02,
    "out:Total CO2 (tons)": 142.44,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 59220,
    "out:Running Cost over RSP/MSEK": 8.336,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 3,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 188,
    "out:Energy use kWh/m2": 188,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 181.9,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 61.86,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 137.63,
    "out:Klimatpaverkan": -4.81,
    "out:Initial Cost/MSEK": 0.055625,
    "out:Running cost/(Apt SEK y)": 57270,
    "out:Running Cost over RSP/MSEK": 8.062,
    "out:LCP/MSEK": 0.219,
    "out:ROI% old": 70.52,
    "out:Payback discounted": 2,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 63,
    "out:Return/kSEK/y": 35,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 13350,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 12148,
    "out:DH kr savings": 12148,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 180.9,
    "out:Primary Energy": 133.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 61.5,
    "out:Total CO2/m2": 61.92,
    "out:Total CO2 (tons)": 137.77,
    "out:Klimatpaverkan": -4.67,
    "out:Initial Cost/MSEK": 0.11384,
    "out:Running cost/(Apt SEK y)": 56946,
    "out:Running Cost over RSP/MSEK": 8.016,
    "out:LCP/MSEK": 0.206,
    "out:ROI% old": 40.2,
    "out:Payback discounted": 3,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 36,
    "out:Return/kSEK/y": 41,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 15575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 14173,
    "out:DH kr savings": 14173,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 175.9,
    "out:Primary Energy": 129.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 59.7,
    "out:Total CO2/m2": 60.12,
    "out:Total CO2 (tons)": 133.76,
    "out:Klimatpaverkan": -8.68,
    "out:Initial Cost/MSEK": 0.169465,
    "out:Running cost/(Apt SEK y)": 55321,
    "out:Running Cost over RSP/MSEK": 7.787,
    "out:LCP/MSEK": 0.38,
    "out:ROI% old": 46.29,
    "out:Payback discounted": 3,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 41,
    "out:Return/kSEK/y": 70,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 26700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 24297,
    "out:DH kr savings": 24297,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 182.73,
    "out:Primary Energy": 126.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 33.02,
    "out:Total CO2/m2": 45.37,
    "out:Total CO2 (tons)": 100.96,
    "out:Klimatpaverkan": -41.48,
    "out:Initial Cost/MSEK": 0.462824,
    "out:Running cost/(Apt SEK y)": 57186,
    "out:Running Cost over RSP/MSEK": 8.058,
    "out:LCP/MSEK": -0.184,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 37,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 36599,
    "out:DH kr savings": 0,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 176.73,
    "out:Primary Energy": 122.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 43.21,
    "out:Total CO2 (tons)": 96.15,
    "out:Klimatpaverkan": -46.29,
    "out:Initial Cost/MSEK": 0.518449,
    "out:Running cost/(Apt SEK y)": 55237,
    "out:Running Cost over RSP/MSEK": 7.783,
    "out:LCP/MSEK": 0.035,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 72,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 13350,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 48747,
    "out:DH kr savings": 12148,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 175.73,
    "out:Primary Energy": 123.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 43.28,
    "out:Total CO2 (tons)": 96.29,
    "out:Klimatpaverkan": -46.15,
    "out:Initial Cost/MSEK": 0.576664,
    "out:Running cost/(Apt SEK y)": 54912,
    "out:Running Cost over RSP/MSEK": 7.737,
    "out:LCP/MSEK": 0.022,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 78,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 15575,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 50772,
    "out:DH kr savings": 14173,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 170.73,
    "out:Primary Energy": 120.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 41.48,
    "out:Total CO2 (tons)": 92.29,
    "out:Klimatpaverkan": -50.15,
    "out:Initial Cost/MSEK": 0.632289,
    "out:Running cost/(Apt SEK y)": 53288,
    "out:Running Cost over RSP/MSEK": 7.509,
    "out:LCP/MSEK": 0.195,
    "out:ROI% old": 18.7,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 107,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 26700,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 60896,
    "out:DH kr savings": 24297,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 187.9,
    "out:Total Energy Use Post PV": 181.88,
    "out:Primary Energy": 125,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -19.66,
    "out:Total CO2/m2": 5.05,
    "out:Total CO2 (tons)": 11.23,
    "out:Klimatpaverkan": -131.21,
    "out:Initial Cost/MSEK": 0.925648,
    "out:Running cost/(Apt SEK y)": 55380,
    "out:Running Cost over RSP/MSEK": 7.811,
    "out:LCP/MSEK": -0.4,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 69,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69109,
    "out:DH kr savings": 0,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 175.88,
    "out:Primary Energy": 121.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -21.82,
    "out:Total CO2/m2": 2.89,
    "out:Total CO2 (tons)": 6.43,
    "out:Klimatpaverkan": -136.01,
    "out:Initial Cost/MSEK": 0.981273,
    "out:Running cost/(Apt SEK y)": 53431,
    "out:Running Cost over RSP/MSEK": 7.536,
    "out:LCP/MSEK": -0.181,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 104,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 13350,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81258,
    "out:DH kr savings": 12148,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 174.88,
    "out:Primary Energy": 122.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -22.18,
    "out:Total CO2/m2": 2.95,
    "out:Total CO2 (tons)": 6.56,
    "out:Klimatpaverkan": -135.88,
    "out:Initial Cost/MSEK": 1.039488,
    "out:Running cost/(Apt SEK y)": 53106,
    "out:Running Cost over RSP/MSEK": 7.49,
    "out:LCP/MSEK": -0.193,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 15575,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83283,
    "out:DH kr savings": 14173,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 169.88,
    "out:Primary Energy": 118.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -23.98,
    "out:Total CO2/m2": 1.15,
    "out:Total CO2 (tons)": 2.56,
    "out:Klimatpaverkan": -139.88,
    "out:Initial Cost/MSEK": 1.095113,
    "out:Running cost/(Apt SEK y)": 51482,
    "out:Running Cost over RSP/MSEK": 7.261,
    "out:LCP/MSEK": -0.02,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 139,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 26700,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93406,
    "out:DH kr savings": 24297,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 178.9,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.78,
    "out:Total CO2/m2": 60.78,
    "out:Total CO2 (tons)": 135.23,
    "out:Klimatpaverkan": -7.21,
    "out:Initial Cost/MSEK": 0.047508,
    "out:Running cost/(Apt SEK y)": 56296,
    "out:Running Cost over RSP/MSEK": 7.924,
    "out:LCP/MSEK": 0.364,
    "out:ROI% old": 123.85,
    "out:Payback discounted": 1,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 111,
    "out:Return/kSEK/y": 53,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 38,
    "out:Payback Time": 2.631579,
    "out:DH kWh savings": 20025,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 18223,
    "out:DH kr savings": 18223,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 128.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 130.42,
    "out:Klimatpaverkan": -12.02,
    "out:Initial Cost/MSEK": 0.103133,
    "out:Running cost/(Apt SEK y)": 54346,
    "out:Running Cost over RSP/MSEK": 7.65,
    "out:LCP/MSEK": 0.583,
    "out:ROI% old": 95.09,
    "out:Payback discounted": 2,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 85,
    "out:Return/kSEK/y": 88,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 29,
    "out:Payback Time": 3.448276,
    "out:DH kWh savings": 33375,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 30371,
    "out:DH kr savings": 30371,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 173.9,
    "out:Primary Energy": 130.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 58.98,
    "out:Total CO2/m2": 59.4,
    "out:Total CO2 (tons)": 132.16,
    "out:Klimatpaverkan": -10.28,
    "out:Initial Cost/MSEK": 0.161348,
    "out:Running cost/(Apt SEK y)": 54671,
    "out:Running Cost over RSP/MSEK": 7.696,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 56.73,
    "out:Payback discounted": 2,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 51,
    "out:Return/kSEK/y": 82,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 31150,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 28346,
    "out:DH kr savings": 28346,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 126.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 57.24,
    "out:Total CO2 (tons)": 127.36,
    "out:Klimatpaverkan": -15.08,
    "out:Initial Cost/MSEK": 0.216973,
    "out:Running cost/(Apt SEK y)": 52722,
    "out:Running Cost over RSP/MSEK": 7.421,
    "out:LCP/MSEK": 0.698,
    "out:ROI% old": 60.26,
    "out:Payback discounted": 2,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 54,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40495,
    "out:DH kr savings": 40495,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 173.73,
    "out:Primary Energy": 123.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 42.13,
    "out:Total CO2 (tons)": 93.75,
    "out:Klimatpaverkan": -48.69,
    "out:Initial Cost/MSEK": 0.510332,
    "out:Running cost/(Apt SEK y)": 54262,
    "out:Running Cost over RSP/MSEK": 7.646,
    "out:LCP/MSEK": 0.18,
    "out:ROI% old": 19.33,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 89,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 20025,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54821,
    "out:DH kr savings": 18223,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 119.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 39.97,
    "out:Total CO2 (tons)": 88.94,
    "out:Klimatpaverkan": -53.5,
    "out:Initial Cost/MSEK": 0.565957,
    "out:Running cost/(Apt SEK y)": 52313,
    "out:Running Cost over RSP/MSEK": 7.371,
    "out:LCP/MSEK": 0.399,
    "out:ROI% old": 24.36,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 124,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 33375,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66970,
    "out:DH kr savings": 30371,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 168.73,
    "out:Primary Energy": 120.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 40.76,
    "out:Total CO2 (tons)": 90.68,
    "out:Klimatpaverkan": -51.76,
    "out:Initial Cost/MSEK": 0.624172,
    "out:Running cost/(Apt SEK y)": 52638,
    "out:Running Cost over RSP/MSEK": 7.417,
    "out:LCP/MSEK": 0.295,
    "out:ROI% old": 21.04,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 31150,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 64945,
    "out:DH kr savings": 28346,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 117.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 38.6,
    "out:Total CO2 (tons)": 85.88,
    "out:Klimatpaverkan": -56.56,
    "out:Initial Cost/MSEK": 0.679797,
    "out:Running cost/(Apt SEK y)": 50689,
    "out:Running Cost over RSP/MSEK": 7.142,
    "out:LCP/MSEK": 0.514,
    "out:ROI% old": 25.09,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 154,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77094,
    "out:DH kr savings": 40495,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 172.88,
    "out:Primary Energy": 121.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -22.9,
    "out:Total CO2/m2": 1.81,
    "out:Total CO2 (tons)": 4.02,
    "out:Klimatpaverkan": -138.42,
    "out:Initial Cost/MSEK": 0.973156,
    "out:Running cost/(Apt SEK y)": 52456,
    "out:Running Cost over RSP/MSEK": 7.399,
    "out:LCP/MSEK": -0.036,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 20025,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87332,
    "out:DH kr savings": 18223,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 117.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": -0.35,
    "out:Total CO2 (tons)": -0.78,
    "out:Klimatpaverkan": -143.22,
    "out:Initial Cost/MSEK": 1.028781,
    "out:Running cost/(Apt SEK y)": 50507,
    "out:Running Cost over RSP/MSEK": 7.124,
    "out:LCP/MSEK": 0.183,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 157,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 33375,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99481,
    "out:DH kr savings": 30371,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.16,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 167.88,
    "out:Primary Energy": 119.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -24.7,
    "out:Total CO2/m2": 0.43,
    "out:Total CO2 (tons)": 0.96,
    "out:Klimatpaverkan": -141.48,
    "out:Initial Cost/MSEK": 1.086996,
    "out:Running cost/(Apt SEK y)": 50832,
    "out:Running Cost over RSP/MSEK": 7.17,
    "out:LCP/MSEK": 0.079,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 31150,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97456,
    "out:DH kr savings": 28346,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 285165,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 115.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": -1.73,
    "out:Total CO2 (tons)": -3.85,
    "out:Klimatpaverkan": -146.29,
    "out:Initial Cost/MSEK": 1.142621,
    "out:Running cost/(Apt SEK y)": 48883,
    "out:Running Cost over RSP/MSEK": 6.895,
    "out:LCP/MSEK": 0.298,
    "out:ROI% old": 18.02,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109604,
    "out:DH kr savings": 40495,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 272810,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 41.31,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 130.31,
    "out:Primary Energy": 128.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 37.75,
    "out:Total CO2/m2": 42.16,
    "out:Total CO2 (tons)": 93.82,
    "out:Klimatpaverkan": -48.62,
    "out:Initial Cost/MSEK": 1.85275,
    "out:Running cost/(Apt SEK y)": 38012,
    "out:Running Cost over RSP/MSEK": 5.339,
    "out:LCP/MSEK": 1.145,
    "out:ROI% old": 23.11,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 3030.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 382,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -56532,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71949,
    "out:DH kr savings": 168054,
    "out:El kr savings": -96105,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 197680,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 76.91,
    "out:Etvv": 0,
    "out:Ef": 41.31,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.25,
    "out:Electricity (inc PV)": 41.23,
    "out:Total Energy Use Pre PV": 126.25,
    "out:Total Energy Use Post PV": 124.23,
    "out:Primary Energy": 124.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 35.59,
    "out:Total CO2/m2": 40,
    "out:Total CO2 (tons)": 89,
    "out:Klimatpaverkan": -53.44,
    "out:Initial Cost/MSEK": 1.908375,
    "out:Running cost/(Apt SEK y)": 36053,
    "out:Running Cost over RSP/MSEK": 5.063,
    "out:LCP/MSEK": 1.365,
    "out:ROI% old": 24.5,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 3027.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 417,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -56350,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 84407,
    "out:DH kr savings": 180203,
    "out:El kr savings": -95795,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 185660,
    "out:% savings (space heating)": 34.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 71.62,
    "out:Etvv": 0,
    "out:Ef": 41.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 40.87,
    "out:Total Energy Use Pre PV": 127.63,
    "out:Total Energy Use Post PV": 125.87,
    "out:Primary Energy": 125.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.83,
    "out:CO2 Operational/m2": 36.23,
    "out:Total CO2/m2": 41.06,
    "out:Total CO2 (tons)": 91.36,
    "out:Klimatpaverkan": -51.08,
    "out:Initial Cost/MSEK": 1.966625,
    "out:Running cost/(Apt SEK y)": 36572,
    "out:Running Cost over RSP/MSEK": 5.136,
    "out:LCP/MSEK": 1.233,
    "out:ROI% old": 23.24,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 2983.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -55567,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 81689,
    "out:DH kr savings": 176153,
    "out:El kr savings": -94464,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 188082,
    "out:% savings (space heating)": 34.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 74.59,
    "out:Etvv": 0,
    "out:Ef": 40.87,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 40.78,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 119.78,
    "out:Primary Energy": 121.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.83,
    "out:CO2 Operational/m2": 34.06,
    "out:Total CO2/m2": 38.89,
    "out:Total CO2 (tons)": 86.53,
    "out:Klimatpaverkan": -55.91,
    "out:Initial Cost/MSEK": 2.02225,
    "out:Running cost/(Apt SEK y)": 34610,
    "out:Running Cost over RSP/MSEK": 4.86,
    "out:LCP/MSEK": 1.454,
    "out:ROI% old": 24.56,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2979.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 206925,
    "out:EL kWh savings": -55365,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94182,
    "out:DH kr savings": 188302,
    "out:El kr savings": -94120,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 176155,
    "out:% savings (space heating)": 38.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 69.34,
    "out:Etvv": 0,
    "out:Ef": 40.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 31.98,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 120.98,
    "out:Primary Energy": 111.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 28.47,
    "out:Total CO2/m2": 45.24,
    "out:Total CO2 (tons)": 100.65,
    "out:Klimatpaverkan": -41.79,
    "out:Initial Cost/MSEK": 2.315625,
    "out:Running cost/(Apt SEK y)": 35750,
    "out:Running Cost over RSP/MSEK": 5.029,
    "out:LCP/MSEK": 0.992,
    "out:ROI% old": 20.4,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 2388.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 422,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3663.01,
    "out:PV (% sold (El))": 14.73,
    "out:PV (kWh self-consumed)": 21206.13,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -35785,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111891,
    "out:DH kr savings": 168054,
    "out:El kr savings": -60834,
    "out:El kr sold": 4670,
    "out:El kr saved": 36050,
    "out:El kr return": 40721,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 197680,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 76.91,
    "out:Etvv": 0,
    "out:Ef": 31.98,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.25,
    "out:Electricity (inc PV)": 31.91,
    "out:Total Energy Use Pre PV": 126.25,
    "out:Total Energy Use Post PV": 114.91,
    "out:Primary Energy": 107.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 26.28,
    "out:Total CO2/m2": 43.05,
    "out:Total CO2 (tons)": 95.78,
    "out:Klimatpaverkan": -46.66,
    "out:Initial Cost/MSEK": 2.37125,
    "out:Running cost/(Apt SEK y)": 33791,
    "out:Running Cost over RSP/MSEK": 4.753,
    "out:LCP/MSEK": 1.212,
    "out:ROI% old": 21.59,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2385.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3673.18,
    "out:PV (% sold (El))": 14.77,
    "out:PV (kWh self-consumed)": 21195.96,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -35622,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124329,
    "out:DH kr savings": 180203,
    "out:El kr savings": -60557,
    "out:El kr sold": 4683,
    "out:El kr saved": 36033,
    "out:El kr return": 40716,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 185660,
    "out:% savings (space heating)": 34.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 71.62,
    "out:Etvv": 0,
    "out:Ef": 31.91,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 31.6,
    "out:Total Energy Use Pre PV": 127.63,
    "out:Total Energy Use Post PV": 116.6,
    "out:Primary Energy": 109.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.19,
    "out:CO2 Operational/m2": 26.61,
    "out:Total CO2/m2": 43.79,
    "out:Total CO2 (tons)": 97.44,
    "out:Klimatpaverkan": -45,
    "out:Initial Cost/MSEK": 2.429375,
    "out:Running cost/(Apt SEK y)": 34313,
    "out:Running Cost over RSP/MSEK": 4.827,
    "out:LCP/MSEK": 1.08,
    "out:ROI% old": 20.64,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 2346,
    "out:Return %": 18,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3814.23,
    "out:PV (% sold (El))": 15.34,
    "out:PV (kWh self-consumed)": 21054.91,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -34924,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121646,
    "out:DH kr savings": 176153,
    "out:El kr savings": -59370,
    "out:El kr sold": 4863,
    "out:El kr saved": 35793,
    "out:El kr return": 40656,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 188082,
    "out:% savings (space heating)": 34.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 74.59,
    "out:Etvv": 0,
    "out:Ef": 31.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 31.51,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 110.51,
    "out:Primary Energy": 105.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.19,
    "out:CO2 Operational/m2": 24.41,
    "out:Total CO2/m2": 41.6,
    "out:Total CO2 (tons)": 92.55,
    "out:Klimatpaverkan": -49.89,
    "out:Initial Cost/MSEK": 2.485,
    "out:Running cost/(Apt SEK y)": 32352,
    "out:Running Cost over RSP/MSEK": 4.551,
    "out:LCP/MSEK": 1.301,
    "out:ROI% old": 21.76,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2342.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3827.53,
    "out:PV (% sold (El))": 15.39,
    "out:PV (kWh self-consumed)": 21041.61,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 206925,
    "out:EL kWh savings": -34743,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134118,
    "out:DH kr savings": 188302,
    "out:El kr savings": -59064,
    "out:El kr sold": 4880,
    "out:El kr saved": 35771,
    "out:El kr return": 40651,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 176155,
    "out:% savings (space heating)": 38.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 69.34,
    "out:Etvv": 0,
    "out:Ef": 31.51,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 28.91,
    "out:Total Energy Use Pre PV": 132.3,
    "out:Total Energy Use Post PV": 117.91,
    "out:Primary Energy": 105.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": -12.01,
    "out:Total CO2/m2": 17.11,
    "out:Total CO2 (tons)": 38.07,
    "out:Klimatpaverkan": -104.37,
    "out:Initial Cost/MSEK": 2.778375,
    "out:Running cost/(Apt SEK y)": 33817,
    "out:Running Cost over RSP/MSEK": 4.764,
    "out:LCP/MSEK": 0.794,
    "out:ROI% old": 18.37,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 2156.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21284.36,
    "out:PV (% sold (El))": 42.79,
    "out:PV (kWh self-consumed)": 28453.92,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -28949,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145979,
    "out:DH kr savings": 168054,
    "out:El kr savings": -49213,
    "out:El kr sold": 27138,
    "out:El kr saved": 48372,
    "out:El kr return": 75509,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 197680,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 76.91,
    "out:Etvv": 0,
    "out:Ef": 28.91,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.25,
    "out:Electricity (inc PV)": 28.85,
    "out:Total Energy Use Pre PV": 126.25,
    "out:Total Energy Use Post PV": 111.85,
    "out:Primary Energy": 102.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": -14.22,
    "out:Total CO2/m2": 14.9,
    "out:Total CO2 (tons)": 33.15,
    "out:Klimatpaverkan": -109.29,
    "out:Initial Cost/MSEK": 2.834,
    "out:Running cost/(Apt SEK y)": 31859,
    "out:Running Cost over RSP/MSEK": 4.488,
    "out:LCP/MSEK": 1.014,
    "out:ROI% old": 19.4,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2154.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21304.04,
    "out:PV (% sold (El))": 42.83,
    "out:PV (kWh self-consumed)": 28434.24,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -28803,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158400,
    "out:DH kr savings": 180203,
    "out:El kr savings": -48965,
    "out:El kr sold": 27163,
    "out:El kr saved": 48338,
    "out:El kr return": 75501,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 185660,
    "out:% savings (space heating)": 34.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 71.62,
    "out:Etvv": 0,
    "out:Ef": 28.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 28.56,
    "out:Total Energy Use Pre PV": 127.63,
    "out:Total Energy Use Post PV": 113.56,
    "out:Primary Energy": 103.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": -14.19,
    "out:Total CO2/m2": 15.35,
    "out:Total CO2 (tons)": 34.15,
    "out:Klimatpaverkan": -108.29,
    "out:Initial Cost/MSEK": 2.89225,
    "out:Running cost/(Apt SEK y)": 32384,
    "out:Running Cost over RSP/MSEK": 4.563,
    "out:LCP/MSEK": 0.882,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 2118.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21575.2,
    "out:PV (% sold (El))": 43.38,
    "out:PV (kWh self-consumed)": 28163.08,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -28176,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155762,
    "out:DH kr savings": 176153,
    "out:El kr savings": -47900,
    "out:El kr sold": 27508,
    "out:El kr saved": 47877,
    "out:El kr return": 75386,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 188082,
    "out:% savings (space heating)": 34.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 74.59,
    "out:Etvv": 0,
    "out:Ef": 28.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 28.49,
    "out:Total Energy Use Pre PV": 121.57,
    "out:Total Energy Use Post PV": 107.49,
    "out:Primary Energy": 99.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": -16.41,
    "out:Total CO2/m2": 13.13,
    "out:Total CO2 (tons)": 29.2,
    "out:Klimatpaverkan": -113.24,
    "out:Initial Cost/MSEK": 2.947875,
    "out:Running cost/(Apt SEK y)": 30423,
    "out:Running Cost over RSP/MSEK": 4.286,
    "out:LCP/MSEK": 1.102,
    "out:ROI% old": 19.63,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2115.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21600.66,
    "out:PV (% sold (El))": 43.43,
    "out:PV (kWh self-consumed)": 28137.62,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 206925,
    "out:EL kWh savings": -28015,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168218,
    "out:DH kr savings": 188302,
    "out:El kr savings": -47625,
    "out:El kr sold": 27541,
    "out:El kr saved": 47834,
    "out:El kr return": 75375,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 176155,
    "out:% savings (space heating)": 38.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 69.34,
    "out:Etvv": 0,
    "out:Ef": 28.49,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 40.73,
    "out:Total Energy Use Pre PV": 125.39,
    "out:Total Energy Use Post PV": 123.73,
    "out:Primary Energy": 124.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 35.48,
    "out:Total CO2/m2": 39.89,
    "out:Total CO2 (tons)": 88.75,
    "out:Klimatpaverkan": -53.69,
    "out:Initial Cost/MSEK": 1.90025,
    "out:Running cost/(Apt SEK y)": 35873,
    "out:Running Cost over RSP/MSEK": 5.038,
    "out:LCP/MSEK": 1.398,
    "out:ROI% old": 24.8,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2967.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -55239,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86296,
    "out:DH kr savings": 180203,
    "out:El kr savings": -93907,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184943,
    "out:% savings (space heating)": 35.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 73.82,
    "out:Etvv": 0,
    "out:Ef": 40.73,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.33,
    "out:Electricity (inc PV)": 40.63,
    "out:Total Energy Use Pre PV": 120.33,
    "out:Total Energy Use Post PV": 118.63,
    "out:Primary Energy": 121.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.41,
    "out:CO2 Operational/m2": 33.67,
    "out:Total CO2/m2": 38.08,
    "out:Total CO2 (tons)": 84.72,
    "out:Klimatpaverkan": -57.72,
    "out:Initial Cost/MSEK": 1.955875,
    "out:Running cost/(Apt SEK y)": 34236,
    "out:Running Cost over RSP/MSEK": 4.807,
    "out:LCP/MSEK": 1.573,
    "out:ROI% old": 25.77,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2963.3,
    "out:Return %": 23,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 209150,
    "out:EL kWh savings": -55031,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 96773,
    "out:DH kr savings": 190326,
    "out:El kr savings": -93553,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173046,
    "out:% savings (space heating)": 39.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 68.58,
    "out:Etvv": 0,
    "out:Ef": 40.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.82,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 121.82,
    "out:Total Energy Use Post PV": 120.37,
    "out:Primary Energy": 123.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.83,
    "out:CO2 Operational/m2": 34.32,
    "out:Total CO2/m2": 39.15,
    "out:Total CO2 (tons)": 87.12,
    "out:Klimatpaverkan": -55.32,
    "out:Initial Cost/MSEK": 2.014125,
    "out:Running cost/(Apt SEK y)": 34779,
    "out:Running Cost over RSP/MSEK": 4.884,
    "out:LCP/MSEK": 1.438,
    "out:ROI% old": 24.49,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 2927.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 204700,
    "out:EL kWh savings": -54444,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93722,
    "out:DH kr savings": 186277,
    "out:El kr savings": -92555,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 177517,
    "out:% savings (space heating)": 37.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 72,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.75,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 115.75,
    "out:Total Energy Use Post PV": 114.27,
    "out:Primary Energy": 119.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.83,
    "out:CO2 Operational/m2": 32.15,
    "out:Total CO2/m2": 36.98,
    "out:Total CO2 (tons)": 82.29,
    "out:Klimatpaverkan": -60.15,
    "out:Initial Cost/MSEK": 2.06975,
    "out:Running cost/(Apt SEK y)": 32814,
    "out:Running Cost over RSP/MSEK": 4.607,
    "out:LCP/MSEK": 1.659,
    "out:ROI% old": 25.74,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2922.6,
    "out:Return %": 23,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218050,
    "out:EL kWh savings": -54222,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106247,
    "out:DH kr savings": 198425,
    "out:El kr savings": -92178,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 165695,
    "out:% savings (space heating)": 41.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 66.78,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 31.46,
    "out:Total Energy Use Pre PV": 125.39,
    "out:Total Energy Use Post PV": 114.46,
    "out:Primary Energy": 108.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 25.74,
    "out:Total CO2/m2": 42.5,
    "out:Total CO2 (tons)": 94.57,
    "out:Klimatpaverkan": -47.87,
    "out:Initial Cost/MSEK": 2.363125,
    "out:Running cost/(Apt SEK y)": 33616,
    "out:Running Cost over RSP/MSEK": 4.729,
    "out:LCP/MSEK": 1.244,
    "out:ROI% old": 21.81,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2331.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3867.18,
    "out:PV (% sold (El))": 15.55,
    "out:PV (kWh self-consumed)": 21001.96,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -34632,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126259,
    "out:DH kr savings": 180203,
    "out:El kr savings": -58874,
    "out:El kr sold": 4931,
    "out:El kr saved": 35703,
    "out:El kr return": 40634,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184943,
    "out:% savings (space heating)": 35.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 73.82,
    "out:Etvv": 0,
    "out:Ef": 31.46,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.33,
    "out:Electricity (inc PV)": 31.38,
    "out:Total Energy Use Pre PV": 120.33,
    "out:Total Energy Use Post PV": 109.38,
    "out:Primary Energy": 104.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 23.9,
    "out:Total CO2/m2": 40.66,
    "out:Total CO2 (tons)": 90.48,
    "out:Klimatpaverkan": -51.96,
    "out:Initial Cost/MSEK": 2.41875,
    "out:Running cost/(Apt SEK y)": 31979,
    "out:Running Cost over RSP/MSEK": 4.498,
    "out:LCP/MSEK": 1.419,
    "out:ROI% old": 22.67,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2327.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3881.55,
    "out:PV (% sold (El))": 15.61,
    "out:PV (kWh self-consumed)": 20987.59,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209150,
    "out:EL kWh savings": -34446,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136717,
    "out:DH kr savings": 190326,
    "out:El kr savings": -58559,
    "out:El kr sold": 4949,
    "out:El kr saved": 35679,
    "out:El kr return": 40628,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173046,
    "out:% savings (space heating)": 39.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 68.58,
    "out:Etvv": 0,
    "out:Ef": 31.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.82,
    "out:Electricity (inc PV)": 31.15,
    "out:Total Energy Use Pre PV": 121.82,
    "out:Total Energy Use Post PV": 111.15,
    "out:Primary Energy": 106.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.19,
    "out:CO2 Operational/m2": 24.29,
    "out:Total CO2/m2": 41.48,
    "out:Total CO2 (tons)": 92.29,
    "out:Klimatpaverkan": -50.15,
    "out:Initial Cost/MSEK": 2.476875,
    "out:Running cost/(Apt SEK y)": 32524,
    "out:Running Cost over RSP/MSEK": 4.575,
    "out:LCP/MSEK": 1.284,
    "out:ROI% old": 21.69,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 2295.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 481,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3999.33,
    "out:PV (% sold (El))": 16.08,
    "out:PV (kWh self-consumed)": 20869.81,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 204700,
    "out:EL kWh savings": -33924,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133705,
    "out:DH kr savings": 186277,
    "out:El kr savings": -57671,
    "out:El kr sold": 5099,
    "out:El kr saved": 35479,
    "out:El kr return": 40578,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 177517,
    "out:% savings (space heating)": 37.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 72,
    "out:Etvv": 0,
    "out:Ef": 31.15,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.75,
    "out:Electricity (inc PV)": 31.06,
    "out:Total Energy Use Pre PV": 115.75,
    "out:Total Energy Use Post PV": 105.06,
    "out:Primary Energy": 102.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.19,
    "out:CO2 Operational/m2": 22.08,
    "out:Total CO2/m2": 39.27,
    "out:Total CO2 (tons)": 87.37,
    "out:Klimatpaverkan": -55.07,
    "out:Initial Cost/MSEK": 2.5325,
    "out:Running cost/(Apt SEK y)": 30560,
    "out:Running Cost over RSP/MSEK": 4.299,
    "out:LCP/MSEK": 1.505,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2290.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 516,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4016.32,
    "out:PV (% sold (El))": 16.15,
    "out:PV (kWh self-consumed)": 20852.82,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218050,
    "out:EL kWh savings": -33727,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146210,
    "out:DH kr savings": 198425,
    "out:El kr savings": -57336,
    "out:El kr sold": 5121,
    "out:El kr saved": 35450,
    "out:El kr return": 40571,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 165695,
    "out:% savings (space heating)": 41.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 66.78,
    "out:Etvv": 0,
    "out:Ef": 31.06,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 28.45,
    "out:Total Energy Use Pre PV": 125.39,
    "out:Total Energy Use Post PV": 111.45,
    "out:Primary Energy": 102.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": -15.17,
    "out:Total CO2/m2": 13.95,
    "out:Total CO2 (tons)": 31.04,
    "out:Klimatpaverkan": -111.4,
    "out:Initial Cost/MSEK": 2.825875,
    "out:Running cost/(Apt SEK y)": 31688,
    "out:Running Cost over RSP/MSEK": 4.465,
    "out:LCP/MSEK": 1.046,
    "out:ROI% old": 19.57,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2105.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21676.31,
    "out:PV (% sold (El))": 43.58,
    "out:PV (kWh self-consumed)": 28061.97,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 198025,
    "out:EL kWh savings": -27914,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160385,
    "out:DH kr savings": 180203,
    "out:El kr savings": -47455,
    "out:El kr sold": 27637,
    "out:El kr saved": 47705,
    "out:El kr return": 75343,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184943,
    "out:% savings (space heating)": 35.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 73.82,
    "out:Etvv": 0,
    "out:Ef": 28.45,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.33,
    "out:Electricity (inc PV)": 28.37,
    "out:Total Energy Use Pre PV": 120.33,
    "out:Total Energy Use Post PV": 106.37,
    "out:Primary Energy": 99.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": -17.04,
    "out:Total CO2/m2": 12.08,
    "out:Total CO2 (tons)": 26.88,
    "out:Klimatpaverkan": -115.56,
    "out:Initial Cost/MSEK": 2.8815,
    "out:Running cost/(Apt SEK y)": 30051,
    "out:Running Cost over RSP/MSEK": 4.234,
    "out:LCP/MSEK": 1.221,
    "out:ROI% old": 20.34,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2101.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21703.62,
    "out:PV (% sold (El))": 43.64,
    "out:PV (kWh self-consumed)": 28034.66,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209150,
    "out:EL kWh savings": -27748,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170827,
    "out:DH kr savings": 190326,
    "out:El kr savings": -47172,
    "out:El kr sold": 27672,
    "out:El kr saved": 47659,
    "out:El kr return": 75331,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173046,
    "out:% savings (space heating)": 39.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 68.58,
    "out:Etvv": 0,
    "out:Ef": 28.37,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.82,
    "out:Electricity (inc PV)": 28.16,
    "out:Total Energy Use Pre PV": 121.82,
    "out:Total Energy Use Post PV": 108.16,
    "out:Primary Energy": 101.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": -16.89,
    "out:Total CO2/m2": 12.65,
    "out:Total CO2 (tons)": 28.15,
    "out:Klimatpaverkan": -114.29,
    "out:Initial Cost/MSEK": 2.93975,
    "out:Running cost/(Apt SEK y)": 30599,
    "out:Running Cost over RSP/MSEK": 4.311,
    "out:LCP/MSEK": 1.085,
    "out:ROI% old": 19.56,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 2073,
    "out:Return %": 18,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21926.46,
    "out:PV (% sold (El))": 44.08,
    "out:PV (kWh self-consumed)": 27811.82,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 204700,
    "out:EL kWh savings": -27279,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167858,
    "out:DH kr savings": 186277,
    "out:El kr savings": -46375,
    "out:El kr sold": 27956,
    "out:El kr saved": 47280,
    "out:El kr return": 75236,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 177517,
    "out:% savings (space heating)": 37.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 72,
    "out:Etvv": 0,
    "out:Ef": 28.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.75,
    "out:Electricity (inc PV)": 28.08,
    "out:Total Energy Use Pre PV": 115.75,
    "out:Total Energy Use Post PV": 102.08,
    "out:Primary Energy": 97.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": -19.13,
    "out:Total CO2/m2": 10.41,
    "out:Total CO2 (tons)": 23.17,
    "out:Klimatpaverkan": -119.27,
    "out:Initial Cost/MSEK": 2.995375,
    "out:Running cost/(Apt SEK y)": 28635,
    "out:Running Cost over RSP/MSEK": 4.035,
    "out:LCP/MSEK": 1.306,
    "out:ROI% old": 20.51,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2068.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21958.48,
    "out:PV (% sold (El))": 44.15,
    "out:PV (kWh self-consumed)": 27779.8,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218050,
    "out:EL kWh savings": -27102,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180349,
    "out:DH kr savings": 198425,
    "out:El kr savings": -46074,
    "out:El kr sold": 27997,
    "out:El kr saved": 47226,
    "out:El kr return": 75223,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 165695,
    "out:% savings (space heating)": 41.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 66.78,
    "out:Etvv": 0,
    "out:Ef": 28.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.26,
    "out:Electricity (inc PV)": 58.67,
    "out:Total Energy Use Pre PV": 88.26,
    "out:Total Energy Use Post PV": 84.67,
    "out:Primary Energy": 119.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.06,
    "out:CO2 Operational/m2": 17.58,
    "out:Total CO2/m2": 22.64,
    "out:Total CO2 (tons)": 50.37,
    "out:Klimatpaverkan": -92.07,
    "out:Initial Cost/MSEK": 3.17875,
    "out:Running cost/(Apt SEK y)": 21530,
    "out:Running Cost over RSP/MSEK": 3.01,
    "out:LCP/MSEK": 2.147,
    "out:ROI% old": 23.94,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 4358,
    "out:Return %": 21,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 324850,
    "out:EL kWh savings": -95167,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133829,
    "out:DH kr savings": 295613,
    "out:El kr savings": -161784,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 58456,
    "out:% savings (space heating)": 79.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 19.61,
    "out:Etvv": 0,
    "out:Ef": 58.67,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.78,
    "out:Electricity (inc PV)": 58.07,
    "out:Total Energy Use Pre PV": 83.78,
    "out:Total Energy Use Post PV": 80.07,
    "out:Primary Energy": 115.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.06,
    "out:CO2 Operational/m2": 16.07,
    "out:Total CO2/m2": 21.13,
    "out:Total CO2 (tons)": 47.02,
    "out:Klimatpaverkan": -95.42,
    "out:Initial Cost/MSEK": 3.234375,
    "out:Running cost/(Apt SEK y)": 20129,
    "out:Running Cost over RSP/MSEK": 2.813,
    "out:LCP/MSEK": 2.289,
    "out:ROI% old": 24.39,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 4324.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 333750,
    "out:EL kWh savings": -93838,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144188,
    "out:DH kr savings": 303712,
    "out:El kr savings": -159524,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 49634,
    "out:% savings (space heating)": 82.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.02,
    "out:Etvv": 0,
    "out:Ef": 58.07,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.02,
    "out:Electricity (inc PV)": 57.91,
    "out:Total Energy Use Pre PV": 85.02,
    "out:Total Energy Use Post PV": 81.91,
    "out:Primary Energy": 117.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.48,
    "out:CO2 Operational/m2": 16.7,
    "out:Total CO2/m2": 22.18,
    "out:Total CO2 (tons)": 49.34,
    "out:Klimatpaverkan": -93.1,
    "out:Initial Cost/MSEK": 3.292625,
    "out:Running cost/(Apt SEK y)": 20620,
    "out:Running Cost over RSP/MSEK": 2.883,
    "out:LCP/MSEK": 2.161,
    "out:ROI% old": 23.66,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 4271.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329300,
    "out:EL kWh savings": -93480,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140746,
    "out:DH kr savings": 299663,
    "out:El kr savings": -158917,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 53009,
    "out:% savings (space heating)": 81.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 18.36,
    "out:Etvv": 0,
    "out:Ef": 57.91,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.5,
    "out:Electricity (inc PV)": 57.29,
    "out:Total Energy Use Pre PV": 80.5,
    "out:Total Energy Use Post PV": 77.29,
    "out:Primary Energy": 113.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.48,
    "out:CO2 Operational/m2": 15.19,
    "out:Total CO2/m2": 20.67,
    "out:Total CO2 (tons)": 45.99,
    "out:Klimatpaverkan": -96.45,
    "out:Initial Cost/MSEK": 3.34825,
    "out:Running cost/(Apt SEK y)": 19211,
    "out:Running Cost over RSP/MSEK": 2.684,
    "out:LCP/MSEK": 2.304,
    "out:ROI% old": 24.11,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 4235.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 720,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 338200,
    "out:EL kWh savings": -92086,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151215,
    "out:DH kr savings": 307762,
    "out:El kr savings": -156547,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 44470,
    "out:% savings (space heating)": 84.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.87,
    "out:Etvv": 0,
    "out:Ef": 57.29,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.26,
    "out:Electricity (inc PV)": 48.02,
    "out:Total Energy Use Pre PV": 88.26,
    "out:Total Energy Use Post PV": 74.02,
    "out:Primary Energy": 100.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 14.53,
    "out:Total CO2/m2": 31.95,
    "out:Total CO2 (tons)": 71.08,
    "out:Klimatpaverkan": -71.36,
    "out:Initial Cost/MSEK": 3.641625,
    "out:Running cost/(Apt SEK y)": 19200,
    "out:Running Cost over RSP/MSEK": 2.691,
    "out:LCP/MSEK": 2.003,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3640.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 720,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 799.7,
    "out:PV (% sold (El))": 3.22,
    "out:PV (kWh self-consumed)": 24069.44,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 324850,
    "out:EL kWh savings": -71457,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175156,
    "out:DH kr savings": 295613,
    "out:El kr savings": -121477,
    "out:El kr sold": 1020,
    "out:El kr saved": 40918,
    "out:El kr return": 41938,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 58456,
    "out:% savings (space heating)": 79.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 19.61,
    "out:Etvv": 0,
    "out:Ef": 48.02,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.78,
    "out:Electricity (inc PV)": 47.45,
    "out:Total Energy Use Pre PV": 83.78,
    "out:Total Energy Use Post PV": 69.45,
    "out:Primary Energy": 96.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 12.94,
    "out:Total CO2/m2": 30.35,
    "out:Total CO2 (tons)": 67.53,
    "out:Klimatpaverkan": -74.91,
    "out:Initial Cost/MSEK": 3.69725,
    "out:Running cost/(Apt SEK y)": 17800,
    "out:Running Cost over RSP/MSEK": 2.494,
    "out:LCP/MSEK": 2.145,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3608.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 843.22,
    "out:PV (% sold (El))": 3.39,
    "out:PV (kWh self-consumed)": 24025.92,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 333750,
    "out:EL kWh savings": -70194,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185457,
    "out:DH kr savings": 303712,
    "out:El kr savings": -119330,
    "out:El kr sold": 1075,
    "out:El kr saved": 40844,
    "out:El kr return": 41919,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 49634,
    "out:% savings (space heating)": 82.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.02,
    "out:Etvv": 0,
    "out:Ef": 47.45,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.02,
    "out:Electricity (inc PV)": 47.3,
    "out:Total Energy Use Pre PV": 85.02,
    "out:Total Energy Use Post PV": 71.3,
    "out:Primary Energy": 97.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.84,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 31.24,
    "out:Total CO2 (tons)": 69.52,
    "out:Klimatpaverkan": -72.92,
    "out:Initial Cost/MSEK": 3.755375,
    "out:Running cost/(Apt SEK y)": 18293,
    "out:Running Cost over RSP/MSEK": 2.564,
    "out:LCP/MSEK": 2.017,
    "out:ROI% old": 21.96,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3557,
    "out:Return %": 20,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 914.34,
    "out:PV (% sold (El))": 3.68,
    "out:PV (kWh self-consumed)": 23954.8,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329300,
    "out:EL kWh savings": -69855,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182075,
    "out:DH kr savings": 299663,
    "out:El kr savings": -118754,
    "out:El kr sold": 1166,
    "out:El kr saved": 40723,
    "out:El kr return": 41889,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 53009,
    "out:% savings (space heating)": 81.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 18.36,
    "out:Etvv": 0,
    "out:Ef": 47.3,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.5,
    "out:Electricity (inc PV)": 46.7,
    "out:Total Energy Use Pre PV": 80.5,
    "out:Total Energy Use Post PV": 66.7,
    "out:Primary Energy": 94.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.84,
    "out:CO2 Operational/m2": 11.79,
    "out:Total CO2/m2": 29.63,
    "out:Total CO2 (tons)": 65.92,
    "out:Klimatpaverkan": -76.52,
    "out:Initial Cost/MSEK": 3.811,
    "out:Running cost/(Apt SEK y)": 16885,
    "out:Running Cost over RSP/MSEK": 2.366,
    "out:LCP/MSEK": 2.159,
    "out:ROI% old": 22.38,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3521.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 965.61,
    "out:PV (% sold (El))": 3.88,
    "out:PV (kWh self-consumed)": 23903.53,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 338200,
    "out:EL kWh savings": -68534,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192485,
    "out:DH kr savings": 307762,
    "out:El kr savings": -116508,
    "out:El kr sold": 1231,
    "out:El kr saved": 40636,
    "out:El kr return": 41867,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 44470,
    "out:% savings (space heating)": 84.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.87,
    "out:Etvv": 0,
    "out:Ef": 46.7,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.26,
    "out:Electricity (inc PV)": 43.19,
    "out:Total Energy Use Pre PV": 88.26,
    "out:Total Energy Use Post PV": 69.19,
    "out:Primary Energy": 91.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.77,
    "out:CO2 Operational/m2": -15.95,
    "out:Total CO2/m2": 13.81,
    "out:Total CO2 (tons)": 30.74,
    "out:Klimatpaverkan": -111.7,
    "out:Initial Cost/MSEK": 4.104375,
    "out:Running cost/(Apt SEK y)": 17164,
    "out:Running Cost over RSP/MSEK": 2.412,
    "out:LCP/MSEK": 1.82,
    "out:ROI% old": 20.62,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 26,
    "out:El bought/kWh/m2": 3267.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14053.3,
    "out:PV (% sold (El))": 28.25,
    "out:PV (kWh self-consumed)": 35684.98,
    "out:PV (ratio sold/self-consumed)": 0.39,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 324850,
    "out:EL kWh savings": -60719,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210309,
    "out:DH kr savings": 295613,
    "out:El kr savings": -103223,
    "out:El kr sold": 17918,
    "out:El kr saved": 60664,
    "out:El kr return": 78582,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 58456,
    "out:% savings (space heating)": 79.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 19.61,
    "out:Etvv": 0,
    "out:Ef": 43.19,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.78,
    "out:Electricity (inc PV)": 42.69,
    "out:Total Energy Use Pre PV": 83.78,
    "out:Total Energy Use Post PV": 64.69,
    "out:Primary Energy": 88.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.77,
    "out:CO2 Operational/m2": -17.82,
    "out:Total CO2/m2": 11.95,
    "out:Total CO2 (tons)": 26.58,
    "out:Klimatpaverkan": -115.86,
    "out:Initial Cost/MSEK": 4.16,
    "out:Running cost/(Apt SEK y)": 15767,
    "out:Running Cost over RSP/MSEK": 2.216,
    "out:LCP/MSEK": 1.961,
    "out:ROI% old": 21.02,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3238.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14215.74,
    "out:PV (% sold (El))": 28.58,
    "out:PV (kWh self-consumed)": 35522.54,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 333750,
    "out:EL kWh savings": -59602,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220514,
    "out:DH kr savings": 303712,
    "out:El kr savings": -101324,
    "out:El kr sold": 18125,
    "out:El kr saved": 60388,
    "out:El kr return": 78513,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 49634,
    "out:% savings (space heating)": 82.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.02,
    "out:Etvv": 0,
    "out:Ef": 42.69,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.02,
    "out:Electricity (inc PV)": 42.55,
    "out:Total Energy Use Pre PV": 85.02,
    "out:Total Energy Use Post PV": 66.55,
    "out:Primary Energy": 89.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.19,
    "out:CO2 Operational/m2": -17.77,
    "out:Total CO2/m2": 12.42,
    "out:Total CO2 (tons)": 27.63,
    "out:Klimatpaverkan": -114.81,
    "out:Initial Cost/MSEK": 4.21825,
    "out:Running cost/(Apt SEK y)": 16264,
    "out:Running Cost over RSP/MSEK": 2.286,
    "out:LCP/MSEK": 1.832,
    "out:ROI% old": 20.49,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3193.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 773,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14472.21,
    "out:PV (% sold (El))": 29.1,
    "out:PV (kWh self-consumed)": 35266.07,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 329300,
    "out:EL kWh savings": -59302,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217301,
    "out:DH kr savings": 299663,
    "out:El kr savings": -100814,
    "out:El kr sold": 18452,
    "out:El kr saved": 59952,
    "out:El kr return": 78404,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 53009,
    "out:% savings (space heating)": 81.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 18.36,
    "out:Etvv": 0,
    "out:Ef": 42.55,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.5,
    "out:Electricity (inc PV)": 42.03,
    "out:Total Energy Use Pre PV": 80.5,
    "out:Total Energy Use Post PV": 62.03,
    "out:Primary Energy": 86.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.19,
    "out:CO2 Operational/m2": -19.68,
    "out:Total CO2/m2": 10.51,
    "out:Total CO2 (tons)": 23.38,
    "out:Klimatpaverkan": -119.06,
    "out:Initial Cost/MSEK": 4.273875,
    "out:Running cost/(Apt SEK y)": 14860,
    "out:Running Cost over RSP/MSEK": 2.088,
    "out:LCP/MSEK": 1.974,
    "out:ROI% old": 20.88,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3162.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14650.87,
    "out:PV (% sold (El))": 29.46,
    "out:PV (kWh self-consumed)": 35087.41,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 338200,
    "out:EL kWh savings": -58133,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227616,
    "out:DH kr savings": 307762,
    "out:El kr savings": -98826,
    "out:El kr sold": 18680,
    "out:El kr saved": 59649,
    "out:El kr return": 78328,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 44470,
    "out:% savings (space heating)": 84.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.87,
    "out:Etvv": 0,
    "out:Ef": 42.03,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.6,
    "out:Electricity (inc PV)": 57.66,
    "out:Total Energy Use Pre PV": 83.6,
    "out:Total Energy Use Post PV": 80.66,
    "out:Primary Energy": 116.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.06,
    "out:CO2 Operational/m2": 16.28,
    "out:Total CO2/m2": 21.34,
    "out:Total CO2 (tons)": 47.48,
    "out:Klimatpaverkan": -94.96,
    "out:Initial Cost/MSEK": 3.22625,
    "out:Running cost/(Apt SEK y)": 20207,
    "out:Running Cost over RSP/MSEK": 2.825,
    "out:LCP/MSEK": 2.285,
    "out:ROI% old": 24.41,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 4242.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 702,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 331525,
    "out:EL kWh savings": -92913,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143736,
    "out:DH kr savings": 301688,
    "out:El kr savings": -157951,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 51244,
    "out:% savings (space heating)": 82.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 17.95,
    "out:Etvv": 0,
    "out:Ef": 57.66,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.07,
    "out:Electricity (inc PV)": 57.02,
    "out:Total Energy Use Pre PV": 79.07,
    "out:Total Energy Use Post PV": 76.02,
    "out:Primary Energy": 112.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.06,
    "out:CO2 Operational/m2": 14.77,
    "out:Total CO2/m2": 19.83,
    "out:Total CO2 (tons)": 44.12,
    "out:Klimatpaverkan": -98.32,
    "out:Initial Cost/MSEK": 3.281875,
    "out:Running cost/(Apt SEK y)": 18796,
    "out:Running Cost over RSP/MSEK": 2.626,
    "out:LCP/MSEK": 2.428,
    "out:ROI% old": 24.86,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 4204.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340425,
    "out:EL kWh savings": -91495,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154245,
    "out:DH kr savings": 309787,
    "out:El kr savings": -155542,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 42808,
    "out:% savings (space heating)": 84.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 57.02,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.58,
    "out:Electricity (inc PV)": 57.04,
    "out:Total Energy Use Pre PV": 80.58,
    "out:Total Energy Use Post PV": 78.04,
    "out:Primary Energy": 114.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.48,
    "out:CO2 Operational/m2": 15.43,
    "out:Total CO2/m2": 20.91,
    "out:Total CO2 (tons)": 46.52,
    "out:Klimatpaverkan": -95.92,
    "out:Initial Cost/MSEK": 3.340125,
    "out:Running cost/(Apt SEK y)": 19343,
    "out:Running Cost over RSP/MSEK": 2.703,
    "out:LCP/MSEK": 2.293,
    "out:ROI% old": 24.09,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 4170.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 335975,
    "out:EL kWh savings": -91536,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150125,
    "out:DH kr savings": 305737,
    "out:El kr savings": -155612,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 47114,
    "out:% savings (space heating)": 83.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.99,
    "out:Etvv": 0,
    "out:Ef": 57.04,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.02,
    "out:Electricity (inc PV)": 56.38,
    "out:Total Energy Use Pre PV": 76.02,
    "out:Total Energy Use Post PV": 73.38,
    "out:Primary Energy": 111.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.48,
    "out:CO2 Operational/m2": 13.91,
    "out:Total CO2/m2": 19.39,
    "out:Total CO2 (tons)": 43.15,
    "out:Klimatpaverkan": -99.29,
    "out:Initial Cost/MSEK": 3.39575,
    "out:Running cost/(Apt SEK y)": 17925,
    "out:Running Cost over RSP/MSEK": 2.504,
    "out:LCP/MSEK": 2.437,
    "out:ROI% old": 24.54,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 4131.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344875,
    "out:EL kWh savings": -90063,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160728,
    "out:DH kr savings": 313836,
    "out:El kr savings": -153108,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 38919,
    "out:% savings (space heating)": 86.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 13.62,
    "out:Etvv": 0,
    "out:Ef": 56.38,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.6,
    "out:Electricity (inc PV)": 47.05,
    "out:Total Energy Use Pre PV": 83.6,
    "out:Total Energy Use Post PV": 70.05,
    "out:Primary Energy": 97.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 12.91,
    "out:Total CO2/m2": 30.32,
    "out:Total CO2 (tons)": 67.46,
    "out:Klimatpaverkan": -74.98,
    "out:Initial Cost/MSEK": 3.689125,
    "out:Running cost/(Apt SEK y)": 17881,
    "out:Running Cost over RSP/MSEK": 2.506,
    "out:LCP/MSEK": 2.141,
    "out:ROI% old": 22.58,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3528.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 955.48,
    "out:PV (% sold (El))": 3.84,
    "out:PV (kWh self-consumed)": 23913.66,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 331525,
    "out:EL kWh savings": -69317,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185067,
    "out:DH kr savings": 301688,
    "out:El kr savings": -117839,
    "out:El kr sold": 1218,
    "out:El kr saved": 40653,
    "out:El kr return": 41871,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 51244,
    "out:% savings (space heating)": 82.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 17.95,
    "out:Etvv": 0,
    "out:Ef": 47.05,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.07,
    "out:Electricity (inc PV)": 46.45,
    "out:Total Energy Use Pre PV": 79.07,
    "out:Total Energy Use Post PV": 65.45,
    "out:Primary Energy": 93.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 11.28,
    "out:Total CO2/m2": 28.69,
    "out:Total CO2 (tons)": 63.84,
    "out:Klimatpaverkan": -78.6,
    "out:Initial Cost/MSEK": 3.74475,
    "out:Running cost/(Apt SEK y)": 16471,
    "out:Running Cost over RSP/MSEK": 2.308,
    "out:LCP/MSEK": 2.284,
    "out:ROI% old": 23,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3492.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1009.67,
    "out:PV (% sold (El))": 4.06,
    "out:PV (kWh self-consumed)": 23859.47,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340425,
    "out:EL kWh savings": -67975,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195517,
    "out:DH kr savings": 309787,
    "out:El kr savings": -115557,
    "out:El kr sold": 1287,
    "out:El kr saved": 40561,
    "out:El kr return": 41848,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 42808,
    "out:% savings (space heating)": 84.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 46.45,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.58,
    "out:Electricity (inc PV)": 46.47,
    "out:Total Energy Use Pre PV": 80.58,
    "out:Total Energy Use Post PV": 67.47,
    "out:Primary Energy": 95.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.84,
    "out:CO2 Operational/m2": 11.83,
    "out:Total CO2/m2": 29.66,
    "out:Total CO2 (tons)": 66,
    "out:Klimatpaverkan": -76.44,
    "out:Initial Cost/MSEK": 3.802875,
    "out:Running cost/(Apt SEK y)": 17020,
    "out:Running Cost over RSP/MSEK": 2.385,
    "out:LCP/MSEK": 2.148,
    "out:ROI% old": 22.36,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3459.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 760,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1060.47,
    "out:PV (% sold (El))": 4.26,
    "out:PV (kWh self-consumed)": 23808.67,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335975,
    "out:EL kWh savings": -68014,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191466,
    "out:DH kr savings": 305737,
    "out:El kr savings": -115623,
    "out:El kr sold": 1352,
    "out:El kr saved": 40475,
    "out:El kr return": 41827,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 47114,
    "out:% savings (space heating)": 83.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.99,
    "out:Etvv": 0,
    "out:Ef": 46.47,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.02,
    "out:Electricity (inc PV)": 45.84,
    "out:Total Energy Use Pre PV": 76.02,
    "out:Total Energy Use Post PV": 62.84,
    "out:Primary Energy": 92.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.84,
    "out:CO2 Operational/m2": 10.18,
    "out:Total CO2/m2": 28.02,
    "out:Total CO2 (tons)": 62.34,
    "out:Klimatpaverkan": -80.1,
    "out:Initial Cost/MSEK": 3.8585,
    "out:Running cost/(Apt SEK y)": 15602,
    "out:Running Cost over RSP/MSEK": 2.186,
    "out:LCP/MSEK": 2.292,
    "out:ROI% old": 22.77,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3421.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 785,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1121.26,
    "out:PV (% sold (El))": 4.51,
    "out:PV (kWh self-consumed)": 23747.88,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344875,
    "out:EL kWh savings": -66622,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202009,
    "out:DH kr savings": 313836,
    "out:El kr savings": -113257,
    "out:El kr sold": 1430,
    "out:El kr saved": 40371,
    "out:El kr return": 41801,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 38919,
    "out:% savings (space heating)": 86.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 13.62,
    "out:Etvv": 0,
    "out:Ef": 45.84,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.6,
    "out:Electricity (inc PV)": 42.34,
    "out:Total Energy Use Pre PV": 83.6,
    "out:Total Energy Use Post PV": 65.34,
    "out:Primary Energy": 88.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.77,
    "out:CO2 Operational/m2": -18.51,
    "out:Total CO2/m2": 11.26,
    "out:Total CO2 (tons)": 25.05,
    "out:Klimatpaverkan": -117.39,
    "out:Initial Cost/MSEK": 4.151875,
    "out:Running cost/(Apt SEK y)": 15855,
    "out:Running Cost over RSP/MSEK": 2.228,
    "out:LCP/MSEK": 1.956,
    "out:ROI% old": 21.02,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3168.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 781,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14616.04,
    "out:PV (% sold (El))": 29.39,
    "out:PV (kWh self-consumed)": 35122.24,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 331525,
    "out:EL kWh savings": -58826,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220319,
    "out:DH kr savings": 301688,
    "out:El kr savings": -100004,
    "out:El kr sold": 18635,
    "out:El kr saved": 59708,
    "out:El kr return": 78343,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 51244,
    "out:% savings (space heating)": 82.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 17.95,
    "out:Etvv": 0,
    "out:Ef": 42.34,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.07,
    "out:Electricity (inc PV)": 41.8,
    "out:Total Energy Use Pre PV": 79.07,
    "out:Total Energy Use Post PV": 60.8,
    "out:Primary Energy": 85.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.77,
    "out:CO2 Operational/m2": -20.43,
    "out:Total CO2/m2": 9.33,
    "out:Total CO2 (tons)": 20.77,
    "out:Klimatpaverkan": -121.67,
    "out:Initial Cost/MSEK": 4.2075,
    "out:Running cost/(Apt SEK y)": 14448,
    "out:Running Cost over RSP/MSEK": 2.03,
    "out:LCP/MSEK": 2.099,
    "out:ROI% old": 21.41,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3136.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14800.22,
    "out:PV (% sold (El))": 29.76,
    "out:PV (kWh self-consumed)": 34938.06,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340425,
    "out:EL kWh savings": -57637,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230673,
    "out:DH kr savings": 309787,
    "out:El kr savings": -97983,
    "out:El kr sold": 18870,
    "out:El kr saved": 59395,
    "out:El kr return": 78265,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 42808,
    "out:% savings (space heating)": 84.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 41.8,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.58,
    "out:Electricity (inc PV)": 41.82,
    "out:Total Energy Use Pre PV": 80.58,
    "out:Total Energy Use Post PV": 62.82,
    "out:Primary Energy": 87.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.19,
    "out:CO2 Operational/m2": -20.15,
    "out:Total CO2/m2": 10.03,
    "out:Total CO2 (tons)": 22.32,
    "out:Klimatpaverkan": -120.12,
    "out:Initial Cost/MSEK": 4.26575,
    "out:Running cost/(Apt SEK y)": 14999,
    "out:Running Cost over RSP/MSEK": 2.108,
    "out:LCP/MSEK": 1.962,
    "out:ROI% old": 20.86,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3107.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14968.84,
    "out:PV (% sold (El))": 30.1,
    "out:PV (kWh self-consumed)": 34769.44,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335975,
    "out:EL kWh savings": -57672,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226780,
    "out:DH kr savings": 305737,
    "out:El kr savings": -98042,
    "out:El kr sold": 19085,
    "out:El kr saved": 59108,
    "out:El kr return": 78193,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 47114,
    "out:% savings (space heating)": 83.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 16.99,
    "out:Etvv": 0,
    "out:Ef": 41.82,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.02,
    "out:Electricity (inc PV)": 41.27,
    "out:Total Energy Use Pre PV": 76.02,
    "out:Total Energy Use Post PV": 58.27,
    "out:Primary Energy": 83.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.19,
    "out:CO2 Operational/m2": -22.11,
    "out:Total CO2/m2": 8.07,
    "out:Total CO2 (tons)": 17.96,
    "out:Klimatpaverkan": -124.48,
    "out:Initial Cost/MSEK": 4.321375,
    "out:Running cost/(Apt SEK y)": 13585,
    "out:Running Cost over RSP/MSEK": 1.909,
    "out:LCP/MSEK": 2.106,
    "out:ROI% old": 21.25,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3073.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 821,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15168.04,
    "out:PV (% sold (El))": 30.5,
    "out:PV (kWh self-consumed)": 34570.24,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 344875,
    "out:EL kWh savings": -56438,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237230,
    "out:DH kr savings": 313836,
    "out:El kr savings": -95945,
    "out:El kr sold": 19339,
    "out:El kr saved": 58769,
    "out:El kr return": 78109,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 38919,
    "out:% savings (space heating)": 86.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 13.62,
    "out:Etvv": 0,
    "out:Ef": 41.27,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 174.9,
    "out:Primary Energy": 126.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.9,
    "out:CO2 Operational/m2": 59.34,
    "out:Total CO2/m2": 62.23,
    "out:Total CO2 (tons)": 138.47,
    "out:Klimatpaverkan": -3.97,
    "out:Initial Cost/MSEK": 0.450842,
    "out:Running cost/(Apt SEK y)": 54996,
    "out:Running Cost over RSP/MSEK": 7.741,
    "out:LCP/MSEK": 0.144,
    "out:ROI% old": 18.85,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 7.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 17,
    "out:Return/kSEK/y": 76,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 28925,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 26322,
    "out:DH kr savings": 26322,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 169.9,
    "out:Primary Energy": 123.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.9,
    "out:CO2 Operational/m2": 57.54,
    "out:Total CO2/m2": 60.43,
    "out:Total CO2 (tons)": 134.47,
    "out:Klimatpaverkan": -7.97,
    "out:Initial Cost/MSEK": 0.506467,
    "out:Running cost/(Apt SEK y)": 53372,
    "out:Running Cost over RSP/MSEK": 7.513,
    "out:LCP/MSEK": 0.317,
    "out:ROI% old": 23.23,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 21,
    "out:Return/kSEK/y": 105,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 40050,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 36445,
    "out:DH kr savings": 36445,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 124.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.32,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 60.14,
    "out:Total CO2 (tons)": 133.81,
    "out:Klimatpaverkan": -8.63,
    "out:Initial Cost/MSEK": 0.564682,
    "out:Running cost/(Apt SEK y)": 52722,
    "out:Running Cost over RSP/MSEK": 7.421,
    "out:LCP/MSEK": 0.351,
    "out:ROI% old": 23.16,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.9,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 21,
    "out:Return/kSEK/y": 117,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40495,
    "out:DH kr savings": 40495,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 120.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.32,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 58.34,
    "out:Total CO2 (tons)": 129.8,
    "out:Klimatpaverkan": -12.64,
    "out:Initial Cost/MSEK": 0.620307,
    "out:Running cost/(Apt SEK y)": 51098,
    "out:Running Cost over RSP/MSEK": 7.192,
    "out:LCP/MSEK": 0.524,
    "out:ROI% old": 26.35,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 24,
    "out:Return/kSEK/y": 146,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55625,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 50619,
    "out:DH kr savings": 50619,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 169.73,
    "out:Primary Energy": 117.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.25,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 43.59,
    "out:Total CO2 (tons)": 96.99,
    "out:Klimatpaverkan": -45.45,
    "out:Initial Cost/MSEK": 0.913666,
    "out:Running cost/(Apt SEK y)": 52963,
    "out:Running Cost over RSP/MSEK": 7.463,
    "out:LCP/MSEK": -0.04,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 113,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 28925,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62920,
    "out:DH kr savings": 26322,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 164.73,
    "out:Primary Energy": 114.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.25,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 41.79,
    "out:Total CO2 (tons)": 92.99,
    "out:Klimatpaverkan": -49.45,
    "out:Initial Cost/MSEK": 0.969291,
    "out:Running cost/(Apt SEK y)": 51339,
    "out:Running Cost over RSP/MSEK": 7.234,
    "out:LCP/MSEK": 0.133,
    "out:ROI% old": 16.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 142,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 40050,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73044,
    "out:DH kr savings": 36445,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 114.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.67,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 41.5,
    "out:Total CO2 (tons)": 92.33,
    "out:Klimatpaverkan": -50.11,
    "out:Initial Cost/MSEK": 1.027506,
    "out:Running cost/(Apt SEK y)": 50689,
    "out:Running Cost over RSP/MSEK": 7.142,
    "out:LCP/MSEK": 0.166,
    "out:ROI% old": 16.6,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 154,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77094,
    "out:DH kr savings": 40495,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 111.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.67,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 39.7,
    "out:Total CO2 (tons)": 88.32,
    "out:Klimatpaverkan": -54.12,
    "out:Initial Cost/MSEK": 1.083131,
    "out:Running cost/(Apt SEK y)": 49064,
    "out:Running Cost over RSP/MSEK": 6.914,
    "out:LCP/MSEK": 0.34,
    "out:ROI% old": 18.76,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 183,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55625,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 87217,
    "out:DH kr savings": 50619,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 168.88,
    "out:Primary Energy": 115.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.6,
    "out:CO2 Operational/m2": -24.34,
    "out:Total CO2/m2": 3.27,
    "out:Total CO2 (tons)": 7.27,
    "out:Klimatpaverkan": -135.17,
    "out:Initial Cost/MSEK": 1.3765,
    "out:Running cost/(Apt SEK y)": 51157,
    "out:Running Cost over RSP/MSEK": 7.216,
    "out:LCP/MSEK": -0.256,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 11.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 145,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 28925,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95431,
    "out:DH kr savings": 26322,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 163.88,
    "out:Primary Energy": 112.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.6,
    "out:CO2 Operational/m2": -26.14,
    "out:Total CO2/m2": 1.47,
    "out:Total CO2 (tons)": 3.26,
    "out:Klimatpaverkan": -139.18,
    "out:Initial Cost/MSEK": 1.432125,
    "out:Running cost/(Apt SEK y)": 49532,
    "out:Running Cost over RSP/MSEK": 6.987,
    "out:LCP/MSEK": -0.083,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 174,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 40050,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105555,
    "out:DH kr savings": 36445,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 113.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.03,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": 1.17,
    "out:Total CO2 (tons)": 2.6,
    "out:Klimatpaverkan": -139.84,
    "out:Initial Cost/MSEK": 1.490375,
    "out:Running cost/(Apt SEK y)": 48883,
    "out:Running Cost over RSP/MSEK": 6.895,
    "out:LCP/MSEK": -0.049,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 44500,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109604,
    "out:DH kr savings": 40495,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 109.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.03,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": -0.63,
    "out:Total CO2 (tons)": -1.4,
    "out:Klimatpaverkan": -143.84,
    "out:Initial Cost/MSEK": 1.546,
    "out:Running cost/(Apt SEK y)": 47258,
    "out:Running Cost over RSP/MSEK": 6.666,
    "out:LCP/MSEK": 0.124,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 55625,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119728,
    "out:DH kr savings": 50619,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 165.9,
    "out:Primary Energy": 123.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.9,
    "out:CO2 Operational/m2": 56.1,
    "out:Total CO2/m2": 58.99,
    "out:Total CO2 (tons)": 131.26,
    "out:Klimatpaverkan": -11.18,
    "out:Initial Cost/MSEK": 0.49835,
    "out:Running cost/(Apt SEK y)": 52072,
    "out:Running Cost over RSP/MSEK": 7.33,
    "out:LCP/MSEK": 0.508,
    "out:ROI% old": 28.86,
    "out:Payback discounted": 4,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 44544,
    "out:DH kr savings": 44544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 160.9,
    "out:Primary Energy": 119.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.9,
    "out:CO2 Operational/m2": 54.3,
    "out:Total CO2/m2": 57.19,
    "out:Total CO2 (tons)": 127.26,
    "out:Klimatpaverkan": -15.18,
    "out:Initial Cost/MSEK": 0.553975,
    "out:Running cost/(Apt SEK y)": 50448,
    "out:Running Cost over RSP/MSEK": 7.101,
    "out:LCP/MSEK": 0.682,
    "out:ROI% old": 31.86,
    "out:Payback discounted": 4,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 29,
    "out:Return/kSEK/y": 158,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54668,
    "out:DH kr savings": 54668,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 160.9,
    "out:Primary Energy": 121.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.32,
    "out:CO2 Operational/m2": 54.3,
    "out:Total CO2/m2": 57.62,
    "out:Total CO2 (tons)": 128.2,
    "out:Klimatpaverkan": -14.24,
    "out:Initial Cost/MSEK": 0.61219,
    "out:Running cost/(Apt SEK y)": 50448,
    "out:Running Cost over RSP/MSEK": 7.101,
    "out:LCP/MSEK": 0.623,
    "out:ROI% old": 28.83,
    "out:Payback discounted": 4,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 158,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54668,
    "out:DH kr savings": 54668,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 117.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.32,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 55.82,
    "out:Total CO2 (tons)": 124.19,
    "out:Klimatpaverkan": -18.25,
    "out:Initial Cost/MSEK": 0.667815,
    "out:Running cost/(Apt SEK y)": 48824,
    "out:Running Cost over RSP/MSEK": 6.872,
    "out:LCP/MSEK": 0.797,
    "out:ROI% old": 31.33,
    "out:Payback discounted": 4,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 28,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64792,
    "out:DH kr savings": 64792,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 160.73,
    "out:Primary Energy": 114,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.25,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 40.35,
    "out:Total CO2 (tons)": 89.79,
    "out:Klimatpaverkan": -52.65,
    "out:Initial Cost/MSEK": 0.961174,
    "out:Running cost/(Apt SEK y)": 50039,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": 0.324,
    "out:ROI% old": 19.1,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 81143,
    "out:DH kr savings": 44544,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 155.73,
    "out:Primary Energy": 110.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.25,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 38.55,
    "out:Total CO2 (tons)": 85.78,
    "out:Klimatpaverkan": -56.66,
    "out:Initial Cost/MSEK": 1.016799,
    "out:Running cost/(Apt SEK y)": 48415,
    "out:Running Cost over RSP/MSEK": 6.822,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 21.27,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91267,
    "out:DH kr savings": 54668,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 155.73,
    "out:Primary Energy": 111.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.67,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 38.98,
    "out:Total CO2 (tons)": 86.72,
    "out:Klimatpaverkan": -55.72,
    "out:Initial Cost/MSEK": 1.075014,
    "out:Running cost/(Apt SEK y)": 48415,
    "out:Running Cost over RSP/MSEK": 6.822,
    "out:LCP/MSEK": 0.439,
    "out:ROI% old": 20.12,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91267,
    "out:DH kr savings": 54668,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 108.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.67,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 37.18,
    "out:Total CO2 (tons)": 82.72,
    "out:Klimatpaverkan": -59.72,
    "out:Initial Cost/MSEK": 1.130639,
    "out:Running cost/(Apt SEK y)": 46790,
    "out:Running Cost over RSP/MSEK": 6.593,
    "out:LCP/MSEK": 0.612,
    "out:ROI% old": 22.02,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101391,
    "out:DH kr savings": 64792,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 159.88,
    "out:Primary Energy": 112.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.6,
    "out:CO2 Operational/m2": -27.58,
    "out:Total CO2/m2": 0.03,
    "out:Total CO2 (tons)": 0.06,
    "out:Klimatpaverkan": -142.38,
    "out:Initial Cost/MSEK": 1.424,
    "out:Running cost/(Apt SEK y)": 48233,
    "out:Running Cost over RSP/MSEK": 6.804,
    "out:LCP/MSEK": 0.109,
    "out:ROI% old": 15.37,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113654,
    "out:DH kr savings": 44544,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 154.88,
    "out:Primary Energy": 109,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.6,
    "out:CO2 Operational/m2": -29.38,
    "out:Total CO2/m2": -1.77,
    "out:Total CO2 (tons)": -3.95,
    "out:Klimatpaverkan": -146.39,
    "out:Initial Cost/MSEK": 1.479625,
    "out:Running cost/(Apt SEK y)": 46609,
    "out:Running Cost over RSP/MSEK": 6.575,
    "out:LCP/MSEK": 0.282,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 227,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123778,
    "out:DH kr savings": 54668,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.28,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 154.88,
    "out:Primary Energy": 110.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.03,
    "out:CO2 Operational/m2": -29.38,
    "out:Total CO2/m2": -1.35,
    "out:Total CO2 (tons)": -3.01,
    "out:Klimatpaverkan": -145.45,
    "out:Initial Cost/MSEK": 1.537875,
    "out:Running cost/(Apt SEK y)": 46609,
    "out:Running Cost over RSP/MSEK": 6.575,
    "out:LCP/MSEK": 0.223,
    "out:ROI% old": 16.36,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 227,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 60075,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123778,
    "out:DH kr savings": 54668,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 256491,
    "out:% savings (space heating)": 10.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 106.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.03,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": -3.15,
    "out:Total CO2 (tons)": -7.01,
    "out:Klimatpaverkan": -149.45,
    "out:Initial Cost/MSEK": 1.5935,
    "out:Running cost/(Apt SEK y)": 44984,
    "out:Running Cost over RSP/MSEK": 6.346,
    "out:LCP/MSEK": 0.397,
    "out:ROI% old": 17.84,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133901,
    "out:DH kr savings": 64792,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 245439,
    "out:% savings (space heating)": 13.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 40.94,
    "out:Total Energy Use Pre PV": 120.06,
    "out:Total Energy Use Post PV": 117.94,
    "out:Primary Energy": 119.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 40.71,
    "out:Total CO2 (tons)": 90.59,
    "out:Klimatpaverkan": -51.85,
    "out:Initial Cost/MSEK": 2.303625,
    "out:Running cost/(Apt SEK y)": 34065,
    "out:Running Cost over RSP/MSEK": 4.783,
    "out:LCP/MSEK": 1.25,
    "out:ROI% old": 22.04,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 3014.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211375,
    "out:EL kWh savings": -55713,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97638,
    "out:DH kr savings": 192351,
    "out:El kr savings": -94713,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 170717,
    "out:% savings (space heating)": 40.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 65.23,
    "out:Etvv": 0,
    "out:Ef": 40.94,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 40.85,
    "out:Total Energy Use Pre PV": 115.01,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 115.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 38.91,
    "out:Total CO2 (tons)": 86.57,
    "out:Klimatpaverkan": -55.87,
    "out:Initial Cost/MSEK": 2.35925,
    "out:Running cost/(Apt SEK y)": 32429,
    "out:Running Cost over RSP/MSEK": 4.553,
    "out:LCP/MSEK": 1.424,
    "out:ROI% old": 22.91,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 3010.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222500,
    "out:EL kWh savings": -55521,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108088,
    "out:DH kr savings": 202475,
    "out:El kr savings": -94386,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 160042,
    "out:% savings (space heating)": 43.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.55,
    "out:Etvv": 0,
    "out:Ef": 40.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 40.48,
    "out:Total Energy Use Pre PV": 115.34,
    "out:Total Energy Use Post PV": 113.48,
    "out:Primary Energy": 116.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.73,
    "out:CO2 Operational/m2": 31.87,
    "out:Total CO2/m2": 39.6,
    "out:Total CO2 (tons)": 88.11,
    "out:Klimatpaverkan": -54.33,
    "out:Initial Cost/MSEK": 2.4175,
    "out:Running cost/(Apt SEK y)": 32614,
    "out:Running Cost over RSP/MSEK": 4.579,
    "out:LCP/MSEK": 1.34,
    "out:ROI% old": 22.2,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2964,
    "out:Return %": 20,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -54687,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107482,
    "out:DH kr savings": 200450,
    "out:El kr savings": -92968,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161490,
    "out:% savings (space heating)": 43.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 63.01,
    "out:Etvv": 0,
    "out:Ef": 40.48,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.28,
    "out:Electricity (inc PV)": 40.38,
    "out:Total Energy Use Pre PV": 110.28,
    "out:Total Energy Use Post PV": 108.38,
    "out:Primary Energy": 113.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.73,
    "out:CO2 Operational/m2": 30.06,
    "out:Total CO2/m2": 37.79,
    "out:Total CO2 (tons)": 84.09,
    "out:Klimatpaverkan": -58.35,
    "out:Initial Cost/MSEK": 2.473125,
    "out:Running cost/(Apt SEK y)": 30976,
    "out:Running Cost over RSP/MSEK": 4.348,
    "out:LCP/MSEK": 1.515,
    "out:ROI% old": 23.04,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2959.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -54475,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117966,
    "out:DH kr savings": 210574,
    "out:El kr savings": -92608,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 150892,
    "out:% savings (space heating)": 47.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.35,
    "out:Etvv": 0,
    "out:Ef": 40.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 31.65,
    "out:Total Energy Use Pre PV": 120.06,
    "out:Total Energy Use Post PV": 108.65,
    "out:Primary Energy": 102.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.66,
    "out:CO2 Operational/m2": 24.01,
    "out:Total CO2/m2": 43.67,
    "out:Total CO2 (tons)": 97.16,
    "out:Klimatpaverkan": -45.28,
    "out:Initial Cost/MSEK": 2.766375,
    "out:Running cost/(Apt SEK y)": 31804,
    "out:Running Cost over RSP/MSEK": 4.473,
    "out:LCP/MSEK": 1.097,
    "out:ROI% old": 19.95,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2373.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3715.24,
    "out:PV (% sold (El))": 14.94,
    "out:PV (kWh self-consumed)": 21153.9,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211375,
    "out:EL kWh savings": -35054,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137496,
    "out:DH kr savings": 192351,
    "out:El kr savings": -59592,
    "out:El kr sold": 4737,
    "out:El kr saved": 35962,
    "out:El kr return": 40699,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 170717,
    "out:% savings (space heating)": 40.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 65.23,
    "out:Etvv": 0,
    "out:Ef": 31.65,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 31.58,
    "out:Total Energy Use Pre PV": 115.01,
    "out:Total Energy Use Post PV": 103.58,
    "out:Primary Energy": 99.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.66,
    "out:CO2 Operational/m2": 22.17,
    "out:Total CO2/m2": 41.84,
    "out:Total CO2 (tons)": 93.08,
    "out:Klimatpaverkan": -49.36,
    "out:Initial Cost/MSEK": 2.822,
    "out:Running cost/(Apt SEK y)": 30169,
    "out:Running Cost over RSP/MSEK": 4.243,
    "out:LCP/MSEK": 1.271,
    "out:ROI% old": 20.72,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2370.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 523,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3726.79,
    "out:PV (% sold (El))": 14.99,
    "out:PV (kWh self-consumed)": 21142.35,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222500,
    "out:EL kWh savings": -34883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147926,
    "out:DH kr savings": 202475,
    "out:El kr savings": -59301,
    "out:El kr sold": 4752,
    "out:El kr saved": 35942,
    "out:El kr return": 40694,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 160042,
    "out:% savings (space heating)": 43.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.55,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 31.24,
    "out:Total Energy Use Pre PV": 115.34,
    "out:Total Energy Use Post PV": 104.24,
    "out:Primary Energy": 100.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 22.11,
    "out:Total CO2/m2": 42.19,
    "out:Total CO2 (tons)": 93.87,
    "out:Klimatpaverkan": -48.57,
    "out:Initial Cost/MSEK": 2.88025,
    "out:Running cost/(Apt SEK y)": 30356,
    "out:Running Cost over RSP/MSEK": 4.27,
    "out:LCP/MSEK": 1.186,
    "out:ROI% old": 20.17,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2328,
    "out:Return %": 18,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3879.15,
    "out:PV (% sold (El))": 15.6,
    "out:PV (kWh self-consumed)": 20989.99,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -34140,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147358,
    "out:DH kr savings": 200450,
    "out:El kr savings": -58038,
    "out:El kr sold": 4946,
    "out:El kr saved": 35683,
    "out:El kr return": 40629,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161490,
    "out:% savings (space heating)": 43.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 63.01,
    "out:Etvv": 0,
    "out:Ef": 31.24,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.28,
    "out:Electricity (inc PV)": 31.16,
    "out:Total Energy Use Pre PV": 110.28,
    "out:Total Energy Use Post PV": 99.16,
    "out:Primary Energy": 96.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 20.27,
    "out:Total CO2/m2": 40.35,
    "out:Total CO2 (tons)": 89.78,
    "out:Klimatpaverkan": -52.66,
    "out:Initial Cost/MSEK": 2.935875,
    "out:Running cost/(Apt SEK y)": 28720,
    "out:Running Cost over RSP/MSEK": 4.039,
    "out:LCP/MSEK": 1.361,
    "out:ROI% old": 20.91,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2324.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3893.41,
    "out:PV (% sold (El))": 15.66,
    "out:PV (kWh self-consumed)": 20975.73,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -33952,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157820,
    "out:DH kr savings": 210574,
    "out:El kr savings": -57718,
    "out:El kr sold": 4964,
    "out:El kr saved": 35659,
    "out:El kr return": 40623,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 150892,
    "out:% savings (space heating)": 47.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.35,
    "out:Etvv": 0,
    "out:Ef": 31.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 28.62,
    "out:Total Energy Use Pre PV": 120.06,
    "out:Total Energy Use Post PV": 105.62,
    "out:Primary Energy": 97.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.02,
    "out:CO2 Operational/m2": -16.59,
    "out:Total CO2/m2": 15.43,
    "out:Total CO2 (tons)": 34.33,
    "out:Klimatpaverkan": -108.11,
    "out:Initial Cost/MSEK": 3.22925,
    "out:Running cost/(Apt SEK y)": 29872,
    "out:Running Cost over RSP/MSEK": 4.209,
    "out:LCP/MSEK": 0.898,
    "out:ROI% old": 18.26,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2143.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21385.2,
    "out:PV (% sold (El))": 43,
    "out:PV (kWh self-consumed)": 28353.08,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 211375,
    "out:EL kWh savings": -28293,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171518,
    "out:DH kr savings": 192351,
    "out:El kr savings": -48099,
    "out:El kr sold": 27266,
    "out:El kr saved": 48200,
    "out:El kr return": 75466,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 170717,
    "out:% savings (space heating)": 40.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 65.23,
    "out:Etvv": 0,
    "out:Ef": 28.62,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 28.55,
    "out:Total Energy Use Pre PV": 115.01,
    "out:Total Energy Use Post PV": 100.55,
    "out:Primary Energy": 93.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.02,
    "out:CO2 Operational/m2": -18.44,
    "out:Total CO2/m2": 13.57,
    "out:Total CO2 (tons)": 30.2,
    "out:Klimatpaverkan": -112.24,
    "out:Initial Cost/MSEK": 3.284875,
    "out:Running cost/(Apt SEK y)": 28237,
    "out:Running Cost over RSP/MSEK": 3.978,
    "out:LCP/MSEK": 1.073,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2140.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21407.42,
    "out:PV (% sold (El))": 43.04,
    "out:PV (kWh self-consumed)": 28330.86,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 222500,
    "out:EL kWh savings": -28140,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181932,
    "out:DH kr savings": 202475,
    "out:El kr savings": -47838,
    "out:El kr sold": 27294,
    "out:El kr saved": 48162,
    "out:El kr return": 75457,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 160042,
    "out:% savings (space heating)": 43.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.55,
    "out:Etvv": 0,
    "out:Ef": 28.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 28.25,
    "out:Total Energy Use Pre PV": 115.34,
    "out:Total Energy Use Post PV": 101.25,
    "out:Primary Energy": 94.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.44,
    "out:CO2 Operational/m2": -18.83,
    "out:Total CO2/m2": 13.61,
    "out:Total CO2 (tons)": 30.29,
    "out:Klimatpaverkan": -112.15,
    "out:Initial Cost/MSEK": 3.343125,
    "out:Running cost/(Apt SEK y)": 28428,
    "out:Running Cost over RSP/MSEK": 4.005,
    "out:LCP/MSEK": 0.988,
    "out:ROI% old": 18.51,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2102.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21699.08,
    "out:PV (% sold (El))": 43.63,
    "out:PV (kWh self-consumed)": 28039.2,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -27473,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181412,
    "out:DH kr savings": 200450,
    "out:El kr savings": -46704,
    "out:El kr sold": 27666,
    "out:El kr saved": 47667,
    "out:El kr return": 75333,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161490,
    "out:% savings (space heating)": 43.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 63.01,
    "out:Etvv": 0,
    "out:Ef": 28.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.28,
    "out:Electricity (inc PV)": 28.17,
    "out:Total Energy Use Pre PV": 110.28,
    "out:Total Energy Use Post PV": 96.17,
    "out:Primary Energy": 91.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.44,
    "out:CO2 Operational/m2": -20.69,
    "out:Total CO2/m2": 11.74,
    "out:Total CO2 (tons)": 26.13,
    "out:Klimatpaverkan": -116.31,
    "out:Initial Cost/MSEK": 3.39875,
    "out:Running cost/(Apt SEK y)": 26792,
    "out:Running Cost over RSP/MSEK": 3.775,
    "out:LCP/MSEK": 1.163,
    "out:ROI% old": 19.17,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2098.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 584,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21726.13,
    "out:PV (% sold (El))": 43.68,
    "out:PV (kWh self-consumed)": 28012.15,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -27304,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191858,
    "out:DH kr savings": 210574,
    "out:El kr savings": -46417,
    "out:El kr sold": 27701,
    "out:El kr saved": 47621,
    "out:El kr return": 75321,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 150892,
    "out:% savings (space heating)": 47.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.35,
    "out:Etvv": 0,
    "out:Ef": 28.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 40.32,
    "out:Total Energy Use Pre PV": 113.1,
    "out:Total Energy Use Post PV": 111.32,
    "out:Primary Energy": 116.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 31.12,
    "out:Total CO2/m2": 38.42,
    "out:Total CO2 (tons)": 85.5,
    "out:Klimatpaverkan": -56.94,
    "out:Initial Cost/MSEK": 2.351125,
    "out:Running cost/(Apt SEK y)": 31912,
    "out:Running Cost over RSP/MSEK": 4.48,
    "out:LCP/MSEK": 1.505,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2946.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 224725,
    "out:EL kWh savings": -54339,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112123,
    "out:DH kr savings": 204500,
    "out:El kr savings": -92377,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 158463,
    "out:% savings (space heating)": 44.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 40.32,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.03,
    "out:Electricity (inc PV)": 40.22,
    "out:Total Energy Use Pre PV": 108.03,
    "out:Total Energy Use Post PV": 106.22,
    "out:Primary Energy": 112.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 29.31,
    "out:Total CO2/m2": 36.62,
    "out:Total CO2 (tons)": 81.47,
    "out:Klimatpaverkan": -60.97,
    "out:Initial Cost/MSEK": 2.40675,
    "out:Running cost/(Apt SEK y)": 30274,
    "out:Running Cost over RSP/MSEK": 4.249,
    "out:LCP/MSEK": 1.68,
    "out:ROI% old": 24.26,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2942.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235850,
    "out:EL kWh savings": -54122,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122616,
    "out:DH kr savings": 214623,
    "out:El kr savings": -92007,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 147895,
    "out:% savings (space heating)": 48.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 40.22,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.49,
    "out:Electricity (inc PV)": 39.94,
    "out:Total Energy Use Pre PV": 109.49,
    "out:Total Energy Use Post PV": 107.94,
    "out:Primary Energy": 114.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.73,
    "out:CO2 Operational/m2": 29.96,
    "out:Total CO2/m2": 37.69,
    "out:Total CO2 (tons)": 83.85,
    "out:Klimatpaverkan": -58.59,
    "out:Initial Cost/MSEK": 2.465,
    "out:Running cost/(Apt SEK y)": 30810,
    "out:Running Cost over RSP/MSEK": 4.325,
    "out:LCP/MSEK": 1.546,
    "out:ROI% old": 23.25,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2904.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -53496,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119631,
    "out:DH kr savings": 210574,
    "out:El kr savings": -90943,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151306,
    "out:% savings (space heating)": 46.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.52,
    "out:Etvv": 0,
    "out:Ef": 39.94,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.41,
    "out:Electricity (inc PV)": 39.84,
    "out:Total Energy Use Pre PV": 104.41,
    "out:Total Energy Use Post PV": 102.84,
    "out:Primary Energy": 110.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.73,
    "out:CO2 Operational/m2": 28.15,
    "out:Total CO2/m2": 35.88,
    "out:Total CO2 (tons)": 79.83,
    "out:Klimatpaverkan": -62.61,
    "out:Initial Cost/MSEK": 2.520625,
    "out:Running cost/(Apt SEK y)": 29169,
    "out:Running Cost over RSP/MSEK": 4.094,
    "out:LCP/MSEK": 1.722,
    "out:ROI% old": 24.04,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2898.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -53265,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130148,
    "out:DH kr savings": 220698,
    "out:El kr savings": -90550,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 140816,
    "out:% savings (space heating)": 50.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.9,
    "out:Etvv": 0,
    "out:Ef": 39.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 31.1,
    "out:Total Energy Use Pre PV": 113.1,
    "out:Total Energy Use Post PV": 102.1,
    "out:Primary Energy": 99.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.66,
    "out:CO2 Operational/m2": 21.23,
    "out:Total CO2/m2": 40.89,
    "out:Total CO2 (tons)": 90.98,
    "out:Klimatpaverkan": -51.46,
    "out:Initial Cost/MSEK": 2.813875,
    "out:Running cost/(Apt SEK y)": 29656,
    "out:Running Cost over RSP/MSEK": 4.171,
    "out:LCP/MSEK": 1.351,
    "out:ROI% old": 21.15,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2312.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3936.18,
    "out:PV (% sold (El))": 15.83,
    "out:PV (kWh self-consumed)": 20932.96,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 224725,
    "out:EL kWh savings": -33831,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152006,
    "out:DH kr savings": 204500,
    "out:El kr savings": -57513,
    "out:El kr sold": 5019,
    "out:El kr saved": 35586,
    "out:El kr return": 40605,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 158463,
    "out:% savings (space heating)": 44.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 31.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.03,
    "out:Electricity (inc PV)": 31.02,
    "out:Total Energy Use Pre PV": 108.03,
    "out:Total Energy Use Post PV": 97.02,
    "out:Primary Energy": 96.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.66,
    "out:CO2 Operational/m2": 19.38,
    "out:Total CO2/m2": 39.05,
    "out:Total CO2 (tons)": 86.88,
    "out:Klimatpaverkan": -55.56,
    "out:Initial Cost/MSEK": 2.8695,
    "out:Running cost/(Apt SEK y)": 28018,
    "out:Running Cost over RSP/MSEK": 3.94,
    "out:LCP/MSEK": 1.526,
    "out:ROI% old": 21.88,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2308.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3951.44,
    "out:PV (% sold (El))": 15.89,
    "out:PV (kWh self-consumed)": 20917.7,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 235850,
    "out:EL kWh savings": -33638,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162477,
    "out:DH kr savings": 214623,
    "out:El kr savings": -57184,
    "out:El kr sold": 5038,
    "out:El kr saved": 35560,
    "out:El kr return": 40598,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 147895,
    "out:% savings (space heating)": 48.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 31.02,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.49,
    "out:Electricity (inc PV)": 30.77,
    "out:Total Energy Use Pre PV": 109.49,
    "out:Total Energy Use Post PV": 98.77,
    "out:Primary Energy": 97.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 19.75,
    "out:Total CO2/m2": 39.84,
    "out:Total CO2 (tons)": 88.63,
    "out:Klimatpaverkan": -53.81,
    "out:Initial Cost/MSEK": 2.92775,
    "out:Running cost/(Apt SEK y)": 28557,
    "out:Running Cost over RSP/MSEK": 4.017,
    "out:LCP/MSEK": 1.392,
    "out:ROI% old": 21.08,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2273.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4078.31,
    "out:PV (% sold (El))": 16.4,
    "out:PV (kWh self-consumed)": 20790.83,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -33082,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159534,
    "out:DH kr savings": 210574,
    "out:El kr savings": -56240,
    "out:El kr sold": 5200,
    "out:El kr saved": 35344,
    "out:El kr return": 40544,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151306,
    "out:% savings (space heating)": 46.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.52,
    "out:Etvv": 0,
    "out:Ef": 30.77,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.41,
    "out:Electricity (inc PV)": 30.68,
    "out:Total Energy Use Pre PV": 104.41,
    "out:Total Energy Use Post PV": 93.68,
    "out:Primary Energy": 94.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.08,
    "out:CO2 Operational/m2": 17.9,
    "out:Total CO2/m2": 37.99,
    "out:Total CO2 (tons)": 84.52,
    "out:Klimatpaverkan": -57.92,
    "out:Initial Cost/MSEK": 2.983375,
    "out:Running cost/(Apt SEK y)": 26917,
    "out:Running Cost over RSP/MSEK": 3.786,
    "out:LCP/MSEK": 1.567,
    "out:ROI% old": 21.79,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2269,
    "out:Return %": 19,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4096.49,
    "out:PV (% sold (El))": 16.47,
    "out:PV (kWh self-consumed)": 20772.65,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -32877,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170030,
    "out:DH kr savings": 220698,
    "out:El kr savings": -55891,
    "out:El kr sold": 5223,
    "out:El kr saved": 35314,
    "out:El kr return": 40537,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 140816,
    "out:% savings (space heating)": 50.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.9,
    "out:Etvv": 0,
    "out:Ef": 30.68,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 28.12,
    "out:Total Energy Use Pre PV": 113.1,
    "out:Total Energy Use Post PV": 99.12,
    "out:Primary Energy": 94.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.02,
    "out:CO2 Operational/m2": -19.82,
    "out:Total CO2/m2": 12.19,
    "out:Total CO2 (tons)": 27.13,
    "out:Klimatpaverkan": -115.31,
    "out:Initial Cost/MSEK": 3.27675,
    "out:Running cost/(Apt SEK y)": 27729,
    "out:Running Cost over RSP/MSEK": 3.907,
    "out:LCP/MSEK": 1.152,
    "out:ROI% old": 19.31,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2088.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 567,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21807.18,
    "out:PV (% sold (El))": 43.84,
    "out:PV (kWh self-consumed)": 27931.1,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 224725,
    "out:EL kWh savings": -27196,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186071,
    "out:DH kr savings": 204500,
    "out:El kr savings": -46232,
    "out:El kr sold": 27804,
    "out:El kr saved": 47483,
    "out:El kr return": 75287,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 158463,
    "out:% savings (space heating)": 44.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 62.27,
    "out:Etvv": 0,
    "out:Ef": 28.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.03,
    "out:Electricity (inc PV)": 28.04,
    "out:Total Energy Use Pre PV": 108.03,
    "out:Total Energy Use Post PV": 94.04,
    "out:Primary Energy": 90.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.02,
    "out:CO2 Operational/m2": -21.7,
    "out:Total CO2/m2": 10.32,
    "out:Total CO2 (tons)": 22.96,
    "out:Klimatpaverkan": -119.48,
    "out:Initial Cost/MSEK": 3.332375,
    "out:Running cost/(Apt SEK y)": 26092,
    "out:Running Cost over RSP/MSEK": 3.676,
    "out:LCP/MSEK": 1.327,
    "out:ROI% old": 19.98,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2084.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 596,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21836.08,
    "out:PV (% sold (El))": 43.9,
    "out:PV (kWh self-consumed)": 27902.2,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 235850,
    "out:EL kWh savings": -27022,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196527,
    "out:DH kr savings": 214623,
    "out:El kr savings": -45938,
    "out:El kr sold": 27841,
    "out:El kr saved": 47434,
    "out:El kr return": 75275,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 147895,
    "out:% savings (space heating)": 48.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 57.63,
    "out:Etvv": 0,
    "out:Ef": 28.04,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.49,
    "out:Electricity (inc PV)": 27.82,
    "out:Total Energy Use Pre PV": 109.49,
    "out:Total Energy Use Post PV": 95.82,
    "out:Primary Energy": 92.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.44,
    "out:CO2 Operational/m2": -21.58,
    "out:Total CO2/m2": 10.85,
    "out:Total CO2 (tons)": 24.15,
    "out:Klimatpaverkan": -118.29,
    "out:Initial Cost/MSEK": 3.390625,
    "out:Running cost/(Apt SEK y)": 26634,
    "out:Running Cost over RSP/MSEK": 3.753,
    "out:LCP/MSEK": 1.193,
    "out:ROI% old": 19.31,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2054,
    "out:Return %": 17,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22074.62,
    "out:PV (% sold (El))": 44.38,
    "out:PV (kWh self-consumed)": 27663.66,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -26523,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193630,
    "out:DH kr savings": 210574,
    "out:El kr savings": -45089,
    "out:El kr sold": 28145,
    "out:El kr saved": 47028,
    "out:El kr return": 75173,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151306,
    "out:% savings (space heating)": 46.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 60.52,
    "out:Etvv": 0,
    "out:Ef": 27.82,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.41,
    "out:Electricity (inc PV)": 27.74,
    "out:Total Energy Use Pre PV": 104.41,
    "out:Total Energy Use Post PV": 90.74,
    "out:Primary Energy": 89.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.44,
    "out:CO2 Operational/m2": -23.47,
    "out:Total CO2/m2": 8.97,
    "out:Total CO2 (tons)": 19.95,
    "out:Klimatpaverkan": -122.49,
    "out:Initial Cost/MSEK": 3.44625,
    "out:Running cost/(Apt SEK y)": 24994,
    "out:Running Cost over RSP/MSEK": 3.522,
    "out:LCP/MSEK": 1.368,
    "out:ROI% old": 19.96,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2049.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 616,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22108.44,
    "out:PV (% sold (El))": 44.45,
    "out:PV (kWh self-consumed)": 27629.84,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -26338,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204111,
    "out:DH kr savings": 220698,
    "out:El kr savings": -44775,
    "out:El kr sold": 28188,
    "out:El kr saved": 46971,
    "out:El kr return": 75159,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 140816,
    "out:% savings (space heating)": 50.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.9,
    "out:Etvv": 0,
    "out:Ef": 27.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.86,
    "out:Electricity (inc PV)": 56.97,
    "out:Total Energy Use Pre PV": 78.86,
    "out:Total Energy Use Post PV": 74.97,
    "out:Primary Energy": 111.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.96,
    "out:CO2 Operational/m2": 14.51,
    "out:Total CO2/m2": 22.47,
    "out:Total CO2 (tons)": 50,
    "out:Klimatpaverkan": -92.44,
    "out:Initial Cost/MSEK": 3.629625,
    "out:Running cost/(Apt SEK y)": 18636,
    "out:Running Cost over RSP/MSEK": 2.603,
    "out:LCP/MSEK": 2.103,
    "out:ROI% old": 22.56,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4259.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 342650,
    "out:EL kWh savings": -91386,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156455,
    "out:DH kr savings": 311811,
    "out:El kr savings": -155356,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 40075,
    "out:% savings (space heating)": 85.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 56.97,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 56.29,
    "out:Total Energy Use Pre PV": 75.33,
    "out:Total Energy Use Post PV": 71.29,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.96,
    "out:CO2 Operational/m2": 13.36,
    "out:Total CO2/m2": 21.32,
    "out:Total CO2 (tons)": 47.44,
    "out:Klimatpaverkan": -95,
    "out:Initial Cost/MSEK": 3.68525,
    "out:Running cost/(Apt SEK y)": 17551,
    "out:Running Cost over RSP/MSEK": 2.451,
    "out:LCP/MSEK": 2.2,
    "out:ROI% old": 22.82,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4223.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -89862,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165119,
    "out:DH kr savings": 317886,
    "out:El kr savings": -152766,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 32884,
    "out:% savings (space heating)": 88.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 56.29,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.53,
    "out:Electricity (inc PV)": 56.14,
    "out:Total Energy Use Pre PV": 75.53,
    "out:Total Energy Use Post PV": 72.14,
    "out:Primary Energy": 108.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 13.62,
    "out:Total CO2/m2": 22,
    "out:Total CO2 (tons)": 48.94,
    "out:Klimatpaverkan": -93.5,
    "out:Initial Cost/MSEK": 3.7435,
    "out:Running cost/(Apt SEK y)": 17707,
    "out:Running Cost over RSP/MSEK": 2.473,
    "out:LCP/MSEK": 2.12,
    "out:ROI% old": 22.38,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 161.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4166.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 747,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347100,
    "out:EL kWh savings": -89536,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163649,
    "out:DH kr savings": 315861,
    "out:El kr savings": -152212,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 35320,
    "out:% savings (space heating)": 87.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 11.32,
    "out:Etvv": 0,
    "out:Ef": 56.14,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 55.42,
    "out:Total Energy Use Pre PV": 71.95,
    "out:Total Energy Use Post PV": 68.42,
    "out:Primary Energy": 105.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 12.46,
    "out:Total CO2/m2": 20.84,
    "out:Total CO2 (tons)": 46.37,
    "out:Klimatpaverkan": -96.07,
    "out:Initial Cost/MSEK": 3.799125,
    "out:Running cost/(Apt SEK y)": 16610,
    "out:Running Cost over RSP/MSEK": 2.319,
    "out:LCP/MSEK": 2.219,
    "out:ROI% old": 22.63,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4126.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -87933,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172449,
    "out:DH kr savings": 321935,
    "out:El kr savings": -149486,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28519,
    "out:% savings (space heating)": 90,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 55.42,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.86,
    "out:Electricity (inc PV)": 46.4,
    "out:Total Energy Use Pre PV": 78.86,
    "out:Total Energy Use Post PV": 64.4,
    "out:Primary Energy": 92.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.31,
    "out:CO2 Operational/m2": 11.19,
    "out:Total CO2/m2": 31.5,
    "out:Total CO2 (tons)": 70.09,
    "out:Klimatpaverkan": -72.35,
    "out:Initial Cost/MSEK": 4.092375,
    "out:Running cost/(Apt SEK y)": 16309,
    "out:Running Cost over RSP/MSEK": 2.285,
    "out:LCP/MSEK": 1.959,
    "out:ROI% old": 21.12,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 193.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3545.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 930.52,
    "out:PV (% sold (El))": 3.74,
    "out:PV (kWh self-consumed)": 23938.62,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 342650,
    "out:EL kWh savings": -67871,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197616,
    "out:DH kr savings": 311811,
    "out:El kr savings": -115382,
    "out:El kr sold": 1186,
    "out:El kr saved": 40696,
    "out:El kr return": 41882,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 40075,
    "out:% savings (space heating)": 85.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 46.4,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 45.76,
    "out:Total Energy Use Pre PV": 75.33,
    "out:Total Energy Use Post PV": 60.76,
    "out:Primary Energy": 89.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.31,
    "out:CO2 Operational/m2": 9.93,
    "out:Total CO2/m2": 30.24,
    "out:Total CO2 (tons)": 67.29,
    "out:Klimatpaverkan": -75.15,
    "out:Initial Cost/MSEK": 4.148,
    "out:Running cost/(Apt SEK y)": 15225,
    "out:Running Cost over RSP/MSEK": 2.132,
    "out:LCP/MSEK": 2.056,
    "out:ROI% old": 21.37,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3510.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 792,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 983.12,
    "out:PV (% sold (El))": 3.95,
    "out:PV (kWh self-consumed)": 23886.02,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -66432,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206205,
    "out:DH kr savings": 317886,
    "out:El kr savings": -112935,
    "out:El kr sold": 1253,
    "out:El kr saved": 40606,
    "out:El kr return": 41860,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 32884,
    "out:% savings (space heating)": 88.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 45.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.53,
    "out:Electricity (inc PV)": 45.62,
    "out:Total Energy Use Pre PV": 75.53,
    "out:Total Energy Use Post PV": 61.62,
    "out:Primary Energy": 90.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 10.01,
    "out:Total CO2/m2": 30.74,
    "out:Total CO2 (tons)": 68.4,
    "out:Klimatpaverkan": -74.04,
    "out:Initial Cost/MSEK": 4.20625,
    "out:Running cost/(Apt SEK y)": 15383,
    "out:Running Cost over RSP/MSEK": 2.155,
    "out:LCP/MSEK": 1.975,
    "out:ROI% old": 20.99,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3455.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1066.63,
    "out:PV (% sold (El))": 4.29,
    "out:PV (kWh self-consumed)": 23802.51,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 347100,
    "out:EL kWh savings": -66124,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204809,
    "out:DH kr savings": 315861,
    "out:El kr savings": -112411,
    "out:El kr sold": 1360,
    "out:El kr saved": 40464,
    "out:El kr return": 41824,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 35320,
    "out:% savings (space heating)": 87.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 11.32,
    "out:Etvv": 0,
    "out:Ef": 45.62,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 44.94,
    "out:Total Energy Use Pre PV": 71.95,
    "out:Total Energy Use Post PV": 57.94,
    "out:Primary Energy": 87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 8.72,
    "out:Total CO2/m2": 29.45,
    "out:Total CO2 (tons)": 65.53,
    "out:Klimatpaverkan": -76.91,
    "out:Initial Cost/MSEK": 4.261875,
    "out:Running cost/(Apt SEK y)": 14288,
    "out:Running Cost over RSP/MSEK": 2.001,
    "out:LCP/MSEK": 2.074,
    "out:ROI% old": 21.24,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3416.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1128.89,
    "out:PV (% sold (El))": 4.54,
    "out:PV (kWh self-consumed)": 23740.25,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -64613,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213532,
    "out:DH kr savings": 321935,
    "out:El kr savings": -109843,
    "out:El kr sold": 1439,
    "out:El kr saved": 40358,
    "out:El kr return": 41798,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28519,
    "out:% savings (space heating)": 90,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 44.94,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.86,
    "out:Electricity (inc PV)": 41.76,
    "out:Total Energy Use Pre PV": 78.86,
    "out:Total Energy Use Post PV": 59.76,
    "out:Primary Energy": 83.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.66,
    "out:CO2 Operational/m2": -20.08,
    "out:Total CO2/m2": 12.58,
    "out:Total CO2 (tons)": 28,
    "out:Klimatpaverkan": -114.44,
    "out:Initial Cost/MSEK": 4.55525,
    "out:Running cost/(Apt SEK y)": 14281,
    "out:Running Cost over RSP/MSEK": 2.007,
    "out:LCP/MSEK": 1.774,
    "out:ROI% old": 19.85,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3183.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14529.29,
    "out:PV (% sold (El))": 29.21,
    "out:PV (kWh self-consumed)": 35208.99,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 342650,
    "out:EL kWh savings": -57546,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232508,
    "out:DH kr savings": 311811,
    "out:El kr savings": -97828,
    "out:El kr sold": 18525,
    "out:El kr saved": 59855,
    "out:El kr return": 78380,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 40075,
    "out:% savings (space heating)": 85.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 12.33,
    "out:Etvv": 0,
    "out:Ef": 41.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.33,
    "out:Electricity (inc PV)": 41.19,
    "out:Total Energy Use Pre PV": 75.33,
    "out:Total Energy Use Post PV": 56.19,
    "out:Primary Energy": 80.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.66,
    "out:CO2 Operational/m2": -21.64,
    "out:Total CO2/m2": 11.03,
    "out:Total CO2 (tons)": 24.54,
    "out:Klimatpaverkan": -117.9,
    "out:Initial Cost/MSEK": 4.610875,
    "out:Running cost/(Apt SEK y)": 13201,
    "out:Running Cost over RSP/MSEK": 1.854,
    "out:LCP/MSEK": 1.871,
    "out:ROI% old": 20.08,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3152,
    "out:Return %": 18,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14710.36,
    "out:PV (% sold (El))": 29.58,
    "out:PV (kWh self-consumed)": 35027.92,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -56270,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240982,
    "out:DH kr savings": 317886,
    "out:El kr savings": -95659,
    "out:El kr sold": 18756,
    "out:El kr saved": 59547,
    "out:El kr return": 78303,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 32884,
    "out:% savings (space heating)": 88.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.62,
    "out:Etvv": 0,
    "out:Ef": 41.19,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.53,
    "out:Electricity (inc PV)": 41.07,
    "out:Total Energy Use Pre PV": 75.53,
    "out:Total Energy Use Post PV": 57.07,
    "out:Primary Energy": 81.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -22.01,
    "out:Total CO2/m2": 11.08,
    "out:Total CO2 (tons)": 24.65,
    "out:Klimatpaverkan": -117.79,
    "out:Initial Cost/MSEK": 4.669125,
    "out:Running cost/(Apt SEK y)": 13363,
    "out:Running Cost over RSP/MSEK": 1.878,
    "out:LCP/MSEK": 1.79,
    "out:ROI% old": 19.76,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3104,
    "out:Return %": 18,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14989.11,
    "out:PV (% sold (El))": 30.14,
    "out:PV (kWh self-consumed)": 34749.17,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 347100,
    "out:EL kWh savings": -55997,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239776,
    "out:DH kr savings": 315861,
    "out:El kr savings": -95196,
    "out:El kr sold": 19111,
    "out:El kr saved": 59074,
    "out:El kr return": 78185,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 35320,
    "out:% savings (space heating)": 87.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 11.32,
    "out:Etvv": 0,
    "out:Ef": 41.07,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.95,
    "out:Electricity (inc PV)": 40.47,
    "out:Total Energy Use Pre PV": 71.95,
    "out:Total Energy Use Post PV": 53.47,
    "out:Primary Energy": 78.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -23.62,
    "out:Total CO2/m2": 9.47,
    "out:Total CO2 (tons)": 21.06,
    "out:Klimatpaverkan": -121.38,
    "out:Initial Cost/MSEK": 4.72475,
    "out:Running cost/(Apt SEK y)": 12272,
    "out:Running Cost over RSP/MSEK": 1.724,
    "out:LCP/MSEK": 1.887,
    "out:ROI% old": 19.99,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3069.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 845,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15192.93,
    "out:PV (% sold (El))": 30.55,
    "out:PV (kWh self-consumed)": 34545.35,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -54657,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248388,
    "out:DH kr savings": 321935,
    "out:El kr savings": -92918,
    "out:El kr sold": 19371,
    "out:El kr saved": 58727,
    "out:El kr return": 78098,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28519,
    "out:% savings (space heating)": 90,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.73,
    "out:Etvv": 0,
    "out:Ef": 40.47,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.07,
    "out:Electricity (inc PV)": 55.86,
    "out:Total Energy Use Pre PV": 74.07,
    "out:Total Energy Use Post PV": 70.86,
    "out:Primary Energy": 108.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.96,
    "out:CO2 Operational/m2": 13.2,
    "out:Total CO2/m2": 21.16,
    "out:Total CO2 (tons)": 47.07,
    "out:Klimatpaverkan": -95.37,
    "out:Initial Cost/MSEK": 3.677125,
    "out:Running cost/(Apt SEK y)": 17287,
    "out:Running Cost over RSP/MSEK": 2.414,
    "out:LCP/MSEK": 2.245,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4135.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 755,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -88913,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166733,
    "out:DH kr savings": 317886,
    "out:El kr savings": -151152,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33804,
    "out:% savings (space heating)": 88.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.99,
    "out:Etvv": 0,
    "out:Ef": 55.86,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 55.13,
    "out:Total Energy Use Pre PV": 70.48,
    "out:Total Energy Use Post PV": 67.13,
    "out:Primary Energy": 105.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.96,
    "out:CO2 Operational/m2": 12.04,
    "out:Total CO2/m2": 20,
    "out:Total CO2 (tons)": 44.49,
    "out:Klimatpaverkan": -97.95,
    "out:Initial Cost/MSEK": 3.73275,
    "out:Running cost/(Apt SEK y)": 16187,
    "out:Running Cost over RSP/MSEK": 2.259,
    "out:LCP/MSEK": 2.344,
    "out:ROI% old": 23.26,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4093.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -87282,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175581,
    "out:DH kr savings": 323960,
    "out:El kr savings": -148379,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27138,
    "out:% savings (space heating)": 90.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.45,
    "out:Etvv": 0,
    "out:Ef": 55.13,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.97,
    "out:Electricity (inc PV)": 55.18,
    "out:Total Energy Use Pre PV": 71.97,
    "out:Total Energy Use Post PV": 69.18,
    "out:Primary Energy": 106.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 21.07,
    "out:Total CO2 (tons)": 46.88,
    "out:Klimatpaverkan": -95.56,
    "out:Initial Cost/MSEK": 3.791,
    "out:Running cost/(Apt SEK y)": 16729,
    "out:Running Cost over RSP/MSEK": 2.336,
    "out:LCP/MSEK": 2.21,
    "out:ROI% old": 22.61,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4057.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 765,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351550,
    "out:EL kWh savings": -87401,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171328,
    "out:DH kr savings": 319910,
    "out:El kr savings": -148582,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 30319,
    "out:% savings (space heating)": 89.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.24,
    "out:Etvv": 0,
    "out:Ef": 55.18,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 54.42,
    "out:Total Energy Use Pre PV": 68.32,
    "out:Total Energy Use Post PV": 65.42,
    "out:Primary Energy": 103.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.38,
    "out:CO2 Operational/m2": 11.53,
    "out:Total CO2/m2": 19.91,
    "out:Total CO2 (tons)": 44.29,
    "out:Klimatpaverkan": -98.15,
    "out:Initial Cost/MSEK": 3.846625,
    "out:Running cost/(Apt SEK y)": 15620,
    "out:Running Cost over RSP/MSEK": 2.18,
    "out:LCP/MSEK": 2.31,
    "out:ROI% old": 22.86,
    "out:Payback discounted": 5,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4012.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 785,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -85696,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180301,
    "out:DH kr savings": 325985,
    "out:El kr savings": -145683,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23986,
    "out:% savings (space heating)": 91.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.8,
    "out:Etvv": 0,
    "out:Ef": 54.42,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.07,
    "out:Electricity (inc PV)": 45.35,
    "out:Total Energy Use Pre PV": 74.07,
    "out:Total Energy Use Post PV": 60.35,
    "out:Primary Energy": 89.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.31,
    "out:CO2 Operational/m2": 9.48,
    "out:Total CO2/m2": 29.79,
    "out:Total CO2 (tons)": 66.29,
    "out:Klimatpaverkan": -76.15,
    "out:Initial Cost/MSEK": 4.139875,
    "out:Running cost/(Apt SEK y)": 14964,
    "out:Running Cost over RSP/MSEK": 2.096,
    "out:LCP/MSEK": 2.101,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3425.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1115.2,
    "out:PV (% sold (El))": 4.48,
    "out:PV (kWh self-consumed)": 23753.94,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -65536,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207896,
    "out:DH kr savings": 317886,
    "out:El kr savings": -111412,
    "out:El kr sold": 1422,
    "out:El kr saved": 40382,
    "out:El kr return": 41804,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33804,
    "out:% savings (space heating)": 88.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.99,
    "out:Etvv": 0,
    "out:Ef": 45.35,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 44.66,
    "out:Total Energy Use Pre PV": 70.48,
    "out:Total Energy Use Post PV": 56.66,
    "out:Primary Energy": 86.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.31,
    "out:CO2 Operational/m2": 8.18,
    "out:Total CO2/m2": 28.49,
    "out:Total CO2 (tons)": 63.4,
    "out:Klimatpaverkan": -79.04,
    "out:Initial Cost/MSEK": 4.1955,
    "out:Running cost/(Apt SEK y)": 13866,
    "out:Running Cost over RSP/MSEK": 1.941,
    "out:LCP/MSEK": 2.199,
    "out:ROI% old": 21.77,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3385.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1180.57,
    "out:PV (% sold (El))": 4.75,
    "out:PV (kWh self-consumed)": 23688.57,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -64001,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216664,
    "out:DH kr savings": 323960,
    "out:El kr savings": -108801,
    "out:El kr sold": 1505,
    "out:El kr saved": 40271,
    "out:El kr return": 41776,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27138,
    "out:% savings (space heating)": 90.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.45,
    "out:Etvv": 0,
    "out:Ef": 44.66,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.97,
    "out:Electricity (inc PV)": 44.71,
    "out:Total Energy Use Pre PV": 71.97,
    "out:Total Energy Use Post PV": 58.71,
    "out:Primary Energy": 87.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 8.71,
    "out:Total CO2/m2": 29.44,
    "out:Total CO2 (tons)": 65.51,
    "out:Klimatpaverkan": -76.93,
    "out:Initial Cost/MSEK": 4.25375,
    "out:Running cost/(Apt SEK y)": 14410,
    "out:Running Cost over RSP/MSEK": 2.018,
    "out:LCP/MSEK": 2.064,
    "out:ROI% old": 21.22,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3350.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1237.97,
    "out:PV (% sold (El))": 4.98,
    "out:PV (kWh self-consumed)": 23631.17,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351550,
    "out:EL kWh savings": -64113,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212496,
    "out:DH kr savings": 319910,
    "out:El kr savings": -108992,
    "out:El kr sold": 1578,
    "out:El kr saved": 40173,
    "out:El kr return": 41751,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 30319,
    "out:% savings (space heating)": 89.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.24,
    "out:Etvv": 0,
    "out:Ef": 44.71,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 43.99,
    "out:Total Energy Use Pre PV": 68.32,
    "out:Total Energy Use Post PV": 54.99,
    "out:Primary Energy": 84.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.73,
    "out:CO2 Operational/m2": 7.38,
    "out:Total CO2/m2": 28.12,
    "out:Total CO2 (tons)": 62.56,
    "out:Klimatpaverkan": -79.88,
    "out:Initial Cost/MSEK": 4.309375,
    "out:Running cost/(Apt SEK y)": 13302,
    "out:Running Cost over RSP/MSEK": 1.862,
    "out:LCP/MSEK": 2.165,
    "out:ROI% old": 21.46,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3307.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1312.82,
    "out:PV (% sold (El))": 5.28,
    "out:PV (kWh self-consumed)": 23556.32,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -62511,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221390,
    "out:DH kr savings": 325985,
    "out:El kr savings": -106268,
    "out:El kr sold": 1674,
    "out:El kr saved": 40046,
    "out:El kr return": 41720,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23986,
    "out:% savings (space heating)": 91.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.8,
    "out:Etvv": 0,
    "out:Ef": 43.99,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.07,
    "out:Electricity (inc PV)": 40.83,
    "out:Total Energy Use Pre PV": 74.07,
    "out:Total Energy Use Post PV": 55.83,
    "out:Primary Energy": 81.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.66,
    "out:CO2 Operational/m2": -22.78,
    "out:Total CO2/m2": 9.88,
    "out:Total CO2 (tons)": 21.99,
    "out:Klimatpaverkan": -120.45,
    "out:Initial Cost/MSEK": 4.60275,
    "out:Running cost/(Apt SEK y)": 12947,
    "out:Running Cost over RSP/MSEK": 1.819,
    "out:LCP/MSEK": 1.914,
    "out:ROI% old": 20.23,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3077,
    "out:Return %": 18,
    "out:Return/kSEK/y": 833,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15148.29,
    "out:PV (% sold (El))": 30.46,
    "out:PV (kWh self-consumed)": 34589.99,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -55476,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242890,
    "out:DH kr savings": 317886,
    "out:El kr savings": -94310,
    "out:El kr sold": 19314,
    "out:El kr saved": 58803,
    "out:El kr return": 78117,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33804,
    "out:% savings (space heating)": 88.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.99,
    "out:Etvv": 0,
    "out:Ef": 40.83,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.48,
    "out:Electricity (inc PV)": 40.22,
    "out:Total Energy Use Pre PV": 70.48,
    "out:Total Energy Use Post PV": 52.22,
    "out:Primary Energy": 78.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.66,
    "out:CO2 Operational/m2": -24.41,
    "out:Total CO2/m2": 8.25,
    "out:Total CO2 (tons)": 18.36,
    "out:Klimatpaverkan": -124.08,
    "out:Initial Cost/MSEK": 4.658375,
    "out:Running cost/(Apt SEK y)": 11852,
    "out:Running Cost over RSP/MSEK": 1.665,
    "out:LCP/MSEK": 2.013,
    "out:ROI% old": 20.46,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3041.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15359.75,
    "out:PV (% sold (El))": 30.88,
    "out:PV (kWh self-consumed)": 34378.53,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -54114,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251550,
    "out:DH kr savings": 323960,
    "out:El kr savings": -91993,
    "out:El kr sold": 19584,
    "out:El kr saved": 58443,
    "out:El kr return": 78027,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27138,
    "out:% savings (space heating)": 90.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.45,
    "out:Etvv": 0,
    "out:Ef": 40.22,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.97,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 71.97,
    "out:Total Energy Use Post PV": 54.27,
    "out:Primary Energy": 79.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -24.17,
    "out:Total CO2/m2": 8.92,
    "out:Total CO2 (tons)": 19.84,
    "out:Klimatpaverkan": -122.6,
    "out:Initial Cost/MSEK": 4.716625,
    "out:Running cost/(Apt SEK y)": 12399,
    "out:Running Cost over RSP/MSEK": 1.742,
    "out:LCP/MSEK": 1.877,
    "out:ROI% old": 19.97,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3011.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15541.33,
    "out:PV (% sold (El))": 31.25,
    "out:PV (kWh self-consumed)": 34196.95,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351550,
    "out:EL kWh savings": -54214,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247562,
    "out:DH kr savings": 319910,
    "out:El kr savings": -92163,
    "out:El kr sold": 19815,
    "out:El kr saved": 58135,
    "out:El kr return": 77950,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 30319,
    "out:% savings (space heating)": 89.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.24,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 39.63,
    "out:Total Energy Use Pre PV": 68.32,
    "out:Total Energy Use Post PV": 50.63,
    "out:Primary Energy": 76.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.09,
    "out:CO2 Operational/m2": -25.85,
    "out:Total CO2/m2": 7.24,
    "out:Total CO2 (tons)": 16.1,
    "out:Klimatpaverkan": -126.34,
    "out:Initial Cost/MSEK": 4.77225,
    "out:Running cost/(Apt SEK y)": 11294,
    "out:Running Cost over RSP/MSEK": 1.587,
    "out:LCP/MSEK": 1.977,
    "out:ROI% old": 20.2,
    "out:Payback discounted": 6,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2973,
    "out:Return %": 18,
    "out:Return/kSEK/y": 863,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15771.81,
    "out:PV (% sold (El))": 31.71,
    "out:PV (kWh self-consumed)": 33966.47,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -52791,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256349,
    "out:DH kr savings": 325985,
    "out:El kr savings": -89745,
    "out:El kr sold": 20109,
    "out:El kr saved": 57743,
    "out:El kr return": 77852,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23986,
    "out:% savings (space heating)": 91.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.8,
    "out:Etvv": 0,
    "out:Ef": 39.63,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 170.9,
    "out:Primary Energy": 124.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.13,
    "out:CO2 Operational/m2": 57.9,
    "out:Total CO2/m2": 68.02,
    "out:Total CO2 (tons)": 151.35,
    "out:Klimatpaverkan": 8.91,
    "out:Initial Cost/MSEK": 1.55475,
    "out:Running cost/(Apt SEK y)": 53697,
    "out:Running Cost over RSP/MSEK": 7.558,
    "out:LCP/MSEK": -0.777,
    "out:ROI% old": 7.15,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 99,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 34421,
    "out:DH kr savings": 34421,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 165.9,
    "out:Primary Energy": 120.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.13,
    "out:CO2 Operational/m2": 56.1,
    "out:Total CO2/m2": 66.22,
    "out:Total CO2 (tons)": 147.35,
    "out:Klimatpaverkan": 4.91,
    "out:Initial Cost/MSEK": 1.610375,
    "out:Running cost/(Apt SEK y)": 52072,
    "out:Running Cost over RSP/MSEK": 7.33,
    "out:LCP/MSEK": -0.604,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44544,
    "out:DH kr savings": 44544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 163.9,
    "out:Primary Energy": 121.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 55.38,
    "out:Total CO2/m2": 65.93,
    "out:Total CO2 (tons)": 146.68,
    "out:Klimatpaverkan": 4.24,
    "out:Initial Cost/MSEK": 1.668625,
    "out:Running cost/(Apt SEK y)": 51423,
    "out:Running Cost over RSP/MSEK": 7.238,
    "out:LCP/MSEK": -0.57,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48594,
    "out:DH kr savings": 48594,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 118.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 64.49,
    "out:Total CO2 (tons)": 143.48,
    "out:Klimatpaverkan": 1.04,
    "out:Initial Cost/MSEK": 1.72425,
    "out:Running cost/(Apt SEK y)": 50123,
    "out:Running Cost over RSP/MSEK": 7.055,
    "out:LCP/MSEK": -0.443,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 164,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 56693,
    "out:DH kr savings": 56693,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 165.73,
    "out:Primary Energy": 114.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.48,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 49.38,
    "out:Total CO2 (tons)": 109.87,
    "out:Klimatpaverkan": -32.57,
    "out:Initial Cost/MSEK": 2.017625,
    "out:Running cost/(Apt SEK y)": 51664,
    "out:Running Cost over RSP/MSEK": 7.28,
    "out:LCP/MSEK": -0.961,
    "out:ROI% old": 7.48,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71019,
    "out:DH kr savings": 34421,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 160.73,
    "out:Primary Energy": 111.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.48,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 47.58,
    "out:Total CO2 (tons)": 105.87,
    "out:Klimatpaverkan": -36.57,
    "out:Initial Cost/MSEK": 2.07325,
    "out:Running cost/(Apt SEK y)": 50039,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": -0.788,
    "out:ROI% old": 8.86,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81143,
    "out:DH kr savings": 44544,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 158.73,
    "out:Primary Energy": 112.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 47.28,
    "out:Total CO2 (tons)": 105.21,
    "out:Klimatpaverkan": -37.23,
    "out:Initial Cost/MSEK": 2.131375,
    "out:Running cost/(Apt SEK y)": 49389,
    "out:Running Cost over RSP/MSEK": 6.959,
    "out:LCP/MSEK": -0.754,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 177,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85193,
    "out:DH kr savings": 48594,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 108.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 45.84,
    "out:Total CO2 (tons)": 102,
    "out:Klimatpaverkan": -40.44,
    "out:Initial Cost/MSEK": 2.187,
    "out:Running cost/(Apt SEK y)": 48090,
    "out:Running Cost over RSP/MSEK": 6.776,
    "out:LCP/MSEK": -0.627,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93292,
    "out:DH kr savings": 56693,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 164.88,
    "out:Primary Energy": 113.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.83,
    "out:CO2 Operational/m2": -25.78,
    "out:Total CO2/m2": 9.05,
    "out:Total CO2 (tons)": 20.15,
    "out:Klimatpaverkan": -122.29,
    "out:Initial Cost/MSEK": 2.480375,
    "out:Running cost/(Apt SEK y)": 49857,
    "out:Running Cost over RSP/MSEK": 7.033,
    "out:LCP/MSEK": -1.177,
    "out:ROI% old": 7.51,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 169,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103530,
    "out:DH kr savings": 34421,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 159.88,
    "out:Primary Energy": 109.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.83,
    "out:CO2 Operational/m2": -27.58,
    "out:Total CO2/m2": 7.25,
    "out:Total CO2 (tons)": 16.14,
    "out:Klimatpaverkan": -126.3,
    "out:Initial Cost/MSEK": 2.536,
    "out:Running cost/(Apt SEK y)": 48233,
    "out:Running Cost over RSP/MSEK": 6.804,
    "out:LCP/MSEK": -1.003,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113654,
    "out:DH kr savings": 44544,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 157.88,
    "out:Primary Energy": 110.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -28.3,
    "out:Total CO2/m2": 6.96,
    "out:Total CO2 (tons)": 15.48,
    "out:Klimatpaverkan": -126.96,
    "out:Initial Cost/MSEK": 2.59425,
    "out:Running cost/(Apt SEK y)": 47583,
    "out:Running Cost over RSP/MSEK": 6.712,
    "out:LCP/MSEK": -0.97,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117703,
    "out:DH kr savings": 48594,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 107.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 5.52,
    "out:Total CO2 (tons)": 12.28,
    "out:Klimatpaverkan": -130.16,
    "out:Initial Cost/MSEK": 2.649875,
    "out:Running cost/(Apt SEK y)": 46284,
    "out:Running Cost over RSP/MSEK": 6.529,
    "out:LCP/MSEK": -0.843,
    "out:ROI% old": 9.74,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125802,
    "out:DH kr savings": 56693,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 120.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.13,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 64.78,
    "out:Total CO2 (tons)": 144.14,
    "out:Klimatpaverkan": 1.7,
    "out:Initial Cost/MSEK": 1.60225,
    "out:Running cost/(Apt SEK y)": 50773,
    "out:Running Cost over RSP/MSEK": 7.146,
    "out:LCP/MSEK": -0.412,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52643,
    "out:DH kr savings": 52643,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 117.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.13,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 62.98,
    "out:Total CO2 (tons)": 140.14,
    "out:Klimatpaverkan": -2.3,
    "out:Initial Cost/MSEK": 1.657875,
    "out:Running cost/(Apt SEK y)": 49148,
    "out:Running Cost over RSP/MSEK": 6.918,
    "out:LCP/MSEK": -0.239,
    "out:ROI% old": 12.22,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62767,
    "out:DH kr savings": 62767,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 118.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 63.41,
    "out:Total CO2 (tons)": 141.08,
    "out:Klimatpaverkan": -1.36,
    "out:Initial Cost/MSEK": 1.716125,
    "out:Running cost/(Apt SEK y)": 49148,
    "out:Running Cost over RSP/MSEK": 6.918,
    "out:LCP/MSEK": -0.297,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62767,
    "out:DH kr savings": 62767,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 115.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 61.61,
    "out:Total CO2 (tons)": 137.07,
    "out:Klimatpaverkan": -5.37,
    "out:Initial Cost/MSEK": 1.77175,
    "out:Running cost/(Apt SEK y)": 47524,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": -0.124,
    "out:ROI% old": 13.28,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 211,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72891,
    "out:DH kr savings": 72891,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 111.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.48,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 46.14,
    "out:Total CO2 (tons)": 102.66,
    "out:Klimatpaverkan": -39.78,
    "out:Initial Cost/MSEK": 2.065125,
    "out:Running cost/(Apt SEK y)": 48740,
    "out:Running Cost over RSP/MSEK": 6.868,
    "out:LCP/MSEK": -0.597,
    "out:ROI% old": 10.16,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89242,
    "out:DH kr savings": 52643,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 107.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.48,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 44.34,
    "out:Total CO2 (tons)": 98.66,
    "out:Klimatpaverkan": -43.78,
    "out:Initial Cost/MSEK": 2.12075,
    "out:Running cost/(Apt SEK y)": 47115,
    "out:Running Cost over RSP/MSEK": 6.639,
    "out:LCP/MSEK": -0.424,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99366,
    "out:DH kr savings": 62767,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 109.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 44.76,
    "out:Total CO2 (tons)": 99.6,
    "out:Klimatpaverkan": -42.84,
    "out:Initial Cost/MSEK": 2.178875,
    "out:Running cost/(Apt SEK y)": 47115,
    "out:Running Cost over RSP/MSEK": 6.639,
    "out:LCP/MSEK": -0.482,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99366,
    "out:DH kr savings": 62767,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 105.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 95.59,
    "out:Klimatpaverkan": -46.85,
    "out:Initial Cost/MSEK": 2.2345,
    "out:Running cost/(Apt SEK y)": 45491,
    "out:Running Cost over RSP/MSEK": 6.41,
    "out:LCP/MSEK": -0.308,
    "out:ROI% old": 12.31,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109490,
    "out:DH kr savings": 72891,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 109.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.83,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 5.81,
    "out:Total CO2 (tons)": 12.94,
    "out:Klimatpaverkan": -129.5,
    "out:Initial Cost/MSEK": 2.527875,
    "out:Running cost/(Apt SEK y)": 46934,
    "out:Running Cost over RSP/MSEK": 6.621,
    "out:LCP/MSEK": -0.812,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121753,
    "out:DH kr savings": 52643,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 106.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.83,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 4.01,
    "out:Total CO2 (tons)": 8.93,
    "out:Klimatpaverkan": -133.51,
    "out:Initial Cost/MSEK": 2.5835,
    "out:Running cost/(Apt SEK y)": 45309,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.639,
    "out:ROI% old": 10.75,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131877,
    "out:DH kr savings": 62767,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 107.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 4.44,
    "out:Total CO2 (tons)": 9.87,
    "out:Klimatpaverkan": -132.57,
    "out:Initial Cost/MSEK": 2.64175,
    "out:Running cost/(Apt SEK y)": 45309,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.697,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131877,
    "out:DH kr savings": 62767,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247786,
    "out:% savings (space heating)": 13.11,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 104.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 2.64,
    "out:Total CO2 (tons)": 5.87,
    "out:Klimatpaverkan": -136.57,
    "out:Initial Cost/MSEK": 2.697375,
    "out:Running cost/(Apt SEK y)": 43685,
    "out:Running Cost over RSP/MSEK": 6.163,
    "out:LCP/MSEK": -0.524,
    "out:ROI% old": 11.51,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142000,
    "out:DH kr savings": 72891,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 237130,
    "out:% savings (space heating)": 16.84,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 40.89,
    "out:Total Energy Use Pre PV": 116.04,
    "out:Total Energy Use Post PV": 113.89,
    "out:Primary Energy": 116.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 46.5,
    "out:Total CO2 (tons)": 103.46,
    "out:Klimatpaverkan": -38.98,
    "out:Initial Cost/MSEK": 3.4075,
    "out:Running cost/(Apt SEK y)": 32761,
    "out:Running Cost over RSP/MSEK": 4.599,
    "out:LCP/MSEK": 0.329,
    "out:ROI% old": 15.67,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 3013,
    "out:Return %": 14,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -55614,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105907,
    "out:DH kr savings": 200450,
    "out:El kr savings": -94544,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162163,
    "out:% savings (space heating)": 43.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.47,
    "out:Etvv": 0,
    "out:Ef": 40.89,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 40.81,
    "out:Total Energy Use Pre PV": 110.99,
    "out:Total Energy Use Post PV": 108.81,
    "out:Primary Energy": 113.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 30.15,
    "out:Total CO2/m2": 44.69,
    "out:Total CO2 (tons)": 99.44,
    "out:Klimatpaverkan": -43,
    "out:Initial Cost/MSEK": 3.463125,
    "out:Running cost/(Apt SEK y)": 31125,
    "out:Running Cost over RSP/MSEK": 4.369,
    "out:LCP/MSEK": 0.504,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 3009.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -55415,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116368,
    "out:DH kr savings": 210574,
    "out:El kr savings": -94206,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151892,
    "out:% savings (space heating)": 46.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.97,
    "out:Etvv": 0,
    "out:Ef": 40.81,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.31,
    "out:Electricity (inc PV)": 40.43,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 109.43,
    "out:Primary Energy": 114.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 30.42,
    "out:Total CO2/m2": 45.38,
    "out:Total CO2 (tons)": 100.98,
    "out:Klimatpaverkan": -41.46,
    "out:Initial Cost/MSEK": 3.521375,
    "out:Running cost/(Apt SEK y)": 31308,
    "out:Running Cost over RSP/MSEK": 4.395,
    "out:LCP/MSEK": 0.42,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2962,
    "out:Return %": 14,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229175,
    "out:EL kWh savings": -54573,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115775,
    "out:DH kr savings": 208549,
    "out:El kr savings": -92774,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152993,
    "out:% savings (space heating)": 46.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 59.26,
    "out:Etvv": 0,
    "out:Ef": 40.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.25,
    "out:Electricity (inc PV)": 40.33,
    "out:Total Energy Use Pre PV": 106.25,
    "out:Total Energy Use Post PV": 104.33,
    "out:Primary Energy": 110.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 28.62,
    "out:Total CO2/m2": 43.58,
    "out:Total CO2 (tons)": 96.96,
    "out:Klimatpaverkan": -45.48,
    "out:Initial Cost/MSEK": 3.577,
    "out:Running cost/(Apt SEK y)": 29671,
    "out:Running Cost over RSP/MSEK": 4.164,
    "out:LCP/MSEK": 0.595,
    "out:ROI% old": 16.66,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2957.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -54358,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126265,
    "out:DH kr savings": 218673,
    "out:El kr savings": -92408,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142802,
    "out:% savings (space heating)": 49.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.79,
    "out:Etvv": 0,
    "out:Ef": 40.33,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 31.61,
    "out:Total Energy Use Pre PV": 116.04,
    "out:Total Energy Use Post PV": 104.61,
    "out:Primary Energy": 99.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": 22.55,
    "out:Total CO2/m2": 49.44,
    "out:Total CO2 (tons)": 110.01,
    "out:Klimatpaverkan": -32.43,
    "out:Initial Cost/MSEK": 3.870375,
    "out:Running cost/(Apt SEK y)": 30500,
    "out:Running Cost over RSP/MSEK": 4.29,
    "out:LCP/MSEK": 0.176,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2372.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3719.87,
    "out:PV (% sold (El))": 14.96,
    "out:PV (kWh self-consumed)": 21149.27,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -34965,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145752,
    "out:DH kr savings": 200450,
    "out:El kr savings": -59441,
    "out:El kr sold": 4743,
    "out:El kr saved": 35954,
    "out:El kr return": 40697,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162163,
    "out:% savings (space heating)": 43.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.47,
    "out:Etvv": 0,
    "out:Ef": 31.61,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 31.54,
    "out:Total Energy Use Pre PV": 110.99,
    "out:Total Energy Use Post PV": 99.54,
    "out:Primary Energy": 96.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": 20.72,
    "out:Total CO2/m2": 47.61,
    "out:Total CO2 (tons)": 105.93,
    "out:Klimatpaverkan": -36.51,
    "out:Initial Cost/MSEK": 3.926,
    "out:Running cost/(Apt SEK y)": 28865,
    "out:Running Cost over RSP/MSEK": 4.059,
    "out:LCP/MSEK": 0.351,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2369.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3731.7,
    "out:PV (% sold (El))": 15.01,
    "out:PV (kWh self-consumed)": 21137.44,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -34788,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156192,
    "out:DH kr savings": 210574,
    "out:El kr savings": -59140,
    "out:El kr sold": 4758,
    "out:El kr saved": 35934,
    "out:El kr return": 40692,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151892,
    "out:% savings (space heating)": 46.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.97,
    "out:Etvv": 0,
    "out:Ef": 31.54,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.31,
    "out:Electricity (inc PV)": 31.2,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 100.2,
    "out:Primary Energy": 97.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 20.65,
    "out:Total CO2/m2": 47.96,
    "out:Total CO2 (tons)": 106.71,
    "out:Klimatpaverkan": -35.73,
    "out:Initial Cost/MSEK": 3.984125,
    "out:Running cost/(Apt SEK y)": 29051,
    "out:Running Cost over RSP/MSEK": 4.086,
    "out:LCP/MSEK": 0.266,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2326.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3885.68,
    "out:PV (% sold (El))": 15.62,
    "out:PV (kWh self-consumed)": 20983.46,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229175,
    "out:EL kWh savings": -34039,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155638,
    "out:DH kr savings": 208549,
    "out:El kr savings": -57866,
    "out:El kr sold": 4954,
    "out:El kr saved": 35672,
    "out:El kr return": 40626,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152993,
    "out:% savings (space heating)": 46.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 59.26,
    "out:Etvv": 0,
    "out:Ef": 31.2,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.25,
    "out:Electricity (inc PV)": 31.11,
    "out:Total Energy Use Pre PV": 106.25,
    "out:Total Energy Use Post PV": 95.11,
    "out:Primary Energy": 94.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 46.12,
    "out:Total CO2 (tons)": 102.62,
    "out:Klimatpaverkan": -39.82,
    "out:Initial Cost/MSEK": 4.03975,
    "out:Running cost/(Apt SEK y)": 27414,
    "out:Running Cost over RSP/MSEK": 3.855,
    "out:LCP/MSEK": 0.441,
    "out:ROI% old": 15.85,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2322.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3900.29,
    "out:PV (% sold (El))": 15.68,
    "out:PV (kWh self-consumed)": 20968.85,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -33847,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166105,
    "out:DH kr savings": 218673,
    "out:El kr savings": -57540,
    "out:El kr sold": 4973,
    "out:El kr saved": 35647,
    "out:El kr return": 40620,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142802,
    "out:% savings (space heating)": 49.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.79,
    "out:Etvv": 0,
    "out:Ef": 31.11,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 28.58,
    "out:Total Energy Use Pre PV": 116.04,
    "out:Total Energy Use Post PV": 101.58,
    "out:Primary Energy": 94.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -18.05,
    "out:Total CO2/m2": 21.2,
    "out:Total CO2 (tons)": 47.16,
    "out:Klimatpaverkan": -95.28,
    "out:Initial Cost/MSEK": 4.333125,
    "out:Running cost/(Apt SEK y)": 28568,
    "out:Running Cost over RSP/MSEK": 4.025,
    "out:LCP/MSEK": -0.022,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2142.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21394.1,
    "out:PV (% sold (El))": 43.01,
    "out:PV (kWh self-consumed)": 28344.18,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -28214,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179764,
    "out:DH kr savings": 200450,
    "out:El kr savings": -47963,
    "out:El kr sold": 27277,
    "out:El kr saved": 48185,
    "out:El kr return": 75463,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162163,
    "out:% savings (space heating)": 43.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.47,
    "out:Etvv": 0,
    "out:Ef": 28.58,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 28.51,
    "out:Total Energy Use Pre PV": 110.99,
    "out:Total Energy Use Post PV": 96.51,
    "out:Primary Energy": 91.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -19.91,
    "out:Total CO2/m2": 19.34,
    "out:Total CO2 (tons)": 43.03,
    "out:Klimatpaverkan": -99.41,
    "out:Initial Cost/MSEK": 4.38875,
    "out:Running cost/(Apt SEK y)": 26934,
    "out:Running Cost over RSP/MSEK": 3.795,
    "out:LCP/MSEK": 0.153,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2139.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21416.86,
    "out:PV (% sold (El))": 43.06,
    "out:PV (kWh self-consumed)": 28321.42,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -28055,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190187,
    "out:DH kr savings": 210574,
    "out:El kr savings": -47693,
    "out:El kr sold": 27306,
    "out:El kr saved": 48146,
    "out:El kr return": 75453,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151892,
    "out:% savings (space heating)": 46.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.97,
    "out:Etvv": 0,
    "out:Ef": 28.51,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.31,
    "out:Electricity (inc PV)": 28.21,
    "out:Total Energy Use Pre PV": 111.31,
    "out:Total Energy Use Post PV": 97.21,
    "out:Primary Energy": 92.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -20.3,
    "out:Total CO2/m2": 19.37,
    "out:Total CO2 (tons)": 43.1,
    "out:Klimatpaverkan": -99.34,
    "out:Initial Cost/MSEK": 4.447,
    "out:Running cost/(Apt SEK y)": 27123,
    "out:Running Cost over RSP/MSEK": 3.822,
    "out:LCP/MSEK": 0.068,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2100.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21711.46,
    "out:PV (% sold (El))": 43.65,
    "out:PV (kWh self-consumed)": 28026.82,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229175,
    "out:EL kWh savings": -27382,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189682,
    "out:DH kr savings": 208549,
    "out:El kr savings": -46550,
    "out:El kr sold": 27682,
    "out:El kr saved": 47646,
    "out:El kr return": 75328,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152993,
    "out:% savings (space heating)": 46.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 59.26,
    "out:Etvv": 0,
    "out:Ef": 28.21,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.25,
    "out:Electricity (inc PV)": 28.13,
    "out:Total Energy Use Pre PV": 106.25,
    "out:Total Energy Use Post PV": 92.13,
    "out:Primary Energy": 88.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -22.17,
    "out:Total CO2/m2": 17.5,
    "out:Total CO2 (tons)": 38.93,
    "out:Klimatpaverkan": -103.51,
    "out:Initial Cost/MSEK": 4.502625,
    "out:Running cost/(Apt SEK y)": 25486,
    "out:Running Cost over RSP/MSEK": 3.591,
    "out:LCP/MSEK": 0.243,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2097.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21739.18,
    "out:PV (% sold (El))": 43.71,
    "out:PV (kWh self-consumed)": 27999.1,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -27210,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200133,
    "out:DH kr savings": 218673,
    "out:El kr savings": -46257,
    "out:El kr sold": 27717,
    "out:El kr saved": 47598,
    "out:El kr return": 75316,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142802,
    "out:% savings (space heating)": 49.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.79,
    "out:Etvv": 0,
    "out:Ef": 28.13,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.06,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 109.06,
    "out:Total Energy Use Post PV": 107.27,
    "out:Primary Energy": 113.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 44.21,
    "out:Total CO2 (tons)": 98.36,
    "out:Klimatpaverkan": -44.08,
    "out:Initial Cost/MSEK": 3.455,
    "out:Running cost/(Apt SEK y)": 30606,
    "out:Running Cost over RSP/MSEK": 4.296,
    "out:LCP/MSEK": 0.585,
    "out:ROI% old": 16.7,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2944.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -54222,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120422,
    "out:DH kr savings": 212599,
    "out:El kr savings": -92177,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149984,
    "out:% savings (space heating)": 47.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.53,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42,
    "out:Electricity (inc PV)": 40.17,
    "out:Total Energy Use Pre PV": 105,
    "out:Total Energy Use Post PV": 103.17,
    "out:Primary Energy": 110.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.54,
    "out:CO2 Operational/m2": 28.22,
    "out:Total CO2/m2": 42.76,
    "out:Total CO2 (tons)": 95.14,
    "out:Klimatpaverkan": -47.3,
    "out:Initial Cost/MSEK": 3.510625,
    "out:Running cost/(Apt SEK y)": 29292,
    "out:Running Cost over RSP/MSEK": 4.111,
    "out:LCP/MSEK": 0.714,
    "out:ROI% old": 17.19,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2939.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 539,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -54000,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128898,
    "out:DH kr savings": 220698,
    "out:El kr savings": -91800,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139828,
    "out:% savings (space heating)": 50.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.07,
    "out:Etvv": 0,
    "out:Ef": 40.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.45,
    "out:Electricity (inc PV)": 39.89,
    "out:Total Energy Use Pre PV": 105.45,
    "out:Total Energy Use Post PV": 103.89,
    "out:Primary Energy": 111.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 28.51,
    "out:Total CO2/m2": 43.47,
    "out:Total CO2 (tons)": 96.72,
    "out:Klimatpaverkan": -45.72,
    "out:Initial Cost/MSEK": 3.568875,
    "out:Running cost/(Apt SEK y)": 29502,
    "out:Running Cost over RSP/MSEK": 4.141,
    "out:LCP/MSEK": 0.626,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2901.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -53369,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127946,
    "out:DH kr savings": 218673,
    "out:El kr savings": -90727,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142882,
    "out:% savings (space heating)": 49.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.8,
    "out:Etvv": 0,
    "out:Ef": 39.89,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 39.78,
    "out:Total Energy Use Pre PV": 101.37,
    "out:Total Energy Use Post PV": 99.78,
    "out:Primary Energy": 108.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 27.06,
    "out:Total CO2/m2": 42.02,
    "out:Total CO2 (tons)": 93.49,
    "out:Klimatpaverkan": -48.95,
    "out:Initial Cost/MSEK": 3.6245,
    "out:Running cost/(Apt SEK y)": 28186,
    "out:Running Cost over RSP/MSEK": 3.956,
    "out:LCP/MSEK": 0.756,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2896.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -53131,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136448,
    "out:DH kr savings": 226772,
    "out:El kr savings": -90323,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132800,
    "out:% savings (space heating)": 53.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.36,
    "out:Etvv": 0,
    "out:Ef": 39.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.06,
    "out:Electricity (inc PV)": 31.06,
    "out:Total Energy Use Pre PV": 109.06,
    "out:Total Energy Use Post PV": 98.06,
    "out:Primary Energy": 96.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": 19.77,
    "out:Total CO2/m2": 46.66,
    "out:Total CO2 (tons)": 103.81,
    "out:Klimatpaverkan": -38.63,
    "out:Initial Cost/MSEK": 3.917875,
    "out:Running cost/(Apt SEK y)": 28350,
    "out:Running Cost over RSP/MSEK": 3.987,
    "out:LCP/MSEK": 0.431,
    "out:ROI% old": 15.86,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2310.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3943.32,
    "out:PV (% sold (El))": 15.86,
    "out:PV (kWh self-consumed)": 20925.82,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -33726,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160292,
    "out:DH kr savings": 212599,
    "out:El kr savings": -57335,
    "out:El kr sold": 5028,
    "out:El kr saved": 35574,
    "out:El kr return": 40602,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149984,
    "out:% savings (space heating)": 47.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.53,
    "out:Etvv": 0,
    "out:Ef": 31.06,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42,
    "out:Electricity (inc PV)": 30.97,
    "out:Total Energy Use Pre PV": 105,
    "out:Total Energy Use Post PV": 93.97,
    "out:Primary Energy": 93.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": 18.28,
    "out:Total CO2/m2": 45.17,
    "out:Total CO2 (tons)": 100.51,
    "out:Klimatpaverkan": -41.93,
    "out:Initial Cost/MSEK": 3.9735,
    "out:Running cost/(Apt SEK y)": 27037,
    "out:Running Cost over RSP/MSEK": 3.802,
    "out:LCP/MSEK": 0.561,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2306.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3959.08,
    "out:PV (% sold (El))": 15.92,
    "out:PV (kWh self-consumed)": 20910.06,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -33529,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168746,
    "out:DH kr savings": 220698,
    "out:El kr savings": -57000,
    "out:El kr sold": 5048,
    "out:El kr saved": 35547,
    "out:El kr return": 40595,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139828,
    "out:% savings (space heating)": 50.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.07,
    "out:Etvv": 0,
    "out:Ef": 30.97,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.45,
    "out:Electricity (inc PV)": 30.72,
    "out:Total Energy Use Pre PV": 105.45,
    "out:Total Energy Use Post PV": 94.72,
    "out:Primary Energy": 95.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 18.29,
    "out:Total CO2/m2": 45.6,
    "out:Total CO2 (tons)": 101.46,
    "out:Klimatpaverkan": -40.98,
    "out:Initial Cost/MSEK": 4.031625,
    "out:Running cost/(Apt SEK y)": 27250,
    "out:Running Cost over RSP/MSEK": 3.832,
    "out:LCP/MSEK": 0.472,
    "out:ROI% old": 15.96,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2271.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4087.39,
    "out:PV (% sold (El))": 16.44,
    "out:PV (kWh self-consumed)": 20781.75,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -32969,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167836,
    "out:DH kr savings": 218673,
    "out:El kr savings": -56048,
    "out:El kr sold": 5211,
    "out:El kr saved": 35329,
    "out:El kr return": 40540,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142882,
    "out:% savings (space heating)": 49.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.8,
    "out:Etvv": 0,
    "out:Ef": 30.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 30.62,
    "out:Total Energy Use Pre PV": 101.37,
    "out:Total Energy Use Post PV": 90.62,
    "out:Primary Energy": 91.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 16.79,
    "out:Total CO2/m2": 44.11,
    "out:Total CO2 (tons)": 98.14,
    "out:Klimatpaverkan": -44.3,
    "out:Initial Cost/MSEK": 4.08725,
    "out:Running cost/(Apt SEK y)": 25935,
    "out:Running Cost over RSP/MSEK": 3.647,
    "out:LCP/MSEK": 0.602,
    "out:ROI% old": 16.39,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2266.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4106.02,
    "out:PV (% sold (El))": 16.51,
    "out:PV (kWh self-consumed)": 20763.12,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -32759,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176317,
    "out:DH kr savings": 226772,
    "out:El kr savings": -55690,
    "out:El kr sold": 5235,
    "out:El kr saved": 35297,
    "out:El kr return": 40532,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132800,
    "out:% savings (space heating)": 53.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.36,
    "out:Etvv": 0,
    "out:Ef": 30.62,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.06,
    "out:Electricity (inc PV)": 28.08,
    "out:Total Energy Use Pre PV": 109.06,
    "out:Total Energy Use Post PV": 95.08,
    "out:Primary Energy": 91.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -21.3,
    "out:Total CO2/m2": 17.95,
    "out:Total CO2 (tons)": 39.93,
    "out:Klimatpaverkan": -102.51,
    "out:Initial Cost/MSEK": 4.380625,
    "out:Running cost/(Apt SEK y)": 26424,
    "out:Running Cost over RSP/MSEK": 3.723,
    "out:LCP/MSEK": 0.232,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2086.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21820.7,
    "out:PV (% sold (El))": 43.87,
    "out:PV (kWh self-consumed)": 27917.58,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -27102,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194347,
    "out:DH kr savings": 212599,
    "out:El kr savings": -46073,
    "out:El kr sold": 27821,
    "out:El kr saved": 47460,
    "out:El kr return": 75281,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149984,
    "out:% savings (space heating)": 47.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.53,
    "out:Etvv": 0,
    "out:Ef": 28.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42,
    "out:Electricity (inc PV)": 28,
    "out:Total Energy Use Pre PV": 105,
    "out:Total Energy Use Post PV": 91,
    "out:Primary Energy": 88.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -22.81,
    "out:Total CO2/m2": 16.43,
    "out:Total CO2 (tons)": 36.56,
    "out:Klimatpaverkan": -105.88,
    "out:Initial Cost/MSEK": 4.43625,
    "out:Running cost/(Apt SEK y)": 25111,
    "out:Running Cost over RSP/MSEK": 3.538,
    "out:LCP/MSEK": 0.362,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2082.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21850.56,
    "out:PV (% sold (El))": 43.93,
    "out:PV (kWh self-consumed)": 27887.72,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -26925,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202785,
    "out:DH kr savings": 220698,
    "out:El kr savings": -45772,
    "out:El kr sold": 27859,
    "out:El kr saved": 47409,
    "out:El kr return": 75269,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139828,
    "out:% savings (space heating)": 50.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.07,
    "out:Etvv": 0,
    "out:Ef": 28,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.45,
    "out:Electricity (inc PV)": 27.77,
    "out:Total Energy Use Pre PV": 105.45,
    "out:Total Energy Use Post PV": 91.77,
    "out:Primary Energy": 89.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -23.07,
    "out:Total CO2/m2": 16.6,
    "out:Total CO2 (tons)": 36.93,
    "out:Klimatpaverkan": -105.51,
    "out:Initial Cost/MSEK": 4.4945,
    "out:Running cost/(Apt SEK y)": 25326,
    "out:Running Cost over RSP/MSEK": 3.569,
    "out:LCP/MSEK": 0.273,
    "out:ROI% old": 15.15,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2051.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22091.52,
    "out:PV (% sold (El))": 44.42,
    "out:PV (kWh self-consumed)": 27646.76,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -26422,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201923,
    "out:DH kr savings": 218673,
    "out:El kr savings": -44917,
    "out:El kr sold": 28167,
    "out:El kr saved": 46999,
    "out:El kr return": 75166,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 142882,
    "out:% savings (space heating)": 49.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.8,
    "out:Etvv": 0,
    "out:Ef": 27.77,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 27.69,
    "out:Total Energy Use Pre PV": 101.37,
    "out:Total Energy Use Post PV": 87.69,
    "out:Primary Energy": 86.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -24.59,
    "out:Total CO2/m2": 15.07,
    "out:Total CO2 (tons)": 33.53,
    "out:Klimatpaverkan": -108.91,
    "out:Initial Cost/MSEK": 4.550125,
    "out:Running cost/(Apt SEK y)": 24011,
    "out:Running Cost over RSP/MSEK": 3.384,
    "out:LCP/MSEK": 0.403,
    "out:ROI% old": 15.55,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2047.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22126.17,
    "out:PV (% sold (El))": 44.49,
    "out:PV (kWh self-consumed)": 27612.11,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -26232,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210388,
    "out:DH kr savings": 226772,
    "out:El kr savings": -44595,
    "out:El kr sold": 28211,
    "out:El kr saved": 46941,
    "out:El kr return": 75151,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132800,
    "out:% savings (space heating)": 53.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.36,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.47,
    "out:Electricity (inc PV)": 56.46,
    "out:Total Energy Use Pre PV": 75.47,
    "out:Total Energy Use Post PV": 71.46,
    "out:Primary Energy": 108.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 13.38,
    "out:Total CO2/m2": 28.57,
    "out:Total CO2 (tons)": 63.56,
    "out:Klimatpaverkan": -78.88,
    "out:Initial Cost/MSEK": 4.7335,
    "out:Running cost/(Apt SEK y)": 17581,
    "out:Running Cost over RSP/MSEK": 2.455,
    "out:LCP/MSEK": 1.148,
    "out:ROI% old": 17.75,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4233.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -90246,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164468,
    "out:DH kr savings": 317886,
    "out:El kr savings": -153418,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 34176,
    "out:% savings (space heating)": 88.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.11,
    "out:Etvv": 0,
    "out:Ef": 56.46,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.92,
    "out:Electricity (inc PV)": 55.73,
    "out:Total Energy Use Pre PV": 71.92,
    "out:Total Energy Use Post PV": 67.73,
    "out:Primary Energy": 105.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 12.23,
    "out:Total CO2/m2": 27.41,
    "out:Total CO2 (tons)": 61,
    "out:Klimatpaverkan": -81.44,
    "out:Initial Cost/MSEK": 4.789125,
    "out:Running cost/(Apt SEK y)": 16489,
    "out:Running Cost over RSP/MSEK": 2.301,
    "out:LCP/MSEK": 1.246,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4194.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -88622,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173302,
    "out:DH kr savings": 323960,
    "out:El kr savings": -150657,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27619,
    "out:% savings (space heating)": 90.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.72,
    "out:Etvv": 0,
    "out:Ef": 55.73,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 55.6,
    "out:Total Energy Use Pre PV": 72.1,
    "out:Total Energy Use Post PV": 68.6,
    "out:Primary Energy": 106.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 12.48,
    "out:Total CO2/m2": 28.09,
    "out:Total CO2 (tons)": 62.5,
    "out:Klimatpaverkan": -79.94,
    "out:Initial Cost/MSEK": 4.847375,
    "out:Running cost/(Apt SEK y)": 16643,
    "out:Running Cost over RSP/MSEK": 2.323,
    "out:LCP/MSEK": 1.166,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4137,
    "out:Return %": 16,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -88331,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171773,
    "out:DH kr savings": 321935,
    "out:El kr savings": -150162,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 29740,
    "out:% savings (space heating)": 89.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 55.6,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.49,
    "out:Electricity (inc PV)": 54.83,
    "out:Total Energy Use Pre PV": 69.49,
    "out:Total Energy Use Post PV": 65.83,
    "out:Primary Energy": 103.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 11.68,
    "out:Total CO2/m2": 27.29,
    "out:Total CO2 (tons)": 60.72,
    "out:Klimatpaverkan": -81.72,
    "out:Initial Cost/MSEK": 4.903,
    "out:Running cost/(Apt SEK y)": 15864,
    "out:Running Cost over RSP/MSEK": 2.214,
    "out:LCP/MSEK": 1.22,
    "out:ROI% old": 17.84,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4094,
    "out:Return %": 16,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -86608,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178750,
    "out:DH kr savings": 325985,
    "out:El kr savings": -147234,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23595,
    "out:% savings (space heating)": 91.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.96,
    "out:Etvv": 0,
    "out:Ef": 54.83,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.47,
    "out:Electricity (inc PV)": 45.92,
    "out:Total Energy Use Pre PV": 75.47,
    "out:Total Energy Use Post PV": 60.92,
    "out:Primary Energy": 89.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.54,
    "out:CO2 Operational/m2": 9.98,
    "out:Total CO2/m2": 37.52,
    "out:Total CO2 (tons)": 83.48,
    "out:Klimatpaverkan": -58.96,
    "out:Initial Cost/MSEK": 5.196375,
    "out:Running cost/(Apt SEK y)": 15255,
    "out:Running Cost over RSP/MSEK": 2.136,
    "out:LCP/MSEK": 1.004,
    "out:ROI% old": 17.04,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3519.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 968.52,
    "out:PV (% sold (El))": 3.89,
    "out:PV (kWh self-consumed)": 23900.62,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -66794,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205571,
    "out:DH kr savings": 317886,
    "out:El kr savings": -113550,
    "out:El kr sold": 1235,
    "out:El kr saved": 40631,
    "out:El kr return": 41866,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 34176,
    "out:% savings (space heating)": 88.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.11,
    "out:Etvv": 0,
    "out:Ef": 45.92,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.92,
    "out:Electricity (inc PV)": 45.23,
    "out:Total Energy Use Pre PV": 71.92,
    "out:Total Energy Use Post PV": 57.23,
    "out:Primary Energy": 86.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.54,
    "out:CO2 Operational/m2": 8.71,
    "out:Total CO2/m2": 36.24,
    "out:Total CO2 (tons)": 80.64,
    "out:Klimatpaverkan": -61.8,
    "out:Initial Cost/MSEK": 5.252,
    "out:Running cost/(Apt SEK y)": 14164,
    "out:Running Cost over RSP/MSEK": 1.983,
    "out:LCP/MSEK": 1.101,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3482.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1025.65,
    "out:PV (% sold (El))": 4.12,
    "out:PV (kWh self-consumed)": 23843.49,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -65262,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214322,
    "out:DH kr savings": 323960,
    "out:El kr savings": -110946,
    "out:El kr sold": 1308,
    "out:El kr saved": 40534,
    "out:El kr return": 41842,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27619,
    "out:% savings (space heating)": 90.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.72,
    "out:Etvv": 0,
    "out:Ef": 45.23,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 45.11,
    "out:Total Energy Use Pre PV": 72.1,
    "out:Total Energy Use Post PV": 58.11,
    "out:Primary Energy": 87.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 8.77,
    "out:Total CO2/m2": 36.73,
    "out:Total CO2 (tons)": 81.73,
    "out:Klimatpaverkan": -60.71,
    "out:Initial Cost/MSEK": 5.310125,
    "out:Running cost/(Apt SEK y)": 14320,
    "out:Running Cost over RSP/MSEK": 2.005,
    "out:LCP/MSEK": 1.021,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3427.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 808,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1112.26,
    "out:PV (% sold (El))": 4.47,
    "out:PV (kWh self-consumed)": 23756.88,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -64988,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212874,
    "out:DH kr savings": 321935,
    "out:El kr savings": -110479,
    "out:El kr sold": 1418,
    "out:El kr saved": 40387,
    "out:El kr return": 41805,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 29740,
    "out:% savings (space heating)": 89.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 45.11,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.49,
    "out:Electricity (inc PV)": 44.38,
    "out:Total Energy Use Pre PV": 69.49,
    "out:Total Energy Use Post PV": 55.38,
    "out:Primary Energy": 84.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 7.83,
    "out:Total CO2/m2": 35.79,
    "out:Total CO2 (tons)": 79.63,
    "out:Klimatpaverkan": -62.81,
    "out:Initial Cost/MSEK": 5.36575,
    "out:Running cost/(Apt SEK y)": 13543,
    "out:Running Cost over RSP/MSEK": 1.896,
    "out:LCP/MSEK": 1.075,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3385.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 822,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1179.48,
    "out:PV (% sold (El))": 4.74,
    "out:PV (kWh self-consumed)": 23689.66,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -63367,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 219764,
    "out:DH kr savings": 325985,
    "out:El kr savings": -107724,
    "out:El kr sold": 1504,
    "out:El kr saved": 40272,
    "out:El kr return": 41776,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23595,
    "out:% savings (space heating)": 91.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.96,
    "out:Etvv": 0,
    "out:Ef": 44.38,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.47,
    "out:Electricity (inc PV)": 41.33,
    "out:Total Energy Use Pre PV": 75.47,
    "out:Total Energy Use Post PV": 56.33,
    "out:Primary Energy": 81.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.89,
    "out:CO2 Operational/m2": -21.51,
    "out:Total CO2/m2": 18.39,
    "out:Total CO2 (tons)": 40.91,
    "out:Klimatpaverkan": -101.53,
    "out:Initial Cost/MSEK": 5.659125,
    "out:Running cost/(Apt SEK y)": 13230,
    "out:Running Cost over RSP/MSEK": 1.859,
    "out:LCP/MSEK": 0.819,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3160.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14660.8,
    "out:PV (% sold (El))": 29.48,
    "out:PV (kWh self-consumed)": 35077.48,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -56591,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240374,
    "out:DH kr savings": 317886,
    "out:El kr savings": -96204,
    "out:El kr sold": 18693,
    "out:El kr saved": 59632,
    "out:El kr return": 78324,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 34176,
    "out:% savings (space heating)": 88.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 10.11,
    "out:Etvv": 0,
    "out:Ef": 41.33,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.92,
    "out:Electricity (inc PV)": 40.72,
    "out:Total Energy Use Pre PV": 71.92,
    "out:Total Energy Use Post PV": 52.72,
    "out:Primary Energy": 78.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.89,
    "out:CO2 Operational/m2": -23.09,
    "out:Total CO2/m2": 16.8,
    "out:Total CO2 (tons)": 37.38,
    "out:Klimatpaverkan": -105.06,
    "out:Initial Cost/MSEK": 5.71475,
    "out:Running cost/(Apt SEK y)": 12142,
    "out:Running Cost over RSP/MSEK": 1.706,
    "out:LCP/MSEK": 0.916,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3127.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14853.64,
    "out:PV (% sold (El))": 29.86,
    "out:PV (kWh self-consumed)": 34884.64,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -55233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249002,
    "out:DH kr savings": 323960,
    "out:El kr savings": -93896,
    "out:El kr sold": 18938,
    "out:El kr saved": 59304,
    "out:El kr return": 78242,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27619,
    "out:% savings (space heating)": 90.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.72,
    "out:Etvv": 0,
    "out:Ef": 40.72,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.1,
    "out:Electricity (inc PV)": 40.61,
    "out:Total Energy Use Pre PV": 72.1,
    "out:Total Energy Use Post PV": 53.61,
    "out:Primary Energy": 79.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.31,
    "out:CO2 Operational/m2": -23.48,
    "out:Total CO2/m2": 16.84,
    "out:Total CO2 (tons)": 37.46,
    "out:Klimatpaverkan": -104.98,
    "out:Initial Cost/MSEK": 5.773,
    "out:Running cost/(Apt SEK y)": 12303,
    "out:Running Cost over RSP/MSEK": 1.728,
    "out:LCP/MSEK": 0.835,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3078.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 845,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15138.71,
    "out:PV (% sold (El))": 30.44,
    "out:PV (kWh self-consumed)": 34599.57,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -54990,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247755,
    "out:DH kr savings": 321935,
    "out:El kr savings": -93482,
    "out:El kr sold": 19302,
    "out:El kr saved": 58819,
    "out:El kr return": 78121,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 29740,
    "out:% savings (space heating)": 89.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 40.61,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.49,
    "out:Electricity (inc PV)": 39.97,
    "out:Total Energy Use Pre PV": 69.49,
    "out:Total Energy Use Post PV": 50.97,
    "out:Primary Energy": 76.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.31,
    "out:CO2 Operational/m2": -24.77,
    "out:Total CO2/m2": 15.55,
    "out:Total CO2 (tons)": 34.6,
    "out:Klimatpaverkan": -107.84,
    "out:Initial Cost/MSEK": 5.828625,
    "out:Running cost/(Apt SEK y)": 11529,
    "out:Running Cost over RSP/MSEK": 1.62,
    "out:LCP/MSEK": 0.888,
    "out:ROI% old": 16.46,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3042,
    "out:Return %": 15,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15356.25,
    "out:PV (% sold (El))": 30.87,
    "out:PV (kWh self-consumed)": 34382.03,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -53552,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254526,
    "out:DH kr savings": 325985,
    "out:El kr savings": -91038,
    "out:El kr sold": 19579,
    "out:El kr saved": 58449,
    "out:El kr return": 78029,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 23595,
    "out:% savings (space heating)": 91.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.96,
    "out:Etvv": 0,
    "out:Ef": 39.97,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 55.31,
    "out:Total Energy Use Pre PV": 71.63,
    "out:Total Energy Use Post PV": 68.31,
    "out:Primary Energy": 105.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 12.42,
    "out:Total CO2/m2": 27.61,
    "out:Total CO2 (tons)": 61.42,
    "out:Klimatpaverkan": -81.02,
    "out:Initial Cost/MSEK": 4.781,
    "out:Running cost/(Apt SEK y)": 16544,
    "out:Running Cost over RSP/MSEK": 2.309,
    "out:LCP/MSEK": 1.246,
    "out:ROI% old": 18.01,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4104.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -87685,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172871,
    "out:DH kr savings": 321935,
    "out:El kr savings": -149064,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28336,
    "out:% savings (space heating)": 90.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.9,
    "out:Etvv": 0,
    "out:Ef": 55.31,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 54.52,
    "out:Total Energy Use Pre PV": 68,
    "out:Total Energy Use Post PV": 64.52,
    "out:Primary Energy": 102.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.19,
    "out:CO2 Operational/m2": 11.26,
    "out:Total CO2/m2": 26.44,
    "out:Total CO2 (tons)": 58.83,
    "out:Klimatpaverkan": -83.61,
    "out:Initial Cost/MSEK": 4.836625,
    "out:Running cost/(Apt SEK y)": 15437,
    "out:Running Cost over RSP/MSEK": 2.154,
    "out:LCP/MSEK": 1.346,
    "out:ROI% old": 18.26,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4059.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 788,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -85926,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181934,
    "out:DH kr savings": 328009,
    "out:El kr savings": -146075,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22334,
    "out:% savings (space heating)": 92.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.72,
    "out:Etvv": 0,
    "out:Ef": 54.52,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.49,
    "out:Electricity (inc PV)": 54.6,
    "out:Total Energy Use Pre PV": 68.49,
    "out:Total Energy Use Post PV": 65.6,
    "out:Primary Energy": 104.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 11.55,
    "out:Total CO2/m2": 27.16,
    "out:Total CO2 (tons)": 60.42,
    "out:Klimatpaverkan": -82.02,
    "out:Initial Cost/MSEK": 4.894875,
    "out:Running cost/(Apt SEK y)": 15654,
    "out:Running Cost over RSP/MSEK": 2.184,
    "out:LCP/MSEK": 1.257,
    "out:ROI% old": 17.95,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4024.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -86115,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179588,
    "out:DH kr savings": 325985,
    "out:El kr savings": -146396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25123,
    "out:% savings (space heating)": 91.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.23,
    "out:Etvv": 0,
    "out:Ef": 54.6,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.81,
    "out:Electricity (inc PV)": 53.77,
    "out:Total Energy Use Pre PV": 65.81,
    "out:Total Energy Use Post PV": 62.77,
    "out:Primary Energy": 101.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 10.74,
    "out:Total CO2/m2": 26.35,
    "out:Total CO2 (tons)": 58.62,
    "out:Klimatpaverkan": -83.82,
    "out:Initial Cost/MSEK": 4.9505,
    "out:Running cost/(Apt SEK y)": 14861,
    "out:Running Cost over RSP/MSEK": 2.073,
    "out:LCP/MSEK": 1.313,
    "out:ROI% old": 18.07,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3976.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -84269,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186777,
    "out:DH kr savings": 330034,
    "out:El kr savings": -143257,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19481,
    "out:% savings (space heating)": 93.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.18,
    "out:Etvv": 0,
    "out:Ef": 53.77,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 44.83,
    "out:Total Energy Use Pre PV": 71.63,
    "out:Total Energy Use Post PV": 57.83,
    "out:Primary Energy": 86.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.54,
    "out:CO2 Operational/m2": 8.6,
    "out:Total CO2/m2": 36.14,
    "out:Total CO2 (tons)": 80.41,
    "out:Klimatpaverkan": -62.03,
    "out:Initial Cost/MSEK": 5.243875,
    "out:Running cost/(Apt SEK y)": 14223,
    "out:Running Cost over RSP/MSEK": 1.992,
    "out:LCP/MSEK": 1.101,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3395.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 810,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1163.26,
    "out:PV (% sold (El))": 4.68,
    "out:PV (kWh self-consumed)": 23705.88,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -64380,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213973,
    "out:DH kr savings": 321935,
    "out:El kr savings": -109445,
    "out:El kr sold": 1483,
    "out:El kr saved": 40300,
    "out:El kr return": 41783,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28336,
    "out:% savings (space heating)": 90.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.9,
    "out:Etvv": 0,
    "out:Ef": 44.83,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 44.09,
    "out:Total Energy Use Pre PV": 68,
    "out:Total Energy Use Post PV": 54.09,
    "out:Primary Energy": 84.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.54,
    "out:CO2 Operational/m2": 7.28,
    "out:Total CO2/m2": 34.82,
    "out:Total CO2 (tons)": 77.48,
    "out:Klimatpaverkan": -64.96,
    "out:Initial Cost/MSEK": 5.2995,
    "out:Running cost/(Apt SEK y)": 13117,
    "out:Running Cost over RSP/MSEK": 1.836,
    "out:LCP/MSEK": 1.201,
    "out:ROI% old": 17.52,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3353,
    "out:Return %": 16,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1234.27,
    "out:PV (% sold (El))": 4.96,
    "out:PV (kWh self-consumed)": 23634.87,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -62727,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222948,
    "out:DH kr savings": 328009,
    "out:El kr savings": -106635,
    "out:El kr sold": 1574,
    "out:El kr saved": 40179,
    "out:El kr return": 41753,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22334,
    "out:% savings (space heating)": 92.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.72,
    "out:Etvv": 0,
    "out:Ef": 44.09,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.49,
    "out:Electricity (inc PV)": 44.17,
    "out:Total Energy Use Pre PV": 68.49,
    "out:Total Energy Use Post PV": 55.17,
    "out:Primary Energy": 85.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 7.45,
    "out:Total CO2/m2": 35.41,
    "out:Total CO2 (tons)": 78.79,
    "out:Klimatpaverkan": -63.65,
    "out:Initial Cost/MSEK": 5.357625,
    "out:Running cost/(Apt SEK y)": 13336,
    "out:Running Cost over RSP/MSEK": 1.867,
    "out:LCP/MSEK": 1.112,
    "out:ROI% old": 17.25,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3318.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1293.37,
    "out:PV (% sold (El))": 5.2,
    "out:PV (kWh self-consumed)": 23575.77,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -62904,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220697,
    "out:DH kr savings": 325985,
    "out:El kr savings": -106937,
    "out:El kr sold": 1649,
    "out:El kr saved": 40079,
    "out:El kr return": 41728,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25123,
    "out:% savings (space heating)": 91.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.23,
    "out:Etvv": 0,
    "out:Ef": 44.17,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.81,
    "out:Electricity (inc PV)": 43.39,
    "out:Total Energy Use Pre PV": 65.81,
    "out:Total Energy Use Post PV": 52.39,
    "out:Primary Energy": 82.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 6.46,
    "out:Total CO2/m2": 34.42,
    "out:Total CO2 (tons)": 76.59,
    "out:Klimatpaverkan": -65.85,
    "out:Initial Cost/MSEK": 5.41325,
    "out:Running cost/(Apt SEK y)": 12545,
    "out:Running Cost over RSP/MSEK": 1.756,
    "out:LCP/MSEK": 1.167,
    "out:ROI% old": 17.37,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3273,
    "out:Return %": 16,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1374.93,
    "out:PV (% sold (El))": 5.53,
    "out:PV (kWh self-consumed)": 23494.21,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -61173,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227793,
    "out:DH kr savings": 330034,
    "out:El kr savings": -103994,
    "out:El kr sold": 1753,
    "out:El kr saved": 39940,
    "out:El kr return": 41693,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19481,
    "out:% savings (space heating)": 93.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.18,
    "out:Etvv": 0,
    "out:Ef": 43.39,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 71.63,
    "out:Total Energy Use Post PV": 53.37,
    "out:Primary Energy": 78.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.89,
    "out:CO2 Operational/m2": -23.91,
    "out:Total CO2/m2": 15.98,
    "out:Total CO2 (tons)": 35.56,
    "out:Klimatpaverkan": -106.88,
    "out:Initial Cost/MSEK": 5.706625,
    "out:Running cost/(Apt SEK y)": 12208,
    "out:Running Cost over RSP/MSEK": 1.715,
    "out:LCP/MSEK": 0.914,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3050.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15304.26,
    "out:PV (% sold (El))": 30.77,
    "out:PV (kWh self-consumed)": 34434.02,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -54450,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248883,
    "out:DH kr savings": 321935,
    "out:El kr savings": -92565,
    "out:El kr sold": 19513,
    "out:El kr saved": 58538,
    "out:El kr return": 78051,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28336,
    "out:% savings (space heating)": 90.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.9,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 39.71,
    "out:Total Energy Use Pre PV": 68,
    "out:Total Energy Use Post PV": 49.71,
    "out:Primary Energy": 76.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.89,
    "out:CO2 Operational/m2": -25.58,
    "out:Total CO2/m2": 14.31,
    "out:Total CO2 (tons)": 31.85,
    "out:Klimatpaverkan": -110.59,
    "out:Initial Cost/MSEK": 5.76225,
    "out:Running cost/(Apt SEK y)": 11106,
    "out:Running Cost over RSP/MSEK": 1.56,
    "out:LCP/MSEK": 1.014,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3013,
    "out:Return %": 15,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15529.74,
    "out:PV (% sold (El))": 31.22,
    "out:PV (kWh self-consumed)": 34208.54,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -52983,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257739,
    "out:DH kr savings": 328009,
    "out:El kr savings": -90071,
    "out:El kr sold": 19800,
    "out:El kr saved": 58155,
    "out:El kr return": 77955,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22334,
    "out:% savings (space heating)": 92.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.72,
    "out:Etvv": 0,
    "out:Ef": 39.71,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.49,
    "out:Electricity (inc PV)": 39.78,
    "out:Total Energy Use Pre PV": 68.49,
    "out:Total Energy Use Post PV": 50.78,
    "out:Primary Energy": 77.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.31,
    "out:CO2 Operational/m2": -25.7,
    "out:Total CO2/m2": 14.62,
    "out:Total CO2 (tons)": 32.53,
    "out:Klimatpaverkan": -109.91,
    "out:Initial Cost/MSEK": 5.8205,
    "out:Running cost/(Apt SEK y)": 11328,
    "out:Running Cost over RSP/MSEK": 1.592,
    "out:LCP/MSEK": 0.924,
    "out:ROI% old": 16.55,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2982.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15712.6,
    "out:PV (% sold (El))": 31.59,
    "out:PV (kWh self-consumed)": 34025.68,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -53141,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255679,
    "out:DH kr savings": 325985,
    "out:El kr savings": -90339,
    "out:El kr sold": 20034,
    "out:El kr saved": 57844,
    "out:El kr return": 77877,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25123,
    "out:% savings (space heating)": 91.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.23,
    "out:Etvv": 0,
    "out:Ef": 39.78,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.81,
    "out:Electricity (inc PV)": 39.09,
    "out:Total Energy Use Pre PV": 65.81,
    "out:Total Energy Use Post PV": 48.09,
    "out:Primary Energy": 74.69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.31,
    "out:CO2 Operational/m2": -27.06,
    "out:Total CO2/m2": 13.26,
    "out:Total CO2 (tons)": 29.49,
    "out:Klimatpaverkan": -112.95,
    "out:Initial Cost/MSEK": 5.876125,
    "out:Running cost/(Apt SEK y)": 10540,
    "out:Running Cost over RSP/MSEK": 1.481,
    "out:LCP/MSEK": 0.979,
    "out:ROI% old": 16.67,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2942.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 876,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15958.36,
    "out:PV (% sold (El))": 32.08,
    "out:PV (kWh self-consumed)": 33779.92,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -51602,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262658,
    "out:DH kr savings": 330034,
    "out:El kr savings": -87723,
    "out:El kr sold": 20347,
    "out:El kr saved": 57426,
    "out:El kr return": 77773,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19481,
    "out:% savings (space heating)": 93.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.18,
    "out:Etvv": 0,
    "out:Ef": 39.09,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 170.9,
    "out:Primary Energy": 123.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 57.9,
    "out:Total CO2/m2": 68.45,
    "out:Total CO2 (tons)": 152.29,
    "out:Klimatpaverkan": 9.85,
    "out:Initial Cost/MSEK": 1.686625,
    "out:Running cost/(Apt SEK y)": 53697,
    "out:Running Cost over RSP/MSEK": 7.558,
    "out:LCP/MSEK": -0.909,
    "out:ROI% old": 6.59,
    "out:Payback discounted": 18,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.9,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 99,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 34421,
    "out:DH kr savings": 34421,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 165.9,
    "out:Primary Energy": 120.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 56.1,
    "out:Total CO2/m2": 66.65,
    "out:Total CO2 (tons)": 148.29,
    "out:Klimatpaverkan": 5.85,
    "out:Initial Cost/MSEK": 1.74225,
    "out:Running cost/(Apt SEK y)": 52072,
    "out:Running Cost over RSP/MSEK": 7.33,
    "out:LCP/MSEK": -0.735,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 44544,
    "out:DH kr savings": 44544,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 163.9,
    "out:Primary Energy": 121.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.97,
    "out:CO2 Operational/m2": 55.38,
    "out:Total CO2/m2": 66.35,
    "out:Total CO2 (tons)": 147.63,
    "out:Klimatpaverkan": 5.19,
    "out:Initial Cost/MSEK": 1.8005,
    "out:Running cost/(Apt SEK y)": 51423,
    "out:Running Cost over RSP/MSEK": 7.238,
    "out:LCP/MSEK": -0.702,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48594,
    "out:DH kr savings": 48594,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 158.9,
    "out:Primary Energy": 117.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.97,
    "out:CO2 Operational/m2": 53.58,
    "out:Total CO2/m2": 64.55,
    "out:Total CO2 (tons)": 143.62,
    "out:Klimatpaverkan": 1.18,
    "out:Initial Cost/MSEK": 1.856125,
    "out:Running cost/(Apt SEK y)": 49798,
    "out:Running Cost over RSP/MSEK": 7.009,
    "out:LCP/MSEK": -0.529,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58718,
    "out:DH kr savings": 58718,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 165.73,
    "out:Primary Energy": 114.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 49.8,
    "out:Total CO2 (tons)": 110.82,
    "out:Klimatpaverkan": -31.62,
    "out:Initial Cost/MSEK": 2.1495,
    "out:Running cost/(Apt SEK y)": 51664,
    "out:Running Cost over RSP/MSEK": 7.28,
    "out:LCP/MSEK": -1.093,
    "out:ROI% old": 7.02,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71019,
    "out:DH kr savings": 34421,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 160.73,
    "out:Primary Energy": 111.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 25.1,
    "out:Total CO2/m2": 48.01,
    "out:Total CO2 (tons)": 106.81,
    "out:Klimatpaverkan": -35.63,
    "out:Initial Cost/MSEK": 2.205125,
    "out:Running cost/(Apt SEK y)": 50039,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": -0.92,
    "out:ROI% old": 8.33,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81143,
    "out:DH kr savings": 44544,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 158.73,
    "out:Primary Energy": 111.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 47.71,
    "out:Total CO2 (tons)": 106.15,
    "out:Klimatpaverkan": -36.29,
    "out:Initial Cost/MSEK": 2.26325,
    "out:Running cost/(Apt SEK y)": 49389,
    "out:Running Cost over RSP/MSEK": 6.959,
    "out:LCP/MSEK": -0.886,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 177,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85193,
    "out:DH kr savings": 48594,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 153.73,
    "out:Primary Energy": 108.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 45.91,
    "out:Total CO2 (tons)": 102.14,
    "out:Klimatpaverkan": -40.3,
    "out:Initial Cost/MSEK": 2.318875,
    "out:Running cost/(Apt SEK y)": 47765,
    "out:Running Cost over RSP/MSEK": 6.731,
    "out:LCP/MSEK": -0.713,
    "out:ROI% old": 9.89,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95316,
    "out:DH kr savings": 58718,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 164.88,
    "out:Primary Energy": 113.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -25.78,
    "out:Total CO2/m2": 9.48,
    "out:Total CO2 (tons)": 21.09,
    "out:Klimatpaverkan": -121.35,
    "out:Initial Cost/MSEK": 2.61225,
    "out:Running cost/(Apt SEK y)": 49857,
    "out:Running Cost over RSP/MSEK": 7.033,
    "out:LCP/MSEK": -1.309,
    "out:ROI% old": 7.13,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 6,
    "out:Return/kSEK/y": 169,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 37825,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 103530,
    "out:DH kr savings": 34421,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 165.9,
    "out:Total Energy Use Post PV": 159.88,
    "out:Primary Energy": 109.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -27.58,
    "out:Total CO2/m2": 7.68,
    "out:Total CO2 (tons)": 17.08,
    "out:Klimatpaverkan": -125.36,
    "out:Initial Cost/MSEK": 2.667875,
    "out:Running cost/(Apt SEK y)": 48233,
    "out:Running Cost over RSP/MSEK": 6.804,
    "out:LCP/MSEK": -1.135,
    "out:ROI% old": 8.21,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 48950,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113654,
    "out:DH kr savings": 44544,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 157.88,
    "out:Primary Energy": 110.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": -28.3,
    "out:Total CO2/m2": 7.38,
    "out:Total CO2 (tons)": 16.42,
    "out:Klimatpaverkan": -126.02,
    "out:Initial Cost/MSEK": 2.726125,
    "out:Running cost/(Apt SEK y)": 47583,
    "out:Running Cost over RSP/MSEK": 6.712,
    "out:LCP/MSEK": -1.102,
    "out:ROI% old": 8.51,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 209,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 53400,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117703,
    "out:DH kr savings": 48594,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 152.88,
    "out:Primary Energy": 107.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": -30.1,
    "out:Total CO2/m2": 5.58,
    "out:Total CO2 (tons)": 12.42,
    "out:Klimatpaverkan": -130.02,
    "out:Initial Cost/MSEK": 2.78175,
    "out:Running cost/(Apt SEK y)": 45959,
    "out:Running Cost over RSP/MSEK": 6.483,
    "out:LCP/MSEK": -0.929,
    "out:ROI% old": 9.52,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 239,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127827,
    "out:DH kr savings": 58718,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 120.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 65.21,
    "out:Total CO2 (tons)": 145.09,
    "out:Klimatpaverkan": 2.65,
    "out:Initial Cost/MSEK": 1.734125,
    "out:Running cost/(Apt SEK y)": 50773,
    "out:Running Cost over RSP/MSEK": 7.146,
    "out:LCP/MSEK": -0.544,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 16,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52643,
    "out:DH kr savings": 52643,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 117.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.55,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 63.41,
    "out:Total CO2 (tons)": 141.08,
    "out:Klimatpaverkan": -1.36,
    "out:Initial Cost/MSEK": 1.78975,
    "out:Running cost/(Apt SEK y)": 49148,
    "out:Running Cost over RSP/MSEK": 6.918,
    "out:LCP/MSEK": -0.371,
    "out:ROI% old": 11.32,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62767,
    "out:DH kr savings": 62767,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 118.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.97,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 63.83,
    "out:Total CO2 (tons)": 142.02,
    "out:Klimatpaverkan": -0.42,
    "out:Initial Cost/MSEK": 1.848,
    "out:Running cost/(Apt SEK y)": 49148,
    "out:Running Cost over RSP/MSEK": 6.918,
    "out:LCP/MSEK": -0.429,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62767,
    "out:DH kr savings": 62767,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 114.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.97,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 138.02,
    "out:Klimatpaverkan": -4.42,
    "out:Initial Cost/MSEK": 1.903625,
    "out:Running cost/(Apt SEK y)": 47524,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": -0.256,
    "out:ROI% old": 12.36,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 211,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72891,
    "out:DH kr savings": 72891,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 111.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 46.57,
    "out:Total CO2 (tons)": 103.61,
    "out:Klimatpaverkan": -38.83,
    "out:Initial Cost/MSEK": 2.197,
    "out:Running cost/(Apt SEK y)": 48740,
    "out:Running Cost over RSP/MSEK": 6.868,
    "out:LCP/MSEK": -0.729,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 189,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89242,
    "out:DH kr savings": 52643,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 107.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.9,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 44.77,
    "out:Total CO2 (tons)": 99.6,
    "out:Klimatpaverkan": -42.84,
    "out:Initial Cost/MSEK": 2.252625,
    "out:Running cost/(Apt SEK y)": 47115,
    "out:Running Cost over RSP/MSEK": 6.639,
    "out:LCP/MSEK": -0.555,
    "out:ROI% old": 10.76,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99366,
    "out:DH kr savings": 62767,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 108.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 45.19,
    "out:Total CO2 (tons)": 100.54,
    "out:Klimatpaverkan": -41.9,
    "out:Initial Cost/MSEK": 2.31075,
    "out:Running cost/(Apt SEK y)": 47115,
    "out:Running Cost over RSP/MSEK": 6.639,
    "out:LCP/MSEK": -0.614,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99366,
    "out:DH kr savings": 62767,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 105.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 43.39,
    "out:Total CO2 (tons)": 96.54,
    "out:Klimatpaverkan": -45.9,
    "out:Initial Cost/MSEK": 2.366375,
    "out:Running cost/(Apt SEK y)": 45491,
    "out:Running Cost over RSP/MSEK": 6.41,
    "out:LCP/MSEK": -0.44,
    "out:ROI% old": 11.63,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109490,
    "out:DH kr savings": 72891,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 109.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 6.24,
    "out:Total CO2 (tons)": 13.88,
    "out:Klimatpaverkan": -128.56,
    "out:Initial Cost/MSEK": 2.65975,
    "out:Running cost/(Apt SEK y)": 46934,
    "out:Running Cost over RSP/MSEK": 6.621,
    "out:LCP/MSEK": -0.944,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57850,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121753,
    "out:DH kr savings": 52643,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 106.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.26,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 4.44,
    "out:Total CO2 (tons)": 9.88,
    "out:Klimatpaverkan": -132.56,
    "out:Initial Cost/MSEK": 2.715375,
    "out:Running cost/(Apt SEK y)": 45309,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.771,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131877,
    "out:DH kr savings": 62767,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 107.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 4.86,
    "out:Total CO2 (tons)": 10.82,
    "out:Klimatpaverkan": -131.62,
    "out:Initial Cost/MSEK": 2.773625,
    "out:Running cost/(Apt SEK y)": 45309,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -0.829,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131877,
    "out:DH kr savings": 62767,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 247075,
    "out:% savings (space heating)": 13.36,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 104.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 3.06,
    "out:Total CO2 (tons)": 6.81,
    "out:Klimatpaverkan": -135.63,
    "out:Initial Cost/MSEK": 2.82925,
    "out:Running cost/(Apt SEK y)": 43685,
    "out:Running Cost over RSP/MSEK": 6.163,
    "out:LCP/MSEK": -0.656,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142000,
    "out:DH kr savings": 72891,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 236451,
    "out:% savings (space heating)": 17.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 40.92,
    "out:Total Energy Use Pre PV": 116.06,
    "out:Total Energy Use Post PV": 113.92,
    "out:Primary Energy": 116.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 46.92,
    "out:Total CO2 (tons)": 104.41,
    "out:Klimatpaverkan": -38.03,
    "out:Initial Cost/MSEK": 3.539375,
    "out:Running cost/(Apt SEK y)": 32764,
    "out:Running Cost over RSP/MSEK": 4.6,
    "out:LCP/MSEK": 0.197,
    "out:ROI% old": 15.08,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 3014.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -55680,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105795,
    "out:DH kr savings": 200450,
    "out:El kr savings": -94655,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161330,
    "out:% savings (space heating)": 43.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.05,
    "out:Etvv": 0,
    "out:Ef": 40.92,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 40.84,
    "out:Total Energy Use Pre PV": 111.01,
    "out:Total Energy Use Post PV": 108.84,
    "out:Primary Energy": 113.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 100.39,
    "out:Klimatpaverkan": -42.05,
    "out:Initial Cost/MSEK": 3.595,
    "out:Running cost/(Apt SEK y)": 31129,
    "out:Running Cost over RSP/MSEK": 4.37,
    "out:LCP/MSEK": 0.372,
    "out:ROI% old": 15.76,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 3010.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -55482,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116254,
    "out:DH kr savings": 210574,
    "out:El kr savings": -94320,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151085,
    "out:% savings (space heating)": 47.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.57,
    "out:Etvv": 0,
    "out:Ef": 40.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 40.46,
    "out:Total Energy Use Pre PV": 110.34,
    "out:Total Energy Use Post PV": 108.46,
    "out:Primary Energy": 114.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 30.07,
    "out:Total CO2/m2": 45.45,
    "out:Total CO2 (tons)": 101.13,
    "out:Klimatpaverkan": -41.31,
    "out:Initial Cost/MSEK": 3.65325,
    "out:Running cost/(Apt SEK y)": 30988,
    "out:Running Cost over RSP/MSEK": 4.35,
    "out:LCP/MSEK": 0.333,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2963.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -54645,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117678,
    "out:DH kr savings": 210574,
    "out:El kr savings": -92896,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152123,
    "out:% savings (space heating)": 46.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.84,
    "out:Etvv": 0,
    "out:Ef": 40.46,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 106.27,
    "out:Total Energy Use Post PV": 104.36,
    "out:Primary Energy": 110.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 28.62,
    "out:Total CO2/m2": 44,
    "out:Total CO2 (tons)": 97.91,
    "out:Klimatpaverkan": -44.53,
    "out:Initial Cost/MSEK": 3.708875,
    "out:Running cost/(Apt SEK y)": 29675,
    "out:Running Cost over RSP/MSEK": 4.165,
    "out:LCP/MSEK": 0.462,
    "out:ROI% old": 16.07,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2959.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -54428,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126145,
    "out:DH kr savings": 218673,
    "out:El kr savings": -92528,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141962,
    "out:% savings (space heating)": 50.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.38,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 31.64,
    "out:Total Energy Use Pre PV": 116.06,
    "out:Total Energy Use Post PV": 104.64,
    "out:Primary Energy": 99.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 22.56,
    "out:Total CO2/m2": 49.88,
    "out:Total CO2 (tons)": 110.98,
    "out:Klimatpaverkan": -31.46,
    "out:Initial Cost/MSEK": 4.00225,
    "out:Running cost/(Apt SEK y)": 30503,
    "out:Running Cost over RSP/MSEK": 4.29,
    "out:LCP/MSEK": 0.044,
    "out:ROI% old": 14.44,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2373.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3716.1,
    "out:PV (% sold (El))": 14.94,
    "out:PV (kWh self-consumed)": 21153.04,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -35024,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145648,
    "out:DH kr savings": 200450,
    "out:El kr savings": -59540,
    "out:El kr sold": 4738,
    "out:El kr saved": 35960,
    "out:El kr return": 40698,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161330,
    "out:% savings (space heating)": 43.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.05,
    "out:Etvv": 0,
    "out:Ef": 31.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 31.56,
    "out:Total Energy Use Pre PV": 111.01,
    "out:Total Energy Use Post PV": 99.56,
    "out:Primary Energy": 96.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 20.73,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 106.9,
    "out:Klimatpaverkan": -35.54,
    "out:Initial Cost/MSEK": 4.057875,
    "out:Running cost/(Apt SEK y)": 28868,
    "out:Running Cost over RSP/MSEK": 4.06,
    "out:LCP/MSEK": 0.219,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2370.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3727.75,
    "out:PV (% sold (El))": 14.99,
    "out:PV (kWh self-consumed)": 21141.39,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -34848,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156085,
    "out:DH kr savings": 210574,
    "out:El kr savings": -59242,
    "out:El kr sold": 4753,
    "out:El kr saved": 35940,
    "out:El kr return": 40693,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151085,
    "out:% savings (space heating)": 47.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.57,
    "out:Etvv": 0,
    "out:Ef": 31.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 31.23,
    "out:Total Energy Use Pre PV": 110.34,
    "out:Total Energy Use Post PV": 99.23,
    "out:Primary Energy": 97.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.74,
    "out:CO2 Operational/m2": 20.3,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 106.89,
    "out:Klimatpaverkan": -35.55,
    "out:Initial Cost/MSEK": 4.116,
    "out:Running cost/(Apt SEK y)": 28731,
    "out:Running Cost over RSP/MSEK": 4.041,
    "out:LCP/MSEK": 0.18,
    "out:ROI% old": 14.91,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2327.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 549,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3880.7,
    "out:PV (% sold (El))": 15.6,
    "out:PV (kWh self-consumed)": 20988.44,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -34102,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157548,
    "out:DH kr savings": 210574,
    "out:El kr savings": -57974,
    "out:El kr sold": 4948,
    "out:El kr saved": 35680,
    "out:El kr return": 40628,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152123,
    "out:% savings (space heating)": 46.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.84,
    "out:Etvv": 0,
    "out:Ef": 31.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 31.14,
    "out:Total Energy Use Pre PV": 106.27,
    "out:Total Energy Use Post PV": 95.14,
    "out:Primary Energy": 94.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.74,
    "out:CO2 Operational/m2": 18.82,
    "out:Total CO2/m2": 46.56,
    "out:Total CO2 (tons)": 103.59,
    "out:Klimatpaverkan": -38.85,
    "out:Initial Cost/MSEK": 4.171625,
    "out:Running cost/(Apt SEK y)": 27418,
    "out:Running Cost over RSP/MSEK": 3.856,
    "out:LCP/MSEK": 0.309,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2323.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 572,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3895.21,
    "out:PV (% sold (El))": 15.66,
    "out:PV (kWh self-consumed)": 20973.93,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -33910,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165992,
    "out:DH kr savings": 218673,
    "out:El kr savings": -57647,
    "out:El kr sold": 4966,
    "out:El kr saved": 35656,
    "out:El kr return": 40622,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141962,
    "out:% savings (space heating)": 50.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.38,
    "out:Etvv": 0,
    "out:Ef": 31.14,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 28.6,
    "out:Total Energy Use Pre PV": 116.06,
    "out:Total Energy Use Post PV": 101.6,
    "out:Primary Energy": 94.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -18.03,
    "out:Total CO2/m2": 21.64,
    "out:Total CO2 (tons)": 48.15,
    "out:Klimatpaverkan": -94.29,
    "out:Initial Cost/MSEK": 4.465,
    "out:Running cost/(Apt SEK y)": 28572,
    "out:Running Cost over RSP/MSEK": 4.025,
    "out:LCP/MSEK": -0.154,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2143.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21386.85,
    "out:PV (% sold (El))": 43,
    "out:PV (kWh self-consumed)": 28351.43,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 220275,
    "out:EL kWh savings": -28266,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179666,
    "out:DH kr savings": 200450,
    "out:El kr savings": -48053,
    "out:El kr sold": 27268,
    "out:El kr saved": 48197,
    "out:El kr return": 75466,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161330,
    "out:% savings (space heating)": 43.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 61.05,
    "out:Etvv": 0,
    "out:Ef": 28.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.01,
    "out:Electricity (inc PV)": 28.53,
    "out:Total Energy Use Pre PV": 111.01,
    "out:Total Energy Use Post PV": 96.53,
    "out:Primary Energy": 90.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -19.89,
    "out:Total CO2/m2": 19.78,
    "out:Total CO2 (tons)": 44.01,
    "out:Klimatpaverkan": -98.43,
    "out:Initial Cost/MSEK": 4.520625,
    "out:Running cost/(Apt SEK y)": 26937,
    "out:Running Cost over RSP/MSEK": 3.795,
    "out:LCP/MSEK": 0.021,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2140.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21409.28,
    "out:PV (% sold (El))": 43.04,
    "out:PV (kWh self-consumed)": 28329,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -28109,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190086,
    "out:DH kr savings": 210574,
    "out:El kr savings": -47785,
    "out:El kr sold": 27297,
    "out:El kr saved": 48159,
    "out:El kr return": 75456,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 151085,
    "out:% savings (space heating)": 47.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.57,
    "out:Etvv": 0,
    "out:Ef": 28.53,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 28.23,
    "out:Total Energy Use Pre PV": 110.34,
    "out:Total Energy Use Post PV": 96.23,
    "out:Primary Energy": 92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.09,
    "out:CO2 Operational/m2": -20.63,
    "out:Total CO2/m2": 19.46,
    "out:Total CO2 (tons)": 43.29,
    "out:Klimatpaverkan": -99.15,
    "out:Initial Cost/MSEK": 4.578875,
    "out:Running cost/(Apt SEK y)": 26803,
    "out:Running Cost over RSP/MSEK": 3.776,
    "out:LCP/MSEK": -0.019,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2102.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 584,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21702,
    "out:PV (% sold (El))": 43.63,
    "out:PV (kWh self-consumed)": 28036.28,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231400,
    "out:EL kWh savings": -27439,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191597,
    "out:DH kr savings": 210574,
    "out:El kr savings": -46647,
    "out:El kr sold": 27670,
    "out:El kr saved": 47662,
    "out:El kr return": 75332,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152123,
    "out:% savings (space heating)": 46.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.84,
    "out:Etvv": 0,
    "out:Ef": 28.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 28.15,
    "out:Total Energy Use Pre PV": 106.27,
    "out:Total Energy Use Post PV": 92.15,
    "out:Primary Energy": 88.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.09,
    "out:CO2 Operational/m2": -22.14,
    "out:Total CO2/m2": 17.95,
    "out:Total CO2 (tons)": 39.93,
    "out:Klimatpaverkan": -102.51,
    "out:Initial Cost/MSEK": 4.6345,
    "out:Running cost/(Apt SEK y)": 25491,
    "out:Running Cost over RSP/MSEK": 3.592,
    "out:LCP/MSEK": 0.11,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2098.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21729.54,
    "out:PV (% sold (El))": 43.69,
    "out:PV (kWh self-consumed)": 28008.74,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -27266,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200025,
    "out:DH kr savings": 218673,
    "out:El kr savings": -46353,
    "out:El kr sold": 27705,
    "out:El kr saved": 47615,
    "out:El kr return": 75320,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141962,
    "out:% savings (space heating)": 50.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 54.38,
    "out:Etvv": 0,
    "out:Ef": 28.15,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.09,
    "out:Electricity (inc PV)": 40.3,
    "out:Total Energy Use Pre PV": 109.09,
    "out:Total Energy Use Post PV": 107.3,
    "out:Primary Energy": 113.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 44.64,
    "out:Total CO2 (tons)": 99.32,
    "out:Klimatpaverkan": -43.12,
    "out:Initial Cost/MSEK": 3.586875,
    "out:Running cost/(Apt SEK y)": 30611,
    "out:Running Cost over RSP/MSEK": 4.297,
    "out:LCP/MSEK": 0.453,
    "out:ROI% old": 16.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2946.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -54295,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120298,
    "out:DH kr savings": 212599,
    "out:El kr savings": -92301,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 47.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.11,
    "out:Etvv": 0,
    "out:Ef": 40.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 40.2,
    "out:Total Energy Use Pre PV": 104.02,
    "out:Total Energy Use Post PV": 102.2,
    "out:Primary Energy": 109.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 42.83,
    "out:Total CO2 (tons)": 95.29,
    "out:Klimatpaverkan": -47.15,
    "out:Initial Cost/MSEK": 3.6425,
    "out:Running cost/(Apt SEK y)": 28972,
    "out:Running Cost over RSP/MSEK": 4.066,
    "out:LCP/MSEK": 0.628,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2941.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 544,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244750,
    "out:EL kWh savings": -54071,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130801,
    "out:DH kr savings": 222722,
    "out:El kr savings": -91921,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 138977,
    "out:% savings (space heating)": 51.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 40.2,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 39.92,
    "out:Total Energy Use Pre PV": 105.48,
    "out:Total Energy Use Post PV": 103.92,
    "out:Primary Energy": 111.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 28.51,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 97.67,
    "out:Klimatpaverkan": -44.77,
    "out:Initial Cost/MSEK": 3.70075,
    "out:Running cost/(Apt SEK y)": 29508,
    "out:Running Cost over RSP/MSEK": 4.142,
    "out:LCP/MSEK": 0.494,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2903.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -53444,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127819,
    "out:DH kr savings": 218673,
    "out:El kr savings": -90854,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141977,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.37,
    "out:Etvv": 0,
    "out:Ef": 39.92,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.4,
    "out:Electricity (inc PV)": 39.81,
    "out:Total Energy Use Pre PV": 100.4,
    "out:Total Energy Use Post PV": 98.81,
    "out:Primary Energy": 108.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 26.7,
    "out:Total CO2/m2": 42.09,
    "out:Total CO2 (tons)": 93.65,
    "out:Klimatpaverkan": -48.79,
    "out:Initial Cost/MSEK": 3.756375,
    "out:Running cost/(Apt SEK y)": 27867,
    "out:Running Cost over RSP/MSEK": 3.911,
    "out:LCP/MSEK": 0.669,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2897.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -53207,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138345,
    "out:DH kr savings": 228797,
    "out:El kr savings": -90452,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 131923,
    "out:% savings (space heating)": 53.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.95,
    "out:Etvv": 0,
    "out:Ef": 39.81,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.09,
    "out:Electricity (inc PV)": 31.09,
    "out:Total Energy Use Pre PV": 109.09,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 96.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 19.78,
    "out:Total CO2/m2": 47.1,
    "out:Total CO2 (tons)": 104.79,
    "out:Klimatpaverkan": -37.65,
    "out:Initial Cost/MSEK": 4.04975,
    "out:Running cost/(Apt SEK y)": 28355,
    "out:Running Cost over RSP/MSEK": 3.988,
    "out:LCP/MSEK": 0.299,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2311.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3938.01,
    "out:PV (% sold (El))": 15.83,
    "out:PV (kWh self-consumed)": 20931.13,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -33791,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160174,
    "out:DH kr savings": 212599,
    "out:El kr savings": -57445,
    "out:El kr sold": 5021,
    "out:El kr saved": 35583,
    "out:El kr return": 40604,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 47.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.11,
    "out:Etvv": 0,
    "out:Ef": 31.09,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 31,
    "out:Total Energy Use Pre PV": 104.02,
    "out:Total Energy Use Post PV": 93,
    "out:Primary Energy": 93.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.31,
    "out:CO2 Operational/m2": 17.94,
    "out:Total CO2/m2": 45.25,
    "out:Total CO2 (tons)": 100.69,
    "out:Klimatpaverkan": -41.75,
    "out:Initial Cost/MSEK": 4.105375,
    "out:Running cost/(Apt SEK y)": 26717,
    "out:Running Cost over RSP/MSEK": 3.757,
    "out:LCP/MSEK": 0.474,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 102.2,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2307.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3953.67,
    "out:PV (% sold (El))": 15.9,
    "out:PV (kWh self-consumed)": 20915.47,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244750,
    "out:EL kWh savings": -33593,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170656,
    "out:DH kr savings": 222722,
    "out:El kr savings": -57108,
    "out:El kr sold": 5041,
    "out:El kr saved": 35556,
    "out:El kr return": 40597,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 138977,
    "out:% savings (space heating)": 51.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 31,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 30.75,
    "out:Total Energy Use Pre PV": 105.48,
    "out:Total Energy Use Post PV": 94.75,
    "out:Primary Energy": 94.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.74,
    "out:CO2 Operational/m2": 18.3,
    "out:Total CO2/m2": 46.04,
    "out:Total CO2 (tons)": 102.44,
    "out:Klimatpaverkan": -40,
    "out:Initial Cost/MSEK": 4.1635,
    "out:Running cost/(Apt SEK y)": 27255,
    "out:Running Cost over RSP/MSEK": 3.833,
    "out:LCP/MSEK": 0.34,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2273.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 575,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4081.14,
    "out:PV (% sold (El))": 16.41,
    "out:PV (kWh self-consumed)": 20788,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -33036,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167716,
    "out:DH kr savings": 218673,
    "out:El kr savings": -56161,
    "out:El kr sold": 5203,
    "out:El kr saved": 35340,
    "out:El kr return": 40543,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141977,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.37,
    "out:Etvv": 0,
    "out:Ef": 30.75,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.4,
    "out:Electricity (inc PV)": 30.65,
    "out:Total Energy Use Pre PV": 100.4,
    "out:Total Energy Use Post PV": 89.65,
    "out:Primary Energy": 91.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.74,
    "out:CO2 Operational/m2": 16.45,
    "out:Total CO2/m2": 44.19,
    "out:Total CO2 (tons)": 98.32,
    "out:Klimatpaverkan": -44.12,
    "out:Initial Cost/MSEK": 4.219125,
    "out:Running cost/(Apt SEK y)": 25615,
    "out:Running Cost over RSP/MSEK": 3.602,
    "out:LCP/MSEK": 0.515,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2268.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4099.56,
    "out:PV (% sold (El))": 16.48,
    "out:PV (kWh self-consumed)": 20769.58,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -32826,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178220,
    "out:DH kr savings": 228797,
    "out:El kr savings": -55804,
    "out:El kr sold": 5227,
    "out:El kr saved": 35308,
    "out:El kr return": 40535,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 131923,
    "out:% savings (space heating)": 53.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.95,
    "out:Etvv": 0,
    "out:Ef": 30.65,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.09,
    "out:Electricity (inc PV)": 28.11,
    "out:Total Energy Use Pre PV": 109.09,
    "out:Total Energy Use Post PV": 95.11,
    "out:Primary Energy": 91.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -21.27,
    "out:Total CO2/m2": 18.4,
    "out:Total CO2 (tons)": 40.93,
    "out:Klimatpaverkan": -101.51,
    "out:Initial Cost/MSEK": 4.5125,
    "out:Running cost/(Apt SEK y)": 26428,
    "out:Running Cost over RSP/MSEK": 3.724,
    "out:LCP/MSEK": 0.1,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2088,
    "out:Return %": 13,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21810.65,
    "out:PV (% sold (El))": 43.85,
    "out:PV (kWh self-consumed)": 27927.63,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -27160,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194235,
    "out:DH kr savings": 212599,
    "out:El kr savings": -46172,
    "out:El kr sold": 27809,
    "out:El kr saved": 47477,
    "out:El kr return": 75286,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 47.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 58.11,
    "out:Etvv": 0,
    "out:Ef": 28.11,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 28.03,
    "out:Total Energy Use Pre PV": 104.02,
    "out:Total Energy Use Post PV": 90.03,
    "out:Primary Energy": 88.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.67,
    "out:CO2 Operational/m2": -23.15,
    "out:Total CO2/m2": 16.52,
    "out:Total CO2 (tons)": 36.76,
    "out:Klimatpaverkan": -105.68,
    "out:Initial Cost/MSEK": 4.568125,
    "out:Running cost/(Apt SEK y)": 24790,
    "out:Running Cost over RSP/MSEK": 3.493,
    "out:LCP/MSEK": 0.275,
    "out:ROI% old": 15.15,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2084.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21840.3,
    "out:PV (% sold (El))": 43.91,
    "out:PV (kWh self-consumed)": 27897.98,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244750,
    "out:EL kWh savings": -26982,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204700,
    "out:DH kr savings": 222722,
    "out:El kr savings": -45869,
    "out:El kr sold": 27846,
    "out:El kr saved": 47427,
    "out:El kr return": 75273,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 138977,
    "out:% savings (space heating)": 51.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.66,
    "out:Etvv": 0,
    "out:Ef": 28.03,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 27.8,
    "out:Total Energy Use Pre PV": 105.48,
    "out:Total Energy Use Post PV": 91.8,
    "out:Primary Energy": 89.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.09,
    "out:CO2 Operational/m2": -23.04,
    "out:Total CO2/m2": 17.05,
    "out:Total CO2 (tons)": 37.94,
    "out:Klimatpaverkan": -104.5,
    "out:Initial Cost/MSEK": 4.626375,
    "out:Running cost/(Apt SEK y)": 25332,
    "out:Running Cost over RSP/MSEK": 3.57,
    "out:LCP/MSEK": 0.14,
    "out:ROI% old": 14.72,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2053.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22079.88,
    "out:PV (% sold (El))": 44.39,
    "out:PV (kWh self-consumed)": 27658.4,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 240300,
    "out:EL kWh savings": -26481,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201807,
    "out:DH kr savings": 218673,
    "out:El kr savings": -45018,
    "out:El kr sold": 28152,
    "out:El kr saved": 47019,
    "out:El kr return": 75171,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141977,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 56.37,
    "out:Etvv": 0,
    "out:Ef": 27.8,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.4,
    "out:Electricity (inc PV)": 27.72,
    "out:Total Energy Use Pre PV": 100.4,
    "out:Total Energy Use Post PV": 86.72,
    "out:Primary Energy": 86.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.09,
    "out:CO2 Operational/m2": -24.92,
    "out:Total CO2/m2": 15.17,
    "out:Total CO2 (tons)": 33.74,
    "out:Klimatpaverkan": -108.7,
    "out:Initial Cost/MSEK": 4.682,
    "out:Running cost/(Apt SEK y)": 23692,
    "out:Running Cost over RSP/MSEK": 3.339,
    "out:LCP/MSEK": 0.316,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2049,
    "out:Return %": 14,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22114.16,
    "out:PV (% sold (El))": 44.46,
    "out:PV (kWh self-consumed)": 27624.12,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -26292,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212295,
    "out:DH kr savings": 228797,
    "out:El kr savings": -44697,
    "out:El kr sold": 28196,
    "out:El kr saved": 46961,
    "out:El kr return": 75157,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 131923,
    "out:% savings (space heating)": 53.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.95,
    "out:Etvv": 0,
    "out:Ef": 27.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.51,
    "out:Electricity (inc PV)": 56.48,
    "out:Total Energy Use Pre PV": 75.51,
    "out:Total Energy Use Post PV": 71.48,
    "out:Primary Energy": 108.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 13.39,
    "out:Total CO2/m2": 29,
    "out:Total CO2 (tons)": 64.52,
    "out:Klimatpaverkan": -77.92,
    "out:Initial Cost/MSEK": 4.865375,
    "out:Running cost/(Apt SEK y)": 17588,
    "out:Running Cost over RSP/MSEK": 2.456,
    "out:LCP/MSEK": 1.015,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4235.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -90290,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164392,
    "out:DH kr savings": 317886,
    "out:El kr savings": -153494,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33207,
    "out:% savings (space heating)": 88.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.72,
    "out:Etvv": 0,
    "out:Ef": 56.48,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 55.73,
    "out:Total Energy Use Pre PV": 71.94,
    "out:Total Energy Use Post PV": 67.73,
    "out:Primary Energy": 105.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 12.23,
    "out:Total CO2/m2": 27.84,
    "out:Total CO2 (tons)": 61.95,
    "out:Klimatpaverkan": -80.49,
    "out:Initial Cost/MSEK": 4.921,
    "out:Running cost/(Apt SEK y)": 16495,
    "out:Running Cost over RSP/MSEK": 2.302,
    "out:LCP/MSEK": 1.113,
    "out:ROI% old": 17.52,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 176.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4196.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 769,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -88630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173288,
    "out:DH kr savings": 323960,
    "out:El kr savings": -150672,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 26730,
    "out:% savings (space heating)": 90.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.41,
    "out:Etvv": 0,
    "out:Ef": 55.73,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.13,
    "out:Electricity (inc PV)": 55.61,
    "out:Total Energy Use Pre PV": 72.13,
    "out:Total Energy Use Post PV": 68.61,
    "out:Primary Energy": 106.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.03,
    "out:CO2 Operational/m2": 12.49,
    "out:Total CO2/m2": 28.52,
    "out:Total CO2 (tons)": 63.45,
    "out:Klimatpaverkan": -78.99,
    "out:Initial Cost/MSEK": 4.97925,
    "out:Running cost/(Apt SEK y)": 16649,
    "out:Running Cost over RSP/MSEK": 2.324,
    "out:LCP/MSEK": 1.033,
    "out:ROI% old": 17.25,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4139.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -88363,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171718,
    "out:DH kr savings": 321935,
    "out:El kr savings": -150217,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28804,
    "out:% savings (space heating)": 89.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 55.61,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.51,
    "out:Electricity (inc PV)": 54.82,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 64.82,
    "out:Primary Energy": 103.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.03,
    "out:CO2 Operational/m2": 11.32,
    "out:Total CO2/m2": 27.36,
    "out:Total CO2 (tons)": 60.87,
    "out:Klimatpaverkan": -81.57,
    "out:Initial Cost/MSEK": 5.034875,
    "out:Running cost/(Apt SEK y)": 15544,
    "out:Running Cost over RSP/MSEK": 2.169,
    "out:LCP/MSEK": 1.133,
    "out:ROI% old": 17.5,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4095.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 786,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -86595,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180797,
    "out:DH kr savings": 328009,
    "out:El kr savings": -147212,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22744,
    "out:% savings (space heating)": 92.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.67,
    "out:Etvv": 0,
    "out:Ef": 54.82,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.51,
    "out:Electricity (inc PV)": 45.94,
    "out:Total Energy Use Pre PV": 75.51,
    "out:Total Energy Use Post PV": 60.94,
    "out:Primary Energy": 89.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 9.99,
    "out:Total CO2/m2": 37.96,
    "out:Total CO2 (tons)": 84.45,
    "out:Klimatpaverkan": -57.99,
    "out:Initial Cost/MSEK": 5.32825,
    "out:Running cost/(Apt SEK y)": 15262,
    "out:Running Cost over RSP/MSEK": 2.137,
    "out:LCP/MSEK": 0.871,
    "out:ROI% old": 16.62,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3522.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 964.97,
    "out:PV (% sold (El))": 3.88,
    "out:PV (kWh self-consumed)": 23904.17,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -66836,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205495,
    "out:DH kr savings": 317886,
    "out:El kr savings": -113621,
    "out:El kr sold": 1230,
    "out:El kr saved": 40637,
    "out:El kr return": 41867,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33207,
    "out:% savings (space heating)": 88.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.72,
    "out:Etvv": 0,
    "out:Ef": 45.94,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 45.23,
    "out:Total Energy Use Pre PV": 71.94,
    "out:Total Energy Use Post PV": 57.23,
    "out:Primary Energy": 86.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 8.72,
    "out:Total CO2/m2": 36.68,
    "out:Total CO2 (tons)": 81.61,
    "out:Klimatpaverkan": -60.83,
    "out:Initial Cost/MSEK": 5.383875,
    "out:Running cost/(Apt SEK y)": 14170,
    "out:Running Cost over RSP/MSEK": 1.984,
    "out:LCP/MSEK": 0.969,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3484.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1022.67,
    "out:PV (% sold (El))": 4.11,
    "out:PV (kWh self-consumed)": 23846.47,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -65270,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214305,
    "out:DH kr savings": 323960,
    "out:El kr savings": -110959,
    "out:El kr sold": 1304,
    "out:El kr saved": 40539,
    "out:El kr return": 41843,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 26730,
    "out:% savings (space heating)": 90.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.41,
    "out:Etvv": 0,
    "out:Ef": 45.23,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.13,
    "out:Electricity (inc PV)": 45.12,
    "out:Total Energy Use Pre PV": 72.13,
    "out:Total Energy Use Post PV": 58.12,
    "out:Primary Energy": 87.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.39,
    "out:CO2 Operational/m2": 8.78,
    "out:Total CO2/m2": 37.17,
    "out:Total CO2 (tons)": 82.7,
    "out:Klimatpaverkan": -59.74,
    "out:Initial Cost/MSEK": 5.442,
    "out:Running cost/(Apt SEK y)": 14327,
    "out:Running Cost over RSP/MSEK": 2.006,
    "out:LCP/MSEK": 0.888,
    "out:ROI% old": 16.62,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3429.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 808,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1108.94,
    "out:PV (% sold (El))": 4.46,
    "out:PV (kWh self-consumed)": 23760.2,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -65018,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212818,
    "out:DH kr savings": 321935,
    "out:El kr savings": -110531,
    "out:El kr sold": 1414,
    "out:El kr saved": 40392,
    "out:El kr return": 41806,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28804,
    "out:% savings (space heating)": 89.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 45.12,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.51,
    "out:Electricity (inc PV)": 44.37,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 54.37,
    "out:Primary Energy": 84.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.39,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 35.86,
    "out:Total CO2 (tons)": 79.79,
    "out:Klimatpaverkan": -62.65,
    "out:Initial Cost/MSEK": 5.497625,
    "out:Running cost/(Apt SEK y)": 13223,
    "out:Running Cost over RSP/MSEK": 1.851,
    "out:LCP/MSEK": 0.988,
    "out:ROI% old": 16.85,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3387.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1176.86,
    "out:PV (% sold (El))": 4.73,
    "out:PV (kWh self-consumed)": 23692.28,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -63355,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221806,
    "out:DH kr savings": 328009,
    "out:El kr savings": -107704,
    "out:El kr sold": 1500,
    "out:El kr saved": 40277,
    "out:El kr return": 41777,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22744,
    "out:% savings (space heating)": 92.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.67,
    "out:Etvv": 0,
    "out:Ef": 44.37,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.51,
    "out:Electricity (inc PV)": 41.35,
    "out:Total Energy Use Pre PV": 75.51,
    "out:Total Energy Use Post PV": 56.35,
    "out:Primary Energy": 81.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.32,
    "out:CO2 Operational/m2": -21.47,
    "out:Total CO2/m2": 18.84,
    "out:Total CO2 (tons)": 41.92,
    "out:Klimatpaverkan": -100.52,
    "out:Initial Cost/MSEK": 5.791,
    "out:Running cost/(Apt SEK y)": 13237,
    "out:Running Cost over RSP/MSEK": 1.86,
    "out:LCP/MSEK": 0.686,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3162.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14648.69,
    "out:PV (% sold (El))": 29.45,
    "out:PV (kWh self-consumed)": 35089.59,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 349325,
    "out:EL kWh savings": -56628,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240295,
    "out:DH kr savings": 317886,
    "out:El kr savings": -96268,
    "out:El kr sold": 18677,
    "out:El kr saved": 59652,
    "out:El kr return": 78329,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 33207,
    "out:% savings (space heating)": 88.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 9.72,
    "out:Etvv": 0,
    "out:Ef": 41.35,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 40.73,
    "out:Total Energy Use Pre PV": 71.94,
    "out:Total Energy Use Post PV": 52.73,
    "out:Primary Energy": 78.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.32,
    "out:CO2 Operational/m2": -23.07,
    "out:Total CO2/m2": 17.25,
    "out:Total CO2 (tons)": 38.38,
    "out:Klimatpaverkan": -104.06,
    "out:Initial Cost/MSEK": 5.846625,
    "out:Running cost/(Apt SEK y)": 12148,
    "out:Running Cost over RSP/MSEK": 1.706,
    "out:LCP/MSEK": 0.783,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3129,
    "out:Return %": 14,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14843.73,
    "out:PV (% sold (El))": 29.84,
    "out:PV (kWh self-consumed)": 34894.55,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -55240,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248978,
    "out:DH kr savings": 323960,
    "out:El kr savings": -93908,
    "out:El kr sold": 18926,
    "out:El kr saved": 59321,
    "out:El kr return": 78246,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 26730,
    "out:% savings (space heating)": 90.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.41,
    "out:Etvv": 0,
    "out:Ef": 40.73,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.13,
    "out:Electricity (inc PV)": 40.63,
    "out:Total Energy Use Pre PV": 72.13,
    "out:Total Energy Use Post PV": 53.63,
    "out:Primary Energy": 79.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.74,
    "out:CO2 Operational/m2": -23.45,
    "out:Total CO2/m2": 17.29,
    "out:Total CO2 (tons)": 38.47,
    "out:Klimatpaverkan": -103.97,
    "out:Initial Cost/MSEK": 5.904875,
    "out:Running cost/(Apt SEK y)": 12309,
    "out:Running Cost over RSP/MSEK": 1.729,
    "out:LCP/MSEK": 0.702,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3080.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 844,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15127.88,
    "out:PV (% sold (El))": 30.41,
    "out:PV (kWh self-consumed)": 34610.4,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 353775,
    "out:EL kWh savings": -55017,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247695,
    "out:DH kr savings": 321935,
    "out:El kr savings": -93529,
    "out:El kr sold": 19288,
    "out:El kr saved": 58838,
    "out:El kr return": 78126,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 28804,
    "out:% savings (space heating)": 89.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.83,
    "out:Etvv": 0,
    "out:Ef": 40.63,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.51,
    "out:Electricity (inc PV)": 39.96,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 49.96,
    "out:Primary Energy": 76.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.74,
    "out:CO2 Operational/m2": -25.1,
    "out:Total CO2/m2": 15.64,
    "out:Total CO2 (tons)": 34.79,
    "out:Klimatpaverkan": -107.65,
    "out:Initial Cost/MSEK": 5.9605,
    "out:Running cost/(Apt SEK y)": 11209,
    "out:Running Cost over RSP/MSEK": 1.574,
    "out:LCP/MSEK": 0.801,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3043.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 864,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15347.88,
    "out:PV (% sold (El))": 30.86,
    "out:PV (kWh self-consumed)": 34390.4,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -53541,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256558,
    "out:DH kr savings": 328009,
    "out:El kr savings": -91020,
    "out:El kr sold": 19569,
    "out:El kr saved": 58464,
    "out:El kr return": 78032,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 22744,
    "out:% savings (space heating)": 92.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.67,
    "out:Etvv": 0,
    "out:Ef": 39.96,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 55.32,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 67.32,
    "out:Primary Energy": 105.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 12.06,
    "out:Total CO2/m2": 27.67,
    "out:Total CO2 (tons)": 61.57,
    "out:Klimatpaverkan": -80.87,
    "out:Initial Cost/MSEK": 4.912875,
    "out:Running cost/(Apt SEK y)": 16226,
    "out:Running Cost over RSP/MSEK": 2.265,
    "out:LCP/MSEK": 1.159,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4106.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 774,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -87712,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174849,
    "out:DH kr savings": 323960,
    "out:El kr savings": -149111,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27410,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.54,
    "out:Etvv": 0,
    "out:Ef": 55.32,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 54.51,
    "out:Total Energy Use Pre PV": 68.02,
    "out:Total Energy Use Post PV": 64.51,
    "out:Primary Energy": 102.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.61,
    "out:CO2 Operational/m2": 11.26,
    "out:Total CO2/m2": 26.87,
    "out:Total CO2 (tons)": 59.78,
    "out:Klimatpaverkan": -82.66,
    "out:Initial Cost/MSEK": 4.9685,
    "out:Running cost/(Apt SEK y)": 15441,
    "out:Running Cost over RSP/MSEK": 2.154,
    "out:LCP/MSEK": 1.214,
    "out:ROI% old": 17.78,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4061.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 788,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -85907,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181968,
    "out:DH kr savings": 328009,
    "out:El kr savings": -146041,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21500,
    "out:% savings (space heating)": 92.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.45,
    "out:Etvv": 0,
    "out:Ef": 54.51,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 54.61,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 65.61,
    "out:Primary Energy": 103.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.03,
    "out:CO2 Operational/m2": 11.55,
    "out:Total CO2/m2": 27.58,
    "out:Total CO2 (tons)": 61.37,
    "out:Klimatpaverkan": -81.07,
    "out:Initial Cost/MSEK": 5.02675,
    "out:Running cost/(Apt SEK y)": 15660,
    "out:Running Cost over RSP/MSEK": 2.185,
    "out:LCP/MSEK": 1.124,
    "out:ROI% old": 17.48,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4026,
    "out:Return %": 16,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -86128,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179568,
    "out:DH kr savings": 325985,
    "out:El kr savings": -146417,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 24226,
    "out:% savings (space heating)": 91.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.89,
    "out:Etvv": 0,
    "out:Ef": 54.61,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 53.76,
    "out:Total Energy Use Pre PV": 64.82,
    "out:Total Energy Use Post PV": 61.76,
    "out:Primary Energy": 100.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.03,
    "out:CO2 Operational/m2": 10.38,
    "out:Total CO2/m2": 26.41,
    "out:Total CO2 (tons)": 58.77,
    "out:Klimatpaverkan": -83.67,
    "out:Initial Cost/MSEK": 5.082375,
    "out:Running cost/(Apt SEK y)": 14539,
    "out:Running Cost over RSP/MSEK": 2.028,
    "out:LCP/MSEK": 1.226,
    "out:ROI% old": 17.73,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3977.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -84233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188863,
    "out:DH kr savings": 332059,
    "out:El kr savings": -143196,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18700,
    "out:% savings (space heating)": 93.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 53.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 44.85,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 56.85,
    "out:Primary Energy": 86.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 8.25,
    "out:Total CO2/m2": 36.21,
    "out:Total CO2 (tons)": 80.58,
    "out:Klimatpaverkan": -61.86,
    "out:Initial Cost/MSEK": 5.37575,
    "out:Running cost/(Apt SEK y)": 13904,
    "out:Running Cost over RSP/MSEK": 1.947,
    "out:LCP/MSEK": 1.014,
    "out:ROI% old": 16.98,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3397.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1160.04,
    "out:PV (% sold (El))": 4.66,
    "out:PV (kWh self-consumed)": 23709.1,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -64405,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215950,
    "out:DH kr savings": 323960,
    "out:El kr savings": -109489,
    "out:El kr sold": 1479,
    "out:El kr saved": 40305,
    "out:El kr return": 41785,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27410,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.54,
    "out:Etvv": 0,
    "out:Ef": 44.85,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 44.08,
    "out:Total Energy Use Pre PV": 68.02,
    "out:Total Energy Use Post PV": 54.08,
    "out:Primary Energy": 83.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.96,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 35.25,
    "out:Total CO2 (tons)": 78.44,
    "out:Klimatpaverkan": -64,
    "out:Initial Cost/MSEK": 5.431375,
    "out:Running cost/(Apt SEK y)": 13121,
    "out:Running Cost over RSP/MSEK": 1.837,
    "out:LCP/MSEK": 1.068,
    "out:ROI% old": 17.1,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3354.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1231.87,
    "out:PV (% sold (El))": 4.95,
    "out:PV (kWh self-consumed)": 23637.27,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -62708,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222976,
    "out:DH kr savings": 328009,
    "out:El kr savings": -106604,
    "out:El kr sold": 1571,
    "out:El kr saved": 40183,
    "out:El kr return": 41754,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21500,
    "out:% savings (space heating)": 92.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.45,
    "out:Etvv": 0,
    "out:Ef": 44.08,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 44.18,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 55.18,
    "out:Primary Energy": 85.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.39,
    "out:CO2 Operational/m2": 7.46,
    "out:Total CO2/m2": 35.84,
    "out:Total CO2 (tons)": 79.75,
    "out:Klimatpaverkan": -62.69,
    "out:Initial Cost/MSEK": 5.4895,
    "out:Running cost/(Apt SEK y)": 13341,
    "out:Running Cost over RSP/MSEK": 1.868,
    "out:LCP/MSEK": 0.979,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3320.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1290.39,
    "out:PV (% sold (El))": 5.19,
    "out:PV (kWh self-consumed)": 23578.75,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -62916,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220673,
    "out:DH kr savings": 325985,
    "out:El kr savings": -106956,
    "out:El kr sold": 1645,
    "out:El kr saved": 40084,
    "out:El kr return": 41729,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 24226,
    "out:% savings (space heating)": 91.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.89,
    "out:Etvv": 0,
    "out:Ef": 44.18,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 43.38,
    "out:Total Energy Use Pre PV": 64.82,
    "out:Total Energy Use Post PV": 51.38,
    "out:Primary Energy": 82.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.39,
    "out:CO2 Operational/m2": 6.11,
    "out:Total CO2/m2": 34.49,
    "out:Total CO2 (tons)": 76.75,
    "out:Klimatpaverkan": -65.69,
    "out:Initial Cost/MSEK": 5.545125,
    "out:Running cost/(Apt SEK y)": 12223,
    "out:Running Cost over RSP/MSEK": 1.71,
    "out:LCP/MSEK": 1.081,
    "out:ROI% old": 17.07,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3273.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1373.25,
    "out:PV (% sold (El))": 5.52,
    "out:PV (kWh self-consumed)": 23495.89,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -61140,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229873,
    "out:DH kr savings": 332059,
    "out:El kr savings": -103937,
    "out:El kr sold": 1751,
    "out:El kr saved": 39943,
    "out:El kr return": 41694,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18700,
    "out:% savings (space heating)": 93.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 43.38,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 40.38,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 52.38,
    "out:Primary Energy": 78.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.32,
    "out:CO2 Operational/m2": -24.24,
    "out:Total CO2/m2": 16.07,
    "out:Total CO2 (tons)": 35.76,
    "out:Klimatpaverkan": -106.68,
    "out:Initial Cost/MSEK": 5.8385,
    "out:Running cost/(Apt SEK y)": 11889,
    "out:Running Cost over RSP/MSEK": 1.67,
    "out:LCP/MSEK": 0.828,
    "out:ROI% old": 16.31,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3052.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15293.89,
    "out:PV (% sold (El))": 30.75,
    "out:PV (kWh self-consumed)": 34444.39,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 356000,
    "out:EL kWh savings": -54473,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250856,
    "out:DH kr savings": 323960,
    "out:El kr savings": -92604,
    "out:El kr sold": 19500,
    "out:El kr saved": 58555,
    "out:El kr return": 78055,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 27410,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 8.54,
    "out:Etvv": 0,
    "out:Ef": 40.38,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 39.71,
    "out:Total Energy Use Pre PV": 68.02,
    "out:Total Energy Use Post PV": 49.71,
    "out:Primary Energy": 75.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.32,
    "out:CO2 Operational/m2": -25.56,
    "out:Total CO2/m2": 14.76,
    "out:Total CO2 (tons)": 32.84,
    "out:Klimatpaverkan": -109.6,
    "out:Initial Cost/MSEK": 5.894125,
    "out:Running cost/(Apt SEK y)": 11110,
    "out:Running Cost over RSP/MSEK": 1.561,
    "out:LCP/MSEK": 0.881,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3014.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15522.21,
    "out:PV (% sold (El))": 31.21,
    "out:PV (kWh self-consumed)": 34216.07,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -52967,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257757,
    "out:DH kr savings": 328009,
    "out:El kr savings": -90043,
    "out:El kr sold": 19791,
    "out:El kr saved": 58167,
    "out:El kr return": 77958,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21500,
    "out:% savings (space heating)": 92.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.45,
    "out:Etvv": 0,
    "out:Ef": 39.71,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 39.79,
    "out:Total Energy Use Pre PV": 68.51,
    "out:Total Energy Use Post PV": 50.79,
    "out:Primary Energy": 77.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.74,
    "out:CO2 Operational/m2": -25.67,
    "out:Total CO2/m2": 15.07,
    "out:Total CO2 (tons)": 33.52,
    "out:Klimatpaverkan": -108.92,
    "out:Initial Cost/MSEK": 5.952375,
    "out:Running cost/(Apt SEK y)": 11333,
    "out:Running Cost over RSP/MSEK": 1.592,
    "out:LCP/MSEK": 0.792,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2984.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15703.53,
    "out:PV (% sold (El))": 31.57,
    "out:PV (kWh self-consumed)": 34034.75,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -53151,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255650,
    "out:DH kr savings": 325985,
    "out:El kr savings": -90356,
    "out:El kr sold": 20022,
    "out:El kr saved": 57859,
    "out:El kr return": 77881,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 24226,
    "out:% savings (space heating)": 91.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 7.89,
    "out:Etvv": 0,
    "out:Ef": 39.79,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 39.08,
    "out:Total Energy Use Pre PV": 64.82,
    "out:Total Energy Use Post PV": 47.08,
    "out:Primary Energy": 74.49,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.74,
    "out:CO2 Operational/m2": -27.41,
    "out:Total CO2/m2": 13.33,
    "out:Total CO2 (tons)": 29.67,
    "out:Klimatpaverkan": -112.77,
    "out:Initial Cost/MSEK": 6.008,
    "out:Running cost/(Apt SEK y)": 10218,
    "out:Running Cost over RSP/MSEK": 1.436,
    "out:LCP/MSEK": 0.893,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2943.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 882,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15953.37,
    "out:PV (% sold (El))": 32.07,
    "out:PV (kWh self-consumed)": 33784.91,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -51572,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264727,
    "out:DH kr savings": 332059,
    "out:El kr savings": -87673,
    "out:El kr sold": 20341,
    "out:El kr saved": 57434,
    "out:El kr return": 77775,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18700,
    "out:% savings (space heating)": 93.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 39.08,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 114.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.45,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 66.31,
    "out:Total CO2 (tons)": 147.53,
    "out:Klimatpaverkan": 5.09,
    "out:Initial Cost/MSEK": 2.1375,
    "out:Running cost/(Apt SEK y)": 49148,
    "out:Running Cost over RSP/MSEK": 6.918,
    "out:LCP/MSEK": -0.719,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62767,
    "out:DH kr savings": 62767,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 111.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.45,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 64.87,
    "out:Total CO2 (tons)": 144.33,
    "out:Klimatpaverkan": 1.89,
    "out:Initial Cost/MSEK": 2.193125,
    "out:Running cost/(Apt SEK y)": 47849,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -0.591,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 205,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70866,
    "out:DH kr savings": 70866,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 111.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 64.57,
    "out:Total CO2 (tons)": 143.66,
    "out:Klimatpaverkan": 1.22,
    "out:Initial Cost/MSEK": 2.25125,
    "out:Running cost/(Apt SEK y)": 47199,
    "out:Running Cost over RSP/MSEK": 6.643,
    "out:LCP/MSEK": -0.558,
    "out:ROI% old": 10.74,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74916,
    "out:DH kr savings": 74916,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 109.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 63.13,
    "out:Total CO2 (tons)": 140.46,
    "out:Klimatpaverkan": -1.98,
    "out:Initial Cost/MSEK": 2.306875,
    "out:Running cost/(Apt SEK y)": 45900,
    "out:Running Cost over RSP/MSEK": 6.46,
    "out:LCP/MSEK": -0.431,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83015,
    "out:DH kr savings": 83015,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 105.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.8,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 47.66,
    "out:Total CO2 (tons)": 106.05,
    "out:Klimatpaverkan": -36.39,
    "out:Initial Cost/MSEK": 2.60025,
    "out:Running cost/(Apt SEK y)": 47115,
    "out:Running Cost over RSP/MSEK": 6.639,
    "out:LCP/MSEK": -0.903,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99366,
    "out:DH kr savings": 62767,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 102.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.8,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 46.22,
    "out:Total CO2 (tons)": 102.85,
    "out:Klimatpaverkan": -39.59,
    "out:Initial Cost/MSEK": 2.655875,
    "out:Running cost/(Apt SEK y)": 45816,
    "out:Running Cost over RSP/MSEK": 6.456,
    "out:LCP/MSEK": -0.776,
    "out:ROI% old": 10.11,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107465,
    "out:DH kr savings": 70866,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 102.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.22,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 45.93,
    "out:Total CO2 (tons)": 102.19,
    "out:Klimatpaverkan": -40.25,
    "out:Initial Cost/MSEK": 2.714125,
    "out:Running cost/(Apt SEK y)": 45166,
    "out:Running Cost over RSP/MSEK": 6.364,
    "out:LCP/MSEK": -0.742,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111514,
    "out:DH kr savings": 74916,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 99.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.22,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 44.49,
    "out:Total CO2 (tons)": 98.98,
    "out:Klimatpaverkan": -43.46,
    "out:Initial Cost/MSEK": 2.76975,
    "out:Running cost/(Apt SEK y)": 43866,
    "out:Running Cost over RSP/MSEK": 6.181,
    "out:LCP/MSEK": -0.615,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119613,
    "out:DH kr savings": 83015,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 103.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.16,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 7.34,
    "out:Total CO2 (tons)": 16.32,
    "out:Klimatpaverkan": -126.12,
    "out:Initial Cost/MSEK": 3.063125,
    "out:Running cost/(Apt SEK y)": 45309,
    "out:Running Cost over RSP/MSEK": 6.392,
    "out:LCP/MSEK": -1.119,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 250,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68975,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131877,
    "out:DH kr savings": 62767,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 100.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.16,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 5.9,
    "out:Total CO2 (tons)": 13.12,
    "out:Klimatpaverkan": -129.32,
    "out:Initial Cost/MSEK": 3.11875,
    "out:Running cost/(Apt SEK y)": 44010,
    "out:Running Cost over RSP/MSEK": 6.209,
    "out:LCP/MSEK": -0.991,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139976,
    "out:DH kr savings": 70866,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 101.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.58,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 5.6,
    "out:Total CO2 (tons)": 12.46,
    "out:Klimatpaverkan": -129.98,
    "out:Initial Cost/MSEK": 3.177,
    "out:Running cost/(Apt SEK y)": 43360,
    "out:Running Cost over RSP/MSEK": 6.117,
    "out:LCP/MSEK": -0.958,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144025,
    "out:DH kr savings": 74916,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 98.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.58,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 4.16,
    "out:Total CO2 (tons)": 9.26,
    "out:Klimatpaverkan": -133.18,
    "out:Initial Cost/MSEK": 3.232625,
    "out:Running cost/(Apt SEK y)": 42060,
    "out:Running Cost over RSP/MSEK": 5.934,
    "out:LCP/MSEK": -0.831,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152124,
    "out:DH kr savings": 83015,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 111.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.45,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 63.43,
    "out:Total CO2 (tons)": 141.12,
    "out:Klimatpaverkan": -1.32,
    "out:Initial Cost/MSEK": 2.185,
    "out:Running cost/(Apt SEK y)": 46550,
    "out:Running Cost over RSP/MSEK": 6.552,
    "out:LCP/MSEK": -0.4,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78965,
    "out:DH kr savings": 78965,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 108.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.45,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 61.63,
    "out:Total CO2 (tons)": 137.12,
    "out:Klimatpaverkan": -5.32,
    "out:Initial Cost/MSEK": 2.240625,
    "out:Running cost/(Apt SEK y)": 44925,
    "out:Running Cost over RSP/MSEK": 6.323,
    "out:LCP/MSEK": -0.227,
    "out:ROI% old": 12.84,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 257,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89089,
    "out:DH kr savings": 89089,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 108.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 61.69,
    "out:Total CO2 (tons)": 137.26,
    "out:Klimatpaverkan": -5.18,
    "out:Initial Cost/MSEK": 2.298875,
    "out:Running cost/(Apt SEK y)": 44600,
    "out:Running Cost over RSP/MSEK": 6.277,
    "out:LCP/MSEK": -0.24,
    "out:ROI% old": 12.8,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91114,
    "out:DH kr savings": 91114,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 106.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 60.25,
    "out:Total CO2 (tons)": 134.05,
    "out:Klimatpaverkan": -8.39,
    "out:Initial Cost/MSEK": 2.3545,
    "out:Running cost/(Apt SEK y)": 43301,
    "out:Running Cost over RSP/MSEK": 6.094,
    "out:LCP/MSEK": -0.112,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99213,
    "out:DH kr savings": 99213,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 101.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.8,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 44.78,
    "out:Total CO2 (tons)": 99.64,
    "out:Klimatpaverkan": -42.8,
    "out:Initial Cost/MSEK": 2.64775,
    "out:Running cost/(Apt SEK y)": 44516,
    "out:Running Cost over RSP/MSEK": 6.273,
    "out:LCP/MSEK": -0.584,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115564,
    "out:DH kr savings": 78965,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 98.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.8,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 42.98,
    "out:Total CO2 (tons)": 95.64,
    "out:Klimatpaverkan": -46.8,
    "out:Initial Cost/MSEK": 2.703375,
    "out:Running cost/(Apt SEK y)": 42892,
    "out:Running Cost over RSP/MSEK": 6.044,
    "out:LCP/MSEK": -0.411,
    "out:ROI% old": 12.11,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125688,
    "out:DH kr savings": 89089,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 99.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.22,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 43.05,
    "out:Total CO2 (tons)": 95.78,
    "out:Klimatpaverkan": -46.66,
    "out:Initial Cost/MSEK": 2.761625,
    "out:Running cost/(Apt SEK y)": 42567,
    "out:Running Cost over RSP/MSEK": 5.998,
    "out:LCP/MSEK": -0.424,
    "out:ROI% old": 12.09,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127712,
    "out:DH kr savings": 91114,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 96.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.22,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 41.61,
    "out:Total CO2 (tons)": 92.57,
    "out:Klimatpaverkan": -49.87,
    "out:Initial Cost/MSEK": 2.81725,
    "out:Running cost/(Apt SEK y)": 41268,
    "out:Running Cost over RSP/MSEK": 5.815,
    "out:LCP/MSEK": -0.296,
    "out:ROI% old": 12.78,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135811,
    "out:DH kr savings": 99213,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 100.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.16,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 4.46,
    "out:Total CO2 (tons)": 9.92,
    "out:Klimatpaverkan": -132.52,
    "out:Initial Cost/MSEK": 3.110625,
    "out:Running cost/(Apt SEK y)": 42710,
    "out:Running Cost over RSP/MSEK": 6.026,
    "out:LCP/MSEK": -0.8,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148075,
    "out:DH kr savings": 78965,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 97.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.16,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 2.66,
    "out:Total CO2 (tons)": 5.91,
    "out:Klimatpaverkan": -136.53,
    "out:Initial Cost/MSEK": 3.16625,
    "out:Running cost/(Apt SEK y)": 41086,
    "out:Running Cost over RSP/MSEK": 5.797,
    "out:LCP/MSEK": -0.627,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158198,
    "out:DH kr savings": 89089,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.8,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 98.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.58,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 2.72,
    "out:Total CO2 (tons)": 6.05,
    "out:Klimatpaverkan": -136.39,
    "out:Initial Cost/MSEK": 3.2245,
    "out:Running cost/(Apt SEK y)": 40761,
    "out:Running Cost over RSP/MSEK": 5.751,
    "out:LCP/MSEK": -0.639,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160223,
    "out:DH kr savings": 91114,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 217595,
    "out:% savings (space heating)": 23.7,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 95.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.58,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 1.28,
    "out:Total CO2 (tons)": 2.85,
    "out:Klimatpaverkan": -139.59,
    "out:Initial Cost/MSEK": 3.280125,
    "out:Running cost/(Apt SEK y)": 39461,
    "out:Running Cost over RSP/MSEK": 5.568,
    "out:LCP/MSEK": -0.512,
    "out:ROI% old": 12.06,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168322,
    "out:DH kr savings": 99213,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208312,
    "out:% savings (space heating)": 26.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.76,
    "out:Electricity (inc PV)": 40.46,
    "out:Total Energy Use Pre PV": 102.76,
    "out:Total Energy Use Post PV": 100.46,
    "out:Primary Energy": 107.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 27.24,
    "out:Total CO2/m2": 45.1,
    "out:Total CO2 (tons)": 100.36,
    "out:Klimatpaverkan": -42.08,
    "out:Initial Cost/MSEK": 3.99025,
    "out:Running cost/(Apt SEK y)": 28478,
    "out:Running Cost over RSP/MSEK": 3.996,
    "out:LCP/MSEK": 0.35,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2993.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -54655,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133858,
    "out:DH kr savings": 226772,
    "out:El kr savings": -92914,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 134030,
    "out:% savings (space heating)": 53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.32,
    "out:Etvv": 0,
    "out:Ef": 40.46,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.71,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 98.71,
    "out:Total Energy Use Post PV": 96.37,
    "out:Primary Energy": 104.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 25.8,
    "out:Total CO2/m2": 43.66,
    "out:Total CO2 (tons)": 97.13,
    "out:Klimatpaverkan": -45.31,
    "out:Initial Cost/MSEK": 4.045875,
    "out:Running cost/(Apt SEK y)": 27167,
    "out:Running Cost over RSP/MSEK": 3.812,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2989.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -54452,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142302,
    "out:DH kr savings": 234871,
    "out:El kr savings": -92569,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125160,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.45,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 39.97,
    "out:Total Energy Use Pre PV": 97.98,
    "out:Total Energy Use Post PV": 95.97,
    "out:Primary Energy": 104.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.28,
    "out:CO2 Operational/m2": 25.7,
    "out:Total CO2/m2": 43.98,
    "out:Total CO2 (tons)": 97.86,
    "out:Klimatpaverkan": -44.58,
    "out:Initial Cost/MSEK": 4.104125,
    "out:Running cost/(Apt SEK y)": 27015,
    "out:Running Cost over RSP/MSEK": 3.79,
    "out:LCP/MSEK": 0.442,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2938.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -53552,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143832,
    "out:DH kr savings": 234871,
    "out:El kr savings": -91039,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125245,
    "out:% savings (space heating)": 56.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 47.21,
    "out:Etvv": 0,
    "out:Ef": 39.97,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 39.87,
    "out:Total Energy Use Pre PV": 93.91,
    "out:Total Energy Use Post PV": 91.87,
    "out:Primary Energy": 102.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.28,
    "out:CO2 Operational/m2": 24.25,
    "out:Total CO2/m2": 42.53,
    "out:Total CO2 (tons)": 94.64,
    "out:Klimatpaverkan": -47.8,
    "out:Initial Cost/MSEK": 4.15975,
    "out:Running cost/(Apt SEK y)": 25701,
    "out:Running Cost over RSP/MSEK": 3.605,
    "out:LCP/MSEK": 0.571,
    "out:ROI% old": 16.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2934,
    "out:Return %": 15,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -53331,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152307,
    "out:DH kr savings": 242970,
    "out:El kr savings": -90663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116458,
    "out:% savings (space heating)": 59.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.36,
    "out:Etvv": 0,
    "out:Ef": 39.87,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.76,
    "out:Electricity (inc PV)": 31.23,
    "out:Total Energy Use Pre PV": 102.76,
    "out:Total Energy Use Post PV": 91.23,
    "out:Primary Energy": 90.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.21,
    "out:CO2 Operational/m2": 17.69,
    "out:Total CO2/m2": 47.91,
    "out:Total CO2 (tons)": 106.59,
    "out:Klimatpaverkan": -35.85,
    "out:Initial Cost/MSEK": 4.453,
    "out:Running cost/(Apt SEK y)": 26219,
    "out:Running Cost over RSP/MSEK": 3.687,
    "out:LCP/MSEK": 0.197,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2354.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3783.26,
    "out:PV (% sold (El))": 15.21,
    "out:PV (kWh self-consumed)": 21085.88,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -34112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173605,
    "out:DH kr savings": 226772,
    "out:El kr savings": -57990,
    "out:El kr sold": 4824,
    "out:El kr saved": 35846,
    "out:El kr return": 40670,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 134030,
    "out:% savings (space heating)": 53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.32,
    "out:Etvv": 0,
    "out:Ef": 31.23,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.71,
    "out:Electricity (inc PV)": 31.15,
    "out:Total Energy Use Pre PV": 98.71,
    "out:Total Energy Use Post PV": 87.15,
    "out:Primary Energy": 87.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.21,
    "out:CO2 Operational/m2": 16.22,
    "out:Total CO2/m2": 46.43,
    "out:Total CO2 (tons)": 103.31,
    "out:Klimatpaverkan": -39.13,
    "out:Initial Cost/MSEK": 4.508625,
    "out:Running cost/(Apt SEK y)": 24908,
    "out:Running Cost over RSP/MSEK": 3.502,
    "out:LCP/MSEK": 0.326,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2351.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3796.07,
    "out:PV (% sold (El))": 15.26,
    "out:PV (kWh self-consumed)": 21073.07,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -33931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182028,
    "out:DH kr savings": 234871,
    "out:El kr savings": -57683,
    "out:El kr sold": 4840,
    "out:El kr saved": 35824,
    "out:El kr return": 40664,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125160,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.45,
    "out:Etvv": 0,
    "out:Ef": 31.15,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 30.79,
    "out:Total Energy Use Pre PV": 97.98,
    "out:Total Energy Use Post PV": 86.79,
    "out:Primary Energy": 88.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.64,
    "out:CO2 Operational/m2": 15.75,
    "out:Total CO2/m2": 46.39,
    "out:Total CO2 (tons)": 103.21,
    "out:Klimatpaverkan": -39.23,
    "out:Initial Cost/MSEK": 4.566875,
    "out:Running cost/(Apt SEK y)": 24760,
    "out:Running Cost over RSP/MSEK": 3.481,
    "out:LCP/MSEK": 0.288,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2305.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 620,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3962.65,
    "out:PV (% sold (El))": 15.93,
    "out:PV (kWh self-consumed)": 20906.49,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -33132,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183598,
    "out:DH kr savings": 234871,
    "out:El kr savings": -56325,
    "out:El kr sold": 5052,
    "out:El kr saved": 35541,
    "out:El kr return": 40593,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125245,
    "out:% savings (space heating)": 56.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 47.21,
    "out:Etvv": 0,
    "out:Ef": 30.79,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 30.7,
    "out:Total Energy Use Pre PV": 93.91,
    "out:Total Energy Use Post PV": 82.7,
    "out:Primary Energy": 85.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.64,
    "out:CO2 Operational/m2": 14.27,
    "out:Total CO2/m2": 44.9,
    "out:Total CO2 (tons)": 99.91,
    "out:Klimatpaverkan": -42.53,
    "out:Initial Cost/MSEK": 4.6225,
    "out:Running cost/(Apt SEK y)": 23446,
    "out:Running Cost over RSP/MSEK": 3.296,
    "out:LCP/MSEK": 0.417,
    "out:ROI% old": 15.58,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2300.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3978.39,
    "out:PV (% sold (El))": 16,
    "out:PV (kWh self-consumed)": 20890.75,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -32936,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192051,
    "out:DH kr savings": 242970,
    "out:El kr savings": -55991,
    "out:El kr sold": 5072,
    "out:El kr saved": 35514,
    "out:El kr return": 40587,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116458,
    "out:% savings (space heating)": 59.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.36,
    "out:Etvv": 0,
    "out:Ef": 30.7,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.76,
    "out:Electricity (inc PV)": 28.24,
    "out:Total Energy Use Pre PV": 102.76,
    "out:Total Energy Use Post PV": 88.24,
    "out:Primary Energy": 85.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.57,
    "out:CO2 Operational/m2": -23.04,
    "out:Total CO2/m2": 19.53,
    "out:Total CO2 (tons)": 43.45,
    "out:Klimatpaverkan": -98.99,
    "out:Initial Cost/MSEK": 4.915875,
    "out:Running cost/(Apt SEK y)": 24289,
    "out:Running Cost over RSP/MSEK": 3.422,
    "out:LCP/MSEK": -0.002,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2126.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21515.84,
    "out:PV (% sold (El))": 43.26,
    "out:PV (kWh self-consumed)": 28222.44,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 249200,
    "out:EL kWh savings": -27448,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207543,
    "out:DH kr savings": 226772,
    "out:El kr savings": -46661,
    "out:El kr sold": 27433,
    "out:El kr saved": 47978,
    "out:El kr return": 75411,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 134030,
    "out:% savings (space heating)": 53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.32,
    "out:Etvv": 0,
    "out:Ef": 28.24,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.71,
    "out:Electricity (inc PV)": 28.16,
    "out:Total Energy Use Pre PV": 98.71,
    "out:Total Energy Use Post PV": 84.16,
    "out:Primary Energy": 82.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.57,
    "out:CO2 Operational/m2": -24.54,
    "out:Total CO2/m2": 18.03,
    "out:Total CO2 (tons)": 40.11,
    "out:Klimatpaverkan": -102.33,
    "out:Initial Cost/MSEK": 4.9715,
    "out:Running cost/(Apt SEK y)": 22978,
    "out:Running Cost over RSP/MSEK": 3.238,
    "out:LCP/MSEK": 0.127,
    "out:ROI% old": 14.65,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2123.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21540.4,
    "out:PV (% sold (El))": 43.31,
    "out:PV (kWh self-consumed)": 28197.88,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -27286,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215949,
    "out:DH kr savings": 234871,
    "out:El kr savings": -46386,
    "out:El kr sold": 27464,
    "out:El kr saved": 47936,
    "out:El kr return": 75400,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125160,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.45,
    "out:Etvv": 0,
    "out:Ef": 28.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 27.84,
    "out:Total Energy Use Pre PV": 97.98,
    "out:Total Energy Use Post PV": 83.84,
    "out:Primary Energy": 83.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.99,
    "out:CO2 Operational/m2": -25.35,
    "out:Total CO2/m2": 17.64,
    "out:Total CO2 (tons)": 39.25,
    "out:Klimatpaverkan": -103.19,
    "out:Initial Cost/MSEK": 5.02975,
    "out:Running cost/(Apt SEK y)": 22834,
    "out:Running Cost over RSP/MSEK": 3.217,
    "out:LCP/MSEK": 0.089,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2081.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21857.3,
    "out:PV (% sold (El))": 43.94,
    "out:PV (kWh self-consumed)": 27880.98,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -26568,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217574,
    "out:DH kr savings": 234871,
    "out:El kr savings": -45165,
    "out:El kr sold": 27868,
    "out:El kr saved": 47398,
    "out:El kr return": 75266,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125245,
    "out:% savings (space heating)": 56.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 47.21,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 27.76,
    "out:Total Energy Use Pre PV": 93.91,
    "out:Total Energy Use Post PV": 79.76,
    "out:Primary Energy": 80.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.99,
    "out:CO2 Operational/m2": -26.87,
    "out:Total CO2/m2": 16.12,
    "out:Total CO2 (tons)": 35.87,
    "out:Klimatpaverkan": -106.57,
    "out:Initial Cost/MSEK": 5.085375,
    "out:Running cost/(Apt SEK y)": 21520,
    "out:Running Cost over RSP/MSEK": 3.033,
    "out:LCP/MSEK": 0.218,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2078.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21887.02,
    "out:PV (% sold (El))": 44,
    "out:PV (kWh self-consumed)": 27851.26,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -26392,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226010,
    "out:DH kr savings": 242970,
    "out:El kr savings": -44866,
    "out:El kr sold": 27906,
    "out:El kr saved": 47347,
    "out:El kr return": 75253,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116458,
    "out:% savings (space heating)": 59.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.36,
    "out:Etvv": 0,
    "out:Ef": 27.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.71,
    "out:Electricity (inc PV)": 39.8,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 94.8,
    "out:Primary Energy": 104.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 25.31,
    "out:Total CO2/m2": 43.17,
    "out:Total CO2 (tons)": 96.04,
    "out:Klimatpaverkan": -46.4,
    "out:Initial Cost/MSEK": 4.03775,
    "out:Running cost/(Apt SEK y)": 26634,
    "out:Running Cost over RSP/MSEK": 3.737,
    "out:LCP/MSEK": 0.562,
    "out:ROI% old": 16.27,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2920,
    "out:Return %": 15,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -53180,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146489,
    "out:DH kr savings": 236896,
    "out:El kr savings": -90407,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 122370,
    "out:% savings (space heating)": 57.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.51,
    "out:Etvv": 0,
    "out:Ef": 39.8,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.64,
    "out:Electricity (inc PV)": 39.7,
    "out:Total Energy Use Pre PV": 92.64,
    "out:Total Energy Use Post PV": 90.7,
    "out:Primary Energy": 101.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.86,
    "out:CO2 Operational/m2": 23.86,
    "out:Total CO2/m2": 41.72,
    "out:Total CO2 (tons)": 92.82,
    "out:Klimatpaverkan": -49.62,
    "out:Initial Cost/MSEK": 4.093375,
    "out:Running cost/(Apt SEK y)": 25319,
    "out:Running Cost over RSP/MSEK": 3.552,
    "out:LCP/MSEK": 0.691,
    "out:ROI% old": 16.7,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2914.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -52951,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154978,
    "out:DH kr savings": 244995,
    "out:El kr savings": -90017,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113615,
    "out:% savings (space heating)": 60.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.68,
    "out:Etvv": 0,
    "out:Ef": 39.7,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.06,
    "out:Electricity (inc PV)": 39.39,
    "out:Total Energy Use Pre PV": 93.06,
    "out:Total Energy Use Post PV": 91.39,
    "out:Primary Energy": 102.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.28,
    "out:CO2 Operational/m2": 24.14,
    "out:Total CO2/m2": 42.42,
    "out:Total CO2 (tons)": 94.39,
    "out:Klimatpaverkan": -48.05,
    "out:Initial Cost/MSEK": 4.151625,
    "out:Running cost/(Apt SEK y)": 25521,
    "out:Running Cost over RSP/MSEK": 3.58,
    "out:LCP/MSEK": 0.604,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2874,
    "out:Return %": 15,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -52275,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154102,
    "out:DH kr savings": 242970,
    "out:El kr savings": -88867,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115581,
    "out:% savings (space heating)": 59.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 44.85,
    "out:Etvv": 0,
    "out:Ef": 39.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.97,
    "out:Electricity (inc PV)": 39.28,
    "out:Total Energy Use Pre PV": 88.97,
    "out:Total Energy Use Post PV": 87.28,
    "out:Primary Energy": 99.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.28,
    "out:CO2 Operational/m2": 22.69,
    "out:Total CO2/m2": 40.97,
    "out:Total CO2 (tons)": 91.16,
    "out:Klimatpaverkan": -51.28,
    "out:Initial Cost/MSEK": 4.20725,
    "out:Running cost/(Apt SEK y)": 24204,
    "out:Running Cost over RSP/MSEK": 3.395,
    "out:LCP/MSEK": 0.734,
    "out:ROI% old": 16.78,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2868.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -52028,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162621,
    "out:DH kr savings": 251069,
    "out:El kr savings": -88448,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 106900,
    "out:% savings (space heating)": 62.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.05,
    "out:Etvv": 0,
    "out:Ef": 39.28,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.71,
    "out:Electricity (inc PV)": 30.64,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 85.64,
    "out:Primary Energy": 87.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.21,
    "out:CO2 Operational/m2": 15.22,
    "out:Total CO2/m2": 45.43,
    "out:Total CO2 (tons)": 101.08,
    "out:Klimatpaverkan": -41.36,
    "out:Initial Cost/MSEK": 4.5005,
    "out:Running cost/(Apt SEK y)": 24380,
    "out:Running Cost over RSP/MSEK": 3.428,
    "out:LCP/MSEK": 0.408,
    "out:ROI% old": 15.58,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2288.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4025.1,
    "out:PV (% sold (El))": 16.19,
    "out:PV (kWh self-consumed)": 20844.04,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -32802,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186264,
    "out:DH kr savings": 236896,
    "out:El kr savings": -55764,
    "out:El kr sold": 5132,
    "out:El kr saved": 35435,
    "out:El kr return": 40567,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 122370,
    "out:% savings (space heating)": 57.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.51,
    "out:Etvv": 0,
    "out:Ef": 30.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.64,
    "out:Electricity (inc PV)": 30.55,
    "out:Total Energy Use Pre PV": 92.64,
    "out:Total Energy Use Post PV": 81.55,
    "out:Primary Energy": 84.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.21,
    "out:CO2 Operational/m2": 13.73,
    "out:Total CO2/m2": 43.94,
    "out:Total CO2 (tons)": 97.78,
    "out:Klimatpaverkan": -44.66,
    "out:Initial Cost/MSEK": 4.556125,
    "out:Running cost/(Apt SEK y)": 23066,
    "out:Running Cost over RSP/MSEK": 3.243,
    "out:LCP/MSEK": 0.537,
    "out:ROI% old": 15.97,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2283.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4042.08,
    "out:PV (% sold (El))": 16.25,
    "out:PV (kWh self-consumed)": 20827.06,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -32599,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194730,
    "out:DH kr savings": 244995,
    "out:El kr savings": -55419,
    "out:El kr sold": 5154,
    "out:El kr saved": 35406,
    "out:El kr return": 40560,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113615,
    "out:% savings (space heating)": 60.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.68,
    "out:Etvv": 0,
    "out:Ef": 30.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.06,
    "out:Electricity (inc PV)": 30.28,
    "out:Total Energy Use Pre PV": 93.06,
    "out:Total Energy Use Post PV": 82.28,
    "out:Primary Energy": 85.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.64,
    "out:CO2 Operational/m2": 13.71,
    "out:Total CO2/m2": 44.34,
    "out:Total CO2 (tons)": 98.66,
    "out:Klimatpaverkan": -43.78,
    "out:Initial Cost/MSEK": 4.614375,
    "out:Running cost/(Apt SEK y)": 23271,
    "out:Running Cost over RSP/MSEK": 3.272,
    "out:LCP/MSEK": 0.45,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2246.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4181.4,
    "out:PV (% sold (El))": 16.81,
    "out:PV (kWh self-consumed)": 20687.74,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -32001,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193900,
    "out:DH kr savings": 242970,
    "out:El kr savings": -54402,
    "out:El kr sold": 5331,
    "out:El kr saved": 35169,
    "out:El kr return": 40500,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115581,
    "out:% savings (space heating)": 59.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 44.85,
    "out:Etvv": 0,
    "out:Ef": 30.28,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.97,
    "out:Electricity (inc PV)": 30.18,
    "out:Total Energy Use Pre PV": 88.97,
    "out:Total Energy Use Post PV": 78.18,
    "out:Primary Energy": 83.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.64,
    "out:CO2 Operational/m2": 12.21,
    "out:Total CO2/m2": 42.85,
    "out:Total CO2 (tons)": 95.33,
    "out:Klimatpaverkan": -47.11,
    "out:Initial Cost/MSEK": 4.67,
    "out:Running cost/(Apt SEK y)": 21955,
    "out:Running Cost over RSP/MSEK": 3.087,
    "out:LCP/MSEK": 0.58,
    "out:ROI% old": 16.06,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2241.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4201.19,
    "out:PV (% sold (El))": 16.89,
    "out:PV (kWh self-consumed)": 20667.95,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -31783,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202394,
    "out:DH kr savings": 251069,
    "out:El kr savings": -54031,
    "out:El kr sold": 5357,
    "out:El kr saved": 35136,
    "out:El kr return": 40492,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 106900,
    "out:% savings (space heating)": 62.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.05,
    "out:Etvv": 0,
    "out:Ef": 30.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.71,
    "out:Electricity (inc PV)": 27.71,
    "out:Total Energy Use Pre PV": 96.71,
    "out:Total Energy Use Post PV": 82.71,
    "out:Primary Energy": 82.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.57,
    "out:CO2 Operational/m2": -26.01,
    "out:Total CO2/m2": 16.56,
    "out:Total CO2 (tons)": 36.84,
    "out:Klimatpaverkan": -105.6,
    "out:Initial Cost/MSEK": 4.963375,
    "out:Running cost/(Apt SEK y)": 22455,
    "out:Running Cost over RSP/MSEK": 3.164,
    "out:LCP/MSEK": 0.209,
    "out:ROI% old": 14.89,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2066.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21975.01,
    "out:PV (% sold (El))": 44.18,
    "out:PV (kWh self-consumed)": 27763.27,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -26271,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220252,
    "out:DH kr savings": 236896,
    "out:El kr savings": -44661,
    "out:El kr sold": 28018,
    "out:El kr saved": 47198,
    "out:El kr return": 75216,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 122370,
    "out:% savings (space heating)": 57.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.51,
    "out:Etvv": 0,
    "out:Ef": 27.71,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.64,
    "out:Electricity (inc PV)": 27.63,
    "out:Total Energy Use Pre PV": 92.64,
    "out:Total Energy Use Post PV": 78.63,
    "out:Primary Energy": 79.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.57,
    "out:CO2 Operational/m2": -27.53,
    "out:Total CO2/m2": 15.04,
    "out:Total CO2 (tons)": 33.45,
    "out:Klimatpaverkan": -108.99,
    "out:Initial Cost/MSEK": 5.019,
    "out:Running cost/(Apt SEK y)": 21141,
    "out:Running Cost over RSP/MSEK": 2.979,
    "out:LCP/MSEK": 0.338,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2062.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22006.85,
    "out:PV (% sold (El))": 44.25,
    "out:PV (kWh self-consumed)": 27731.43,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -26089,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228702,
    "out:DH kr savings": 244995,
    "out:El kr savings": -44351,
    "out:El kr sold": 28059,
    "out:El kr saved": 47143,
    "out:El kr return": 75202,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113615,
    "out:% savings (space heating)": 60.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.68,
    "out:Etvv": 0,
    "out:Ef": 27.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.06,
    "out:Electricity (inc PV)": 27.38,
    "out:Total Energy Use Pre PV": 93.06,
    "out:Total Energy Use Post PV": 79.38,
    "out:Primary Energy": 80.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.99,
    "out:CO2 Operational/m2": -27.83,
    "out:Total CO2/m2": 15.16,
    "out:Total CO2 (tons)": 33.72,
    "out:Klimatpaverkan": -108.72,
    "out:Initial Cost/MSEK": 5.07725,
    "out:Running cost/(Apt SEK y)": 21349,
    "out:Running Cost over RSP/MSEK": 3.009,
    "out:LCP/MSEK": 0.25,
    "out:ROI% old": 14.99,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2029.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22266.14,
    "out:PV (% sold (El))": 44.77,
    "out:PV (kWh self-consumed)": 27472.14,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -25551,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227922,
    "out:DH kr savings": 242970,
    "out:El kr savings": -43437,
    "out:El kr sold": 28389,
    "out:El kr saved": 46703,
    "out:El kr return": 75092,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115581,
    "out:% savings (space heating)": 59.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 44.85,
    "out:Etvv": 0,
    "out:Ef": 27.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.97,
    "out:Electricity (inc PV)": 27.3,
    "out:Total Energy Use Pre PV": 88.97,
    "out:Total Energy Use Post PV": 75.3,
    "out:Primary Energy": 77.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.99,
    "out:CO2 Operational/m2": -29.36,
    "out:Total CO2/m2": 13.62,
    "out:Total CO2 (tons)": 30.31,
    "out:Klimatpaverkan": -112.13,
    "out:Initial Cost/MSEK": 5.132875,
    "out:Running cost/(Apt SEK y)": 20033,
    "out:Running Cost over RSP/MSEK": 2.823,
    "out:LCP/MSEK": 0.38,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2025,
    "out:Return %": 14,
    "out:Return/kSEK/y": 705,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22302.68,
    "out:PV (% sold (El))": 44.84,
    "out:PV (kWh self-consumed)": 27435.6,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -25355,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236401,
    "out:DH kr savings": 251069,
    "out:El kr savings": -43104,
    "out:El kr sold": 28436,
    "out:El kr saved": 46641,
    "out:El kr return": 75076,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 106900,
    "out:% savings (space heating)": 62.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.05,
    "out:Etvv": 0,
    "out:Ef": 27.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 53.99,
    "out:Total Energy Use Pre PV": 66.56,
    "out:Total Energy Use Post PV": 61.99,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.51,
    "out:CO2 Operational/m2": 10.61,
    "out:Total CO2/m2": 29.12,
    "out:Total CO2 (tons)": 64.79,
    "out:Klimatpaverkan": -77.65,
    "out:Initial Cost/MSEK": 5.31625,
    "out:Running cost/(Apt SEK y)": 14904,
    "out:Running Cost over RSP/MSEK": 2.078,
    "out:LCP/MSEK": 0.942,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4099.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -84742,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187998,
    "out:DH kr savings": 332059,
    "out:El kr savings": -144061,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18049,
    "out:% savings (space heating)": 93.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.7,
    "out:Etvv": 0,
    "out:Ef": 53.99,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.88,
    "out:Electricity (inc PV)": 53.1,
    "out:Total Energy Use Pre PV": 63.88,
    "out:Total Energy Use Post PV": 59.1,
    "out:Primary Energy": 98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.51,
    "out:CO2 Operational/m2": 9.8,
    "out:Total CO2/m2": 28.31,
    "out:Total CO2 (tons)": 62.99,
    "out:Klimatpaverkan": -79.45,
    "out:Initial Cost/MSEK": 5.371875,
    "out:Running cost/(Apt SEK y)": 14113,
    "out:Running Cost over RSP/MSEK": 1.967,
    "out:LCP/MSEK": 0.997,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4051.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -82763,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195412,
    "out:DH kr savings": 336108,
    "out:El kr savings": -140696,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13725,
    "out:% savings (space heating)": 95.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.46,
    "out:Etvv": 0,
    "out:Ef": 53.1,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.01,
    "out:Electricity (inc PV)": 53,
    "out:Total Energy Use Pre PV": 64.01,
    "out:Total Energy Use Post PV": 60,
    "out:Primary Energy": 98.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.93,
    "out:CO2 Operational/m2": 10.05,
    "out:Total CO2/m2": 28.98,
    "out:Total CO2 (tons)": 64.47,
    "out:Klimatpaverkan": -77.97,
    "out:Initial Cost/MSEK": 5.430125,
    "out:Running cost/(Apt SEK y)": 14254,
    "out:Running Cost over RSP/MSEK": 1.987,
    "out:LCP/MSEK": 0.919,
    "out:ROI% old": 16.7,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3990.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -82546,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193756,
    "out:DH kr savings": 334084,
    "out:El kr savings": -140328,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 14914,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.21,
    "out:Etvv": 0,
    "out:Ef": 53,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 52.08,
    "out:Total Energy Use Pre PV": 61.25,
    "out:Total Energy Use Post PV": 57.08,
    "out:Primary Energy": 95.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.93,
    "out:CO2 Operational/m2": 9.23,
    "out:Total CO2/m2": 28.16,
    "out:Total CO2 (tons)": 62.65,
    "out:Klimatpaverkan": -79.79,
    "out:Initial Cost/MSEK": 5.48575,
    "out:Running cost/(Apt SEK y)": 13444,
    "out:Running Cost over RSP/MSEK": 1.874,
    "out:LCP/MSEK": 0.977,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3937.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -80502,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201279,
    "out:DH kr savings": 338133,
    "out:El kr savings": -136854,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11206,
    "out:% savings (space heating)": 96.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.06,
    "out:Etvv": 0,
    "out:Ef": 52.08,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 43.59,
    "out:Total Energy Use Pre PV": 66.56,
    "out:Total Energy Use Post PV": 51.59,
    "out:Primary Energy": 81.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.86,
    "out:CO2 Operational/m2": 6.77,
    "out:Total CO2/m2": 37.63,
    "out:Total CO2 (tons)": 83.74,
    "out:Klimatpaverkan": -58.7,
    "out:Initial Cost/MSEK": 5.779,
    "out:Running cost/(Apt SEK y)": 12583,
    "out:Running Cost over RSP/MSEK": 1.761,
    "out:LCP/MSEK": 0.797,
    "out:ROI% old": 16.26,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3390.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1171.47,
    "out:PV (% sold (El))": 4.71,
    "out:PV (kWh self-consumed)": 23697.67,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -61616,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228806,
    "out:DH kr savings": 332059,
    "out:El kr savings": -104747,
    "out:El kr sold": 1494,
    "out:El kr saved": 40286,
    "out:El kr return": 41780,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18049,
    "out:% savings (space heating)": 93.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.7,
    "out:Etvv": 0,
    "out:Ef": 43.59,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.88,
    "out:Electricity (inc PV)": 42.76,
    "out:Total Energy Use Pre PV": 63.88,
    "out:Total Energy Use Post PV": 48.76,
    "out:Primary Energy": 79.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.86,
    "out:CO2 Operational/m2": 5.8,
    "out:Total CO2/m2": 36.66,
    "out:Total CO2 (tons)": 81.57,
    "out:Klimatpaverkan": -60.87,
    "out:Initial Cost/MSEK": 5.834625,
    "out:Running cost/(Apt SEK y)": 11793,
    "out:Running Cost over RSP/MSEK": 1.65,
    "out:LCP/MSEK": 0.852,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3345.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1247.53,
    "out:PV (% sold (El))": 5.02,
    "out:PV (kWh self-consumed)": 23621.61,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -59764,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236100,
    "out:DH kr savings": 336108,
    "out:El kr savings": -101599,
    "out:El kr sold": 1591,
    "out:El kr saved": 40157,
    "out:El kr return": 41747,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13725,
    "out:% savings (space heating)": 95.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.46,
    "out:Etvv": 0,
    "out:Ef": 42.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.01,
    "out:Electricity (inc PV)": 42.67,
    "out:Total Energy Use Pre PV": 64.01,
    "out:Total Energy Use Post PV": 49.67,
    "out:Primary Energy": 79.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.28,
    "out:CO2 Operational/m2": 5.82,
    "out:Total CO2/m2": 37.1,
    "out:Total CO2 (tons)": 82.56,
    "out:Klimatpaverkan": -59.88,
    "out:Initial Cost/MSEK": 5.892875,
    "out:Running cost/(Apt SEK y)": 11937,
    "out:Running Cost over RSP/MSEK": 1.67,
    "out:LCP/MSEK": 0.773,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3286.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 851,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1350.47,
    "out:PV (% sold (El))": 5.43,
    "out:PV (kWh self-consumed)": 23518.67,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -59562,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234550,
    "out:DH kr savings": 334084,
    "out:El kr savings": -101255,
    "out:El kr sold": 1722,
    "out:El kr saved": 39982,
    "out:El kr return": 41704,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 14914,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.21,
    "out:Etvv": 0,
    "out:Ef": 42.67,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 41.81,
    "out:Total Energy Use Pre PV": 61.25,
    "out:Total Energy Use Post PV": 46.81,
    "out:Primary Energy": 77.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.28,
    "out:CO2 Operational/m2": 4.8,
    "out:Total CO2/m2": 36.08,
    "out:Total CO2 (tons)": 80.29,
    "out:Klimatpaverkan": -62.15,
    "out:Initial Cost/MSEK": 5.9485,
    "out:Running cost/(Apt SEK y)": 11130,
    "out:Running Cost over RSP/MSEK": 1.557,
    "out:LCP/MSEK": 0.831,
    "out:ROI% old": 16.28,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3235.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1443.6,
    "out:PV (% sold (El))": 5.8,
    "out:PV (kWh self-consumed)": 23425.54,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -57657,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241957,
    "out:DH kr savings": 338133,
    "out:El kr savings": -98016,
    "out:El kr sold": 1841,
    "out:El kr saved": 39823,
    "out:El kr return": 41664,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11206,
    "out:% savings (space heating)": 96.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.06,
    "out:Etvv": 0,
    "out:Ef": 41.81,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 39.27,
    "out:Total Energy Use Pre PV": 66.56,
    "out:Total Energy Use Post PV": 47.27,
    "out:Primary Energy": 73.97,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.21,
    "out:CO2 Operational/m2": -25.78,
    "out:Total CO2/m2": 17.44,
    "out:Total CO2 (tons)": 38.8,
    "out:Klimatpaverkan": -103.64,
    "out:Initial Cost/MSEK": 6.241875,
    "out:Running cost/(Apt SEK y)": 10569,
    "out:Running Cost over RSP/MSEK": 1.484,
    "out:LCP/MSEK": 0.61,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3046.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 876,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15330.62,
    "out:PV (% sold (El))": 30.82,
    "out:PV (kWh self-consumed)": 34407.66,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -51995,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263213,
    "out:DH kr savings": 332059,
    "out:El kr savings": -88392,
    "out:El kr sold": 19547,
    "out:El kr saved": 58493,
    "out:El kr return": 78040,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 18049,
    "out:% savings (space heating)": 93.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.7,
    "out:Etvv": 0,
    "out:Ef": 39.27,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.88,
    "out:Electricity (inc PV)": 38.53,
    "out:Total Energy Use Pre PV": 63.88,
    "out:Total Energy Use Post PV": 44.53,
    "out:Primary Energy": 71.78,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.21,
    "out:CO2 Operational/m2": -27.13,
    "out:Total CO2/m2": 16.09,
    "out:Total CO2 (tons)": 35.8,
    "out:Klimatpaverkan": -106.64,
    "out:Initial Cost/MSEK": 6.2975,
    "out:Running cost/(Apt SEK y)": 9783,
    "out:Running Cost over RSP/MSEK": 1.374,
    "out:LCP/MSEK": 0.665,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3006.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 890,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15571.24,
    "out:PV (% sold (El))": 31.31,
    "out:PV (kWh self-consumed)": 34167.04,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -50350,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270367,
    "out:DH kr savings": 336108,
    "out:El kr savings": -85595,
    "out:El kr sold": 19853,
    "out:El kr saved": 58084,
    "out:El kr return": 77937,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13725,
    "out:% savings (space heating)": 95.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.46,
    "out:Etvv": 0,
    "out:Ef": 38.53,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.01,
    "out:Electricity (inc PV)": 38.45,
    "out:Total Energy Use Pre PV": 64.01,
    "out:Total Energy Use Post PV": 45.45,
    "out:Primary Energy": 72.15,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.64,
    "out:CO2 Operational/m2": -27.59,
    "out:Total CO2/m2": 16.05,
    "out:Total CO2 (tons)": 35.71,
    "out:Klimatpaverkan": -106.73,
    "out:Initial Cost/MSEK": 6.35575,
    "out:Running cost/(Apt SEK y)": 9931,
    "out:Running Cost over RSP/MSEK": 1.395,
    "out:LCP/MSEK": 0.586,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2954.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 887,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15885.43,
    "out:PV (% sold (El))": 31.94,
    "out:PV (kWh self-consumed)": 33852.85,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -50170,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 269049,
    "out:DH kr savings": 334084,
    "out:El kr savings": -85289,
    "out:El kr sold": 20254,
    "out:El kr saved": 57550,
    "out:El kr return": 77804,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 14914,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.21,
    "out:Etvv": 0,
    "out:Ef": 38.45,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.25,
    "out:Electricity (inc PV)": 37.69,
    "out:Total Energy Use Pre PV": 61.25,
    "out:Total Energy Use Post PV": 42.69,
    "out:Primary Energy": 69.98,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.64,
    "out:CO2 Operational/m2": -29.03,
    "out:Total CO2/m2": 14.61,
    "out:Total CO2 (tons)": 32.51,
    "out:Klimatpaverkan": -109.93,
    "out:Initial Cost/MSEK": 6.411375,
    "out:Running cost/(Apt SEK y)": 9128,
    "out:Running Cost over RSP/MSEK": 1.282,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2909.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.05,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16160.24,
    "out:PV (% sold (El))": 32.49,
    "out:PV (kWh self-consumed)": 33578.04,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -48475,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276331,
    "out:DH kr savings": 338133,
    "out:El kr savings": -82407,
    "out:El kr sold": 20604,
    "out:El kr saved": 57083,
    "out:El kr return": 77687,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11206,
    "out:% savings (space heating)": 96.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.06,
    "out:Etvv": 0,
    "out:Ef": 37.69,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 52.67,
    "out:Total Energy Use Pre PV": 62.47,
    "out:Total Energy Use Post PV": 58.67,
    "out:Primary Energy": 97.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.51,
    "out:CO2 Operational/m2": 9.62,
    "out:Total CO2/m2": 28.12,
    "out:Total CO2 (tons)": 62.57,
    "out:Klimatpaverkan": -79.87,
    "out:Initial Cost/MSEK": 5.36375,
    "out:Running cost/(Apt SEK y)": 13817,
    "out:Running Cost over RSP/MSEK": 1.926,
    "out:LCP/MSEK": 1.047,
    "out:ROI% old": 17.07,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3953.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -81807,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197036,
    "out:DH kr savings": 336108,
    "out:El kr savings": -139072,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13988,
    "out:% savings (space heating)": 95.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 52.67,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.69,
    "out:Electricity (inc PV)": 51.74,
    "out:Total Energy Use Pre PV": 60.69,
    "out:Total Energy Use Post PV": 56.74,
    "out:Primary Energy": 95.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.51,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 27.66,
    "out:Total CO2 (tons)": 61.54,
    "out:Klimatpaverkan": -80.9,
    "out:Initial Cost/MSEK": 5.419375,
    "out:Running cost/(Apt SEK y)": 13327,
    "out:Running Cost over RSP/MSEK": 1.857,
    "out:LCP/MSEK": 1.06,
    "out:ROI% old": 17.08,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3898.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -79744,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202569,
    "out:DH kr savings": 338133,
    "out:El kr savings": -135564,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10473,
    "out:% savings (space heating)": 96.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.94,
    "out:Etvv": 0,
    "out:Ef": 51.74,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 51.86,
    "out:Total Energy Use Pre PV": 60.17,
    "out:Total Energy Use Post PV": 56.86,
    "out:Primary Energy": 95.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.93,
    "out:CO2 Operational/m2": 9.08,
    "out:Total CO2/m2": 28.01,
    "out:Total CO2 (tons)": 62.33,
    "out:Klimatpaverkan": -80.11,
    "out:Initial Cost/MSEK": 5.477625,
    "out:Running cost/(Apt SEK y)": 13217,
    "out:Running Cost over RSP/MSEK": 1.842,
    "out:LCP/MSEK": 1.017,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3861.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -80022,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202096,
    "out:DH kr savings": 338133,
    "out:El kr savings": -136037,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11980,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 51.86,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 50.92,
    "out:Total Energy Use Pre PV": 58.32,
    "out:Total Energy Use Post PV": 54.92,
    "out:Primary Energy": 93.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.93,
    "out:CO2 Operational/m2": 8.61,
    "out:Total CO2/m2": 27.54,
    "out:Total CO2 (tons)": 61.28,
    "out:Klimatpaverkan": -81.16,
    "out:Initial Cost/MSEK": 5.53325,
    "out:Running cost/(Apt SEK y)": 12714,
    "out:Running Cost over RSP/MSEK": 1.771,
    "out:LCP/MSEK": 1.032,
    "out:ROI% old": 16.95,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3802.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -77912,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207707,
    "out:DH kr savings": 340158,
    "out:El kr savings": -132450,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8908,
    "out:% savings (space heating)": 96.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 50.92,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 42.36,
    "out:Total Energy Use Pre PV": 62.47,
    "out:Total Energy Use Post PV": 48.36,
    "out:Primary Energy": 79.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.86,
    "out:CO2 Operational/m2": 5.25,
    "out:Total CO2/m2": 36.11,
    "out:Total CO2 (tons)": 80.35,
    "out:Klimatpaverkan": -62.09,
    "out:Initial Cost/MSEK": 5.8265,
    "out:Running cost/(Apt SEK y)": 11502,
    "out:Running Cost over RSP/MSEK": 1.609,
    "out:LCP/MSEK": 0.901,
    "out:ROI% old": 16.49,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3250.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 859,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1415.46,
    "out:PV (% sold (El))": 5.69,
    "out:PV (kWh self-consumed)": 23453.68,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -58872,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237830,
    "out:DH kr savings": 336108,
    "out:El kr savings": -100083,
    "out:El kr sold": 1805,
    "out:El kr saved": 39871,
    "out:El kr return": 41676,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13988,
    "out:% savings (space heating)": 95.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 42.36,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.69,
    "out:Electricity (inc PV)": 41.5,
    "out:Total Energy Use Pre PV": 60.69,
    "out:Total Energy Use Post PV": 46.5,
    "out:Primary Energy": 76.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.86,
    "out:CO2 Operational/m2": 4.57,
    "out:Total CO2/m2": 35.43,
    "out:Total CO2 (tons)": 78.84,
    "out:Klimatpaverkan": -63.6,
    "out:Initial Cost/MSEK": 5.882125,
    "out:Running cost/(Apt SEK y)": 11014,
    "out:Running Cost over RSP/MSEK": 1.541,
    "out:LCP/MSEK": 0.914,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3198.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1514.63,
    "out:PV (% sold (El))": 6.09,
    "out:PV (kWh self-consumed)": 23354.51,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -56951,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243248,
    "out:DH kr savings": 338133,
    "out:El kr savings": -96816,
    "out:El kr sold": 1931,
    "out:El kr saved": 39703,
    "out:El kr return": 41634,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10473,
    "out:% savings (space heating)": 96.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.94,
    "out:Etvv": 0,
    "out:Ef": 41.5,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 41.61,
    "out:Total Energy Use Pre PV": 60.17,
    "out:Total Energy Use Post PV": 46.61,
    "out:Primary Energy": 77.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.28,
    "out:CO2 Operational/m2": 4.36,
    "out:Total CO2/m2": 35.64,
    "out:Total CO2 (tons)": 79.3,
    "out:Klimatpaverkan": -63.14,
    "out:Initial Cost/MSEK": 5.940375,
    "out:Running cost/(Apt SEK y)": 10906,
    "out:Running Cost over RSP/MSEK": 1.525,
    "out:LCP/MSEK": 0.87,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3163.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1582.8,
    "out:PV (% sold (El))": 6.36,
    "out:PV (kWh self-consumed)": 23286.34,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -57209,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242895,
    "out:DH kr savings": 338133,
    "out:El kr savings": -97256,
    "out:El kr sold": 2018,
    "out:El kr saved": 39587,
    "out:El kr return": 41605,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11980,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 41.61,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 40.73,
    "out:Total Energy Use Pre PV": 58.32,
    "out:Total Energy Use Post PV": 44.73,
    "out:Primary Energy": 75.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.28,
    "out:CO2 Operational/m2": 3.64,
    "out:Total CO2/m2": 34.92,
    "out:Total CO2 (tons)": 77.7,
    "out:Klimatpaverkan": -64.74,
    "out:Initial Cost/MSEK": 5.996,
    "out:Running cost/(Apt SEK y)": 10406,
    "out:Running Cost over RSP/MSEK": 1.455,
    "out:LCP/MSEK": 0.885,
    "out:ROI% old": 16.39,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3107.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1696.58,
    "out:PV (% sold (El))": 6.82,
    "out:PV (kWh self-consumed)": 23172.56,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -55250,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248397,
    "out:DH kr savings": 340158,
    "out:El kr savings": -93924,
    "out:El kr sold": 2163,
    "out:El kr saved": 39393,
    "out:El kr return": 41556,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8908,
    "out:% savings (space heating)": 96.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 40.73,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.47,
    "out:Electricity (inc PV)": 38.17,
    "out:Total Energy Use Pre PV": 62.47,
    "out:Total Energy Use Post PV": 44.17,
    "out:Primary Energy": 71.55,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.21,
    "out:CO2 Operational/m2": -28.45,
    "out:Total CO2/m2": 14.76,
    "out:Total CO2 (tons)": 32.85,
    "out:Klimatpaverkan": -109.59,
    "out:Initial Cost/MSEK": 6.289375,
    "out:Running cost/(Apt SEK y)": 9499,
    "out:Running Cost over RSP/MSEK": 1.334,
    "out:LCP/MSEK": 0.713,
    "out:ROI% old": 15.9,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2922.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 895,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16077.97,
    "out:PV (% sold (El))": 32.33,
    "out:PV (kWh self-consumed)": 33660.31,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -49556,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272362,
    "out:DH kr savings": 336108,
    "out:El kr savings": -84246,
    "out:El kr sold": 20499,
    "out:El kr saved": 57223,
    "out:El kr return": 77722,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13988,
    "out:% savings (space heating)": 95.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 38.17,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.69,
    "out:Electricity (inc PV)": 37.4,
    "out:Total Energy Use Pre PV": 60.69,
    "out:Total Energy Use Post PV": 42.4,
    "out:Primary Energy": 69.39,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.21,
    "out:CO2 Operational/m2": -29.56,
    "out:Total CO2/m2": 13.66,
    "out:Total CO2 (tons)": 30.38,
    "out:Klimatpaverkan": -112.06,
    "out:Initial Cost/MSEK": 6.345,
    "out:Running cost/(Apt SEK y)": 9016,
    "out:Running Cost over RSP/MSEK": 1.266,
    "out:LCP/MSEK": 0.725,
    "out:ROI% old": 15.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2876.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.49,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16364.23,
    "out:PV (% sold (El))": 32.9,
    "out:PV (kWh self-consumed)": 33374.05,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -47846,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277659,
    "out:DH kr savings": 338133,
    "out:El kr savings": -81338,
    "out:El kr sold": 20864,
    "out:El kr saved": 56736,
    "out:El kr return": 77600,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10473,
    "out:% savings (space heating)": 96.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.94,
    "out:Etvv": 0,
    "out:Ef": 37.4,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 37.51,
    "out:Total Energy Use Pre PV": 60.17,
    "out:Total Energy Use Post PV": 42.51,
    "out:Primary Energy": 70.1,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.64,
    "out:CO2 Operational/m2": -30.06,
    "out:Total CO2/m2": 13.58,
    "out:Total CO2 (tons)": 30.21,
    "out:Klimatpaverkan": -112.23,
    "out:Initial Cost/MSEK": 6.40325,
    "out:Running cost/(Apt SEK y)": 8910,
    "out:Running Cost over RSP/MSEK": 1.252,
    "out:LCP/MSEK": 0.681,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2845.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16555.98,
    "out:PV (% sold (El))": 33.29,
    "out:PV (kWh self-consumed)": 33182.3,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -48076,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277512,
    "out:DH kr savings": 338133,
    "out:El kr savings": -81730,
    "out:El kr sold": 21109,
    "out:El kr saved": 56410,
    "out:El kr return": 77519,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11980,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 37.51,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 36.72,
    "out:Total Energy Use Pre PV": 58.32,
    "out:Total Energy Use Post PV": 40.72,
    "out:Primary Energy": 67.96,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.64,
    "out:CO2 Operational/m2": -31.23,
    "out:Total CO2/m2": 12.4,
    "out:Total CO2 (tons)": 27.6,
    "out:Klimatpaverkan": -114.84,
    "out:Initial Cost/MSEK": 6.458875,
    "out:Running cost/(Apt SEK y)": 8415,
    "out:Running Cost over RSP/MSEK": 1.182,
    "out:LCP/MSEK": 0.695,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2795.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 914,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16868.11,
    "out:PV (% sold (El))": 33.91,
    "out:PV (kWh self-consumed)": 32870.17,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -46332,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282900,
    "out:DH kr savings": 340158,
    "out:El kr savings": -78765,
    "out:El kr sold": 21507,
    "out:El kr saved": 55879,
    "out:El kr return": 77386,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8908,
    "out:% savings (space heating)": 96.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 36.72,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 111.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.68,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 72.09,
    "out:Total CO2 (tons)": 160.41,
    "out:Klimatpaverkan": 17.97,
    "out:Initial Cost/MSEK": 3.241375,
    "out:Running cost/(Apt SEK y)": 47849,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -1.64,
    "out:ROI% old": 7.06,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 205,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 70866,
    "out:DH kr savings": 70866,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 108.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.68,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 70.65,
    "out:Total CO2 (tons)": 157.2,
    "out:Klimatpaverkan": 14.76,
    "out:Initial Cost/MSEK": 3.297,
    "out:Running cost/(Apt SEK y)": 46550,
    "out:Running Cost over RSP/MSEK": 6.552,
    "out:LCP/MSEK": -1.512,
    "out:ROI% old": 7.73,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78965,
    "out:DH kr savings": 78965,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 109.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.1,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 70.36,
    "out:Total CO2 (tons)": 156.54,
    "out:Klimatpaverkan": 14.1,
    "out:Initial Cost/MSEK": 3.35525,
    "out:Running cost/(Apt SEK y)": 45900,
    "out:Running Cost over RSP/MSEK": 6.46,
    "out:LCP/MSEK": -1.479,
    "out:ROI% old": 7.99,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83015,
    "out:DH kr savings": 83015,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 106.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.1,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 68.92,
    "out:Total CO2 (tons)": 153.34,
    "out:Klimatpaverkan": 10.9,
    "out:Initial Cost/MSEK": 3.410875,
    "out:Running cost/(Apt SEK y)": 44600,
    "out:Running Cost over RSP/MSEK": 6.277,
    "out:LCP/MSEK": -1.352,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91114,
    "out:DH kr savings": 91114,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 102.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.03,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 53.45,
    "out:Total CO2 (tons)": 118.93,
    "out:Klimatpaverkan": -23.51,
    "out:Initial Cost/MSEK": 3.70425,
    "out:Running cost/(Apt SEK y)": 45816,
    "out:Running Cost over RSP/MSEK": 6.456,
    "out:LCP/MSEK": -1.824,
    "out:ROI% old": 7.25,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 241,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107465,
    "out:DH kr savings": 70866,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 99.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.03,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 52.01,
    "out:Total CO2 (tons)": 115.73,
    "out:Klimatpaverkan": -26.71,
    "out:Initial Cost/MSEK": 3.759875,
    "out:Running cost/(Apt SEK y)": 44516,
    "out:Running Cost over RSP/MSEK": 6.273,
    "out:LCP/MSEK": -1.697,
    "out:ROI% old": 7.84,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115564,
    "out:DH kr savings": 78965,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 99.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 115.06,
    "out:Klimatpaverkan": -27.38,
    "out:Initial Cost/MSEK": 3.818,
    "out:Running cost/(Apt SEK y)": 43866,
    "out:Running Cost over RSP/MSEK": 6.181,
    "out:LCP/MSEK": -1.663,
    "out:ROI% old": 8.06,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119613,
    "out:DH kr savings": 83015,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 97.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 111.86,
    "out:Klimatpaverkan": -30.58,
    "out:Initial Cost/MSEK": 3.873625,
    "out:Running cost/(Apt SEK y)": 42567,
    "out:Running Cost over RSP/MSEK": 5.998,
    "out:LCP/MSEK": -1.536,
    "out:ROI% old": 8.62,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127712,
    "out:DH kr savings": 91114,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 100.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 13.12,
    "out:Total CO2 (tons)": 29.2,
    "out:Klimatpaverkan": -113.24,
    "out:Initial Cost/MSEK": 4.167,
    "out:Running cost/(Apt SEK y)": 44010,
    "out:Running Cost over RSP/MSEK": 6.209,
    "out:LCP/MSEK": -2.04,
    "out:ROI% old": 7.29,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139976,
    "out:DH kr savings": 70866,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 98.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 11.68,
    "out:Total CO2 (tons)": 26,
    "out:Klimatpaverkan": -116.44,
    "out:Initial Cost/MSEK": 4.222625,
    "out:Running cost/(Apt SEK y)": 42710,
    "out:Running Cost over RSP/MSEK": 6.026,
    "out:LCP/MSEK": -1.912,
    "out:ROI% old": 7.82,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86775,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148075,
    "out:DH kr savings": 78965,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 98.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.81,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 11.39,
    "out:Total CO2 (tons)": 25.34,
    "out:Klimatpaverkan": -117.1,
    "out:Initial Cost/MSEK": 4.280875,
    "out:Running cost/(Apt SEK y)": 42060,
    "out:Running Cost over RSP/MSEK": 5.934,
    "out:LCP/MSEK": -1.879,
    "out:ROI% old": 8.02,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152124,
    "out:DH kr savings": 83015,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 95.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.81,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 9.95,
    "out:Total CO2 (tons)": 22.13,
    "out:Klimatpaverkan": -120.31,
    "out:Initial Cost/MSEK": 4.3365,
    "out:Running cost/(Apt SEK y)": 40761,
    "out:Running Cost over RSP/MSEK": 5.751,
    "out:LCP/MSEK": -1.751,
    "out:ROI% old": 8.52,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160223,
    "out:DH kr savings": 91114,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 108.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.68,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 69.21,
    "out:Total CO2 (tons)": 154,
    "out:Klimatpaverkan": 11.56,
    "out:Initial Cost/MSEK": 3.288875,
    "out:Running cost/(Apt SEK y)": 45250,
    "out:Running Cost over RSP/MSEK": 6.368,
    "out:LCP/MSEK": -1.321,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87064,
    "out:DH kr savings": 87064,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 105.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.68,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 67.77,
    "out:Total CO2 (tons)": 150.8,
    "out:Klimatpaverkan": 8.36,
    "out:Initial Cost/MSEK": 3.3445,
    "out:Running cost/(Apt SEK y)": 43951,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -1.194,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95163,
    "out:DH kr savings": 95163,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 106.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.1,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 67.48,
    "out:Total CO2 (tons)": 150.13,
    "out:Klimatpaverkan": 7.69,
    "out:Initial Cost/MSEK": 3.40275,
    "out:Running cost/(Apt SEK y)": 43301,
    "out:Running Cost over RSP/MSEK": 6.094,
    "out:LCP/MSEK": -1.16,
    "out:ROI% old": 9.41,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99213,
    "out:DH kr savings": 99213,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 134.9,
    "out:Primary Energy": 103.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.1,
    "out:CO2 Operational/m2": 44.94,
    "out:Total CO2/m2": 66.04,
    "out:Total CO2 (tons)": 146.93,
    "out:Klimatpaverkan": 4.49,
    "out:Initial Cost/MSEK": 3.458375,
    "out:Running cost/(Apt SEK y)": 42001,
    "out:Running Cost over RSP/MSEK": 5.911,
    "out:LCP/MSEK": -1.033,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 310,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107312,
    "out:DH kr savings": 107312,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.03,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 50.57,
    "out:Total CO2 (tons)": 112.52,
    "out:Klimatpaverkan": -29.92,
    "out:Initial Cost/MSEK": 3.75175,
    "out:Running cost/(Apt SEK y)": 43217,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -1.505,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 288,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123663,
    "out:DH kr savings": 87064,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 96.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.03,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 49.13,
    "out:Total CO2 (tons)": 109.32,
    "out:Klimatpaverkan": -33.12,
    "out:Initial Cost/MSEK": 3.807375,
    "out:Running cost/(Apt SEK y)": 41917,
    "out:Running Cost over RSP/MSEK": 5.907,
    "out:LCP/MSEK": -1.378,
    "out:ROI% old": 9.12,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131762,
    "out:DH kr savings": 95163,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 96.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 108.66,
    "out:Klimatpaverkan": -33.78,
    "out:Initial Cost/MSEK": 3.8655,
    "out:Running cost/(Apt SEK y)": 41268,
    "out:Running Cost over RSP/MSEK": 5.815,
    "out:LCP/MSEK": -1.345,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135811,
    "out:DH kr savings": 99213,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 129.73,
    "out:Primary Energy": 94.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.45,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 47.39,
    "out:Total CO2 (tons)": 105.45,
    "out:Klimatpaverkan": -36.99,
    "out:Initial Cost/MSEK": 3.921125,
    "out:Running cost/(Apt SEK y)": 39968,
    "out:Running Cost over RSP/MSEK": 5.632,
    "out:LCP/MSEK": -1.217,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 347,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143910,
    "out:DH kr savings": 107312,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 97.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 10.25,
    "out:Total CO2 (tons)": 22.8,
    "out:Klimatpaverkan": -119.64,
    "out:Initial Cost/MSEK": 4.2145,
    "out:Running cost/(Apt SEK y)": 41411,
    "out:Running Cost over RSP/MSEK": 5.843,
    "out:LCP/MSEK": -1.721,
    "out:ROI% old": 8.45,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156174,
    "out:DH kr savings": 87064,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 94.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 8.81,
    "out:Total CO2 (tons)": 19.59,
    "out:Klimatpaverkan": -122.85,
    "out:Initial Cost/MSEK": 4.270125,
    "out:Running cost/(Apt SEK y)": 40111,
    "out:Running Cost over RSP/MSEK": 5.66,
    "out:LCP/MSEK": -1.593,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164273,
    "out:DH kr savings": 95163,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 95.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.81,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 8.51,
    "out:Total CO2 (tons)": 18.93,
    "out:Klimatpaverkan": -123.51,
    "out:Initial Cost/MSEK": 4.328375,
    "out:Running cost/(Apt SEK y)": 39461,
    "out:Running Cost over RSP/MSEK": 5.568,
    "out:LCP/MSEK": -1.56,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168322,
    "out:DH kr savings": 99213,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 208558,
    "out:% savings (space heating)": 26.86,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 128.88,
    "out:Primary Energy": 92.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.81,
    "out:CO2 Operational/m2": -38.74,
    "out:Total CO2/m2": 7.07,
    "out:Total CO2 (tons)": 15.73,
    "out:Klimatpaverkan": -126.71,
    "out:Initial Cost/MSEK": 4.384,
    "out:Running cost/(Apt SEK y)": 38162,
    "out:Running Cost over RSP/MSEK": 5.385,
    "out:LCP/MSEK": -1.433,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176421,
    "out:DH kr savings": 107312,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 199685,
    "out:% savings (space heating)": 29.98,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 40.39,
    "out:Total Energy Use Pre PV": 98.72,
    "out:Total Energy Use Post PV": 96.39,
    "out:Primary Energy": 104.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.09,
    "out:CO2 Operational/m2": 25.8,
    "out:Total CO2/m2": 50.89,
    "out:Total CO2 (tons)": 113.22,
    "out:Klimatpaverkan": -29.22,
    "out:Initial Cost/MSEK": 5.094125,
    "out:Running cost/(Apt SEK y)": 27171,
    "out:Running Cost over RSP/MSEK": 3.812,
    "out:LCP/MSEK": -0.57,
    "out:ROI% old": 12.69,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2990.6,
    "out:Return %": 11,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -54491,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142236,
    "out:DH kr savings": 234871,
    "out:El kr savings": -92635,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125282,
    "out:% savings (space heating)": 56.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.5,
    "out:Etvv": 0,
    "out:Ef": 40.39,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.66,
    "out:Electricity (inc PV)": 40.3,
    "out:Total Energy Use Pre PV": 95.66,
    "out:Total Energy Use Post PV": 93.3,
    "out:Primary Energy": 101.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.09,
    "out:CO2 Operational/m2": 24.71,
    "out:Total CO2/m2": 49.8,
    "out:Total CO2 (tons)": 110.8,
    "out:Klimatpaverkan": -31.64,
    "out:Initial Cost/MSEK": 5.14975,
    "out:Running cost/(Apt SEK y)": 26184,
    "out:Running Cost over RSP/MSEK": 3.673,
    "out:LCP/MSEK": -0.487,
    "out:ROI% old": 12.94,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 102.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2986.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 264775,
    "out:EL kWh savings": -54283,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148664,
    "out:DH kr savings": 240945,
    "out:El kr savings": -92282,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116835,
    "out:% savings (space heating)": 59.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.82,
    "out:Etvv": 0,
    "out:Ef": 40.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.93,
    "out:Electricity (inc PV)": 39.89,
    "out:Total Energy Use Pre PV": 93.93,
    "out:Total Energy Use Post PV": 91.89,
    "out:Primary Energy": 102.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.51,
    "out:CO2 Operational/m2": 24.25,
    "out:Total CO2/m2": 49.76,
    "out:Total CO2 (tons)": 110.73,
    "out:Klimatpaverkan": -31.71,
    "out:Initial Cost/MSEK": 5.208,
    "out:Running cost/(Apt SEK y)": 25705,
    "out:Running Cost over RSP/MSEK": 3.606,
    "out:LCP/MSEK": -0.478,
    "out:ROI% old": 12.98,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2935.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 603,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -53372,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152238,
    "out:DH kr savings": 242970,
    "out:El kr savings": -90732,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116581,
    "out:% savings (space heating)": 59.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 39.89,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.86,
    "out:Electricity (inc PV)": 39.78,
    "out:Total Energy Use Pre PV": 90.86,
    "out:Total Energy Use Post PV": 88.78,
    "out:Primary Energy": 99.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.51,
    "out:CO2 Operational/m2": 23.17,
    "out:Total CO2/m2": 48.67,
    "out:Total CO2 (tons)": 108.3,
    "out:Klimatpaverkan": -34.14,
    "out:Initial Cost/MSEK": 5.263625,
    "out:Running cost/(Apt SEK y)": 24715,
    "out:Running Cost over RSP/MSEK": 3.466,
    "out:LCP/MSEK": -0.394,
    "out:ROI% old": 13.22,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2930.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 621,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -53141,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158705,
    "out:DH kr savings": 249044,
    "out:El kr savings": -90339,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 108224,
    "out:% savings (space heating)": 62.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.77,
    "out:Etvv": 0,
    "out:Ef": 39.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 31.17,
    "out:Total Energy Use Pre PV": 98.72,
    "out:Total Energy Use Post PV": 87.17,
    "out:Primary Energy": 87.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.44,
    "out:CO2 Operational/m2": 16.23,
    "out:Total CO2/m2": 53.67,
    "out:Total CO2 (tons)": 119.42,
    "out:Klimatpaverkan": -23.02,
    "out:Initial Cost/MSEK": 5.557,
    "out:Running cost/(Apt SEK y)": 24912,
    "out:Running Cost over RSP/MSEK": 3.503,
    "out:LCP/MSEK": -0.723,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2352.2,
    "out:Return %": 11,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3792.2,
    "out:PV (% sold (El))": 15.25,
    "out:PV (kWh self-consumed)": 21076.94,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -33966,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181964,
    "out:DH kr savings": 234871,
    "out:El kr savings": -57742,
    "out:El kr sold": 4835,
    "out:El kr saved": 35831,
    "out:El kr return": 40666,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125282,
    "out:% savings (space heating)": 56.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.5,
    "out:Etvv": 0,
    "out:Ef": 31.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.66,
    "out:Electricity (inc PV)": 31.08,
    "out:Total Energy Use Pre PV": 95.66,
    "out:Total Energy Use Post PV": 84.08,
    "out:Primary Energy": 85.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.44,
    "out:CO2 Operational/m2": 15.11,
    "out:Total CO2/m2": 52.55,
    "out:Total CO2 (tons)": 116.93,
    "out:Klimatpaverkan": -25.51,
    "out:Initial Cost/MSEK": 5.612625,
    "out:Running cost/(Apt SEK y)": 23925,
    "out:Running Cost over RSP/MSEK": 3.364,
    "out:LCP/MSEK": -0.64,
    "out:ROI% old": 12.66,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2348.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3805.41,
    "out:PV (% sold (El))": 15.3,
    "out:PV (kWh self-consumed)": 21063.73,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 264775,
    "out:EL kWh savings": -33781,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188369,
    "out:DH kr savings": 240945,
    "out:El kr savings": -57428,
    "out:El kr sold": 4852,
    "out:El kr saved": 35808,
    "out:El kr return": 40660,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116835,
    "out:% savings (space heating)": 59.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.82,
    "out:Etvv": 0,
    "out:Ef": 31.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.93,
    "out:Electricity (inc PV)": 30.72,
    "out:Total Energy Use Pre PV": 93.93,
    "out:Total Energy Use Post PV": 82.72,
    "out:Primary Energy": 85.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.86,
    "out:CO2 Operational/m2": 14.28,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 116.02,
    "out:Klimatpaverkan": -26.42,
    "out:Initial Cost/MSEK": 5.67075,
    "out:Running cost/(Apt SEK y)": 23450,
    "out:Running Cost over RSP/MSEK": 3.297,
    "out:LCP/MSEK": -0.631,
    "out:ROI% old": 12.7,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2301.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3974.47,
    "out:PV (% sold (El))": 15.98,
    "out:PV (kWh self-consumed)": 20894.67,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -32972,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191985,
    "out:DH kr savings": 242970,
    "out:El kr savings": -56052,
    "out:El kr sold": 5067,
    "out:El kr saved": 35521,
    "out:El kr return": 40588,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116581,
    "out:% savings (space heating)": 59.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 30.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.86,
    "out:Electricity (inc PV)": 30.63,
    "out:Total Energy Use Pre PV": 90.86,
    "out:Total Energy Use Post PV": 79.63,
    "out:Primary Energy": 82.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.86,
    "out:CO2 Operational/m2": 13.15,
    "out:Total CO2/m2": 51.02,
    "out:Total CO2 (tons)": 113.51,
    "out:Klimatpaverkan": -28.93,
    "out:Initial Cost/MSEK": 5.726375,
    "out:Running cost/(Apt SEK y)": 22461,
    "out:Running Cost over RSP/MSEK": 3.158,
    "out:LCP/MSEK": -0.548,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2297.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3990.83,
    "out:PV (% sold (El))": 16.05,
    "out:PV (kWh self-consumed)": 20878.31,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -32767,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198428,
    "out:DH kr savings": 249044,
    "out:El kr savings": -55704,
    "out:El kr sold": 5088,
    "out:El kr saved": 35493,
    "out:El kr return": 40581,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 108224,
    "out:% savings (space heating)": 62.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.77,
    "out:Etvv": 0,
    "out:Ef": 30.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 28.18,
    "out:Total Energy Use Pre PV": 98.72,
    "out:Total Energy Use Post PV": 84.18,
    "out:Primary Energy": 82.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.79,
    "out:CO2 Operational/m2": -24.52,
    "out:Total CO2/m2": 25.27,
    "out:Total CO2 (tons)": 56.23,
    "out:Klimatpaverkan": -86.21,
    "out:Initial Cost/MSEK": 6.01975,
    "out:Running cost/(Apt SEK y)": 22982,
    "out:Running Cost over RSP/MSEK": 3.238,
    "out:LCP/MSEK": -0.922,
    "out:ROI% old": 12.1,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 123.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2124,
    "out:Return %": 11,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21532.99,
    "out:PV (% sold (El))": 43.29,
    "out:PV (kWh self-consumed)": 28205.29,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 258100,
    "out:EL kWh savings": -27317,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215887,
    "out:DH kr savings": 234871,
    "out:El kr savings": -46439,
    "out:El kr sold": 27455,
    "out:El kr saved": 47949,
    "out:El kr return": 75404,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 125282,
    "out:% savings (space heating)": 56.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 45.5,
    "out:Etvv": 0,
    "out:Ef": 28.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.66,
    "out:Electricity (inc PV)": 28.1,
    "out:Total Energy Use Pre PV": 95.66,
    "out:Total Energy Use Post PV": 81.1,
    "out:Primary Energy": 79.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.79,
    "out:CO2 Operational/m2": -25.67,
    "out:Total CO2/m2": 24.13,
    "out:Total CO2 (tons)": 53.68,
    "out:Klimatpaverkan": -88.76,
    "out:Initial Cost/MSEK": 6.075375,
    "out:Running cost/(Apt SEK y)": 21995,
    "out:Running Cost over RSP/MSEK": 3.099,
    "out:LCP/MSEK": -0.838,
    "out:ROI% old": 12.31,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2120.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21558.3,
    "out:PV (% sold (El))": 43.34,
    "out:PV (kWh self-consumed)": 28179.98,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 264775,
    "out:EL kWh savings": -27151,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222276,
    "out:DH kr savings": 240945,
    "out:El kr savings": -46156,
    "out:El kr sold": 27487,
    "out:El kr saved": 47906,
    "out:El kr return": 75393,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116835,
    "out:% savings (space heating)": 59.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.82,
    "out:Etvv": 0,
    "out:Ef": 28.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.93,
    "out:Electricity (inc PV)": 27.78,
    "out:Total Energy Use Pre PV": 93.93,
    "out:Total Energy Use Post PV": 79.78,
    "out:Primary Energy": 80.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.22,
    "out:CO2 Operational/m2": -26.85,
    "out:Total CO2/m2": 23.37,
    "out:Total CO2 (tons)": 52,
    "out:Klimatpaverkan": -90.44,
    "out:Initial Cost/MSEK": 6.133625,
    "out:Running cost/(Apt SEK y)": 21524,
    "out:Running Cost over RSP/MSEK": 3.033,
    "out:LCP/MSEK": -0.83,
    "out:ROI% old": 12.35,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2079.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21879.63,
    "out:PV (% sold (El))": 43.99,
    "out:PV (kWh self-consumed)": 27858.65,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 267000,
    "out:EL kWh savings": -26424,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225946,
    "out:DH kr savings": 242970,
    "out:El kr savings": -44921,
    "out:El kr sold": 27897,
    "out:El kr saved": 47360,
    "out:El kr return": 75256,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 116581,
    "out:% savings (space heating)": 59.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.41,
    "out:Etvv": 0,
    "out:Ef": 27.78,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.86,
    "out:Electricity (inc PV)": 27.69,
    "out:Total Energy Use Pre PV": 90.86,
    "out:Total Energy Use Post PV": 76.69,
    "out:Primary Energy": 77.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.22,
    "out:CO2 Operational/m2": -28.01,
    "out:Total CO2/m2": 22.21,
    "out:Total CO2 (tons)": 49.42,
    "out:Klimatpaverkan": -93.02,
    "out:Initial Cost/MSEK": 6.18925,
    "out:Running cost/(Apt SEK y)": 20535,
    "out:Running Cost over RSP/MSEK": 2.894,
    "out:LCP/MSEK": -0.747,
    "out:ROI% old": 12.56,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2075.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21910.46,
    "out:PV (% sold (El))": 44.05,
    "out:PV (kWh self-consumed)": 27827.82,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -26240,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232372,
    "out:DH kr savings": 249044,
    "out:El kr savings": -44608,
    "out:El kr sold": 27936,
    "out:El kr saved": 47307,
    "out:El kr return": 75243,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 108224,
    "out:% savings (space heating)": 62.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.77,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 39.72,
    "out:Total Energy Use Pre PV": 92.66,
    "out:Total Energy Use Post PV": 90.72,
    "out:Primary Energy": 101.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.09,
    "out:CO2 Operational/m2": 23.86,
    "out:Total CO2/m2": 48.95,
    "out:Total CO2 (tons)": 108.9,
    "out:Klimatpaverkan": -33.54,
    "out:Initial Cost/MSEK": 5.141625,
    "out:Running cost/(Apt SEK y)": 25323,
    "out:Running Cost over RSP/MSEK": 3.552,
    "out:LCP/MSEK": -0.357,
    "out:ROI% old": 13.29,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2916.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -52992,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154908,
    "out:DH kr savings": 244995,
    "out:El kr savings": -90087,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113738,
    "out:% savings (space heating)": 60.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.73,
    "out:Etvv": 0,
    "out:Ef": 39.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.58,
    "out:Electricity (inc PV)": 39.61,
    "out:Total Energy Use Pre PV": 88.58,
    "out:Total Energy Use Post PV": 86.61,
    "out:Primary Energy": 98.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.09,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 47.5,
    "out:Total CO2 (tons)": 105.68,
    "out:Klimatpaverkan": -36.76,
    "out:Initial Cost/MSEK": 5.19725,
    "out:Running cost/(Apt SEK y)": 24008,
    "out:Running Cost over RSP/MSEK": 3.367,
    "out:LCP/MSEK": -0.228,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2910.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -52754,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163411,
    "out:DH kr savings": 253094,
    "out:El kr savings": -89682,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 105411,
    "out:% savings (space heating)": 63.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.09,
    "out:Etvv": 0,
    "out:Ef": 39.61,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.99,
    "out:Electricity (inc PV)": 39.3,
    "out:Total Energy Use Pre PV": 88.99,
    "out:Total Energy Use Post PV": 87.3,
    "out:Primary Energy": 99.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.51,
    "out:CO2 Operational/m2": 22.69,
    "out:Total CO2/m2": 48.2,
    "out:Total CO2 (tons)": 107.25,
    "out:Klimatpaverkan": -35.19,
    "out:Initial Cost/MSEK": 5.2555,
    "out:Running cost/(Apt SEK y)": 24208,
    "out:Running Cost over RSP/MSEK": 3.395,
    "out:LCP/MSEK": -0.315,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2869.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -52070,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162550,
    "out:DH kr savings": 251069,
    "out:El kr savings": -88519,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 107021,
    "out:% savings (space heating)": 62.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 39.3,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91,
    "out:Electricity (inc PV)": 39.19,
    "out:Total Energy Use Pre PV": 84.91,
    "out:Total Energy Use Post PV": 83.19,
    "out:Primary Energy": 96.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.51,
    "out:CO2 Operational/m2": 21.24,
    "out:Total CO2/m2": 46.75,
    "out:Total CO2 (tons)": 104.02,
    "out:Klimatpaverkan": -38.42,
    "out:Initial Cost/MSEK": 5.311125,
    "out:Running cost/(Apt SEK y)": 22891,
    "out:Running Cost over RSP/MSEK": 3.21,
    "out:LCP/MSEK": -0.185,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2863.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 284800,
    "out:EL kWh savings": -51815,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171082,
    "out:DH kr savings": 259168,
    "out:El kr savings": -88086,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 98769,
    "out:% savings (space heating)": 65.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.48,
    "out:Etvv": 0,
    "out:Ef": 39.19,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 30.57,
    "out:Total Energy Use Pre PV": 92.66,
    "out:Total Energy Use Post PV": 81.57,
    "out:Primary Energy": 84.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.44,
    "out:CO2 Operational/m2": 13.74,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 113.88,
    "out:Klimatpaverkan": -28.56,
    "out:Initial Cost/MSEK": 5.6045,
    "out:Running cost/(Apt SEK y)": 23069,
    "out:Running Cost over RSP/MSEK": 3.243,
    "out:LCP/MSEK": -0.512,
    "out:ROI% old": 12.98,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2284.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 651,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4038.11,
    "out:PV (% sold (El))": 16.24,
    "out:PV (kWh self-consumed)": 20831.03,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -32635,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194663,
    "out:DH kr savings": 244995,
    "out:El kr savings": -55480,
    "out:El kr sold": 5149,
    "out:El kr saved": 35413,
    "out:El kr return": 40561,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113738,
    "out:% savings (space heating)": 60.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.73,
    "out:Etvv": 0,
    "out:Ef": 30.57,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.58,
    "out:Electricity (inc PV)": 30.47,
    "out:Total Energy Use Pre PV": 88.58,
    "out:Total Energy Use Post PV": 77.47,
    "out:Primary Energy": 82.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.44,
    "out:CO2 Operational/m2": 12.25,
    "out:Total CO2/m2": 49.69,
    "out:Total CO2 (tons)": 110.57,
    "out:Klimatpaverkan": -31.87,
    "out:Initial Cost/MSEK": 5.660125,
    "out:Running cost/(Apt SEK y)": 21755,
    "out:Running Cost over RSP/MSEK": 3.058,
    "out:LCP/MSEK": -0.382,
    "out:ROI% old": 13.32,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2279.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 674,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4055.63,
    "out:PV (% sold (El))": 16.31,
    "out:PV (kWh self-consumed)": 20813.51,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -32425,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203142,
    "out:DH kr savings": 253094,
    "out:El kr savings": -55122,
    "out:El kr sold": 5171,
    "out:El kr saved": 35383,
    "out:El kr return": 40554,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 105411,
    "out:% savings (space heating)": 63.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.09,
    "out:Etvv": 0,
    "out:Ef": 30.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.99,
    "out:Electricity (inc PV)": 30.2,
    "out:Total Energy Use Pre PV": 88.99,
    "out:Total Energy Use Post PV": 78.2,
    "out:Primary Energy": 83.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.86,
    "out:CO2 Operational/m2": 12.22,
    "out:Total CO2/m2": 50.09,
    "out:Total CO2 (tons)": 111.44,
    "out:Klimatpaverkan": -31,
    "out:Initial Cost/MSEK": 5.71825,
    "out:Running cost/(Apt SEK y)": 21958,
    "out:Running Cost over RSP/MSEK": 3.087,
    "out:LCP/MSEK": -0.469,
    "out:ROI% old": 13.11,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2242.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4197.07,
    "out:PV (% sold (El))": 16.88,
    "out:PV (kWh self-consumed)": 20672.07,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -31820,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202326,
    "out:DH kr savings": 251069,
    "out:El kr savings": -54094,
    "out:El kr sold": 5351,
    "out:El kr saved": 35143,
    "out:El kr return": 40494,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 107021,
    "out:% savings (space heating)": 62.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 30.2,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91,
    "out:Electricity (inc PV)": 30.1,
    "out:Total Energy Use Pre PV": 84.91,
    "out:Total Energy Use Post PV": 74.1,
    "out:Primary Energy": 80.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.86,
    "out:CO2 Operational/m2": 10.73,
    "out:Total CO2/m2": 48.59,
    "out:Total CO2 (tons)": 108.11,
    "out:Klimatpaverkan": -34.33,
    "out:Initial Cost/MSEK": 5.773875,
    "out:Running cost/(Apt SEK y)": 20642,
    "out:Running Cost over RSP/MSEK": 2.902,
    "out:LCP/MSEK": -0.339,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2237.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4217.51,
    "out:PV (% sold (El))": 16.96,
    "out:PV (kWh self-consumed)": 20651.63,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 284800,
    "out:EL kWh savings": -31595,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210834,
    "out:DH kr savings": 259168,
    "out:El kr savings": -53711,
    "out:El kr sold": 5377,
    "out:El kr saved": 35108,
    "out:El kr return": 40485,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 98769,
    "out:% savings (space heating)": 65.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.48,
    "out:Etvv": 0,
    "out:Ef": 30.1,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 27.64,
    "out:Total Energy Use Pre PV": 92.66,
    "out:Total Energy Use Post PV": 78.64,
    "out:Primary Energy": 79.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.79,
    "out:CO2 Operational/m2": -27.51,
    "out:Total CO2/m2": 22.28,
    "out:Total CO2 (tons)": 49.58,
    "out:Klimatpaverkan": -92.86,
    "out:Initial Cost/MSEK": 6.06725,
    "out:Running cost/(Apt SEK y)": 21145,
    "out:Running Cost over RSP/MSEK": 2.98,
    "out:LCP/MSEK": -0.711,
    "out:ROI% old": 12.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2063.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21999.41,
    "out:PV (% sold (El))": 44.23,
    "out:PV (kWh self-consumed)": 27738.87,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -26122,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228637,
    "out:DH kr savings": 244995,
    "out:El kr savings": -44407,
    "out:El kr sold": 28049,
    "out:El kr saved": 47156,
    "out:El kr return": 75205,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113738,
    "out:% savings (space heating)": 60.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 42.73,
    "out:Etvv": 0,
    "out:Ef": 27.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.58,
    "out:Electricity (inc PV)": 27.55,
    "out:Total Energy Use Pre PV": 88.58,
    "out:Total Energy Use Post PV": 74.55,
    "out:Primary Energy": 76.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.79,
    "out:CO2 Operational/m2": -29.04,
    "out:Total CO2/m2": 20.76,
    "out:Total CO2 (tons)": 46.19,
    "out:Klimatpaverkan": -96.25,
    "out:Initial Cost/MSEK": 6.122875,
    "out:Running cost/(Apt SEK y)": 19830,
    "out:Running Cost over RSP/MSEK": 2.795,
    "out:LCP/MSEK": -0.581,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2059.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 709,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22032.24,
    "out:PV (% sold (El))": 44.3,
    "out:PV (kWh self-consumed)": 27706.04,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -25932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237100,
    "out:DH kr savings": 253094,
    "out:El kr savings": -44085,
    "out:El kr sold": 28091,
    "out:El kr saved": 47100,
    "out:El kr return": 75191,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 105411,
    "out:% savings (space heating)": 63.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.09,
    "out:Etvv": 0,
    "out:Ef": 27.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.99,
    "out:Electricity (inc PV)": 27.31,
    "out:Total Energy Use Pre PV": 88.99,
    "out:Total Energy Use Post PV": 75.31,
    "out:Primary Energy": 77.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.22,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": 20.87,
    "out:Total CO2 (tons)": 46.44,
    "out:Klimatpaverkan": -96,
    "out:Initial Cost/MSEK": 6.181125,
    "out:Running cost/(Apt SEK y)": 20037,
    "out:Running Cost over RSP/MSEK": 2.824,
    "out:LCP/MSEK": -0.669,
    "out:ROI% old": 12.74,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2025.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 705,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22295.07,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 27443.21,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275900,
    "out:EL kWh savings": -25388,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236335,
    "out:DH kr savings": 251069,
    "out:El kr savings": -43160,
    "out:El kr sold": 28426,
    "out:El kr saved": 46653,
    "out:El kr return": 75080,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 107021,
    "out:% savings (space heating)": 62.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 27.31,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91,
    "out:Electricity (inc PV)": 27.22,
    "out:Total Energy Use Pre PV": 84.91,
    "out:Total Energy Use Post PV": 71.22,
    "out:Primary Energy": 75.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.22,
    "out:CO2 Operational/m2": -30.88,
    "out:Total CO2/m2": 19.34,
    "out:Total CO2 (tons)": 43.02,
    "out:Klimatpaverkan": -99.42,
    "out:Initial Cost/MSEK": 6.23675,
    "out:Running cost/(Apt SEK y)": 18720,
    "out:Running Cost over RSP/MSEK": 2.638,
    "out:LCP/MSEK": -0.539,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2021.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22332.71,
    "out:PV (% sold (El))": 44.9,
    "out:PV (kWh self-consumed)": 27405.57,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 284800,
    "out:EL kWh savings": -25186,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244826,
    "out:DH kr savings": 259168,
    "out:El kr savings": -42816,
    "out:El kr sold": 28474,
    "out:El kr saved": 46589,
    "out:El kr return": 75064,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 98769,
    "out:% savings (space heating)": 65.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.48,
    "out:Etvv": 0,
    "out:Ef": 27.22,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.91,
    "out:Electricity (inc PV)": 53.12,
    "out:Total Energy Use Pre PV": 63.91,
    "out:Total Energy Use Post PV": 59.12,
    "out:Primary Energy": 98.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 9.8,
    "out:Total CO2/m2": 35.54,
    "out:Total CO2 (tons)": 79.08,
    "out:Klimatpaverkan": -63.36,
    "out:Initial Cost/MSEK": 6.420125,
    "out:Running cost/(Apt SEK y)": 14118,
    "out:Running Cost over RSP/MSEK": 1.968,
    "out:LCP/MSEK": -0.052,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4053.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 812,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -82818,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195318,
    "out:DH kr savings": 336108,
    "out:El kr savings": -140790,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13794,
    "out:% savings (space heating)": 95.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.49,
    "out:Etvv": 0,
    "out:Ef": 53.12,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.17,
    "out:Electricity (inc PV)": 52.21,
    "out:Total Energy Use Pre PV": 62.17,
    "out:Total Energy Use Post PV": 57.21,
    "out:Primary Energy": 95.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 9.35,
    "out:Total CO2/m2": 35.08,
    "out:Total CO2 (tons)": 78.06,
    "out:Klimatpaverkan": -64.38,
    "out:Initial Cost/MSEK": 6.47575,
    "out:Running cost/(Apt SEK y)": 13637,
    "out:Running Cost over RSP/MSEK": 1.9,
    "out:LCP/MSEK": -0.04,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4001.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 820,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -80793,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200784,
    "out:DH kr savings": 338133,
    "out:El kr savings": -137349,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10357,
    "out:% savings (space heating)": 96.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 52.21,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 52.11,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 57.11,
    "out:Primary Energy": 95.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 9.23,
    "out:Total CO2/m2": 35.39,
    "out:Total CO2 (tons)": 78.74,
    "out:Klimatpaverkan": -63.7,
    "out:Initial Cost/MSEK": 6.534,
    "out:Running cost/(Apt SEK y)": 13449,
    "out:Running Cost over RSP/MSEK": 1.874,
    "out:LCP/MSEK": -0.072,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3939.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 824,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -80558,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201185,
    "out:DH kr savings": 338133,
    "out:El kr savings": -136948,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11276,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.09,
    "out:Etvv": 0,
    "out:Ef": 52.11,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.46,
    "out:Electricity (inc PV)": 51.17,
    "out:Total Energy Use Pre PV": 59.46,
    "out:Total Energy Use Post PV": 55.17,
    "out:Primary Energy": 93.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 8.76,
    "out:Total CO2/m2": 34.92,
    "out:Total CO2 (tons)": 77.69,
    "out:Klimatpaverkan": -64.75,
    "out:Initial Cost/MSEK": 6.589625,
    "out:Running cost/(Apt SEK y)": 12953,
    "out:Running Cost over RSP/MSEK": 1.805,
    "out:LCP/MSEK": -0.058,
    "out:ROI% old": 14.16,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3882,
    "out:Return %": 13,
    "out:Return/kSEK/y": 833,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -78481,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206739,
    "out:DH kr savings": 340158,
    "out:El kr savings": -133418,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8386,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 51.17,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.91,
    "out:Electricity (inc PV)": 42.78,
    "out:Total Energy Use Pre PV": 63.91,
    "out:Total Energy Use Post PV": 48.78,
    "out:Primary Energy": 79.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": 5.81,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 97.67,
    "out:Klimatpaverkan": -44.77,
    "out:Initial Cost/MSEK": 6.883,
    "out:Running cost/(Apt SEK y)": 11798,
    "out:Running Cost over RSP/MSEK": 1.65,
    "out:LCP/MSEK": -0.197,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1244.76,
    "out:PV (% sold (El))": 5.01,
    "out:PV (kWh self-consumed)": 23624.38,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -59816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236009,
    "out:DH kr savings": 336108,
    "out:El kr savings": -101687,
    "out:El kr sold": 1587,
    "out:El kr saved": 40161,
    "out:El kr return": 41749,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13794,
    "out:% savings (space heating)": 95.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.49,
    "out:Etvv": 0,
    "out:Ef": 42.78,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.17,
    "out:Electricity (inc PV)": 41.93,
    "out:Total Energy Use Pre PV": 62.17,
    "out:Total Energy Use Post PV": 46.93,
    "out:Primary Energy": 77.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": 5.16,
    "out:Total CO2/m2": 43.25,
    "out:Total CO2 (tons)": 96.24,
    "out:Klimatpaverkan": -46.2,
    "out:Initial Cost/MSEK": 6.938625,
    "out:Running cost/(Apt SEK y)": 11320,
    "out:Running Cost over RSP/MSEK": 1.583,
    "out:LCP/MSEK": -0.185,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3297.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 862,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1331.47,
    "out:PV (% sold (El))": 5.35,
    "out:PV (kWh self-consumed)": 23537.67,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -57928,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241354,
    "out:DH kr savings": 338133,
    "out:El kr savings": -98477,
    "out:El kr sold": 1698,
    "out:El kr saved": 40014,
    "out:El kr return": 41712,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10357,
    "out:% savings (space heating)": 96.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 41.93,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 41.84,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 46.84,
    "out:Primary Energy": 77.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 4.81,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 96.39,
    "out:Klimatpaverkan": -46.05,
    "out:Initial Cost/MSEK": 6.99675,
    "out:Running cost/(Apt SEK y)": 11135,
    "out:Running Cost over RSP/MSEK": 1.557,
    "out:LCP/MSEK": -0.218,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3237.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1440.59,
    "out:PV (% sold (El))": 5.79,
    "out:PV (kWh self-consumed)": 23428.55,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -57708,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241866,
    "out:DH kr savings": 338133,
    "out:El kr savings": -98104,
    "out:El kr sold": 1837,
    "out:El kr saved": 39829,
    "out:El kr return": 41665,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11276,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.09,
    "out:Etvv": 0,
    "out:Ef": 41.84,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.46,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 59.46,
    "out:Total Energy Use Post PV": 44.97,
    "out:Primary Energy": 75.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 4.12,
    "out:Total CO2/m2": 42.63,
    "out:Total CO2 (tons)": 94.85,
    "out:Klimatpaverkan": -47.59,
    "out:Initial Cost/MSEK": 7.052375,
    "out:Running cost/(Apt SEK y)": 10641,
    "out:Running Cost over RSP/MSEK": 1.488,
    "out:LCP/MSEK": -0.204,
    "out:ROI% old": 13.87,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3183,
    "out:Return %": 12,
    "out:Return/kSEK/y": 874,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1544.64,
    "out:PV (% sold (El))": 6.21,
    "out:PV (kWh self-consumed)": 23324.5,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -55778,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247305,
    "out:DH kr savings": 340158,
    "out:El kr savings": -94822,
    "out:El kr sold": 1969,
    "out:El kr saved": 39652,
    "out:El kr return": 41621,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8386,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.91,
    "out:Electricity (inc PV)": 38.55,
    "out:Total Energy Use Pre PV": 63.91,
    "out:Total Energy Use Post PV": 44.55,
    "out:Primary Energy": 71.83,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.44,
    "out:CO2 Operational/m2": -27.1,
    "out:Total CO2/m2": 23.34,
    "out:Total CO2 (tons)": 51.93,
    "out:Klimatpaverkan": -90.51,
    "out:Initial Cost/MSEK": 7.34575,
    "out:Running cost/(Apt SEK y)": 9788,
    "out:Running Cost over RSP/MSEK": 1.374,
    "out:LCP/MSEK": -0.384,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3007.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 890,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15562.59,
    "out:PV (% sold (El))": 31.29,
    "out:PV (kWh self-consumed)": 34175.69,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -50396,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270278,
    "out:DH kr savings": 336108,
    "out:El kr savings": -85672,
    "out:El kr sold": 19842,
    "out:El kr saved": 58099,
    "out:El kr return": 77941,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13794,
    "out:% savings (space heating)": 95.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.49,
    "out:Etvv": 0,
    "out:Ef": 38.55,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.17,
    "out:Electricity (inc PV)": 37.79,
    "out:Total Energy Use Pre PV": 62.17,
    "out:Total Energy Use Post PV": 42.79,
    "out:Primary Energy": 69.78,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.44,
    "out:CO2 Operational/m2": -28.16,
    "out:Total CO2/m2": 22.28,
    "out:Total CO2 (tons)": 49.58,
    "out:Klimatpaverkan": -92.86,
    "out:Initial Cost/MSEK": 7.401375,
    "out:Running cost/(Apt SEK y)": 9314,
    "out:Running Cost over RSP/MSEK": 1.308,
    "out:LCP/MSEK": -0.373,
    "out:ROI% old": 13.57,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2963.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 898,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15828.32,
    "out:PV (% sold (El))": 31.82,
    "out:PV (kWh self-consumed)": 33909.96,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -48716,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275497,
    "out:DH kr savings": 338133,
    "out:El kr savings": -82817,
    "out:El kr sold": 20181,
    "out:El kr saved": 57647,
    "out:El kr return": 77828,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10357,
    "out:% savings (space heating)": 96.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 37.79,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 37.71,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 42.71,
    "out:Primary Energy": 70.04,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": -29,
    "out:Total CO2/m2": 21.86,
    "out:Total CO2 (tons)": 48.64,
    "out:Klimatpaverkan": -93.8,
    "out:Initial Cost/MSEK": 7.459625,
    "out:Running cost/(Apt SEK y)": 9133,
    "out:Running Cost over RSP/MSEK": 1.283,
    "out:LCP/MSEK": -0.406,
    "out:ROI% old": 13.51,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2910.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16151.48,
    "out:PV (% sold (El))": 32.47,
    "out:PV (kWh self-consumed)": 33586.8,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -48520,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276242,
    "out:DH kr savings": 338133,
    "out:El kr savings": -82485,
    "out:El kr sold": 20593,
    "out:El kr saved": 57098,
    "out:El kr return": 77691,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 11276,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.09,
    "out:Etvv": 0,
    "out:Ef": 37.71,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.46,
    "out:Electricity (inc PV)": 36.93,
    "out:Total Energy Use Pre PV": 59.46,
    "out:Total Energy Use Post PV": 40.93,
    "out:Primary Energy": 68.01,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": -30.14,
    "out:Total CO2/m2": 20.72,
    "out:Total CO2 (tons)": 46.11,
    "out:Klimatpaverkan": -96.33,
    "out:Initial Cost/MSEK": 7.51525,
    "out:Running cost/(Apt SEK y)": 8644,
    "out:Running Cost over RSP/MSEK": 1.214,
    "out:LCP/MSEK": -0.393,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2862.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 910,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16449.02,
    "out:PV (% sold (El))": 33.07,
    "out:PV (kWh self-consumed)": 33289.26,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -46802,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281566,
    "out:DH kr savings": 340158,
    "out:El kr savings": -79564,
    "out:El kr sold": 20973,
    "out:El kr saved": 56592,
    "out:El kr return": 77564,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8386,
    "out:% savings (space heating)": 97.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.19,
    "out:Etvv": 0,
    "out:Ef": 36.93,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.71,
    "out:Electricity (inc PV)": 51.76,
    "out:Total Energy Use Pre PV": 60.71,
    "out:Total Energy Use Post PV": 56.76,
    "out:Primary Energy": 95.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 34.89,
    "out:Total CO2 (tons)": 77.63,
    "out:Klimatpaverkan": -64.81,
    "out:Initial Cost/MSEK": 6.467625,
    "out:Running cost/(Apt SEK y)": 13332,
    "out:Running Cost over RSP/MSEK": 1.858,
    "out:LCP/MSEK": 0.011,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 229.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3899.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -79799,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202475,
    "out:DH kr savings": 338133,
    "out:El kr savings": -135658,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10542,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.97,
    "out:Etvv": 0,
    "out:Ef": 51.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.88,
    "out:Electricity (inc PV)": 50.82,
    "out:Total Energy Use Pre PV": 58.88,
    "out:Total Energy Use Post PV": 54.82,
    "out:Primary Energy": 92.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.74,
    "out:CO2 Operational/m2": 8.68,
    "out:Total CO2/m2": 34.42,
    "out:Total CO2 (tons)": 76.58,
    "out:Klimatpaverkan": -65.86,
    "out:Initial Cost/MSEK": 6.52325,
    "out:Running cost/(Apt SEK y)": 12831,
    "out:Running Cost over RSP/MSEK": 1.788,
    "out:LCP/MSEK": 0.025,
    "out:ROI% old": 14.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3841.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 835,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -77707,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208056,
    "out:DH kr savings": 340158,
    "out:El kr savings": -132102,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7808,
    "out:% savings (space heating)": 97.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 50.82,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.35,
    "out:Electricity (inc PV)": 50.94,
    "out:Total Energy Use Pre PV": 58.35,
    "out:Total Energy Use Post PV": 54.94,
    "out:Primary Energy": 93.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 8.61,
    "out:Total CO2/m2": 34.77,
    "out:Total CO2 (tons)": 77.37,
    "out:Klimatpaverkan": -65.07,
    "out:Initial Cost/MSEK": 6.5815,
    "out:Running cost/(Apt SEK y)": 12720,
    "out:Running Cost over RSP/MSEK": 1.772,
    "out:LCP/MSEK": -0.017,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3804.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -77968,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207612,
    "out:DH kr savings": 340158,
    "out:El kr savings": -132546,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8974,
    "out:% savings (space heating)": 96.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.68,
    "out:Etvv": 0,
    "out:Ef": 50.94,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.47,
    "out:Electricity (inc PV)": 49.99,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 52.99,
    "out:Primary Energy": 91.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 8.14,
    "out:Total CO2/m2": 34.3,
    "out:Total CO2 (tons)": 76.31,
    "out:Klimatpaverkan": -66.13,
    "out:Initial Cost/MSEK": 6.637125,
    "out:Running cost/(Apt SEK y)": 12210,
    "out:Running Cost over RSP/MSEK": 1.701,
    "out:LCP/MSEK": -0.002,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3742.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -75840,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213254,
    "out:DH kr savings": 342183,
    "out:El kr savings": -128928,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6549,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 49.99,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.71,
    "out:Electricity (inc PV)": 41.52,
    "out:Total Energy Use Pre PV": 60.71,
    "out:Total Energy Use Post PV": 46.52,
    "out:Primary Energy": 76.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": 4.58,
    "out:Total CO2/m2": 42.67,
    "out:Total CO2 (tons)": 94.94,
    "out:Klimatpaverkan": -47.5,
    "out:Initial Cost/MSEK": 6.9305,
    "out:Running cost/(Apt SEK y)": 11019,
    "out:Running Cost over RSP/MSEK": 1.541,
    "out:LCP/MSEK": -0.135,
    "out:ROI% old": 14.01,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3200,
    "out:Return %": 13,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1511.51,
    "out:PV (% sold (El))": 6.08,
    "out:PV (kWh self-consumed)": 23357.63,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -57002,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243156,
    "out:DH kr savings": 338133,
    "out:El kr savings": -96904,
    "out:El kr sold": 1927,
    "out:El kr saved": 39708,
    "out:El kr return": 41635,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10542,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.97,
    "out:Etvv": 0,
    "out:Ef": 41.52,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.88,
    "out:Electricity (inc PV)": 40.65,
    "out:Total Energy Use Pre PV": 58.88,
    "out:Total Energy Use Post PV": 44.65,
    "out:Primary Energy": 74.62,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.09,
    "out:CO2 Operational/m2": 3.87,
    "out:Total CO2/m2": 41.96,
    "out:Total CO2 (tons)": 93.37,
    "out:Klimatpaverkan": -49.07,
    "out:Initial Cost/MSEK": 6.986125,
    "out:Running cost/(Apt SEK y)": 10521,
    "out:Running Cost over RSP/MSEK": 1.471,
    "out:LCP/MSEK": -0.121,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3144.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1621.14,
    "out:PV (% sold (El))": 6.52,
    "out:PV (kWh self-consumed)": 23248,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -55060,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248624,
    "out:DH kr savings": 340158,
    "out:El kr savings": -93601,
    "out:El kr sold": 2067,
    "out:El kr saved": 39522,
    "out:El kr return": 41589,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7808,
    "out:% savings (space heating)": 97.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 40.65,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.35,
    "out:Electricity (inc PV)": 40.75,
    "out:Total Energy Use Pre PV": 58.35,
    "out:Total Energy Use Post PV": 44.75,
    "out:Primary Energy": 75.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 3.65,
    "out:Total CO2/m2": 42.16,
    "out:Total CO2 (tons)": 93.8,
    "out:Klimatpaverkan": -48.64,
    "out:Initial Cost/MSEK": 7.04425,
    "out:Running cost/(Apt SEK y)": 10411,
    "out:Running Cost over RSP/MSEK": 1.456,
    "out:LCP/MSEK": -0.164,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3109,
    "out:Return %": 12,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1693.19,
    "out:PV (% sold (El))": 6.81,
    "out:PV (kWh self-consumed)": 23175.95,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -55302,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248303,
    "out:DH kr savings": 340158,
    "out:El kr savings": -94013,
    "out:El kr sold": 2159,
    "out:El kr saved": 39399,
    "out:El kr return": 41558,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8974,
    "out:% savings (space heating)": 96.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.68,
    "out:Etvv": 0,
    "out:Ef": 40.75,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.47,
    "out:Electricity (inc PV)": 39.87,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 42.87,
    "out:Primary Energy": 73.06,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.51,
    "out:CO2 Operational/m2": 2.9,
    "out:Total CO2/m2": 41.41,
    "out:Total CO2 (tons)": 92.15,
    "out:Klimatpaverkan": -50.29,
    "out:Initial Cost/MSEK": 7.099875,
    "out:Running cost/(Apt SEK y)": 9904,
    "out:Running Cost over RSP/MSEK": 1.385,
    "out:LCP/MSEK": -0.149,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3050.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 888,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1817.12,
    "out:PV (% sold (El))": 7.31,
    "out:PV (kWh self-consumed)": 23052.02,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -53331,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253837,
    "out:DH kr savings": 342183,
    "out:El kr savings": -90662,
    "out:El kr sold": 2317,
    "out:El kr saved": 39188,
    "out:El kr return": 41505,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6549,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 39.87,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.71,
    "out:Electricity (inc PV)": 37.42,
    "out:Total Energy Use Pre PV": 60.71,
    "out:Total Energy Use Post PV": 42.42,
    "out:Primary Energy": 69.44,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.44,
    "out:CO2 Operational/m2": -29.54,
    "out:Total CO2/m2": 20.91,
    "out:Total CO2 (tons)": 46.52,
    "out:Klimatpaverkan": -95.92,
    "out:Initial Cost/MSEK": 7.39325,
    "out:Running cost/(Apt SEK y)": 9021,
    "out:Running Cost over RSP/MSEK": 1.267,
    "out:LCP/MSEK": -0.324,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2877.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16355.38,
    "out:PV (% sold (El))": 32.88,
    "out:PV (kWh self-consumed)": 33382.9,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -47892,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277570,
    "out:DH kr savings": 338133,
    "out:El kr savings": -81417,
    "out:El kr sold": 20853,
    "out:El kr saved": 56751,
    "out:El kr return": 77604,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 10542,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.97,
    "out:Etvv": 0,
    "out:Ef": 37.42,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.88,
    "out:Electricity (inc PV)": 36.65,
    "out:Total Energy Use Pre PV": 58.88,
    "out:Total Energy Use Post PV": 40.65,
    "out:Primary Energy": 67.42,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.44,
    "out:CO2 Operational/m2": -30.7,
    "out:Total CO2/m2": 19.75,
    "out:Total CO2 (tons)": 43.93,
    "out:Klimatpaverkan": -98.51,
    "out:Initial Cost/MSEK": 7.448875,
    "out:Running cost/(Apt SEK y)": 8527,
    "out:Running Cost over RSP/MSEK": 1.198,
    "out:LCP/MSEK": -0.311,
    "out:ROI% old": 13.69,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2828.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16662.53,
    "out:PV (% sold (El))": 33.5,
    "out:PV (kWh self-consumed)": 33075.75,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -46163,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282925,
    "out:DH kr savings": 340158,
    "out:El kr savings": -78477,
    "out:El kr sold": 21245,
    "out:El kr saved": 56229,
    "out:El kr return": 77474,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7808,
    "out:% savings (space heating)": 97.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.08,
    "out:Etvv": 0,
    "out:Ef": 36.65,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.35,
    "out:Electricity (inc PV)": 36.74,
    "out:Total Energy Use Pre PV": 58.35,
    "out:Total Energy Use Post PV": 40.74,
    "out:Primary Energy": 68.02,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": -31.21,
    "out:Total CO2/m2": 19.66,
    "out:Total CO2 (tons)": 43.73,
    "out:Klimatpaverkan": -98.71,
    "out:Initial Cost/MSEK": 7.507125,
    "out:Running cost/(Apt SEK y)": 8420,
    "out:Running Cost over RSP/MSEK": 1.183,
    "out:LCP/MSEK": -0.354,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2797.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 914,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16859.02,
    "out:PV (% sold (El))": 33.9,
    "out:PV (kWh self-consumed)": 32879.26,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -46379,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282809,
    "out:DH kr savings": 340158,
    "out:El kr savings": -78844,
    "out:El kr sold": 21495,
    "out:El kr saved": 55895,
    "out:El kr return": 77390,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8974,
    "out:% savings (space heating)": 96.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.68,
    "out:Etvv": 0,
    "out:Ef": 36.74,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.47,
    "out:Electricity (inc PV)": 35.96,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 38.96,
    "out:Primary Energy": 66.02,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.86,
    "out:CO2 Operational/m2": -32.42,
    "out:Total CO2/m2": 18.44,
    "out:Total CO2 (tons)": 41.04,
    "out:Klimatpaverkan": -101.4,
    "out:Initial Cost/MSEK": 7.56275,
    "out:Running cost/(Apt SEK y)": 7918,
    "out:Running Cost over RSP/MSEK": 1.113,
    "out:LCP/MSEK": -0.339,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 382.1,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2745.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 923,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17186.16,
    "out:PV (% sold (El))": 34.55,
    "out:PV (kWh self-consumed)": 32552.12,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -44624,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288234,
    "out:DH kr savings": 342183,
    "out:El kr savings": -75861,
    "out:El kr sold": 21912,
    "out:El kr saved": 55339,
    "out:El kr return": 77251,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6549,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 35.96,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 119.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.94,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 68.68,
    "out:Total CO2 (tons)": 152.8,
    "out:Klimatpaverkan": 10.36,
    "out:Initial Cost/MSEK": 2.071625,
    "out:Running cost/(Apt SEK y)": 51748,
    "out:Running Cost over RSP/MSEK": 7.284,
    "out:LCP/MSEK": -1.019,
    "out:ROI% old": 7.26,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 51175,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46569,
    "out:DH kr savings": 46569,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 116.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.94,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 66.88,
    "out:Total CO2 (tons)": 148.8,
    "out:Klimatpaverkan": 6.36,
    "out:Initial Cost/MSEK": 2.12725,
    "out:Running cost/(Apt SEK y)": 50123,
    "out:Running Cost over RSP/MSEK": 7.055,
    "out:LCP/MSEK": -0.846,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 164,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 56693,
    "out:DH kr savings": 56693,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 117.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.36,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 66.58,
    "out:Total CO2 (tons)": 148.14,
    "out:Klimatpaverkan": 5.7,
    "out:Initial Cost/MSEK": 2.1855,
    "out:Running cost/(Apt SEK y)": 49473,
    "out:Running Cost over RSP/MSEK": 6.963,
    "out:LCP/MSEK": -0.813,
    "out:ROI% old": 8.97,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 66750,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 60742,
    "out:DH kr savings": 60742,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 114.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.36,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 65.14,
    "out:Total CO2 (tons)": 144.93,
    "out:Klimatpaverkan": 2.49,
    "out:Initial Cost/MSEK": 2.241125,
    "out:Running cost/(Apt SEK y)": 48174,
    "out:Running Cost over RSP/MSEK": 6.78,
    "out:LCP/MSEK": -0.685,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68841,
    "out:DH kr savings": 68841,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 110.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.29,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 50.03,
    "out:Total CO2 (tons)": 111.32,
    "out:Klimatpaverkan": -31.12,
    "out:Initial Cost/MSEK": 2.5345,
    "out:Running cost/(Apt SEK y)": 49714,
    "out:Running Cost over RSP/MSEK": 7.005,
    "out:LCP/MSEK": -1.203,
    "out:ROI% old": 7.5,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 17.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 51175,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83168,
    "out:DH kr savings": 46569,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 107.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.29,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 48.23,
    "out:Total CO2 (tons)": 107.32,
    "out:Klimatpaverkan": -35.12,
    "out:Initial Cost/MSEK": 2.590125,
    "out:Running cost/(Apt SEK y)": 48090,
    "out:Running Cost over RSP/MSEK": 6.776,
    "out:LCP/MSEK": -1.03,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93292,
    "out:DH kr savings": 56693,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 107.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.71,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 47.94,
    "out:Total CO2 (tons)": 106.66,
    "out:Klimatpaverkan": -35.78,
    "out:Initial Cost/MSEK": 2.64825,
    "out:Running cost/(Apt SEK y)": 47440,
    "out:Running Cost over RSP/MSEK": 6.685,
    "out:LCP/MSEK": -0.997,
    "out:ROI% old": 8.91,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66750,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97341,
    "out:DH kr savings": 60742,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 104.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.71,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 46.5,
    "out:Total CO2 (tons)": 103.45,
    "out:Klimatpaverkan": -38.99,
    "out:Initial Cost/MSEK": 2.703875,
    "out:Running cost/(Apt SEK y)": 46141,
    "out:Running Cost over RSP/MSEK": 6.502,
    "out:LCP/MSEK": -0.869,
    "out:ROI% old": 9.69,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105440,
    "out:DH kr savings": 68841,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 109.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.65,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": 9.71,
    "out:Total CO2 (tons)": 21.6,
    "out:Klimatpaverkan": -120.84,
    "out:Initial Cost/MSEK": 2.99725,
    "out:Running cost/(Apt SEK y)": 47908,
    "out:Running Cost over RSP/MSEK": 6.758,
    "out:LCP/MSEK": -1.419,
    "out:ROI% old": 7.52,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 18.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 51175,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115679,
    "out:DH kr savings": 46569,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 105.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.65,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 7.91,
    "out:Total CO2 (tons)": 17.59,
    "out:Klimatpaverkan": -124.85,
    "out:Initial Cost/MSEK": 3.052875,
    "out:Running cost/(Apt SEK y)": 46284,
    "out:Running Cost over RSP/MSEK": 6.529,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 8.46,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 22.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62300,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125802,
    "out:DH kr savings": 56693,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 106.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.07,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 7.61,
    "out:Total CO2 (tons)": 16.93,
    "out:Klimatpaverkan": -125.51,
    "out:Initial Cost/MSEK": 3.111125,
    "out:Running cost/(Apt SEK y)": 45634,
    "out:Running Cost over RSP/MSEK": 6.438,
    "out:LCP/MSEK": -1.212,
    "out:ROI% old": 8.72,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 245,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 66750,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129852,
    "out:DH kr savings": 60742,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 103.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.07,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 6.17,
    "out:Total CO2 (tons)": 13.73,
    "out:Klimatpaverkan": -128.71,
    "out:Initial Cost/MSEK": 3.16675,
    "out:Running cost/(Apt SEK y)": 44334,
    "out:Running Cost over RSP/MSEK": 6.255,
    "out:LCP/MSEK": -1.085,
    "out:ROI% old": 9.39,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137951,
    "out:DH kr savings": 68841,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 116.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.94,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 65.44,
    "out:Total CO2 (tons)": 145.59,
    "out:Klimatpaverkan": 3.15,
    "out:Initial Cost/MSEK": 2.119125,
    "out:Running cost/(Apt SEK y)": 48824,
    "out:Running Cost over RSP/MSEK": 6.872,
    "out:LCP/MSEK": -0.655,
    "out:ROI% old": 9.87,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 64792,
    "out:DH kr savings": 64792,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 113.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.94,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 64,
    "out:Total CO2 (tons)": 142.39,
    "out:Klimatpaverkan": -0.05,
    "out:Initial Cost/MSEK": 2.17475,
    "out:Running cost/(Apt SEK y)": 47524,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": -0.527,
    "out:ROI% old": 10.82,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 211,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72891,
    "out:DH kr savings": 72891,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 114.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.36,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 64.06,
    "out:Total CO2 (tons)": 142.53,
    "out:Klimatpaverkan": 0.09,
    "out:Initial Cost/MSEK": 2.233,
    "out:Running cost/(Apt SEK y)": 47199,
    "out:Running Cost over RSP/MSEK": 6.643,
    "out:LCP/MSEK": -0.54,
    "out:ROI% old": 10.83,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74916,
    "out:DH kr savings": 74916,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 145.9,
    "out:Primary Energy": 111.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.36,
    "out:CO2 Operational/m2": 48.9,
    "out:Total CO2/m2": 62.26,
    "out:Total CO2 (tons)": 138.52,
    "out:Klimatpaverkan": -3.92,
    "out:Initial Cost/MSEK": 2.288625,
    "out:Running cost/(Apt SEK y)": 45575,
    "out:Running Cost over RSP/MSEK": 6.414,
    "out:LCP/MSEK": -0.367,
    "out:ROI% old": 12,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85039,
    "out:DH kr savings": 85039,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 107.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.29,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 46.79,
    "out:Total CO2 (tons)": 104.12,
    "out:Klimatpaverkan": -38.32,
    "out:Initial Cost/MSEK": 2.582,
    "out:Running cost/(Apt SEK y)": 46790,
    "out:Running Cost over RSP/MSEK": 6.593,
    "out:LCP/MSEK": -0.839,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101391,
    "out:DH kr savings": 64792,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 103.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.29,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 45.35,
    "out:Total CO2 (tons)": 100.91,
    "out:Klimatpaverkan": -41.53,
    "out:Initial Cost/MSEK": 2.637625,
    "out:Running cost/(Apt SEK y)": 45491,
    "out:Running Cost over RSP/MSEK": 6.41,
    "out:LCP/MSEK": -0.712,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109490,
    "out:DH kr savings": 72891,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 104.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.71,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 45.42,
    "out:Total CO2 (tons)": 101.05,
    "out:Klimatpaverkan": -41.39,
    "out:Initial Cost/MSEK": 2.69575,
    "out:Running cost/(Apt SEK y)": 45166,
    "out:Running Cost over RSP/MSEK": 6.364,
    "out:LCP/MSEK": -0.724,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111514,
    "out:DH kr savings": 74916,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 140.73,
    "out:Primary Energy": 101.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.71,
    "out:CO2 Operational/m2": 17.9,
    "out:Total CO2/m2": 43.62,
    "out:Total CO2 (tons)": 97.05,
    "out:Klimatpaverkan": -45.39,
    "out:Initial Cost/MSEK": 2.751375,
    "out:Running cost/(Apt SEK y)": 43542,
    "out:Running Cost over RSP/MSEK": 6.136,
    "out:LCP/MSEK": -0.551,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121638,
    "out:DH kr savings": 85039,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 105.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.65,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 6.47,
    "out:Total CO2 (tons)": 14.39,
    "out:Klimatpaverkan": -128.05,
    "out:Initial Cost/MSEK": 3.04475,
    "out:Running cost/(Apt SEK y)": 44984,
    "out:Running Cost over RSP/MSEK": 6.346,
    "out:LCP/MSEK": -1.055,
    "out:ROI% old": 9.34,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 71200,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133901,
    "out:DH kr savings": 64792,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 102.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.65,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 5.03,
    "out:Total CO2 (tons)": 11.19,
    "out:Klimatpaverkan": -131.25,
    "out:Initial Cost/MSEK": 3.100375,
    "out:Running cost/(Apt SEK y)": 43685,
    "out:Running Cost over RSP/MSEK": 6.163,
    "out:LCP/MSEK": -0.927,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 80100,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142000,
    "out:DH kr savings": 72891,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.35,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 103.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.07,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 5.09,
    "out:Total CO2 (tons)": 11.32,
    "out:Klimatpaverkan": -131.12,
    "out:Initial Cost/MSEK": 3.158625,
    "out:Running cost/(Apt SEK y)": 43360,
    "out:Running Cost over RSP/MSEK": 6.117,
    "out:LCP/MSEK": -0.94,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144025,
    "out:DH kr savings": 74916,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 234408,
    "out:% savings (space heating)": 17.8,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 139.88,
    "out:Primary Energy": 100.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.07,
    "out:CO2 Operational/m2": -34.78,
    "out:Total CO2/m2": 3.29,
    "out:Total CO2 (tons)": 7.32,
    "out:Klimatpaverkan": -135.12,
    "out:Initial Cost/MSEK": 3.21425,
    "out:Running cost/(Apt SEK y)": 41736,
    "out:Running Cost over RSP/MSEK": 5.888,
    "out:LCP/MSEK": -0.766,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154149,
    "out:DH kr savings": 85039,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 224360,
    "out:% savings (space heating)": 21.32,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 40.85,
    "out:Total Energy Use Pre PV": 110.03,
    "out:Total Energy Use Post PV": 107.85,
    "out:Primary Energy": 112.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 29.8,
    "out:Total CO2/m2": 47.15,
    "out:Total CO2 (tons)": 104.9,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 3.924375,
    "out:Running cost/(Apt SEK y)": 30808,
    "out:Running Cost over RSP/MSEK": 4.324,
    "out:LCP/MSEK": 0.088,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 3011.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -55521,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118214,
    "out:DH kr savings": 212599,
    "out:El kr savings": -94385,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 148905,
    "out:% savings (space heating)": 47.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.6,
    "out:Etvv": 0,
    "out:Ef": 40.85,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.97,
    "out:Electricity (inc PV)": 40.76,
    "out:Total Energy Use Pre PV": 105.97,
    "out:Total Energy Use Post PV": 103.76,
    "out:Primary Energy": 109.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 28.35,
    "out:Total CO2/m2": 45.7,
    "out:Total CO2 (tons)": 101.69,
    "out:Klimatpaverkan": -40.75,
    "out:Initial Cost/MSEK": 3.98,
    "out:Running cost/(Apt SEK y)": 29497,
    "out:Running Cost over RSP/MSEK": 4.14,
    "out:LCP/MSEK": 0.217,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 3008,
    "out:Return %": 13,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -55316,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126660,
    "out:DH kr savings": 220698,
    "out:El kr savings": -94037,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139256,
    "out:% savings (space heating)": 51.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.38,
    "out:Etvv": 0,
    "out:Ef": 40.76,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 40.38,
    "out:Total Energy Use Pre PV": 105.29,
    "out:Total Energy Use Post PV": 103.38,
    "out:Primary Energy": 110.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.77,
    "out:CO2 Operational/m2": 28.26,
    "out:Total CO2/m2": 46.03,
    "out:Total CO2 (tons)": 102.42,
    "out:Klimatpaverkan": -40.02,
    "out:Initial Cost/MSEK": 4.03825,
    "out:Running cost/(Apt SEK y)": 29354,
    "out:Running Cost over RSP/MSEK": 4.12,
    "out:LCP/MSEK": 0.178,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2960.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -54467,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128104,
    "out:DH kr savings": 220698,
    "out:El kr savings": -92593,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139789,
    "out:% savings (space heating)": 50.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.41,
    "out:Etvv": 0,
    "out:Ef": 40.38,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.22,
    "out:Electricity (inc PV)": 40.28,
    "out:Total Energy Use Pre PV": 101.22,
    "out:Total Energy Use Post PV": 99.28,
    "out:Primary Energy": 106.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.77,
    "out:CO2 Operational/m2": 26.81,
    "out:Total CO2/m2": 44.59,
    "out:Total CO2 (tons)": 99.2,
    "out:Klimatpaverkan": -43.24,
    "out:Initial Cost/MSEK": 4.093875,
    "out:Running cost/(Apt SEK y)": 28041,
    "out:Running Cost over RSP/MSEK": 3.935,
    "out:LCP/MSEK": 0.308,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2955.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -54243,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136583,
    "out:DH kr savings": 228797,
    "out:El kr savings": -92213,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 130225,
    "out:% savings (space heating)": 54.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.22,
    "out:Etvv": 0,
    "out:Ef": 40.28,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 31.58,
    "out:Total Energy Use Pre PV": 110.03,
    "out:Total Energy Use Post PV": 98.58,
    "out:Primary Energy": 95.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 20.38,
    "out:Total CO2/m2": 50.08,
    "out:Total CO2 (tons)": 111.44,
    "out:Klimatpaverkan": -31,
    "out:Initial Cost/MSEK": 4.38725,
    "out:Running cost/(Apt SEK y)": 28547,
    "out:Running Cost over RSP/MSEK": 4.015,
    "out:LCP/MSEK": -0.066,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2371.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3723.57,
    "out:PV (% sold (El))": 14.97,
    "out:PV (kWh self-consumed)": 21145.57,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -34882,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158047,
    "out:DH kr savings": 212599,
    "out:El kr savings": -59300,
    "out:El kr sold": 4748,
    "out:El kr saved": 35947,
    "out:El kr return": 40695,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 148905,
    "out:% savings (space heating)": 47.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.6,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.97,
    "out:Electricity (inc PV)": 31.5,
    "out:Total Energy Use Pre PV": 105.97,
    "out:Total Energy Use Post PV": 94.5,
    "out:Primary Energy": 92.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 18.91,
    "out:Total CO2/m2": 48.61,
    "out:Total CO2 (tons)": 108.16,
    "out:Klimatpaverkan": -34.28,
    "out:Initial Cost/MSEK": 4.442875,
    "out:Running cost/(Apt SEK y)": 27237,
    "out:Running Cost over RSP/MSEK": 3.83,
    "out:LCP/MSEK": 0.063,
    "out:ROI% old": 14.49,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2368.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3735.81,
    "out:PV (% sold (El))": 15.02,
    "out:PV (kWh self-consumed)": 21133.33,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -34700,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166471,
    "out:DH kr savings": 220698,
    "out:El kr savings": -58990,
    "out:El kr sold": 4763,
    "out:El kr saved": 35927,
    "out:El kr return": 40690,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139256,
    "out:% savings (space heating)": 51.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.38,
    "out:Etvv": 0,
    "out:Ef": 31.5,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 31.16,
    "out:Total Energy Use Pre PV": 105.29,
    "out:Total Energy Use Post PV": 94.16,
    "out:Primary Energy": 93.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 18.47,
    "out:Total CO2/m2": 48.6,
    "out:Total CO2 (tons)": 108.13,
    "out:Klimatpaverkan": -34.31,
    "out:Initial Cost/MSEK": 4.501,
    "out:Running cost/(Apt SEK y)": 27097,
    "out:Running Cost over RSP/MSEK": 3.811,
    "out:LCP/MSEK": 0.025,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2324.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3891.16,
    "out:PV (% sold (El))": 15.65,
    "out:PV (kWh self-consumed)": 20977.98,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -33944,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167954,
    "out:DH kr savings": 220698,
    "out:El kr savings": -57705,
    "out:El kr sold": 4961,
    "out:El kr saved": 35663,
    "out:El kr return": 40624,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139789,
    "out:% savings (space heating)": 50.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.41,
    "out:Etvv": 0,
    "out:Ef": 31.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.22,
    "out:Electricity (inc PV)": 31.07,
    "out:Total Energy Use Pre PV": 101.22,
    "out:Total Energy Use Post PV": 90.07,
    "out:Primary Energy": 90.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 16.99,
    "out:Total CO2/m2": 47.12,
    "out:Total CO2 (tons)": 104.83,
    "out:Klimatpaverkan": -37.61,
    "out:Initial Cost/MSEK": 4.556625,
    "out:Running cost/(Apt SEK y)": 25784,
    "out:Running Cost over RSP/MSEK": 3.626,
    "out:LCP/MSEK": 0.154,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 108.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2320.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3906.38,
    "out:PV (% sold (El))": 15.71,
    "out:PV (kWh self-consumed)": 20962.76,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -33745,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176410,
    "out:DH kr savings": 228797,
    "out:El kr savings": -57367,
    "out:El kr sold": 4981,
    "out:El kr saved": 35637,
    "out:El kr return": 40617,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 130225,
    "out:% savings (space heating)": 54.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.22,
    "out:Etvv": 0,
    "out:Ef": 31.07,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 28.55,
    "out:Total Energy Use Pre PV": 110.03,
    "out:Total Energy Use Post PV": 95.55,
    "out:Primary Energy": 90.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.06,
    "out:CO2 Operational/m2": -20.23,
    "out:Total CO2/m2": 21.83,
    "out:Total CO2 (tons)": 48.57,
    "out:Klimatpaverkan": -93.87,
    "out:Initial Cost/MSEK": 4.85,
    "out:Running cost/(Apt SEK y)": 26616,
    "out:Running Cost over RSP/MSEK": 3.75,
    "out:LCP/MSEK": -0.264,
    "out:ROI% old": 13.51,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 95.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2141.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 587,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21401.22,
    "out:PV (% sold (El))": 43.03,
    "out:PV (kWh self-consumed)": 28337.06,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 233625,
    "out:EL kWh savings": -28139,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192049,
    "out:DH kr savings": 212599,
    "out:El kr savings": -47837,
    "out:El kr sold": 27287,
    "out:El kr saved": 48173,
    "out:El kr return": 75460,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 148905,
    "out:% savings (space heating)": 47.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 55.6,
    "out:Etvv": 0,
    "out:Ef": 28.55,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.97,
    "out:Electricity (inc PV)": 28.47,
    "out:Total Energy Use Pre PV": 105.97,
    "out:Total Energy Use Post PV": 91.47,
    "out:Primary Energy": 87.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.06,
    "out:CO2 Operational/m2": -21.73,
    "out:Total CO2/m2": 20.33,
    "out:Total CO2 (tons)": 45.23,
    "out:Klimatpaverkan": -97.21,
    "out:Initial Cost/MSEK": 4.905625,
    "out:Running cost/(Apt SEK y)": 25306,
    "out:Running Cost over RSP/MSEK": 3.565,
    "out:LCP/MSEK": -0.135,
    "out:ROI% old": 13.89,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2138.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21424.77,
    "out:PV (% sold (El))": 43.08,
    "out:PV (kWh self-consumed)": 28313.51,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -27976,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200456,
    "out:DH kr savings": 220698,
    "out:El kr savings": -47559,
    "out:El kr sold": 27317,
    "out:El kr saved": 48133,
    "out:El kr return": 75450,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139256,
    "out:% savings (space heating)": 51.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 51.38,
    "out:Etvv": 0,
    "out:Ef": 28.47,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 28.17,
    "out:Total Energy Use Pre PV": 105.29,
    "out:Total Energy Use Post PV": 91.17,
    "out:Primary Energy": 88.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": -22.48,
    "out:Total CO2/m2": 19.99,
    "out:Total CO2 (tons)": 44.49,
    "out:Klimatpaverkan": -97.95,
    "out:Initial Cost/MSEK": 4.963875,
    "out:Running cost/(Apt SEK y)": 25169,
    "out:Running Cost over RSP/MSEK": 3.546,
    "out:LCP/MSEK": -0.174,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2099.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21721.85,
    "out:PV (% sold (El))": 43.67,
    "out:PV (kWh self-consumed)": 28016.43,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 242525,
    "out:EL kWh savings": -27297,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201988,
    "out:DH kr savings": 220698,
    "out:El kr savings": -46405,
    "out:El kr sold": 27695,
    "out:El kr saved": 47628,
    "out:El kr return": 75323,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 139789,
    "out:% savings (space heating)": 50.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 53.41,
    "out:Etvv": 0,
    "out:Ef": 28.17,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.22,
    "out:Electricity (inc PV)": 28.09,
    "out:Total Energy Use Pre PV": 101.22,
    "out:Total Energy Use Post PV": 87.09,
    "out:Primary Energy": 85.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": -24,
    "out:Total CO2/m2": 18.48,
    "out:Total CO2 (tons)": 41.12,
    "out:Klimatpaverkan": -101.32,
    "out:Initial Cost/MSEK": 5.0195,
    "out:Running cost/(Apt SEK y)": 23857,
    "out:Running Cost over RSP/MSEK": 3.362,
    "out:LCP/MSEK": -0.045,
    "out:ROI% old": 14.16,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2095.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21750.73,
    "out:PV (% sold (El))": 43.73,
    "out:PV (kWh self-consumed)": 27987.55,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 251425,
    "out:EL kWh savings": -27119,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210427,
    "out:DH kr savings": 228797,
    "out:El kr savings": -46102,
    "out:El kr sold": 27732,
    "out:El kr saved": 47579,
    "out:El kr return": 75311,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 130225,
    "out:% savings (space heating)": 54.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 49.22,
    "out:Etvv": 0,
    "out:Ef": 28.09,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.04,
    "out:Electricity (inc PV)": 40.22,
    "out:Total Energy Use Pre PV": 103.04,
    "out:Total Energy Use Post PV": 101.22,
    "out:Primary Energy": 109.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 27.51,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 99.81,
    "out:Klimatpaverkan": -42.63,
    "out:Initial Cost/MSEK": 3.971875,
    "out:Running cost/(Apt SEK y)": 28651,
    "out:Running Cost over RSP/MSEK": 4.021,
    "out:LCP/MSEK": 0.344,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2942.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 246975,
    "out:EL kWh savings": -54110,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132761,
    "out:DH kr savings": 224747,
    "out:El kr savings": -91987,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136806,
    "out:% savings (space heating)": 52.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.69,
    "out:Etvv": 0,
    "out:Ef": 40.22,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.97,
    "out:Electricity (inc PV)": 40.12,
    "out:Total Energy Use Pre PV": 98.97,
    "out:Total Energy Use Post PV": 97.12,
    "out:Primary Energy": 106.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.35,
    "out:CO2 Operational/m2": 26.06,
    "out:Total CO2/m2": 43.41,
    "out:Total CO2 (tons)": 96.58,
    "out:Klimatpaverkan": -45.86,
    "out:Initial Cost/MSEK": 4.0275,
    "out:Running cost/(Apt SEK y)": 27337,
    "out:Running Cost over RSP/MSEK": 3.836,
    "out:LCP/MSEK": 0.473,
    "out:ROI% old": 15.96,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 93.8,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2937.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255875,
    "out:EL kWh savings": -53881,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141248,
    "out:DH kr savings": 232846,
    "out:El kr savings": -91598,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 127271,
    "out:% savings (space heating)": 55.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 48.51,
    "out:Etvv": 0,
    "out:Ef": 40.12,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.42,
    "out:Electricity (inc PV)": 39.83,
    "out:Total Energy Use Pre PV": 99.42,
    "out:Total Energy Use Post PV": 97.83,
    "out:Primary Energy": 107.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.77,
    "out:CO2 Operational/m2": 26.35,
    "out:Total CO2/m2": 44.12,
    "out:Total CO2 (tons)": 98.16,
    "out:Klimatpaverkan": -44.28,
    "out:Initial Cost/MSEK": 4.08575,
    "out:Running cost/(Apt SEK y)": 27546,
    "out:Running Cost over RSP/MSEK": 3.865,
    "out:LCP/MSEK": 0.385,
    "out:ROI% old": 15.63,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2899.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 253650,
    "out:EL kWh savings": -53246,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140304,
    "out:DH kr savings": 230821,
    "out:El kr savings": -90518,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 129751,
    "out:% savings (space heating)": 54.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 50.97,
    "out:Etvv": 0,
    "out:Ef": 39.83,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.34,
    "out:Electricity (inc PV)": 39.72,
    "out:Total Energy Use Pre PV": 95.34,
    "out:Total Energy Use Post PV": 93.72,
    "out:Primary Energy": 104.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.77,
    "out:CO2 Operational/m2": 24.9,
    "out:Total CO2/m2": 42.67,
    "out:Total CO2 (tons)": 94.94,
    "out:Klimatpaverkan": -47.5,
    "out:Initial Cost/MSEK": 4.141375,
    "out:Running cost/(Apt SEK y)": 26229,
    "out:Running Cost over RSP/MSEK": 3.68,
    "out:LCP/MSEK": 0.515,
    "out:ROI% old": 16.06,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2893.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262550,
    "out:EL kWh savings": -53003,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148816,
    "out:DH kr savings": 238920,
    "out:El kr savings": -90104,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 120302,
    "out:% savings (space heating)": 57.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.81,
    "out:Etvv": 0,
    "out:Ef": 39.72,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.04,
    "out:Electricity (inc PV)": 31.01,
    "out:Total Energy Use Pre PV": 103.04,
    "out:Total Energy Use Post PV": 92.01,
    "out:Primary Energy": 92.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 17.59,
    "out:Total CO2/m2": 47.29,
    "out:Total CO2 (tons)": 105.22,
    "out:Klimatpaverkan": -37.22,
    "out:Initial Cost/MSEK": 4.43475,
    "out:Running cost/(Apt SEK y)": 26396,
    "out:Running Cost over RSP/MSEK": 3.712,
    "out:LCP/MSEK": 0.19,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 104.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2308.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 591,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3949.63,
    "out:PV (% sold (El))": 15.88,
    "out:PV (kWh self-consumed)": 20919.51,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246975,
    "out:EL kWh savings": -33627,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172617,
    "out:DH kr savings": 224747,
    "out:El kr savings": -57166,
    "out:El kr sold": 5036,
    "out:El kr saved": 35563,
    "out:El kr return": 40599,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136806,
    "out:% savings (space heating)": 52.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.69,
    "out:Etvv": 0,
    "out:Ef": 31.01,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.97,
    "out:Electricity (inc PV)": 30.92,
    "out:Total Energy Use Pre PV": 98.97,
    "out:Total Energy Use Post PV": 87.92,
    "out:Primary Energy": 89.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.7,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 45.81,
    "out:Total CO2 (tons)": 101.92,
    "out:Klimatpaverkan": -40.52,
    "out:Initial Cost/MSEK": 4.490375,
    "out:Running cost/(Apt SEK y)": 25082,
    "out:Running Cost over RSP/MSEK": 3.527,
    "out:LCP/MSEK": 0.319,
    "out:ROI% old": 15.3,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 113.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2304.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3965.84,
    "out:PV (% sold (El))": 15.95,
    "out:PV (kWh self-consumed)": 20903.3,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255875,
    "out:EL kWh savings": -33424,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181082,
    "out:DH kr savings": 232846,
    "out:El kr savings": -56821,
    "out:El kr sold": 5056,
    "out:El kr saved": 35536,
    "out:El kr return": 40592,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 127271,
    "out:% savings (space heating)": 55.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 48.51,
    "out:Etvv": 0,
    "out:Ef": 30.92,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.42,
    "out:Electricity (inc PV)": 30.67,
    "out:Total Energy Use Pre PV": 99.42,
    "out:Total Energy Use Post PV": 88.67,
    "out:Primary Energy": 90.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 46.23,
    "out:Total CO2 (tons)": 102.86,
    "out:Klimatpaverkan": -39.58,
    "out:Initial Cost/MSEK": 4.5485,
    "out:Running cost/(Apt SEK y)": 25294,
    "out:Running Cost over RSP/MSEK": 3.557,
    "out:LCP/MSEK": 0.231,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2269.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 611,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4095.42,
    "out:PV (% sold (El))": 16.47,
    "out:PV (kWh self-consumed)": 20773.72,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 253650,
    "out:EL kWh savings": -32860,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180181,
    "out:DH kr savings": 230821,
    "out:El kr savings": -55862,
    "out:El kr sold": 5222,
    "out:El kr saved": 35315,
    "out:El kr return": 40537,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 129751,
    "out:% savings (space heating)": 54.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 50.97,
    "out:Etvv": 0,
    "out:Ef": 30.67,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.34,
    "out:Electricity (inc PV)": 30.57,
    "out:Total Energy Use Pre PV": 95.34,
    "out:Total Energy Use Post PV": 84.57,
    "out:Primary Energy": 87.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.13,
    "out:CO2 Operational/m2": 14.61,
    "out:Total CO2/m2": 44.74,
    "out:Total CO2 (tons)": 99.54,
    "out:Klimatpaverkan": -42.9,
    "out:Initial Cost/MSEK": 4.604125,
    "out:Running cost/(Apt SEK y)": 23978,
    "out:Running Cost over RSP/MSEK": 3.371,
    "out:LCP/MSEK": 0.361,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2264.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 634,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4114.83,
    "out:PV (% sold (El))": 16.55,
    "out:PV (kWh self-consumed)": 20754.31,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262550,
    "out:EL kWh savings": -32645,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188671,
    "out:DH kr savings": 238920,
    "out:El kr savings": -55496,
    "out:El kr sold": 5246,
    "out:El kr saved": 35282,
    "out:El kr return": 40529,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 120302,
    "out:% savings (space heating)": 57.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.81,
    "out:Etvv": 0,
    "out:Ef": 30.57,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.04,
    "out:Electricity (inc PV)": 28.04,
    "out:Total Energy Use Pre PV": 103.04,
    "out:Total Energy Use Post PV": 89.04,
    "out:Primary Energy": 87.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.06,
    "out:CO2 Operational/m2": -23.49,
    "out:Total CO2/m2": 18.57,
    "out:Total CO2 (tons)": 41.32,
    "out:Klimatpaverkan": -101.12,
    "out:Initial Cost/MSEK": 4.8975,
    "out:Running cost/(Apt SEK y)": 24469,
    "out:Running Cost over RSP/MSEK": 3.448,
    "out:LCP/MSEK": -0.009,
    "out:ROI% old": 14.26,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2085.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21832.66,
    "out:PV (% sold (El))": 43.9,
    "out:PV (kWh self-consumed)": 27905.62,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 246975,
    "out:EL kWh savings": -27012,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206663,
    "out:DH kr savings": 224747,
    "out:El kr savings": -45921,
    "out:El kr sold": 27837,
    "out:El kr saved": 47440,
    "out:El kr return": 75276,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136806,
    "out:% savings (space heating)": 52.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 52.69,
    "out:Etvv": 0,
    "out:Ef": 28.04,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.97,
    "out:Electricity (inc PV)": 27.96,
    "out:Total Energy Use Pre PV": 98.97,
    "out:Total Energy Use Post PV": 84.96,
    "out:Primary Energy": 84.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.06,
    "out:CO2 Operational/m2": -25,
    "out:Total CO2/m2": 17.05,
    "out:Total CO2 (tons)": 37.94,
    "out:Klimatpaverkan": -104.5,
    "out:Initial Cost/MSEK": 4.953125,
    "out:Running cost/(Apt SEK y)": 23156,
    "out:Running Cost over RSP/MSEK": 3.263,
    "out:LCP/MSEK": 0.12,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2081.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.77,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21863.33,
    "out:PV (% sold (El))": 43.96,
    "out:PV (kWh self-consumed)": 27874.95,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255875,
    "out:EL kWh savings": -26830,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215111,
    "out:DH kr savings": 232846,
    "out:El kr savings": -45611,
    "out:El kr sold": 27876,
    "out:El kr saved": 47387,
    "out:El kr return": 75263,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 127271,
    "out:% savings (space heating)": 55.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 48.51,
    "out:Etvv": 0,
    "out:Ef": 27.96,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.42,
    "out:Electricity (inc PV)": 27.73,
    "out:Total Energy Use Pre PV": 99.42,
    "out:Total Energy Use Post PV": 85.73,
    "out:Primary Energy": 85.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": -25.26,
    "out:Total CO2/m2": 17.21,
    "out:Total CO2 (tons)": 38.3,
    "out:Klimatpaverkan": -104.14,
    "out:Initial Cost/MSEK": 5.011375,
    "out:Running cost/(Apt SEK y)": 23370,
    "out:Running Cost over RSP/MSEK": 3.293,
    "out:LCP/MSEK": 0.032,
    "out:ROI% old": 14.38,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2050,
    "out:Return %": 13,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22106.45,
    "out:PV (% sold (El))": 44.45,
    "out:PV (kWh self-consumed)": 27631.83,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 253650,
    "out:EL kWh savings": -26323,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214257,
    "out:DH kr savings": 230821,
    "out:El kr savings": -44750,
    "out:El kr sold": 28186,
    "out:El kr saved": 46974,
    "out:El kr return": 75160,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 129751,
    "out:% savings (space heating)": 54.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 50.97,
    "out:Etvv": 0,
    "out:Ef": 27.73,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.34,
    "out:Electricity (inc PV)": 27.64,
    "out:Total Energy Use Pre PV": 95.34,
    "out:Total Energy Use Post PV": 81.64,
    "out:Primary Energy": 82.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.48,
    "out:CO2 Operational/m2": -26.8,
    "out:Total CO2/m2": 15.68,
    "out:Total CO2 (tons)": 34.89,
    "out:Klimatpaverkan": -107.55,
    "out:Initial Cost/MSEK": 5.067,
    "out:Running cost/(Apt SEK y)": 22055,
    "out:Running Cost over RSP/MSEK": 3.108,
    "out:LCP/MSEK": 0.161,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2045.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22142.56,
    "out:PV (% sold (El))": 44.52,
    "out:PV (kWh self-consumed)": 27595.72,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262550,
    "out:EL kWh savings": -26130,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222731,
    "out:DH kr savings": 238920,
    "out:El kr savings": -44421,
    "out:El kr sold": 28232,
    "out:El kr saved": 46913,
    "out:El kr return": 75144,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 120302,
    "out:% savings (space heating)": 57.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 46.81,
    "out:Etvv": 0,
    "out:Ef": 27.64,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.88,
    "out:Electricity (inc PV)": 55.61,
    "out:Total Energy Use Pre PV": 70.88,
    "out:Total Energy Use Post PV": 66.61,
    "out:Primary Energy": 104.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18,
    "out:CO2 Operational/m2": 11.86,
    "out:Total CO2/m2": 29.86,
    "out:Total CO2 (tons)": 66.44,
    "out:Klimatpaverkan": -76,
    "out:Initial Cost/MSEK": 5.250375,
    "out:Running cost/(Apt SEK y)": 16156,
    "out:Running Cost over RSP/MSEK": 2.254,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 16.55,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 180.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4191.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -88351,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175788,
    "out:DH kr savings": 325985,
    "out:El kr savings": -150196,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25187,
    "out:% savings (space heating)": 91.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.9,
    "out:Etvv": 0,
    "out:Ef": 55.61,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 54.76,
    "out:Total Energy Use Pre PV": 68.26,
    "out:Total Energy Use Post PV": 63.76,
    "out:Primary Energy": 102.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18,
    "out:CO2 Operational/m2": 11.06,
    "out:Total CO2/m2": 29.06,
    "out:Total CO2 (tons)": 64.66,
    "out:Klimatpaverkan": -77.78,
    "out:Initial Cost/MSEK": 5.306,
    "out:Running cost/(Apt SEK y)": 15376,
    "out:Running Cost over RSP/MSEK": 2.145,
    "out:LCP/MSEK": 0.886,
    "out:ROI% old": 16.67,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 193.8,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4147.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -86472,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183032,
    "out:DH kr savings": 330034,
    "out:El kr savings": -147002,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19695,
    "out:% savings (space heating)": 93.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.17,
    "out:Etvv": 0,
    "out:Ef": 54.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.42,
    "out:Electricity (inc PV)": 54.68,
    "out:Total Energy Use Pre PV": 68.42,
    "out:Total Energy Use Post PV": 64.68,
    "out:Primary Energy": 102.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 11.31,
    "out:Total CO2/m2": 29.73,
    "out:Total CO2 (tons)": 66.16,
    "out:Klimatpaverkan": -76.28,
    "out:Initial Cost/MSEK": 5.36425,
    "out:Running cost/(Apt SEK y)": 15526,
    "out:Running Cost over RSP/MSEK": 2.166,
    "out:LCP/MSEK": 0.806,
    "out:ROI% old": 16.43,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 189.2,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4089.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 786,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -86285,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181325,
    "out:DH kr savings": 328009,
    "out:El kr savings": -146684,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21323,
    "out:% savings (space heating)": 92.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.22,
    "out:Etvv": 0,
    "out:Ef": 54.68,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.74,
    "out:Electricity (inc PV)": 53.79,
    "out:Total Energy Use Pre PV": 64.74,
    "out:Total Energy Use Post PV": 60.79,
    "out:Primary Energy": 100.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 10.14,
    "out:Total CO2/m2": 28.56,
    "out:Total CO2 (tons)": 63.55,
    "out:Klimatpaverkan": -78.89,
    "out:Initial Cost/MSEK": 5.419875,
    "out:Running cost/(Apt SEK y)": 14406,
    "out:Running Cost over RSP/MSEK": 2.009,
    "out:LCP/MSEK": 0.908,
    "out:ROI% old": 16.68,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 208.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4041.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -84307,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190762,
    "out:DH kr savings": 334084,
    "out:El kr savings": -143321,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 16339,
    "out:% savings (space heating)": 94.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.64,
    "out:Etvv": 0,
    "out:Ef": 53.79,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.88,
    "out:Electricity (inc PV)": 45.12,
    "out:Total Energy Use Pre PV": 70.88,
    "out:Total Energy Use Post PV": 56.12,
    "out:Primary Energy": 86.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.35,
    "out:CO2 Operational/m2": 8.33,
    "out:Total CO2/m2": 38.68,
    "out:Total CO2 (tons)": 86.07,
    "out:Klimatpaverkan": -56.37,
    "out:Initial Cost/MSEK": 5.71325,
    "out:Running cost/(Apt SEK y)": 13831,
    "out:Running Cost over RSP/MSEK": 1.936,
    "out:LCP/MSEK": 0.687,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3479.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1029.78,
    "out:PV (% sold (El))": 4.14,
    "out:PV (kWh self-consumed)": 23839.36,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -65007,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216786,
    "out:DH kr savings": 325985,
    "out:El kr savings": -110511,
    "out:El kr sold": 1313,
    "out:El kr saved": 40527,
    "out:El kr return": 41840,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25187,
    "out:% savings (space heating)": 91.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.9,
    "out:Etvv": 0,
    "out:Ef": 45.12,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 44.32,
    "out:Total Energy Use Pre PV": 68.26,
    "out:Total Energy Use Post PV": 53.32,
    "out:Primary Energy": 83.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.35,
    "out:CO2 Operational/m2": 7.39,
    "out:Total CO2/m2": 37.74,
    "out:Total CO2 (tons)": 83.97,
    "out:Klimatpaverkan": -58.47,
    "out:Initial Cost/MSEK": 5.768875,
    "out:Running cost/(Apt SEK y)": 13053,
    "out:Running Cost over RSP/MSEK": 1.827,
    "out:LCP/MSEK": 0.741,
    "out:ROI% old": 16.12,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3437.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1095.56,
    "out:PV (% sold (El))": 4.41,
    "out:PV (kWh self-consumed)": 23773.58,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -63239,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223925,
    "out:DH kr savings": 330034,
    "out:El kr savings": -107506,
    "out:El kr sold": 1397,
    "out:El kr saved": 40415,
    "out:El kr return": 41812,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19695,
    "out:% savings (space heating)": 93.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.17,
    "out:Etvv": 0,
    "out:Ef": 44.32,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.42,
    "out:Electricity (inc PV)": 44.24,
    "out:Total Energy Use Pre PV": 68.42,
    "out:Total Energy Use Post PV": 54.24,
    "out:Primary Energy": 83.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.77,
    "out:CO2 Operational/m2": 7.44,
    "out:Total CO2/m2": 38.22,
    "out:Total CO2 (tons)": 85.03,
    "out:Klimatpaverkan": -57.41,
    "out:Initial Cost/MSEK": 5.827,
    "out:Running cost/(Apt SEK y)": 13205,
    "out:Running Cost over RSP/MSEK": 1.848,
    "out:LCP/MSEK": 0.661,
    "out:ROI% old": 15.91,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3381.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1186.31,
    "out:PV (% sold (El))": 4.77,
    "out:PV (kWh self-consumed)": 23682.83,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -63063,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222314,
    "out:DH kr savings": 328009,
    "out:El kr savings": -107208,
    "out:El kr sold": 1513,
    "out:El kr saved": 40261,
    "out:El kr return": 41773,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21323,
    "out:% savings (space heating)": 92.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.22,
    "out:Etvv": 0,
    "out:Ef": 44.24,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.74,
    "out:Electricity (inc PV)": 43.41,
    "out:Total Energy Use Pre PV": 64.74,
    "out:Total Energy Use Post PV": 50.41,
    "out:Primary Energy": 81.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.77,
    "out:CO2 Operational/m2": 6.1,
    "out:Total CO2/m2": 36.88,
    "out:Total CO2 (tons)": 82.05,
    "out:Klimatpaverkan": -60.39,
    "out:Initial Cost/MSEK": 5.882625,
    "out:Running cost/(Apt SEK y)": 12088,
    "out:Running Cost over RSP/MSEK": 1.691,
    "out:LCP/MSEK": 0.763,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3335.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1264.54,
    "out:PV (% sold (El))": 5.08,
    "out:PV (kWh self-consumed)": 23604.6,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -61208,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231642,
    "out:DH kr savings": 334084,
    "out:El kr savings": -104054,
    "out:El kr sold": 1612,
    "out:El kr saved": 40128,
    "out:El kr return": 41740,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 16339,
    "out:% savings (space heating)": 94.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.64,
    "out:Etvv": 0,
    "out:Ef": 43.41,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.88,
    "out:Electricity (inc PV)": 40.62,
    "out:Total Energy Use Pre PV": 70.88,
    "out:Total Energy Use Post PV": 51.62,
    "out:Primary Energy": 77.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.7,
    "out:CO2 Operational/m2": -23.49,
    "out:Total CO2/m2": 19.22,
    "out:Total CO2 (tons)": 42.75,
    "out:Klimatpaverkan": -99.69,
    "out:Initial Cost/MSEK": 6.176,
    "out:Running cost/(Apt SEK y)": 11809,
    "out:Running Cost over RSP/MSEK": 1.659,
    "out:LCP/MSEK": 0.502,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3124.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 14867.38,
    "out:PV (% sold (El))": 29.89,
    "out:PV (kWh self-consumed)": 34870.9,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 358225,
    "out:EL kWh savings": -55006,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251430,
    "out:DH kr savings": 325985,
    "out:El kr savings": -93511,
    "out:El kr sold": 18956,
    "out:El kr saved": 59281,
    "out:El kr return": 78236,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 25187,
    "out:% savings (space heating)": 91.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.9,
    "out:Etvv": 0,
    "out:Ef": 40.62,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 39.92,
    "out:Total Energy Use Pre PV": 68.26,
    "out:Total Energy Use Post PV": 48.92,
    "out:Primary Energy": 75.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.7,
    "out:CO2 Operational/m2": -24.78,
    "out:Total CO2/m2": 17.93,
    "out:Total CO2 (tons)": 39.89,
    "out:Klimatpaverkan": -102.55,
    "out:Initial Cost/MSEK": 6.231625,
    "out:Running cost/(Apt SEK y)": 11034,
    "out:Running Cost over RSP/MSEK": 1.55,
    "out:LCP/MSEK": 0.555,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3087.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15084.08,
    "out:PV (% sold (El))": 30.33,
    "out:PV (kWh self-consumed)": 34654.2,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -53438,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258422,
    "out:DH kr savings": 330034,
    "out:El kr savings": -90844,
    "out:El kr sold": 19232,
    "out:El kr saved": 58912,
    "out:El kr return": 78144,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 19695,
    "out:% savings (space heating)": 93.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.17,
    "out:Etvv": 0,
    "out:Ef": 39.92,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.42,
    "out:Electricity (inc PV)": 39.85,
    "out:Total Energy Use Pre PV": 68.42,
    "out:Total Energy Use Post PV": 49.85,
    "out:Primary Energy": 76.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.13,
    "out:CO2 Operational/m2": -25.18,
    "out:Total CO2/m2": 17.94,
    "out:Total CO2 (tons)": 39.93,
    "out:Klimatpaverkan": -102.51,
    "out:Initial Cost/MSEK": 6.289875,
    "out:Running cost/(Apt SEK y)": 11192,
    "out:Running Cost over RSP/MSEK": 1.572,
    "out:LCP/MSEK": 0.474,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3038.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15378.01,
    "out:PV (% sold (El))": 30.92,
    "out:PV (kWh self-consumed)": 34360.27,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360450,
    "out:EL kWh savings": -53282,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257037,
    "out:DH kr savings": 328009,
    "out:El kr savings": -90580,
    "out:El kr sold": 19607,
    "out:El kr saved": 58412,
    "out:El kr return": 78019,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 21323,
    "out:% savings (space heating)": 92.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6.22,
    "out:Etvv": 0,
    "out:Ef": 39.85,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.74,
    "out:Electricity (inc PV)": 39.11,
    "out:Total Energy Use Pre PV": 64.74,
    "out:Total Energy Use Post PV": 46.11,
    "out:Primary Energy": 73.64,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.13,
    "out:CO2 Operational/m2": -26.9,
    "out:Total CO2/m2": 16.22,
    "out:Total CO2 (tons)": 36.09,
    "out:Klimatpaverkan": -106.35,
    "out:Initial Cost/MSEK": 6.3455,
    "out:Running cost/(Apt SEK y)": 10078,
    "out:Running Cost over RSP/MSEK": 1.415,
    "out:LCP/MSEK": 0.575,
    "out:ROI% old": 15.58,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2997.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.54,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15624.17,
    "out:PV (% sold (El))": 31.41,
    "out:PV (kWh self-consumed)": 34114.11,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -51633,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266228,
    "out:DH kr savings": 334084,
    "out:El kr savings": -87776,
    "out:El kr sold": 19921,
    "out:El kr saved": 57994,
    "out:El kr return": 77915,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 16339,
    "out:% savings (space heating)": 94.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.64,
    "out:Etvv": 0,
    "out:Ef": 39.11,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.93,
    "out:Electricity (inc PV)": 54.37,
    "out:Total Energy Use Pre PV": 66.93,
    "out:Total Energy Use Post PV": 63.37,
    "out:Primary Energy": 102.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 28.88,
    "out:Total CO2 (tons)": 64.27,
    "out:Klimatpaverkan": -78.17,
    "out:Initial Cost/MSEK": 5.297875,
    "out:Running cost/(Apt SEK y)": 15097,
    "out:Running Cost over RSP/MSEK": 2.106,
    "out:LCP/MSEK": 0.933,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 198.4,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4055,
    "out:Return %": 15,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -85586,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184538,
    "out:DH kr savings": 330034,
    "out:El kr savings": -145496,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 20124,
    "out:% savings (space heating)": 92.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 54.37,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.21,
    "out:Electricity (inc PV)": 53.46,
    "out:Total Energy Use Pre PV": 64.21,
    "out:Total Energy Use Post PV": 60.46,
    "out:Primary Energy": 99.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18,
    "out:CO2 Operational/m2": 10.07,
    "out:Total CO2/m2": 28.07,
    "out:Total CO2 (tons)": 62.46,
    "out:Klimatpaverkan": -79.98,
    "out:Initial Cost/MSEK": 5.3535,
    "out:Running cost/(Apt SEK y)": 14297,
    "out:Running Cost over RSP/MSEK": 1.993,
    "out:LCP/MSEK": 0.989,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 213.3,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4004.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -83576,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192004,
    "out:DH kr savings": 334084,
    "out:El kr savings": -142080,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 15336,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.47,
    "out:Etvv": 0,
    "out:Ef": 53.46,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.71,
    "out:Electricity (inc PV)": 53.6,
    "out:Total Energy Use Pre PV": 64.71,
    "out:Total Energy Use Post PV": 61.6,
    "out:Primary Energy": 100.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 10.37,
    "out:Total CO2/m2": 28.79,
    "out:Total CO2 (tons)": 64.05,
    "out:Klimatpaverkan": -78.39,
    "out:Initial Cost/MSEK": 5.41175,
    "out:Running cost/(Apt SEK y)": 14516,
    "out:Running Cost over RSP/MSEK": 2.024,
    "out:LCP/MSEK": 0.9,
    "out:ROI% old": 16.66,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 203.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3969.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 805,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -83889,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189447,
    "out:DH kr savings": 332059,
    "out:El kr savings": -142612,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 17445,
    "out:% savings (space heating)": 93.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.52,
    "out:Etvv": 0,
    "out:Ef": 53.6,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.93,
    "out:Electricity (inc PV)": 52.67,
    "out:Total Energy Use Pre PV": 61.93,
    "out:Total Energy Use Post PV": 58.67,
    "out:Primary Energy": 97.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.42,
    "out:CO2 Operational/m2": 9.54,
    "out:Total CO2/m2": 27.96,
    "out:Total CO2 (tons)": 62.22,
    "out:Klimatpaverkan": -80.22,
    "out:Initial Cost/MSEK": 5.467375,
    "out:Running cost/(Apt SEK y)": 13702,
    "out:Running Cost over RSP/MSEK": 1.91,
    "out:LCP/MSEK": 0.959,
    "out:ROI% old": 16.79,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 218.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3914.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -81808,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197035,
    "out:DH kr savings": 336108,
    "out:El kr savings": -139074,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13167,
    "out:% savings (space heating)": 95.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.09,
    "out:Etvv": 0,
    "out:Ef": 52.67,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.93,
    "out:Electricity (inc PV)": 43.95,
    "out:Total Energy Use Pre PV": 66.93,
    "out:Total Energy Use Post PV": 52.95,
    "out:Primary Energy": 83.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.35,
    "out:CO2 Operational/m2": 6.9,
    "out:Total CO2/m2": 37.25,
    "out:Total CO2 (tons)": 82.88,
    "out:Klimatpaverkan": -59.56,
    "out:Initial Cost/MSEK": 5.76075,
    "out:Running cost/(Apt SEK y)": 12778,
    "out:Running Cost over RSP/MSEK": 1.788,
    "out:LCP/MSEK": 0.787,
    "out:ROI% old": 16.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3348.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1242.24,
    "out:PV (% sold (El))": 5,
    "out:PV (kWh self-consumed)": 23626.9,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -62407,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225525,
    "out:DH kr savings": 330034,
    "out:El kr savings": -106092,
    "out:El kr sold": 1584,
    "out:El kr saved": 40166,
    "out:El kr return": 41750,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 20124,
    "out:% savings (space heating)": 92.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 43.95,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.21,
    "out:Electricity (inc PV)": 43.1,
    "out:Total Energy Use Pre PV": 64.21,
    "out:Total Energy Use Post PV": 50.1,
    "out:Primary Energy": 80.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.35,
    "out:CO2 Operational/m2": 5.9,
    "out:Total CO2/m2": 36.25,
    "out:Total CO2 (tons)": 80.66,
    "out:Klimatpaverkan": -61.78,
    "out:Initial Cost/MSEK": 5.816375,
    "out:Running cost/(Apt SEK y)": 11979,
    "out:Running Cost over RSP/MSEK": 1.676,
    "out:LCP/MSEK": 0.844,
    "out:ROI% old": 16.36,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 276,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3300.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 850,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1325.99,
    "out:PV (% sold (El))": 5.33,
    "out:PV (kWh self-consumed)": 23543.15,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -60525,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232882,
    "out:DH kr savings": 334084,
    "out:El kr savings": -102892,
    "out:El kr sold": 1691,
    "out:El kr saved": 40023,
    "out:El kr return": 41714,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 15336,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.47,
    "out:Etvv": 0,
    "out:Ef": 43.1,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.71,
    "out:Electricity (inc PV)": 43.23,
    "out:Total Energy Use Pre PV": 64.71,
    "out:Total Energy Use Post PV": 51.23,
    "out:Primary Energy": 81.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.77,
    "out:CO2 Operational/m2": 6.06,
    "out:Total CO2/m2": 36.84,
    "out:Total CO2 (tons)": 81.97,
    "out:Klimatpaverkan": -60.47,
    "out:Initial Cost/MSEK": 5.8745,
    "out:Running cost/(Apt SEK y)": 12200,
    "out:Running Cost over RSP/MSEK": 1.707,
    "out:LCP/MSEK": 0.754,
    "out:ROI% old": 16.12,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3266.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1386.32,
    "out:PV (% sold (El))": 5.57,
    "out:PV (kWh self-consumed)": 23482.82,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -60818,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230436,
    "out:DH kr savings": 332059,
    "out:El kr savings": -103390,
    "out:El kr sold": 1768,
    "out:El kr saved": 39921,
    "out:El kr return": 41688,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 17445,
    "out:% savings (space heating)": 93.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.52,
    "out:Etvv": 0,
    "out:Ef": 43.23,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.93,
    "out:Electricity (inc PV)": 42.36,
    "out:Total Energy Use Pre PV": 61.93,
    "out:Total Energy Use Post PV": 48.36,
    "out:Primary Energy": 79.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.77,
    "out:CO2 Operational/m2": 5.03,
    "out:Total CO2/m2": 35.8,
    "out:Total CO2 (tons)": 79.66,
    "out:Klimatpaverkan": -62.78,
    "out:Initial Cost/MSEK": 5.930125,
    "out:Running cost/(Apt SEK y)": 11388,
    "out:Running Cost over RSP/MSEK": 1.593,
    "out:LCP/MSEK": 0.813,
    "out:ROI% old": 16.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 291.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3214.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 861,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1484.21,
    "out:PV (% sold (El))": 5.97,
    "out:PV (kWh self-consumed)": 23384.93,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -58873,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237916,
    "out:DH kr savings": 336108,
    "out:El kr savings": -100084,
    "out:El kr sold": 1892,
    "out:El kr saved": 39754,
    "out:El kr return": 41647,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13167,
    "out:% savings (space heating)": 95.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.09,
    "out:Etvv": 0,
    "out:Ef": 42.36,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.93,
    "out:Electricity (inc PV)": 39.59,
    "out:Total Energy Use Pre PV": 66.93,
    "out:Total Energy Use Post PV": 48.59,
    "out:Primary Energy": 75.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.7,
    "out:CO2 Operational/m2": -26,
    "out:Total CO2/m2": 16.7,
    "out:Total CO2 (tons)": 37.16,
    "out:Klimatpaverkan": -105.28,
    "out:Initial Cost/MSEK": 6.2235,
    "out:Running cost/(Apt SEK y)": 10767,
    "out:Running Cost over RSP/MSEK": 1.512,
    "out:LCP/MSEK": 0.6,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 283.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3008.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15554.7,
    "out:PV (% sold (El))": 31.27,
    "out:PV (kWh self-consumed)": 34183.58,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 362675,
    "out:EL kWh savings": -52699,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260278,
    "out:DH kr savings": 330034,
    "out:El kr savings": -89589,
    "out:El kr sold": 19832,
    "out:El kr saved": 58112,
    "out:El kr return": 77944,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 20124,
    "out:% savings (space heating)": 92.94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 6,
    "out:Etvv": 0,
    "out:Ef": 39.59,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.21,
    "out:Electricity (inc PV)": 38.83,
    "out:Total Energy Use Pre PV": 64.21,
    "out:Total Energy Use Post PV": 45.83,
    "out:Primary Energy": 73.03,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.7,
    "out:CO2 Operational/m2": -27.4,
    "out:Total CO2/m2": 15.31,
    "out:Total CO2 (tons)": 34.06,
    "out:Klimatpaverkan": -108.38,
    "out:Initial Cost/MSEK": 6.279125,
    "out:Running cost/(Apt SEK y)": 9973,
    "out:Running Cost over RSP/MSEK": 1.401,
    "out:LCP/MSEK": 0.656,
    "out:ROI% old": 15.78,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2966.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15811.75,
    "out:PV (% sold (El))": 31.79,
    "out:PV (kWh self-consumed)": 33926.53,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 367125,
    "out:EL kWh savings": -51026,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267500,
    "out:DH kr savings": 334084,
    "out:El kr savings": -86744,
    "out:El kr sold": 20160,
    "out:El kr saved": 57675,
    "out:El kr return": 77835,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 15336,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.47,
    "out:Etvv": 0,
    "out:Ef": 38.83,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.71,
    "out:Electricity (inc PV)": 38.95,
    "out:Total Energy Use Pre PV": 64.71,
    "out:Total Energy Use Post PV": 46.95,
    "out:Primary Energy": 73.97,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.13,
    "out:CO2 Operational/m2": -27.51,
    "out:Total CO2/m2": 15.62,
    "out:Total CO2 (tons)": 34.75,
    "out:Klimatpaverkan": -107.69,
    "out:Initial Cost/MSEK": 6.337375,
    "out:Running cost/(Apt SEK y)": 10196,
    "out:Running Cost over RSP/MSEK": 1.432,
    "out:LCP/MSEK": 0.566,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2936.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 882,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15992.11,
    "out:PV (% sold (El))": 32.15,
    "out:PV (kWh self-consumed)": 33746.17,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364900,
    "out:EL kWh savings": -51286,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265263,
    "out:DH kr savings": 332059,
    "out:El kr savings": -87186,
    "out:El kr sold": 20390,
    "out:El kr saved": 57368,
    "out:El kr return": 77758,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 17445,
    "out:% savings (space heating)": 93.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 5.52,
    "out:Etvv": 0,
    "out:Ef": 38.95,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.93,
    "out:Electricity (inc PV)": 38.17,
    "out:Total Energy Use Pre PV": 61.93,
    "out:Total Energy Use Post PV": 44.17,
    "out:Primary Energy": 71.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.13,
    "out:CO2 Operational/m2": -28.97,
    "out:Total CO2/m2": 14.15,
    "out:Total CO2 (tons)": 31.49,
    "out:Klimatpaverkan": -110.95,
    "out:Initial Cost/MSEK": 6.393,
    "out:Running cost/(Apt SEK y)": 9388,
    "out:Running Cost over RSP/MSEK": 1.319,
    "out:LCP/MSEK": 0.624,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2890.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16277.7,
    "out:PV (% sold (El))": 32.73,
    "out:PV (kWh self-consumed)": 33460.58,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 369350,
    "out:EL kWh savings": -49557,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272615,
    "out:DH kr savings": 336108,
    "out:El kr savings": -84247,
    "out:El kr sold": 20754,
    "out:El kr saved": 56883,
    "out:El kr return": 77637,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 13167,
    "out:% savings (space heating)": 95.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 4.09,
    "out:Etvv": 0,
    "out:Ef": 38.17,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 110.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.84,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 66.53,
    "out:Total CO2 (tons)": 148.04,
    "out:Klimatpaverkan": 5.6,
    "out:Initial Cost/MSEK": 2.5225,
    "out:Running cost/(Apt SEK y)": 47199,
    "out:Running Cost over RSP/MSEK": 6.643,
    "out:LCP/MSEK": -0.829,
    "out:ROI% old": 9.59,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74916,
    "out:DH kr savings": 74916,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 107.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.84,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 65.45,
    "out:Total CO2 (tons)": 145.63,
    "out:Klimatpaverkan": 3.19,
    "out:Initial Cost/MSEK": 2.578125,
    "out:Running cost/(Apt SEK y)": 46225,
    "out:Running Cost over RSP/MSEK": 6.506,
    "out:LCP/MSEK": -0.748,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 89000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80990,
    "out:DH kr savings": 80990,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 107.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.26,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 64.8,
    "out:Total CO2 (tons)": 144.17,
    "out:Klimatpaverkan": 1.73,
    "out:Initial Cost/MSEK": 2.63625,
    "out:Running cost/(Apt SEK y)": 45250,
    "out:Running Cost over RSP/MSEK": 6.368,
    "out:LCP/MSEK": -0.668,
    "out:ROI% old": 10.66,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87064,
    "out:DH kr savings": 87064,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 105.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.26,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 63.36,
    "out:Total CO2 (tons)": 140.97,
    "out:Klimatpaverkan": -1.47,
    "out:Initial Cost/MSEK": 2.691875,
    "out:Running cost/(Apt SEK y)": 43951,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -0.541,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95163,
    "out:DH kr savings": 95163,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 101.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 47.89,
    "out:Total CO2 (tons)": 106.56,
    "out:Klimatpaverkan": -35.88,
    "out:Initial Cost/MSEK": 2.98525,
    "out:Running cost/(Apt SEK y)": 45166,
    "out:Running Cost over RSP/MSEK": 6.364,
    "out:LCP/MSEK": -1.013,
    "out:ROI% old": 9.44,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111514,
    "out:DH kr savings": 74916,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 98.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 46.81,
    "out:Total CO2 (tons)": 104.16,
    "out:Klimatpaverkan": -38.28,
    "out:Initial Cost/MSEK": 3.040875,
    "out:Running cost/(Apt SEK y)": 44191,
    "out:Running Cost over RSP/MSEK": 6.227,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 89000,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117589,
    "out:DH kr savings": 80990,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.61,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 46.15,
    "out:Total CO2 (tons)": 102.69,
    "out:Klimatpaverkan": -39.75,
    "out:Initial Cost/MSEK": 3.099125,
    "out:Running cost/(Apt SEK y)": 43217,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -0.853,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 288,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123663,
    "out:DH kr savings": 87064,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 95.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.61,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 44.71,
    "out:Total CO2 (tons)": 99.49,
    "out:Klimatpaverkan": -42.95,
    "out:Initial Cost/MSEK": 3.15475,
    "out:Running cost/(Apt SEK y)": 41917,
    "out:Running Cost over RSP/MSEK": 5.907,
    "out:LCP/MSEK": -0.725,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131762,
    "out:DH kr savings": 95163,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 99.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 7.57,
    "out:Total CO2 (tons)": 16.83,
    "out:Klimatpaverkan": -125.61,
    "out:Initial Cost/MSEK": 3.448125,
    "out:Running cost/(Apt SEK y)": 43360,
    "out:Running Cost over RSP/MSEK": 6.117,
    "out:LCP/MSEK": -1.229,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144025,
    "out:DH kr savings": 74916,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 96.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 6.49,
    "out:Total CO2 (tons)": 14.43,
    "out:Klimatpaverkan": -128.01,
    "out:Initial Cost/MSEK": 3.50375,
    "out:Running cost/(Apt SEK y)": 42385,
    "out:Running Cost over RSP/MSEK": 5.98,
    "out:LCP/MSEK": -1.147,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 89000,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150099,
    "out:DH kr savings": 80990,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 97.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.97,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 5.83,
    "out:Total CO2 (tons)": 12.97,
    "out:Klimatpaverkan": -129.47,
    "out:Initial Cost/MSEK": 3.562,
    "out:Running cost/(Apt SEK y)": 41411,
    "out:Running Cost over RSP/MSEK": 5.843,
    "out:LCP/MSEK": -1.068,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156174,
    "out:DH kr savings": 87064,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 94.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.97,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 4.39,
    "out:Total CO2 (tons)": 9.76,
    "out:Klimatpaverkan": -132.68,
    "out:Initial Cost/MSEK": 3.617625,
    "out:Running cost/(Apt SEK y)": 40111,
    "out:Running Cost over RSP/MSEK": 5.66,
    "out:LCP/MSEK": -0.941,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164273,
    "out:DH kr savings": 95163,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 106.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.84,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 63.65,
    "out:Total CO2 (tons)": 141.63,
    "out:Klimatpaverkan": -0.81,
    "out:Initial Cost/MSEK": 2.57,
    "out:Running cost/(Apt SEK y)": 44600,
    "out:Running Cost over RSP/MSEK": 6.277,
    "out:LCP/MSEK": -0.511,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91114,
    "out:DH kr savings": 91114,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.84,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 62.21,
    "out:Total CO2 (tons)": 138.43,
    "out:Klimatpaverkan": -4.01,
    "out:Initial Cost/MSEK": 2.625625,
    "out:Running cost/(Apt SEK y)": 43301,
    "out:Running Cost over RSP/MSEK": 6.094,
    "out:LCP/MSEK": -0.383,
    "out:ROI% old": 12.2,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99213,
    "out:DH kr savings": 99213,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 137.9,
    "out:Primary Energy": 104.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.26,
    "out:CO2 Operational/m2": 46.02,
    "out:Total CO2/m2": 62.28,
    "out:Total CO2 (tons)": 138.56,
    "out:Klimatpaverkan": -3.88,
    "out:Initial Cost/MSEK": 2.683875,
    "out:Running cost/(Apt SEK y)": 42976,
    "out:Running Cost over RSP/MSEK": 6.048,
    "out:LCP/MSEK": -0.396,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101237,
    "out:DH kr savings": 101237,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 133.9,
    "out:Primary Energy": 102.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.26,
    "out:CO2 Operational/m2": 44.58,
    "out:Total CO2/m2": 60.84,
    "out:Total CO2 (tons)": 135.36,
    "out:Klimatpaverkan": -7.08,
    "out:Initial Cost/MSEK": 2.7395,
    "out:Running cost/(Apt SEK y)": 41676,
    "out:Running Cost over RSP/MSEK": 5.865,
    "out:LCP/MSEK": -0.268,
    "out:ROI% old": 12.89,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109336,
    "out:DH kr savings": 109336,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 97.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 45.01,
    "out:Total CO2 (tons)": 100.15,
    "out:Klimatpaverkan": -42.29,
    "out:Initial Cost/MSEK": 3.03275,
    "out:Running cost/(Apt SEK y)": 42567,
    "out:Running Cost over RSP/MSEK": 5.998,
    "out:LCP/MSEK": -0.695,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127712,
    "out:DH kr savings": 91114,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 94.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.19,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 43.57,
    "out:Total CO2 (tons)": 96.95,
    "out:Klimatpaverkan": -45.49,
    "out:Initial Cost/MSEK": 3.088375,
    "out:Running cost/(Apt SEK y)": 41268,
    "out:Running Cost over RSP/MSEK": 5.815,
    "out:LCP/MSEK": -0.567,
    "out:ROI% old": 11.66,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135811,
    "out:DH kr savings": 99213,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 132.73,
    "out:Primary Energy": 95.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.61,
    "out:CO2 Operational/m2": 15.02,
    "out:Total CO2/m2": 43.63,
    "out:Total CO2 (tons)": 97.09,
    "out:Klimatpaverkan": -45.35,
    "out:Initial Cost/MSEK": 3.146625,
    "out:Running cost/(Apt SEK y)": 40943,
    "out:Running Cost over RSP/MSEK": 5.77,
    "out:LCP/MSEK": -0.58,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 329,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137836,
    "out:DH kr savings": 101237,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 128.73,
    "out:Primary Energy": 92.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.61,
    "out:CO2 Operational/m2": 13.58,
    "out:Total CO2/m2": 42.19,
    "out:Total CO2 (tons)": 93.88,
    "out:Klimatpaverkan": -48.56,
    "out:Initial Cost/MSEK": 3.20225,
    "out:Running cost/(Apt SEK y)": 39643,
    "out:Running Cost over RSP/MSEK": 5.587,
    "out:LCP/MSEK": -0.452,
    "out:ROI% old": 12.27,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145935,
    "out:DH kr savings": 109336,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 96.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 4.69,
    "out:Total CO2 (tons)": 10.43,
    "out:Klimatpaverkan": -132.01,
    "out:Initial Cost/MSEK": 3.495625,
    "out:Running cost/(Apt SEK y)": 40761,
    "out:Running Cost over RSP/MSEK": 5.751,
    "out:LCP/MSEK": -0.911,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160223,
    "out:DH kr savings": 91114,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.54,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 3.25,
    "out:Total CO2 (tons)": 7.22,
    "out:Klimatpaverkan": -135.22,
    "out:Initial Cost/MSEK": 3.55125,
    "out:Running cost/(Apt SEK y)": 39461,
    "out:Running Cost over RSP/MSEK": 5.568,
    "out:LCP/MSEK": -0.783,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168322,
    "out:DH kr savings": 99213,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.94,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 131.88,
    "out:Primary Energy": 94.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.97,
    "out:CO2 Operational/m2": -37.66,
    "out:Total CO2/m2": 3.31,
    "out:Total CO2 (tons)": 7.36,
    "out:Klimatpaverkan": -135.08,
    "out:Initial Cost/MSEK": 3.6095,
    "out:Running cost/(Apt SEK y)": 39136,
    "out:Running Cost over RSP/MSEK": 5.522,
    "out:LCP/MSEK": -0.796,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170347,
    "out:DH kr savings": 101237,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 204566,
    "out:% savings (space heating)": 28.26,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.03,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 127.88,
    "out:Primary Energy": 91.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.97,
    "out:CO2 Operational/m2": -39.1,
    "out:Total CO2/m2": 1.87,
    "out:Total CO2 (tons)": 4.16,
    "out:Klimatpaverkan": -138.28,
    "out:Initial Cost/MSEK": 3.665125,
    "out:Running cost/(Apt SEK y)": 37837,
    "out:Running Cost over RSP/MSEK": 5.339,
    "out:LCP/MSEK": -0.668,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178446,
    "out:DH kr savings": 109336,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195875,
    "out:% savings (space heating)": 31.31,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.7,
    "out:Electricity (inc PV)": 40.35,
    "out:Total Energy Use Pre PV": 97.7,
    "out:Total Energy Use Post PV": 95.35,
    "out:Primary Energy": 103.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 25.44,
    "out:Total CO2/m2": 45.68,
    "out:Total CO2 (tons)": 101.65,
    "out:Klimatpaverkan": -40.79,
    "out:Initial Cost/MSEK": 4.37525,
    "out:Running cost/(Apt SEK y)": 26841,
    "out:Running Cost over RSP/MSEK": 3.766,
    "out:LCP/MSEK": 0.195,
    "out:ROI% old": 14.92,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2988.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -54400,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144416,
    "out:DH kr savings": 236896,
    "out:El kr savings": -92480,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 121468,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 40.35,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 40.25,
    "out:Total Energy Use Pre PV": 93.64,
    "out:Total Energy Use Post PV": 91.25,
    "out:Primary Energy": 100.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 23.99,
    "out:Total CO2/m2": 44.24,
    "out:Total CO2 (tons)": 98.42,
    "out:Klimatpaverkan": -44.02,
    "out:Initial Cost/MSEK": 4.430875,
    "out:Running cost/(Apt SEK y)": 25529,
    "out:Running Cost over RSP/MSEK": 3.581,
    "out:LCP/MSEK": 0.325,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2984.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -54190,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152872,
    "out:DH kr savings": 244995,
    "out:El kr savings": -92122,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113208,
    "out:% savings (space heating)": 60.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 40.25,
    "out:Etvv": 0,
    "out:Ef": 40.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.9,
    "out:Electricity (inc PV)": 39.84,
    "out:Total Energy Use Pre PV": 92.9,
    "out:Total Energy Use Post PV": 90.84,
    "out:Primary Energy": 100.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.67,
    "out:CO2 Operational/m2": 23.89,
    "out:Total CO2/m2": 44.56,
    "out:Total CO2 (tons)": 99.15,
    "out:Klimatpaverkan": -43.29,
    "out:Initial Cost/MSEK": 4.489125,
    "out:Running cost/(Apt SEK y)": 25373,
    "out:Running Cost over RSP/MSEK": 3.559,
    "out:LCP/MSEK": 0.288,
    "out:ROI% old": 15.2,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2933,
    "out:Return %": 14,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -53272,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154433,
    "out:DH kr savings": 244995,
    "out:El kr savings": -90562,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112814,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.77,
    "out:Etvv": 0,
    "out:Ef": 39.84,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 39.74,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 86.74,
    "out:Primary Energy": 98.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.67,
    "out:CO2 Operational/m2": 22.44,
    "out:Total CO2/m2": 43.11,
    "out:Total CO2 (tons)": 95.92,
    "out:Klimatpaverkan": -46.52,
    "out:Initial Cost/MSEK": 4.54475,
    "out:Running cost/(Apt SEK y)": 24059,
    "out:Running Cost over RSP/MSEK": 3.374,
    "out:LCP/MSEK": 0.418,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2928,
    "out:Return %": 14,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -53037,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162930,
    "out:DH kr savings": 253094,
    "out:El kr savings": -90163,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104646,
    "out:% savings (space heating)": 63.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 38.21,
    "out:Etvv": 0,
    "out:Ef": 39.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.7,
    "out:Electricity (inc PV)": 31.13,
    "out:Total Energy Use Pre PV": 97.7,
    "out:Total Energy Use Post PV": 86.13,
    "out:Primary Energy": 86.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 15.85,
    "out:Total CO2/m2": 48.46,
    "out:Total CO2 (tons)": 107.81,
    "out:Klimatpaverkan": -34.63,
    "out:Initial Cost/MSEK": 4.838,
    "out:Running cost/(Apt SEK y)": 24582,
    "out:Running Cost over RSP/MSEK": 3.456,
    "out:LCP/MSEK": 0.042,
    "out:ROI% old": 14.41,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2350.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 623,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3797.7,
    "out:PV (% sold (El))": 15.27,
    "out:PV (kWh self-consumed)": 21071.44,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -33885,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184134,
    "out:DH kr savings": 236896,
    "out:El kr savings": -57604,
    "out:El kr sold": 4842,
    "out:El kr saved": 35821,
    "out:El kr return": 40664,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 121468,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 31.13,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 31.05,
    "out:Total Energy Use Pre PV": 93.64,
    "out:Total Energy Use Post PV": 82.05,
    "out:Primary Energy": 84.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 14.38,
    "out:Total CO2/m2": 46.98,
    "out:Total CO2 (tons)": 104.53,
    "out:Klimatpaverkan": -37.91,
    "out:Initial Cost/MSEK": 4.893625,
    "out:Running cost/(Apt SEK y)": 23270,
    "out:Running Cost over RSP/MSEK": 3.271,
    "out:LCP/MSEK": 0.171,
    "out:ROI% old": 14.79,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2346.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3811.08,
    "out:PV (% sold (El))": 15.32,
    "out:PV (kWh self-consumed)": 21058.06,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -33698,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192568,
    "out:DH kr savings": 244995,
    "out:El kr savings": -57286,
    "out:El kr sold": 4859,
    "out:El kr saved": 35799,
    "out:El kr return": 40658,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113208,
    "out:% savings (space heating)": 60.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 40.25,
    "out:Etvv": 0,
    "out:Ef": 31.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.9,
    "out:Electricity (inc PV)": 30.68,
    "out:Total Energy Use Pre PV": 92.9,
    "out:Total Energy Use Post PV": 81.68,
    "out:Primary Energy": 84.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.02,
    "out:CO2 Operational/m2": 13.9,
    "out:Total CO2/m2": 46.92,
    "out:Total CO2 (tons)": 104.4,
    "out:Klimatpaverkan": -38.04,
    "out:Initial Cost/MSEK": 4.951875,
    "out:Running cost/(Apt SEK y)": 23119,
    "out:Running Cost over RSP/MSEK": 3.25,
    "out:LCP/MSEK": 0.134,
    "out:ROI% old": 14.67,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2299.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3981.6,
    "out:PV (% sold (El))": 16.01,
    "out:PV (kWh self-consumed)": 20887.54,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -32883,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194170,
    "out:DH kr savings": 244995,
    "out:El kr savings": -55901,
    "out:El kr sold": 5077,
    "out:El kr saved": 35509,
    "out:El kr return": 40585,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112814,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.77,
    "out:Etvv": 0,
    "out:Ef": 30.68,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 30.59,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 77.59,
    "out:Primary Energy": 81.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.02,
    "out:CO2 Operational/m2": 12.41,
    "out:Total CO2/m2": 45.44,
    "out:Total CO2 (tons)": 101.1,
    "out:Klimatpaverkan": -41.34,
    "out:Initial Cost/MSEK": 5.0075,
    "out:Running cost/(Apt SEK y)": 21805,
    "out:Running Cost over RSP/MSEK": 3.065,
    "out:LCP/MSEK": 0.264,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2295.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3998.23,
    "out:PV (% sold (El))": 16.08,
    "out:PV (kWh self-consumed)": 20870.91,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -32675,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202643,
    "out:DH kr savings": 253094,
    "out:El kr savings": -55548,
    "out:El kr sold": 5098,
    "out:El kr saved": 35481,
    "out:El kr return": 40578,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104646,
    "out:% savings (space heating)": 63.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 38.21,
    "out:Etvv": 0,
    "out:Ef": 30.59,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.7,
    "out:Electricity (inc PV)": 28.14,
    "out:Total Energy Use Pre PV": 97.7,
    "out:Total Energy Use Post PV": 83.14,
    "out:Primary Energy": 81.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -24.91,
    "out:Total CO2/m2": 20.05,
    "out:Total CO2 (tons)": 44.6,
    "out:Klimatpaverkan": -97.84,
    "out:Initial Cost/MSEK": 5.300875,
    "out:Running cost/(Apt SEK y)": 22652,
    "out:Running Cost over RSP/MSEK": 3.192,
    "out:LCP/MSEK": -0.156,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2122.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21543.53,
    "out:PV (% sold (El))": 43.31,
    "out:PV (kWh self-consumed)": 28194.75,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 260325,
    "out:EL kWh savings": -27244,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218049,
    "out:DH kr savings": 236896,
    "out:El kr savings": -46315,
    "out:El kr sold": 27468,
    "out:El kr saved": 47931,
    "out:El kr return": 75399,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 121468,
    "out:% savings (space heating)": 57.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 43.85,
    "out:Etvv": 0,
    "out:Ef": 28.14,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 28.07,
    "out:Total Energy Use Pre PV": 93.64,
    "out:Total Energy Use Post PV": 79.07,
    "out:Primary Energy": 78.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -26.41,
    "out:Total CO2/m2": 18.54,
    "out:Total CO2 (tons)": 41.25,
    "out:Klimatpaverkan": -101.19,
    "out:Initial Cost/MSEK": 5.3565,
    "out:Running cost/(Apt SEK y)": 21341,
    "out:Running Cost over RSP/MSEK": 3.007,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2119.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21569.17,
    "out:PV (% sold (El))": 43.37,
    "out:PV (kWh self-consumed)": 28169.11,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -27076,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226466,
    "out:DH kr savings": 244995,
    "out:El kr savings": -46029,
    "out:El kr sold": 27501,
    "out:El kr saved": 47887,
    "out:El kr return": 75388,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 113208,
    "out:% savings (space heating)": 60.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 40.25,
    "out:Etvv": 0,
    "out:Ef": 28.07,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.9,
    "out:Electricity (inc PV)": 27.74,
    "out:Total Energy Use Pre PV": 92.9,
    "out:Total Energy Use Post PV": 78.74,
    "out:Primary Energy": 79.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -27.24,
    "out:Total CO2/m2": 18.14,
    "out:Total CO2 (tons)": 40.35,
    "out:Klimatpaverkan": -102.09,
    "out:Initial Cost/MSEK": 5.41475,
    "out:Running cost/(Apt SEK y)": 21193,
    "out:Running Cost over RSP/MSEK": 2.986,
    "out:LCP/MSEK": -0.065,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2077.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21893.07,
    "out:PV (% sold (El))": 44.02,
    "out:PV (kWh self-consumed)": 27845.21,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -26344,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228123,
    "out:DH kr savings": 244995,
    "out:El kr savings": -44785,
    "out:El kr sold": 27914,
    "out:El kr saved": 47337,
    "out:El kr return": 75251,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112814,
    "out:% savings (space heating)": 60.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.77,
    "out:Etvv": 0,
    "out:Ef": 27.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 27.66,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 74.66,
    "out:Primary Energy": 76.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -28.76,
    "out:Total CO2/m2": 16.62,
    "out:Total CO2 (tons)": 36.97,
    "out:Klimatpaverkan": -105.47,
    "out:Initial Cost/MSEK": 5.470375,
    "out:Running cost/(Apt SEK y)": 19879,
    "out:Running Cost over RSP/MSEK": 2.801,
    "out:LCP/MSEK": 0.065,
    "out:ROI% old": 14.45,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2073.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21924.4,
    "out:PV (% sold (El))": 44.08,
    "out:PV (kWh self-consumed)": 27813.88,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -26157,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236580,
    "out:DH kr savings": 253094,
    "out:El kr savings": -44468,
    "out:El kr sold": 27954,
    "out:El kr saved": 47284,
    "out:El kr return": 75237,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104646,
    "out:% savings (space heating)": 63.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 38.21,
    "out:Etvv": 0,
    "out:Ef": 27.66,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.63,
    "out:Electricity (inc PV)": 39.67,
    "out:Total Energy Use Pre PV": 90.63,
    "out:Total Energy Use Post PV": 88.67,
    "out:Primary Energy": 100.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 43.38,
    "out:Total CO2 (tons)": 96.52,
    "out:Klimatpaverkan": -45.92,
    "out:Initial Cost/MSEK": 4.42275,
    "out:Running cost/(Apt SEK y)": 24666,
    "out:Running Cost over RSP/MSEK": 3.46,
    "out:LCP/MSEK": 0.454,
    "out:ROI% old": 15.75,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 111.2,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2913.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -52889,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159134,
    "out:DH kr savings": 249044,
    "out:El kr savings": -89911,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 109988,
    "out:% savings (space heating)": 61.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 39.67,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 39.56,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 85.56,
    "out:Primary Energy": 97.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 22.04,
    "out:Total CO2/m2": 42.29,
    "out:Total CO2 (tons)": 94.1,
    "out:Klimatpaverkan": -48.34,
    "out:Initial Cost/MSEK": 4.478375,
    "out:Running cost/(Apt SEK y)": 23676,
    "out:Running Cost over RSP/MSEK": 3.32,
    "out:LCP/MSEK": 0.538,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2908.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -52647,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165618,
    "out:DH kr savings": 255118,
    "out:El kr savings": -89501,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101847,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.54,
    "out:Etvv": 0,
    "out:Ef": 39.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.95,
    "out:Electricity (inc PV)": 39.25,
    "out:Total Energy Use Pre PV": 86.95,
    "out:Total Energy Use Post PV": 85.25,
    "out:Primary Energy": 98.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.67,
    "out:CO2 Operational/m2": 21.97,
    "out:Total CO2/m2": 42.64,
    "out:Total CO2 (tons)": 94.86,
    "out:Klimatpaverkan": -47.58,
    "out:Initial Cost/MSEK": 4.536625,
    "out:Running cost/(Apt SEK y)": 23550,
    "out:Running Cost over RSP/MSEK": 3.303,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 15.85,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 121.2,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2866.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 642,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -51959,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166788,
    "out:DH kr savings": 255118,
    "out:El kr savings": -88330,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 103305,
    "out:% savings (space heating)": 63.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.47,
    "out:Etvv": 0,
    "out:Ef": 39.25,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 39.14,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 82.14,
    "out:Primary Energy": 95.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.67,
    "out:CO2 Operational/m2": 20.87,
    "out:Total CO2/m2": 41.54,
    "out:Total CO2 (tons)": 92.44,
    "out:Klimatpaverkan": -50,
    "out:Initial Cost/MSEK": 4.59225,
    "out:Running cost/(Apt SEK y)": 22558,
    "out:Running Cost over RSP/MSEK": 3.163,
    "out:LCP/MSEK": 0.581,
    "out:ROI% old": 16.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2860.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -51701,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173301,
    "out:DH kr savings": 261193,
    "out:El kr savings": -87891,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 95237,
    "out:% savings (space heating)": 66.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.94,
    "out:Etvv": 0,
    "out:Ef": 39.14,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.63,
    "out:Electricity (inc PV)": 30.53,
    "out:Total Energy Use Pre PV": 90.63,
    "out:Total Energy Use Post PV": 79.53,
    "out:Primary Energy": 83.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 13,
    "out:Total CO2/m2": 45.6,
    "out:Total CO2 (tons)": 101.46,
    "out:Klimatpaverkan": -40.98,
    "out:Initial Cost/MSEK": 4.8855,
    "out:Running cost/(Apt SEK y)": 22413,
    "out:Running Cost over RSP/MSEK": 3.151,
    "out:LCP/MSEK": 0.3,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 135,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2282.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4045.88,
    "out:PV (% sold (El))": 16.27,
    "out:PV (kWh self-consumed)": 20823.26,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -32544,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198878,
    "out:DH kr savings": 249044,
    "out:El kr savings": -55324,
    "out:El kr sold": 5158,
    "out:El kr saved": 35400,
    "out:El kr return": 40558,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 109988,
    "out:% savings (space heating)": 61.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 30.53,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 30.43,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 76.43,
    "out:Primary Energy": 81.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 11.87,
    "out:Total CO2/m2": 44.47,
    "out:Total CO2 (tons)": 98.95,
    "out:Klimatpaverkan": -43.49,
    "out:Initial Cost/MSEK": 4.941125,
    "out:Running cost/(Apt SEK y)": 21423,
    "out:Running Cost over RSP/MSEK": 3.011,
    "out:LCP/MSEK": 0.384,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2277.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4063.55,
    "out:PV (% sold (El))": 16.34,
    "out:PV (kWh self-consumed)": 20805.59,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -32330,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205338,
    "out:DH kr savings": 255118,
    "out:El kr savings": -54962,
    "out:El kr sold": 5181,
    "out:El kr saved": 35370,
    "out:El kr return": 40551,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101847,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.54,
    "out:Etvv": 0,
    "out:Ef": 30.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.95,
    "out:Electricity (inc PV)": 30.16,
    "out:Total Energy Use Pre PV": 86.95,
    "out:Total Energy Use Post PV": 76.16,
    "out:Primary Energy": 81.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.02,
    "out:CO2 Operational/m2": 11.48,
    "out:Total CO2/m2": 44.5,
    "out:Total CO2 (tons)": 99.01,
    "out:Klimatpaverkan": -43.43,
    "out:Initial Cost/MSEK": 4.999375,
    "out:Running cost/(Apt SEK y)": 21301,
    "out:Running Cost over RSP/MSEK": 2.994,
    "out:LCP/MSEK": 0.342,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2240.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 683,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4206.14,
    "out:PV (% sold (El))": 16.91,
    "out:PV (kWh self-consumed)": 20663,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -31722,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206555,
    "out:DH kr savings": 255118,
    "out:El kr savings": -53927,
    "out:El kr sold": 5363,
    "out:El kr saved": 35127,
    "out:El kr return": 40490,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 103305,
    "out:% savings (space heating)": 63.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.47,
    "out:Etvv": 0,
    "out:Ef": 30.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 30.05,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 73.05,
    "out:Primary Energy": 79.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.02,
    "out:CO2 Operational/m2": 10.34,
    "out:Total CO2/m2": 43.36,
    "out:Total CO2 (tons)": 96.48,
    "out:Klimatpaverkan": -45.96,
    "out:Initial Cost/MSEK": 5.055,
    "out:Running cost/(Apt SEK y)": 20309,
    "out:Running Cost over RSP/MSEK": 2.855,
    "out:LCP/MSEK": 0.427,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2234.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4226.75,
    "out:PV (% sold (El))": 17,
    "out:PV (kWh self-consumed)": 20642.39,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -31494,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213043,
    "out:DH kr savings": 261193,
    "out:El kr savings": -53539,
    "out:El kr sold": 5389,
    "out:El kr saved": 35092,
    "out:El kr return": 40481,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 95237,
    "out:% savings (space heating)": 66.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.94,
    "out:Etvv": 0,
    "out:Ef": 30.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.63,
    "out:Electricity (inc PV)": 27.6,
    "out:Total Energy Use Pre PV": 90.63,
    "out:Total Energy Use Post PV": 76.6,
    "out:Primary Energy": 78.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 16.69,
    "out:Total CO2 (tons)": 37.13,
    "out:Klimatpaverkan": -105.31,
    "out:Initial Cost/MSEK": 5.348375,
    "out:Running cost/(Apt SEK y)": 20488,
    "out:Running Cost over RSP/MSEK": 2.887,
    "out:LCP/MSEK": 0.101,
    "out:ROI% old": 14.55,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 144.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2061.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22013.98,
    "out:PV (% sold (El))": 44.26,
    "out:PV (kWh self-consumed)": 27724.3,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273675,
    "out:EL kWh savings": -26039,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232845,
    "out:DH kr savings": 249044,
    "out:El kr savings": -44267,
    "out:El kr sold": 28068,
    "out:El kr saved": 47131,
    "out:El kr return": 75199,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 109988,
    "out:% savings (space heating)": 61.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 41.09,
    "out:Etvv": 0,
    "out:Ef": 27.6,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 27.52,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 73.52,
    "out:Primary Energy": 75.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -29.43,
    "out:Total CO2/m2": 15.52,
    "out:Total CO2 (tons)": 34.53,
    "out:Klimatpaverkan": -107.91,
    "out:Initial Cost/MSEK": 5.404,
    "out:Running cost/(Apt SEK y)": 19499,
    "out:Running Cost over RSP/MSEK": 2.748,
    "out:LCP/MSEK": 0.184,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2057.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22047.07,
    "out:PV (% sold (El))": 44.33,
    "out:PV (kWh self-consumed)": 27691.21,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -25847,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239288,
    "out:DH kr savings": 255118,
    "out:El kr savings": -43940,
    "out:El kr sold": 28110,
    "out:El kr saved": 47075,
    "out:El kr return": 75185,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101847,
    "out:% savings (space heating)": 64.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.54,
    "out:Etvv": 0,
    "out:Ef": 27.52,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.95,
    "out:Electricity (inc PV)": 27.27,
    "out:Total Energy Use Pre PV": 86.95,
    "out:Total Energy Use Post PV": 73.27,
    "out:Primary Energy": 76.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -30.11,
    "out:Total CO2/m2": 15.27,
    "out:Total CO2 (tons)": 33.97,
    "out:Klimatpaverkan": -108.47,
    "out:Initial Cost/MSEK": 5.46225,
    "out:Running cost/(Apt SEK y)": 19380,
    "out:Running Cost over RSP/MSEK": 2.731,
    "out:LCP/MSEK": 0.143,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2023.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22311.8,
    "out:PV (% sold (El))": 44.86,
    "out:PV (kWh self-consumed)": 27426.48,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -25300,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240556,
    "out:DH kr savings": 255118,
    "out:El kr savings": -43010,
    "out:El kr sold": 28448,
    "out:El kr saved": 46625,
    "out:El kr return": 75073,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 103305,
    "out:% savings (space heating)": 63.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.47,
    "out:Etvv": 0,
    "out:Ef": 27.27,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 27.18,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 70.18,
    "out:Primary Energy": 74.08,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.38,
    "out:CO2 Operational/m2": -31.28,
    "out:Total CO2/m2": 14.09,
    "out:Total CO2 (tons)": 31.36,
    "out:Klimatpaverkan": -111.08,
    "out:Initial Cost/MSEK": 5.517875,
    "out:Running cost/(Apt SEK y)": 18388,
    "out:Running Cost over RSP/MSEK": 2.592,
    "out:LCP/MSEK": 0.227,
    "out:ROI% old": 14.87,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2019,
    "out:Return %": 13,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22349.69,
    "out:PV (% sold (El))": 44.93,
    "out:PV (kWh self-consumed)": 27388.59,
    "out:PV (ratio sold/self-consumed)": 0.82,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -25095,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247027,
    "out:DH kr savings": 261193,
    "out:El kr savings": -42661,
    "out:El kr sold": 28496,
    "out:El kr saved": 46561,
    "out:El kr return": 75056,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 95237,
    "out:% savings (space heating)": 66.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.94,
    "out:Etvv": 0,
    "out:Ef": 27.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.59,
    "out:Electricity (inc PV)": 52.72,
    "out:Total Energy Use Pre PV": 62.59,
    "out:Total Energy Use Post PV": 57.72,
    "out:Primary Energy": 97.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 9.4,
    "out:Total CO2/m2": 30.3,
    "out:Total CO2 (tons)": 67.41,
    "out:Klimatpaverkan": -75.03,
    "out:Initial Cost/MSEK": 5.70125,
    "out:Running cost/(Apt SEK y)": 13726,
    "out:Running Cost over RSP/MSEK": 1.913,
    "out:LCP/MSEK": 0.722,
    "out:ROI% old": 16.1,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 224.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4031.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -81919,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198871,
    "out:DH kr savings": 338133,
    "out:El kr savings": -139262,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 12135,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.03,
    "out:Etvv": 0,
    "out:Ef": 52.72,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 51.8,
    "out:Total Energy Use Pre PV": 60.82,
    "out:Total Energy Use Post PV": 55.8,
    "out:Primary Energy": 94.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 8.94,
    "out:Total CO2/m2": 29.84,
    "out:Total CO2 (tons)": 66.39,
    "out:Klimatpaverkan": -76.05,
    "out:Initial Cost/MSEK": 5.756875,
    "out:Running cost/(Apt SEK y)": 13240,
    "out:Running Cost over RSP/MSEK": 1.845,
    "out:LCP/MSEK": 0.735,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3977.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -79883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204357,
    "out:DH kr savings": 340158,
    "out:El kr savings": -135800,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9080,
    "out:% savings (space heating)": 96.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 51.8,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.92,
    "out:Electricity (inc PV)": 51.69,
    "out:Total Energy Use Pre PV": 59.92,
    "out:Total Energy Use Post PV": 55.69,
    "out:Primary Energy": 94.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 8.82,
    "out:Total CO2/m2": 30.14,
    "out:Total CO2 (tons)": 67.06,
    "out:Klimatpaverkan": -75.38,
    "out:Initial Cost/MSEK": 5.815125,
    "out:Running cost/(Apt SEK y)": 13050,
    "out:Running Cost over RSP/MSEK": 1.818,
    "out:LCP/MSEK": 0.703,
    "out:ROI% old": 16.01,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3914.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 831,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -79634,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204780,
    "out:DH kr savings": 340158,
    "out:El kr savings": -135377,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9888,
    "out:% savings (space heating)": 96.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 51.69,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 50.75,
    "out:Total Energy Use Pre PV": 58.09,
    "out:Total Energy Use Post PV": 53.75,
    "out:Primary Energy": 92.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 8.35,
    "out:Total CO2/m2": 29.67,
    "out:Total CO2 (tons)": 66.02,
    "out:Klimatpaverkan": -76.42,
    "out:Initial Cost/MSEK": 5.87075,
    "out:Running cost/(Apt SEK y)": 12550,
    "out:Running Cost over RSP/MSEK": 1.748,
    "out:LCP/MSEK": 0.717,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3856,
    "out:Return %": 14,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -77552,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210345,
    "out:DH kr savings": 342183,
    "out:El kr savings": -131838,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7312,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 50.75,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.59,
    "out:Electricity (inc PV)": 42.41,
    "out:Total Energy Use Pre PV": 62.59,
    "out:Total Energy Use Post PV": 47.41,
    "out:Primary Energy": 78.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.25,
    "out:CO2 Operational/m2": 5.33,
    "out:Total CO2/m2": 38.58,
    "out:Total CO2 (tons)": 85.83,
    "out:Klimatpaverkan": -56.61,
    "out:Initial Cost/MSEK": 6.164,
    "out:Running cost/(Apt SEK y)": 11408,
    "out:Running Cost over RSP/MSEK": 1.595,
    "out:LCP/MSEK": 0.577,
    "out:ROI% old": 15.62,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 300,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3325.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 861,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1281.53,
    "out:PV (% sold (El))": 5.15,
    "out:PV (kWh self-consumed)": 23587.61,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -58976,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239507,
    "out:DH kr savings": 338133,
    "out:El kr savings": -100260,
    "out:El kr sold": 1634,
    "out:El kr saved": 40099,
    "out:El kr return": 41733,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 12135,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.03,
    "out:Etvv": 0,
    "out:Ef": 42.41,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 41.55,
    "out:Total Energy Use Pre PV": 60.82,
    "out:Total Energy Use Post PV": 45.55,
    "out:Primary Energy": 76.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.25,
    "out:CO2 Operational/m2": 4.67,
    "out:Total CO2/m2": 37.92,
    "out:Total CO2 (tons)": 84.37,
    "out:Klimatpaverkan": -58.07,
    "out:Initial Cost/MSEK": 6.219625,
    "out:Running cost/(Apt SEK y)": 10924,
    "out:Running Cost over RSP/MSEK": 1.527,
    "out:LCP/MSEK": 0.589,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 308.7,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3274.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 869,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1372.99,
    "out:PV (% sold (El))": 5.52,
    "out:PV (kWh self-consumed)": 23496.15,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -57080,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244872,
    "out:DH kr savings": 340158,
    "out:El kr savings": -97036,
    "out:El kr sold": 1751,
    "out:El kr saved": 39943,
    "out:El kr return": 41694,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9080,
    "out:% savings (space heating)": 96.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 41.55,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.92,
    "out:Electricity (inc PV)": 41.45,
    "out:Total Energy Use Pre PV": 59.92,
    "out:Total Energy Use Post PV": 45.45,
    "out:Primary Energy": 76.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": 4.31,
    "out:Total CO2/m2": 37.98,
    "out:Total CO2 (tons)": 84.5,
    "out:Klimatpaverkan": -57.94,
    "out:Initial Cost/MSEK": 6.277875,
    "out:Running cost/(Apt SEK y)": 10736,
    "out:Running Cost over RSP/MSEK": 1.501,
    "out:LCP/MSEK": 0.557,
    "out:ROI% old": 15.55,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3213.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 873,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1485.26,
    "out:PV (% sold (El))": 5.97,
    "out:PV (kWh self-consumed)": 23383.88,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -56849,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245409,
    "out:DH kr savings": 340158,
    "out:El kr savings": -96643,
    "out:El kr sold": 1894,
    "out:El kr saved": 39753,
    "out:El kr return": 41646,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9888,
    "out:% savings (space heating)": 96.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 41.45,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 40.58,
    "out:Total Energy Use Pre PV": 58.09,
    "out:Total Energy Use Post PV": 43.58,
    "out:Primary Energy": 74.34,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": 3.6,
    "out:Total CO2/m2": 37.27,
    "out:Total CO2 (tons)": 82.93,
    "out:Klimatpaverkan": -59.51,
    "out:Initial Cost/MSEK": 6.3335,
    "out:Running cost/(Apt SEK y)": 10239,
    "out:Running Cost over RSP/MSEK": 1.432,
    "out:LCP/MSEK": 0.571,
    "out:ROI% old": 15.57,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3158.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 882,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1593.57,
    "out:PV (% sold (El))": 6.41,
    "out:PV (kWh self-consumed)": 23275.57,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -54916,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250858,
    "out:DH kr savings": 342183,
    "out:El kr savings": -93356,
    "out:El kr sold": 2032,
    "out:El kr saved": 39568,
    "out:El kr return": 41600,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7312,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 40.58,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.59,
    "out:Electricity (inc PV)": 38.21,
    "out:Total Energy Use Pre PV": 62.59,
    "out:Total Energy Use Post PV": 43.21,
    "out:Primary Energy": 70.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.6,
    "out:CO2 Operational/m2": -27.76,
    "out:Total CO2/m2": 17.84,
    "out:Total CO2 (tons)": 39.7,
    "out:Klimatpaverkan": -102.74,
    "out:Initial Cost/MSEK": 6.626875,
    "out:Running cost/(Apt SEK y)": 9399,
    "out:Running Cost over RSP/MSEK": 1.32,
    "out:LCP/MSEK": 0.39,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2988.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15676.46,
    "out:PV (% sold (El))": 31.52,
    "out:PV (kWh self-consumed)": 34061.82,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 371575,
    "out:EL kWh savings": -49649,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273717,
    "out:DH kr savings": 338133,
    "out:El kr savings": -84403,
    "out:El kr sold": 19987,
    "out:El kr saved": 57905,
    "out:El kr return": 77893,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 12135,
    "out:% savings (space heating)": 95.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 3.03,
    "out:Etvv": 0,
    "out:Ef": 38.21,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.82,
    "out:Electricity (inc PV)": 37.46,
    "out:Total Energy Use Pre PV": 60.82,
    "out:Total Energy Use Post PV": 41.46,
    "out:Primary Energy": 68.92,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.6,
    "out:CO2 Operational/m2": -28.84,
    "out:Total CO2/m2": 16.76,
    "out:Total CO2 (tons)": 37.29,
    "out:Klimatpaverkan": -105.15,
    "out:Initial Cost/MSEK": 6.6825,
    "out:Running cost/(Apt SEK y)": 8919,
    "out:Running Cost over RSP/MSEK": 1.252,
    "out:LCP/MSEK": 0.401,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2943.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 905,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.62,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15952.6,
    "out:PV (% sold (El))": 32.07,
    "out:PV (kWh self-consumed)": 33785.68,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -47961,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278963,
    "out:DH kr savings": 340158,
    "out:El kr savings": -81534,
    "out:El kr sold": 20340,
    "out:El kr saved": 57436,
    "out:El kr return": 77775,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9080,
    "out:% savings (space heating)": 96.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.14,
    "out:Etvv": 0,
    "out:Ef": 37.46,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.92,
    "out:Electricity (inc PV)": 37.36,
    "out:Total Energy Use Pre PV": 59.92,
    "out:Total Energy Use Post PV": 41.36,
    "out:Primary Energy": 69.12,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.03,
    "out:CO2 Operational/m2": -29.7,
    "out:Total CO2/m2": 16.32,
    "out:Total CO2 (tons)": 36.32,
    "out:Klimatpaverkan": -106.12,
    "out:Initial Cost/MSEK": 6.74075,
    "out:Running cost/(Apt SEK y)": 8737,
    "out:Running Cost over RSP/MSEK": 1.227,
    "out:LCP/MSEK": 0.368,
    "out:ROI% old": 15.07,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2889.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16280.73,
    "out:PV (% sold (El))": 32.73,
    "out:PV (kWh self-consumed)": 33457.55,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -47755,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279732,
    "out:DH kr savings": 340158,
    "out:El kr savings": -81184,
    "out:El kr sold": 20758,
    "out:El kr saved": 56878,
    "out:El kr return": 77636,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9888,
    "out:% savings (space heating)": 96.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 37.36,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 36.59,
    "out:Total Energy Use Pre PV": 58.09,
    "out:Total Energy Use Post PV": 39.59,
    "out:Primary Energy": 67.16,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.03,
    "out:CO2 Operational/m2": -30.86,
    "out:Total CO2/m2": 15.17,
    "out:Total CO2 (tons)": 33.75,
    "out:Klimatpaverkan": -108.69,
    "out:Initial Cost/MSEK": 6.796375,
    "out:Running cost/(Apt SEK y)": 8244,
    "out:Running Cost over RSP/MSEK": 1.158,
    "out:LCP/MSEK": 0.382,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2840.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 918,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16585.98,
    "out:PV (% sold (El))": 33.35,
    "out:PV (kWh self-consumed)": 33152.3,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -46035,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285070,
    "out:DH kr savings": 342183,
    "out:El kr savings": -78259,
    "out:El kr sold": 21147,
    "out:El kr saved": 56359,
    "out:El kr return": 77506,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7312,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.85,
    "out:Etvv": 0,
    "out:Ef": 36.59,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.35,
    "out:Electricity (inc PV)": 51.35,
    "out:Total Energy Use Pre PV": 59.35,
    "out:Total Energy Use Post PV": 55.35,
    "out:Primary Energy": 94.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 8.75,
    "out:Total CO2/m2": 29.64,
    "out:Total CO2 (tons)": 65.95,
    "out:Klimatpaverkan": -76.49,
    "out:Initial Cost/MSEK": 5.74875,
    "out:Running cost/(Apt SEK y)": 12930,
    "out:Running Cost over RSP/MSEK": 1.802,
    "out:LCP/MSEK": 0.786,
    "out:ROI% old": 16.24,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3874.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 833,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -78867,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206083,
    "out:DH kr savings": 340158,
    "out:El kr savings": -134074,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9239,
    "out:% savings (space heating)": 96.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.56,
    "out:Etvv": 0,
    "out:Ef": 51.35,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.5,
    "out:Electricity (inc PV)": 50.4,
    "out:Total Energy Use Pre PV": 57.5,
    "out:Total Energy Use Post PV": 53.4,
    "out:Primary Energy": 91.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.9,
    "out:CO2 Operational/m2": 8.27,
    "out:Total CO2/m2": 29.17,
    "out:Total CO2 (tons)": 64.9,
    "out:Klimatpaverkan": -77.54,
    "out:Initial Cost/MSEK": 5.804375,
    "out:Running cost/(Apt SEK y)": 12427,
    "out:Running Cost over RSP/MSEK": 1.731,
    "out:LCP/MSEK": 0.801,
    "out:ROI% old": 16.26,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3814.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -76771,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211673,
    "out:DH kr savings": 342183,
    "out:El kr savings": -130510,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6791,
    "out:% savings (space heating)": 97.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 50.4,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.96,
    "out:Electricity (inc PV)": 50.51,
    "out:Total Energy Use Pre PV": 57.96,
    "out:Total Energy Use Post PV": 54.51,
    "out:Primary Energy": 92.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 8.56,
    "out:Total CO2/m2": 29.88,
    "out:Total CO2 (tons)": 66.49,
    "out:Klimatpaverkan": -75.95,
    "out:Initial Cost/MSEK": 5.862625,
    "out:Running cost/(Apt SEK y)": 12638,
    "out:Running Cost over RSP/MSEK": 1.761,
    "out:LCP/MSEK": 0.713,
    "out:ROI% old": 16.02,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 241.8,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3777,
    "out:Return %": 14,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -77018,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209228,
    "out:DH kr savings": 340158,
    "out:El kr savings": -130930,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7831,
    "out:% savings (space heating)": 97.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.3,
    "out:Etvv": 0,
    "out:Ef": 50.51,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.07,
    "out:Electricity (inc PV)": 49.56,
    "out:Total Energy Use Pre PV": 56.07,
    "out:Total Energy Use Post PV": 52.56,
    "out:Primary Energy": 90.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.32,
    "out:CO2 Operational/m2": 8.09,
    "out:Total CO2/m2": 29.4,
    "out:Total CO2 (tons)": 65.42,
    "out:Klimatpaverkan": -77.02,
    "out:Initial Cost/MSEK": 5.91825,
    "out:Running cost/(Apt SEK y)": 12127,
    "out:Running Cost over RSP/MSEK": 1.689,
    "out:LCP/MSEK": 0.729,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3714.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -74889,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214872,
    "out:DH kr savings": 342183,
    "out:El kr savings": -127311,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5651,
    "out:% savings (space heating)": 98.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.55,
    "out:Etvv": 0,
    "out:Ef": 49.56,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.35,
    "out:Electricity (inc PV)": 41.13,
    "out:Total Energy Use Pre PV": 59.35,
    "out:Total Energy Use Post PV": 45.13,
    "out:Primary Energy": 75.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.25,
    "out:CO2 Operational/m2": 4.07,
    "out:Total CO2/m2": 37.32,
    "out:Total CO2 (tons)": 83.04,
    "out:Klimatpaverkan": -59.4,
    "out:Initial Cost/MSEK": 6.2115,
    "out:Running cost/(Apt SEK y)": 10618,
    "out:Running Cost over RSP/MSEK": 1.485,
    "out:LCP/MSEK": 0.64,
    "out:ROI% old": 15.76,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3175.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1559.06,
    "out:PV (% sold (El))": 6.27,
    "out:PV (kWh self-consumed)": 23310.08,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -56136,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246714,
    "out:DH kr savings": 340158,
    "out:El kr savings": -95432,
    "out:El kr sold": 1988,
    "out:El kr saved": 39627,
    "out:El kr return": 41615,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9239,
    "out:% savings (space heating)": 96.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.56,
    "out:Etvv": 0,
    "out:Ef": 41.13,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.5,
    "out:Electricity (inc PV)": 40.26,
    "out:Total Energy Use Pre PV": 57.5,
    "out:Total Energy Use Post PV": 43.26,
    "out:Primary Energy": 73.69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.25,
    "out:CO2 Operational/m2": 3.35,
    "out:Total CO2/m2": 36.6,
    "out:Total CO2 (tons)": 81.44,
    "out:Klimatpaverkan": -61,
    "out:Initial Cost/MSEK": 6.267125,
    "out:Running cost/(Apt SEK y)": 10117,
    "out:Running Cost over RSP/MSEK": 1.415,
    "out:LCP/MSEK": 0.655,
    "out:ROI% old": 15.78,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3119,
    "out:Return %": 14,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1672.37,
    "out:PV (% sold (El))": 6.72,
    "out:PV (kWh self-consumed)": 23196.77,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -54192,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252189,
    "out:DH kr savings": 342183,
    "out:El kr savings": -92126,
    "out:El kr sold": 2132,
    "out:El kr saved": 39435,
    "out:El kr return": 41567,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6791,
    "out:% savings (space heating)": 97.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 40.26,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.96,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 57.96,
    "out:Total Energy Use Post PV": 44.36,
    "out:Primary Energy": 74.26,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": 3.48,
    "out:Total CO2/m2": 37.15,
    "out:Total CO2 (tons)": 82.66,
    "out:Klimatpaverkan": -59.78,
    "out:Initial Cost/MSEK": 6.325375,
    "out:Running cost/(Apt SEK y)": 10330,
    "out:Running Cost over RSP/MSEK": 1.445,
    "out:LCP/MSEK": 0.566,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3083.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1747.38,
    "out:PV (% sold (El))": 7.03,
    "out:PV (kWh self-consumed)": 23121.76,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -54421,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249871,
    "out:DH kr savings": 340158,
    "out:El kr savings": -92515,
    "out:El kr sold": 2228,
    "out:El kr saved": 39307,
    "out:El kr return": 41535,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7831,
    "out:% savings (space heating)": 97.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.3,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.07,
    "out:Electricity (inc PV)": 39.47,
    "out:Total Energy Use Pre PV": 56.07,
    "out:Total Energy Use Post PV": 42.47,
    "out:Primary Energy": 72.14,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.67,
    "out:CO2 Operational/m2": 2.73,
    "out:Total CO2/m2": 36.4,
    "out:Total CO2 (tons)": 80.98,
    "out:Klimatpaverkan": -61.46,
    "out:Initial Cost/MSEK": 6.381,
    "out:Running cost/(Apt SEK y)": 9822,
    "out:Running Cost over RSP/MSEK": 1.374,
    "out:LCP/MSEK": 0.582,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3024.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 889,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1874.75,
    "out:PV (% sold (El))": 7.54,
    "out:PV (kWh self-consumed)": 22994.39,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -52451,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255406,
    "out:DH kr savings": 342183,
    "out:El kr savings": -89167,
    "out:El kr sold": 2390,
    "out:El kr saved": 39090,
    "out:El kr return": 41481,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5651,
    "out:% savings (space heating)": 98.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.55,
    "out:Etvv": 0,
    "out:Ef": 39.47,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.35,
    "out:Electricity (inc PV)": 37.08,
    "out:Total Energy Use Pre PV": 59.35,
    "out:Total Energy Use Post PV": 41.08,
    "out:Primary Energy": 68.53,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.6,
    "out:CO2 Operational/m2": -30.25,
    "out:Total CO2/m2": 15.36,
    "out:Total CO2 (tons)": 34.17,
    "out:Klimatpaverkan": -108.27,
    "out:Initial Cost/MSEK": 6.674375,
    "out:Running cost/(Apt SEK y)": 8622,
    "out:Running Cost over RSP/MSEK": 1.211,
    "out:LCP/MSEK": 0.451,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2856.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 911,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16489.6,
    "out:PV (% sold (El))": 33.15,
    "out:PV (kWh self-consumed)": 33248.68,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -47121,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281076,
    "out:DH kr savings": 340158,
    "out:El kr savings": -80106,
    "out:El kr sold": 21024,
    "out:El kr saved": 56523,
    "out:El kr return": 77547,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 9239,
    "out:% savings (space heating)": 96.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.56,
    "out:Etvv": 0,
    "out:Ef": 37.08,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.5,
    "out:Electricity (inc PV)": 36.3,
    "out:Total Energy Use Pre PV": 57.5,
    "out:Total Energy Use Post PV": 39.3,
    "out:Primary Energy": 66.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 45.6,
    "out:CO2 Operational/m2": -31.42,
    "out:Total CO2/m2": 14.18,
    "out:Total CO2 (tons)": 31.55,
    "out:Klimatpaverkan": -110.89,
    "out:Initial Cost/MSEK": 6.73,
    "out:Running cost/(Apt SEK y)": 8126,
    "out:Running Cost over RSP/MSEK": 1.142,
    "out:LCP/MSEK": 0.465,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 382.1,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2806,
    "out:Return %": 14,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16802.97,
    "out:PV (% sold (El))": 33.78,
    "out:PV (kWh self-consumed)": 32935.31,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -45391,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286442,
    "out:DH kr savings": 342183,
    "out:El kr savings": -77164,
    "out:El kr sold": 21424,
    "out:El kr saved": 55990,
    "out:El kr return": 77414,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6791,
    "out:% savings (space heating)": 97.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.76,
    "out:Etvv": 0,
    "out:Ef": 36.3,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.96,
    "out:Electricity (inc PV)": 36.39,
    "out:Total Energy Use Pre PV": 57.96,
    "out:Total Energy Use Post PV": 40.39,
    "out:Primary Energy": 67.12,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.03,
    "out:CO2 Operational/m2": -31.58,
    "out:Total CO2/m2": 14.44,
    "out:Total CO2 (tons)": 32.13,
    "out:Klimatpaverkan": -110.31,
    "out:Initial Cost/MSEK": 6.78825,
    "out:Running cost/(Apt SEK y)": 8342,
    "out:Running Cost over RSP/MSEK": 1.172,
    "out:LCP/MSEK": 0.376,
    "out:ROI% old": 15.08,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2774.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17002.78,
    "out:PV (% sold (El))": 34.18,
    "out:PV (kWh self-consumed)": 32735.5,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -45594,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284326,
    "out:DH kr savings": 340158,
    "out:El kr savings": -77511,
    "out:El kr sold": 21679,
    "out:El kr saved": 55650,
    "out:El kr return": 77329,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7831,
    "out:% savings (space heating)": 97.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.3,
    "out:Etvv": 0,
    "out:Ef": 36.39,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.07,
    "out:Electricity (inc PV)": 35.6,
    "out:Total Energy Use Pre PV": 56.07,
    "out:Total Energy Use Post PV": 38.6,
    "out:Primary Energy": 65.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 46.03,
    "out:CO2 Operational/m2": -32.81,
    "out:Total CO2/m2": 13.22,
    "out:Total CO2 (tons)": 29.41,
    "out:Klimatpaverkan": -113.03,
    "out:Initial Cost/MSEK": 6.843875,
    "out:Running cost/(Apt SEK y)": 7839,
    "out:Running Cost over RSP/MSEK": 1.102,
    "out:LCP/MSEK": 0.391,
    "out:ROI% old": 15.1,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 382.1,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2722.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.87,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17335.38,
    "out:PV (% sold (El))": 34.85,
    "out:PV (kWh self-consumed)": 32402.9,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -43841,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289755,
    "out:DH kr savings": 342183,
    "out:El kr savings": -74530,
    "out:El kr sold": 22103,
    "out:El kr saved": 55085,
    "out:El kr return": 77188,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5651,
    "out:% savings (space heating)": 98.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.55,
    "out:Etvv": 0,
    "out:Ef": 35.6,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 107.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.06,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 72.32,
    "out:Total CO2 (tons)": 160.92,
    "out:Klimatpaverkan": 18.48,
    "out:Initial Cost/MSEK": 3.626375,
    "out:Running cost/(Apt SEK y)": 45900,
    "out:Running Cost over RSP/MSEK": 6.46,
    "out:LCP/MSEK": -1.75,
    "out:ROI% old": 7.39,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83015,
    "out:DH kr savings": 83015,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 104.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.06,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 71.24,
    "out:Total CO2 (tons)": 158.51,
    "out:Klimatpaverkan": 16.07,
    "out:Initial Cost/MSEK": 3.682,
    "out:Running cost/(Apt SEK y)": 44925,
    "out:Running Cost over RSP/MSEK": 6.323,
    "out:LCP/MSEK": -1.668,
    "out:ROI% old": 7.81,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 257,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89089,
    "out:DH kr savings": 89089,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 104.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.49,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 70.58,
    "out:Total CO2 (tons)": 157.05,
    "out:Klimatpaverkan": 14.61,
    "out:Initial Cost/MSEK": 3.74025,
    "out:Running cost/(Apt SEK y)": 43951,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -1.589,
    "out:ROI% old": 8.22,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95163,
    "out:DH kr savings": 95163,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 136.9,
    "out:Primary Energy": 102.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.49,
    "out:CO2 Operational/m2": 45.66,
    "out:Total CO2/m2": 69.14,
    "out:Total CO2 (tons)": 153.85,
    "out:Klimatpaverkan": 11.41,
    "out:Initial Cost/MSEK": 3.795875,
    "out:Running cost/(Apt SEK y)": 42651,
    "out:Running Cost over RSP/MSEK": 6.002,
    "out:LCP/MSEK": -1.462,
    "out:ROI% old": 8.78,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103262,
    "out:DH kr savings": 103262,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 98.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.42,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 53.68,
    "out:Total CO2 (tons)": 119.44,
    "out:Klimatpaverkan": -23,
    "out:Initial Cost/MSEK": 4.08925,
    "out:Running cost/(Apt SEK y)": 43866,
    "out:Running Cost over RSP/MSEK": 6.181,
    "out:LCP/MSEK": -1.934,
    "out:ROI% old": 7.53,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119613,
    "out:DH kr savings": 83015,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 95.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.42,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 117.03,
    "out:Klimatpaverkan": -25.41,
    "out:Initial Cost/MSEK": 4.144875,
    "out:Running cost/(Apt SEK y)": 42892,
    "out:Running Cost over RSP/MSEK": 6.044,
    "out:LCP/MSEK": -1.853,
    "out:ROI% old": 7.9,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125688,
    "out:DH kr savings": 89089,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 95.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.84,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 51.94,
    "out:Total CO2 (tons)": 115.57,
    "out:Klimatpaverkan": -26.87,
    "out:Initial Cost/MSEK": 4.203,
    "out:Running cost/(Apt SEK y)": 41917,
    "out:Running Cost over RSP/MSEK": 5.907,
    "out:LCP/MSEK": -1.774,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 311,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131762,
    "out:DH kr savings": 95163,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 131.73,
    "out:Primary Energy": 93.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.84,
    "out:CO2 Operational/m2": 14.66,
    "out:Total CO2/m2": 50.5,
    "out:Total CO2 (tons)": 112.37,
    "out:Klimatpaverkan": -30.07,
    "out:Initial Cost/MSEK": 4.258625,
    "out:Running cost/(Apt SEK y)": 40618,
    "out:Running Cost over RSP/MSEK": 5.724,
    "out:LCP/MSEK": -1.646,
    "out:ROI% old": 8.76,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 335,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139861,
    "out:DH kr savings": 103262,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 96.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.77,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 13.35,
    "out:Total CO2 (tons)": 29.71,
    "out:Klimatpaverkan": -112.73,
    "out:Initial Cost/MSEK": 4.552,
    "out:Running cost/(Apt SEK y)": 42060,
    "out:Running Cost over RSP/MSEK": 5.934,
    "out:LCP/MSEK": -2.15,
    "out:ROI% old": 7.54,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152124,
    "out:DH kr savings": 83015,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 94.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.77,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 12.27,
    "out:Total CO2 (tons)": 27.31,
    "out:Klimatpaverkan": -115.13,
    "out:Initial Cost/MSEK": 4.607625,
    "out:Running cost/(Apt SEK y)": 41086,
    "out:Running Cost over RSP/MSEK": 5.797,
    "out:LCP/MSEK": -2.068,
    "out:ROI% old": 7.87,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 326,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97900,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158198,
    "out:DH kr savings": 89089,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 94.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.19,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 11.62,
    "out:Total CO2 (tons)": 25.85,
    "out:Klimatpaverkan": -116.59,
    "out:Initial Cost/MSEK": 4.665875,
    "out:Running cost/(Apt SEK y)": 40111,
    "out:Running Cost over RSP/MSEK": 5.66,
    "out:LCP/MSEK": -1.989,
    "out:ROI% old": 8.2,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 344,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 104575,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164273,
    "out:DH kr savings": 95163,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 130.88,
    "out:Primary Energy": 91.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.19,
    "out:CO2 Operational/m2": -38.02,
    "out:Total CO2/m2": 10.18,
    "out:Total CO2 (tons)": 22.64,
    "out:Klimatpaverkan": -119.8,
    "out:Initial Cost/MSEK": 4.7215,
    "out:Running cost/(Apt SEK y)": 38812,
    "out:Running Cost over RSP/MSEK": 5.477,
    "out:LCP/MSEK": -1.862,
    "out:ROI% old": 8.65,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172372,
    "out:DH kr savings": 103262,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.06,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 69.44,
    "out:Total CO2 (tons)": 154.51,
    "out:Klimatpaverkan": 12.07,
    "out:Initial Cost/MSEK": 3.673875,
    "out:Running cost/(Apt SEK y)": 43301,
    "out:Running Cost over RSP/MSEK": 6.094,
    "out:LCP/MSEK": -1.431,
    "out:ROI% old": 8.72,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99213,
    "out:DH kr savings": 99213,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 134.9,
    "out:Primary Energy": 101.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.06,
    "out:CO2 Operational/m2": 44.94,
    "out:Total CO2/m2": 68,
    "out:Total CO2 (tons)": 151.3,
    "out:Klimatpaverkan": 8.86,
    "out:Initial Cost/MSEK": 3.7295,
    "out:Running cost/(Apt SEK y)": 42001,
    "out:Running Cost over RSP/MSEK": 5.911,
    "out:LCP/MSEK": -1.304,
    "out:ROI% old": 9.29,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 310,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107312,
    "out:DH kr savings": 107312,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 132.9,
    "out:Primary Energy": 101.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.49,
    "out:CO2 Operational/m2": 44.22,
    "out:Total CO2/m2": 67.7,
    "out:Total CO2 (tons)": 150.64,
    "out:Klimatpaverkan": 8.2,
    "out:Initial Cost/MSEK": 3.78775,
    "out:Running cost/(Apt SEK y)": 41352,
    "out:Running Cost over RSP/MSEK": 5.819,
    "out:LCP/MSEK": -1.271,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111361,
    "out:DH kr savings": 111361,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 129.9,
    "out:Primary Energy": 99.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.49,
    "out:CO2 Operational/m2": 43.14,
    "out:Total CO2/m2": 66.62,
    "out:Total CO2 (tons)": 148.24,
    "out:Klimatpaverkan": 5.8,
    "out:Initial Cost/MSEK": 3.843375,
    "out:Running cost/(Apt SEK y)": 40377,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": -1.189,
    "out:ROI% old": 9.87,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117435,
    "out:DH kr savings": 117435,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 94.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.42,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 50.8,
    "out:Total CO2 (tons)": 113.03,
    "out:Klimatpaverkan": -29.41,
    "out:Initial Cost/MSEK": 4.13675,
    "out:Running cost/(Apt SEK y)": 41268,
    "out:Running Cost over RSP/MSEK": 5.815,
    "out:LCP/MSEK": -1.616,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135811,
    "out:DH kr savings": 99213,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 129.73,
    "out:Primary Energy": 92.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.42,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 49.36,
    "out:Total CO2 (tons)": 109.83,
    "out:Klimatpaverkan": -32.61,
    "out:Initial Cost/MSEK": 4.192375,
    "out:Running cost/(Apt SEK y)": 39968,
    "out:Running Cost over RSP/MSEK": 5.632,
    "out:LCP/MSEK": -1.488,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 347,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143910,
    "out:DH kr savings": 107312,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 127.73,
    "out:Primary Energy": 92.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.84,
    "out:CO2 Operational/m2": 13.22,
    "out:Total CO2/m2": 49.06,
    "out:Total CO2 (tons)": 109.16,
    "out:Klimatpaverkan": -33.28,
    "out:Initial Cost/MSEK": 4.2505,
    "out:Running cost/(Apt SEK y)": 39318,
    "out:Running Cost over RSP/MSEK": 5.541,
    "out:LCP/MSEK": -1.455,
    "out:ROI% old": 9.4,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147960,
    "out:DH kr savings": 111361,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 124.73,
    "out:Primary Energy": 90.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.84,
    "out:CO2 Operational/m2": 12.14,
    "out:Total CO2/m2": 47.98,
    "out:Total CO2 (tons)": 106.76,
    "out:Klimatpaverkan": -35.68,
    "out:Initial Cost/MSEK": 4.306125,
    "out:Running cost/(Apt SEK y)": 38344,
    "out:Running Cost over RSP/MSEK": 5.403,
    "out:LCP/MSEK": -1.373,
    "out:ROI% old": 9.73,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 13362.13,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 11507.01,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": 11507,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154034,
    "out:DH kr savings": 117435,
    "out:El kr savings": 19562,
    "out:El kr sold": 17037,
    "out:El kr saved": 19562,
    "out:El kr return": 36599,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.77,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 10.47,
    "out:Total CO2 (tons)": 23.3,
    "out:Klimatpaverkan": -119.14,
    "out:Initial Cost/MSEK": 4.5995,
    "out:Running cost/(Apt SEK y)": 39461,
    "out:Running Cost over RSP/MSEK": 5.568,
    "out:LCP/MSEK": -1.831,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 356,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109025,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168322,
    "out:DH kr savings": 99213,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 128.88,
    "out:Primary Energy": 90.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.77,
    "out:CO2 Operational/m2": -38.74,
    "out:Total CO2/m2": 9.03,
    "out:Total CO2 (tons)": 20.1,
    "out:Klimatpaverkan": -122.34,
    "out:Initial Cost/MSEK": 4.655125,
    "out:Running cost/(Apt SEK y)": 38162,
    "out:Running Cost over RSP/MSEK": 5.385,
    "out:LCP/MSEK": -1.704,
    "out:ROI% old": 9.06,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176421,
    "out:DH kr savings": 107312,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 126.88,
    "out:Primary Energy": 91.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.19,
    "out:CO2 Operational/m2": -39.46,
    "out:Total CO2/m2": 8.74,
    "out:Total CO2 (tons)": 19.44,
    "out:Klimatpaverkan": -123,
    "out:Initial Cost/MSEK": 4.713375,
    "out:Running cost/(Apt SEK y)": 37512,
    "out:Running Cost over RSP/MSEK": 5.294,
    "out:LCP/MSEK": -1.671,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180471,
    "out:DH kr savings": 111361,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 195316,
    "out:% savings (space heating)": 31.51,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.07,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 123.88,
    "out:Primary Energy": 88.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.19,
    "out:CO2 Operational/m2": -40.54,
    "out:Total CO2/m2": 7.66,
    "out:Total CO2 (tons)": 17.03,
    "out:Klimatpaverkan": -125.41,
    "out:Initial Cost/MSEK": 4.769,
    "out:Running cost/(Apt SEK y)": 36538,
    "out:Running Cost over RSP/MSEK": 5.156,
    "out:LCP/MSEK": -1.589,
    "out:ROI% old": 9.53,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 36342.81,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 13395.47,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": 13395,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186545,
    "out:DH kr savings": 117435,
    "out:El kr savings": 22772,
    "out:El kr sold": 46337,
    "out:El kr saved": 22772,
    "out:El kr return": 69109,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 187045,
    "out:% savings (space heating)": 34.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 40.26,
    "out:Total Energy Use Pre PV": 93.65,
    "out:Total Energy Use Post PV": 91.26,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 23.99,
    "out:Total CO2/m2": 51.46,
    "out:Total CO2 (tons)": 114.51,
    "out:Klimatpaverkan": -27.93,
    "out:Initial Cost/MSEK": 5.479125,
    "out:Running cost/(Apt SEK y)": 25530,
    "out:Running Cost over RSP/MSEK": 3.581,
    "out:LCP/MSEK": -0.724,
    "out:ROI% old": 12.4,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 106.6,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2985.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -54194,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152865,
    "out:DH kr savings": 244995,
    "out:El kr savings": -92129,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112597,
    "out:% savings (space heating)": 60.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.99,
    "out:Etvv": 0,
    "out:Ef": 40.26,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 40.16,
    "out:Total Energy Use Pre PV": 89.59,
    "out:Total Energy Use Post PV": 87.16,
    "out:Primary Energy": 97.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 22.54,
    "out:Total CO2/m2": 50.02,
    "out:Total CO2 (tons)": 111.29,
    "out:Klimatpaverkan": -31.15,
    "out:Initial Cost/MSEK": 5.53475,
    "out:Running cost/(Apt SEK y)": 24218,
    "out:Running Cost over RSP/MSEK": 3.396,
    "out:LCP/MSEK": -0.595,
    "out:ROI% old": 12.75,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2981,
    "out:Return %": 11,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -53972,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161341,
    "out:DH kr savings": 253094,
    "out:El kr savings": -91753,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104772,
    "out:% savings (space heating)": 63.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 36.6,
    "out:Etvv": 0,
    "out:Ef": 40.16,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 39.74,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 86.74,
    "out:Primary Energy": 98.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.9,
    "out:CO2 Operational/m2": 22.44,
    "out:Total CO2/m2": 50.34,
    "out:Total CO2 (tons)": 112.01,
    "out:Klimatpaverkan": -30.43,
    "out:Initial Cost/MSEK": 5.593,
    "out:Running cost/(Apt SEK y)": 24060,
    "out:Running Cost over RSP/MSEK": 3.374,
    "out:LCP/MSEK": -0.631,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 116.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2928.4,
    "out:Return %": 11,
    "out:Return/kSEK/y": 633,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -53040,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162926,
    "out:DH kr savings": 253094,
    "out:El kr savings": -90167,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104052,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.96,
    "out:Etvv": 0,
    "out:Ef": 39.74,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.76,
    "out:Electricity (inc PV)": 39.63,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 82.63,
    "out:Primary Energy": 95.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.9,
    "out:CO2 Operational/m2": 20.99,
    "out:Total CO2/m2": 48.89,
    "out:Total CO2 (tons)": 108.78,
    "out:Klimatpaverkan": -33.66,
    "out:Initial Cost/MSEK": 5.648625,
    "out:Running cost/(Apt SEK y)": 22745,
    "out:Running Cost over RSP/MSEK": 3.189,
    "out:LCP/MSEK": -0.501,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 126.5,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2923.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 657,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -52798,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171437,
    "out:DH kr savings": 261193,
    "out:El kr savings": -89756,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 96315,
    "out:% savings (space heating)": 66.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 34.6,
    "out:Etvv": 0,
    "out:Ef": 39.63,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 31.05,
    "out:Total Energy Use Pre PV": 93.65,
    "out:Total Energy Use Post PV": 82.05,
    "out:Primary Energy": 83.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.83,
    "out:CO2 Operational/m2": 14.38,
    "out:Total CO2/m2": 54.21,
    "out:Total CO2 (tons)": 120.62,
    "out:Klimatpaverkan": -21.82,
    "out:Initial Cost/MSEK": 5.942,
    "out:Running cost/(Apt SEK y)": 23272,
    "out:Running Cost over RSP/MSEK": 3.272,
    "out:LCP/MSEK": -0.877,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2347.4,
    "out:Return %": 11,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3809.44,
    "out:PV (% sold (El))": 15.32,
    "out:PV (kWh self-consumed)": 21059.7,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -33702,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192559,
    "out:DH kr savings": 244995,
    "out:El kr savings": -57293,
    "out:El kr sold": 4857,
    "out:El kr saved": 35801,
    "out:El kr return": 40659,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112597,
    "out:% savings (space heating)": 60.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.99,
    "out:Etvv": 0,
    "out:Ef": 31.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 30.96,
    "out:Total Energy Use Pre PV": 89.59,
    "out:Total Energy Use Post PV": 77.96,
    "out:Primary Energy": 81.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.83,
    "out:CO2 Operational/m2": 12.9,
    "out:Total CO2/m2": 52.73,
    "out:Total CO2 (tons)": 117.33,
    "out:Klimatpaverkan": -25.11,
    "out:Initial Cost/MSEK": 5.997625,
    "out:Running cost/(Apt SEK y)": 21960,
    "out:Running Cost over RSP/MSEK": 3.087,
    "out:LCP/MSEK": -0.748,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2343.5,
    "out:Return %": 11,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3823.29,
    "out:PV (% sold (El))": 15.37,
    "out:PV (kWh self-consumed)": 21045.85,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -33505,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201010,
    "out:DH kr savings": 253094,
    "out:El kr savings": -56959,
    "out:El kr sold": 4875,
    "out:El kr saved": 35778,
    "out:El kr return": 40653,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104772,
    "out:% savings (space heating)": 63.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 36.6,
    "out:Etvv": 0,
    "out:Ef": 30.96,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 30.59,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 77.59,
    "out:Primary Energy": 81.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.25,
    "out:CO2 Operational/m2": 12.42,
    "out:Total CO2/m2": 52.67,
    "out:Total CO2 (tons)": 117.19,
    "out:Klimatpaverkan": -25.25,
    "out:Initial Cost/MSEK": 6.05575,
    "out:Running cost/(Apt SEK y)": 21806,
    "out:Running Cost over RSP/MSEK": 3.065,
    "out:LCP/MSEK": -0.785,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2295.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 3997.1,
    "out:PV (% sold (El))": 16.07,
    "out:PV (kWh self-consumed)": 20872.04,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -32678,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202638,
    "out:DH kr savings": 253094,
    "out:El kr savings": -55552,
    "out:El kr sold": 5096,
    "out:El kr saved": 35482,
    "out:El kr return": 40579,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104052,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.96,
    "out:Etvv": 0,
    "out:Ef": 30.59,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.76,
    "out:Electricity (inc PV)": 30.49,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 73.49,
    "out:Primary Energy": 79.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.25,
    "out:CO2 Operational/m2": 10.93,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 113.88,
    "out:Klimatpaverkan": -28.56,
    "out:Initial Cost/MSEK": 6.111375,
    "out:Running cost/(Apt SEK y)": 20491,
    "out:Running Cost over RSP/MSEK": 2.88,
    "out:LCP/MSEK": -0.655,
    "out:ROI% old": 12.75,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2291.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 697,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4014.11,
    "out:PV (% sold (El))": 16.14,
    "out:PV (kWh self-consumed)": 20855.03,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -32463,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211123,
    "out:DH kr savings": 261193,
    "out:El kr savings": -55188,
    "out:El kr sold": 5118,
    "out:El kr saved": 35454,
    "out:El kr return": 40572,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 96315,
    "out:% savings (space heating)": 66.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 34.6,
    "out:Etvv": 0,
    "out:Ef": 30.49,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 28.07,
    "out:Total Energy Use Pre PV": 93.65,
    "out:Total Energy Use Post PV": 79.07,
    "out:Primary Energy": 78.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 52.18,
    "out:CO2 Operational/m2": -26.41,
    "out:Total CO2/m2": 25.78,
    "out:Total CO2 (tons)": 57.35,
    "out:Klimatpaverkan": -85.09,
    "out:Initial Cost/MSEK": 6.40475,
    "out:Running cost/(Apt SEK y)": 21342,
    "out:Running Cost over RSP/MSEK": 3.007,
    "out:LCP/MSEK": -1.076,
    "out:ROI% old": 11.89,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 138,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2119.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21566.02,
    "out:PV (% sold (El))": 43.36,
    "out:PV (kWh self-consumed)": 28172.26,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 269225,
    "out:EL kWh savings": -27079,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226456,
    "out:DH kr savings": 244995,
    "out:El kr savings": -46035,
    "out:El kr sold": 27497,
    "out:El kr saved": 47893,
    "out:El kr return": 75390,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 112597,
    "out:% savings (space heating)": 60.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 39.99,
    "out:Etvv": 0,
    "out:Ef": 28.07,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 27.99,
    "out:Total Energy Use Pre PV": 89.59,
    "out:Total Energy Use Post PV": 74.99,
    "out:Primary Energy": 76.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 52.18,
    "out:CO2 Operational/m2": -27.91,
    "out:Total CO2/m2": 24.27,
    "out:Total CO2 (tons)": 54,
    "out:Klimatpaverkan": -88.44,
    "out:Initial Cost/MSEK": 6.460375,
    "out:Running cost/(Apt SEK y)": 20030,
    "out:Running Cost over RSP/MSEK": 2.822,
    "out:LCP/MSEK": -0.946,
    "out:ROI% old": 12.19,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2116.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 705,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21592.56,
    "out:PV (% sold (El))": 43.41,
    "out:PV (kWh self-consumed)": 28145.72,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -26903,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234889,
    "out:DH kr savings": 253094,
    "out:El kr savings": -45735,
    "out:El kr sold": 27531,
    "out:El kr saved": 47848,
    "out:El kr return": 75378,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104772,
    "out:% savings (space heating)": 63.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 36.6,
    "out:Etvv": 0,
    "out:Ef": 27.99,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.83,
    "out:Electricity (inc PV)": 27.66,
    "out:Total Energy Use Pre PV": 88.83,
    "out:Total Energy Use Post PV": 74.66,
    "out:Primary Energy": 76.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 52.6,
    "out:CO2 Operational/m2": -28.76,
    "out:Total CO2/m2": 23.85,
    "out:Total CO2 (tons)": 53.07,
    "out:Klimatpaverkan": -89.37,
    "out:Initial Cost/MSEK": 6.518625,
    "out:Running cost/(Apt SEK y)": 19880,
    "out:Running Cost over RSP/MSEK": 2.801,
    "out:LCP/MSEK": -0.984,
    "out:ROI% old": 12.13,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 150.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2073.6,
    "out:Return %": 11,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21922.26,
    "out:PV (% sold (El))": 44.08,
    "out:PV (kWh self-consumed)": 27816.02,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 278125,
    "out:EL kWh savings": -26159,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236574,
    "out:DH kr savings": 253094,
    "out:El kr savings": -44471,
    "out:El kr sold": 27951,
    "out:El kr saved": 47287,
    "out:El kr return": 75238,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 104052,
    "out:% savings (space heating)": 63.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.96,
    "out:Etvv": 0,
    "out:Ef": 27.66,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.76,
    "out:Electricity (inc PV)": 27.57,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 70.57,
    "out:Primary Energy": 73.84,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.6,
    "out:CO2 Operational/m2": -30.28,
    "out:Total CO2/m2": 22.33,
    "out:Total CO2 (tons)": 49.68,
    "out:Klimatpaverkan": -92.76,
    "out:Initial Cost/MSEK": 6.57425,
    "out:Running cost/(Apt SEK y)": 18566,
    "out:Running Cost over RSP/MSEK": 2.616,
    "out:LCP/MSEK": -0.854,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 164.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2069.4,
    "out:Return %": 11,
    "out:Return/kSEK/y": 732,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 23.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 21954.33,
    "out:PV (% sold (El))": 44.14,
    "out:PV (kWh self-consumed)": 27783.95,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -25967,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245041,
    "out:DH kr savings": 261193,
    "out:El kr savings": -44144,
    "out:El kr sold": 27992,
    "out:El kr saved": 47233,
    "out:El kr return": 75224,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 96315,
    "out:% savings (space heating)": 66.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 34.6,
    "out:Etvv": 0,
    "out:Ef": 27.57,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.56,
    "out:Electricity (inc PV)": 39.56,
    "out:Total Energy Use Pre PV": 87.56,
    "out:Total Energy Use Post PV": 85.56,
    "out:Primary Energy": 97.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 22.04,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 110.18,
    "out:Klimatpaverkan": -32.26,
    "out:Initial Cost/MSEK": 5.526625,
    "out:Running cost/(Apt SEK y)": 23677,
    "out:Running Cost over RSP/MSEK": 3.32,
    "out:LCP/MSEK": -0.511,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 118.6,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2908.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -52649,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165614,
    "out:DH kr savings": 255118,
    "out:El kr savings": -89504,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101257,
    "out:% savings (space heating)": 64.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.28,
    "out:Etvv": 0,
    "out:Ef": 39.56,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 39.45,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 81.45,
    "out:Primary Energy": 94.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.48,
    "out:CO2 Operational/m2": 20.59,
    "out:Total CO2/m2": 48.07,
    "out:Total CO2 (tons)": 106.96,
    "out:Klimatpaverkan": -35.48,
    "out:Initial Cost/MSEK": 5.58225,
    "out:Running cost/(Apt SEK y)": 22361,
    "out:Running Cost over RSP/MSEK": 3.135,
    "out:LCP/MSEK": -0.381,
    "out:ROI% old": 13.31,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 132.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2903.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 289250,
    "out:EL kWh savings": -52400,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174138,
    "out:DH kr savings": 263217,
    "out:El kr savings": -89079,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 93552,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 33.94,
    "out:Etvv": 0,
    "out:Ef": 39.45,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 39.14,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 82.14,
    "out:Primary Energy": 95.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.9,
    "out:CO2 Operational/m2": 20.87,
    "out:Total CO2/m2": 48.77,
    "out:Total CO2 (tons)": 108.52,
    "out:Klimatpaverkan": -33.92,
    "out:Initial Cost/MSEK": 5.6405,
    "out:Running cost/(Apt SEK y)": 22558,
    "out:Running Cost over RSP/MSEK": 3.163,
    "out:LCP/MSEK": -0.467,
    "out:ROI% old": 13.1,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 129.3,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2861.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -51701,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173301,
    "out:DH kr savings": 261193,
    "out:El kr savings": -87892,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 94657,
    "out:% savings (space heating)": 66.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 39.14,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.79,
    "out:Electricity (inc PV)": 39.01,
    "out:Total Energy Use Pre PV": 79.79,
    "out:Total Energy Use Post PV": 78.01,
    "out:Primary Energy": 92.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.9,
    "out:CO2 Operational/m2": 19.42,
    "out:Total CO2/m2": 47.32,
    "out:Total CO2 (tons)": 105.29,
    "out:Klimatpaverkan": -37.15,
    "out:Initial Cost/MSEK": 5.696125,
    "out:Running cost/(Apt SEK y)": 21241,
    "out:Running Cost over RSP/MSEK": 2.977,
    "out:LCP/MSEK": -0.337,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 141,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2855,
    "out:Return %": 12,
    "out:Return/kSEK/y": 684,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 295925,
    "out:EL kWh savings": -51428,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181865,
    "out:DH kr savings": 269292,
    "out:El kr savings": -87427,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 87024,
    "out:% savings (space heating)": 69.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 32.38,
    "out:Etvv": 0,
    "out:Ef": 39.01,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.56,
    "out:Electricity (inc PV)": 30.43,
    "out:Total Energy Use Pre PV": 87.56,
    "out:Total Energy Use Post PV": 76.43,
    "out:Primary Energy": 80.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.83,
    "out:CO2 Operational/m2": 11.87,
    "out:Total CO2/m2": 51.7,
    "out:Total CO2 (tons)": 115.04,
    "out:Klimatpaverkan": -27.4,
    "out:Initial Cost/MSEK": 5.9895,
    "out:Running cost/(Apt SEK y)": 21424,
    "out:Running Cost over RSP/MSEK": 3.012,
    "out:LCP/MSEK": -0.665,
    "out:ROI% old": 12.7,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 147.4,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2278.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4062.54,
    "out:PV (% sold (El))": 16.34,
    "out:PV (kWh self-consumed)": 20806.6,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -32332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205334,
    "out:DH kr savings": 255118,
    "out:El kr savings": -54965,
    "out:El kr sold": 5180,
    "out:El kr saved": 35371,
    "out:El kr return": 40551,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101257,
    "out:% savings (space heating)": 64.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.28,
    "out:Etvv": 0,
    "out:Ef": 30.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 30.33,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 72.33,
    "out:Primary Energy": 78.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.83,
    "out:CO2 Operational/m2": 10.38,
    "out:Total CO2/m2": 50.21,
    "out:Total CO2 (tons)": 111.72,
    "out:Klimatpaverkan": -30.72,
    "out:Initial Cost/MSEK": 6.045125,
    "out:Running cost/(Apt SEK y)": 20108,
    "out:Running Cost over RSP/MSEK": 2.826,
    "out:LCP/MSEK": -0.535,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 161.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2273.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4080.83,
    "out:PV (% sold (El))": 16.41,
    "out:PV (kWh self-consumed)": 20788.31,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289250,
    "out:EL kWh savings": -32111,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213832,
    "out:DH kr savings": 263217,
    "out:El kr savings": -54589,
    "out:El kr sold": 5203,
    "out:El kr saved": 35340,
    "out:El kr return": 40543,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 93552,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 33.94,
    "out:Etvv": 0,
    "out:Ef": 30.33,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 30.05,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 73.05,
    "out:Primary Energy": 79.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.25,
    "out:CO2 Operational/m2": 10.34,
    "out:Total CO2/m2": 50.59,
    "out:Total CO2 (tons)": 112.57,
    "out:Klimatpaverkan": -29.87,
    "out:Initial Cost/MSEK": 6.10325,
    "out:Running cost/(Apt SEK y)": 20310,
    "out:Running Cost over RSP/MSEK": 2.855,
    "out:LCP/MSEK": -0.622,
    "out:ROI% old": 12.83,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 157.5,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2234.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4226.01,
    "out:PV (% sold (El))": 16.99,
    "out:PV (kWh self-consumed)": 20643.13,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -31494,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213041,
    "out:DH kr savings": 261193,
    "out:El kr savings": -53539,
    "out:El kr sold": 5388,
    "out:El kr saved": 35093,
    "out:El kr return": 40481,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 94657,
    "out:% savings (space heating)": 66.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 30.05,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.79,
    "out:Electricity (inc PV)": 29.95,
    "out:Total Energy Use Pre PV": 79.79,
    "out:Total Energy Use Post PV": 68.95,
    "out:Primary Energy": 76.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.25,
    "out:CO2 Operational/m2": 8.84,
    "out:Total CO2/m2": 49.1,
    "out:Total CO2 (tons)": 109.24,
    "out:Klimatpaverkan": -33.2,
    "out:Initial Cost/MSEK": 6.158875,
    "out:Running cost/(Apt SEK y)": 18992,
    "out:Running Cost over RSP/MSEK": 2.669,
    "out:LCP/MSEK": -0.492,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2229.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 724,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 4247.15,
    "out:PV (% sold (El))": 17.08,
    "out:PV (kWh self-consumed)": 20621.99,
    "out:PV (ratio sold/self-consumed)": 0.21,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 295925,
    "out:EL kWh savings": -31253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221577,
    "out:DH kr savings": 269292,
    "out:El kr savings": -53130,
    "out:El kr sold": 5415,
    "out:El kr saved": 35057,
    "out:El kr return": 40472,
    "out:% solar/total": 58,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 87024,
    "out:% savings (space heating)": 69.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 32.38,
    "out:Etvv": 0,
    "out:Ef": 29.95,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.56,
    "out:Electricity (inc PV)": 27.52,
    "out:Total Energy Use Pre PV": 87.56,
    "out:Total Energy Use Post PV": 73.52,
    "out:Primary Energy": 75.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 52.18,
    "out:CO2 Operational/m2": -29.43,
    "out:Total CO2/m2": 22.75,
    "out:Total CO2 (tons)": 50.63,
    "out:Klimatpaverkan": -91.81,
    "out:Initial Cost/MSEK": 6.45225,
    "out:Running cost/(Apt SEK y)": 19500,
    "out:Running Cost over RSP/MSEK": 2.748,
    "out:LCP/MSEK": -0.864,
    "out:ROI% old": 12.37,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 154.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2057.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22045.18,
    "out:PV (% sold (El))": 44.32,
    "out:PV (kWh self-consumed)": 27693.1,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280350,
    "out:EL kWh savings": -25849,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239283,
    "out:DH kr savings": 255118,
    "out:El kr savings": -43943,
    "out:El kr sold": 28108,
    "out:El kr saved": 47078,
    "out:El kr return": 75186,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 101257,
    "out:% savings (space heating)": 64.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 37.28,
    "out:Etvv": 0,
    "out:Ef": 27.52,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 27.43,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 69.43,
    "out:Primary Energy": 73.13,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.18,
    "out:CO2 Operational/m2": -30.96,
    "out:Total CO2/m2": 21.23,
    "out:Total CO2 (tons)": 47.23,
    "out:Klimatpaverkan": -95.21,
    "out:Initial Cost/MSEK": 6.507875,
    "out:Running cost/(Apt SEK y)": 18185,
    "out:Running Cost over RSP/MSEK": 2.563,
    "out:LCP/MSEK": -0.734,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 172.5,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2053.4,
    "out:Return %": 11,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22079.31,
    "out:PV (% sold (El))": 44.39,
    "out:PV (kWh self-consumed)": 27658.97,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289250,
    "out:EL kWh savings": -25650,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247763,
    "out:DH kr savings": 263217,
    "out:El kr savings": -43605,
    "out:El kr sold": 28151,
    "out:El kr saved": 47020,
    "out:El kr return": 75171,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 93552,
    "out:% savings (space heating)": 67.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 33.94,
    "out:Etvv": 0,
    "out:Ef": 27.43,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.87,
    "out:Electricity (inc PV)": 27.18,
    "out:Total Energy Use Pre PV": 83.87,
    "out:Total Energy Use Post PV": 70.18,
    "out:Primary Energy": 73.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.6,
    "out:CO2 Operational/m2": -31.28,
    "out:Total CO2/m2": 21.32,
    "out:Total CO2 (tons)": 47.45,
    "out:Klimatpaverkan": -94.99,
    "out:Initial Cost/MSEK": 6.566125,
    "out:Running cost/(Apt SEK y)": 18389,
    "out:Running Cost over RSP/MSEK": 2.592,
    "out:LCP/MSEK": -0.822,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 168.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2019.2,
    "out:Return %": 11,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22348.32,
    "out:PV (% sold (El))": 44.93,
    "out:PV (kWh self-consumed)": 27389.96,
    "out:PV (ratio sold/self-consumed)": 0.82,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 287025,
    "out:EL kWh savings": -25095,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247025,
    "out:DH kr savings": 261193,
    "out:El kr savings": -42661,
    "out:El kr sold": 28494,
    "out:El kr saved": 46563,
    "out:El kr return": 75057,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 94657,
    "out:% savings (space heating)": 66.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 35.69,
    "out:Etvv": 0,
    "out:Ef": 27.18,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.79,
    "out:Electricity (inc PV)": 27.08,
    "out:Total Energy Use Pre PV": 79.79,
    "out:Total Energy Use Post PV": 66.08,
    "out:Primary Energy": 71.41,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.6,
    "out:CO2 Operational/m2": -32.82,
    "out:Total CO2/m2": 19.79,
    "out:Total CO2 (tons)": 44.02,
    "out:Klimatpaverkan": -98.42,
    "out:Initial Cost/MSEK": 6.62175,
    "out:Running cost/(Apt SEK y)": 17072,
    "out:Running Cost over RSP/MSEK": 2.406,
    "out:LCP/MSEK": -0.692,
    "out:ROI% old": 12.79,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 184.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2014.3,
    "out:Return %": 11,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 591600,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 29580,
    "out:EAHP (replacement cost)": 446068.893149,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 22.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 22387.14,
    "out:PV (% sold (El))": 45.01,
    "out:PV (kWh self-consumed)": 27351.14,
    "out:PV (ratio sold/self-consumed)": 0.82,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 295925,
    "out:EL kWh savings": -24878,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255542,
    "out:DH kr savings": 269292,
    "out:El kr savings": -42293,
    "out:El kr sold": 28544,
    "out:El kr saved": 46497,
    "out:El kr return": 75041,
    "out:% solar/total": 77,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 87024,
    "out:% savings (space heating)": 69.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 32.38,
    "out:Etvv": 0,
    "out:Ef": 27.08,
    "out:heatPumpQuantity": 4,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 51.74,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 55.74,
    "out:Primary Energy": 94.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": 8.93,
    "out:Total CO2/m2": 37.06,
    "out:Total CO2 (tons)": 82.45,
    "out:Klimatpaverkan": -59.99,
    "out:Initial Cost/MSEK": 6.805125,
    "out:Running cost/(Apt SEK y)": 13228,
    "out:Running Cost over RSP/MSEK": 1.843,
    "out:LCP/MSEK": -0.312,
    "out:ROI% old": 13.63,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 235.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3973.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -79737,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204605,
    "out:DH kr savings": 340158,
    "out:El kr savings": -135553,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8935,
    "out:% savings (space heating)": 96.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.11,
    "out:Etvv": 0,
    "out:Ef": 51.74,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.96,
    "out:Electricity (inc PV)": 50.82,
    "out:Total Energy Use Pre PV": 58.96,
    "out:Total Energy Use Post PV": 53.82,
    "out:Primary Energy": 92.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": 8.47,
    "out:Total CO2/m2": 36.59,
    "out:Total CO2 (tons)": 81.41,
    "out:Klimatpaverkan": -61.03,
    "out:Initial Cost/MSEK": 6.86075,
    "out:Running cost/(Apt SEK y)": 12734,
    "out:Running Cost over RSP/MSEK": 1.774,
    "out:LCP/MSEK": -0.298,
    "out:ROI% old": 13.67,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3917.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -77699,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210095,
    "out:DH kr savings": 342183,
    "out:El kr savings": -132088,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6587,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.41,
    "out:Etvv": 0,
    "out:Ef": 50.82,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.02,
    "out:Electricity (inc PV)": 50.69,
    "out:Total Energy Use Pre PV": 58.02,
    "out:Total Energy Use Post PV": 53.69,
    "out:Primary Energy": 92.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.55,
    "out:CO2 Operational/m2": 8.34,
    "out:Total CO2/m2": 36.89,
    "out:Total CO2 (tons)": 82.08,
    "out:Klimatpaverkan": -60.36,
    "out:Initial Cost/MSEK": 6.919,
    "out:Running cost/(Apt SEK y)": 12537,
    "out:Running Cost over RSP/MSEK": 1.746,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 248.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3851.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -77403,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210597,
    "out:DH kr savings": 342183,
    "out:El kr savings": -131586,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7196,
    "out:% savings (space heating)": 97.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 50.69,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.17,
    "out:Electricity (inc PV)": 49.75,
    "out:Total Energy Use Pre PV": 56.17,
    "out:Total Energy Use Post PV": 51.75,
    "out:Primary Energy": 90.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.55,
    "out:CO2 Operational/m2": 7.87,
    "out:Total CO2/m2": 36.42,
    "out:Total CO2 (tons)": 81.03,
    "out:Klimatpaverkan": -61.41,
    "out:Initial Cost/MSEK": 6.974625,
    "out:Running cost/(Apt SEK y)": 12034,
    "out:Running Cost over RSP/MSEK": 1.676,
    "out:LCP/MSEK": -0.314,
    "out:ROI% old": 13.64,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 261.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3792.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 35.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -75321,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216162,
    "out:DH kr savings": 344207,
    "out:El kr savings": -128045,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5177,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.19,
    "out:Etvv": 0,
    "out:Ef": 49.75,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 41.49,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 45.49,
    "out:Primary Energy": 76.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.48,
    "out:CO2 Operational/m2": 4.65,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 100.4,
    "out:Klimatpaverkan": -42.04,
    "out:Initial Cost/MSEK": 7.268,
    "out:Running cost/(Apt SEK y)": 10912,
    "out:Running Cost over RSP/MSEK": 1.526,
    "out:LCP/MSEK": -0.458,
    "out:ROI% old": 13.39,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 317.8,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3270.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1379.84,
    "out:PV (% sold (El))": 5.55,
    "out:PV (kWh self-consumed)": 23489.3,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -56945,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245111,
    "out:DH kr savings": 340158,
    "out:El kr savings": -96806,
    "out:El kr sold": 1759,
    "out:El kr saved": 39932,
    "out:El kr return": 41691,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8935,
    "out:% savings (space heating)": 96.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.11,
    "out:Etvv": 0,
    "out:Ef": 41.49,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.96,
    "out:Electricity (inc PV)": 40.64,
    "out:Total Energy Use Pre PV": 58.96,
    "out:Total Energy Use Post PV": 43.64,
    "out:Primary Energy": 74.15,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.48,
    "out:CO2 Operational/m2": 3.96,
    "out:Total CO2/m2": 44.44,
    "out:Total CO2 (tons)": 98.88,
    "out:Klimatpaverkan": -43.56,
    "out:Initial Cost/MSEK": 7.323625,
    "out:Running cost/(Apt SEK y)": 10420,
    "out:Running Cost over RSP/MSEK": 1.457,
    "out:LCP/MSEK": -0.444,
    "out:ROI% old": 13.42,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3216.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 878,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1480.08,
    "out:PV (% sold (El))": 5.95,
    "out:PV (kWh self-consumed)": 23389.06,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -55052,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250482,
    "out:DH kr savings": 342183,
    "out:El kr savings": -93588,
    "out:El kr sold": 1887,
    "out:El kr saved": 39761,
    "out:El kr return": 41649,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6587,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.41,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.02,
    "out:Electricity (inc PV)": 40.52,
    "out:Total Energy Use Pre PV": 58.02,
    "out:Total Energy Use Post PV": 43.52,
    "out:Primary Energy": 74.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.9,
    "out:CO2 Operational/m2": 3.58,
    "out:Total CO2/m2": 44.48,
    "out:Total CO2 (tons)": 98.96,
    "out:Klimatpaverkan": -43.48,
    "out:Initial Cost/MSEK": 7.38175,
    "out:Running cost/(Apt SEK y)": 10227,
    "out:Running Cost over RSP/MSEK": 1.43,
    "out:LCP/MSEK": -0.475,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 327.3,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3154.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 882,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1601.54,
    "out:PV (% sold (El))": 6.44,
    "out:PV (kWh self-consumed)": 23267.6,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -54778,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251102,
    "out:DH kr savings": 342183,
    "out:El kr savings": -93122,
    "out:El kr sold": 2042,
    "out:El kr saved": 39555,
    "out:El kr return": 41597,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7196,
    "out:% savings (space heating)": 97.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 40.52,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.17,
    "out:Electricity (inc PV)": 39.65,
    "out:Total Energy Use Pre PV": 56.17,
    "out:Total Energy Use Post PV": 41.65,
    "out:Primary Energy": 72.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.9,
    "out:CO2 Operational/m2": 2.85,
    "out:Total CO2/m2": 43.75,
    "out:Total CO2 (tons)": 97.35,
    "out:Klimatpaverkan": -45.09,
    "out:Initial Cost/MSEK": 7.437375,
    "out:Running cost/(Apt SEK y)": 9725,
    "out:Running Cost over RSP/MSEK": 1.36,
    "out:LCP/MSEK": -0.461,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 347.6,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3097.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 35.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1717.01,
    "out:PV (% sold (El))": 6.9,
    "out:PV (kWh self-consumed)": 23152.13,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -52850,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256551,
    "out:DH kr savings": 344207,
    "out:El kr savings": -89845,
    "out:El kr sold": 2189,
    "out:El kr saved": 39359,
    "out:El kr return": 41548,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5177,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.19,
    "out:Etvv": 0,
    "out:Ef": 39.65,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 37.4,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 41.4,
    "out:Primary Energy": 68.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.83,
    "out:CO2 Operational/m2": -28.9,
    "out:Total CO2/m2": 23.93,
    "out:Total CO2 (tons)": 53.25,
    "out:Klimatpaverkan": -89.19,
    "out:Initial Cost/MSEK": 7.73075,
    "out:Running cost/(Apt SEK y)": 8908,
    "out:Running Cost over RSP/MSEK": 1.251,
    "out:LCP/MSEK": -0.645,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2939.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 15972.91,
    "out:PV (% sold (El))": 32.11,
    "out:PV (kWh self-consumed)": 33765.37,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373800,
    "out:EL kWh savings": -47841,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279194,
    "out:DH kr savings": 340158,
    "out:El kr savings": -81329,
    "out:El kr sold": 20365,
    "out:El kr saved": 57401,
    "out:El kr return": 77767,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 8935,
    "out:% savings (space heating)": 96.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 2.11,
    "out:Etvv": 0,
    "out:Ef": 37.4,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.96,
    "out:Electricity (inc PV)": 36.64,
    "out:Total Energy Use Pre PV": 58.96,
    "out:Total Energy Use Post PV": 39.64,
    "out:Primary Energy": 66.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.83,
    "out:CO2 Operational/m2": -30.02,
    "out:Total CO2/m2": 22.81,
    "out:Total CO2 (tons)": 50.75,
    "out:Klimatpaverkan": -91.69,
    "out:Initial Cost/MSEK": 7.786375,
    "out:Running cost/(Apt SEK y)": 8420,
    "out:Running Cost over RSP/MSEK": 1.183,
    "out:LCP/MSEK": -0.633,
    "out:ROI% old": 13.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2892.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 914,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16265.88,
    "out:PV (% sold (El))": 32.7,
    "out:PV (kWh self-consumed)": 33472.4,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -46156,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284456,
    "out:DH kr savings": 342183,
    "out:El kr savings": -78466,
    "out:El kr sold": 20739,
    "out:El kr saved": 56903,
    "out:El kr return": 77642,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6587,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.41,
    "out:Etvv": 0,
    "out:Ef": 36.64,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.02,
    "out:Electricity (inc PV)": 36.53,
    "out:Total Energy Use Pre PV": 58.02,
    "out:Total Energy Use Post PV": 39.53,
    "out:Primary Energy": 67.04,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.25,
    "out:CO2 Operational/m2": -30.91,
    "out:Total CO2/m2": 22.34,
    "out:Total CO2 (tons)": 49.7,
    "out:Klimatpaverkan": -92.74,
    "out:Initial Cost/MSEK": 7.844625,
    "out:Running cost/(Apt SEK y)": 8232,
    "out:Running Cost over RSP/MSEK": 1.156,
    "out:LCP/MSEK": -0.665,
    "out:ROI% old": 13.08,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 370,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 8,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2837.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 918,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16608.16,
    "out:PV (% sold (El))": 33.39,
    "out:PV (kWh self-consumed)": 33130.12,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -45912,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285307,
    "out:DH kr savings": 342183,
    "out:El kr savings": -78051,
    "out:El kr sold": 21175,
    "out:El kr saved": 56321,
    "out:El kr return": 77497,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 7196,
    "out:% savings (space heating)": 97.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 36.53,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.17,
    "out:Electricity (inc PV)": 35.76,
    "out:Total Energy Use Pre PV": 56.17,
    "out:Total Energy Use Post PV": 37.76,
    "out:Primary Energy": 65.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.25,
    "out:CO2 Operational/m2": -32.1,
    "out:Total CO2/m2": 21.16,
    "out:Total CO2 (tons)": 47.08,
    "out:Klimatpaverkan": -95.36,
    "out:Initial Cost/MSEK": 7.90025,
    "out:Running cost/(Apt SEK y)": 7736,
    "out:Running Cost over RSP/MSEK": 1.087,
    "out:LCP/MSEK": -0.651,
    "out:ROI% old": 13.11,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 394.7,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2786.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 927,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 35.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16922.6,
    "out:PV (% sold (El))": 34.02,
    "out:PV (kWh self-consumed)": 32815.68,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -44196,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290650,
    "out:DH kr savings": 344207,
    "out:El kr savings": -75134,
    "out:El kr sold": 21576,
    "out:El kr saved": 55787,
    "out:El kr return": 77363,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5177,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.19,
    "out:Etvv": 0,
    "out:Ef": 35.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.44,
    "out:Electricity (inc PV)": 50.34,
    "out:Total Energy Use Pre PV": 57.44,
    "out:Total Energy Use Post PV": 53.34,
    "out:Primary Energy": 91.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": 8.27,
    "out:Total CO2/m2": 36.39,
    "out:Total CO2 (tons)": 80.97,
    "out:Klimatpaverkan": -61.47,
    "out:Initial Cost/MSEK": 6.852625,
    "out:Running cost/(Apt SEK y)": 12414,
    "out:Running Cost over RSP/MSEK": 1.729,
    "out:LCP/MSEK": -0.245,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 254.7,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3810.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -76621,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211926,
    "out:DH kr savings": 342183,
    "out:El kr savings": -130256,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6684,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 50.34,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.57,
    "out:Electricity (inc PV)": 49.39,
    "out:Total Energy Use Pre PV": 55.57,
    "out:Total Energy Use Post PV": 51.39,
    "out:Primary Energy": 89.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.12,
    "out:CO2 Operational/m2": 7.79,
    "out:Total CO2/m2": 35.92,
    "out:Total CO2 (tons)": 79.91,
    "out:Klimatpaverkan": -62.53,
    "out:Initial Cost/MSEK": 6.90825,
    "out:Running cost/(Apt SEK y)": 11907,
    "out:Running Cost over RSP/MSEK": 1.658,
    "out:LCP/MSEK": -0.23,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3750,
    "out:Return %": 12,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -74524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217517,
    "out:DH kr savings": 344207,
    "out:El kr savings": -126690,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 4762,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.12,
    "out:Etvv": 0,
    "out:Ef": 49.39,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.01,
    "out:Electricity (inc PV)": 49.49,
    "out:Total Energy Use Pre PV": 55.01,
    "out:Total Energy Use Post PV": 51.49,
    "out:Primary Energy": 90.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.55,
    "out:CO2 Operational/m2": 7.72,
    "out:Total CO2/m2": 36.26,
    "out:Total CO2 (tons)": 80.69,
    "out:Klimatpaverkan": -61.75,
    "out:Initial Cost/MSEK": 6.9665,
    "out:Running cost/(Apt SEK y)": 11789,
    "out:Running Cost over RSP/MSEK": 1.642,
    "out:LCP/MSEK": -0.272,
    "out:ROI% old": 13.73,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3710.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -74738,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217154,
    "out:DH kr savings": 344207,
    "out:El kr savings": -127054,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5559,
    "out:% savings (space heating)": 98.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.52,
    "out:Etvv": 0,
    "out:Ef": 49.49,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.11,
    "out:Electricity (inc PV)": 48.53,
    "out:Total Energy Use Pre PV": 54.11,
    "out:Total Energy Use Post PV": 50.53,
    "out:Primary Energy": 88.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.55,
    "out:CO2 Operational/m2": 7.6,
    "out:Total CO2/m2": 36.15,
    "out:Total CO2 (tons)": 80.42,
    "out:Klimatpaverkan": -62.02,
    "out:Initial Cost/MSEK": 7.022125,
    "out:Running cost/(Apt SEK y)": 11601,
    "out:Running Cost over RSP/MSEK": 1.615,
    "out:LCP/MSEK": -0.301,
    "out:ROI% old": 13.67,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 268.6,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3647.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 33.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -72602,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220784,
    "out:DH kr savings": 344207,
    "out:El kr savings": -123423,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 3851,
    "out:% savings (space heating)": 98.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 0.97,
    "out:Etvv": 0,
    "out:Ef": 48.53,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.44,
    "out:Electricity (inc PV)": 40.19,
    "out:Total Energy Use Pre PV": 57.44,
    "out:Total Energy Use Post PV": 43.19,
    "out:Primary Energy": 73.56,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.48,
    "out:CO2 Operational/m2": 3.33,
    "out:Total CO2/m2": 43.8,
    "out:Total CO2 (tons)": 97.46,
    "out:Klimatpaverkan": -44.98,
    "out:Initial Cost/MSEK": 7.3155,
    "out:Running cost/(Apt SEK y)": 10105,
    "out:Running Cost over RSP/MSEK": 1.413,
    "out:LCP/MSEK": -0.392,
    "out:ROI% old": 13.52,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 337.2,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3115,
    "out:Return %": 12,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1680.78,
    "out:PV (% sold (El))": 6.76,
    "out:PV (kWh self-consumed)": 23188.36,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -54054,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252434,
    "out:DH kr savings": 342183,
    "out:El kr savings": -91891,
    "out:El kr sold": 2143,
    "out:El kr saved": 39420,
    "out:El kr return": 41563,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6684,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 40.19,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.57,
    "out:Electricity (inc PV)": 39.32,
    "out:Total Energy Use Pre PV": 55.57,
    "out:Total Energy Use Post PV": 41.32,
    "out:Primary Energy": 71.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.48,
    "out:CO2 Operational/m2": 2.59,
    "out:Total CO2/m2": 43.07,
    "out:Total CO2 (tons)": 95.82,
    "out:Klimatpaverkan": -46.62,
    "out:Initial Cost/MSEK": 7.371125,
    "out:Running cost/(Apt SEK y)": 9601,
    "out:Running Cost over RSP/MSEK": 1.342,
    "out:LCP/MSEK": -0.377,
    "out:ROI% old": 13.56,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3057.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1802.21,
    "out:PV (% sold (El))": 7.25,
    "out:PV (kWh self-consumed)": 23066.93,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -52114,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257911,
    "out:DH kr savings": 344207,
    "out:El kr savings": -88594,
    "out:El kr sold": 2298,
    "out:El kr saved": 39214,
    "out:El kr return": 41512,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 4762,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.12,
    "out:Etvv": 0,
    "out:Ef": 39.32,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.01,
    "out:Electricity (inc PV)": 39.41,
    "out:Total Energy Use Pre PV": 55.01,
    "out:Total Energy Use Post PV": 41.41,
    "out:Primary Energy": 72.01,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.9,
    "out:CO2 Operational/m2": 2.34,
    "out:Total CO2/m2": 43.24,
    "out:Total CO2 (tons)": 96.2,
    "out:Klimatpaverkan": -46.24,
    "out:Initial Cost/MSEK": 7.42925,
    "out:Running cost/(Apt SEK y)": 9484,
    "out:Running Cost over RSP/MSEK": 1.326,
    "out:LCP/MSEK": -0.419,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3020.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 895,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 1884.07,
    "out:PV (% sold (El))": 7.58,
    "out:PV (kWh self-consumed)": 22985.07,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -52312,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257680,
    "out:DH kr savings": 344207,
    "out:El kr savings": -88930,
    "out:El kr sold": 2402,
    "out:El kr saved": 39075,
    "out:El kr return": 41477,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5559,
    "out:% savings (space heating)": 98.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.52,
    "out:Etvv": 0,
    "out:Ef": 39.41,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.11,
    "out:Electricity (inc PV)": 38.53,
    "out:Total Energy Use Pre PV": 54.11,
    "out:Total Energy Use Post PV": 40.53,
    "out:Primary Energy": 70.03,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.9,
    "out:CO2 Operational/m2": 1.93,
    "out:Total CO2/m2": 42.83,
    "out:Total CO2 (tons)": 95.29,
    "out:Klimatpaverkan": -47.15,
    "out:Initial Cost/MSEK": 7.484875,
    "out:Running cost/(Apt SEK y)": 9300,
    "out:Running Cost over RSP/MSEK": 1.3,
    "out:LCP/MSEK": -0.449,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 358.5,
    "out:Op energy cost/MSEK": 9,
    "out:El price/MSEK": 9,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2961.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 33.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 2018.49,
    "out:PV (% sold (El))": 8.12,
    "out:PV (kWh self-consumed)": 22850.65,
    "out:PV (ratio sold/self-consumed)": 0.09,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -50343,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261197,
    "out:DH kr savings": 344207,
    "out:El kr savings": -85584,
    "out:El kr sold": 2574,
    "out:El kr saved": 38846,
    "out:El kr return": 41420,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 3851,
    "out:% savings (space heating)": 98.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 0.97,
    "out:Etvv": 0,
    "out:Ef": 38.53,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.44,
    "out:Electricity (inc PV)": 36.25,
    "out:Total Energy Use Pre PV": 57.44,
    "out:Total Energy Use Post PV": 39.25,
    "out:Primary Energy": 66.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.83,
    "out:CO2 Operational/m2": -31.48,
    "out:Total CO2/m2": 21.35,
    "out:Total CO2 (tons)": 47.5,
    "out:Klimatpaverkan": -94.94,
    "out:Initial Cost/MSEK": 7.77825,
    "out:Running cost/(Apt SEK y)": 8113,
    "out:Running Cost over RSP/MSEK": 1.14,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 13.22,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 382.1,
    "out:Op energy cost/MSEK": 8,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2802.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 16825.67,
    "out:PV (% sold (El))": 33.83,
    "out:PV (kWh self-consumed)": 32912.61,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376025,
    "out:EL kWh savings": -45268,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286680,
    "out:DH kr savings": 342183,
    "out:El kr savings": -76955,
    "out:El kr sold": 21453,
    "out:El kr saved": 55951,
    "out:El kr return": 77404,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 6684,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.73,
    "out:Etvv": 0,
    "out:Ef": 36.25,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.57,
    "out:Electricity (inc PV)": 35.47,
    "out:Total Energy Use Pre PV": 55.57,
    "out:Total Energy Use Post PV": 37.47,
    "out:Primary Energy": 64.63,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 52.83,
    "out:CO2 Operational/m2": -32.68,
    "out:Total CO2/m2": 20.15,
    "out:Total CO2 (tons)": 44.84,
    "out:Klimatpaverkan": -97.6,
    "out:Initial Cost/MSEK": 7.833875,
    "out:Running cost/(Apt SEK y)": 7614,
    "out:Running Cost over RSP/MSEK": 1.07,
    "out:LCP/MSEK": -0.567,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 408.1,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2751.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17147.2,
    "out:PV (% sold (El))": 34.47,
    "out:PV (kWh self-consumed)": 32591.08,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -43541,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292051,
    "out:DH kr savings": 344207,
    "out:El kr savings": -74019,
    "out:El kr sold": 21863,
    "out:El kr saved": 55405,
    "out:El kr return": 77268,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 4762,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.12,
    "out:Etvv": 0,
    "out:Ef": 35.47,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.01,
    "out:Electricity (inc PV)": 35.55,
    "out:Total Energy Use Pre PV": 55.01,
    "out:Total Energy Use Post PV": 37.55,
    "out:Primary Energy": 65.05,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.25,
    "out:CO2 Operational/m2": -33.23,
    "out:Total CO2/m2": 20.02,
    "out:Total CO2 (tons)": 44.55,
    "out:Klimatpaverkan": -97.89,
    "out:Initial Cost/MSEK": 7.892125,
    "out:Running cost/(Apt SEK y)": 7501,
    "out:Running Cost over RSP/MSEK": 1.054,
    "out:LCP/MSEK": -0.61,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 394.7,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2718.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 931,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17359.33,
    "out:PV (% sold (El))": 34.9,
    "out:PV (kWh self-consumed)": 32378.95,
    "out:PV (ratio sold/self-consumed)": 0.54,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -43717,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292022,
    "out:DH kr savings": 344207,
    "out:El kr savings": -74318,
    "out:El kr sold": 22133,
    "out:El kr saved": 55044,
    "out:El kr return": 77177,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 5559,
    "out:% savings (space heating)": 98.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 1.52,
    "out:Etvv": 0,
    "out:Ef": 35.55,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.11,
    "out:Electricity (inc PV)": 34.76,
    "out:Total Energy Use Pre PV": 54.11,
    "out:Total Energy Use Post PV": 36.76,
    "out:Primary Energy": 63.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.25,
    "out:CO2 Operational/m2": -34.11,
    "out:Total CO2/m2": 19.14,
    "out:Total CO2 (tons)": 42.59,
    "out:Klimatpaverkan": -99.85,
    "out:Initial Cost/MSEK": 7.94775,
    "out:Running cost/(Apt SEK y)": 7321,
    "out:Running Cost over RSP/MSEK": 1.029,
    "out:LCP/MSEK": -0.64,
    "out:ROI% old": 13.13,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 408.1,
    "out:Op energy cost/MSEK": 7,
    "out:El price/MSEK": 7,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2666.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1331100,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 66555,
    "out:EAHP (replacement cost)": 995528.285975,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 33.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 17698.22,
    "out:PV (% sold (El))": 35.58,
    "out:PV (kWh self-consumed)": 32040.06,
    "out:PV (ratio sold/self-consumed)": 0.55,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378250,
    "out:EL kWh savings": -41963,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295436,
    "out:DH kr savings": 344207,
    "out:El kr savings": -71336,
    "out:El kr sold": 22565,
    "out:El kr saved": 54468,
    "out:El kr return": 77033,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 3851,
    "out:% savings (space heating)": 98.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 0.97,
    "out:Etvv": 0,
    "out:Ef": 34.76,
    "out:heatPumpQuantity": 9,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 163.5,
    "out:Total Energy Use Post PV": 163.5,
    "out:Primary Energy": 124.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.04,
    "out:CO2 Operational/m2": 54.18,
    "out:Total CO2/m2": 56.22,
    "out:Total CO2 (tons)": 125.09,
    "out:Klimatpaverkan": -17.35,
    "out:Initial Cost/MSEK": 1.6045,
    "out:Running cost/(Apt SEK y)": 50765,
    "out:Running Cost over RSP/MSEK": 7.144,
    "out:LCP/MSEK": -0.412,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 15.3,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41322,
    "out:DH kr savings": 58718,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 158.5,
    "out:Total Energy Use Post PV": 158.5,
    "out:Primary Energy": 120.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.04,
    "out:CO2 Operational/m2": 52.38,
    "out:Total CO2/m2": 54.42,
    "out:Total CO2 (tons)": 121.08,
    "out:Klimatpaverkan": -21.36,
    "out:Initial Cost/MSEK": 1.660125,
    "out:Running cost/(Apt SEK y)": 49140,
    "out:Running Cost over RSP/MSEK": 6.915,
    "out:LCP/MSEK": -0.239,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51446,
    "out:DH kr savings": 68841,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 121.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.46,
    "out:CO2 Operational/m2": 52.02,
    "out:Total CO2/m2": 54.48,
    "out:Total CO2 (tons)": 121.22,
    "out:Klimatpaverkan": -21.22,
    "out:Initial Cost/MSEK": 1.71825,
    "out:Running cost/(Apt SEK y)": 48815,
    "out:Running Cost over RSP/MSEK": 6.869,
    "out:LCP/MSEK": -0.251,
    "out:ROI% old": 12.2,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53471,
    "out:DH kr savings": 70866,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 117.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.46,
    "out:CO2 Operational/m2": 49.86,
    "out:Total CO2/m2": 52.32,
    "out:Total CO2 (tons)": 116.42,
    "out:Klimatpaverkan": -26.02,
    "out:Initial Cost/MSEK": 1.773875,
    "out:Running cost/(Apt SEK y)": 46866,
    "out:Running Cost over RSP/MSEK": 6.594,
    "out:LCP/MSEK": -0.032,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 222,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 65619,
    "out:DH kr savings": 83015,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 163.5,
    "out:Total Energy Use Post PV": 157.33,
    "out:Primary Energy": 113.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 28.17,
    "out:Total CO2/m2": 42.56,
    "out:Total CO2 (tons)": 94.69,
    "out:Klimatpaverkan": -47.75,
    "out:Initial Cost/MSEK": 2.06725,
    "out:Running cost/(Apt SEK y)": 48679,
    "out:Running Cost over RSP/MSEK": 6.858,
    "out:LCP/MSEK": -0.589,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78862,
    "out:DH kr savings": 58718,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 158.5,
    "out:Total Energy Use Post PV": 152.33,
    "out:Primary Energy": 109.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 26.37,
    "out:Total CO2/m2": 40.76,
    "out:Total CO2 (tons)": 90.69,
    "out:Klimatpaverkan": -51.75,
    "out:Initial Cost/MSEK": 2.122875,
    "out:Running cost/(Apt SEK y)": 47055,
    "out:Running Cost over RSP/MSEK": 6.629,
    "out:LCP/MSEK": -0.416,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88986,
    "out:DH kr savings": 68841,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 151.33,
    "out:Primary Energy": 110.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 26.01,
    "out:Total CO2/m2": 40.82,
    "out:Total CO2 (tons)": 90.83,
    "out:Klimatpaverkan": -51.61,
    "out:Initial Cost/MSEK": 2.181125,
    "out:Running cost/(Apt SEK y)": 46730,
    "out:Running Cost over RSP/MSEK": 6.583,
    "out:LCP/MSEK": -0.428,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91011,
    "out:DH kr savings": 70866,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 145.33,
    "out:Primary Energy": 106.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 23.85,
    "out:Total CO2/m2": 38.66,
    "out:Total CO2 (tons)": 86.02,
    "out:Klimatpaverkan": -56.42,
    "out:Initial Cost/MSEK": 2.23675,
    "out:Running cost/(Apt SEK y)": 44781,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": -0.209,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29.7,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103159,
    "out:DH kr savings": 83015,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 163.5,
    "out:Total Energy Use Post PV": 156.09,
    "out:Primary Energy": 110.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.74,
    "out:CO2 Operational/m2": -22.51,
    "out:Total CO2/m2": 4.23,
    "out:Total CO2 (tons)": 9.41,
    "out:Klimatpaverkan": -133.03,
    "out:Initial Cost/MSEK": 2.530125,
    "out:Running cost/(Apt SEK y)": 46852,
    "out:Running Cost over RSP/MSEK": 6.608,
    "out:LCP/MSEK": -0.802,
    "out:ROI% old": 9.76,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 64525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111745,
    "out:DH kr savings": 58718,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 158.5,
    "out:Total Energy Use Post PV": 151.09,
    "out:Primary Energy": 107.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.74,
    "out:CO2 Operational/m2": -24.31,
    "out:Total CO2/m2": 2.43,
    "out:Total CO2 (tons)": 5.41,
    "out:Klimatpaverkan": -137.03,
    "out:Initial Cost/MSEK": 2.58575,
    "out:Running cost/(Apt SEK y)": 45228,
    "out:Running Cost over RSP/MSEK": 6.379,
    "out:LCP/MSEK": -0.628,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 24.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 75650,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121868,
    "out:DH kr savings": 68841,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 150.09,
    "out:Primary Energy": 108.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": -24.67,
    "out:Total CO2/m2": 2.49,
    "out:Total CO2 (tons)": 5.55,
    "out:Klimatpaverkan": -136.89,
    "out:Initial Cost/MSEK": 2.644,
    "out:Running cost/(Apt SEK y)": 44903,
    "out:Running Cost over RSP/MSEK": 6.333,
    "out:LCP/MSEK": -0.641,
    "out:ROI% old": 10.82,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 77875,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123893,
    "out:DH kr savings": 70866,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 151.5,
    "out:Total Energy Use Post PV": 144.09,
    "out:Primary Energy": 104.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": -26.83,
    "out:Total CO2/m2": 0.33,
    "out:Total CO2 (tons)": 0.74,
    "out:Klimatpaverkan": -141.7,
    "out:Initial Cost/MSEK": 2.699625,
    "out:Running cost/(Apt SEK y)": 42954,
    "out:Running Cost over RSP/MSEK": 6.059,
    "out:LCP/MSEK": -0.422,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 91225,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136042,
    "out:DH kr savings": 83015,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 120.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.04,
    "out:CO2 Operational/m2": 51.3,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 118.68,
    "out:Klimatpaverkan": -23.76,
    "out:Initial Cost/MSEK": 1.652,
    "out:Running cost/(Apt SEK y)": 48166,
    "out:Running Cost over RSP/MSEK": 6.778,
    "out:LCP/MSEK": -0.093,
    "out:ROI% old": 13.48,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 21.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57520,
    "out:DH kr savings": 74916,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 116.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.04,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 113.88,
    "out:Klimatpaverkan": -28.56,
    "out:Initial Cost/MSEK": 1.707625,
    "out:Running cost/(Apt SEK y)": 46216,
    "out:Running Cost over RSP/MSEK": 6.503,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69669,
    "out:DH kr savings": 87064,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 118.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.46,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 114.82,
    "out:Klimatpaverkan": -27.62,
    "out:Initial Cost/MSEK": 1.76575,
    "out:Running cost/(Apt SEK y)": 46216,
    "out:Running Cost over RSP/MSEK": 6.503,
    "out:LCP/MSEK": 0.068,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69669,
    "out:DH kr savings": 87064,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 114.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.46,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 49.8,
    "out:Total CO2 (tons)": 110.81,
    "out:Klimatpaverkan": -31.63,
    "out:Initial Cost/MSEK": 1.821375,
    "out:Running cost/(Apt SEK y)": 44592,
    "out:Running Cost over RSP/MSEK": 6.274,
    "out:LCP/MSEK": 0.241,
    "out:ROI% old": 16.17,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79792,
    "out:DH kr savings": 97188,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 149.33,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 25.29,
    "out:Total CO2/m2": 39.68,
    "out:Total CO2 (tons)": 88.28,
    "out:Klimatpaverkan": -54.16,
    "out:Initial Cost/MSEK": 2.11475,
    "out:Running cost/(Apt SEK y)": 46080,
    "out:Running Cost over RSP/MSEK": 6.492,
    "out:LCP/MSEK": -0.27,
    "out:ROI% old": 12.46,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95060,
    "out:DH kr savings": 74916,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 105.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.39,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 37.52,
    "out:Total CO2 (tons)": 83.48,
    "out:Klimatpaverkan": -58.96,
    "out:Initial Cost/MSEK": 2.170375,
    "out:Running cost/(Apt SEK y)": 44131,
    "out:Running Cost over RSP/MSEK": 6.217,
    "out:LCP/MSEK": -0.051,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 107209,
    "out:DH kr savings": 87064,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 107.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 37.94,
    "out:Total CO2 (tons)": 84.42,
    "out:Klimatpaverkan": -58.02,
    "out:Initial Cost/MSEK": 2.228625,
    "out:Running cost/(Apt SEK y)": 44131,
    "out:Running Cost over RSP/MSEK": 6.217,
    "out:LCP/MSEK": -0.11,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 107209,
    "out:DH kr savings": 87064,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 103.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 36.14,
    "out:Total CO2 (tons)": 80.41,
    "out:Klimatpaverkan": -62.03,
    "out:Initial Cost/MSEK": 2.28425,
    "out:Running cost/(Apt SEK y)": 42506,
    "out:Running Cost over RSP/MSEK": 5.988,
    "out:LCP/MSEK": 0.064,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117333,
    "out:DH kr savings": 97188,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 148.09,
    "out:Primary Energy": 107.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.74,
    "out:CO2 Operational/m2": -25.39,
    "out:Total CO2/m2": 1.35,
    "out:Total CO2 (tons)": 3,
    "out:Klimatpaverkan": -139.44,
    "out:Initial Cost/MSEK": 2.577625,
    "out:Running cost/(Apt SEK y)": 44253,
    "out:Running Cost over RSP/MSEK": 6.242,
    "out:LCP/MSEK": -0.483,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 27,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 82325,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127943,
    "out:DH kr savings": 74916,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 103.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.74,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": -0.81,
    "out:Total CO2 (tons)": -1.8,
    "out:Klimatpaverkan": -144.24,
    "out:Initial Cost/MSEK": 2.63325,
    "out:Running cost/(Apt SEK y)": 42304,
    "out:Running Cost over RSP/MSEK": 5.967,
    "out:LCP/MSEK": -0.264,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140091,
    "out:DH kr savings": 87064,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.86,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 105.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": -0.39,
    "out:Total CO2 (tons)": -0.86,
    "out:Klimatpaverkan": -143.3,
    "out:Initial Cost/MSEK": 2.6915,
    "out:Running cost/(Apt SEK y)": 42304,
    "out:Running Cost over RSP/MSEK": 5.967,
    "out:LCP/MSEK": -0.322,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140091,
    "out:DH kr savings": 87064,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 222195,
    "out:% savings (space heating)": 22.08,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 128.16,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 101.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": -2.19,
    "out:Total CO2 (tons)": -4.87,
    "out:Klimatpaverkan": -147.31,
    "out:Initial Cost/MSEK": 2.747125,
    "out:Running cost/(Apt SEK y)": 40680,
    "out:Running Cost over RSP/MSEK": 5.738,
    "out:LCP/MSEK": -0.149,
    "out:ROI% old": 13.51,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.89,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150215,
    "out:DH kr savings": 97188,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 209840,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 122.61,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 150.5,
    "out:Primary Energy": 115.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.93,
    "out:CO2 Operational/m2": 49.5,
    "out:Total CO2/m2": 54.44,
    "out:Total CO2 (tons)": 121.12,
    "out:Klimatpaverkan": -21.32,
    "out:Initial Cost/MSEK": 2.05525,
    "out:Running cost/(Apt SEK y)": 46541,
    "out:Running Cost over RSP/MSEK": 6.549,
    "out:LCP/MSEK": -0.268,
    "out:ROI% old": 12.43,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 25.3,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 228,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 67644,
    "out:DH kr savings": 85039,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 111.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.93,
    "out:CO2 Operational/m2": 48.06,
    "out:Total CO2/m2": 53,
    "out:Total CO2 (tons)": 117.92,
    "out:Klimatpaverkan": -24.52,
    "out:Initial Cost/MSEK": 2.110875,
    "out:Running cost/(Apt SEK y)": 45242,
    "out:Running Cost over RSP/MSEK": 6.366,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75743,
    "out:DH kr savings": 93138,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 112.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.36,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 52.7,
    "out:Total CO2 (tons)": 117.26,
    "out:Klimatpaverkan": -25.18,
    "out:Initial Cost/MSEK": 2.169125,
    "out:Running cost/(Apt SEK y)": 44592,
    "out:Running Cost over RSP/MSEK": 6.274,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79792,
    "out:DH kr savings": 97188,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 109.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.36,
    "out:CO2 Operational/m2": 45.54,
    "out:Total CO2/m2": 50.9,
    "out:Total CO2 (tons)": 113.25,
    "out:Klimatpaverkan": -29.19,
    "out:Initial Cost/MSEK": 2.22475,
    "out:Running cost/(Apt SEK y)": 42968,
    "out:Running Cost over RSP/MSEK": 6.045,
    "out:LCP/MSEK": 0.066,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89916,
    "out:DH kr savings": 107312,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 144.33,
    "out:Primary Energy": 104.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.29,
    "out:CO2 Operational/m2": 23.49,
    "out:Total CO2/m2": 40.78,
    "out:Total CO2 (tons)": 90.73,
    "out:Klimatpaverkan": -51.71,
    "out:Initial Cost/MSEK": 2.518125,
    "out:Running cost/(Apt SEK y)": 44456,
    "out:Running Cost over RSP/MSEK": 6.263,
    "out:LCP/MSEK": -0.445,
    "out:ROI% old": 11.76,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 30.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105184,
    "out:DH kr savings": 85039,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 140.33,
    "out:Primary Energy": 100.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.29,
    "out:CO2 Operational/m2": 22.05,
    "out:Total CO2/m2": 39.34,
    "out:Total CO2 (tons)": 87.52,
    "out:Klimatpaverkan": -54.92,
    "out:Initial Cost/MSEK": 2.57375,
    "out:Running cost/(Apt SEK y)": 43156,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": -0.317,
    "out:ROI% old": 12.52,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 113283,
    "out:DH kr savings": 93138,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 101.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.71,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 39.04,
    "out:Total CO2 (tons)": 86.86,
    "out:Klimatpaverkan": -55.58,
    "out:Initial Cost/MSEK": 2.632,
    "out:Running cost/(Apt SEK y)": 42506,
    "out:Running Cost over RSP/MSEK": 5.988,
    "out:LCP/MSEK": -0.284,
    "out:ROI% old": 12.74,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117333,
    "out:DH kr savings": 97188,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 133.33,
    "out:Primary Energy": 98.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.71,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 37.24,
    "out:Total CO2 (tons)": 82.86,
    "out:Klimatpaverkan": -59.58,
    "out:Initial Cost/MSEK": 2.687625,
    "out:Running cost/(Apt SEK y)": 40882,
    "out:Running Cost over RSP/MSEK": 5.76,
    "out:LCP/MSEK": -0.111,
    "out:ROI% old": 13.7,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127456,
    "out:DH kr savings": 107312,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 143.09,
    "out:Primary Energy": 101.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.64,
    "out:CO2 Operational/m2": -27.19,
    "out:Total CO2/m2": 2.45,
    "out:Total CO2 (tons)": 5.45,
    "out:Klimatpaverkan": -136.99,
    "out:Initial Cost/MSEK": 2.981,
    "out:Running cost/(Apt SEK y)": 42629,
    "out:Running Cost over RSP/MSEK": 6.013,
    "out:LCP/MSEK": -0.658,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 31.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 299,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 93450,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138066,
    "out:DH kr savings": 85039,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 139.09,
    "out:Primary Energy": 98.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.64,
    "out:CO2 Operational/m2": -28.63,
    "out:Total CO2/m2": 1.01,
    "out:Total CO2 (tons)": 2.24,
    "out:Klimatpaverkan": -140.2,
    "out:Initial Cost/MSEK": 3.036625,
    "out:Running cost/(Apt SEK y)": 41329,
    "out:Running Cost over RSP/MSEK": 5.83,
    "out:LCP/MSEK": -0.53,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146165,
    "out:DH kr savings": 93138,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 99.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.06,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": 0.71,
    "out:Total CO2 (tons)": 1.58,
    "out:Klimatpaverkan": -140.86,
    "out:Initial Cost/MSEK": 3.09475,
    "out:Running cost/(Apt SEK y)": 40680,
    "out:Running Cost over RSP/MSEK": 5.738,
    "out:LCP/MSEK": -0.497,
    "out:ROI% old": 11.99,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106800,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150215,
    "out:DH kr savings": 97188,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 132.09,
    "out:Primary Energy": 95.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.06,
    "out:CO2 Operational/m2": -31.15,
    "out:Total CO2/m2": -1.09,
    "out:Total CO2 (tons)": -2.42,
    "out:Klimatpaverkan": -144.86,
    "out:Initial Cost/MSEK": 3.150375,
    "out:Running cost/(Apt SEK y)": 39055,
    "out:Running Cost over RSP/MSEK": 5.509,
    "out:LCP/MSEK": -0.324,
    "out:ROI% old": 12.82,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 117925,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160339,
    "out:DH kr savings": 107312,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 111.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.93,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 51.56,
    "out:Total CO2 (tons)": 114.72,
    "out:Klimatpaverkan": -27.72,
    "out:Initial Cost/MSEK": 2.10275,
    "out:Running cost/(Apt SEK y)": 43942,
    "out:Running Cost over RSP/MSEK": 6.183,
    "out:LCP/MSEK": 0.051,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83842,
    "out:DH kr savings": 101237,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 108.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.93,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 49.76,
    "out:Total CO2 (tons)": 110.71,
    "out:Klimatpaverkan": -31.73,
    "out:Initial Cost/MSEK": 2.158375,
    "out:Running cost/(Apt SEK y)": 42318,
    "out:Running Cost over RSP/MSEK": 5.954,
    "out:LCP/MSEK": 0.224,
    "out:ROI% old": 15.77,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93966,
    "out:DH kr savings": 111361,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 109.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.36,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 50.18,
    "out:Total CO2 (tons)": 111.65,
    "out:Klimatpaverkan": -30.79,
    "out:Initial Cost/MSEK": 2.216625,
    "out:Running cost/(Apt SEK y)": 42318,
    "out:Running Cost over RSP/MSEK": 5.954,
    "out:LCP/MSEK": 0.166,
    "out:ROI% old": 15.35,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93966,
    "out:DH kr savings": 111361,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 106.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.36,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 48.38,
    "out:Total CO2 (tons)": 107.65,
    "out:Klimatpaverkan": -34.79,
    "out:Initial Cost/MSEK": 2.27225,
    "out:Running cost/(Apt SEK y)": 40694,
    "out:Running Cost over RSP/MSEK": 5.725,
    "out:LCP/MSEK": 0.339,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104089,
    "out:DH kr savings": 121485,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 100.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.29,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 37.9,
    "out:Total CO2 (tons)": 84.32,
    "out:Klimatpaverkan": -58.12,
    "out:Initial Cost/MSEK": 2.565625,
    "out:Running cost/(Apt SEK y)": 41857,
    "out:Running Cost over RSP/MSEK": 5.897,
    "out:LCP/MSEK": -0.126,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121382,
    "out:DH kr savings": 101237,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 97.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.29,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 36.1,
    "out:Total CO2 (tons)": 80.32,
    "out:Klimatpaverkan": -62.12,
    "out:Initial Cost/MSEK": 2.62125,
    "out:Running cost/(Apt SEK y)": 40232,
    "out:Running Cost over RSP/MSEK": 5.668,
    "out:LCP/MSEK": 0.047,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131506,
    "out:DH kr savings": 111361,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 98.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.71,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 36.52,
    "out:Total CO2 (tons)": 81.26,
    "out:Klimatpaverkan": -61.18,
    "out:Initial Cost/MSEK": 2.6795,
    "out:Running cost/(Apt SEK y)": 40232,
    "out:Running Cost over RSP/MSEK": 5.668,
    "out:LCP/MSEK": -0.011,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131506,
    "out:DH kr savings": 111361,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 95.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.71,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 34.72,
    "out:Total CO2 (tons)": 77.25,
    "out:Klimatpaverkan": -65.19,
    "out:Initial Cost/MSEK": 2.735125,
    "out:Running cost/(Apt SEK y)": 38608,
    "out:Running Cost over RSP/MSEK": 5.439,
    "out:LCP/MSEK": 0.162,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141630,
    "out:DH kr savings": 121485,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 98.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.64,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": -0.43,
    "out:Total CO2 (tons)": -0.96,
    "out:Klimatpaverkan": -143.4,
    "out:Initial Cost/MSEK": 3.0285,
    "out:Running cost/(Apt SEK y)": 40030,
    "out:Running Cost over RSP/MSEK": 5.647,
    "out:LCP/MSEK": -0.339,
    "out:ROI% old": 12.69,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154264,
    "out:DH kr savings": 101237,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 94.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.64,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": -2.23,
    "out:Total CO2 (tons)": -4.96,
    "out:Klimatpaverkan": -147.4,
    "out:Initial Cost/MSEK": 3.084125,
    "out:Running cost/(Apt SEK y)": 38406,
    "out:Running Cost over RSP/MSEK": 5.418,
    "out:LCP/MSEK": -0.166,
    "out:ROI% old": 13.52,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164388,
    "out:DH kr savings": 111361,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 96.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.06,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": -1.81,
    "out:Total CO2 (tons)": -4.02,
    "out:Klimatpaverkan": -146.46,
    "out:Initial Cost/MSEK": 3.14225,
    "out:Running cost/(Apt SEK y)": 38406,
    "out:Running Cost over RSP/MSEK": 5.418,
    "out:LCP/MSEK": -0.224,
    "out:ROI% old": 13.27,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164388,
    "out:DH kr savings": 111361,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 193521,
    "out:% savings (space heating)": 32.14,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 115.28,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 92.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.06,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": -3.61,
    "out:Total CO2 (tons)": -8.03,
    "out:Klimatpaverkan": -150.47,
    "out:Initial Cost/MSEK": 3.197875,
    "out:Running cost/(Apt SEK y)": 36781,
    "out:Running Cost over RSP/MSEK": 5.189,
    "out:LCP/MSEK": -0.051,
    "out:ROI% old": 14.06,
    "out:Payback discounted": 9,
    "out:Atemp": 2225,
    "out:Umean": 0.72,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174512,
    "out:DH kr savings": 121485,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 182469,
    "out:% savings (space heating)": 36.01,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 110.31,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 112.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 60.59,
    "out:Total CO2 (tons)": 134.8,
    "out:Klimatpaverkan": -7.64,
    "out:Initial Cost/MSEK": 3.15925,
    "out:Running cost/(Apt SEK y)": 45567,
    "out:Running Cost over RSP/MSEK": 6.411,
    "out:LCP/MSEK": -1.234,
    "out:ROI% old": 8.7,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73718,
    "out:DH kr savings": 91114,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 109.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 58.79,
    "out:Total CO2 (tons)": 130.8,
    "out:Klimatpaverkan": -11.64,
    "out:Initial Cost/MSEK": 3.214875,
    "out:Running cost/(Apt SEK y)": 43942,
    "out:Running Cost over RSP/MSEK": 6.183,
    "out:LCP/MSEK": -1.061,
    "out:ROI% old": 9.57,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 32.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 275,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83842,
    "out:DH kr savings": 101237,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 109.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 58.49,
    "out:Total CO2 (tons)": 130.14,
    "out:Klimatpaverkan": -12.3,
    "out:Initial Cost/MSEK": 3.273,
    "out:Running cost/(Apt SEK y)": 43293,
    "out:Running Cost over RSP/MSEK": 6.091,
    "out:LCP/MSEK": -1.028,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87891,
    "out:DH kr savings": 105287,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 106.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 56.69,
    "out:Total CO2 (tons)": 126.13,
    "out:Klimatpaverkan": -16.31,
    "out:Initial Cost/MSEK": 3.328625,
    "out:Running cost/(Apt SEK y)": 41668,
    "out:Running Cost over RSP/MSEK": 5.862,
    "out:LCP/MSEK": -0.855,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98015,
    "out:DH kr savings": 115411,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 101.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 46.92,
    "out:Total CO2 (tons)": 104.41,
    "out:Klimatpaverkan": -38.03,
    "out:Initial Cost/MSEK": 3.622,
    "out:Running cost/(Apt SEK y)": 43481,
    "out:Running Cost over RSP/MSEK": 6.126,
    "out:LCP/MSEK": -1.411,
    "out:ROI% old": 8.72,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111258,
    "out:DH kr savings": 91114,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 98.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 100.4,
    "out:Klimatpaverkan": -42.04,
    "out:Initial Cost/MSEK": 3.677625,
    "out:Running cost/(Apt SEK y)": 41857,
    "out:Running Cost over RSP/MSEK": 5.897,
    "out:LCP/MSEK": -1.238,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121382,
    "out:DH kr savings": 101237,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 98.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 44.83,
    "out:Total CO2 (tons)": 99.74,
    "out:Klimatpaverkan": -42.7,
    "out:Initial Cost/MSEK": 3.735875,
    "out:Running cost/(Apt SEK y)": 41207,
    "out:Running Cost over RSP/MSEK": 5.805,
    "out:LCP/MSEK": -1.205,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125432,
    "out:DH kr savings": 105287,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 95.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 43.03,
    "out:Total CO2 (tons)": 95.74,
    "out:Klimatpaverkan": -46.7,
    "out:Initial Cost/MSEK": 3.7915,
    "out:Running cost/(Apt SEK y)": 39583,
    "out:Running Cost over RSP/MSEK": 5.576,
    "out:LCP/MSEK": -1.032,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135555,
    "out:DH kr savings": 115411,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 99.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 8.6,
    "out:Total CO2 (tons)": 19.13,
    "out:Klimatpaverkan": -123.31,
    "out:Initial Cost/MSEK": 4.084875,
    "out:Running cost/(Apt SEK y)": 41654,
    "out:Running Cost over RSP/MSEK": 5.876,
    "out:LCP/MSEK": -1.624,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100125,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144141,
    "out:DH kr savings": 91114,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 95.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 6.8,
    "out:Total CO2 (tons)": 15.12,
    "out:Klimatpaverkan": -127.32,
    "out:Initial Cost/MSEK": 4.1405,
    "out:Running cost/(Apt SEK y)": 40030,
    "out:Running Cost over RSP/MSEK": 5.647,
    "out:LCP/MSEK": -1.451,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111250,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154264,
    "out:DH kr savings": 101237,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 96.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": 6.5,
    "out:Total CO2 (tons)": 14.46,
    "out:Klimatpaverkan": -127.98,
    "out:Initial Cost/MSEK": 4.19875,
    "out:Running cost/(Apt SEK y)": 39380,
    "out:Running Cost over RSP/MSEK": 5.555,
    "out:LCP/MSEK": -1.418,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158314,
    "out:DH kr savings": 105287,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 93.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 4.7,
    "out:Total CO2 (tons)": 10.46,
    "out:Klimatpaverkan": -131.98,
    "out:Initial Cost/MSEK": 4.254375,
    "out:Running cost/(Apt SEK y)": 37756,
    "out:Running Cost over RSP/MSEK": 5.326,
    "out:LCP/MSEK": -1.244,
    "out:ROI% old": 10.11,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168438,
    "out:DH kr savings": 115411,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 109.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 57.35,
    "out:Total CO2 (tons)": 127.6,
    "out:Klimatpaverkan": -14.84,
    "out:Initial Cost/MSEK": 3.20675,
    "out:Running cost/(Apt SEK y)": 42643,
    "out:Running Cost over RSP/MSEK": 6,
    "out:LCP/MSEK": -0.87,
    "out:ROI% old": 10.41,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91941,
    "out:DH kr savings": 109336,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 105.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.16,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 55.55,
    "out:Total CO2 (tons)": 123.59,
    "out:Klimatpaverkan": -18.85,
    "out:Initial Cost/MSEK": 3.262375,
    "out:Running cost/(Apt SEK y)": 41018,
    "out:Running Cost over RSP/MSEK": 5.771,
    "out:LCP/MSEK": -0.697,
    "out:ROI% old": 11.23,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102064,
    "out:DH kr savings": 119460,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 106.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 55.97,
    "out:Total CO2 (tons)": 124.53,
    "out:Klimatpaverkan": -17.91,
    "out:Initial Cost/MSEK": 3.3205,
    "out:Running cost/(Apt SEK y)": 41018,
    "out:Running Cost over RSP/MSEK": 5.771,
    "out:LCP/MSEK": -0.755,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102064,
    "out:DH kr savings": 119460,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 103.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.58,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 120.53,
    "out:Klimatpaverkan": -21.91,
    "out:Initial Cost/MSEK": 3.376125,
    "out:Running cost/(Apt SEK y)": 39394,
    "out:Running Cost over RSP/MSEK": 5.542,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112188,
    "out:DH kr savings": 129584,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 97.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 43.68,
    "out:Total CO2 (tons)": 97.2,
    "out:Klimatpaverkan": -45.24,
    "out:Initial Cost/MSEK": 3.6695,
    "out:Running cost/(Apt SEK y)": 40557,
    "out:Running Cost over RSP/MSEK": 5.714,
    "out:LCP/MSEK": -1.047,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129481,
    "out:DH kr savings": 109336,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 94.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 41.88,
    "out:Total CO2 (tons)": 93.19,
    "out:Klimatpaverkan": -49.25,
    "out:Initial Cost/MSEK": 3.725125,
    "out:Running cost/(Apt SEK y)": 38933,
    "out:Running Cost over RSP/MSEK": 5.485,
    "out:LCP/MSEK": -0.874,
    "out:ROI% old": 10.93,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139605,
    "out:DH kr savings": 119460,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 95.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 42.31,
    "out:Total CO2 (tons)": 94.13,
    "out:Klimatpaverkan": -48.31,
    "out:Initial Cost/MSEK": 3.783375,
    "out:Running cost/(Apt SEK y)": 38933,
    "out:Running Cost over RSP/MSEK": 5.485,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139605,
    "out:DH kr savings": 119460,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 92.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 40.51,
    "out:Total CO2 (tons)": 90.13,
    "out:Klimatpaverkan": -52.31,
    "out:Initial Cost/MSEK": 3.839,
    "out:Running cost/(Apt SEK y)": 37308,
    "out:Running Cost over RSP/MSEK": 5.256,
    "out:LCP/MSEK": -0.759,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149729,
    "out:DH kr savings": 129584,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 95.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 5.36,
    "out:Total CO2 (tons)": 11.92,
    "out:Klimatpaverkan": -130.52,
    "out:Initial Cost/MSEK": 4.132375,
    "out:Running cost/(Apt SEK y)": 38730,
    "out:Running Cost over RSP/MSEK": 5.464,
    "out:LCP/MSEK": -1.26,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162363,
    "out:DH kr savings": 109336,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 92.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.87,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 3.56,
    "out:Total CO2 (tons)": 7.91,
    "out:Klimatpaverkan": -134.53,
    "out:Initial Cost/MSEK": 4.188,
    "out:Running cost/(Apt SEK y)": 37106,
    "out:Running Cost over RSP/MSEK": 5.235,
    "out:LCP/MSEK": -1.087,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172487,
    "out:DH kr savings": 119460,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 93.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 3.98,
    "out:Total CO2 (tons)": 8.85,
    "out:Klimatpaverkan": -133.59,
    "out:Initial Cost/MSEK": 4.24625,
    "out:Running cost/(Apt SEK y)": 37106,
    "out:Running Cost over RSP/MSEK": 5.235,
    "out:LCP/MSEK": -1.145,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172487,
    "out:DH kr savings": 119460,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184816,
    "out:% savings (space heating)": 35.19,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.36,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 90.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 2.18,
    "out:Total CO2 (tons)": 4.85,
    "out:Klimatpaverkan": -137.59,
    "out:Initial Cost/MSEK": 4.301875,
    "out:Running cost/(Apt SEK y)": 35482,
    "out:Running Cost over RSP/MSEK": 5.006,
    "out:LCP/MSEK": -0.972,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182611,
    "out:DH kr savings": 129584,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 174160,
    "out:% savings (space heating)": 38.93,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.58,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 112.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.59,
    "out:CO2 Operational/m2": 48.06,
    "out:Total CO2/m2": 60.65,
    "out:Total CO2 (tons)": 134.95,
    "out:Klimatpaverkan": -7.49,
    "out:Initial Cost/MSEK": 3.291125,
    "out:Running cost/(Apt SEK y)": 45242,
    "out:Running Cost over RSP/MSEK": 6.366,
    "out:LCP/MSEK": -1.32,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 252,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75743,
    "out:DH kr savings": 93138,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 108.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.59,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 58.85,
    "out:Total CO2 (tons)": 130.94,
    "out:Klimatpaverkan": -11.5,
    "out:Initial Cost/MSEK": 3.34675,
    "out:Running cost/(Apt SEK y)": 43617,
    "out:Running Cost over RSP/MSEK": 6.137,
    "out:LCP/MSEK": -1.147,
    "out:ROI% old": 9.39,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85866,
    "out:DH kr savings": 103262,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 109.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.01,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 58.91,
    "out:Total CO2 (tons)": 131.08,
    "out:Klimatpaverkan": -11.36,
    "out:Initial Cost/MSEK": 3.404875,
    "out:Running cost/(Apt SEK y)": 43293,
    "out:Running Cost over RSP/MSEK": 6.091,
    "out:LCP/MSEK": -1.16,
    "out:ROI% old": 9.42,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 87891,
    "out:DH kr savings": 105287,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 106.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.01,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 57.11,
    "out:Total CO2 (tons)": 127.08,
    "out:Klimatpaverkan": -15.36,
    "out:Initial Cost/MSEK": 3.4605,
    "out:Running cost/(Apt SEK y)": 41668,
    "out:Running Cost over RSP/MSEK": 5.862,
    "out:LCP/MSEK": -0.986,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98015,
    "out:DH kr savings": 115411,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 140.33,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 22.05,
    "out:Total CO2/m2": 46.99,
    "out:Total CO2 (tons)": 104.55,
    "out:Klimatpaverkan": -37.89,
    "out:Initial Cost/MSEK": 3.753875,
    "out:Running cost/(Apt SEK y)": 43156,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": -1.497,
    "out:ROI% old": 8.59,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 34.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 113283,
    "out:DH kr savings": 93138,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 97.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 45.19,
    "out:Total CO2 (tons)": 100.55,
    "out:Klimatpaverkan": -41.89,
    "out:Initial Cost/MSEK": 3.8095,
    "out:Running cost/(Apt SEK y)": 41532,
    "out:Running Cost over RSP/MSEK": 5.851,
    "out:LCP/MSEK": -1.324,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123407,
    "out:DH kr savings": 103262,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 98.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 45.25,
    "out:Total CO2 (tons)": 100.68,
    "out:Klimatpaverkan": -41.76,
    "out:Initial Cost/MSEK": 3.86775,
    "out:Running cost/(Apt SEK y)": 41207,
    "out:Running Cost over RSP/MSEK": 5.805,
    "out:LCP/MSEK": -1.337,
    "out:ROI% old": 9.35,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125432,
    "out:DH kr savings": 105287,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 95.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 43.45,
    "out:Total CO2 (tons)": 96.68,
    "out:Klimatpaverkan": -45.76,
    "out:Initial Cost/MSEK": 3.923375,
    "out:Running cost/(Apt SEK y)": 39583,
    "out:Running Cost over RSP/MSEK": 5.576,
    "out:LCP/MSEK": -1.164,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135555,
    "out:DH kr savings": 115411,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 146.5,
    "out:Total Energy Use Post PV": 139.09,
    "out:Primary Energy": 98.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -28.63,
    "out:Total CO2/m2": 8.66,
    "out:Total CO2 (tons)": 19.27,
    "out:Klimatpaverkan": -123.17,
    "out:Initial Cost/MSEK": 4.21675,
    "out:Running cost/(Apt SEK y)": 41329,
    "out:Running Cost over RSP/MSEK": 5.83,
    "out:LCP/MSEK": -1.71,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 35.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102350,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146165,
    "out:DH kr savings": 93138,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 95.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 6.86,
    "out:Total CO2 (tons)": 15.27,
    "out:Klimatpaverkan": -127.17,
    "out:Initial Cost/MSEK": 4.272375,
    "out:Running cost/(Apt SEK y)": 39705,
    "out:Running Cost over RSP/MSEK": 5.601,
    "out:LCP/MSEK": -1.537,
    "out:ROI% old": 9.15,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156289,
    "out:DH kr savings": 103262,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 96.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.72,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": 6.92,
    "out:Total CO2 (tons)": 15.4,
    "out:Klimatpaverkan": -127.04,
    "out:Initial Cost/MSEK": 4.330625,
    "out:Running cost/(Apt SEK y)": 39380,
    "out:Running Cost over RSP/MSEK": 5.555,
    "out:LCP/MSEK": -1.55,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115700,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158314,
    "out:DH kr savings": 105287,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 93.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.72,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 5.12,
    "out:Total CO2 (tons)": 11.4,
    "out:Klimatpaverkan": -131.04,
    "out:Initial Cost/MSEK": 4.38625,
    "out:Running cost/(Apt SEK y)": 37756,
    "out:Running Cost over RSP/MSEK": 5.326,
    "out:LCP/MSEK": -1.376,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126825,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168438,
    "out:DH kr savings": 115411,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 108.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.59,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 57.77,
    "out:Total CO2 (tons)": 128.54,
    "out:Klimatpaverkan": -13.9,
    "out:Initial Cost/MSEK": 3.338625,
    "out:Running cost/(Apt SEK y)": 42643,
    "out:Running Cost over RSP/MSEK": 6,
    "out:LCP/MSEK": -1.002,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 36.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91941,
    "out:DH kr savings": 109336,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 105.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.59,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 55.97,
    "out:Total CO2 (tons)": 124.53,
    "out:Klimatpaverkan": -17.91,
    "out:Initial Cost/MSEK": 3.39425,
    "out:Running cost/(Apt SEK y)": 41018,
    "out:Running Cost over RSP/MSEK": 5.771,
    "out:LCP/MSEK": -0.829,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102064,
    "out:DH kr savings": 119460,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 106.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.01,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 56.03,
    "out:Total CO2 (tons)": 124.67,
    "out:Klimatpaverkan": -17.77,
    "out:Initial Cost/MSEK": 3.452375,
    "out:Running cost/(Apt SEK y)": 40694,
    "out:Running Cost over RSP/MSEK": 5.725,
    "out:LCP/MSEK": -0.841,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104089,
    "out:DH kr savings": 121485,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 103.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.01,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 54.59,
    "out:Total CO2 (tons)": 121.47,
    "out:Klimatpaverkan": -20.97,
    "out:Initial Cost/MSEK": 3.508,
    "out:Running cost/(Apt SEK y)": 39394,
    "out:Running Cost over RSP/MSEK": 5.542,
    "out:LCP/MSEK": -0.714,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112188,
    "out:DH kr savings": 129584,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 97.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 44.11,
    "out:Total CO2 (tons)": 98.14,
    "out:Klimatpaverkan": -44.3,
    "out:Initial Cost/MSEK": 3.801375,
    "out:Running cost/(Apt SEK y)": 40557,
    "out:Running Cost over RSP/MSEK": 5.714,
    "out:LCP/MSEK": -1.179,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 336,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 129481,
    "out:DH kr savings": 109336,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 94.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.94,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 42.31,
    "out:Total CO2 (tons)": 94.14,
    "out:Klimatpaverkan": -48.3,
    "out:Initial Cost/MSEK": 3.857,
    "out:Running cost/(Apt SEK y)": 38933,
    "out:Running Cost over RSP/MSEK": 5.485,
    "out:LCP/MSEK": -1.006,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139605,
    "out:DH kr savings": 119460,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 95.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 42.37,
    "out:Total CO2 (tons)": 94.28,
    "out:Klimatpaverkan": -48.16,
    "out:Initial Cost/MSEK": 3.91525,
    "out:Running cost/(Apt SEK y)": 38608,
    "out:Running Cost over RSP/MSEK": 5.439,
    "out:LCP/MSEK": -1.018,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141630,
    "out:DH kr savings": 121485,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 92.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 40.93,
    "out:Total CO2 (tons)": 91.07,
    "out:Klimatpaverkan": -51.37,
    "out:Initial Cost/MSEK": 3.970875,
    "out:Running cost/(Apt SEK y)": 37308,
    "out:Running Cost over RSP/MSEK": 5.256,
    "out:LCP/MSEK": -0.891,
    "out:ROI% old": 11.08,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149729,
    "out:DH kr savings": 129584,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 95.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 5.78,
    "out:Total CO2 (tons)": 12.86,
    "out:Klimatpaverkan": -129.58,
    "out:Initial Cost/MSEK": 4.26425,
    "out:Running cost/(Apt SEK y)": 38730,
    "out:Running Cost over RSP/MSEK": 5.464,
    "out:LCP/MSEK": -1.392,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 120150,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162363,
    "out:DH kr savings": 109336,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 92.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.29,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 3.98,
    "out:Total CO2 (tons)": 8.86,
    "out:Klimatpaverkan": -133.58,
    "out:Initial Cost/MSEK": 4.319875,
    "out:Running cost/(Apt SEK y)": 37106,
    "out:Running Cost over RSP/MSEK": 5.235,
    "out:LCP/MSEK": -1.218,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172487,
    "out:DH kr savings": 119460,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.74,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 93.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.72,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 4.04,
    "out:Total CO2 (tons)": 9,
    "out:Klimatpaverkan": -133.44,
    "out:Initial Cost/MSEK": 4.378125,
    "out:Running cost/(Apt SEK y)": 36781,
    "out:Running Cost over RSP/MSEK": 5.189,
    "out:LCP/MSEK": -1.231,
    "out:ROI% old": 10.27,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174512,
    "out:DH kr savings": 121485,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 184105,
    "out:% savings (space heating)": 35.44,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 111.05,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.97,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 90.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.72,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 2.6,
    "out:Total CO2 (tons)": 5.79,
    "out:Klimatpaverkan": -136.65,
    "out:Initial Cost/MSEK": 4.43375,
    "out:Running cost/(Apt SEK y)": 35482,
    "out:Running Cost over RSP/MSEK": 5.006,
    "out:LCP/MSEK": -1.104,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182611,
    "out:DH kr savings": 129584,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 173481,
    "out:% savings (space heating)": 39.16,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 106.27,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 103.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.48,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 58.87,
    "out:Total CO2 (tons)": 130.98,
    "out:Klimatpaverkan": -11.46,
    "out:Initial Cost/MSEK": 3.741875,
    "out:Running cost/(Apt SEK y)": 41018,
    "out:Running Cost over RSP/MSEK": 5.771,
    "out:LCP/MSEK": -1.176,
    "out:ROI% old": 9.79,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102064,
    "out:DH kr savings": 119460,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 100.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.48,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 57.43,
    "out:Total CO2 (tons)": 127.78,
    "out:Klimatpaverkan": -14.66,
    "out:Initial Cost/MSEK": 3.7975,
    "out:Running cost/(Apt SEK y)": 39719,
    "out:Running Cost over RSP/MSEK": 5.588,
    "out:LCP/MSEK": -1.049,
    "out:ROI% old": 10.34,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110163,
    "out:DH kr savings": 127559,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 100.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.91,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 56.77,
    "out:Total CO2 (tons)": 126.32,
    "out:Klimatpaverkan": -16.12,
    "out:Initial Cost/MSEK": 3.85575,
    "out:Running cost/(Apt SEK y)": 38744,
    "out:Running Cost over RSP/MSEK": 5.45,
    "out:LCP/MSEK": -0.97,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 116238,
    "out:DH kr savings": 133633,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 97.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.91,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 55.33,
    "out:Total CO2 (tons)": 123.11,
    "out:Klimatpaverkan": -19.33,
    "out:Initial Cost/MSEK": 3.911375,
    "out:Running cost/(Apt SEK y)": 37445,
    "out:Running Cost over RSP/MSEK": 5.267,
    "out:LCP/MSEK": -0.842,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124337,
    "out:DH kr savings": 141732,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 91.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.84,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 45.21,
    "out:Total CO2 (tons)": 100.59,
    "out:Klimatpaverkan": -41.85,
    "out:Initial Cost/MSEK": 4.20475,
    "out:Running cost/(Apt SEK y)": 38933,
    "out:Running Cost over RSP/MSEK": 5.485,
    "out:LCP/MSEK": -1.353,
    "out:ROI% old": 9.69,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139605,
    "out:DH kr savings": 119460,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 89.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.84,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 43.77,
    "out:Total CO2 (tons)": 97.38,
    "out:Klimatpaverkan": -45.06,
    "out:Initial Cost/MSEK": 4.260375,
    "out:Running cost/(Apt SEK y)": 37633,
    "out:Running Cost over RSP/MSEK": 5.302,
    "out:LCP/MSEK": -1.226,
    "out:ROI% old": 10.17,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147704,
    "out:DH kr savings": 127559,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 89.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 43.11,
    "out:Total CO2 (tons)": 95.92,
    "out:Klimatpaverkan": -46.52,
    "out:Initial Cost/MSEK": 4.318625,
    "out:Running cost/(Apt SEK y)": 36659,
    "out:Running Cost over RSP/MSEK": 5.165,
    "out:LCP/MSEK": -1.147,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153778,
    "out:DH kr savings": 133633,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 86.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 41.67,
    "out:Total CO2 (tons)": 92.72,
    "out:Klimatpaverkan": -49.72,
    "out:Initial Cost/MSEK": 4.37425,
    "out:Running cost/(Apt SEK y)": 35359,
    "out:Running Cost over RSP/MSEK": 4.982,
    "out:LCP/MSEK": -1.02,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161877,
    "out:DH kr savings": 141732,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 89.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.19,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 6.88,
    "out:Total CO2 (tons)": 15.31,
    "out:Klimatpaverkan": -127.13,
    "out:Initial Cost/MSEK": 4.667625,
    "out:Running cost/(Apt SEK y)": 37106,
    "out:Running Cost over RSP/MSEK": 5.235,
    "out:LCP/MSEK": -1.566,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131275,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172487,
    "out:DH kr savings": 119460,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 86.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.19,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 5.44,
    "out:Total CO2 (tons)": 12.1,
    "out:Klimatpaverkan": -130.34,
    "out:Initial Cost/MSEK": 4.72325,
    "out:Running cost/(Apt SEK y)": 35807,
    "out:Running Cost over RSP/MSEK": 5.052,
    "out:LCP/MSEK": -1.439,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180586,
    "out:DH kr savings": 127559,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 87.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.61,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 4.78,
    "out:Total CO2 (tons)": 10.64,
    "out:Klimatpaverkan": -131.8,
    "out:Initial Cost/MSEK": 4.781375,
    "out:Running cost/(Apt SEK y)": 34832,
    "out:Running Cost over RSP/MSEK": 4.915,
    "out:LCP/MSEK": -1.36,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186660,
    "out:DH kr savings": 133633,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 84.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.61,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 3.34,
    "out:Total CO2 (tons)": 7.44,
    "out:Klimatpaverkan": -135,
    "out:Initial Cost/MSEK": 4.837,
    "out:Running cost/(Apt SEK y)": 33532,
    "out:Running Cost over RSP/MSEK": 4.731,
    "out:LCP/MSEK": -1.232,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194759,
    "out:DH kr savings": 141732,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 99.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.48,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 55.63,
    "out:Total CO2 (tons)": 123.77,
    "out:Klimatpaverkan": -18.67,
    "out:Initial Cost/MSEK": 3.789375,
    "out:Running cost/(Apt SEK y)": 38095,
    "out:Running Cost over RSP/MSEK": 5.359,
    "out:LCP/MSEK": -0.812,
    "out:ROI% old": 11.22,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 151300,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120287,
    "out:DH kr savings": 137683,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 96.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.48,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 54.19,
    "out:Total CO2 (tons)": 120.57,
    "out:Klimatpaverkan": -21.87,
    "out:Initial Cost/MSEK": 3.845,
    "out:Running cost/(Apt SEK y)": 36795,
    "out:Running Cost over RSP/MSEK": 5.176,
    "out:LCP/MSEK": -0.684,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128386,
    "out:DH kr savings": 145782,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 97.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.91,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 54.25,
    "out:Total CO2 (tons)": 120.71,
    "out:Klimatpaverkan": -21.73,
    "out:Initial Cost/MSEK": 3.90325,
    "out:Running cost/(Apt SEK y)": 36470,
    "out:Running Cost over RSP/MSEK": 5.13,
    "out:LCP/MSEK": -0.697,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130411,
    "out:DH kr savings": 147807,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 94.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.91,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 52.81,
    "out:Total CO2 (tons)": 117.51,
    "out:Klimatpaverkan": -24.93,
    "out:Initial Cost/MSEK": 3.958875,
    "out:Running cost/(Apt SEK y)": 35171,
    "out:Running Cost over RSP/MSEK": 4.947,
    "out:LCP/MSEK": -0.57,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138510,
    "out:DH kr savings": 155906,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 88.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.84,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 41.97,
    "out:Total CO2 (tons)": 93.38,
    "out:Klimatpaverkan": -49.06,
    "out:Initial Cost/MSEK": 4.25225,
    "out:Running cost/(Apt SEK y)": 36009,
    "out:Running Cost over RSP/MSEK": 5.073,
    "out:LCP/MSEK": -0.989,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 151300,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157828,
    "out:DH kr savings": 137683,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 85.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.84,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 40.53,
    "out:Total CO2 (tons)": 90.17,
    "out:Klimatpaverkan": -52.27,
    "out:Initial Cost/MSEK": 4.307875,
    "out:Running cost/(Apt SEK y)": 34710,
    "out:Running Cost over RSP/MSEK": 4.89,
    "out:LCP/MSEK": -0.862,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165927,
    "out:DH kr savings": 145782,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 86.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 40.59,
    "out:Total CO2 (tons)": 90.31,
    "out:Klimatpaverkan": -52.13,
    "out:Initial Cost/MSEK": 4.366125,
    "out:Running cost/(Apt SEK y)": 34385,
    "out:Running Cost over RSP/MSEK": 4.844,
    "out:LCP/MSEK": -0.874,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167951,
    "out:DH kr savings": 147807,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 83.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.26,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 39.15,
    "out:Total CO2 (tons)": 87.11,
    "out:Klimatpaverkan": -55.33,
    "out:Initial Cost/MSEK": 4.42175,
    "out:Running cost/(Apt SEK y)": 33085,
    "out:Running Cost over RSP/MSEK": 4.661,
    "out:LCP/MSEK": -0.747,
    "out:ROI% old": 11.87,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176050,
    "out:DH kr savings": 155906,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 86.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.19,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 3.64,
    "out:Total CO2 (tons)": 8.1,
    "out:Klimatpaverkan": -134.34,
    "out:Initial Cost/MSEK": 4.715125,
    "out:Running cost/(Apt SEK y)": 34182,
    "out:Running Cost over RSP/MSEK": 4.823,
    "out:LCP/MSEK": -1.202,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 151300,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190710,
    "out:DH kr savings": 137683,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 83.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.19,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 2.2,
    "out:Total CO2 (tons)": 4.89,
    "out:Klimatpaverkan": -137.55,
    "out:Initial Cost/MSEK": 4.77075,
    "out:Running cost/(Apt SEK y)": 32883,
    "out:Running Cost over RSP/MSEK": 4.64,
    "out:LCP/MSEK": -1.074,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198809,
    "out:DH kr savings": 145782,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 84.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.61,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 2.26,
    "out:Total CO2 (tons)": 5.03,
    "out:Klimatpaverkan": -137.41,
    "out:Initial Cost/MSEK": 4.828875,
    "out:Running cost/(Apt SEK y)": 32558,
    "out:Running Cost over RSP/MSEK": 4.594,
    "out:LCP/MSEK": -1.087,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200834,
    "out:DH kr savings": 147807,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 154625,
    "out:% savings (space heating)": 45.78,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 97.8,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 81.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.61,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 0.82,
    "out:Total CO2 (tons)": 1.83,
    "out:Klimatpaverkan": -140.61,
    "out:Initial Cost/MSEK": 4.8845,
    "out:Running cost/(Apt SEK y)": 31258,
    "out:Running Cost over RSP/MSEK": 4.411,
    "out:LCP/MSEK": -0.959,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208933,
    "out:DH kr savings": 155906,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145341,
    "out:% savings (space heating)": 49.03,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.62,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 100.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.71,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 64.66,
    "out:Total CO2 (tons)": 143.86,
    "out:Klimatpaverkan": 1.42,
    "out:Initial Cost/MSEK": 4.845875,
    "out:Running cost/(Apt SEK y)": 39719,
    "out:Running Cost over RSP/MSEK": 5.588,
    "out:LCP/MSEK": -2.097,
    "out:ROI% old": 8.1,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 110163,
    "out:DH kr savings": 127559,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 97.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.71,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 63.22,
    "out:Total CO2 (tons)": 140.66,
    "out:Klimatpaverkan": -1.78,
    "out:Initial Cost/MSEK": 4.9015,
    "out:Running cost/(Apt SEK y)": 38419,
    "out:Running Cost over RSP/MSEK": 5.405,
    "out:LCP/MSEK": -1.97,
    "out:ROI% old": 8.54,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 374,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 149075,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118262,
    "out:DH kr savings": 135658,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 97.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.13,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 62.56,
    "out:Total CO2 (tons)": 139.19,
    "out:Klimatpaverkan": -3.25,
    "out:Initial Cost/MSEK": 4.959625,
    "out:Running cost/(Apt SEK y)": 37445,
    "out:Running Cost over RSP/MSEK": 5.267,
    "out:LCP/MSEK": -1.891,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124337,
    "out:DH kr savings": 141732,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 94.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.13,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 61.12,
    "out:Total CO2 (tons)": 135.99,
    "out:Klimatpaverkan": -6.45,
    "out:Initial Cost/MSEK": 5.01525,
    "out:Running cost/(Apt SEK y)": 36145,
    "out:Running Cost over RSP/MSEK": 5.084,
    "out:LCP/MSEK": -1.763,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132436,
    "out:DH kr savings": 149831,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 89.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.07,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 51,
    "out:Total CO2 (tons)": 113.46,
    "out:Klimatpaverkan": -28.98,
    "out:Initial Cost/MSEK": 5.308625,
    "out:Running cost/(Apt SEK y)": 37633,
    "out:Running Cost over RSP/MSEK": 5.302,
    "out:LCP/MSEK": -2.274,
    "out:ROI% old": 8.17,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147704,
    "out:DH kr savings": 127559,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 86.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.07,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 110.26,
    "out:Klimatpaverkan": -32.18,
    "out:Initial Cost/MSEK": 5.36425,
    "out:Running cost/(Apt SEK y)": 36334,
    "out:Running Cost over RSP/MSEK": 5.119,
    "out:LCP/MSEK": -2.147,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149075,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155803,
    "out:DH kr savings": 135658,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 86.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.49,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 108.8,
    "out:Klimatpaverkan": -33.64,
    "out:Initial Cost/MSEK": 5.4225,
    "out:Running cost/(Apt SEK y)": 35359,
    "out:Running Cost over RSP/MSEK": 4.982,
    "out:LCP/MSEK": -2.068,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161877,
    "out:DH kr savings": 141732,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 83.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.49,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 47.46,
    "out:Total CO2 (tons)": 105.59,
    "out:Klimatpaverkan": -36.85,
    "out:Initial Cost/MSEK": 5.478125,
    "out:Running cost/(Apt SEK y)": 34060,
    "out:Running Cost over RSP/MSEK": 4.799,
    "out:LCP/MSEK": -1.94,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169976,
    "out:DH kr savings": 149831,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 86.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.42,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 12.67,
    "out:Total CO2 (tons)": 28.18,
    "out:Klimatpaverkan": -114.26,
    "out:Initial Cost/MSEK": 5.7715,
    "out:Running cost/(Apt SEK y)": 35807,
    "out:Running Cost over RSP/MSEK": 5.052,
    "out:LCP/MSEK": -2.487,
    "out:ROI% old": 8.13,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 140175,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180586,
    "out:DH kr savings": 127559,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 84.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.42,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 11.23,
    "out:Total CO2 (tons)": 24.98,
    "out:Klimatpaverkan": -117.46,
    "out:Initial Cost/MSEK": 5.827125,
    "out:Running cost/(Apt SEK y)": 34507,
    "out:Running Cost over RSP/MSEK": 4.869,
    "out:LCP/MSEK": -2.36,
    "out:ROI% old": 8.5,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149075,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188685,
    "out:DH kr savings": 135658,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 84.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.84,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 10.57,
    "out:Total CO2 (tons)": 23.52,
    "out:Klimatpaverkan": -118.92,
    "out:Initial Cost/MSEK": 5.885375,
    "out:Running cost/(Apt SEK y)": 33532,
    "out:Running Cost over RSP/MSEK": 4.731,
    "out:LCP/MSEK": -2.281,
    "out:ROI% old": 8.75,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194759,
    "out:DH kr savings": 141732,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 81.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.84,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 9.13,
    "out:Total CO2 (tons)": 20.31,
    "out:Klimatpaverkan": -122.13,
    "out:Initial Cost/MSEK": 5.941,
    "out:Running cost/(Apt SEK y)": 32233,
    "out:Running Cost over RSP/MSEK": 4.548,
    "out:LCP/MSEK": -2.153,
    "out:ROI% old": 9.11,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202858,
    "out:DH kr savings": 149831,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 96.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.71,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 61.42,
    "out:Total CO2 (tons)": 136.65,
    "out:Klimatpaverkan": -5.79,
    "out:Initial Cost/MSEK": 4.893375,
    "out:Running cost/(Apt SEK y)": 36795,
    "out:Running Cost over RSP/MSEK": 5.176,
    "out:LCP/MSEK": -1.733,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128386,
    "out:DH kr savings": 145782,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 93.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.71,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 59.98,
    "out:Total CO2 (tons)": 133.45,
    "out:Klimatpaverkan": -8.99,
    "out:Initial Cost/MSEK": 4.949,
    "out:Running cost/(Apt SEK y)": 35496,
    "out:Running Cost over RSP/MSEK": 4.993,
    "out:LCP/MSEK": -1.605,
    "out:ROI% old": 9.65,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 136485,
    "out:DH kr savings": 153881,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 94.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.13,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 60.04,
    "out:Total CO2 (tons)": 133.59,
    "out:Klimatpaverkan": -8.85,
    "out:Initial Cost/MSEK": 5.007125,
    "out:Running cost/(Apt SEK y)": 35171,
    "out:Running Cost over RSP/MSEK": 4.947,
    "out:LCP/MSEK": -1.618,
    "out:ROI% old": 9.67,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138510,
    "out:DH kr savings": 155906,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 91.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.13,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 58.6,
    "out:Total CO2 (tons)": 130.38,
    "out:Klimatpaverkan": -12.06,
    "out:Initial Cost/MSEK": 5.06275,
    "out:Running cost/(Apt SEK y)": 33871,
    "out:Running Cost over RSP/MSEK": 4.764,
    "out:LCP/MSEK": -1.49,
    "out:ROI% old": 10.08,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146609,
    "out:DH kr savings": 164005,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 85.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.07,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 47.76,
    "out:Total CO2 (tons)": 106.26,
    "out:Klimatpaverkan": -36.18,
    "out:Initial Cost/MSEK": 5.356125,
    "out:Running cost/(Apt SEK y)": 34710,
    "out:Running Cost over RSP/MSEK": 4.89,
    "out:LCP/MSEK": -1.91,
    "out:ROI% old": 9.19,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165927,
    "out:DH kr savings": 145782,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 82.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.07,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 46.32,
    "out:Total CO2 (tons)": 103.05,
    "out:Klimatpaverkan": -39.39,
    "out:Initial Cost/MSEK": 5.41175,
    "out:Running cost/(Apt SEK y)": 33410,
    "out:Running Cost over RSP/MSEK": 4.707,
    "out:LCP/MSEK": -1.782,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174026,
    "out:DH kr savings": 153881,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 83.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.49,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 46.38,
    "out:Total CO2 (tons)": 103.19,
    "out:Klimatpaverkan": -39.25,
    "out:Initial Cost/MSEK": 5.47,
    "out:Running cost/(Apt SEK y)": 33085,
    "out:Running Cost over RSP/MSEK": 4.661,
    "out:LCP/MSEK": -1.795,
    "out:ROI% old": 9.6,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176050,
    "out:DH kr savings": 155906,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 80.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.49,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 44.94,
    "out:Total CO2 (tons)": 99.99,
    "out:Klimatpaverkan": -42.45,
    "out:Initial Cost/MSEK": 5.525625,
    "out:Running cost/(Apt SEK y)": 31786,
    "out:Running Cost over RSP/MSEK": 4.478,
    "out:LCP/MSEK": -1.667,
    "out:ROI% old": 9.97,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 494,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184149,
    "out:DH kr savings": 164005,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 83.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.42,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 9.43,
    "out:Total CO2 (tons)": 20.98,
    "out:Klimatpaverkan": -121.46,
    "out:Initial Cost/MSEK": 5.819,
    "out:Running cost/(Apt SEK y)": 32883,
    "out:Running Cost over RSP/MSEK": 4.64,
    "out:LCP/MSEK": -2.123,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160200,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198809,
    "out:DH kr savings": 145782,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 80.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.42,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 7.99,
    "out:Total CO2 (tons)": 17.77,
    "out:Klimatpaverkan": -124.67,
    "out:Initial Cost/MSEK": 5.874625,
    "out:Running cost/(Apt SEK y)": 31583,
    "out:Running Cost over RSP/MSEK": 4.457,
    "out:LCP/MSEK": -1.995,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206908,
    "out:DH kr savings": 153881,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.43,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 81.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.84,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 8.05,
    "out:Total CO2 (tons)": 17.91,
    "out:Klimatpaverkan": -124.53,
    "out:Initial Cost/MSEK": 5.932875,
    "out:Running cost/(Apt SEK y)": 31258,
    "out:Running Cost over RSP/MSEK": 4.411,
    "out:LCP/MSEK": -2.008,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208933,
    "out:DH kr savings": 155906,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 145588,
    "out:% savings (space heating)": 48.95,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 93.73,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 78.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.84,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 6.61,
    "out:Total CO2 (tons)": 14.71,
    "out:Klimatpaverkan": -127.73,
    "out:Initial Cost/MSEK": 5.9885,
    "out:Running cost/(Apt SEK y)": 29959,
    "out:Running Cost over RSP/MSEK": 4.228,
    "out:LCP/MSEK": -1.88,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217032,
    "out:DH kr savings": 164005,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 136715,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 89.75,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 108.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 61.24,
    "out:Total CO2 (tons)": 136.26,
    "out:Klimatpaverkan": -6.18,
    "out:Initial Cost/MSEK": 3.676125,
    "out:Running cost/(Apt SEK y)": 43617,
    "out:Running Cost over RSP/MSEK": 6.137,
    "out:LCP/MSEK": -1.477,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85866,
    "out:DH kr savings": 103262,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 105.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 59.44,
    "out:Total CO2 (tons)": 132.25,
    "out:Klimatpaverkan": -10.19,
    "out:Initial Cost/MSEK": 3.73175,
    "out:Running cost/(Apt SEK y)": 41993,
    "out:Running Cost over RSP/MSEK": 5.908,
    "out:LCP/MSEK": -1.303,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 38.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 310,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124600,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 95990,
    "out:DH kr savings": 113386,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 105.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.4,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 59.14,
    "out:Total CO2 (tons)": 131.59,
    "out:Klimatpaverkan": -10.85,
    "out:Initial Cost/MSEK": 3.789875,
    "out:Running cost/(Apt SEK y)": 41343,
    "out:Running Cost over RSP/MSEK": 5.816,
    "out:LCP/MSEK": -1.27,
    "out:ROI% old": 9.5,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100040,
    "out:DH kr savings": 117435,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 102.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.4,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 128.39,
    "out:Klimatpaverkan": -14.05,
    "out:Initial Cost/MSEK": 3.8455,
    "out:Running cost/(Apt SEK y)": 40044,
    "out:Running Cost over RSP/MSEK": 5.633,
    "out:LCP/MSEK": -1.143,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108139,
    "out:DH kr savings": 125534,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 97.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 47.58,
    "out:Total CO2 (tons)": 105.86,
    "out:Klimatpaverkan": -36.58,
    "out:Initial Cost/MSEK": 4.138875,
    "out:Running cost/(Apt SEK y)": 41532,
    "out:Running Cost over RSP/MSEK": 5.851,
    "out:LCP/MSEK": -1.654,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 39.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123407,
    "out:DH kr savings": 103262,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 94.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 45.78,
    "out:Total CO2 (tons)": 101.85,
    "out:Klimatpaverkan": -40.59,
    "out:Initial Cost/MSEK": 4.1945,
    "out:Running cost/(Apt SEK y)": 39908,
    "out:Running Cost over RSP/MSEK": 5.622,
    "out:LCP/MSEK": -1.48,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 124600,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133531,
    "out:DH kr savings": 113386,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 94.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.75,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 45.48,
    "out:Total CO2 (tons)": 101.19,
    "out:Klimatpaverkan": -41.25,
    "out:Initial Cost/MSEK": 4.25275,
    "out:Running cost/(Apt SEK y)": 39258,
    "out:Running Cost over RSP/MSEK": 5.531,
    "out:LCP/MSEK": -1.447,
    "out:ROI% old": 9.42,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 359,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137580,
    "out:DH kr savings": 117435,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 91.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.75,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 44.04,
    "out:Total CO2 (tons)": 97.99,
    "out:Klimatpaverkan": -44.45,
    "out:Initial Cost/MSEK": 4.308375,
    "out:Running cost/(Apt SEK y)": 37958,
    "out:Running Cost over RSP/MSEK": 5.348,
    "out:LCP/MSEK": -1.32,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 383,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145679,
    "out:DH kr savings": 125534,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": 9.25,
    "out:Total CO2 (tons)": 20.58,
    "out:Klimatpaverkan": -121.86,
    "out:Initial Cost/MSEK": 4.60175,
    "out:Running cost/(Apt SEK y)": 39705,
    "out:Running Cost over RSP/MSEK": 5.601,
    "out:LCP/MSEK": -1.866,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 40.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 351,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 113475,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156289,
    "out:DH kr savings": 103262,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 91.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 7.45,
    "out:Total CO2 (tons)": 16.57,
    "out:Klimatpaverkan": -125.87,
    "out:Initial Cost/MSEK": 4.657375,
    "out:Running cost/(Apt SEK y)": 38081,
    "out:Running Cost over RSP/MSEK": 5.372,
    "out:LCP/MSEK": -1.693,
    "out:ROI% old": 9.09,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 124600,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166413,
    "out:DH kr savings": 113386,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 92.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.1,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 7.15,
    "out:Total CO2 (tons)": 15.91,
    "out:Klimatpaverkan": -126.53,
    "out:Initial Cost/MSEK": 4.715625,
    "out:Running cost/(Apt SEK y)": 37431,
    "out:Running Cost over RSP/MSEK": 5.281,
    "out:LCP/MSEK": -1.66,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129050,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170462,
    "out:DH kr savings": 117435,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.1,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 5.71,
    "out:Total CO2 (tons)": 12.71,
    "out:Klimatpaverkan": -129.73,
    "out:Initial Cost/MSEK": 4.77125,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 5.098,
    "out:LCP/MSEK": -1.533,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178561,
    "out:DH kr savings": 125534,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 104.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 58,
    "out:Total CO2 (tons)": 129.05,
    "out:Klimatpaverkan": -13.39,
    "out:Initial Cost/MSEK": 3.723625,
    "out:Running cost/(Apt SEK y)": 40694,
    "out:Running Cost over RSP/MSEK": 5.725,
    "out:LCP/MSEK": -1.112,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 104089,
    "out:DH kr savings": 121485,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 101.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 56.2,
    "out:Total CO2 (tons)": 125.04,
    "out:Klimatpaverkan": -17.4,
    "out:Initial Cost/MSEK": 3.77925,
    "out:Running cost/(Apt SEK y)": 39069,
    "out:Running Cost over RSP/MSEK": 5.496,
    "out:LCP/MSEK": -0.939,
    "out:ROI% old": 10.74,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114213,
    "out:DH kr savings": 131609,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 102.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.4,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 56.62,
    "out:Total CO2 (tons)": 125.98,
    "out:Klimatpaverkan": -16.46,
    "out:Initial Cost/MSEK": 3.837375,
    "out:Running cost/(Apt SEK y)": 39069,
    "out:Running Cost over RSP/MSEK": 5.496,
    "out:LCP/MSEK": -0.997,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114213,
    "out:DH kr savings": 131609,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 99.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.4,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 54.82,
    "out:Total CO2 (tons)": 121.98,
    "out:Klimatpaverkan": -20.46,
    "out:Initial Cost/MSEK": 3.893,
    "out:Running cost/(Apt SEK y)": 37445,
    "out:Running Cost over RSP/MSEK": 5.267,
    "out:LCP/MSEK": -0.824,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 392,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124337,
    "out:DH kr savings": 141732,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 93.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 44.34,
    "out:Total CO2 (tons)": 98.65,
    "out:Klimatpaverkan": -43.79,
    "out:Initial Cost/MSEK": 4.186375,
    "out:Running cost/(Apt SEK y)": 38608,
    "out:Running Cost over RSP/MSEK": 5.439,
    "out:LCP/MSEK": -1.289,
    "out:ROI% old": 9.89,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141630,
    "out:DH kr savings": 121485,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 90.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 42.54,
    "out:Total CO2 (tons)": 94.65,
    "out:Klimatpaverkan": -47.79,
    "out:Initial Cost/MSEK": 4.242,
    "out:Running cost/(Apt SEK y)": 36984,
    "out:Running Cost over RSP/MSEK": 5.21,
    "out:LCP/MSEK": -1.116,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151753,
    "out:DH kr savings": 131609,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 91.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.75,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 95.59,
    "out:Klimatpaverkan": -46.85,
    "out:Initial Cost/MSEK": 4.30025,
    "out:Running cost/(Apt SEK y)": 36984,
    "out:Running Cost over RSP/MSEK": 5.21,
    "out:LCP/MSEK": -1.174,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151753,
    "out:DH kr savings": 131609,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 88.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.75,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 41.16,
    "out:Total CO2 (tons)": 91.58,
    "out:Klimatpaverkan": -50.86,
    "out:Initial Cost/MSEK": 4.355875,
    "out:Running cost/(Apt SEK y)": 35359,
    "out:Running Cost over RSP/MSEK": 4.982,
    "out:LCP/MSEK": -1.001,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161877,
    "out:DH kr savings": 141732,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 91.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 6.01,
    "out:Total CO2 (tons)": 13.37,
    "out:Klimatpaverkan": -129.07,
    "out:Initial Cost/MSEK": 4.64925,
    "out:Running cost/(Apt SEK y)": 36781,
    "out:Running Cost over RSP/MSEK": 5.189,
    "out:LCP/MSEK": -1.502,
    "out:ROI% old": 9.67,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174512,
    "out:DH kr savings": 121485,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 88.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 4.21,
    "out:Total CO2 (tons)": 9.37,
    "out:Klimatpaverkan": -133.07,
    "out:Initial Cost/MSEK": 4.704875,
    "out:Running cost/(Apt SEK y)": 35157,
    "out:Running Cost over RSP/MSEK": 4.96,
    "out:LCP/MSEK": -1.329,
    "out:ROI% old": 10.25,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184636,
    "out:DH kr savings": 131609,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.05,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 89.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.1,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 4.63,
    "out:Total CO2 (tons)": 10.31,
    "out:Klimatpaverkan": -132.13,
    "out:Initial Cost/MSEK": 4.763125,
    "out:Running cost/(Apt SEK y)": 35157,
    "out:Running Cost over RSP/MSEK": 4.96,
    "out:LCP/MSEK": -1.387,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184636,
    "out:DH kr savings": 131609,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 171438,
    "out:% savings (space heating)": 39.88,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 105.35,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 86.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.1,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 2.83,
    "out:Total CO2 (tons)": 6.3,
    "out:Klimatpaverkan": -136.14,
    "out:Initial Cost/MSEK": 4.81875,
    "out:Running cost/(Apt SEK y)": 33532,
    "out:Running Cost over RSP/MSEK": 4.731,
    "out:LCP/MSEK": -1.214,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.53,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155750,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194759,
    "out:DH kr savings": 141732,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 161390,
    "out:% savings (space heating)": 43.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 100.84,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 98.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.87,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 59.1,
    "out:Total CO2 (tons)": 131.49,
    "out:Klimatpaverkan": -10.95,
    "out:Initial Cost/MSEK": 4.126875,
    "out:Running cost/(Apt SEK y)": 39069,
    "out:Running Cost over RSP/MSEK": 5.496,
    "out:LCP/MSEK": -1.287,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 48,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114213,
    "out:DH kr savings": 131609,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 96.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.87,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 57.66,
    "out:Total CO2 (tons)": 128.29,
    "out:Klimatpaverkan": -14.15,
    "out:Initial Cost/MSEK": 4.1825,
    "out:Running cost/(Apt SEK y)": 37770,
    "out:Running Cost over RSP/MSEK": 5.313,
    "out:LCP/MSEK": -1.159,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122312,
    "out:DH kr savings": 139708,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 96.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 57.36,
    "out:Total CO2 (tons)": 127.63,
    "out:Klimatpaverkan": -14.81,
    "out:Initial Cost/MSEK": 4.24075,
    "out:Running cost/(Apt SEK y)": 37120,
    "out:Running Cost over RSP/MSEK": 5.222,
    "out:LCP/MSEK": -1.126,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157975,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126361,
    "out:DH kr savings": 143757,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 93.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 55.92,
    "out:Total CO2 (tons)": 124.42,
    "out:Klimatpaverkan": -18.02,
    "out:Initial Cost/MSEK": 4.296375,
    "out:Running cost/(Apt SEK y)": 35820,
    "out:Running Cost over RSP/MSEK": 5.039,
    "out:LCP/MSEK": -0.999,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134460,
    "out:DH kr savings": 151856,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 87.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.23,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 45.44,
    "out:Total CO2 (tons)": 101.09,
    "out:Klimatpaverkan": -41.35,
    "out:Initial Cost/MSEK": 4.58975,
    "out:Running cost/(Apt SEK y)": 36984,
    "out:Running Cost over RSP/MSEK": 5.21,
    "out:LCP/MSEK": -1.464,
    "out:ROI% old": 9.73,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151753,
    "out:DH kr savings": 131609,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 85.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.23,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 44,
    "out:Total CO2 (tons)": 97.89,
    "out:Klimatpaverkan": -44.55,
    "out:Initial Cost/MSEK": 4.645375,
    "out:Running cost/(Apt SEK y)": 35684,
    "out:Running Cost over RSP/MSEK": 5.027,
    "out:LCP/MSEK": -1.336,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159852,
    "out:DH kr savings": 139708,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.65,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 43.7,
    "out:Total CO2 (tons)": 97.23,
    "out:Klimatpaverkan": -45.21,
    "out:Initial Cost/MSEK": 4.703625,
    "out:Running cost/(Apt SEK y)": 35034,
    "out:Running Cost over RSP/MSEK": 4.936,
    "out:LCP/MSEK": -1.303,
    "out:ROI% old": 10.33,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 157975,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163902,
    "out:DH kr savings": 143757,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 82.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.65,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 42.26,
    "out:Total CO2 (tons)": 94.02,
    "out:Klimatpaverkan": -48.42,
    "out:Initial Cost/MSEK": 4.75925,
    "out:Running cost/(Apt SEK y)": 33735,
    "out:Running Cost over RSP/MSEK": 4.753,
    "out:LCP/MSEK": -1.176,
    "out:ROI% old": 10.76,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172001,
    "out:DH kr savings": 151856,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 85.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.58,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 7.11,
    "out:Total CO2 (tons)": 15.81,
    "out:Klimatpaverkan": -126.63,
    "out:Initial Cost/MSEK": 5.052625,
    "out:Running cost/(Apt SEK y)": 35157,
    "out:Running Cost over RSP/MSEK": 4.96,
    "out:LCP/MSEK": -1.677,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144625,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184636,
    "out:DH kr savings": 131609,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 82.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.58,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 5.67,
    "out:Total CO2 (tons)": 12.61,
    "out:Klimatpaverkan": -129.83,
    "out:Initial Cost/MSEK": 5.10825,
    "out:Running cost/(Apt SEK y)": 33857,
    "out:Running Cost over RSP/MSEK": 4.777,
    "out:LCP/MSEK": -1.549,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192735,
    "out:DH kr savings": 139708,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 82.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 5.37,
    "out:Total CO2 (tons)": 11.95,
    "out:Klimatpaverkan": -130.49,
    "out:Initial Cost/MSEK": 5.166375,
    "out:Running cost/(Apt SEK y)": 33208,
    "out:Running Cost over RSP/MSEK": 4.686,
    "out:LCP/MSEK": -1.516,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 157975,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196784,
    "out:DH kr savings": 143757,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 3.93,
    "out:Total CO2 (tons)": 8.75,
    "out:Klimatpaverkan": -133.69,
    "out:Initial Cost/MSEK": 5.222,
    "out:Running cost/(Apt SEK y)": 31908,
    "out:Running Cost over RSP/MSEK": 4.503,
    "out:LCP/MSEK": -1.388,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204883,
    "out:DH kr savings": 151856,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 95.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.87,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 55.86,
    "out:Total CO2 (tons)": 124.28,
    "out:Klimatpaverkan": -18.16,
    "out:Initial Cost/MSEK": 4.174375,
    "out:Running cost/(Apt SEK y)": 36145,
    "out:Running Cost over RSP/MSEK": 5.084,
    "out:LCP/MSEK": -0.922,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132436,
    "out:DH kr savings": 149831,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.87,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 54.78,
    "out:Total CO2 (tons)": 121.88,
    "out:Klimatpaverkan": -20.56,
    "out:Initial Cost/MSEK": 4.23,
    "out:Running cost/(Apt SEK y)": 35171,
    "out:Running Cost over RSP/MSEK": 4.947,
    "out:LCP/MSEK": -0.841,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138510,
    "out:DH kr savings": 155906,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 93.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 54.48,
    "out:Total CO2 (tons)": 121.22,
    "out:Klimatpaverkan": -21.22,
    "out:Initial Cost/MSEK": 4.28825,
    "out:Running cost/(Apt SEK y)": 34521,
    "out:Running Cost over RSP/MSEK": 4.855,
    "out:LCP/MSEK": -0.807,
    "out:ROI% old": 11.6,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142559,
    "out:DH kr savings": 159955,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 90.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.3,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 118.01,
    "out:Klimatpaverkan": -24.43,
    "out:Initial Cost/MSEK": 4.343875,
    "out:Running cost/(Apt SEK y)": 33222,
    "out:Running Cost over RSP/MSEK": 4.672,
    "out:LCP/MSEK": -0.68,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150658,
    "out:DH kr savings": 168054,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 84.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.23,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 42.2,
    "out:Total CO2 (tons)": 93.89,
    "out:Klimatpaverkan": -48.55,
    "out:Initial Cost/MSEK": 4.63725,
    "out:Running cost/(Apt SEK y)": 34060,
    "out:Running Cost over RSP/MSEK": 4.799,
    "out:LCP/MSEK": -1.099,
    "out:ROI% old": 10.9,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169976,
    "out:DH kr savings": 149831,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.23,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 41.12,
    "out:Total CO2 (tons)": 91.48,
    "out:Klimatpaverkan": -50.96,
    "out:Initial Cost/MSEK": 4.692875,
    "out:Running cost/(Apt SEK y)": 33085,
    "out:Running Cost over RSP/MSEK": 4.661,
    "out:LCP/MSEK": -1.018,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176050,
    "out:DH kr savings": 155906,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 82.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.65,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 40.82,
    "out:Total CO2 (tons)": 90.82,
    "out:Klimatpaverkan": -51.62,
    "out:Initial Cost/MSEK": 4.751125,
    "out:Running cost/(Apt SEK y)": 32435,
    "out:Running Cost over RSP/MSEK": 4.57,
    "out:LCP/MSEK": -0.985,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180100,
    "out:DH kr savings": 159955,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 79.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.65,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 39.38,
    "out:Total CO2 (tons)": 87.62,
    "out:Klimatpaverkan": -54.82,
    "out:Initial Cost/MSEK": 4.80675,
    "out:Running cost/(Apt SEK y)": 31136,
    "out:Running Cost over RSP/MSEK": 4.387,
    "out:LCP/MSEK": -0.857,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188199,
    "out:DH kr savings": 168054,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 82.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.58,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 3.87,
    "out:Total CO2 (tons)": 8.61,
    "out:Klimatpaverkan": -133.83,
    "out:Initial Cost/MSEK": 5.100125,
    "out:Running cost/(Apt SEK y)": 32233,
    "out:Running Cost over RSP/MSEK": 4.548,
    "out:LCP/MSEK": -1.312,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202858,
    "out:DH kr savings": 149831,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.58,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 2.79,
    "out:Total CO2 (tons)": 6.2,
    "out:Klimatpaverkan": -136.24,
    "out:Initial Cost/MSEK": 5.15575,
    "out:Running cost/(Apt SEK y)": 31258,
    "out:Running Cost over RSP/MSEK": 4.411,
    "out:LCP/MSEK": -1.231,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208933,
    "out:DH kr savings": 155906,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 80.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 2.49,
    "out:Total CO2 (tons)": 5.54,
    "out:Klimatpaverkan": -136.9,
    "out:Initial Cost/MSEK": 5.213875,
    "out:Running cost/(Apt SEK y)": 30609,
    "out:Running Cost over RSP/MSEK": 4.32,
    "out:LCP/MSEK": -1.197,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212982,
    "out:DH kr savings": 159955,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 141596,
    "out:% savings (space heating)": 50.35,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 91.94,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 77.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 1.05,
    "out:Total CO2 (tons)": 2.34,
    "out:Klimatpaverkan": -140.1,
    "out:Initial Cost/MSEK": 5.2695,
    "out:Running cost/(Apt SEK y)": 29309,
    "out:Running Cost over RSP/MSEK": 4.137,
    "out:LCP/MSEK": -1.07,
    "out:ROI% old": 11.39,
    "out:Payback discounted": 11,
    "out:Atemp": 2225,
    "out:Umean": 0.36,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221081,
    "out:DH kr savings": 168054,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132904,
    "out:% savings (space heating)": 53.39,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 88.03,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 96.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 64.89,
    "out:Total CO2 (tons)": 144.37,
    "out:Klimatpaverkan": 1.93,
    "out:Initial Cost/MSEK": 5.230875,
    "out:Running cost/(Apt SEK y)": 37770,
    "out:Running Cost over RSP/MSEK": 5.313,
    "out:LCP/MSEK": -2.208,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122312,
    "out:DH kr savings": 139708,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 93.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 63.45,
    "out:Total CO2 (tons)": 141.17,
    "out:Klimatpaverkan": -1.27,
    "out:Initial Cost/MSEK": 5.2865,
    "out:Running cost/(Apt SEK y)": 36470,
    "out:Running Cost over RSP/MSEK": 5.13,
    "out:LCP/MSEK": -2.08,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130411,
    "out:DH kr savings": 147807,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 93.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 62.79,
    "out:Total CO2 (tons)": 139.7,
    "out:Klimatpaverkan": -2.74,
    "out:Initial Cost/MSEK": 5.344625,
    "out:Running cost/(Apt SEK y)": 35496,
    "out:Running Cost over RSP/MSEK": 4.993,
    "out:LCP/MSEK": -2.001,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136485,
    "out:DH kr savings": 153881,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 90.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 61.71,
    "out:Total CO2 (tons)": 137.3,
    "out:Klimatpaverkan": -5.14,
    "out:Initial Cost/MSEK": 5.40025,
    "out:Running cost/(Apt SEK y)": 34521,
    "out:Running Cost over RSP/MSEK": 4.855,
    "out:LCP/MSEK": -1.919,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142559,
    "out:DH kr savings": 159955,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 84.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.45,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 51.22,
    "out:Total CO2 (tons)": 113.97,
    "out:Klimatpaverkan": -28.47,
    "out:Initial Cost/MSEK": 5.693625,
    "out:Running cost/(Apt SEK y)": 35684,
    "out:Running Cost over RSP/MSEK": 5.027,
    "out:LCP/MSEK": -2.385,
    "out:ROI% old": 8.3,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159852,
    "out:DH kr savings": 139708,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 82.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.45,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 49.78,
    "out:Total CO2 (tons)": 110.77,
    "out:Klimatpaverkan": -31.67,
    "out:Initial Cost/MSEK": 5.74925,
    "out:Running cost/(Apt SEK y)": 34385,
    "out:Running Cost over RSP/MSEK": 4.844,
    "out:LCP/MSEK": -2.257,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167951,
    "out:DH kr savings": 147807,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 82.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 49.13,
    "out:Total CO2 (tons)": 109.31,
    "out:Klimatpaverkan": -33.13,
    "out:Initial Cost/MSEK": 5.8075,
    "out:Running cost/(Apt SEK y)": 33410,
    "out:Running Cost over RSP/MSEK": 4.707,
    "out:LCP/MSEK": -2.178,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174026,
    "out:DH kr savings": 153881,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 79.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 48.05,
    "out:Total CO2 (tons)": 106.9,
    "out:Klimatpaverkan": -35.54,
    "out:Initial Cost/MSEK": 5.863125,
    "out:Running cost/(Apt SEK y)": 32435,
    "out:Running Cost over RSP/MSEK": 4.57,
    "out:LCP/MSEK": -2.097,
    "out:ROI% old": 9.18,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180100,
    "out:DH kr savings": 159955,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 82.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 12.9,
    "out:Total CO2 (tons)": 28.69,
    "out:Klimatpaverkan": -113.75,
    "out:Initial Cost/MSEK": 6.1565,
    "out:Running cost/(Apt SEK y)": 33857,
    "out:Running Cost over RSP/MSEK": 4.777,
    "out:LCP/MSEK": -2.597,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192735,
    "out:DH kr savings": 139708,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 80.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 11.46,
    "out:Total CO2 (tons)": 25.49,
    "out:Klimatpaverkan": -116.95,
    "out:Initial Cost/MSEK": 6.212125,
    "out:Running cost/(Apt SEK y)": 32558,
    "out:Running Cost over RSP/MSEK": 4.594,
    "out:LCP/MSEK": -2.47,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200834,
    "out:DH kr savings": 147807,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 80.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 10.8,
    "out:Total CO2 (tons)": 24.03,
    "out:Klimatpaverkan": -118.41,
    "out:Initial Cost/MSEK": 6.270375,
    "out:Running cost/(Apt SEK y)": 31583,
    "out:Running Cost over RSP/MSEK": 4.457,
    "out:LCP/MSEK": -2.391,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169100,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206908,
    "out:DH kr savings": 153881,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 77.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 9.72,
    "out:Total CO2 (tons)": 21.62,
    "out:Klimatpaverkan": -120.82,
    "out:Initial Cost/MSEK": 6.326,
    "out:Running cost/(Apt SEK y)": 30609,
    "out:Running Cost over RSP/MSEK": 4.32,
    "out:LCP/MSEK": -2.309,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212982,
    "out:DH kr savings": 159955,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 92.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 61.65,
    "out:Total CO2 (tons)": 137.16,
    "out:Klimatpaverkan": -5.28,
    "out:Initial Cost/MSEK": 5.278375,
    "out:Running cost/(Apt SEK y)": 34846,
    "out:Running Cost over RSP/MSEK": 4.901,
    "out:LCP/MSEK": -1.843,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140535,
    "out:DH kr savings": 157930,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 89.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 60.57,
    "out:Total CO2 (tons)": 134.76,
    "out:Klimatpaverkan": -7.68,
    "out:Initial Cost/MSEK": 5.334,
    "out:Running cost/(Apt SEK y)": 33871,
    "out:Running Cost over RSP/MSEK": 4.764,
    "out:LCP/MSEK": -1.762,
    "out:ROI% old": 9.57,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146609,
    "out:DH kr savings": 164005,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 90.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 60.27,
    "out:Total CO2 (tons)": 134.1,
    "out:Klimatpaverkan": -8.34,
    "out:Initial Cost/MSEK": 5.392125,
    "out:Running cost/(Apt SEK y)": 33222,
    "out:Running Cost over RSP/MSEK": 4.672,
    "out:LCP/MSEK": -1.728,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150658,
    "out:DH kr savings": 168054,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 87.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 33.3,
    "out:Total CO2/m2": 58.83,
    "out:Total CO2 (tons)": 130.89,
    "out:Klimatpaverkan": -11.55,
    "out:Initial Cost/MSEK": 5.44775,
    "out:Running cost/(Apt SEK y)": 31922,
    "out:Running Cost over RSP/MSEK": 4.489,
    "out:LCP/MSEK": -1.601,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158757,
    "out:DH kr savings": 176153,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 81.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.45,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 47.98,
    "out:Total CO2 (tons)": 106.76,
    "out:Klimatpaverkan": -35.68,
    "out:Initial Cost/MSEK": 5.741125,
    "out:Running cost/(Apt SEK y)": 32760,
    "out:Running Cost over RSP/MSEK": 4.615,
    "out:LCP/MSEK": -2.02,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178075,
    "out:DH kr savings": 157930,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 78.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.45,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 46.9,
    "out:Total CO2 (tons)": 104.36,
    "out:Klimatpaverkan": -38.08,
    "out:Initial Cost/MSEK": 5.79675,
    "out:Running cost/(Apt SEK y)": 31786,
    "out:Running Cost over RSP/MSEK": 4.478,
    "out:LCP/MSEK": -1.939,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 494,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184149,
    "out:DH kr savings": 164005,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 79.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 46.61,
    "out:Total CO2 (tons)": 103.7,
    "out:Klimatpaverkan": -38.74,
    "out:Initial Cost/MSEK": 5.855,
    "out:Running cost/(Apt SEK y)": 31136,
    "out:Running Cost over RSP/MSEK": 4.387,
    "out:LCP/MSEK": -1.905,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188199,
    "out:DH kr savings": 168054,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 99.33,
    "out:Primary Energy": 76.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 45.17,
    "out:Total CO2 (tons)": 100.5,
    "out:Klimatpaverkan": -41.94,
    "out:Initial Cost/MSEK": 5.910625,
    "out:Running cost/(Apt SEK y)": 29836,
    "out:Running Cost over RSP/MSEK": 4.204,
    "out:LCP/MSEK": -1.778,
    "out:ROI% old": 9.99,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196298,
    "out:DH kr savings": 176153,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 79.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 9.66,
    "out:Total CO2 (tons)": 21.48,
    "out:Klimatpaverkan": -120.96,
    "out:Initial Cost/MSEK": 6.204,
    "out:Running cost/(Apt SEK y)": 30934,
    "out:Running Cost over RSP/MSEK": 4.365,
    "out:LCP/MSEK": -2.233,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210957,
    "out:DH kr savings": 157930,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 76.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 8.58,
    "out:Total CO2 (tons)": 19.08,
    "out:Klimatpaverkan": -123.36,
    "out:Initial Cost/MSEK": 6.259625,
    "out:Running cost/(Apt SEK y)": 29959,
    "out:Running Cost over RSP/MSEK": 4.228,
    "out:LCP/MSEK": -2.151,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217032,
    "out:DH kr savings": 164005,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 77.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 8.28,
    "out:Total CO2 (tons)": 18.42,
    "out:Klimatpaverkan": -124.02,
    "out:Initial Cost/MSEK": 6.317875,
    "out:Running cost/(Apt SEK y)": 29309,
    "out:Running Cost over RSP/MSEK": 4.137,
    "out:LCP/MSEK": -2.118,
    "out:ROI% old": 9.5,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221081,
    "out:DH kr savings": 168054,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132346,
    "out:% savings (space heating)": 53.59,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.78,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 74.5,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -43.39,
    "out:Total CO2/m2": 6.84,
    "out:Total CO2 (tons)": 15.22,
    "out:Klimatpaverkan": -127.22,
    "out:Initial Cost/MSEK": 6.3735,
    "out:Running cost/(Apt SEK y)": 28010,
    "out:Running Cost over RSP/MSEK": 3.954,
    "out:LCP/MSEK": -1.991,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 2225,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 383355.343313,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229180,
    "out:DH kr savings": 176153,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124075,
    "out:% savings (space heating)": 56.49,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.07,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 105.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 80.57,
    "out:Total CO2 (tons)": 179.27,
    "out:Klimatpaverkan": 36.83,
    "out:Initial Cost/MSEK": 5.12375,
    "out:Running cost/(Apt SEK y)": 42318,
    "out:Running Cost over RSP/MSEK": 5.954,
    "out:LCP/MSEK": -2.741,
    "out:ROI% old": 6.64,
    "out:Payback discounted": 18,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 37.2,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93966,
    "out:DH kr savings": 111361,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 102.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 78.77,
    "out:Total CO2 (tons)": 175.26,
    "out:Klimatpaverkan": 32.82,
    "out:Initial Cost/MSEK": 5.179375,
    "out:Running cost/(Apt SEK y)": 40694,
    "out:Running Cost over RSP/MSEK": 5.725,
    "out:LCP/MSEK": -2.568,
    "out:ROI% old": 7.2,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 42.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104089,
    "out:DH kr savings": 121485,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 102.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 36.17,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 78.47,
    "out:Total CO2 (tons)": 174.6,
    "out:Klimatpaverkan": 32.16,
    "out:Initial Cost/MSEK": 5.237625,
    "out:Running cost/(Apt SEK y)": 40044,
    "out:Running Cost over RSP/MSEK": 5.633,
    "out:LCP/MSEK": -2.535,
    "out:ROI% old": 7.37,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108139,
    "out:DH kr savings": 125534,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 99.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.17,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 77.03,
    "out:Total CO2 (tons)": 171.4,
    "out:Klimatpaverkan": 28.96,
    "out:Initial Cost/MSEK": 5.29325,
    "out:Running cost/(Apt SEK y)": 38744,
    "out:Running Cost over RSP/MSEK": 5.45,
    "out:LCP/MSEK": -2.407,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 369,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116238,
    "out:DH kr savings": 133633,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 94.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 66.91,
    "out:Total CO2 (tons)": 148.87,
    "out:Klimatpaverkan": 6.43,
    "out:Initial Cost/MSEK": 5.586625,
    "out:Running cost/(Apt SEK y)": 40232,
    "out:Running Cost over RSP/MSEK": 5.668,
    "out:LCP/MSEK": -2.918,
    "out:ROI% old": 6.82,
    "out:Payback discounted": 18,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 43.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 342,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131506,
    "out:DH kr savings": 111361,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 91.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 65.11,
    "out:Total CO2 (tons)": 144.87,
    "out:Klimatpaverkan": 2.43,
    "out:Initial Cost/MSEK": 5.64225,
    "out:Running cost/(Apt SEK y)": 38608,
    "out:Running Cost over RSP/MSEK": 5.439,
    "out:LCP/MSEK": -2.745,
    "out:ROI% old": 7.34,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141630,
    "out:DH kr savings": 121485,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 91.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.52,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 64.81,
    "out:Total CO2 (tons)": 144.2,
    "out:Klimatpaverkan": 1.76,
    "out:Initial Cost/MSEK": 5.700375,
    "out:Running cost/(Apt SEK y)": 37958,
    "out:Running Cost over RSP/MSEK": 5.348,
    "out:LCP/MSEK": -2.712,
    "out:ROI% old": 7.49,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 383,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145679,
    "out:DH kr savings": 125534,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 88.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.52,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 63.37,
    "out:Total CO2 (tons)": 141,
    "out:Klimatpaverkan": -1.44,
    "out:Initial Cost/MSEK": 5.756,
    "out:Running cost/(Apt SEK y)": 36659,
    "out:Running Cost over RSP/MSEK": 5.165,
    "out:LCP/MSEK": -2.584,
    "out:ROI% old": 7.87,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 56.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153778,
    "out:DH kr savings": 133633,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 92.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.45,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": 28.58,
    "out:Total CO2 (tons)": 63.59,
    "out:Klimatpaverkan": -78.85,
    "out:Initial Cost/MSEK": 6.049375,
    "out:Running cost/(Apt SEK y)": 38406,
    "out:Running Cost over RSP/MSEK": 5.418,
    "out:LCP/MSEK": -3.131,
    "out:ROI% old": 6.89,
    "out:Payback discounted": 18,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 44.6,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 6,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122375,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164388,
    "out:DH kr savings": 111361,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 89.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.45,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 26.78,
    "out:Total CO2 (tons)": 59.59,
    "out:Klimatpaverkan": -82.85,
    "out:Initial Cost/MSEK": 6.105,
    "out:Running cost/(Apt SEK y)": 36781,
    "out:Running Cost over RSP/MSEK": 5.189,
    "out:LCP/MSEK": -2.958,
    "out:ROI% old": 7.36,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 133500,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174512,
    "out:DH kr savings": 121485,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.87,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 26.48,
    "out:Total CO2 (tons)": 58.92,
    "out:Klimatpaverkan": -83.52,
    "out:Initial Cost/MSEK": 6.16325,
    "out:Running cost/(Apt SEK y)": 36131,
    "out:Running Cost over RSP/MSEK": 5.098,
    "out:LCP/MSEK": -2.925,
    "out:ROI% old": 7.51,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 137950,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178561,
    "out:DH kr savings": 125534,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 86.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.87,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 25.04,
    "out:Total CO2 (tons)": 55.72,
    "out:Klimatpaverkan": -86.72,
    "out:Initial Cost/MSEK": 6.218875,
    "out:Running cost/(Apt SEK y)": 34832,
    "out:Running Cost over RSP/MSEK": 4.915,
    "out:LCP/MSEK": -2.797,
    "out:ROI% old": 7.86,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146850,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186660,
    "out:DH kr savings": 133633,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 102.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 77.33,
    "out:Total CO2 (tons)": 172.06,
    "out:Klimatpaverkan": 29.62,
    "out:Initial Cost/MSEK": 5.17125,
    "out:Running cost/(Apt SEK y)": 39394,
    "out:Running Cost over RSP/MSEK": 5.542,
    "out:LCP/MSEK": -2.377,
    "out:ROI% old": 7.72,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112188,
    "out:DH kr savings": 129584,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 75.53,
    "out:Total CO2 (tons)": 168.05,
    "out:Klimatpaverkan": 25.61,
    "out:Initial Cost/MSEK": 5.226875,
    "out:Running cost/(Apt SEK y)": 37770,
    "out:Running Cost over RSP/MSEK": 5.313,
    "out:LCP/MSEK": -2.204,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122312,
    "out:DH kr savings": 139708,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 99.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.17,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 75.95,
    "out:Total CO2 (tons)": 168.99,
    "out:Klimatpaverkan": 26.55,
    "out:Initial Cost/MSEK": 5.285125,
    "out:Running cost/(Apt SEK y)": 37770,
    "out:Running Cost over RSP/MSEK": 5.313,
    "out:LCP/MSEK": -2.262,
    "out:ROI% old": 8.17,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122312,
    "out:DH kr savings": 139708,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 96.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.17,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 74.15,
    "out:Total CO2 (tons)": 164.99,
    "out:Klimatpaverkan": 22.55,
    "out:Initial Cost/MSEK": 5.34075,
    "out:Running cost/(Apt SEK y)": 36145,
    "out:Running Cost over RSP/MSEK": 5.084,
    "out:LCP/MSEK": -2.089,
    "out:ROI% old": 8.7,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 59.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 132436,
    "out:DH kr savings": 149831,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 90.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 63.67,
    "out:Total CO2 (tons)": 141.66,
    "out:Klimatpaverkan": -0.78,
    "out:Initial Cost/MSEK": 5.634125,
    "out:Running cost/(Apt SEK y)": 37308,
    "out:Running Cost over RSP/MSEK": 5.256,
    "out:LCP/MSEK": -2.554,
    "out:ROI% old": 7.81,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 54.1,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149729,
    "out:DH kr savings": 129584,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 61.87,
    "out:Total CO2 (tons)": 137.66,
    "out:Klimatpaverkan": -4.78,
    "out:Initial Cost/MSEK": 5.68975,
    "out:Running cost/(Apt SEK y)": 35684,
    "out:Running Cost over RSP/MSEK": 5.027,
    "out:LCP/MSEK": -2.381,
    "out:ROI% old": 8.31,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159852,
    "out:DH kr savings": 139708,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 88.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.52,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 62.29,
    "out:Total CO2 (tons)": 138.6,
    "out:Klimatpaverkan": -3.84,
    "out:Initial Cost/MSEK": 5.747875,
    "out:Running cost/(Apt SEK y)": 35684,
    "out:Running Cost over RSP/MSEK": 5.027,
    "out:LCP/MSEK": -2.439,
    "out:ROI% old": 8.22,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159852,
    "out:DH kr savings": 139708,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 85.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.52,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 60.49,
    "out:Total CO2 (tons)": 134.59,
    "out:Klimatpaverkan": -7.85,
    "out:Initial Cost/MSEK": 5.8035,
    "out:Running cost/(Apt SEK y)": 34060,
    "out:Running Cost over RSP/MSEK": 4.799,
    "out:LCP/MSEK": -2.266,
    "out:ROI% old": 8.71,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169976,
    "out:DH kr savings": 149831,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 88.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.45,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 25.34,
    "out:Total CO2 (tons)": 56.38,
    "out:Klimatpaverkan": -86.06,
    "out:Initial Cost/MSEK": 6.096875,
    "out:Running cost/(Apt SEK y)": 35482,
    "out:Running Cost over RSP/MSEK": 5.006,
    "out:LCP/MSEK": -2.767,
    "out:ROI% old": 7.8,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 55.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 427,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 142400,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182611,
    "out:DH kr savings": 129584,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 85.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.45,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 23.54,
    "out:Total CO2 (tons)": 52.38,
    "out:Klimatpaverkan": -90.06,
    "out:Initial Cost/MSEK": 6.1525,
    "out:Running cost/(Apt SEK y)": 33857,
    "out:Running Cost over RSP/MSEK": 4.777,
    "out:LCP/MSEK": -2.593,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192735,
    "out:DH kr savings": 139708,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 73.02,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 86.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.87,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 23.96,
    "out:Total CO2 (tons)": 53.32,
    "out:Klimatpaverkan": -89.12,
    "out:Initial Cost/MSEK": 6.21075,
    "out:Running cost/(Apt SEK y)": 33857,
    "out:Running Cost over RSP/MSEK": 4.777,
    "out:LCP/MSEK": -2.652,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192735,
    "out:DH kr savings": 139708,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 162478,
    "out:% savings (space heating)": 43.02,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 101.32,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 83.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.87,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 22.16,
    "out:Total CO2 (tons)": 49.31,
    "out:Klimatpaverkan": -93.13,
    "out:Initial Cost/MSEK": 6.266375,
    "out:Running cost/(Apt SEK y)": 32233,
    "out:Running Cost over RSP/MSEK": 4.548,
    "out:LCP/MSEK": -2.479,
    "out:ROI% old": 8.64,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.5,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164650,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202858,
    "out:DH kr savings": 149831,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 152837,
    "out:% savings (space heating)": 46.4,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 96.99,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 96.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 78.43,
    "out:Total CO2 (tons)": 174.5,
    "out:Klimatpaverkan": 32.06,
    "out:Initial Cost/MSEK": 5.574625,
    "out:Running cost/(Apt SEK y)": 37770,
    "out:Running Cost over RSP/MSEK": 5.313,
    "out:LCP/MSEK": -2.551,
    "out:ROI% old": 7.75,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122312,
    "out:DH kr savings": 139708,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 93.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 76.99,
    "out:Total CO2 (tons)": 171.3,
    "out:Klimatpaverkan": 28.86,
    "out:Initial Cost/MSEK": 5.63025,
    "out:Running cost/(Apt SEK y)": 36470,
    "out:Running Cost over RSP/MSEK": 5.13,
    "out:LCP/MSEK": -2.424,
    "out:ROI% old": 8.14,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130411,
    "out:DH kr savings": 147807,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 93.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.07,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 76.69,
    "out:Total CO2 (tons)": 170.64,
    "out:Klimatpaverkan": 28.2,
    "out:Initial Cost/MSEK": 5.6885,
    "out:Running cost/(Apt SEK y)": 35820,
    "out:Running Cost over RSP/MSEK": 5.039,
    "out:LCP/MSEK": -2.391,
    "out:ROI% old": 8.28,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134460,
    "out:DH kr savings": 151856,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 90.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.07,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 75.25,
    "out:Total CO2 (tons)": 167.43,
    "out:Klimatpaverkan": 24.99,
    "out:Initial Cost/MSEK": 5.744125,
    "out:Running cost/(Apt SEK y)": 34521,
    "out:Running Cost over RSP/MSEK": 4.855,
    "out:LCP/MSEK": -2.263,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142559,
    "out:DH kr savings": 159955,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 84.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 64.77,
    "out:Total CO2 (tons)": 144.11,
    "out:Klimatpaverkan": 1.67,
    "out:Initial Cost/MSEK": 6.037375,
    "out:Running cost/(Apt SEK y)": 35684,
    "out:Running Cost over RSP/MSEK": 5.027,
    "out:LCP/MSEK": -2.728,
    "out:ROI% old": 7.83,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 60.7,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159852,
    "out:DH kr savings": 139708,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 82.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 63.33,
    "out:Total CO2 (tons)": 140.9,
    "out:Klimatpaverkan": -1.54,
    "out:Initial Cost/MSEK": 6.093,
    "out:Running cost/(Apt SEK y)": 34385,
    "out:Running Cost over RSP/MSEK": 4.844,
    "out:LCP/MSEK": -2.601,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167951,
    "out:DH kr savings": 147807,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 82.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51.42,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 63.03,
    "out:Total CO2 (tons)": 140.24,
    "out:Klimatpaverkan": -2.2,
    "out:Initial Cost/MSEK": 6.15125,
    "out:Running cost/(Apt SEK y)": 33735,
    "out:Running Cost over RSP/MSEK": 4.753,
    "out:LCP/MSEK": -2.568,
    "out:ROI% old": 8.32,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172001,
    "out:DH kr savings": 151856,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 79.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51.42,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 61.59,
    "out:Total CO2 (tons)": 137.04,
    "out:Klimatpaverkan": -5.4,
    "out:Initial Cost/MSEK": 6.206875,
    "out:Running cost/(Apt SEK y)": 32435,
    "out:Running Cost over RSP/MSEK": 4.57,
    "out:LCP/MSEK": -2.44,
    "out:ROI% old": 8.67,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180100,
    "out:DH kr savings": 159955,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 82.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.35,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 26.44,
    "out:Total CO2 (tons)": 58.83,
    "out:Klimatpaverkan": -83.61,
    "out:Initial Cost/MSEK": 6.50025,
    "out:Running cost/(Apt SEK y)": 33857,
    "out:Running Cost over RSP/MSEK": 4.777,
    "out:LCP/MSEK": -2.941,
    "out:ROI% old": 7.82,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 62.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153525,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192735,
    "out:DH kr savings": 139708,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 80.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.35,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 25,
    "out:Total CO2 (tons)": 55.62,
    "out:Klimatpaverkan": -86.82,
    "out:Initial Cost/MSEK": 6.555875,
    "out:Running cost/(Apt SEK y)": 32558,
    "out:Running Cost over RSP/MSEK": 4.594,
    "out:LCP/MSEK": -2.814,
    "out:ROI% old": 8.15,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200834,
    "out:DH kr savings": 147807,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.77,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 24.7,
    "out:Total CO2 (tons)": 54.96,
    "out:Klimatpaverkan": -87.48,
    "out:Initial Cost/MSEK": 6.614125,
    "out:Running cost/(Apt SEK y)": 31908,
    "out:Running Cost over RSP/MSEK": 4.503,
    "out:LCP/MSEK": -2.78,
    "out:ROI% old": 8.28,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166875,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204883,
    "out:DH kr savings": 151856,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 77.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.77,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 23.26,
    "out:Total CO2 (tons)": 51.76,
    "out:Klimatpaverkan": -90.68,
    "out:Initial Cost/MSEK": 6.66975,
    "out:Running cost/(Apt SEK y)": 30609,
    "out:Running Cost over RSP/MSEK": 4.32,
    "out:LCP/MSEK": -2.653,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175775,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212982,
    "out:DH kr savings": 159955,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 92.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 75.19,
    "out:Total CO2 (tons)": 167.29,
    "out:Klimatpaverkan": 24.85,
    "out:Initial Cost/MSEK": 5.622125,
    "out:Running cost/(Apt SEK y)": 34846,
    "out:Running Cost over RSP/MSEK": 4.901,
    "out:LCP/MSEK": -2.187,
    "out:ROI% old": 8.73,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64.9,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140535,
    "out:DH kr savings": 157930,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 89.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 74.11,
    "out:Total CO2 (tons)": 164.89,
    "out:Klimatpaverkan": 22.45,
    "out:Initial Cost/MSEK": 5.67775,
    "out:Running cost/(Apt SEK y)": 33871,
    "out:Running Cost over RSP/MSEK": 4.764,
    "out:LCP/MSEK": -2.105,
    "out:ROI% old": 8.99,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 69.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146609,
    "out:DH kr savings": 164005,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 90.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.07,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 73.81,
    "out:Total CO2 (tons)": 164.23,
    "out:Klimatpaverkan": 21.79,
    "out:Initial Cost/MSEK": 5.736,
    "out:Running cost/(Apt SEK y)": 33222,
    "out:Running Cost over RSP/MSEK": 4.672,
    "out:LCP/MSEK": -2.072,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150658,
    "out:DH kr savings": 168054,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 87.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.07,
    "out:CO2 Operational/m2": 33.3,
    "out:Total CO2/m2": 72.37,
    "out:Total CO2 (tons)": 161.03,
    "out:Klimatpaverkan": 18.59,
    "out:Initial Cost/MSEK": 5.791625,
    "out:Running cost/(Apt SEK y)": 31922,
    "out:Running Cost over RSP/MSEK": 4.489,
    "out:LCP/MSEK": -1.945,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158757,
    "out:DH kr savings": 176153,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 81.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 61.53,
    "out:Total CO2 (tons)": 136.9,
    "out:Klimatpaverkan": -5.54,
    "out:Initial Cost/MSEK": 6.084875,
    "out:Running cost/(Apt SEK y)": 32760,
    "out:Running Cost over RSP/MSEK": 4.615,
    "out:LCP/MSEK": -2.364,
    "out:ROI% old": 8.74,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178075,
    "out:DH kr savings": 157930,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 78.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 134.49,
    "out:Klimatpaverkan": -7.95,
    "out:Initial Cost/MSEK": 6.1405,
    "out:Running cost/(Apt SEK y)": 31786,
    "out:Running Cost over RSP/MSEK": 4.478,
    "out:LCP/MSEK": -2.282,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 494,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184149,
    "out:DH kr savings": 164005,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 79.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51.42,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 60.15,
    "out:Total CO2 (tons)": 133.83,
    "out:Klimatpaverkan": -8.61,
    "out:Initial Cost/MSEK": 6.19875,
    "out:Running cost/(Apt SEK y)": 31136,
    "out:Running Cost over RSP/MSEK": 4.387,
    "out:LCP/MSEK": -2.249,
    "out:ROI% old": 9.1,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188199,
    "out:DH kr savings": 168054,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 99.33,
    "out:Primary Energy": 76.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 51.42,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 58.71,
    "out:Total CO2 (tons)": 130.63,
    "out:Klimatpaverkan": -11.81,
    "out:Initial Cost/MSEK": 6.254375,
    "out:Running cost/(Apt SEK y)": 29836,
    "out:Running Cost over RSP/MSEK": 4.204,
    "out:LCP/MSEK": -2.122,
    "out:ROI% old": 9.44,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196298,
    "out:DH kr savings": 176153,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 79.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.35,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 23.2,
    "out:Total CO2 (tons)": 51.62,
    "out:Klimatpaverkan": -90.82,
    "out:Initial Cost/MSEK": 6.54775,
    "out:Running cost/(Apt SEK y)": 30934,
    "out:Running Cost over RSP/MSEK": 4.365,
    "out:LCP/MSEK": -2.577,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173550,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210957,
    "out:DH kr savings": 157930,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 76.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.35,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 22.12,
    "out:Total CO2 (tons)": 49.21,
    "out:Klimatpaverkan": -93.23,
    "out:Initial Cost/MSEK": 6.603375,
    "out:Running cost/(Apt SEK y)": 29959,
    "out:Running Cost over RSP/MSEK": 4.228,
    "out:LCP/MSEK": -2.495,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180225,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217032,
    "out:DH kr savings": 164005,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 77.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.77,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 21.82,
    "out:Total CO2 (tons)": 48.55,
    "out:Klimatpaverkan": -93.89,
    "out:Initial Cost/MSEK": 6.661625,
    "out:Running cost/(Apt SEK y)": 29309,
    "out:Running Cost over RSP/MSEK": 4.137,
    "out:LCP/MSEK": -2.462,
    "out:ROI% old": 9.01,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221081,
    "out:DH kr savings": 168054,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 132519,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 87.86,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.84,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 74.55,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 63.77,
    "out:CO2 Operational/m2": -43.39,
    "out:Total CO2/m2": 20.38,
    "out:Total CO2 (tons)": 45.35,
    "out:Klimatpaverkan": -97.09,
    "out:Initial Cost/MSEK": 6.71725,
    "out:Running cost/(Apt SEK y)": 28010,
    "out:Running Cost over RSP/MSEK": 3.954,
    "out:LCP/MSEK": -2.334,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 2225,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229180,
    "out:DH kr savings": 176153,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 124240,
    "out:% savings (space heating)": 56.43,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 84.14,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 93.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.87,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 84.22,
    "out:Total CO2 (tons)": 187.38,
    "out:Klimatpaverkan": 44.94,
    "out:Initial Cost/MSEK": 6.6785,
    "out:Running cost/(Apt SEK y)": 36470,
    "out:Running Cost over RSP/MSEK": 5.13,
    "out:LCP/MSEK": -3.472,
    "out:ROI% old": 6.86,
    "out:Payback discounted": 18,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 58,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 409,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130411,
    "out:DH kr savings": 147807,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 90.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.87,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 82.78,
    "out:Total CO2 (tons)": 184.18,
    "out:Klimatpaverkan": 41.74,
    "out:Initial Cost/MSEK": 6.734125,
    "out:Running cost/(Apt SEK y)": 35171,
    "out:Running Cost over RSP/MSEK": 4.947,
    "out:LCP/MSEK": -3.345,
    "out:ROI% old": 7.19,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 63.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 433,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138510,
    "out:DH kr savings": 155906,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 90.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.29,
    "out:CO2 Operational/m2": 35.82,
    "out:Total CO2/m2": 82.12,
    "out:Total CO2 (tons)": 182.71,
    "out:Klimatpaverkan": 40.27,
    "out:Initial Cost/MSEK": 6.792375,
    "out:Running cost/(Apt SEK y)": 34196,
    "out:Running Cost over RSP/MSEK": 4.81,
    "out:LCP/MSEK": -3.266,
    "out:ROI% old": 7.42,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 178000,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144584,
    "out:DH kr savings": 161980,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 88.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.29,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 81.04,
    "out:Total CO2 (tons)": 180.31,
    "out:Klimatpaverkan": 37.87,
    "out:Initial Cost/MSEK": 6.848,
    "out:Running cost/(Apt SEK y)": 33222,
    "out:Running Cost over RSP/MSEK": 4.672,
    "out:LCP/MSEK": -3.184,
    "out:ROI% old": 7.64,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 150658,
    "out:DH kr savings": 168054,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 82.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.22,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 70.55,
    "out:Total CO2 (tons)": 156.98,
    "out:Klimatpaverkan": 14.54,
    "out:Initial Cost/MSEK": 7.141375,
    "out:Running cost/(Apt SEK y)": 34385,
    "out:Running Cost over RSP/MSEK": 4.844,
    "out:LCP/MSEK": -3.649,
    "out:ROI% old": 6.99,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 66.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167951,
    "out:DH kr savings": 147807,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 79.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.22,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 69.11,
    "out:Total CO2 (tons)": 153.78,
    "out:Klimatpaverkan": 11.34,
    "out:Initial Cost/MSEK": 7.197,
    "out:Running cost/(Apt SEK y)": 33085,
    "out:Running Cost over RSP/MSEK": 4.661,
    "out:LCP/MSEK": -3.522,
    "out:ROI% old": 7.29,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 72.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176050,
    "out:DH kr savings": 155906,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 106.33,
    "out:Primary Energy": 79.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.65,
    "out:CO2 Operational/m2": 9.81,
    "out:Total CO2/m2": 68.46,
    "out:Total CO2 (tons)": 152.32,
    "out:Klimatpaverkan": 9.88,
    "out:Initial Cost/MSEK": 7.255125,
    "out:Running cost/(Apt SEK y)": 32110,
    "out:Running Cost over RSP/MSEK": 4.524,
    "out:LCP/MSEK": -3.443,
    "out:ROI% old": 7.51,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 77.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178000,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182125,
    "out:DH kr savings": 161980,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 76.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.65,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 67.38,
    "out:Total CO2 (tons)": 149.91,
    "out:Klimatpaverkan": 7.47,
    "out:Initial Cost/MSEK": 7.31075,
    "out:Running cost/(Apt SEK y)": 31136,
    "out:Running Cost over RSP/MSEK": 4.387,
    "out:LCP/MSEK": -3.361,
    "out:ROI% old": 7.72,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188199,
    "out:DH kr savings": 168054,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 79.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 70.58,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 32.23,
    "out:Total CO2 (tons)": 71.7,
    "out:Klimatpaverkan": -70.74,
    "out:Initial Cost/MSEK": 7.604125,
    "out:Running cost/(Apt SEK y)": 32558,
    "out:Running Cost over RSP/MSEK": 4.594,
    "out:LCP/MSEK": -3.862,
    "out:ROI% old": 7.03,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 6,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 162425,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200834,
    "out:DH kr savings": 147807,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 77.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 70.58,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 30.79,
    "out:Total CO2 (tons)": 68.5,
    "out:Klimatpaverkan": -73.94,
    "out:Initial Cost/MSEK": 7.65975,
    "out:Running cost/(Apt SEK y)": 31258,
    "out:Running Cost over RSP/MSEK": 4.411,
    "out:LCP/MSEK": -3.735,
    "out:ROI% old": 7.32,
    "out:Payback discounted": 17,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 74.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 171325,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208933,
    "out:DH kr savings": 155906,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 96787,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 105.09,
    "out:Primary Energy": 77.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 71,
    "out:CO2 Operational/m2": -40.87,
    "out:Total CO2/m2": 30.13,
    "out:Total CO2 (tons)": 67.04,
    "out:Klimatpaverkan": -75.4,
    "out:Initial Cost/MSEK": 7.718,
    "out:Running cost/(Apt SEK y)": 30284,
    "out:Running Cost over RSP/MSEK": 4.274,
    "out:LCP/MSEK": -3.656,
    "out:ROI% old": 7.52,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178000,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215007,
    "out:DH kr savings": 161980,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 74.76,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 71,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 29.05,
    "out:Total CO2 (tons)": 64.64,
    "out:Klimatpaverkan": -77.8,
    "out:Initial Cost/MSEK": 7.773625,
    "out:Running cost/(Apt SEK y)": 29309,
    "out:Running Cost over RSP/MSEK": 4.137,
    "out:LCP/MSEK": -3.574,
    "out:ROI% old": 7.72,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 184675,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221081,
    "out:DH kr savings": 168054,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 82269,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 110.5,
    "out:Primary Energy": 89.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.87,
    "out:CO2 Operational/m2": 35.1,
    "out:Total CO2/m2": 80.98,
    "out:Total CO2 (tons)": 180.17,
    "out:Klimatpaverkan": 37.73,
    "out:Initial Cost/MSEK": 6.726,
    "out:Running cost/(Apt SEK y)": 33546,
    "out:Running Cost over RSP/MSEK": 4.718,
    "out:LCP/MSEK": -3.108,
    "out:ROI% old": 7.68,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 182450,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148634,
    "out:DH kr savings": 166029,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 87.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.87,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 79.9,
    "out:Total CO2 (tons)": 177.77,
    "out:Klimatpaverkan": 35.33,
    "out:Initial Cost/MSEK": 6.781625,
    "out:Running cost/(Apt SEK y)": 32572,
    "out:Running Cost over RSP/MSEK": 4.581,
    "out:LCP/MSEK": -3.026,
    "out:ROI% old": 7.91,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 75.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 189125,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154708,
    "out:DH kr savings": 172104,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 87.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.29,
    "out:CO2 Operational/m2": 33.3,
    "out:Total CO2/m2": 79.6,
    "out:Total CO2 (tons)": 177.11,
    "out:Klimatpaverkan": 34.67,
    "out:Initial Cost/MSEK": 6.839875,
    "out:Running cost/(Apt SEK y)": 31922,
    "out:Running Cost over RSP/MSEK": 4.489,
    "out:LCP/MSEK": -2.993,
    "out:ROI% old": 8.03,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 79,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158757,
    "out:DH kr savings": 176153,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 101.5,
    "out:Total Energy Use Post PV": 101.5,
    "out:Primary Energy": 85.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.29,
    "out:CO2 Operational/m2": 31.86,
    "out:Total CO2/m2": 78.16,
    "out:Total CO2 (tons)": 173.9,
    "out:Klimatpaverkan": 31.46,
    "out:Initial Cost/MSEK": 6.8955,
    "out:Running cost/(Apt SEK y)": 30622,
    "out:Running Cost over RSP/MSEK": 4.306,
    "out:LCP/MSEK": -2.865,
    "out:ROI% old": 8.35,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 202475,
    "out:EL kWh savings": -10233,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166856,
    "out:DH kr savings": 184252,
    "out:El kr savings": -17396,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 104.33,
    "out:Primary Energy": 78.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.22,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 67.31,
    "out:Total CO2 (tons)": 149.78,
    "out:Klimatpaverkan": 7.34,
    "out:Initial Cost/MSEK": 7.188875,
    "out:Running cost/(Apt SEK y)": 31461,
    "out:Running Cost over RSP/MSEK": 4.432,
    "out:LCP/MSEK": -3.285,
    "out:ROI% old": 7.76,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 80.8,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182450,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186174,
    "out:DH kr savings": 166029,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 76.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.22,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 66.23,
    "out:Total CO2 (tons)": 147.37,
    "out:Klimatpaverkan": 4.93,
    "out:Initial Cost/MSEK": 7.2445,
    "out:Running cost/(Apt SEK y)": 30486,
    "out:Running Cost over RSP/MSEK": 4.295,
    "out:LCP/MSEK": -3.203,
    "out:ROI% old": 7.97,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 86.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 189125,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192248,
    "out:DH kr savings": 172104,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 99.33,
    "out:Primary Energy": 76.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 58.65,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 65.94,
    "out:Total CO2 (tons)": 146.71,
    "out:Klimatpaverkan": 4.27,
    "out:Initial Cost/MSEK": 7.302625,
    "out:Running cost/(Apt SEK y)": 29836,
    "out:Running Cost over RSP/MSEK": 4.204,
    "out:LCP/MSEK": -3.17,
    "out:ROI% old": 8.08,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 89.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196298,
    "out:DH kr savings": 176153,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 101.5,
    "out:Total Energy Use Post PV": 95.33,
    "out:Primary Energy": 74.02,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 58.65,
    "out:CO2 Operational/m2": 5.85,
    "out:Total CO2/m2": 64.5,
    "out:Total CO2 (tons)": 143.51,
    "out:Klimatpaverkan": 1.07,
    "out:Initial Cost/MSEK": 7.35825,
    "out:Running cost/(Apt SEK y)": 28537,
    "out:Running Cost over RSP/MSEK": 4.021,
    "out:LCP/MSEK": -3.042,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 97.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 26.505303,
    "out:PV (m2 panels)": 139,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 24869.14,
    "out:PV (kWh sold)": 11146.17,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 13722.97,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 202475,
    "out:EL kWh savings": 3490,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204397,
    "out:DH kr savings": 184252,
    "out:El kr savings": 5933,
    "out:El kr sold": 14211,
    "out:El kr saved": 23329,
    "out:El kr return": 37540,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 103.09,
    "out:Primary Energy": 76.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 70.58,
    "out:CO2 Operational/m2": -41.59,
    "out:Total CO2/m2": 28.99,
    "out:Total CO2 (tons)": 64.5,
    "out:Klimatpaverkan": -77.94,
    "out:Initial Cost/MSEK": 7.651625,
    "out:Running cost/(Apt SEK y)": 29634,
    "out:Running Cost over RSP/MSEK": 4.182,
    "out:LCP/MSEK": -3.498,
    "out:ROI% old": 7.76,
    "out:Payback discounted": 16,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 82.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 533,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182450,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219056,
    "out:DH kr savings": 166029,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 73.88,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 70.58,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": 27.91,
    "out:Total CO2 (tons)": 62.09,
    "out:Klimatpaverkan": -80.35,
    "out:Initial Cost/MSEK": 7.70725,
    "out:Running cost/(Apt SEK y)": 28659,
    "out:Running Cost over RSP/MSEK": 4.045,
    "out:LCP/MSEK": -3.416,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 88,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 550,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 189125,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225131,
    "out:DH kr savings": 172104,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 77430,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.42,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 74.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 71,
    "out:CO2 Operational/m2": -43.39,
    "out:Total CO2/m2": 27.61,
    "out:Total CO2 (tons)": 61.43,
    "out:Klimatpaverkan": -81.01,
    "out:Initial Cost/MSEK": 7.7655,
    "out:Running cost/(Apt SEK y)": 28010,
    "out:Running Cost over RSP/MSEK": 3.954,
    "out:LCP/MSEK": -3.383,
    "out:ROI% old": 8.06,
    "out:Payback discounted": 15,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 91.8,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 193575,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229180,
    "out:DH kr savings": 176153,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 123304,
    "out:% savings (space heating)": 56.76,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 83.72,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 51.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 101.5,
    "out:Total Energy Use Post PV": 94.09,
    "out:Primary Energy": 71.78,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 71,
    "out:CO2 Operational/m2": -44.83,
    "out:Total CO2/m2": 26.17,
    "out:Total CO2 (tons)": 58.23,
    "out:Klimatpaverkan": -84.21,
    "out:Initial Cost/MSEK": 7.821125,
    "out:Running cost/(Apt SEK y)": 26710,
    "out:Running Cost over RSP/MSEK": 3.77,
    "out:LCP/MSEK": -3.255,
    "out:ROI% old": 8.34,
    "out:Payback discounted": 14,
    "out:Atemp": 2225,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 556.249796,
    "out:Facade surface/m2": 943.51318,
    "out:Window surface/m2": 243.040127,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 57999.476428,
    "out:EAHP (pipework)": 57822.166242,
    "out:EAHP (natural ventilation grills)": 85691.25,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 53.010606,
    "out:PV (m2 panels)": 278,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 49738.28,
    "out:PV (kWh sold)": 33252.62,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 16485.66,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 854343.526537,
    "out:FTX (Diffusers)": 135078.125,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 152671.901418,
    "out:FTX (kWh heat recovery efficiency)": 28.301242,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 18,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 222300,
    "out:DH (pipework)": 247500,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 469627.796518,
    "out:FTX (Facade)": 2578800,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 202475,
    "out:EL kWh savings": 6253,
    "out:Sewage heat recovery cost": 113840,
    "out:Balancing hydronic system cost": 55624.98,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237279,
    "out:DH kr savings": 184252,
    "out:El kr savings": 10630,
    "out:El kr sold": 42397,
    "out:El kr saved": 28026,
    "out:El kr return": 70423,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 35377,
    "out:Total_Space Heating (DH)": 115444,
    "out:% savings (space heating)": 59.52,
    "out:Total_Water Heating (DH)": 65815,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 5,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 584005.566243,
    "out:Eupp": 80.19,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  }
]