export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 186.9,
    "out:Primary Energy": 135.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 63.66,
    "out:Total CO2/m2": 63.66,
    "out:Total CO2 (tons)": 199.69,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 51539,
    "out:Running Cost over RSP/MSEK": 11.689,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 2,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 187,
    "out:Energy use kWh/m2": 187,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 181.9,
    "out:Primary Energy": 131.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 61.86,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 194.04,
    "out:Klimatpaverkan": -5.65,
    "out:Initial Cost/MSEK": 0.078425,
    "out:Running cost/(Apt SEK y)": 50117,
    "out:Running Cost over RSP/MSEK": 11.366,
    "out:LCP/MSEK": 0.244,
    "out:ROI% old": 58.77,
    "out:Payback discounted": 2,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 53,
    "out:Return/kSEK/y": 41,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 15685,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 14273,
    "out:DH kr savings": 14273,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 180.9,
    "out:Primary Energy": 133,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 61.5,
    "out:Total CO2/m2": 61.92,
    "out:Total CO2 (tons)": 194.23,
    "out:Klimatpaverkan": -5.46,
    "out:Initial Cost/MSEK": 0.159376,
    "out:Running cost/(Apt SEK y)": 49833,
    "out:Running Cost over RSP/MSEK": 11.302,
    "out:LCP/MSEK": 0.228,
    "out:ROI% old": 34.7,
    "out:Payback discounted": 4,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 31,
    "out:Return/kSEK/y": 49,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 18822,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 17128,
    "out:DH kr savings": 17128,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 174.9,
    "out:Primary Energy": 129.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 59.34,
    "out:Total CO2/m2": 59.76,
    "out:Total CO2 (tons)": 187.45,
    "out:Klimatpaverkan": -12.24,
    "out:Initial Cost/MSEK": 0.237801,
    "out:Running cost/(Apt SEK y)": 48127,
    "out:Running Cost over RSP/MSEK": 10.914,
    "out:LCP/MSEK": 0.536,
    "out:ROI% old": 46.51,
    "out:Payback discounted": 3,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 6.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 42,
    "out:Return/kSEK/y": 99,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 37644,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 34256,
    "out:DH kr savings": 34256,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 181.73,
    "out:Primary Energy": 126.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 45.01,
    "out:Total CO2 (tons)": 141.21,
    "out:Klimatpaverkan": -58.48,
    "out:Initial Cost/MSEK": 0.65253,
    "out:Running cost/(Apt SEK y)": 49760,
    "out:Running Cost over RSP/MSEK": 11.296,
    "out:LCP/MSEK": -0.26,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 52,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51600,
    "out:DH kr savings": 0,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 176.73,
    "out:Primary Energy": 122.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 43.21,
    "out:Total CO2 (tons)": 135.56,
    "out:Klimatpaverkan": -64.13,
    "out:Initial Cost/MSEK": 0.730955,
    "out:Running cost/(Apt SEK y)": 48338,
    "out:Running Cost over RSP/MSEK": 10.973,
    "out:LCP/MSEK": -0.016,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 5.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 93,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 15685,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 65873,
    "out:DH kr savings": 14273,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 175.73,
    "out:Primary Energy": 123.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.77,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 43.27,
    "out:Total CO2 (tons)": 135.75,
    "out:Klimatpaverkan": -63.94,
    "out:Initial Cost/MSEK": 0.811906,
    "out:Running cost/(Apt SEK y)": 48054,
    "out:Running Cost over RSP/MSEK": 10.909,
    "out:LCP/MSEK": -0.032,
    "out:ROI% old": 13.72,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 101,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 18822,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68728,
    "out:DH kr savings": 17128,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 169.73,
    "out:Primary Energy": 119.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.77,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 41.11,
    "out:Total CO2 (tons)": 128.97,
    "out:Klimatpaverkan": -70.72,
    "out:Initial Cost/MSEK": 0.890331,
    "out:Running cost/(Apt SEK y)": 46348,
    "out:Running Cost over RSP/MSEK": 10.522,
    "out:LCP/MSEK": 0.277,
    "out:ROI% old": 18.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 37644,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85856,
    "out:DH kr savings": 34256,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 180.88,
    "out:Primary Energy": 124.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -20.02,
    "out:Total CO2/m2": 4.69,
    "out:Total CO2 (tons)": 14.71,
    "out:Klimatpaverkan": -184.98,
    "out:Initial Cost/MSEK": 1.305,
    "out:Running cost/(Apt SEK y)": 48179,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": -0.564,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 97,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 97437,
    "out:DH kr savings": 0,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 175.88,
    "out:Primary Energy": 120.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -21.82,
    "out:Total CO2/m2": 2.89,
    "out:Total CO2 (tons)": 9.06,
    "out:Klimatpaverkan": -190.63,
    "out:Initial Cost/MSEK": 1.3835,
    "out:Running cost/(Apt SEK y)": 46758,
    "out:Running Cost over RSP/MSEK": 10.625,
    "out:LCP/MSEK": -0.32,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 139,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 15685,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111710,
    "out:DH kr savings": 14273,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 174.88,
    "out:Primary Energy": 122.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -22.18,
    "out:Total CO2/m2": 2.95,
    "out:Total CO2 (tons)": 9.25,
    "out:Klimatpaverkan": -190.44,
    "out:Initial Cost/MSEK": 1.464375,
    "out:Running cost/(Apt SEK y)": 46473,
    "out:Running Cost over RSP/MSEK": 10.56,
    "out:LCP/MSEK": -0.336,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 6.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 147,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 18822,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114565,
    "out:DH kr savings": 17128,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 168.88,
    "out:Primary Energy": 118.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -24.34,
    "out:Total CO2/m2": 0.79,
    "out:Total CO2 (tons)": 2.47,
    "out:Klimatpaverkan": -197.22,
    "out:Initial Cost/MSEK": 1.542875,
    "out:Running cost/(Apt SEK y)": 44768,
    "out:Running Cost over RSP/MSEK": 10.173,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 37644,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131693,
    "out:DH kr savings": 34256,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 178.9,
    "out:Primary Energy": 132.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.78,
    "out:Total CO2/m2": 60.78,
    "out:Total CO2 (tons)": 190.66,
    "out:Klimatpaverkan": -9.03,
    "out:Initial Cost/MSEK": 0.07654,
    "out:Running cost/(Apt SEK y)": 49265,
    "out:Running Cost over RSP/MSEK": 11.173,
    "out:LCP/MSEK": 0.44,
    "out:ROI% old": 96.34,
    "out:Payback discounted": 2,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 86,
    "out:Return/kSEK/y": 66,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 30,
    "out:Payback Time": 3.333333,
    "out:DH kWh savings": 25096,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 22837,
    "out:DH kr savings": 22837,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 128.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 183.88,
    "out:Klimatpaverkan": -15.81,
    "out:Initial Cost/MSEK": 0.154965,
    "out:Running cost/(Apt SEK y)": 47559,
    "out:Running Cost over RSP/MSEK": 10.785,
    "out:LCP/MSEK": 0.748,
    "out:ROI% old": 83.27,
    "out:Payback discounted": 2,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 74,
    "out:Return/kSEK/y": 115,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 26,
    "out:Payback Time": 3.846154,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 39965,
    "out:DH kr savings": 39965,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 130.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 59.04,
    "out:Total CO2 (tons)": 185.2,
    "out:Klimatpaverkan": -14.49,
    "out:Initial Cost/MSEK": 0.235916,
    "out:Running cost/(Apt SEK y)": 47559,
    "out:Running Cost over RSP/MSEK": 10.785,
    "out:LCP/MSEK": 0.667,
    "out:ROI% old": 54.7,
    "out:Payback discounted": 3,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 49,
    "out:Return/kSEK/y": 115,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 39965,
    "out:DH kr savings": 39965,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 126.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.42,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 57.24,
    "out:Total CO2 (tons)": 179.55,
    "out:Klimatpaverkan": -20.14,
    "out:Initial Cost/MSEK": 0.314341,
    "out:Running cost/(Apt SEK y)": 46137,
    "out:Running Cost over RSP/MSEK": 10.463,
    "out:LCP/MSEK": 0.912,
    "out:ROI% old": 55.71,
    "out:Payback discounted": 3,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 50,
    "out:Return/kSEK/y": 157,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 54239,
    "out:DH kr savings": 54239,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 173.73,
    "out:Primary Energy": 122.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 29.78,
    "out:Total CO2/m2": 42.13,
    "out:Total CO2 (tons)": 132.18,
    "out:Klimatpaverkan": -67.51,
    "out:Initial Cost/MSEK": 0.72907,
    "out:Running cost/(Apt SEK y)": 47485,
    "out:Running Cost over RSP/MSEK": 10.78,
    "out:LCP/MSEK": 0.18,
    "out:ROI% old": 17.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 7.5,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 25096,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74437,
    "out:DH kr savings": 22837,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 118.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 39.97,
    "out:Total CO2 (tons)": 125.4,
    "out:Klimatpaverkan": -74.29,
    "out:Initial Cost/MSEK": 0.807495,
    "out:Running cost/(Apt SEK y)": 45779,
    "out:Running Cost over RSP/MSEK": 10.393,
    "out:LCP/MSEK": 0.489,
    "out:ROI% old": 22.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91565,
    "out:DH kr savings": 39965,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 120.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.77,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 40.39,
    "out:Total CO2 (tons)": 126.72,
    "out:Klimatpaverkan": -72.97,
    "out:Initial Cost/MSEK": 0.888446,
    "out:Running cost/(Apt SEK y)": 45779,
    "out:Running Cost over RSP/MSEK": 10.393,
    "out:LCP/MSEK": 0.408,
    "out:ROI% old": 20.84,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91565,
    "out:DH kr savings": 39965,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 116.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.77,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 38.59,
    "out:Total CO2 (tons)": 121.07,
    "out:Klimatpaverkan": -78.62,
    "out:Initial Cost/MSEK": 0.966871,
    "out:Running cost/(Apt SEK y)": 44358,
    "out:Running Cost over RSP/MSEK": 10.07,
    "out:LCP/MSEK": 0.652,
    "out:ROI% old": 23.92,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105839,
    "out:DH kr savings": 54239,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 178.9,
    "out:Total Energy Use Post PV": 172.88,
    "out:Primary Energy": 121.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -22.9,
    "out:Total CO2/m2": 1.81,
    "out:Total CO2 (tons)": 5.67,
    "out:Klimatpaverkan": -194.02,
    "out:Initial Cost/MSEK": 1.381625,
    "out:Running cost/(Apt SEK y)": 45905,
    "out:Running Cost over RSP/MSEK": 10.431,
    "out:LCP/MSEK": -0.124,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 163,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 25096,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120274,
    "out:DH kr savings": 22837,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 117.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": -0.35,
    "out:Total CO2 (tons)": -1.1,
    "out:Klimatpaverkan": -200.79,
    "out:Initial Cost/MSEK": 1.46,
    "out:Running cost/(Apt SEK y)": 44199,
    "out:Running Cost over RSP/MSEK": 10.044,
    "out:LCP/MSEK": 0.185,
    "out:ROI% old": 16.09,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137402,
    "out:DH kr savings": 39965,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 119.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": 0.07,
    "out:Total CO2 (tons)": 0.21,
    "out:Klimatpaverkan": -199.48,
    "out:Initial Cost/MSEK": 1.541,
    "out:Running cost/(Apt SEK y)": 44199,
    "out:Running Cost over RSP/MSEK": 10.044,
    "out:LCP/MSEK": 0.104,
    "out:ROI% old": 15.25,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 43918,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137402,
    "out:DH kr savings": 39965,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 401118,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 115.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.13,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": -1.73,
    "out:Total CO2 (tons)": -5.43,
    "out:Klimatpaverkan": -205.12,
    "out:Initial Cost/MSEK": 1.619375,
    "out:Running cost/(Apt SEK y)": 42777,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.348,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151675,
    "out:DH kr savings": 54239,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 383741,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.93,
    "out:Electricity (inc PV)": 42.95,
    "out:Total Energy Use Pre PV": 127.93,
    "out:Total Energy Use Post PV": 125.95,
    "out:Primary Energy": 127.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 35.81,
    "out:Total CO2/m2": 39.62,
    "out:Total CO2 (tons)": 124.28,
    "out:Klimatpaverkan": -75.41,
    "out:Initial Cost/MSEK": 2.722375,
    "out:Running cost/(Apt SEK y)": 31859,
    "out:Running Cost over RSP/MSEK": 7.208,
    "out:LCP/MSEK": 1.759,
    "out:ROI% old": 23.51,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 3145.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 571,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276056,
    "out:EL kWh savings": -84864,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106943,
    "out:DH kr savings": 251211,
    "out:El kr savings": -144268,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 260863,
    "out:% savings (space heating)": 34.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 71.18,
    "out:Etvv": 0,
    "out:Ef": 42.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 42.84,
    "out:Total Energy Use Pre PV": 122.87,
    "out:Total Energy Use Post PV": 120.84,
    "out:Primary Energy": 123.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 34,
    "out:Total CO2/m2": 37.81,
    "out:Total CO2 (tons)": 118.61,
    "out:Klimatpaverkan": -81.08,
    "out:Initial Cost/MSEK": 2.80075,
    "out:Running cost/(Apt SEK y)": 30426,
    "out:Running Cost over RSP/MSEK": 6.882,
    "out:LCP/MSEK": 2.006,
    "out:ROI% old": 24.52,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 3140.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 612,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 291741,
    "out:EL kWh savings": -84526,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121790,
    "out:DH kr savings": 265484,
    "out:El kr savings": -143694,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 244128,
    "out:% savings (space heating)": 39.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66,
    "out:Etvv": 0,
    "out:Ef": 42.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 42.52,
    "out:Total Energy Use Pre PV": 123.27,
    "out:Total Energy Use Post PV": 121.52,
    "out:Primary Energy": 124.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.23,
    "out:CO2 Operational/m2": 34.28,
    "out:Total CO2/m2": 38.51,
    "out:Total CO2 (tons)": 120.81,
    "out:Klimatpaverkan": -78.88,
    "out:Initial Cost/MSEK": 2.88175,
    "out:Running cost/(Apt SEK y)": 30600,
    "out:Running Cost over RSP/MSEK": 6.922,
    "out:LCP/MSEK": 1.885,
    "out:ROI% old": 23.63,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 3098.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288604,
    "out:EL kWh savings": -83495,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120689,
    "out:DH kr savings": 262630,
    "out:El kr savings": -141941,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 247252,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.87,
    "out:Etvv": 0,
    "out:Ef": 42.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.19,
    "out:Electricity (inc PV)": 42.4,
    "out:Total Energy Use Pre PV": 118.19,
    "out:Total Energy Use Post PV": 116.4,
    "out:Primary Energy": 120.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.23,
    "out:CO2 Operational/m2": 32.47,
    "out:Total CO2/m2": 36.7,
    "out:Total CO2 (tons)": 115.13,
    "out:Klimatpaverkan": -84.56,
    "out:Initial Cost/MSEK": 2.960125,
    "out:Running cost/(Apt SEK y)": 29164,
    "out:Running Cost over RSP/MSEK": 6.596,
    "out:LCP/MSEK": 2.132,
    "out:ROI% old": 24.58,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 3093.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -83125,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135590,
    "out:DH kr savings": 276903,
    "out:El kr savings": -141313,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 230672,
    "out:% savings (space heating)": 42.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 63.72,
    "out:Etvv": 0,
    "out:Ef": 42.4,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.93,
    "out:Electricity (inc PV)": 33.46,
    "out:Total Energy Use Pre PV": 127.93,
    "out:Total Energy Use Post PV": 116.46,
    "out:Primary Energy": 110.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.16,
    "out:CO2 Operational/m2": 27.31,
    "out:Total CO2/m2": 43.47,
    "out:Total CO2 (tons)": 136.36,
    "out:Klimatpaverkan": -63.33,
    "out:Initial Cost/MSEK": 3.374875,
    "out:Running cost/(Apt SEK y)": 29872,
    "out:Running Cost over RSP/MSEK": 6.769,
    "out:LCP/MSEK": 1.545,
    "out:ROI% old": 20.82,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2493.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4664.77,
    "out:PV (% sold (El))": 13.3,
    "out:PV (kWh self-consumed)": 30397.93,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 276056,
    "out:EL kWh savings": -55074,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163532,
    "out:DH kr savings": 251211,
    "out:El kr savings": -93626,
    "out:El kr sold": 5948,
    "out:El kr saved": 51676,
    "out:El kr return": 57624,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 260863,
    "out:% savings (space heating)": 34.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 71.18,
    "out:Etvv": 0,
    "out:Ef": 33.46,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 33.36,
    "out:Total Energy Use Pre PV": 122.87,
    "out:Total Energy Use Post PV": 111.36,
    "out:Primary Energy": 106.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.16,
    "out:CO2 Operational/m2": 25.47,
    "out:Total CO2/m2": 41.63,
    "out:Total CO2 (tons)": 130.6,
    "out:Klimatpaverkan": -69.09,
    "out:Initial Cost/MSEK": 3.453375,
    "out:Running cost/(Apt SEK y)": 28439,
    "out:Running Cost over RSP/MSEK": 6.444,
    "out:LCP/MSEK": 1.791,
    "out:ROI% old": 21.7,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2489.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 670,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4683.02,
    "out:PV (% sold (El))": 13.36,
    "out:PV (kWh self-consumed)": 30379.69,
    "out:PV (ratio sold/self-consumed)": 0.15,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 291741,
    "out:EL kWh savings": -54771,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178345,
    "out:DH kr savings": 265484,
    "out:El kr savings": -93110,
    "out:El kr sold": 5971,
    "out:El kr saved": 51645,
    "out:El kr return": 57616,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 244128,
    "out:% savings (space heating)": 39.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66,
    "out:Etvv": 0,
    "out:Ef": 33.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 33.06,
    "out:Total Energy Use Pre PV": 123.27,
    "out:Total Energy Use Post PV": 112.06,
    "out:Primary Energy": 107.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.58,
    "out:CO2 Operational/m2": 25.47,
    "out:Total CO2/m2": 42.05,
    "out:Total CO2 (tons)": 131.92,
    "out:Klimatpaverkan": -67.77,
    "out:Initial Cost/MSEK": 3.53425,
    "out:Running cost/(Apt SEK y)": 28616,
    "out:Running Cost over RSP/MSEK": 6.484,
    "out:LCP/MSEK": 1.67,
    "out:ROI% old": 21.04,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2450.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 665,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4862.82,
    "out:PV (% sold (El))": 13.87,
    "out:PV (kWh self-consumed)": 30199.89,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288604,
    "out:EL kWh savings": -53845,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177293,
    "out:DH kr savings": 262630,
    "out:El kr savings": -91537,
    "out:El kr sold": 6200,
    "out:El kr saved": 51340,
    "out:El kr return": 57540,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 247252,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.87,
    "out:Etvv": 0,
    "out:Ef": 33.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.19,
    "out:Electricity (inc PV)": 32.96,
    "out:Total Energy Use Pre PV": 118.19,
    "out:Total Energy Use Post PV": 106.96,
    "out:Primary Energy": 103.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.58,
    "out:CO2 Operational/m2": 23.62,
    "out:Total CO2/m2": 40.21,
    "out:Total CO2 (tons)": 126.12,
    "out:Klimatpaverkan": -73.57,
    "out:Initial Cost/MSEK": 3.61275,
    "out:Running cost/(Apt SEK y)": 27181,
    "out:Running Cost over RSP/MSEK": 6.159,
    "out:LCP/MSEK": 1.917,
    "out:ROI% old": 21.87,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2445.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4886.28,
    "out:PV (% sold (El))": 13.94,
    "out:PV (kWh self-consumed)": 30176.43,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -53514,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192159,
    "out:DH kr savings": 276903,
    "out:El kr savings": -90974,
    "out:El kr sold": 6230,
    "out:El kr saved": 51300,
    "out:El kr return": 57530,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 230672,
    "out:% savings (space heating)": 42.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 63.72,
    "out:Etvv": 0,
    "out:Ef": 32.96,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.93,
    "out:Electricity (inc PV)": 30.23,
    "out:Total Energy Use Pre PV": 127.93,
    "out:Total Energy Use Post PV": 113.23,
    "out:Primary Energy": 104.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -12.38,
    "out:Total CO2/m2": 16.13,
    "out:Total CO2 (tons)": 50.61,
    "out:Klimatpaverkan": -149.08,
    "out:Initial Cost/MSEK": 4.0275,
    "out:Running cost/(Apt SEK y)": 28174,
    "out:Running Cost over RSP/MSEK": 6.394,
    "out:LCP/MSEK": 1.267,
    "out:ROI% old": 18.78,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 2250,
    "out:Return %": 17,
    "out:Return/kSEK/y": 678,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29025.26,
    "out:PV (% sold (El))": 41.39,
    "out:PV (kWh self-consumed)": 41100.16,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 276056,
    "out:EL kWh savings": -44957,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211792,
    "out:DH kr savings": 251211,
    "out:El kr savings": -76426,
    "out:El kr sold": 37007,
    "out:El kr saved": 69870,
    "out:El kr return": 106877,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 260863,
    "out:% savings (space heating)": 34.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 71.18,
    "out:Etvv": 0,
    "out:Ef": 30.23,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.87,
    "out:Electricity (inc PV)": 30.14,
    "out:Total Energy Use Pre PV": 122.87,
    "out:Total Energy Use Post PV": 108.14,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -14.25,
    "out:Total CO2/m2": 14.27,
    "out:Total CO2 (tons)": 44.75,
    "out:Klimatpaverkan": -154.94,
    "out:Initial Cost/MSEK": 4.105875,
    "out:Running cost/(Apt SEK y)": 26741,
    "out:Running Cost over RSP/MSEK": 6.069,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 19.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 2246.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 719,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29062.21,
    "out:PV (% sold (El))": 41.44,
    "out:PV (kWh self-consumed)": 41063.21,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 291741,
    "out:EL kWh savings": -44685,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226574,
    "out:DH kr savings": 265484,
    "out:El kr savings": -75964,
    "out:El kr sold": 37054,
    "out:El kr saved": 69807,
    "out:El kr return": 106862,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 244128,
    "out:% savings (space heating)": 39.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66,
    "out:Etvv": 0,
    "out:Ef": 30.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 29.88,
    "out:Total Energy Use Pre PV": 123.27,
    "out:Total Energy Use Post PV": 108.88,
    "out:Primary Energy": 101.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.94,
    "out:CO2 Operational/m2": -14.54,
    "out:Total CO2/m2": 14.39,
    "out:Total CO2 (tons)": 45.15,
    "out:Klimatpaverkan": -154.54,
    "out:Initial Cost/MSEK": 4.186875,
    "out:Running cost/(Apt SEK y)": 26920,
    "out:Running Cost over RSP/MSEK": 6.11,
    "out:LCP/MSEK": 1.392,
    "out:ROI% old": 19.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 2212.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 714,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29421.21,
    "out:PV (% sold (El))": 41.96,
    "out:PV (kWh self-consumed)": 40704.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288604,
    "out:EL kWh savings": -43856,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225587,
    "out:DH kr savings": 262630,
    "out:El kr savings": -74554,
    "out:El kr sold": 37512,
    "out:El kr saved": 69197,
    "out:El kr return": 106709,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 247252,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.87,
    "out:Etvv": 0,
    "out:Ef": 29.88,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.19,
    "out:Electricity (inc PV)": 29.79,
    "out:Total Energy Use Pre PV": 118.19,
    "out:Total Energy Use Post PV": 103.79,
    "out:Primary Energy": 98.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.94,
    "out:CO2 Operational/m2": -16.43,
    "out:Total CO2/m2": 12.51,
    "out:Total CO2 (tons)": 39.24,
    "out:Klimatpaverkan": -160.45,
    "out:Initial Cost/MSEK": 4.26525,
    "out:Running cost/(Apt SEK y)": 25485,
    "out:Running Cost over RSP/MSEK": 5.784,
    "out:LCP/MSEK": 1.639,
    "out:ROI% old": 19.78,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2207.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 756,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29467.38,
    "out:PV (% sold (El))": 42.02,
    "out:PV (kWh self-consumed)": 40658.04,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -43559,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240424,
    "out:DH kr savings": 276903,
    "out:El kr savings": -74050,
    "out:El kr sold": 37571,
    "out:El kr saved": 69119,
    "out:El kr return": 106690,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 230672,
    "out:% savings (space heating)": 42.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 63.72,
    "out:Etvv": 0,
    "out:Ef": 29.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.04,
    "out:Electricity (inc PV)": 42.37,
    "out:Total Energy Use Pre PV": 121.04,
    "out:Total Energy Use Post PV": 119.37,
    "out:Primary Energy": 123.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 33.53,
    "out:Total CO2/m2": 37.34,
    "out:Total CO2 (tons)": 117.14,
    "out:Klimatpaverkan": -82.55,
    "out:Initial Cost/MSEK": 2.798875,
    "out:Running cost/(Apt SEK y)": 29989,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": 2.106,
    "out:ROI% old": 25.04,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 3082.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 294878,
    "out:EL kWh savings": -83031,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127187,
    "out:DH kr savings": 268339,
    "out:El kr savings": -141152,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 242805,
    "out:% savings (space heating)": 39.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.11,
    "out:Etvv": 0,
    "out:Ef": 42.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.96,
    "out:Electricity (inc PV)": 42.25,
    "out:Total Energy Use Pre PV": 115.96,
    "out:Total Energy Use Post PV": 114.25,
    "out:Primary Energy": 120.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.81,
    "out:CO2 Operational/m2": 31.72,
    "out:Total CO2/m2": 35.53,
    "out:Total CO2 (tons)": 111.46,
    "out:Klimatpaverkan": -88.23,
    "out:Initial Cost/MSEK": 2.877375,
    "out:Running cost/(Apt SEK y)": 28553,
    "out:Running Cost over RSP/MSEK": 6.458,
    "out:LCP/MSEK": 2.354,
    "out:ROI% old": 25.97,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 3076.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 310563,
    "out:EL kWh savings": -82651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142106,
    "out:DH kr savings": 282612,
    "out:El kr savings": -140507,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 226284,
    "out:% savings (space heating)": 43.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 62.97,
    "out:Etvv": 0,
    "out:Ef": 42.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 42.01,
    "out:Total Energy Use Pre PV": 117.47,
    "out:Total Energy Use Post PV": 116.01,
    "out:Primary Energy": 122.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.23,
    "out:CO2 Operational/m2": 32.38,
    "out:Total CO2/m2": 36.61,
    "out:Total CO2 (tons)": 114.83,
    "out:Klimatpaverkan": -84.86,
    "out:Initial Cost/MSEK": 2.95825,
    "out:Running cost/(Apt SEK y)": 29032,
    "out:Running Cost over RSP/MSEK": 6.567,
    "out:LCP/MSEK": 2.164,
    "out:ROI% old": 24.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 3042.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -81901,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137671,
    "out:DH kr savings": 276903,
    "out:El kr savings": -139232,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 232313,
    "out:% savings (space heating)": 42.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66.3,
    "out:Etvv": 0,
    "out:Ef": 42.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 41.88,
    "out:Total Energy Use Pre PV": 112.37,
    "out:Total Energy Use Post PV": 110.88,
    "out:Primary Energy": 118.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.23,
    "out:CO2 Operational/m2": 30.56,
    "out:Total CO2/m2": 34.79,
    "out:Total CO2 (tons)": 109.15,
    "out:Klimatpaverkan": -90.54,
    "out:Initial Cost/MSEK": 3.03675,
    "out:Running cost/(Apt SEK y)": 27593,
    "out:Running Cost over RSP/MSEK": 6.24,
    "out:LCP/MSEK": 2.412,
    "out:ROI% old": 25.63,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 3036.2,
    "out:Return %": 23,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -81495,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152635,
    "out:DH kr savings": 291176,
    "out:El kr savings": -138541,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 215923,
    "out:% savings (space heating)": 46.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.19,
    "out:Etvv": 0,
    "out:Ef": 41.88,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.04,
    "out:Electricity (inc PV)": 32.93,
    "out:Total Energy Use Pre PV": 121.04,
    "out:Total Energy Use Post PV": 109.93,
    "out:Primary Energy": 106.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.16,
    "out:CO2 Operational/m2": 24.61,
    "out:Total CO2/m2": 40.77,
    "out:Total CO2 (tons)": 127.9,
    "out:Klimatpaverkan": -71.79,
    "out:Initial Cost/MSEK": 3.4515,
    "out:Running cost/(Apt SEK y)": 28006,
    "out:Running Cost over RSP/MSEK": 6.346,
    "out:LCP/MSEK": 1.891,
    "out:ROI% old": 22.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2436.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4933.07,
    "out:PV (% sold (El))": 14.07,
    "out:PV (kWh self-consumed)": 30129.64,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 294878,
    "out:EL kWh savings": -53429,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183800,
    "out:DH kr savings": 268339,
    "out:El kr savings": -90829,
    "out:El kr sold": 6290,
    "out:El kr saved": 51220,
    "out:El kr return": 57510,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 242805,
    "out:% savings (space heating)": 39.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.11,
    "out:Etvv": 0,
    "out:Ef": 32.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.96,
    "out:Electricity (inc PV)": 32.82,
    "out:Total Energy Use Pre PV": 115.96,
    "out:Total Energy Use Post PV": 104.82,
    "out:Primary Energy": 103.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.16,
    "out:CO2 Operational/m2": 22.76,
    "out:Total CO2/m2": 38.92,
    "out:Total CO2 (tons)": 122.1,
    "out:Klimatpaverkan": -77.59,
    "out:Initial Cost/MSEK": 3.529875,
    "out:Running cost/(Apt SEK y)": 26570,
    "out:Running Cost over RSP/MSEK": 6.02,
    "out:LCP/MSEK": 2.139,
    "out:ROI% old": 22.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2430.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 724,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4958.61,
    "out:PV (% sold (El))": 14.14,
    "out:PV (kWh self-consumed)": 30104.1,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 310563,
    "out:EL kWh savings": -53088,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198684,
    "out:DH kr savings": 282612,
    "out:El kr savings": -90250,
    "out:El kr sold": 6322,
    "out:El kr saved": 51177,
    "out:El kr return": 57499,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 226284,
    "out:% savings (space heating)": 43.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 62.97,
    "out:Etvv": 0,
    "out:Ef": 32.82,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 32.61,
    "out:Total Energy Use Pre PV": 117.47,
    "out:Total Energy Use Post PV": 106.61,
    "out:Primary Energy": 105.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.58,
    "out:CO2 Operational/m2": 23.18,
    "out:Total CO2/m2": 39.76,
    "out:Total CO2 (tons)": 124.73,
    "out:Klimatpaverkan": -74.96,
    "out:Initial Cost/MSEK": 3.610875,
    "out:Running cost/(Apt SEK y)": 27051,
    "out:Running Cost over RSP/MSEK": 6.13,
    "out:LCP/MSEK": 1.948,
    "out:ROI% old": 21.99,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2399.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5109.95,
    "out:PV (% sold (El))": 14.57,
    "out:PV (kWh self-consumed)": 29952.76,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -52417,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194309,
    "out:DH kr savings": 276903,
    "out:El kr savings": -89109,
    "out:El kr sold": 6515,
    "out:El kr saved": 50920,
    "out:El kr return": 57435,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 232313,
    "out:% savings (space heating)": 42.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66.3,
    "out:Etvv": 0,
    "out:Ef": 32.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 32.49,
    "out:Total Energy Use Pre PV": 112.37,
    "out:Total Energy Use Post PV": 101.49,
    "out:Primary Energy": 101.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.58,
    "out:CO2 Operational/m2": 21.32,
    "out:Total CO2/m2": 37.9,
    "out:Total CO2 (tons)": 118.9,
    "out:Klimatpaverkan": -80.79,
    "out:Initial Cost/MSEK": 3.68925,
    "out:Running cost/(Apt SEK y)": 25613,
    "out:Running Cost over RSP/MSEK": 5.803,
    "out:LCP/MSEK": 2.196,
    "out:ROI% old": 22.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2393.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 752,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5139.89,
    "out:PV (% sold (El))": 14.66,
    "out:PV (kWh self-consumed)": 29922.82,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -52053,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209240,
    "out:DH kr savings": 291176,
    "out:El kr savings": -88490,
    "out:El kr sold": 6553,
    "out:El kr saved": 50869,
    "out:El kr return": 57422,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 215923,
    "out:% savings (space heating)": 46.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.19,
    "out:Etvv": 0,
    "out:Ef": 32.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.04,
    "out:Electricity (inc PV)": 29.76,
    "out:Total Energy Use Pre PV": 121.04,
    "out:Total Energy Use Post PV": 106.76,
    "out:Primary Energy": 101.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -15.51,
    "out:Total CO2/m2": 13,
    "out:Total CO2 (tons)": 40.79,
    "out:Klimatpaverkan": -158.9,
    "out:Initial Cost/MSEK": 4.104,
    "out:Running cost/(Apt SEK y)": 26312,
    "out:Running Cost over RSP/MSEK": 5.972,
    "out:LCP/MSEK": 1.613,
    "out:ROI% old": 19.9,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2199,
    "out:Return %": 18,
    "out:Return/kSEK/y": 732,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29559.2,
    "out:PV (% sold (El))": 42.15,
    "out:PV (kWh self-consumed)": 40566.22,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 294878,
    "out:EL kWh savings": -43483,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232106,
    "out:DH kr savings": 268339,
    "out:El kr savings": -73921,
    "out:El kr sold": 37688,
    "out:El kr saved": 68963,
    "out:El kr return": 106651,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 242805,
    "out:% savings (space heating)": 39.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 68.11,
    "out:Etvv": 0,
    "out:Ef": 29.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.96,
    "out:Electricity (inc PV)": 29.66,
    "out:Total Energy Use Pre PV": 115.96,
    "out:Total Energy Use Post PV": 101.66,
    "out:Primary Energy": 97.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": -17.4,
    "out:Total CO2/m2": 11.11,
    "out:Total CO2 (tons)": 34.86,
    "out:Klimatpaverkan": -164.83,
    "out:Initial Cost/MSEK": 4.182375,
    "out:Running cost/(Apt SEK y)": 24876,
    "out:Running Cost over RSP/MSEK": 5.646,
    "out:LCP/MSEK": 1.86,
    "out:ROI% old": 20.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2194.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 773,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29609.07,
    "out:PV (% sold (El))": 42.22,
    "out:PV (kWh self-consumed)": 40516.34,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 310563,
    "out:EL kWh savings": -43178,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246962,
    "out:DH kr savings": 282612,
    "out:El kr savings": -73402,
    "out:El kr sold": 37752,
    "out:El kr saved": 68878,
    "out:El kr return": 106629,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 226284,
    "out:% savings (space heating)": 43.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 62.97,
    "out:Etvv": 0,
    "out:Ef": 29.66,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 29.47,
    "out:Total Energy Use Pre PV": 117.47,
    "out:Total Energy Use Post PV": 103.47,
    "out:Primary Energy": 99.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.94,
    "out:CO2 Operational/m2": -17.22,
    "out:Total CO2/m2": 11.72,
    "out:Total CO2 (tons)": 36.76,
    "out:Klimatpaverkan": -162.93,
    "out:Initial Cost/MSEK": 4.263375,
    "out:Running cost/(Apt SEK y)": 25359,
    "out:Running Cost over RSP/MSEK": 5.756,
    "out:LCP/MSEK": 1.669,
    "out:ROI% old": 19.88,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2166.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29903.23,
    "out:PV (% sold (El))": 42.64,
    "out:PV (kWh self-consumed)": 40222.18,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 304289,
    "out:EL kWh savings": -42576,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242651,
    "out:DH kr savings": 276903,
    "out:El kr savings": -72379,
    "out:El kr sold": 38127,
    "out:El kr saved": 68378,
    "out:El kr return": 106504,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 232313,
    "out:% savings (space heating)": 42.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 66.3,
    "out:Etvv": 0,
    "out:Ef": 29.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 29.37,
    "out:Total Energy Use Pre PV": 112.37,
    "out:Total Energy Use Post PV": 98.37,
    "out:Primary Energy": 95.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.94,
    "out:CO2 Operational/m2": -19.12,
    "out:Total CO2/m2": 9.81,
    "out:Total CO2 (tons)": 30.78,
    "out:Klimatpaverkan": -168.91,
    "out:Initial Cost/MSEK": 4.34175,
    "out:Running cost/(Apt SEK y)": 23921,
    "out:Running Cost over RSP/MSEK": 5.43,
    "out:LCP/MSEK": 1.917,
    "out:ROI% old": 20.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2161.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 801,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29961.14,
    "out:PV (% sold (El))": 42.73,
    "out:PV (kWh self-consumed)": 40164.28,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -42250,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257553,
    "out:DH kr savings": 291176,
    "out:El kr savings": -71824,
    "out:El kr sold": 38200,
    "out:El kr saved": 68279,
    "out:El kr return": 106480,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 215923,
    "out:% savings (space heating)": 46.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.19,
    "out:Etvv": 0,
    "out:Ef": 29.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 63.41,
    "out:Electricity (inc PV)": 59.7,
    "out:Total Energy Use Pre PV": 85.41,
    "out:Total Energy Use Post PV": 81.7,
    "out:Primary Energy": 118.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 20.74,
    "out:Total CO2 (tons)": 65.07,
    "out:Klimatpaverkan": -134.62,
    "out:Initial Cost/MSEK": 4.57875,
    "out:Running cost/(Apt SEK y)": 17916,
    "out:Running Cost over RSP/MSEK": 4.034,
    "out:LCP/MSEK": 3.076,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 4438.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 975,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 45.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 467413,
    "out:EL kWh savings": -137393,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191778,
    "out:DH kr savings": 425346,
    "out:El kr savings": -233568,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 69528,
    "out:% savings (space heating)": 82.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 15.92,
    "out:Etvv": 0,
    "out:Ef": 59.7,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.86,
    "out:Electricity (inc PV)": 59,
    "out:Total Energy Use Pre PV": 80.86,
    "out:Total Energy Use Post PV": 77,
    "out:Primary Energy": 115.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 14.78,
    "out:Total CO2/m2": 19.23,
    "out:Total CO2 (tons)": 60.33,
    "out:Klimatpaverkan": -139.36,
    "out:Initial Cost/MSEK": 4.657125,
    "out:Running cost/(Apt SEK y)": 16676,
    "out:Running Cost over RSP/MSEK": 3.753,
    "out:LCP/MSEK": 3.279,
    "out:ROI% old": 24.34,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4399.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 479961,
    "out:EL kWh savings": -135198,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206928,
    "out:DH kr savings": 436765,
    "out:El kr savings": -229836,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 57911,
    "out:% savings (space heating)": 85.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 12.69,
    "out:Etvv": 0,
    "out:Ef": 59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.14,
    "out:Electricity (inc PV)": 58.91,
    "out:Total Energy Use Pre PV": 82.14,
    "out:Total Energy Use Post PV": 78.91,
    "out:Primary Energy": 116.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 15.4,
    "out:Total CO2/m2": 20.27,
    "out:Total CO2 (tons)": 63.6,
    "out:Klimatpaverkan": -136.09,
    "out:Initial Cost/MSEK": 4.738125,
    "out:Running cost/(Apt SEK y)": 17112,
    "out:Running Cost over RSP/MSEK": 3.852,
    "out:LCP/MSEK": 3.098,
    "out:ROI% old": 23.63,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 4349.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 473687,
    "out:EL kWh savings": -134911,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201706,
    "out:DH kr savings": 431055,
    "out:El kr savings": -229350,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 62271,
    "out:% savings (space heating)": 84.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.78,
    "out:Etvv": 0,
    "out:Ef": 58.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.53,
    "out:Electricity (inc PV)": 58.17,
    "out:Total Energy Use Pre PV": 77.53,
    "out:Total Energy Use Post PV": 74.17,
    "out:Primary Energy": 112.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 13.88,
    "out:Total CO2/m2": 18.76,
    "out:Total CO2 (tons)": 58.84,
    "out:Klimatpaverkan": -140.85,
    "out:Initial Cost/MSEK": 4.8165,
    "out:Running cost/(Apt SEK y)": 15864,
    "out:Running Cost over RSP/MSEK": 3.569,
    "out:LCP/MSEK": 3.303,
    "out:ROI% old": 24.08,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4307.4,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1035,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -132611,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217035,
    "out:DH kr savings": 442474,
    "out:El kr savings": -225439,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 51132,
    "out:% savings (space heating)": 87.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.66,
    "out:Etvv": 0,
    "out:Ef": 58.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 63.41,
    "out:Electricity (inc PV)": 48.99,
    "out:Total Energy Use Pre PV": 85.41,
    "out:Total Energy Use Post PV": 70.99,
    "out:Primary Energy": 99.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.81,
    "out:CO2 Operational/m2": 13.45,
    "out:Total CO2/m2": 30.26,
    "out:Total CO2 (tons)": 94.92,
    "out:Klimatpaverkan": -104.77,
    "out:Initial Cost/MSEK": 5.23125,
    "out:Running cost/(Apt SEK y)": 15875,
    "out:Running Cost over RSP/MSEK": 3.584,
    "out:LCP/MSEK": 2.874,
    "out:ROI% old": 22.13,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3719.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1034,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 45.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 989.17,
    "out:PV (% sold (El))": 2.82,
    "out:PV (kWh self-consumed)": 34073.53,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 467413,
    "out:EL kWh savings": -103808,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250133,
    "out:DH kr savings": 425346,
    "out:El kr savings": -176474,
    "out:El kr sold": 1261,
    "out:El kr saved": 57925,
    "out:El kr return": 59186,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 69528,
    "out:% savings (space heating)": 82.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 15.92,
    "out:Etvv": 0,
    "out:Ef": 48.99,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.86,
    "out:Electricity (inc PV)": 48.33,
    "out:Total Energy Use Pre PV": 80.86,
    "out:Total Energy Use Post PV": 66.33,
    "out:Primary Energy": 95.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.81,
    "out:CO2 Operational/m2": 11.84,
    "out:Total CO2/m2": 28.65,
    "out:Total CO2 (tons)": 89.87,
    "out:Klimatpaverkan": -109.82,
    "out:Initial Cost/MSEK": 5.30975,
    "out:Running cost/(Apt SEK y)": 14636,
    "out:Running Cost over RSP/MSEK": 3.303,
    "out:LCP/MSEK": 3.076,
    "out:ROI% old": 22.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3681.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1070,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1054.21,
    "out:PV (% sold (El))": 3.01,
    "out:PV (kWh self-consumed)": 34008.5,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 479961,
    "out:EL kWh savings": -101719,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265186,
    "out:DH kr savings": 436765,
    "out:El kr savings": -172922,
    "out:El kr sold": 1344,
    "out:El kr saved": 57814,
    "out:El kr return": 59159,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 57911,
    "out:% savings (space heating)": 85.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 12.69,
    "out:Etvv": 0,
    "out:Ef": 48.33,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.14,
    "out:Electricity (inc PV)": 48.24,
    "out:Total Energy Use Pre PV": 82.14,
    "out:Total Energy Use Post PV": 68.24,
    "out:Primary Energy": 97.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 29.56,
    "out:Total CO2 (tons)": 92.73,
    "out:Klimatpaverkan": -106.96,
    "out:Initial Cost/MSEK": 5.390625,
    "out:Running cost/(Apt SEK y)": 15074,
    "out:Running Cost over RSP/MSEK": 3.403,
    "out:LCP/MSEK": 2.896,
    "out:ROI% old": 21.96,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3632.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1057,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1142.72,
    "out:PV (% sold (El))": 3.26,
    "out:PV (kWh self-consumed)": 33919.98,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 473687,
    "out:EL kWh savings": -101447,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260053,
    "out:DH kr savings": 431055,
    "out:El kr savings": -172459,
    "out:El kr sold": 1457,
    "out:El kr saved": 57664,
    "out:El kr return": 59121,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 62271,
    "out:% savings (space heating)": 84.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.78,
    "out:Etvv": 0,
    "out:Ef": 48.24,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.53,
    "out:Electricity (inc PV)": 47.54,
    "out:Total Energy Use Pre PV": 77.53,
    "out:Total Energy Use Post PV": 63.54,
    "out:Primary Energy": 93.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 10.7,
    "out:Total CO2/m2": 27.92,
    "out:Total CO2 (tons)": 87.6,
    "out:Klimatpaverkan": -112.09,
    "out:Initial Cost/MSEK": 5.469125,
    "out:Running cost/(Apt SEK y)": 13827,
    "out:Running Cost over RSP/MSEK": 3.12,
    "out:LCP/MSEK": 3.1,
    "out:ROI% old": 22.38,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3591.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1094,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1220.57,
    "out:PV (% sold (El))": 3.48,
    "out:PV (kWh self-consumed)": 33842.14,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -99261,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275287,
    "out:DH kr savings": 442474,
    "out:El kr savings": -168744,
    "out:El kr sold": 1556,
    "out:El kr saved": 57532,
    "out:El kr return": 59088,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 51132,
    "out:% savings (space heating)": 87.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.66,
    "out:Etvv": 0,
    "out:Ef": 47.54,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 63.41,
    "out:Electricity (inc PV)": 44.05,
    "out:Total Energy Use Pre PV": 85.41,
    "out:Total Energy Use Post PV": 66.05,
    "out:Primary Energy": 90.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.16,
    "out:CO2 Operational/m2": -16.38,
    "out:Total CO2/m2": 12.78,
    "out:Total CO2 (tons)": 40.09,
    "out:Klimatpaverkan": -159.6,
    "out:Initial Cost/MSEK": 5.883875,
    "out:Running cost/(Apt SEK y)": 14087,
    "out:Running Cost over RSP/MSEK": 3.189,
    "out:LCP/MSEK": 2.616,
    "out:ROI% old": 20.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3337.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1086,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 45.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 19269.85,
    "out:PV (% sold (El))": 27.48,
    "out:PV (kWh self-consumed)": 50855.57,
    "out:PV (ratio sold/self-consumed)": 0.38,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 467413,
    "out:EL kWh savings": -88316,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 299778,
    "out:DH kr savings": 425346,
    "out:El kr savings": -150137,
    "out:El kr sold": 24569,
    "out:El kr saved": 86454,
    "out:El kr return": 111024,
    "out:% solar/total": 102,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 69528,
    "out:% savings (space heating)": 82.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 15.92,
    "out:Etvv": 0,
    "out:Ef": 44.05,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.86,
    "out:Electricity (inc PV)": 43.46,
    "out:Total Energy Use Pre PV": 80.86,
    "out:Total Energy Use Post PV": 61.46,
    "out:Primary Energy": 87.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.16,
    "out:CO2 Operational/m2": -18.3,
    "out:Total CO2/m2": 10.85,
    "out:Total CO2 (tons)": 34.05,
    "out:Klimatpaverkan": -165.64,
    "out:Initial Cost/MSEK": 5.96225,
    "out:Running cost/(Apt SEK y)": 12852,
    "out:Running Cost over RSP/MSEK": 2.908,
    "out:LCP/MSEK": 2.818,
    "out:ROI% old": 21.04,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3303.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 44.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 19530.62,
    "out:PV (% sold (El))": 27.85,
    "out:PV (kWh self-consumed)": 50594.79,
    "out:PV (ratio sold/self-consumed)": 0.39,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 479961,
    "out:EL kWh savings": -86468,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314671,
    "out:DH kr savings": 436765,
    "out:El kr savings": -146995,
    "out:El kr sold": 24902,
    "out:El kr saved": 86011,
    "out:El kr return": 110913,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 57911,
    "out:% savings (space heating)": 85.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 12.69,
    "out:Etvv": 0,
    "out:Ef": 43.46,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.14,
    "out:Electricity (inc PV)": 43.39,
    "out:Total Energy Use Pre PV": 82.14,
    "out:Total Energy Use Post PV": 63.39,
    "out:Primary Energy": 88.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -18.22,
    "out:Total CO2/m2": 11.36,
    "out:Total CO2 (tons)": 35.64,
    "out:Klimatpaverkan": -164.05,
    "out:Initial Cost/MSEK": 6.04325,
    "out:Running cost/(Apt SEK y)": 13293,
    "out:Running Cost over RSP/MSEK": 3.009,
    "out:LCP/MSEK": 2.637,
    "out:ROI% old": 20.52,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3260.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 19871.3,
    "out:PV (% sold (El))": 28.34,
    "out:PV (kWh self-consumed)": 50254.12,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 473687,
    "out:EL kWh savings": -86227,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309806,
    "out:DH kr savings": 431055,
    "out:El kr savings": -146585,
    "out:El kr sold": 25336,
    "out:El kr saved": 85432,
    "out:El kr return": 110768,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 62271,
    "out:% savings (space heating)": 84.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.78,
    "out:Etvv": 0,
    "out:Ef": 43.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.53,
    "out:Electricity (inc PV)": 42.77,
    "out:Total Energy Use Pre PV": 77.53,
    "out:Total Energy Use Post PV": 58.77,
    "out:Primary Energy": 85.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -20.19,
    "out:Total CO2/m2": 9.38,
    "out:Total CO2 (tons)": 29.44,
    "out:Klimatpaverkan": -170.25,
    "out:Initial Cost/MSEK": 6.121625,
    "out:Running cost/(Apt SEK y)": 12049,
    "out:Running Cost over RSP/MSEK": 2.727,
    "out:LCP/MSEK": 2.84,
    "out:ROI% old": 20.91,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3224.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1145,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 43.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20158.44,
    "out:PV (% sold (El))": 28.75,
    "out:PV (kWh self-consumed)": 49966.97,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -84293,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324877,
    "out:DH kr savings": 442474,
    "out:El kr savings": -143299,
    "out:El kr sold": 25702,
    "out:El kr saved": 84944,
    "out:El kr return": 110646,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 51132,
    "out:% savings (space heating)": 87.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.66,
    "out:Etvv": 0,
    "out:Ef": 42.77,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.7,
    "out:Electricity (inc PV)": 58.64,
    "out:Total Energy Use Pre PV": 80.7,
    "out:Total Energy Use Post PV": 77.64,
    "out:Primary Energy": 115.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 14.98,
    "out:Total CO2/m2": 19.44,
    "out:Total CO2 (tons)": 60.98,
    "out:Klimatpaverkan": -138.71,
    "out:Initial Cost/MSEK": 4.65525,
    "out:Running cost/(Apt SEK y)": 16748,
    "out:Running Cost over RSP/MSEK": 3.77,
    "out:LCP/MSEK": 3.264,
    "out:ROI% old": 24.3,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 4319.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1009,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 43.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 476824,
    "out:EL kWh savings": -134073,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205985,
    "out:DH kr savings": 433910,
    "out:El kr savings": -227925,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 59938,
    "out:% savings (space heating)": 85.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.42,
    "out:Etvv": 0,
    "out:Ef": 58.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.08,
    "out:Electricity (inc PV)": 57.9,
    "out:Total Energy Use Pre PV": 77.08,
    "out:Total Energy Use Post PV": 73.9,
    "out:Primary Energy": 112.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.45,
    "out:CO2 Operational/m2": 13.82,
    "out:Total CO2/m2": 18.28,
    "out:Total CO2 (tons)": 57.33,
    "out:Klimatpaverkan": -142.36,
    "out:Initial Cost/MSEK": 4.73375,
    "out:Running cost/(Apt SEK y)": 15782,
    "out:Running Cost over RSP/MSEK": 3.551,
    "out:LCP/MSEK": 3.404,
    "out:ROI% old": 24.56,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4275.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1037,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -131738,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218519,
    "out:DH kr savings": 442474,
    "out:El kr savings": -223955,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 48963,
    "out:% savings (space heating)": 87.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.33,
    "out:Etvv": 0,
    "out:Ef": 57.9,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.65,
    "out:Electricity (inc PV)": 57.99,
    "out:Total Energy Use Pre PV": 77.65,
    "out:Total Energy Use Post PV": 74.99,
    "out:Primary Energy": 113.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 19,
    "out:Total CO2 (tons)": 59.6,
    "out:Klimatpaverkan": -140.09,
    "out:Initial Cost/MSEK": 4.814625,
    "out:Running cost/(Apt SEK y)": 15985,
    "out:Running Cost over RSP/MSEK": 3.597,
    "out:LCP/MSEK": 3.277,
    "out:ROI% old": 24.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 4245.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 42.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 483098,
    "out:EL kWh savings": -132044,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215145,
    "out:DH kr savings": 439619,
    "out:El kr savings": -224474,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 54509,
    "out:% savings (space heating)": 86.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 13.55,
    "out:Etvv": 0,
    "out:Ef": 57.99,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 57.22,
    "out:Total Energy Use Pre PV": 73.99,
    "out:Total Energy Use Post PV": 71.22,
    "out:Primary Energy": 110.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.87,
    "out:CO2 Operational/m2": 12.96,
    "out:Total CO2/m2": 17.83,
    "out:Total CO2 (tons)": 55.94,
    "out:Klimatpaverkan": -143.75,
    "out:Initial Cost/MSEK": 4.893125,
    "out:Running cost/(Apt SEK y)": 15011,
    "out:Running Cost over RSP/MSEK": 3.376,
    "out:LCP/MSEK": 3.419,
    "out:ROI% old": 24.27,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4199,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1059,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -129620,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227829,
    "out:DH kr savings": 448183,
    "out:El kr savings": -220354,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 43960,
    "out:% savings (space heating)": 89.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.56,
    "out:Etvv": 0,
    "out:Ef": 57.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.7,
    "out:Electricity (inc PV)": 47.98,
    "out:Total Energy Use Pre PV": 80.7,
    "out:Total Energy Use Post PV": 66.98,
    "out:Primary Energy": 96.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.81,
    "out:CO2 Operational/m2": 11.83,
    "out:Total CO2/m2": 28.64,
    "out:Total CO2 (tons)": 89.84,
    "out:Klimatpaverkan": -109.85,
    "out:Initial Cost/MSEK": 5.307875,
    "out:Running cost/(Apt SEK y)": 14711,
    "out:Running Cost over RSP/MSEK": 3.32,
    "out:LCP/MSEK": 3.061,
    "out:ROI% old": 22.52,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3603.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1068,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 43.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1198.46,
    "out:PV (% sold (El))": 3.42,
    "out:PV (kWh self-consumed)": 33864.25,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 476824,
    "out:EL kWh savings": -100650,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264333,
    "out:DH kr savings": 433910,
    "out:El kr savings": -171105,
    "out:El kr sold": 1528,
    "out:El kr saved": 57569,
    "out:El kr return": 59097,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 59938,
    "out:% savings (space heating)": 85.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.42,
    "out:Etvv": 0,
    "out:Ef": 47.98,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.08,
    "out:Electricity (inc PV)": 47.28,
    "out:Total Energy Use Pre PV": 77.08,
    "out:Total Energy Use Post PV": 63.28,
    "out:Primary Energy": 93.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.81,
    "out:CO2 Operational/m2": 10.55,
    "out:Total CO2/m2": 27.36,
    "out:Total CO2 (tons)": 85.81,
    "out:Klimatpaverkan": -113.88,
    "out:Initial Cost/MSEK": 5.38625,
    "out:Running cost/(Apt SEK y)": 13745,
    "out:Running Cost over RSP/MSEK": 3.101,
    "out:LCP/MSEK": 3.201,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3561.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1096,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1280.67,
    "out:PV (% sold (El))": 3.65,
    "out:PV (kWh self-consumed)": 33782.04,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -98433,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276771,
    "out:DH kr savings": 442474,
    "out:El kr savings": -167335,
    "out:El kr sold": 1633,
    "out:El kr saved": 57429,
    "out:El kr return": 59062,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 48963,
    "out:% savings (space heating)": 87.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.33,
    "out:Etvv": 0,
    "out:Ef": 47.28,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.65,
    "out:Electricity (inc PV)": 47.37,
    "out:Total Energy Use Pre PV": 77.65,
    "out:Total Energy Use Post PV": 64.37,
    "out:Primary Energy": 94.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 10.76,
    "out:Total CO2/m2": 27.99,
    "out:Total CO2 (tons)": 87.79,
    "out:Klimatpaverkan": -111.9,
    "out:Initial Cost/MSEK": 5.46725,
    "out:Running cost/(Apt SEK y)": 13950,
    "out:Running Cost over RSP/MSEK": 3.148,
    "out:LCP/MSEK": 3.073,
    "out:ROI% old": 22.32,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3531.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1090,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 42.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1341.27,
    "out:PV (% sold (El))": 3.83,
    "out:PV (kWh self-consumed)": 33721.44,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 483098,
    "out:EL kWh savings": -98722,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273501,
    "out:DH kr savings": 439619,
    "out:El kr savings": -167828,
    "out:El kr sold": 1710,
    "out:El kr saved": 57326,
    "out:El kr return": 59037,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 54509,
    "out:% savings (space heating)": 86.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 13.55,
    "out:Etvv": 0,
    "out:Ef": 47.37,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 46.64,
    "out:Total Energy Use Pre PV": 73.99,
    "out:Total Energy Use Post PV": 60.64,
    "out:Primary Energy": 91.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.23,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 26.68,
    "out:Total CO2 (tons)": 83.69,
    "out:Klimatpaverkan": -116,
    "out:Initial Cost/MSEK": 5.545625,
    "out:Running cost/(Apt SEK y)": 12977,
    "out:Running Cost over RSP/MSEK": 2.928,
    "out:LCP/MSEK": 3.215,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3486.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1435.77,
    "out:PV (% sold (El))": 4.09,
    "out:PV (kWh self-consumed)": 33626.94,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -96426,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286090,
    "out:DH kr savings": 448183,
    "out:El kr savings": -163924,
    "out:El kr sold": 1831,
    "out:El kr saved": 57166,
    "out:El kr return": 58996,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 43960,
    "out:% savings (space heating)": 89.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.56,
    "out:Etvv": 0,
    "out:Ef": 46.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.7,
    "out:Electricity (inc PV)": 43.16,
    "out:Total Energy Use Pre PV": 80.7,
    "out:Total Energy Use Post PV": 62.16,
    "out:Primary Energy": 87.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.16,
    "out:CO2 Operational/m2": -18.96,
    "out:Total CO2/m2": 10.2,
    "out:Total CO2 (tons)": 31.98,
    "out:Klimatpaverkan": -167.71,
    "out:Initial Cost/MSEK": 5.960375,
    "out:Running cost/(Apt SEK y)": 12932,
    "out:Running Cost over RSP/MSEK": 2.927,
    "out:LCP/MSEK": 2.801,
    "out:ROI% old": 21,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3234.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 43.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20077.79,
    "out:PV (% sold (El))": 28.63,
    "out:PV (kWh self-consumed)": 50047.63,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 476824,
    "out:EL kWh savings": -85522,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314122,
    "out:DH kr savings": 433910,
    "out:El kr savings": -145387,
    "out:El kr sold": 25599,
    "out:El kr saved": 85081,
    "out:El kr return": 110680,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 59938,
    "out:% savings (space heating)": 85.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 14.42,
    "out:Etvv": 0,
    "out:Ef": 43.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.08,
    "out:Electricity (inc PV)": 42.54,
    "out:Total Energy Use Pre PV": 77.08,
    "out:Total Energy Use Post PV": 58.54,
    "out:Primary Energy": 84.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.16,
    "out:CO2 Operational/m2": -20.6,
    "out:Total CO2/m2": 8.56,
    "out:Total CO2 (tons)": 26.86,
    "out:Klimatpaverkan": -172.83,
    "out:Initial Cost/MSEK": 6.03875,
    "out:Running cost/(Apt SEK y)": 11970,
    "out:Running Cost over RSP/MSEK": 2.709,
    "out:LCP/MSEK": 2.941,
    "out:ROI% old": 21.24,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3197.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1148,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20374.31,
    "out:PV (% sold (El))": 29.05,
    "out:PV (kWh self-consumed)": 49751.11,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -83561,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326398,
    "out:DH kr savings": 442474,
    "out:El kr savings": -142053,
    "out:El kr sold": 25977,
    "out:El kr saved": 84577,
    "out:El kr return": 110554,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 48963,
    "out:% savings (space heating)": 87.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 11.33,
    "out:Etvv": 0,
    "out:Ef": 42.54,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.65,
    "out:Electricity (inc PV)": 42.62,
    "out:Total Energy Use Pre PV": 77.65,
    "out:Total Energy Use Post PV": 59.62,
    "out:Primary Energy": 86.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -20.63,
    "out:Total CO2/m2": 8.95,
    "out:Total CO2 (tons)": 28.07,
    "out:Klimatpaverkan": -171.62,
    "out:Initial Cost/MSEK": 6.11975,
    "out:Running cost/(Apt SEK y)": 12176,
    "out:Running Cost over RSP/MSEK": 2.756,
    "out:LCP/MSEK": 2.813,
    "out:ROI% old": 20.85,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3170.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1142,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 42.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20586.79,
    "out:PV (% sold (El))": 29.36,
    "out:PV (kWh self-consumed)": 49538.62,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 483098,
    "out:EL kWh savings": -83817,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323379,
    "out:DH kr savings": 439619,
    "out:El kr savings": -142489,
    "out:El kr sold": 26248,
    "out:El kr saved": 84216,
    "out:El kr return": 110464,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 54509,
    "out:% savings (space heating)": 86.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 13.55,
    "out:Etvv": 0,
    "out:Ef": 42.62,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.99,
    "out:Electricity (inc PV)": 41.97,
    "out:Total Energy Use Pre PV": 73.99,
    "out:Total Energy Use Post PV": 55.97,
    "out:Primary Energy": 82.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.58,
    "out:CO2 Operational/m2": -22.31,
    "out:Total CO2/m2": 7.27,
    "out:Total CO2 (tons)": 22.81,
    "out:Klimatpaverkan": -176.88,
    "out:Initial Cost/MSEK": 6.198125,
    "out:Running cost/(Apt SEK y)": 11207,
    "out:Running Cost over RSP/MSEK": 2.536,
    "out:LCP/MSEK": 2.954,
    "out:ROI% old": 21.09,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3131.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20907.72,
    "out:PV (% sold (El))": 29.81,
    "out:PV (kWh self-consumed)": 49217.7,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -81784,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335808,
    "out:DH kr savings": 448183,
    "out:El kr savings": -139033,
    "out:El kr sold": 26657,
    "out:El kr saved": 83670,
    "out:El kr return": 110327,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 43960,
    "out:% savings (space heating)": 89.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.56,
    "out:Etvv": 0,
    "out:Ef": 41.97,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 176.9,
    "out:Primary Energy": 128.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.27,
    "out:CO2 Operational/m2": 60.06,
    "out:Total CO2/m2": 62.33,
    "out:Total CO2 (tons)": 195.52,
    "out:Klimatpaverkan": -4.17,
    "out:Initial Cost/MSEK": 0.514103,
    "out:Running cost/(Apt SEK y)": 48696,
    "out:Running Cost over RSP/MSEK": 11.044,
    "out:LCP/MSEK": 0.131,
    "out:ROI% old": 17.93,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 5.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 16,
    "out:Return/kSEK/y": 82,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 31370,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 28547,
    "out:DH kr savings": 28547,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 171.9,
    "out:Primary Energy": 124.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.27,
    "out:CO2 Operational/m2": 58.26,
    "out:Total CO2/m2": 60.53,
    "out:Total CO2 (tons)": 189.87,
    "out:Klimatpaverkan": -9.82,
    "out:Initial Cost/MSEK": 0.592528,
    "out:Running cost/(Apt SEK y)": 47275,
    "out:Running Cost over RSP/MSEK": 10.721,
    "out:LCP/MSEK": 0.375,
    "out:ROI% old": 23.33,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 21,
    "out:Return/kSEK/y": 124,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 47055,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 42820,
    "out:DH kr savings": 42820,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 169.9,
    "out:Primary Energy": 125.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.69,
    "out:CO2 Operational/m2": 57.54,
    "out:Total CO2/m2": 60.23,
    "out:Total CO2 (tons)": 188.93,
    "out:Klimatpaverkan": -10.76,
    "out:Initial Cost/MSEK": 0.673479,
    "out:Running cost/(Apt SEK y)": 46706,
    "out:Running Cost over RSP/MSEK": 10.592,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 23.27,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 21,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 53329,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48529,
    "out:DH kr savings": 48529,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 122,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.69,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 58.43,
    "out:Total CO2 (tons)": 183.28,
    "out:Klimatpaverkan": -16.41,
    "out:Initial Cost/MSEK": 0.751904,
    "out:Running cost/(Apt SEK y)": 45284,
    "out:Running Cost over RSP/MSEK": 10.269,
    "out:LCP/MSEK": 0.668,
    "out:ROI% old": 26.97,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 24,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62803,
    "out:DH kr savings": 62803,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 171.73,
    "out:Primary Energy": 118.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 29.06,
    "out:Total CO2/m2": 43.68,
    "out:Total CO2 (tons)": 137.04,
    "out:Klimatpaverkan": -62.65,
    "out:Initial Cost/MSEK": 1.166633,
    "out:Running cost/(Apt SEK y)": 46917,
    "out:Running Cost over RSP/MSEK": 10.651,
    "out:LCP/MSEK": -0.129,
    "out:ROI% old": 12.71,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 134,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 31370,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 80147,
    "out:DH kr savings": 28547,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 166.73,
    "out:Primary Energy": 115.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 27.26,
    "out:Total CO2/m2": 41.88,
    "out:Total CO2 (tons)": 131.39,
    "out:Klimatpaverkan": -68.3,
    "out:Initial Cost/MSEK": 1.245058,
    "out:Running cost/(Apt SEK y)": 45495,
    "out:Running Cost over RSP/MSEK": 10.328,
    "out:LCP/MSEK": 0.116,
    "out:ROI% old": 15.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 47055,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94420,
    "out:DH kr savings": 42820,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 164.73,
    "out:Primary Energy": 116.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 41.58,
    "out:Total CO2 (tons)": 130.45,
    "out:Klimatpaverkan": -69.24,
    "out:Initial Cost/MSEK": 1.326,
    "out:Running cost/(Apt SEK y)": 44927,
    "out:Running Cost over RSP/MSEK": 10.199,
    "out:LCP/MSEK": 0.164,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 192,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 53329,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100129,
    "out:DH kr savings": 48529,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 112.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 39.78,
    "out:Total CO2 (tons)": 124.8,
    "out:Klimatpaverkan": -74.89,
    "out:Initial Cost/MSEK": 1.404375,
    "out:Running cost/(Apt SEK y)": 43505,
    "out:Running Cost over RSP/MSEK": 9.877,
    "out:LCP/MSEK": 0.408,
    "out:ROI% old": 18.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114403,
    "out:DH kr savings": 62803,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 176.9,
    "out:Total Energy Use Post PV": 170.88,
    "out:Primary Energy": 117.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.98,
    "out:CO2 Operational/m2": -23.62,
    "out:Total CO2/m2": 3.36,
    "out:Total CO2 (tons)": 10.53,
    "out:Klimatpaverkan": -189.16,
    "out:Initial Cost/MSEK": 1.819125,
    "out:Running cost/(Apt SEK y)": 45336,
    "out:Running Cost over RSP/MSEK": 10.302,
    "out:LCP/MSEK": -0.433,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 180,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 31370,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125983,
    "out:DH kr savings": 28547,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 171.9,
    "out:Total Energy Use Post PV": 165.88,
    "out:Primary Energy": 113.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.98,
    "out:CO2 Operational/m2": -25.42,
    "out:Total CO2/m2": 1.56,
    "out:Total CO2 (tons)": 4.89,
    "out:Klimatpaverkan": -194.8,
    "out:Initial Cost/MSEK": 1.897625,
    "out:Running cost/(Apt SEK y)": 43915,
    "out:Running Cost over RSP/MSEK": 9.98,
    "out:LCP/MSEK": -0.189,
    "out:ROI% old": 12.87,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 12.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 221,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 47055,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140257,
    "out:DH kr savings": 42820,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 163.88,
    "out:Primary Energy": 114.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.4,
    "out:CO2 Operational/m2": -26.14,
    "out:Total CO2/m2": 1.26,
    "out:Total CO2 (tons)": 3.94,
    "out:Klimatpaverkan": -195.75,
    "out:Initial Cost/MSEK": 1.9785,
    "out:Running cost/(Apt SEK y)": 43346,
    "out:Running Cost over RSP/MSEK": 9.851,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 13.27,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 53329,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145966,
    "out:DH kr savings": 48529,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 111.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.4,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": -0.54,
    "out:Total CO2 (tons)": -1.7,
    "out:Klimatpaverkan": -201.39,
    "out:Initial Cost/MSEK": 2.057,
    "out:Running cost/(Apt SEK y)": 41925,
    "out:Running Cost over RSP/MSEK": 9.528,
    "out:LCP/MSEK": 0.104,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160239,
    "out:DH kr savings": 62803,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 124.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.27,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 59.09,
    "out:Total CO2 (tons)": 185.35,
    "out:Klimatpaverkan": -14.34,
    "out:Initial Cost/MSEK": 0.590643,
    "out:Running cost/(Apt SEK y)": 46137,
    "out:Running Cost over RSP/MSEK": 10.463,
    "out:LCP/MSEK": 0.635,
    "out:ROI% old": 29.65,
    "out:Payback discounted": 4,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 27,
    "out:Return/kSEK/y": 157,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54239,
    "out:DH kr savings": 54239,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 121.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.27,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 57.29,
    "out:Total CO2 (tons)": 179.71,
    "out:Klimatpaverkan": -19.98,
    "out:Initial Cost/MSEK": 0.669068,
    "out:Running cost/(Apt SEK y)": 44716,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 33.06,
    "out:Payback discounted": 4,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 30,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68512,
    "out:DH kr savings": 68512,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 122.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.69,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 57.71,
    "out:Total CO2 (tons)": 181.02,
    "out:Klimatpaverkan": -18.67,
    "out:Initial Cost/MSEK": 0.750019,
    "out:Running cost/(Apt SEK y)": 44716,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": 0.799,
    "out:ROI% old": 29.5,
    "out:Payback discounted": 4,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68512,
    "out:DH kr savings": 68512,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 119.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.69,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 55.91,
    "out:Total CO2 (tons)": 175.38,
    "out:Klimatpaverkan": -24.31,
    "out:Initial Cost/MSEK": 0.828444,
    "out:Running cost/(Apt SEK y)": 43294,
    "out:Running Cost over RSP/MSEK": 9.818,
    "out:LCP/MSEK": 1.043,
    "out:ROI% old": 32.27,
    "out:Payback discounted": 4,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 29,
    "out:Return/kSEK/y": 239,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82785,
    "out:DH kr savings": 82785,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 115.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 40.44,
    "out:Total CO2 (tons)": 126.87,
    "out:Klimatpaverkan": -72.82,
    "out:Initial Cost/MSEK": 1.243173,
    "out:Running cost/(Apt SEK y)": 44358,
    "out:Running Cost over RSP/MSEK": 10.07,
    "out:LCP/MSEK": 0.375,
    "out:ROI% old": 18.6,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105839,
    "out:DH kr savings": 54239,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 111.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.62,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 38.64,
    "out:Total CO2 (tons)": 121.23,
    "out:Klimatpaverkan": -78.46,
    "out:Initial Cost/MSEK": 1.321625,
    "out:Running cost/(Apt SEK y)": 42937,
    "out:Running Cost over RSP/MSEK": 9.747,
    "out:LCP/MSEK": 0.62,
    "out:ROI% old": 20.98,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120112,
    "out:DH kr savings": 68512,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 113.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 39.06,
    "out:Total CO2 (tons)": 122.54,
    "out:Klimatpaverkan": -77.15,
    "out:Initial Cost/MSEK": 1.4025,
    "out:Running cost/(Apt SEK y)": 42937,
    "out:Running Cost over RSP/MSEK": 9.747,
    "out:LCP/MSEK": 0.539,
    "out:ROI% old": 19.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120112,
    "out:DH kr savings": 68512,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 109.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.04,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 37.26,
    "out:Total CO2 (tons)": 116.9,
    "out:Klimatpaverkan": -82.79,
    "out:Initial Cost/MSEK": 1.481,
    "out:Running cost/(Apt SEK y)": 41515,
    "out:Running Cost over RSP/MSEK": 9.425,
    "out:LCP/MSEK": 0.783,
    "out:ROI% old": 21.84,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134385,
    "out:DH kr savings": 82785,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 113.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.98,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": 0.12,
    "out:Total CO2 (tons)": 0.37,
    "out:Klimatpaverkan": -199.32,
    "out:Initial Cost/MSEK": 1.89575,
    "out:Running cost/(Apt SEK y)": 42777,
    "out:Running Cost over RSP/MSEK": 9.722,
    "out:LCP/MSEK": 0.071,
    "out:ROI% old": 14.82,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 59603,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151675,
    "out:DH kr savings": 54239,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 110.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.98,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": -1.68,
    "out:Total CO2 (tons)": -5.28,
    "out:Klimatpaverkan": -204.97,
    "out:Initial Cost/MSEK": 1.974125,
    "out:Running cost/(Apt SEK y)": 41356,
    "out:Running Cost over RSP/MSEK": 9.399,
    "out:LCP/MSEK": 0.316,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165949,
    "out:DH kr savings": 68512,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.2,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 111.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.4,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": -1.26,
    "out:Total CO2 (tons)": -3.96,
    "out:Klimatpaverkan": -203.65,
    "out:Initial Cost/MSEK": 2.055125,
    "out:Running cost/(Apt SEK y)": 41356,
    "out:Running Cost over RSP/MSEK": 9.399,
    "out:LCP/MSEK": 0.235,
    "out:ROI% old": 15.92,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165949,
    "out:DH kr savings": 68512,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 367667,
    "out:% savings (space heating)": 8.34,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 112.15,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 108.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.4,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": -3.06,
    "out:Total CO2 (tons)": -9.61,
    "out:Klimatpaverkan": -209.3,
    "out:Initial Cost/MSEK": 2.1335,
    "out:Running cost/(Apt SEK y)": 39934,
    "out:Running Cost over RSP/MSEK": 9.076,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180222,
    "out:DH kr savings": 82785,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 351811,
    "out:% savings (space heating)": 12.29,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.72,
    "out:Electricity (inc PV)": 42.62,
    "out:Total Energy Use Pre PV": 117.72,
    "out:Total Energy Use Post PV": 115.62,
    "out:Primary Energy": 119.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.08,
    "out:CO2 Operational/m2": 32.18,
    "out:Total CO2/m2": 38.26,
    "out:Total CO2 (tons)": 120.02,
    "out:Klimatpaverkan": -79.67,
    "out:Initial Cost/MSEK": 3.2365,
    "out:Running cost/(Apt SEK y)": 28977,
    "out:Running Cost over RSP/MSEK": 6.554,
    "out:LCP/MSEK": 1.898,
    "out:ROI% old": 22.67,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 3130.4,
    "out:Return %": 20,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 307426,
    "out:EL kWh savings": -83814,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137273,
    "out:DH kr savings": 279758,
    "out:El kr savings": -142485,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 229587,
    "out:% savings (space heating)": 42.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.62,
    "out:Etvv": 0,
    "out:Ef": 42.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 42.51,
    "out:Total Energy Use Pre PV": 112.65,
    "out:Total Energy Use Post PV": 110.51,
    "out:Primary Energy": 116.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.08,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 36.45,
    "out:Total CO2 (tons)": 114.35,
    "out:Klimatpaverkan": -85.34,
    "out:Initial Cost/MSEK": 3.314875,
    "out:Running cost/(Apt SEK y)": 27543,
    "out:Running Cost over RSP/MSEK": 6.228,
    "out:LCP/MSEK": 2.146,
    "out:ROI% old": 23.53,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 3125.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -83461,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152147,
    "out:DH kr savings": 294031,
    "out:El kr savings": -141884,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 214437,
    "out:% savings (space heating)": 46.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.94,
    "out:Etvv": 0,
    "out:Ef": 42.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 42.16,
    "out:Total Energy Use Pre PV": 113.01,
    "out:Total Energy Use Post PV": 111.16,
    "out:Primary Energy": 117.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.5,
    "out:CO2 Operational/m2": 30.65,
    "out:Total CO2/m2": 37.15,
    "out:Total CO2 (tons)": 116.53,
    "out:Klimatpaverkan": -83.16,
    "out:Initial Cost/MSEK": 3.395875,
    "out:Running cost/(Apt SEK y)": 27710,
    "out:Running Cost over RSP/MSEK": 6.266,
    "out:LCP/MSEK": 2.026,
    "out:ROI% old": 22.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 3080.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 691,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -82370,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151147,
    "out:DH kr savings": 291176,
    "out:El kr savings": -140029,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 216455,
    "out:% savings (space heating)": 46.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 59.39,
    "out:Etvv": 0,
    "out:Ef": 42.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.93,
    "out:Electricity (inc PV)": 42.04,
    "out:Total Energy Use Pre PV": 107.93,
    "out:Total Energy Use Post PV": 106.04,
    "out:Primary Energy": 113.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.5,
    "out:CO2 Operational/m2": 28.84,
    "out:Total CO2/m2": 35.34,
    "out:Total CO2 (tons)": 110.85,
    "out:Klimatpaverkan": -88.84,
    "out:Initial Cost/MSEK": 3.47425,
    "out:Running cost/(Apt SEK y)": 26273,
    "out:Running Cost over RSP/MSEK": 5.94,
    "out:LCP/MSEK": 2.274,
    "out:ROI% old": 23.64,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 3075,
    "out:Return %": 21,
    "out:Return/kSEK/y": 733,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -81986,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166074,
    "out:DH kr savings": 305450,
    "out:El kr savings": -139376,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 201455,
    "out:% savings (space heating)": 49.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.74,
    "out:Etvv": 0,
    "out:Ef": 42.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.72,
    "out:Electricity (inc PV)": 33.16,
    "out:Total Energy Use Pre PV": 117.72,
    "out:Total Energy Use Post PV": 106.16,
    "out:Primary Energy": 102.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.43,
    "out:CO2 Operational/m2": 23.58,
    "out:Total CO2/m2": 42.01,
    "out:Total CO2 (tons)": 131.8,
    "out:Klimatpaverkan": -67.89,
    "out:Initial Cost/MSEK": 3.889,
    "out:Running cost/(Apt SEK y)": 26991,
    "out:Running Cost over RSP/MSEK": 6.115,
    "out:LCP/MSEK": 1.684,
    "out:ROI% old": 20.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2479.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4726.93,
    "out:PV (% sold (El))": 13.48,
    "out:PV (kWh self-consumed)": 30335.78,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 307426,
    "out:EL kWh savings": -54132,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193760,
    "out:DH kr savings": 279758,
    "out:El kr savings": -92024,
    "out:El kr sold": 6027,
    "out:El kr saved": 51571,
    "out:El kr return": 57598,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 229587,
    "out:% savings (space heating)": 42.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.62,
    "out:Etvv": 0,
    "out:Ef": 33.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 33.05,
    "out:Total Energy Use Pre PV": 112.65,
    "out:Total Energy Use Post PV": 101.05,
    "out:Primary Energy": 99.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.43,
    "out:CO2 Operational/m2": 21.74,
    "out:Total CO2/m2": 40.17,
    "out:Total CO2 (tons)": 126.02,
    "out:Klimatpaverkan": -73.67,
    "out:Initial Cost/MSEK": 3.9675,
    "out:Running cost/(Apt SEK y)": 25558,
    "out:Running Cost over RSP/MSEK": 5.79,
    "out:LCP/MSEK": 1.931,
    "out:ROI% old": 21.24,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2475.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4747.27,
    "out:PV (% sold (El))": 13.54,
    "out:PV (kWh self-consumed)": 30315.44,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -53815,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208599,
    "out:DH kr savings": 294031,
    "out:El kr savings": -91485,
    "out:El kr sold": 6053,
    "out:El kr saved": 51536,
    "out:El kr return": 57589,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 214437,
    "out:% savings (space heating)": 46.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.94,
    "out:Etvv": 0,
    "out:Ef": 33.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 32.74,
    "out:Total Energy Use Pre PV": 113.01,
    "out:Total Energy Use Post PV": 101.74,
    "out:Primary Energy": 100.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.85,
    "out:CO2 Operational/m2": 21.71,
    "out:Total CO2/m2": 40.57,
    "out:Total CO2 (tons)": 127.25,
    "out:Klimatpaverkan": -72.44,
    "out:Initial Cost/MSEK": 4.048375,
    "out:Running cost/(Apt SEK y)": 25727,
    "out:Running Cost over RSP/MSEK": 5.829,
    "out:LCP/MSEK": 1.812,
    "out:ROI% old": 20.68,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2434.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4941.36,
    "out:PV (% sold (El))": 14.09,
    "out:PV (kWh self-consumed)": 30121.35,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -52837,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207654,
    "out:DH kr savings": 291176,
    "out:El kr savings": -89823,
    "out:El kr sold": 6300,
    "out:El kr saved": 51206,
    "out:El kr return": 57507,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 216455,
    "out:% savings (space heating)": 46.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 59.39,
    "out:Etvv": 0,
    "out:Ef": 32.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.93,
    "out:Electricity (inc PV)": 32.63,
    "out:Total Energy Use Pre PV": 107.93,
    "out:Total Energy Use Post PV": 96.63,
    "out:Primary Energy": 97.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.85,
    "out:CO2 Operational/m2": 19.86,
    "out:Total CO2/m2": 38.72,
    "out:Total CO2 (tons)": 121.45,
    "out:Klimatpaverkan": -78.24,
    "out:Initial Cost/MSEK": 4.126875,
    "out:Running cost/(Apt SEK y)": 24291,
    "out:Running Cost over RSP/MSEK": 5.503,
    "out:LCP/MSEK": 2.059,
    "out:ROI% old": 21.41,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2429.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4966.96,
    "out:PV (% sold (El))": 14.17,
    "out:PV (kWh self-consumed)": 30095.75,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -52493,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222545,
    "out:DH kr savings": 305450,
    "out:El kr savings": -89237,
    "out:El kr sold": 6333,
    "out:El kr saved": 51163,
    "out:El kr return": 57496,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 201455,
    "out:% savings (space heating)": 49.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.74,
    "out:Etvv": 0,
    "out:Ef": 32.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.72,
    "out:Electricity (inc PV)": 29.96,
    "out:Total Energy Use Pre PV": 117.72,
    "out:Total Energy Use Post PV": 102.96,
    "out:Primary Energy": 97.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.79,
    "out:CO2 Operational/m2": -16.21,
    "out:Total CO2/m2": 14.58,
    "out:Total CO2 (tons)": 45.72,
    "out:Klimatpaverkan": -153.97,
    "out:Initial Cost/MSEK": 4.5415,
    "out:Running cost/(Apt SEK y)": 25294,
    "out:Running Cost over RSP/MSEK": 5.741,
    "out:LCP/MSEK": 1.407,
    "out:ROI% old": 18.71,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 2237.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 761,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29150.54,
    "out:PV (% sold (El))": 41.57,
    "out:PV (kWh self-consumed)": 40974.88,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 307426,
    "out:EL kWh savings": -44113,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241933,
    "out:DH kr savings": 279758,
    "out:El kr savings": -74992,
    "out:El kr sold": 37167,
    "out:El kr saved": 69657,
    "out:El kr return": 106824,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 229587,
    "out:% savings (space heating)": 42.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 61.62,
    "out:Etvv": 0,
    "out:Ef": 29.96,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.65,
    "out:Electricity (inc PV)": 29.87,
    "out:Total Energy Use Pre PV": 112.65,
    "out:Total Energy Use Post PV": 97.87,
    "out:Primary Energy": 93.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.79,
    "out:CO2 Operational/m2": -18.08,
    "out:Total CO2/m2": 12.7,
    "out:Total CO2 (tons)": 39.84,
    "out:Klimatpaverkan": -159.85,
    "out:Initial Cost/MSEK": 4.62,
    "out:Running cost/(Apt SEK y)": 23860,
    "out:Running Cost over RSP/MSEK": 5.415,
    "out:LCP/MSEK": 1.653,
    "out:ROI% old": 19.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2234,
    "out:Return %": 17,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29191.33,
    "out:PV (% sold (El))": 41.63,
    "out:PV (kWh self-consumed)": 40934.08,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -43828,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256742,
    "out:DH kr savings": 294031,
    "out:El kr savings": -74508,
    "out:El kr sold": 37219,
    "out:El kr saved": 69588,
    "out:El kr return": 106807,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 214437,
    "out:% savings (space heating)": 46.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.94,
    "out:Etvv": 0,
    "out:Ef": 29.87,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.01,
    "out:Electricity (inc PV)": 29.59,
    "out:Total Energy Use Pre PV": 113.01,
    "out:Total Energy Use Post PV": 98.59,
    "out:Primary Energy": 94.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.2,
    "out:CO2 Operational/m2": -18.42,
    "out:Total CO2/m2": 12.78,
    "out:Total CO2 (tons)": 40.1,
    "out:Klimatpaverkan": -159.59,
    "out:Initial Cost/MSEK": 4.700875,
    "out:Running cost/(Apt SEK y)": 24033,
    "out:Running Cost over RSP/MSEK": 5.455,
    "out:LCP/MSEK": 1.533,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2197.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29575.39,
    "out:PV (% sold (El))": 42.17,
    "out:PV (kWh self-consumed)": 40550.03,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 319974,
    "out:EL kWh savings": -42952,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255866,
    "out:DH kr savings": 291176,
    "out:El kr savings": -73019,
    "out:El kr sold": 37709,
    "out:El kr saved": 68935,
    "out:El kr return": 106644,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 216455,
    "out:% savings (space heating)": 46.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 59.39,
    "out:Etvv": 0,
    "out:Ef": 29.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.93,
    "out:Electricity (inc PV)": 29.49,
    "out:Total Energy Use Pre PV": 107.93,
    "out:Total Energy Use Post PV": 93.49,
    "out:Primary Energy": 91.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.2,
    "out:CO2 Operational/m2": -20.31,
    "out:Total CO2/m2": 10.89,
    "out:Total CO2 (tons)": 34.17,
    "out:Klimatpaverkan": -165.52,
    "out:Initial Cost/MSEK": 4.779375,
    "out:Running cost/(Apt SEK y)": 22597,
    "out:Running Cost over RSP/MSEK": 5.129,
    "out:LCP/MSEK": 1.781,
    "out:ROI% old": 19.61,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2192.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29625.37,
    "out:PV (% sold (El))": 42.25,
    "out:PV (kWh self-consumed)": 40500.05,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -42644,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270728,
    "out:DH kr savings": 305450,
    "out:El kr savings": -72494,
    "out:El kr sold": 37772,
    "out:El kr saved": 68850,
    "out:El kr return": 106622,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 201455,
    "out:% savings (space heating)": 49.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.74,
    "out:Etvv": 0,
    "out:Ef": 29.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 42,
    "out:Total Energy Use Pre PV": 111.77,
    "out:Total Energy Use Post PV": 110,
    "out:Primary Energy": 116.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.08,
    "out:CO2 Operational/m2": 30.26,
    "out:Total CO2/m2": 36.34,
    "out:Total CO2 (tons)": 113.98,
    "out:Klimatpaverkan": -85.71,
    "out:Initial Cost/MSEK": 3.313,
    "out:Running cost/(Apt SEK y)": 27381,
    "out:Running Cost over RSP/MSEK": 6.192,
    "out:LCP/MSEK": 2.184,
    "out:ROI% old": 23.7,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 3063.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 701,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -81881,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154833,
    "out:DH kr savings": 294031,
    "out:El kr savings": -139198,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 212159,
    "out:% savings (space heating)": 47.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.66,
    "out:Etvv": 0,
    "out:Ef": 42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.68,
    "out:Electricity (inc PV)": 41.88,
    "out:Total Energy Use Pre PV": 106.68,
    "out:Total Energy Use Post PV": 104.88,
    "out:Primary Energy": 113.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.08,
    "out:CO2 Operational/m2": 28.44,
    "out:Total CO2/m2": 34.52,
    "out:Total CO2 (tons)": 108.3,
    "out:Klimatpaverkan": -91.39,
    "out:Initial Cost/MSEK": 3.3915,
    "out:Running cost/(Apt SEK y)": 25943,
    "out:Running Cost over RSP/MSEK": 5.866,
    "out:LCP/MSEK": 2.432,
    "out:ROI% old": 24.53,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 3057.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 742,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.48,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 338796,
    "out:EL kWh savings": -81487,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169776,
    "out:DH kr savings": 308304,
    "out:El kr savings": -138528,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 197214,
    "out:% savings (space heating)": 50.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.02,
    "out:Etvv": 0,
    "out:Ef": 41.88,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 41.62,
    "out:Total Energy Use Pre PV": 107.16,
    "out:Total Energy Use Post PV": 105.62,
    "out:Primary Energy": 114.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.5,
    "out:CO2 Operational/m2": 28.74,
    "out:Total CO2/m2": 35.24,
    "out:Total CO2 (tons)": 110.53,
    "out:Klimatpaverkan": -89.16,
    "out:Initial Cost/MSEK": 3.472375,
    "out:Running cost/(Apt SEK y)": 26133,
    "out:Running Cost over RSP/MSEK": 5.909,
    "out:LCP/MSEK": 2.307,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 3021.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -80693,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168272,
    "out:DH kr savings": 305450,
    "out:El kr savings": -137178,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202020,
    "out:% savings (space heating)": 49.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 41.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 41.49,
    "out:Total Energy Use Pre PV": 103.06,
    "out:Total Energy Use Post PV": 101.49,
    "out:Primary Energy": 111.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.5,
    "out:CO2 Operational/m2": 27.28,
    "out:Total CO2/m2": 33.78,
    "out:Total CO2 (tons)": 105.97,
    "out:Klimatpaverkan": -93.72,
    "out:Initial Cost/MSEK": 3.550875,
    "out:Running cost/(Apt SEK y)": 24977,
    "out:Running Cost over RSP/MSEK": 5.647,
    "out:LCP/MSEK": 2.491,
    "out:ROI% old": 24.31,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 3014.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -80276,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180400,
    "out:DH kr savings": 316868,
    "out:El kr savings": -136468,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187204,
    "out:% savings (space heating)": 53.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.29,
    "out:Etvv": 0,
    "out:Ef": 41.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 32.6,
    "out:Total Energy Use Pre PV": 111.77,
    "out:Total Energy Use Post PV": 100.6,
    "out:Primary Energy": 99.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.43,
    "out:CO2 Operational/m2": 21.2,
    "out:Total CO2/m2": 39.64,
    "out:Total CO2 (tons)": 124.34,
    "out:Klimatpaverkan": -75.35,
    "out:Initial Cost/MSEK": 3.9655,
    "out:Running cost/(Apt SEK y)": 25399,
    "out:Running Cost over RSP/MSEK": 5.754,
    "out:LCP/MSEK": 1.969,
    "out:ROI% old": 21.38,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2418.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5016.93,
    "out:PV (% sold (El))": 14.31,
    "out:PV (kWh self-consumed)": 30045.78,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -52399,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211350,
    "out:DH kr savings": 294031,
    "out:El kr savings": -89078,
    "out:El kr sold": 6397,
    "out:El kr saved": 51078,
    "out:El kr return": 57474,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 212159,
    "out:% savings (space heating)": 47.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.66,
    "out:Etvv": 0,
    "out:Ef": 32.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.68,
    "out:Electricity (inc PV)": 32.49,
    "out:Total Energy Use Pre PV": 106.68,
    "out:Total Energy Use Post PV": 95.49,
    "out:Primary Energy": 96.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.43,
    "out:CO2 Operational/m2": 19.35,
    "out:Total CO2/m2": 37.78,
    "out:Total CO2 (tons)": 118.52,
    "out:Klimatpaverkan": -81.17,
    "out:Initial Cost/MSEK": 4.044,
    "out:Running cost/(Apt SEK y)": 23962,
    "out:Running Cost over RSP/MSEK": 5.428,
    "out:LCP/MSEK": 2.216,
    "out:ROI% old": 22.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2413.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.48,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5044.36,
    "out:PV (% sold (El))": 14.39,
    "out:PV (kWh self-consumed)": 30018.35,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338796,
    "out:EL kWh savings": -52046,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226258,
    "out:DH kr savings": 308304,
    "out:El kr savings": -88478,
    "out:El kr sold": 6432,
    "out:El kr saved": 51031,
    "out:El kr return": 57463,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 197214,
    "out:% savings (space heating)": 50.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.02,
    "out:Etvv": 0,
    "out:Ef": 32.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 32.26,
    "out:Total Energy Use Pre PV": 107.16,
    "out:Total Energy Use Post PV": 96.26,
    "out:Primary Energy": 97.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.85,
    "out:CO2 Operational/m2": 19.39,
    "out:Total CO2/m2": 38.24,
    "out:Total CO2 (tons)": 119.96,
    "out:Klimatpaverkan": -79.73,
    "out:Initial Cost/MSEK": 4.124875,
    "out:Running cost/(Apt SEK y)": 24154,
    "out:Running Cost over RSP/MSEK": 5.472,
    "out:LCP/MSEK": 2.092,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2380.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5205.97,
    "out:PV (% sold (El))": 14.85,
    "out:PV (kWh self-consumed)": 29856.74,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -51335,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224817,
    "out:DH kr savings": 305450,
    "out:El kr savings": -87270,
    "out:El kr sold": 6638,
    "out:El kr saved": 50756,
    "out:El kr return": 57394,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202020,
    "out:% savings (space heating)": 49.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 32.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 32.15,
    "out:Total Energy Use Pre PV": 103.06,
    "out:Total Energy Use Post PV": 92.15,
    "out:Primary Energy": 94.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.85,
    "out:CO2 Operational/m2": 17.89,
    "out:Total CO2/m2": 36.74,
    "out:Total CO2 (tons)": 115.25,
    "out:Klimatpaverkan": -84.44,
    "out:Initial Cost/MSEK": 4.203375,
    "out:Running cost/(Apt SEK y)": 22998,
    "out:Running Cost over RSP/MSEK": 5.21,
    "out:LCP/MSEK": 2.275,
    "out:ROI% old": 22.02,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2373.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5237.84,
    "out:PV (% sold (El))": 14.94,
    "out:PV (kWh self-consumed)": 29824.87,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -50962,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236911,
    "out:DH kr savings": 316868,
    "out:El kr savings": -86636,
    "out:El kr sold": 6678,
    "out:El kr saved": 50702,
    "out:El kr return": 57381,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187204,
    "out:% savings (space heating)": 53.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.29,
    "out:Etvv": 0,
    "out:Ef": 32.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.77,
    "out:Electricity (inc PV)": 29.47,
    "out:Total Energy Use Pre PV": 111.77,
    "out:Total Energy Use Post PV": 97.47,
    "out:Primary Energy": 94.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.79,
    "out:CO2 Operational/m2": -19.05,
    "out:Total CO2/m2": 11.74,
    "out:Total CO2 (tons)": 36.81,
    "out:Klimatpaverkan": -162.88,
    "out:Initial Cost/MSEK": 4.618125,
    "out:Running cost/(Apt SEK y)": 23706,
    "out:Running Cost over RSP/MSEK": 5.381,
    "out:LCP/MSEK": 1.69,
    "out:ROI% old": 19.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2183.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29722.89,
    "out:PV (% sold (El))": 42.39,
    "out:PV (kWh self-consumed)": 40402.53,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 323111,
    "out:EL kWh savings": -42559,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259577,
    "out:DH kr savings": 294031,
    "out:El kr savings": -72351,
    "out:El kr sold": 37897,
    "out:El kr saved": 68684,
    "out:El kr return": 106581,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 212159,
    "out:% savings (space heating)": 47.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.66,
    "out:Etvv": 0,
    "out:Ef": 29.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.68,
    "out:Electricity (inc PV)": 29.37,
    "out:Total Energy Use Pre PV": 106.68,
    "out:Total Energy Use Post PV": 92.37,
    "out:Primary Energy": 90.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.79,
    "out:CO2 Operational/m2": -20.95,
    "out:Total CO2/m2": 9.84,
    "out:Total CO2 (tons)": 30.86,
    "out:Klimatpaverkan": -168.83,
    "out:Initial Cost/MSEK": 4.6965,
    "out:Running cost/(Apt SEK y)": 22269,
    "out:Running Cost over RSP/MSEK": 5.054,
    "out:LCP/MSEK": 1.938,
    "out:ROI% old": 20.18,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2178.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.48,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29776.23,
    "out:PV (% sold (El))": 42.46,
    "out:PV (kWh self-consumed)": 40349.19,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338796,
    "out:EL kWh savings": -42243,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274455,
    "out:DH kr savings": 308304,
    "out:El kr savings": -71814,
    "out:El kr sold": 37965,
    "out:El kr saved": 68594,
    "out:El kr return": 106558,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 197214,
    "out:% savings (space heating)": 50.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.02,
    "out:Etvv": 0,
    "out:Ef": 29.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 29.16,
    "out:Total Energy Use Pre PV": 107.16,
    "out:Total Energy Use Post PV": 93.16,
    "out:Primary Energy": 92.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.2,
    "out:CO2 Operational/m2": -21.15,
    "out:Total CO2/m2": 10.05,
    "out:Total CO2 (tons)": 31.53,
    "out:Klimatpaverkan": -168.16,
    "out:Initial Cost/MSEK": 4.7775,
    "out:Running cost/(Apt SEK y)": 22463,
    "out:Running Cost over RSP/MSEK": 5.099,
    "out:LCP/MSEK": 1.812,
    "out:ROI% old": 19.71,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2149.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30088.85,
    "out:PV (% sold (El))": 42.91,
    "out:PV (kWh self-consumed)": 40036.57,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 335659,
    "out:EL kWh savings": -41607,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273082,
    "out:DH kr savings": 305450,
    "out:El kr savings": -70731,
    "out:El kr sold": 38363,
    "out:El kr saved": 68062,
    "out:El kr return": 106425,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202020,
    "out:% savings (space heating)": 49.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.91,
    "out:Etvv": 0,
    "out:Ef": 29.16,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 29.06,
    "out:Total Energy Use Pre PV": 103.06,
    "out:Total Energy Use Post PV": 89.06,
    "out:Primary Energy": 88.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.2,
    "out:CO2 Operational/m2": -22.7,
    "out:Total CO2/m2": 8.5,
    "out:Total CO2 (tons)": 26.67,
    "out:Klimatpaverkan": -173.02,
    "out:Initial Cost/MSEK": 4.855875,
    "out:Running cost/(Apt SEK y)": 21308,
    "out:Running Cost over RSP/MSEK": 4.837,
    "out:LCP/MSEK": 1.996,
    "out:ROI% old": 20.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2143.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30150.28,
    "out:PV (% sold (El))": 42.99,
    "out:PV (kWh self-consumed)": 39975.13,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -41272,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285147,
    "out:DH kr savings": 316868,
    "out:El kr savings": -70163,
    "out:El kr sold": 38442,
    "out:El kr saved": 67958,
    "out:El kr return": 106399,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187204,
    "out:% savings (space heating)": 53.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.29,
    "out:Etvv": 0,
    "out:Ef": 29.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.15,
    "out:Electricity (inc PV)": 58.15,
    "out:Total Energy Use Pre PV": 78.15,
    "out:Total Energy Use Post PV": 74.15,
    "out:Primary Energy": 111.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.72,
    "out:CO2 Operational/m2": 13.96,
    "out:Total CO2/m2": 20.69,
    "out:Total CO2 (tons)": 64.9,
    "out:Klimatpaverkan": -134.79,
    "out:Initial Cost/MSEK": 5.092875,
    "out:Running cost/(Apt SEK y)": 15978,
    "out:Running Cost over RSP/MSEK": 3.595,
    "out:LCP/MSEK": 3.001,
    "out:ROI% old": 22.7,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4350.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -132524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217182,
    "out:DH kr savings": 442474,
    "out:El kr savings": -225291,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 49127,
    "out:% savings (space heating)": 87.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.38,
    "out:Etvv": 0,
    "out:Ef": 58.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.54,
    "out:Electricity (inc PV)": 57.36,
    "out:Total Energy Use Pre PV": 74.54,
    "out:Total Energy Use Post PV": 70.36,
    "out:Primary Energy": 108.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.72,
    "out:CO2 Operational/m2": 12.8,
    "out:Total CO2/m2": 19.53,
    "out:Total CO2 (tons)": 61.25,
    "out:Klimatpaverkan": -138.44,
    "out:Initial Cost/MSEK": 5.17125,
    "out:Running cost/(Apt SEK y)": 15013,
    "out:Running Cost over RSP/MSEK": 3.376,
    "out:LCP/MSEK": 3.142,
    "out:ROI% old": 22.96,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4307.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1059,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -130073,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229913,
    "out:DH kr savings": 451038,
    "out:El kr savings": -221125,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 39607,
    "out:% savings (space heating)": 90.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 57.36,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.78,
    "out:Electricity (inc PV)": 57.29,
    "out:Total Energy Use Pre PV": 74.78,
    "out:Total Energy Use Post PV": 71.29,
    "out:Primary Energy": 109.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.14,
    "out:CO2 Operational/m2": 13.06,
    "out:Total CO2/m2": 20.21,
    "out:Total CO2 (tons)": 63.38,
    "out:Klimatpaverkan": -136.31,
    "out:Initial Cost/MSEK": 5.25225,
    "out:Running cost/(Apt SEK y)": 15157,
    "out:Running Cost over RSP/MSEK": 3.409,
    "out:LCP/MSEK": 3.027,
    "out:ROI% old": 22.52,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4254.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -129837,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227460,
    "out:DH kr savings": 448183,
    "out:El kr savings": -220723,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42855,
    "out:% savings (space heating)": 89.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.46,
    "out:Etvv": 0,
    "out:Ef": 57.29,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 56.47,
    "out:Total Energy Use Pre PV": 71.11,
    "out:Total Energy Use Post PV": 67.47,
    "out:Primary Energy": 106.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.14,
    "out:CO2 Operational/m2": 11.9,
    "out:Total CO2/m2": 19.04,
    "out:Total CO2 (tons)": 59.72,
    "out:Klimatpaverkan": -139.97,
    "out:Initial Cost/MSEK": 5.330625,
    "out:Running cost/(Apt SEK y)": 14181,
    "out:Running Cost over RSP/MSEK": 3.188,
    "out:LCP/MSEK": 3.17,
    "out:ROI% old": 22.78,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4207.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1083,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -127255,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240413,
    "out:DH kr savings": 456747,
    "out:El kr savings": -216334,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33920,
    "out:% savings (space heating)": 91.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 56.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.15,
    "out:Electricity (inc PV)": 47.52,
    "out:Total Energy Use Pre PV": 78.15,
    "out:Total Energy Use Post PV": 63.52,
    "out:Primary Energy": 92.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.08,
    "out:CO2 Operational/m2": 10.9,
    "out:Total CO2/m2": 29.98,
    "out:Total CO2 (tons)": 94.03,
    "out:Klimatpaverkan": -105.66,
    "out:Initial Cost/MSEK": 5.745375,
    "out:Running cost/(Apt SEK y)": 13939,
    "out:Running Cost over RSP/MSEK": 3.145,
    "out:LCP/MSEK": 2.798,
    "out:ROI% old": 21.24,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3633.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1090,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1140.76,
    "out:PV (% sold (El))": 3.25,
    "out:PV (kWh self-consumed)": 33921.95,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -99178,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275325,
    "out:DH kr savings": 442474,
    "out:El kr savings": -168603,
    "out:El kr sold": 1454,
    "out:El kr saved": 57667,
    "out:El kr return": 59122,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 49127,
    "out:% savings (space heating)": 87.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.38,
    "out:Etvv": 0,
    "out:Ef": 47.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.54,
    "out:Electricity (inc PV)": 46.77,
    "out:Total Energy Use Pre PV": 74.54,
    "out:Total Energy Use Post PV": 59.77,
    "out:Primary Energy": 89.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.08,
    "out:CO2 Operational/m2": 9.62,
    "out:Total CO2/m2": 28.7,
    "out:Total CO2 (tons)": 90.02,
    "out:Klimatpaverkan": -109.67,
    "out:Initial Cost/MSEK": 5.823875,
    "out:Running cost/(Apt SEK y)": 12975,
    "out:Running Cost over RSP/MSEK": 2.927,
    "out:LCP/MSEK": 2.938,
    "out:ROI% old": 21.49,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3592.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1118,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1219.77,
    "out:PV (% sold (El))": 3.48,
    "out:PV (kWh self-consumed)": 33842.94,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -96855,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287940,
    "out:DH kr savings": 451038,
    "out:El kr savings": -164653,
    "out:El kr sold": 1555,
    "out:El kr saved": 57533,
    "out:El kr return": 59088,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 39607,
    "out:% savings (space heating)": 90.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 46.77,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.78,
    "out:Electricity (inc PV)": 46.7,
    "out:Total Energy Use Pre PV": 74.78,
    "out:Total Energy Use Post PV": 60.7,
    "out:Primary Energy": 90.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.5,
    "out:CO2 Operational/m2": 9.73,
    "out:Total CO2/m2": 29.22,
    "out:Total CO2 (tons)": 91.67,
    "out:Klimatpaverkan": -108.02,
    "out:Initial Cost/MSEK": 5.90475,
    "out:Running cost/(Apt SEK y)": 13121,
    "out:Running Cost over RSP/MSEK": 2.96,
    "out:LCP/MSEK": 2.824,
    "out:ROI% old": 21.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3540.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1322.38,
    "out:PV (% sold (El))": 3.77,
    "out:PV (kWh self-consumed)": 33740.33,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -96631,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285596,
    "out:DH kr savings": 448183,
    "out:El kr savings": -164273,
    "out:El kr sold": 1686,
    "out:El kr saved": 57359,
    "out:El kr return": 59045,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42855,
    "out:% savings (space heating)": 89.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.46,
    "out:Etvv": 0,
    "out:Ef": 46.7,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 45.93,
    "out:Total Energy Use Pre PV": 71.11,
    "out:Total Energy Use Post PV": 56.93,
    "out:Primary Energy": 87.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.5,
    "out:CO2 Operational/m2": 8.42,
    "out:Total CO2/m2": 27.91,
    "out:Total CO2 (tons)": 87.56,
    "out:Klimatpaverkan": -112.13,
    "out:Initial Cost/MSEK": 5.98325,
    "out:Running cost/(Apt SEK y)": 12147,
    "out:Running Cost over RSP/MSEK": 2.739,
    "out:LCP/MSEK": 2.966,
    "out:ROI% old": 21.37,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3495.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1142,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1417.44,
    "out:PV (% sold (El))": 4.04,
    "out:PV (kWh self-consumed)": 33645.27,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -94190,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298432,
    "out:DH kr savings": 456747,
    "out:El kr savings": -160123,
    "out:El kr sold": 1807,
    "out:El kr saved": 57197,
    "out:El kr return": 59004,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33920,
    "out:% savings (space heating)": 91.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 45.93,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 62.15,
    "out:Electricity (inc PV)": 42.75,
    "out:Total Energy Use Pre PV": 78.15,
    "out:Total Energy Use Post PV": 58.75,
    "out:Primary Energy": 84.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.43,
    "out:CO2 Operational/m2": -19.65,
    "out:Total CO2/m2": 11.78,
    "out:Total CO2 (tons)": 36.97,
    "out:Klimatpaverkan": -162.72,
    "out:Initial Cost/MSEK": 6.397875,
    "out:Running cost/(Apt SEK y)": 12158,
    "out:Running Cost over RSP/MSEK": 2.751,
    "out:LCP/MSEK": 2.539,
    "out:ROI% old": 19.96,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3261.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1142,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 19863.87,
    "out:PV (% sold (El))": 28.33,
    "out:PV (kWh self-consumed)": 50261.55,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 486235,
    "out:EL kWh savings": -84220,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 324626,
    "out:DH kr savings": 442474,
    "out:El kr savings": -143175,
    "out:El kr sold": 25326,
    "out:El kr saved": 85445,
    "out:El kr return": 110771,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 49127,
    "out:% savings (space heating)": 87.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 10.38,
    "out:Etvv": 0,
    "out:Ef": 42.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.54,
    "out:Electricity (inc PV)": 42.09,
    "out:Total Energy Use Pre PV": 74.54,
    "out:Total Energy Use Post PV": 55.09,
    "out:Primary Energy": 81.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.43,
    "out:CO2 Operational/m2": -21.27,
    "out:Total CO2/m2": 10.16,
    "out:Total CO2 (tons)": 31.87,
    "out:Klimatpaverkan": -167.82,
    "out:Initial Cost/MSEK": 6.476375,
    "out:Running cost/(Apt SEK y)": 11197,
    "out:Running Cost over RSP/MSEK": 2.534,
    "out:LCP/MSEK": 2.679,
    "out:ROI% old": 20.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3224.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1170,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20155.51,
    "out:PV (% sold (El))": 28.74,
    "out:PV (kWh self-consumed)": 49969.9,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -82164,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337058,
    "out:DH kr savings": 451038,
    "out:El kr savings": -139679,
    "out:El kr sold": 25698,
    "out:El kr saved": 84949,
    "out:El kr return": 110647,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 39607,
    "out:% savings (space heating)": 90.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 42.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.78,
    "out:Electricity (inc PV)": 42.03,
    "out:Total Energy Use Pre PV": 74.78,
    "out:Total Energy Use Post PV": 56.03,
    "out:Primary Energy": 82.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": -21.59,
    "out:Total CO2/m2": 10.26,
    "out:Total CO2 (tons)": 32.18,
    "out:Klimatpaverkan": -167.51,
    "out:Initial Cost/MSEK": 6.55725,
    "out:Running cost/(Apt SEK y)": 11347,
    "out:Running Cost over RSP/MSEK": 2.568,
    "out:LCP/MSEK": 2.564,
    "out:ROI% old": 19.87,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3179,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1166,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20521.18,
    "out:PV (% sold (El))": 29.26,
    "out:PV (kWh self-consumed)": 49604.24,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -81966,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335006,
    "out:DH kr savings": 448183,
    "out:El kr savings": -139342,
    "out:El kr sold": 26165,
    "out:El kr saved": 84327,
    "out:El kr return": 110492,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42855,
    "out:% savings (space heating)": 89.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.46,
    "out:Etvv": 0,
    "out:Ef": 42.03,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 41.34,
    "out:Total Energy Use Pre PV": 71.11,
    "out:Total Energy Use Post PV": 52.34,
    "out:Primary Energy": 79.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": -23.27,
    "out:Total CO2/m2": 8.57,
    "out:Total CO2 (tons)": 26.9,
    "out:Klimatpaverkan": -172.79,
    "out:Initial Cost/MSEK": 6.63575,
    "out:Running cost/(Apt SEK y)": 10376,
    "out:Running Cost over RSP/MSEK": 2.348,
    "out:LCP/MSEK": 2.705,
    "out:ROI% old": 20.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3139,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1194,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20846.15,
    "out:PV (% sold (El))": 29.73,
    "out:PV (kWh self-consumed)": 49279.27,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -79803,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 347662,
    "out:DH kr savings": 456747,
    "out:El kr savings": -135665,
    "out:El kr sold": 26579,
    "out:El kr saved": 83775,
    "out:El kr return": 110354,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33920,
    "out:% savings (space heating)": 91.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.12,
    "out:Etvv": 0,
    "out:Ef": 41.34,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 57,
    "out:Total Energy Use Pre PV": 73.31,
    "out:Total Energy Use Post PV": 70,
    "out:Primary Energy": 109.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.72,
    "out:CO2 Operational/m2": 12.64,
    "out:Total CO2/m2": 19.36,
    "out:Total CO2 (tons)": 60.75,
    "out:Klimatpaverkan": -138.94,
    "out:Initial Cost/MSEK": 5.169375,
    "out:Running cost/(Apt SEK y)": 14787,
    "out:Running Cost over RSP/MSEK": 3.325,
    "out:LCP/MSEK": 3.194,
    "out:ROI% old": 23.11,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4221.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1066,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -128931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231856,
    "out:DH kr savings": 451038,
    "out:El kr savings": -219182,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 40873,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.16,
    "out:Etvv": 0,
    "out:Ef": 57,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.63,
    "out:Electricity (inc PV)": 56.16,
    "out:Total Energy Use Pre PV": 69.63,
    "out:Total Energy Use Post PV": 66.16,
    "out:Primary Energy": 105.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.72,
    "out:CO2 Operational/m2": 11.47,
    "out:Total CO2/m2": 18.19,
    "out:Total CO2 (tons)": 57.07,
    "out:Klimatpaverkan": -142.62,
    "out:Initial Cost/MSEK": 5.247875,
    "out:Running cost/(Apt SEK y)": 13808,
    "out:Running Cost over RSP/MSEK": 3.103,
    "out:LCP/MSEK": 3.337,
    "out:ROI% old": 23.37,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4173.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1094,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -126301,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244890,
    "out:DH kr savings": 459602,
    "out:El kr savings": -214712,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 32135,
    "out:% savings (space heating)": 91.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.87,
    "out:Etvv": 0,
    "out:Ef": 56.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.17,
    "out:Electricity (inc PV)": 56.3,
    "out:Total Energy Use Pre PV": 71.17,
    "out:Total Energy Use Post PV": 68.3,
    "out:Primary Energy": 107.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.14,
    "out:CO2 Operational/m2": 12.13,
    "out:Total CO2/m2": 19.27,
    "out:Total CO2 (tons)": 60.46,
    "out:Klimatpaverkan": -139.23,
    "out:Initial Cost/MSEK": 5.32875,
    "out:Running cost/(Apt SEK y)": 14293,
    "out:Running Cost over RSP/MSEK": 3.214,
    "out:LCP/MSEK": 3.146,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4142,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1080,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -126728,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238455,
    "out:DH kr savings": 453893,
    "out:El kr savings": -215438,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36316,
    "out:% savings (space heating)": 90.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 56.3,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.44,
    "out:Electricity (inc PV)": 55.42,
    "out:Total Energy Use Pre PV": 67.44,
    "out:Total Energy Use Post PV": 64.42,
    "out:Primary Energy": 104.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.14,
    "out:CO2 Operational/m2": 10.95,
    "out:Total CO2/m2": 18.1,
    "out:Total CO2 (tons)": 56.77,
    "out:Klimatpaverkan": -142.92,
    "out:Initial Cost/MSEK": 5.40725,
    "out:Running cost/(Apt SEK y)": 13305,
    "out:Running Cost over RSP/MSEK": 2.99,
    "out:LCP/MSEK": 3.292,
    "out:ROI% old": 22.98,
    "out:Payback discounted": 5,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4090.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -123977,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251696,
    "out:DH kr savings": 462457,
    "out:El kr savings": -210760,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28073,
    "out:% savings (space heating)": 93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.32,
    "out:Etvv": 0,
    "out:Ef": 55.42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 46.43,
    "out:Total Energy Use Pre PV": 73.31,
    "out:Total Energy Use Post PV": 59.43,
    "out:Primary Energy": 89.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.08,
    "out:CO2 Operational/m2": 9.21,
    "out:Total CO2/m2": 28.28,
    "out:Total CO2 (tons)": 88.72,
    "out:Klimatpaverkan": -110.97,
    "out:Initial Cost/MSEK": 5.821875,
    "out:Running cost/(Apt SEK y)": 12752,
    "out:Running Cost over RSP/MSEK": 2.876,
    "out:LCP/MSEK": 2.99,
    "out:ROI% old": 21.62,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3509,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1388.53,
    "out:PV (% sold (El))": 3.96,
    "out:PV (kWh self-consumed)": 33674.18,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -95773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289994,
    "out:DH kr savings": 451038,
    "out:El kr savings": -162814,
    "out:El kr sold": 1770,
    "out:El kr saved": 57246,
    "out:El kr return": 59016,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 40873,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.16,
    "out:Etvv": 0,
    "out:Ef": 46.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.63,
    "out:Electricity (inc PV)": 45.64,
    "out:Total Energy Use Pre PV": 69.63,
    "out:Total Energy Use Post PV": 55.64,
    "out:Primary Energy": 86.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.08,
    "out:CO2 Operational/m2": 7.88,
    "out:Total CO2/m2": 26.96,
    "out:Total CO2 (tons)": 84.57,
    "out:Klimatpaverkan": -115.12,
    "out:Initial Cost/MSEK": 5.900375,
    "out:Running cost/(Apt SEK y)": 11774,
    "out:Running Cost over RSP/MSEK": 2.655,
    "out:LCP/MSEK": 3.134,
    "out:ROI% old": 21.87,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3462.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1153,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1489.02,
    "out:PV (% sold (El))": 4.25,
    "out:PV (kWh self-consumed)": 33573.68,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -93289,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302909,
    "out:DH kr savings": 459602,
    "out:El kr savings": -158591,
    "out:El kr sold": 1899,
    "out:El kr saved": 57075,
    "out:El kr return": 58974,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 32135,
    "out:% savings (space heating)": 91.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.87,
    "out:Etvv": 0,
    "out:Ef": 45.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.17,
    "out:Electricity (inc PV)": 45.77,
    "out:Total Energy Use Pre PV": 71.17,
    "out:Total Energy Use Post PV": 57.77,
    "out:Primary Energy": 88.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.5,
    "out:CO2 Operational/m2": 8.44,
    "out:Total CO2/m2": 27.93,
    "out:Total CO2 (tons)": 87.63,
    "out:Klimatpaverkan": -112.06,
    "out:Initial Cost/MSEK": 5.98125,
    "out:Running cost/(Apt SEK y)": 12260,
    "out:Running Cost over RSP/MSEK": 2.765,
    "out:LCP/MSEK": 2.942,
    "out:ROI% old": 21.31,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3431.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1139,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1557.39,
    "out:PV (% sold (El))": 4.44,
    "out:PV (kWh self-consumed)": 33505.32,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -93692,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296602,
    "out:DH kr savings": 453893,
    "out:El kr savings": -159277,
    "out:El kr sold": 1986,
    "out:El kr saved": 56959,
    "out:El kr return": 58945,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36316,
    "out:% savings (space heating)": 90.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 45.77,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.44,
    "out:Electricity (inc PV)": 44.94,
    "out:Total Energy Use Pre PV": 67.44,
    "out:Total Energy Use Post PV": 53.94,
    "out:Primary Energy": 85.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.5,
    "out:CO2 Operational/m2": 7.09,
    "out:Total CO2/m2": 26.58,
    "out:Total CO2 (tons)": 83.39,
    "out:Klimatpaverkan": -116.3,
    "out:Initial Cost/MSEK": 6.05975,
    "out:Running cost/(Apt SEK y)": 11274,
    "out:Running Cost over RSP/MSEK": 2.542,
    "out:LCP/MSEK": 3.087,
    "out:ROI% old": 21.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3382.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1168,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1670.83,
    "out:PV (% sold (El))": 4.77,
    "out:PV (kWh self-consumed)": 33391.87,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -91099,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309719,
    "out:DH kr savings": 462457,
    "out:El kr savings": -154868,
    "out:El kr sold": 2130,
    "out:El kr saved": 56766,
    "out:El kr return": 58897,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28073,
    "out:% savings (space heating)": 93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.32,
    "out:Etvv": 0,
    "out:Ef": 44.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 41.79,
    "out:Total Energy Use Pre PV": 73.31,
    "out:Total Energy Use Post PV": 54.79,
    "out:Primary Energy": 81.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.43,
    "out:CO2 Operational/m2": -22.37,
    "out:Total CO2/m2": 9.06,
    "out:Total CO2 (tons)": 28.41,
    "out:Klimatpaverkan": -171.28,
    "out:Initial Cost/MSEK": 6.4745,
    "out:Running cost/(Apt SEK y)": 10980,
    "out:Running Cost over RSP/MSEK": 2.485,
    "out:LCP/MSEK": 2.729,
    "out:ROI% old": 20.31,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3151,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20748.27,
    "out:PV (% sold (El))": 29.59,
    "out:PV (kWh self-consumed)": 49377.15,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 495646,
    "out:EL kWh savings": -81206,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339442,
    "out:DH kr savings": 451038,
    "out:El kr savings": -138050,
    "out:El kr sold": 26454,
    "out:El kr saved": 83941,
    "out:El kr return": 110395,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 40873,
    "out:% savings (space heating)": 89.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 9.16,
    "out:Etvv": 0,
    "out:Ef": 41.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.63,
    "out:Electricity (inc PV)": 41.08,
    "out:Total Energy Use Pre PV": 69.63,
    "out:Total Energy Use Post PV": 51.08,
    "out:Primary Energy": 78.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.43,
    "out:CO2 Operational/m2": -24.08,
    "out:Total CO2/m2": 7.35,
    "out:Total CO2 (tons)": 23.06,
    "out:Klimatpaverkan": -176.63,
    "out:Initial Cost/MSEK": 6.552875,
    "out:Running cost/(Apt SEK y)": 10006,
    "out:Running Cost over RSP/MSEK": 2.264,
    "out:LCP/MSEK": 2.872,
    "out:ROI% old": 20.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3109.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21084.23,
    "out:PV (% sold (El))": 30.07,
    "out:PV (kWh self-consumed)": 49041.19,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -79004,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352177,
    "out:DH kr savings": 459602,
    "out:El kr savings": -134307,
    "out:El kr sold": 26882,
    "out:El kr saved": 83370,
    "out:El kr return": 110252,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 32135,
    "out:% savings (space heating)": 91.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.87,
    "out:Etvv": 0,
    "out:Ef": 41.08,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.17,
    "out:Electricity (inc PV)": 41.2,
    "out:Total Energy Use Pre PV": 71.17,
    "out:Total Energy Use Post PV": 53.2,
    "out:Primary Energy": 80.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": -23.77,
    "out:Total CO2/m2": 8.08,
    "out:Total CO2 (tons)": 25.33,
    "out:Klimatpaverkan": -174.36,
    "out:Initial Cost/MSEK": 6.633875,
    "out:Running cost/(Apt SEK y)": 10494,
    "out:Running Cost over RSP/MSEK": 2.375,
    "out:LCP/MSEK": 2.68,
    "out:ROI% old": 20.06,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3082.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21308.68,
    "out:PV (% sold (El))": 30.39,
    "out:PV (kWh self-consumed)": 48816.74,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -79362,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346146,
    "out:DH kr savings": 453893,
    "out:El kr savings": -134915,
    "out:El kr sold": 27169,
    "out:El kr saved": 82988,
    "out:El kr return": 110157,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36316,
    "out:% savings (space heating)": 90.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.48,
    "out:Etvv": 0,
    "out:Ef": 41.2,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.44,
    "out:Electricity (inc PV)": 40.47,
    "out:Total Energy Use Pre PV": 67.44,
    "out:Total Energy Use Post PV": 49.47,
    "out:Primary Energy": 77.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.85,
    "out:CO2 Operational/m2": -25.53,
    "out:Total CO2/m2": 6.32,
    "out:Total CO2 (tons)": 19.82,
    "out:Klimatpaverkan": -179.87,
    "out:Initial Cost/MSEK": 6.71225,
    "out:Running cost/(Apt SEK y)": 9511,
    "out:Running Cost over RSP/MSEK": 2.152,
    "out:LCP/MSEK": 2.824,
    "out:ROI% old": 20.3,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1219,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21675.78,
    "out:PV (% sold (El))": 30.91,
    "out:PV (kWh self-consumed)": 48449.63,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -77062,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359088,
    "out:DH kr savings": 462457,
    "out:El kr savings": -131005,
    "out:El kr sold": 27637,
    "out:El kr saved": 82364,
    "out:El kr return": 110001,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28073,
    "out:% savings (space heating)": 93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.32,
    "out:Etvv": 0,
    "out:Ef": 40.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 173.9,
    "out:Primary Energy": 125.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.19,
    "out:CO2 Operational/m2": 58.98,
    "out:Total CO2/m2": 67.17,
    "out:Total CO2 (tons)": 210.7,
    "out:Klimatpaverkan": 11.01,
    "out:Initial Cost/MSEK": 1.772875,
    "out:Running cost/(Apt SEK y)": 47843,
    "out:Running Cost over RSP/MSEK": 10.85,
    "out:LCP/MSEK": -0.934,
    "out:ROI% old": 6.76,
    "out:Payback discounted": 18,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 7.5,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 107,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 40781,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 37111,
    "out:DH kr savings": 37111,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 168.9,
    "out:Primary Energy": 122.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.19,
    "out:CO2 Operational/m2": 57.18,
    "out:Total CO2/m2": 65.37,
    "out:Total CO2 (tons)": 205.05,
    "out:Klimatpaverkan": 5.36,
    "out:Initial Cost/MSEK": 1.851375,
    "out:Running cost/(Apt SEK y)": 46422,
    "out:Running Cost over RSP/MSEK": 10.527,
    "out:LCP/MSEK": -0.69,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 148,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 51384,
    "out:DH kr savings": 51384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 123.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.61,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 65.07,
    "out:Total CO2 (tons)": 204.11,
    "out:Klimatpaverkan": 4.42,
    "out:Initial Cost/MSEK": 1.93225,
    "out:Running cost/(Apt SEK y)": 45853,
    "out:Running Cost over RSP/MSEK": 10.398,
    "out:LCP/MSEK": -0.642,
    "out:ROI% old": 9.54,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57093,
    "out:DH kr savings": 57093,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 119.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.61,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 63.27,
    "out:Total CO2 (tons)": 198.47,
    "out:Klimatpaverkan": -1.22,
    "out:Initial Cost/MSEK": 2.01075,
    "out:Running cost/(Apt SEK y)": 44432,
    "out:Running Cost over RSP/MSEK": 10.076,
    "out:LCP/MSEK": -0.398,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78425,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71367,
    "out:DH kr savings": 71367,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 168.73,
    "out:Primary Energy": 116.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 27.98,
    "out:Total CO2/m2": 48.52,
    "out:Total CO2 (tons)": 152.22,
    "out:Klimatpaverkan": -47.47,
    "out:Initial Cost/MSEK": 2.425375,
    "out:Running cost/(Apt SEK y)": 46064,
    "out:Running Cost over RSP/MSEK": 10.457,
    "out:LCP/MSEK": -1.194,
    "out:ROI% old": 7.25,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 40781,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88711,
    "out:DH kr savings": 37111,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 163.73,
    "out:Primary Energy": 113.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 46.72,
    "out:Total CO2 (tons)": 146.58,
    "out:Klimatpaverkan": -53.11,
    "out:Initial Cost/MSEK": 2.503875,
    "out:Running cost/(Apt SEK y)": 44642,
    "out:Running Cost over RSP/MSEK": 10.135,
    "out:LCP/MSEK": -0.95,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102984,
    "out:DH kr savings": 51384,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 114.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 46.42,
    "out:Total CO2 (tons)": 145.63,
    "out:Klimatpaverkan": -54.06,
    "out:Initial Cost/MSEK": 2.584875,
    "out:Running cost/(Apt SEK y)": 44074,
    "out:Running Cost over RSP/MSEK": 10.006,
    "out:LCP/MSEK": -0.902,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62740,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108693,
    "out:DH kr savings": 57093,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 110.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 44.62,
    "out:Total CO2 (tons)": 139.99,
    "out:Klimatpaverkan": -59.7,
    "out:Initial Cost/MSEK": 2.66325,
    "out:Running cost/(Apt SEK y)": 42652,
    "out:Running Cost over RSP/MSEK": 9.683,
    "out:LCP/MSEK": -0.657,
    "out:ROI% old": 10.76,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 78425,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122967,
    "out:DH kr savings": 71367,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 173.9,
    "out:Total Energy Use Post PV": 167.88,
    "out:Primary Energy": 115.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": -24.7,
    "out:Total CO2/m2": 8.2,
    "out:Total CO2 (tons)": 25.72,
    "out:Klimatpaverkan": -173.97,
    "out:Initial Cost/MSEK": 3.078,
    "out:Running cost/(Apt SEK y)": 44483,
    "out:Running Cost over RSP/MSEK": 10.109,
    "out:LCP/MSEK": -1.498,
    "out:ROI% old": 7.33,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 205,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 40781,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134547,
    "out:DH kr savings": 37111,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 162.88,
    "out:Primary Energy": 111.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": -26.5,
    "out:Total CO2/m2": 6.4,
    "out:Total CO2 (tons)": 20.07,
    "out:Klimatpaverkan": -179.62,
    "out:Initial Cost/MSEK": 3.156375,
    "out:Running cost/(Apt SEK y)": 43062,
    "out:Running Cost over RSP/MSEK": 9.786,
    "out:LCP/MSEK": -1.254,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148821,
    "out:DH kr savings": 51384,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 112.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.32,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 6.1,
    "out:Total CO2 (tons)": 19.13,
    "out:Klimatpaverkan": -180.56,
    "out:Initial Cost/MSEK": 3.237375,
    "out:Running cost/(Apt SEK y)": 42493,
    "out:Running Cost over RSP/MSEK": 9.657,
    "out:LCP/MSEK": -1.206,
    "out:ROI% old": 8.97,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 62740,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154530,
    "out:DH kr savings": 57093,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 109.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.32,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 4.3,
    "out:Total CO2 (tons)": 13.48,
    "out:Klimatpaverkan": -186.21,
    "out:Initial Cost/MSEK": 3.31575,
    "out:Running cost/(Apt SEK y)": 41072,
    "out:Running Cost over RSP/MSEK": 9.334,
    "out:LCP/MSEK": -0.961,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 304,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 78425,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168803,
    "out:DH kr savings": 71367,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 122.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.19,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 63.93,
    "out:Total CO2 (tons)": 200.54,
    "out:Klimatpaverkan": 0.85,
    "out:Initial Cost/MSEK": 1.8495,
    "out:Running cost/(Apt SEK y)": 45284,
    "out:Running Cost over RSP/MSEK": 10.269,
    "out:LCP/MSEK": -0.43,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62803,
    "out:DH kr savings": 62803,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 119.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.19,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 62.13,
    "out:Total CO2 (tons)": 194.89,
    "out:Klimatpaverkan": -4.8,
    "out:Initial Cost/MSEK": 1.927875,
    "out:Running cost/(Apt SEK y)": 43863,
    "out:Running Cost over RSP/MSEK": 9.947,
    "out:LCP/MSEK": -0.186,
    "out:ROI% old": 12.91,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77076,
    "out:DH kr savings": 77076,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 120.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.61,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 62.55,
    "out:Total CO2 (tons)": 196.21,
    "out:Klimatpaverkan": -3.48,
    "out:Initial Cost/MSEK": 2.008875,
    "out:Running cost/(Apt SEK y)": 43863,
    "out:Running Cost over RSP/MSEK": 9.947,
    "out:LCP/MSEK": -0.267,
    "out:ROI% old": 12.39,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 223,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77076,
    "out:DH kr savings": 77076,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 116.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.61,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 60.75,
    "out:Total CO2 (tons)": 190.56,
    "out:Klimatpaverkan": -9.13,
    "out:Initial Cost/MSEK": 2.08725,
    "out:Running cost/(Apt SEK y)": 42441,
    "out:Running Cost over RSP/MSEK": 9.624,
    "out:LCP/MSEK": -0.023,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 13,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 91349,
    "out:DH kr savings": 91349,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 113.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 45.28,
    "out:Total CO2 (tons)": 142.06,
    "out:Klimatpaverkan": -57.63,
    "out:Initial Cost/MSEK": 2.502,
    "out:Running cost/(Apt SEK y)": 43505,
    "out:Running Cost over RSP/MSEK": 9.877,
    "out:LCP/MSEK": -0.69,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114403,
    "out:DH kr savings": 62803,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 109.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.54,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 43.48,
    "out:Total CO2 (tons)": 136.41,
    "out:Klimatpaverkan": -63.28,
    "out:Initial Cost/MSEK": 2.580375,
    "out:Running cost/(Apt SEK y)": 42084,
    "out:Running Cost over RSP/MSEK": 9.554,
    "out:LCP/MSEK": -0.446,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128676,
    "out:DH kr savings": 77076,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 111.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 137.73,
    "out:Klimatpaverkan": -61.96,
    "out:Initial Cost/MSEK": 2.661375,
    "out:Running cost/(Apt SEK y)": 42084,
    "out:Running Cost over RSP/MSEK": 9.554,
    "out:LCP/MSEK": -0.527,
    "out:ROI% old": 11.46,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128676,
    "out:DH kr savings": 77076,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 107.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.96,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 42.1,
    "out:Total CO2 (tons)": 132.08,
    "out:Klimatpaverkan": -67.61,
    "out:Initial Cost/MSEK": 2.73975,
    "out:Running cost/(Apt SEK y)": 40662,
    "out:Running Cost over RSP/MSEK": 9.231,
    "out:LCP/MSEK": -0.282,
    "out:ROI% old": 12.81,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142949,
    "out:DH kr savings": 91349,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 111.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": 4.96,
    "out:Total CO2 (tons)": 15.55,
    "out:Klimatpaverkan": -184.14,
    "out:Initial Cost/MSEK": 3.1545,
    "out:Running cost/(Apt SEK y)": 41925,
    "out:Running Cost over RSP/MSEK": 9.528,
    "out:LCP/MSEK": -0.994,
    "out:ROI% old": 9.79,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160239,
    "out:DH kr savings": 62803,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 108.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.9,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 3.16,
    "out:Total CO2 (tons)": 9.91,
    "out:Klimatpaverkan": -189.78,
    "out:Initial Cost/MSEK": 3.232875,
    "out:Running cost/(Apt SEK y)": 40503,
    "out:Running Cost over RSP/MSEK": 9.205,
    "out:LCP/MSEK": -0.75,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174513,
    "out:DH kr savings": 77076,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 114.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 109.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.32,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 3.58,
    "out:Total CO2 (tons)": 11.22,
    "out:Klimatpaverkan": -188.47,
    "out:Initial Cost/MSEK": 3.313875,
    "out:Running cost/(Apt SEK y)": 40503,
    "out:Running Cost over RSP/MSEK": 9.205,
    "out:LCP/MSEK": -0.831,
    "out:ROI% old": 10.71,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 84699,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174513,
    "out:DH kr savings": 77076,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 357919,
    "out:% savings (space heating)": 10.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.18,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 106.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.32,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 1.78,
    "out:Total CO2 (tons)": 5.58,
    "out:Klimatpaverkan": -194.11,
    "out:Initial Cost/MSEK": 3.39225,
    "out:Running cost/(Apt SEK y)": 39082,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -0.586,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188786,
    "out:DH kr savings": 91349,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 342506,
    "out:% savings (space heating)": 14.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.71,
    "out:Electricity (inc PV)": 42.59,
    "out:Total Energy Use Pre PV": 114.71,
    "out:Total Energy Use Post PV": 112.59,
    "out:Primary Energy": 117.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12,
    "out:CO2 Operational/m2": 31.1,
    "out:Total CO2/m2": 43.1,
    "out:Total CO2 (tons)": 135.2,
    "out:Klimatpaverkan": -64.49,
    "out:Initial Cost/MSEK": 4.49525,
    "out:Running cost/(Apt SEK y)": 28123,
    "out:Running Cost over RSP/MSEK": 6.36,
    "out:LCP/MSEK": 0.834,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 3129.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 679,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 316837,
    "out:EL kWh savings": -83717,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146003,
    "out:DH kr savings": 288322,
    "out:El kr savings": -142319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219952,
    "out:% savings (space heating)": 45.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.62,
    "out:Etvv": 0,
    "out:Ef": 42.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 42.47,
    "out:Total Energy Use Pre PV": 109.64,
    "out:Total Energy Use Post PV": 107.47,
    "out:Primary Energy": 114.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 41.29,
    "out:Total CO2 (tons)": 129.53,
    "out:Klimatpaverkan": -70.16,
    "out:Initial Cost/MSEK": 4.57375,
    "out:Running cost/(Apt SEK y)": 26688,
    "out:Running Cost over RSP/MSEK": 6.034,
    "out:LCP/MSEK": 1.081,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 3124.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -83356,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160890,
    "out:DH kr savings": 302595,
    "out:El kr savings": -141705,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205270,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.08,
    "out:Etvv": 0,
    "out:Ef": 42.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.99,
    "out:Electricity (inc PV)": 42.12,
    "out:Total Energy Use Pre PV": 109.99,
    "out:Total Energy Use Post PV": 108.12,
    "out:Primary Energy": 115.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.42,
    "out:CO2 Operational/m2": 29.57,
    "out:Total CO2/m2": 41.99,
    "out:Total CO2 (tons)": 131.71,
    "out:Klimatpaverkan": -67.98,
    "out:Initial Cost/MSEK": 4.654625,
    "out:Running cost/(Apt SEK y)": 26854,
    "out:Running Cost over RSP/MSEK": 6.072,
    "out:LCP/MSEK": 0.962,
    "out:ROI% old": 17.24,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 3079.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -82258,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159902,
    "out:DH kr savings": 299740,
    "out:El kr savings": -139839,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 206887,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.4,
    "out:Etvv": 0,
    "out:Ef": 42.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.91,
    "out:Electricity (inc PV)": 42,
    "out:Total Energy Use Pre PV": 104.91,
    "out:Total Energy Use Post PV": 103,
    "out:Primary Energy": 111.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.42,
    "out:CO2 Operational/m2": 27.76,
    "out:Total CO2/m2": 40.17,
    "out:Total CO2 (tons)": 126.03,
    "out:Klimatpaverkan": -73.66,
    "out:Initial Cost/MSEK": 4.733125,
    "out:Running cost/(Apt SEK y)": 25417,
    "out:Running Cost over RSP/MSEK": 5.746,
    "out:LCP/MSEK": 1.21,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3073.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -81867,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174841,
    "out:DH kr savings": 314014,
    "out:El kr savings": -139173,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192353,
    "out:% savings (space heating)": 52.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.9,
    "out:Etvv": 0,
    "out:Ef": 42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.71,
    "out:Electricity (inc PV)": 33.13,
    "out:Total Energy Use Pre PV": 114.71,
    "out:Total Energy Use Post PV": 103.13,
    "out:Primary Energy": 100.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.35,
    "out:CO2 Operational/m2": 22.5,
    "out:Total CO2/m2": 46.85,
    "out:Total CO2 (tons)": 146.96,
    "out:Klimatpaverkan": -52.73,
    "out:Initial Cost/MSEK": 5.147875,
    "out:Running cost/(Apt SEK y)": 26136,
    "out:Running Cost over RSP/MSEK": 5.921,
    "out:LCP/MSEK": 0.619,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2479.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4730.2,
    "out:PV (% sold (El))": 13.49,
    "out:PV (kWh self-consumed)": 30332.51,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 316837,
    "out:EL kWh savings": -54044,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202477,
    "out:DH kr savings": 288322,
    "out:El kr savings": -91875,
    "out:El kr sold": 6031,
    "out:El kr saved": 51565,
    "out:El kr return": 57596,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219952,
    "out:% savings (space heating)": 45.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.62,
    "out:Etvv": 0,
    "out:Ef": 33.13,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 33.02,
    "out:Total Energy Use Pre PV": 109.64,
    "out:Total Energy Use Post PV": 98.02,
    "out:Primary Energy": 97.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.35,
    "out:CO2 Operational/m2": 20.65,
    "out:Total CO2/m2": 45.01,
    "out:Total CO2 (tons)": 141.18,
    "out:Klimatpaverkan": -58.51,
    "out:Initial Cost/MSEK": 5.22625,
    "out:Running cost/(Apt SEK y)": 24702,
    "out:Running Cost over RSP/MSEK": 5.596,
    "out:LCP/MSEK": 0.867,
    "out:ROI% old": 16.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2474.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 778,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4751.22,
    "out:PV (% sold (El))": 13.55,
    "out:PV (kWh self-consumed)": 30311.48,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -53720,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217328,
    "out:DH kr savings": 302595,
    "out:El kr savings": -91325,
    "out:El kr sold": 6058,
    "out:El kr saved": 51530,
    "out:El kr return": 57587,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205270,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.08,
    "out:Etvv": 0,
    "out:Ef": 33.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.99,
    "out:Electricity (inc PV)": 32.71,
    "out:Total Energy Use Pre PV": 109.99,
    "out:Total Energy Use Post PV": 98.71,
    "out:Primary Energy": 98.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.77,
    "out:CO2 Operational/m2": 20.62,
    "out:Total CO2/m2": 45.4,
    "out:Total CO2 (tons)": 142.41,
    "out:Klimatpaverkan": -57.28,
    "out:Initial Cost/MSEK": 5.30725,
    "out:Running cost/(Apt SEK y)": 24871,
    "out:Running Cost over RSP/MSEK": 5.634,
    "out:LCP/MSEK": 0.747,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2433.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 773,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4946.74,
    "out:PV (% sold (El))": 14.11,
    "out:PV (kWh self-consumed)": 30115.97,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -52736,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216396,
    "out:DH kr savings": 299740,
    "out:El kr savings": -89652,
    "out:El kr sold": 6307,
    "out:El kr saved": 51197,
    "out:El kr return": 57504,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 206887,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.4,
    "out:Etvv": 0,
    "out:Ef": 32.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.91,
    "out:Electricity (inc PV)": 32.6,
    "out:Total Energy Use Pre PV": 104.91,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 95.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.77,
    "out:CO2 Operational/m2": 18.77,
    "out:Total CO2/m2": 43.54,
    "out:Total CO2 (tons)": 136.6,
    "out:Klimatpaverkan": -63.09,
    "out:Initial Cost/MSEK": 5.385625,
    "out:Running cost/(Apt SEK y)": 23434,
    "out:Running Cost over RSP/MSEK": 5.308,
    "out:LCP/MSEK": 0.995,
    "out:ROI% old": 16.92,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2427.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4973,
    "out:PV (% sold (El))": 14.18,
    "out:PV (kWh self-consumed)": 30089.71,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -52386,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231299,
    "out:DH kr savings": 314014,
    "out:El kr savings": -89056,
    "out:El kr sold": 6341,
    "out:El kr saved": 51153,
    "out:El kr return": 57493,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192353,
    "out:% savings (space heating)": 52.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.9,
    "out:Etvv": 0,
    "out:Ef": 32.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.71,
    "out:Electricity (inc PV)": 29.94,
    "out:Total Energy Use Pre PV": 114.71,
    "out:Total Energy Use Post PV": 99.94,
    "out:Primary Energy": 94.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.71,
    "out:CO2 Operational/m2": -17.3,
    "out:Total CO2/m2": 19.4,
    "out:Total CO2 (tons)": 60.87,
    "out:Klimatpaverkan": -138.82,
    "out:Initial Cost/MSEK": 5.800375,
    "out:Running cost/(Apt SEK y)": 24439,
    "out:Running Cost over RSP/MSEK": 5.547,
    "out:LCP/MSEK": 0.342,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 2237.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 786,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29157.1,
    "out:PV (% sold (El))": 41.58,
    "out:PV (kWh self-consumed)": 40968.32,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 316837,
    "out:EL kWh savings": -44034,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250639,
    "out:DH kr savings": 288322,
    "out:El kr savings": -74858,
    "out:El kr sold": 37175,
    "out:El kr saved": 69646,
    "out:El kr return": 106821,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219952,
    "out:% savings (space heating)": 45.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 58.62,
    "out:Etvv": 0,
    "out:Ef": 29.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 29.84,
    "out:Total Energy Use Pre PV": 109.64,
    "out:Total Energy Use Post PV": 94.84,
    "out:Primary Energy": 91.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.71,
    "out:CO2 Operational/m2": -19.18,
    "out:Total CO2/m2": 17.53,
    "out:Total CO2 (tons)": 54.98,
    "out:Klimatpaverkan": -144.71,
    "out:Initial Cost/MSEK": 5.87875,
    "out:Running cost/(Apt SEK y)": 23005,
    "out:Running Cost over RSP/MSEK": 5.221,
    "out:LCP/MSEK": 0.589,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2233.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29199.27,
    "out:PV (% sold (El))": 41.64,
    "out:PV (kWh self-consumed)": 40926.15,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -43744,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265460,
    "out:DH kr savings": 302595,
    "out:El kr savings": -74365,
    "out:El kr sold": 37229,
    "out:El kr saved": 69574,
    "out:El kr return": 106804,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205270,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.08,
    "out:Etvv": 0,
    "out:Ef": 29.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.99,
    "out:Electricity (inc PV)": 29.56,
    "out:Total Energy Use Pre PV": 109.99,
    "out:Total Energy Use Post PV": 95.56,
    "out:Primary Energy": 92.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.13,
    "out:CO2 Operational/m2": -19.52,
    "out:Total CO2/m2": 17.6,
    "out:Total CO2 (tons)": 55.22,
    "out:Klimatpaverkan": -144.47,
    "out:Initial Cost/MSEK": 5.95975,
    "out:Running cost/(Apt SEK y)": 23177,
    "out:Running Cost over RSP/MSEK": 5.26,
    "out:LCP/MSEK": 0.469,
    "out:ROI% old": 15.41,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2196.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29585.9,
    "out:PV (% sold (El))": 42.19,
    "out:PV (kWh self-consumed)": 40539.52,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -42862,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264597,
    "out:DH kr savings": 299740,
    "out:El kr savings": -72866,
    "out:El kr sold": 37722,
    "out:El kr saved": 68917,
    "out:El kr return": 106639,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 206887,
    "out:% savings (space heating)": 48.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 56.4,
    "out:Etvv": 0,
    "out:Ef": 29.56,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.91,
    "out:Electricity (inc PV)": 29.46,
    "out:Total Energy Use Pre PV": 104.91,
    "out:Total Energy Use Post PV": 90.46,
    "out:Primary Energy": 89.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.13,
    "out:CO2 Operational/m2": -21.41,
    "out:Total CO2/m2": 15.71,
    "out:Total CO2 (tons)": 49.28,
    "out:Klimatpaverkan": -150.41,
    "out:Initial Cost/MSEK": 6.038125,
    "out:Running cost/(Apt SEK y)": 21740,
    "out:Running Cost over RSP/MSEK": 4.934,
    "out:LCP/MSEK": 0.716,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2191.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 864,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29637.17,
    "out:PV (% sold (El))": 42.26,
    "out:PV (kWh self-consumed)": 40488.25,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -42548,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279470,
    "out:DH kr savings": 314014,
    "out:El kr savings": -72331,
    "out:El kr sold": 37787,
    "out:El kr saved": 68830,
    "out:El kr return": 106617,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192353,
    "out:% savings (space heating)": 52.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.9,
    "out:Etvv": 0,
    "out:Ef": 29.46,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 41.96,
    "out:Total Energy Use Pre PV": 108.75,
    "out:Total Energy Use Post PV": 106.96,
    "out:Primary Energy": 114.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12,
    "out:CO2 Operational/m2": 29.17,
    "out:Total CO2/m2": 41.17,
    "out:Total CO2 (tons)": 129.16,
    "out:Klimatpaverkan": -70.53,
    "out:Initial Cost/MSEK": 4.571875,
    "out:Running cost/(Apt SEK y)": 26524,
    "out:Running Cost over RSP/MSEK": 5.998,
    "out:LCP/MSEK": 1.119,
    "out:ROI% old": 17.78,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 3062.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 725,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -81764,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163596,
    "out:DH kr savings": 302595,
    "out:El kr savings": -138999,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202616,
    "out:% savings (space heating)": 49.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 55.67,
    "out:Etvv": 0,
    "out:Ef": 41.96,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.66,
    "out:Electricity (inc PV)": 41.84,
    "out:Total Energy Use Pre PV": 103.66,
    "out:Total Energy Use Post PV": 101.84,
    "out:Primary Energy": 111.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12,
    "out:CO2 Operational/m2": 27.36,
    "out:Total CO2/m2": 39.36,
    "out:Total CO2 (tons)": 123.48,
    "out:Klimatpaverkan": -76.21,
    "out:Initial Cost/MSEK": 4.65025,
    "out:Running cost/(Apt SEK y)": 25086,
    "out:Running Cost over RSP/MSEK": 5.671,
    "out:LCP/MSEK": 1.367,
    "out:ROI% old": 18.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 3056,
    "out:Return %": 16,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -81363,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178551,
    "out:DH kr savings": 316868,
    "out:El kr savings": -138317,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188137,
    "out:% savings (space heating)": 53.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.18,
    "out:Etvv": 0,
    "out:Ef": 41.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.14,
    "out:Electricity (inc PV)": 41.58,
    "out:Total Energy Use Pre PV": 104.14,
    "out:Total Energy Use Post PV": 102.58,
    "out:Primary Energy": 112.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.42,
    "out:CO2 Operational/m2": 27.65,
    "out:Total CO2/m2": 40.07,
    "out:Total CO2 (tons)": 125.71,
    "out:Klimatpaverkan": -73.98,
    "out:Initial Cost/MSEK": 4.73125,
    "out:Running cost/(Apt SEK y)": 25275,
    "out:Running Cost over RSP/MSEK": 5.714,
    "out:LCP/MSEK": 1.243,
    "out:ROI% old": 18.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3019.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -80565,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177053,
    "out:DH kr savings": 314014,
    "out:El kr savings": -136960,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192539,
    "out:% savings (space heating)": 52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 53.93,
    "out:Etvv": 0,
    "out:Ef": 41.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 41.45,
    "out:Total Energy Use Pre PV": 100.04,
    "out:Total Energy Use Post PV": 98.45,
    "out:Primary Energy": 109.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.42,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38.62,
    "out:Total CO2 (tons)": 121.15,
    "out:Klimatpaverkan": -78.54,
    "out:Initial Cost/MSEK": 4.809625,
    "out:Running cost/(Apt SEK y)": 24119,
    "out:Running Cost over RSP/MSEK": 5.452,
    "out:LCP/MSEK": 1.427,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3012.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 795,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -80139,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189196,
    "out:DH kr savings": 325432,
    "out:El kr savings": -136237,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 178187,
    "out:% savings (space heating)": 55.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 49.47,
    "out:Etvv": 0,
    "out:Ef": 41.45,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 32.57,
    "out:Total Energy Use Pre PV": 108.75,
    "out:Total Energy Use Post PV": 97.57,
    "out:Primary Energy": 97.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.35,
    "out:CO2 Operational/m2": 20.11,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 139.49,
    "out:Klimatpaverkan": -60.2,
    "out:Initial Cost/MSEK": 5.224375,
    "out:Running cost/(Apt SEK y)": 24542,
    "out:Running Cost over RSP/MSEK": 5.56,
    "out:LCP/MSEK": 0.904,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2417.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 783,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5023.11,
    "out:PV (% sold (El))": 14.33,
    "out:PV (kWh self-consumed)": 30039.6,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -52294,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220100,
    "out:DH kr savings": 302595,
    "out:El kr savings": -88900,
    "out:El kr sold": 6404,
    "out:El kr saved": 51067,
    "out:El kr return": 57472,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202616,
    "out:% savings (space heating)": 49.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 55.67,
    "out:Etvv": 0,
    "out:Ef": 32.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.66,
    "out:Electricity (inc PV)": 32.46,
    "out:Total Energy Use Pre PV": 103.66,
    "out:Total Energy Use Post PV": 92.46,
    "out:Primary Energy": 94.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.35,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 42.61,
    "out:Total CO2 (tons)": 133.66,
    "out:Klimatpaverkan": -66.03,
    "out:Initial Cost/MSEK": 5.30275,
    "out:Running cost/(Apt SEK y)": 23105,
    "out:Running Cost over RSP/MSEK": 5.234,
    "out:LCP/MSEK": 1.152,
    "out:ROI% old": 17.39,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2411.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5051.25,
    "out:PV (% sold (El))": 14.41,
    "out:PV (kWh self-consumed)": 30011.46,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -51935,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235020,
    "out:DH kr savings": 316868,
    "out:El kr savings": -88289,
    "out:El kr sold": 6440,
    "out:El kr saved": 51019,
    "out:El kr return": 57460,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188137,
    "out:% savings (space heating)": 53.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.18,
    "out:Etvv": 0,
    "out:Ef": 32.46,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.14,
    "out:Electricity (inc PV)": 32.23,
    "out:Total Energy Use Pre PV": 104.14,
    "out:Total Energy Use Post PV": 93.23,
    "out:Primary Energy": 95.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.77,
    "out:CO2 Operational/m2": 18.29,
    "out:Total CO2/m2": 43.06,
    "out:Total CO2 (tons)": 135.09,
    "out:Klimatpaverkan": -64.6,
    "out:Initial Cost/MSEK": 5.38375,
    "out:Running cost/(Apt SEK y)": 23296,
    "out:Running Cost over RSP/MSEK": 5.278,
    "out:LCP/MSEK": 1.027,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2378.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5214.14,
    "out:PV (% sold (El))": 14.87,
    "out:PV (kWh self-consumed)": 29848.57,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -51221,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233586,
    "out:DH kr savings": 314014,
    "out:El kr savings": -87076,
    "out:El kr sold": 6648,
    "out:El kr saved": 50743,
    "out:El kr return": 57391,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192539,
    "out:% savings (space heating)": 52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 53.93,
    "out:Etvv": 0,
    "out:Ef": 32.23,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 32.11,
    "out:Total Energy Use Pre PV": 100.04,
    "out:Total Energy Use Post PV": 89.11,
    "out:Primary Energy": 92.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.77,
    "out:CO2 Operational/m2": 16.79,
    "out:Total CO2/m2": 41.56,
    "out:Total CO2 (tons)": 130.37,
    "out:Klimatpaverkan": -69.32,
    "out:Initial Cost/MSEK": 5.462125,
    "out:Running cost/(Apt SEK y)": 22141,
    "out:Running Cost over RSP/MSEK": 5.015,
    "out:LCP/MSEK": 1.211,
    "out:ROI% old": 17.45,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2372.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5246.67,
    "out:PV (% sold (El))": 14.96,
    "out:PV (kWh self-consumed)": 29816.04,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -50841,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245693,
    "out:DH kr savings": 325432,
    "out:El kr savings": -86429,
    "out:El kr sold": 6690,
    "out:El kr saved": 50687,
    "out:El kr return": 57377,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 178187,
    "out:% savings (space heating)": 55.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 49.47,
    "out:Etvv": 0,
    "out:Ef": 32.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.75,
    "out:Electricity (inc PV)": 29.44,
    "out:Total Energy Use Pre PV": 108.75,
    "out:Total Energy Use Post PV": 94.44,
    "out:Primary Energy": 91.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.71,
    "out:CO2 Operational/m2": -20.15,
    "out:Total CO2/m2": 16.55,
    "out:Total CO2 (tons)": 51.93,
    "out:Klimatpaverkan": -147.76,
    "out:Initial Cost/MSEK": 5.876875,
    "out:Running cost/(Apt SEK y)": 22849,
    "out:Running Cost over RSP/MSEK": 5.186,
    "out:LCP/MSEK": 0.626,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2182.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 832,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29734.93,
    "out:PV (% sold (El))": 42.4,
    "out:PV (kWh self-consumed)": 40390.49,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 332522,
    "out:EL kWh savings": -42466,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268315,
    "out:DH kr savings": 302595,
    "out:El kr savings": -72192,
    "out:El kr sold": 37912,
    "out:El kr saved": 68664,
    "out:El kr return": 106576,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 202616,
    "out:% savings (space heating)": 49.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 55.67,
    "out:Etvv": 0,
    "out:Ef": 29.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.66,
    "out:Electricity (inc PV)": 29.33,
    "out:Total Energy Use Pre PV": 103.66,
    "out:Total Energy Use Post PV": 89.33,
    "out:Primary Energy": 88.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.71,
    "out:CO2 Operational/m2": -22.05,
    "out:Total CO2/m2": 14.65,
    "out:Total CO2 (tons)": 45.97,
    "out:Klimatpaverkan": -153.72,
    "out:Initial Cost/MSEK": 5.955375,
    "out:Running cost/(Apt SEK y)": 21412,
    "out:Running Cost over RSP/MSEK": 4.86,
    "out:LCP/MSEK": 0.873,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2177.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 874,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29789.59,
    "out:PV (% sold (El))": 42.48,
    "out:PV (kWh self-consumed)": 40335.83,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -42144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283206,
    "out:DH kr savings": 316868,
    "out:El kr savings": -71644,
    "out:El kr sold": 37982,
    "out:El kr saved": 68571,
    "out:El kr return": 106553,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188137,
    "out:% savings (space heating)": 53.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.18,
    "out:Etvv": 0,
    "out:Ef": 29.33,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.14,
    "out:Electricity (inc PV)": 29.13,
    "out:Total Energy Use Pre PV": 104.14,
    "out:Total Energy Use Post PV": 90.13,
    "out:Primary Energy": 90.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.13,
    "out:CO2 Operational/m2": -22.26,
    "out:Total CO2/m2": 14.86,
    "out:Total CO2 (tons)": 46.63,
    "out:Klimatpaverkan": -153.06,
    "out:Initial Cost/MSEK": 6.03625,
    "out:Running cost/(Apt SEK y)": 21606,
    "out:Running Cost over RSP/MSEK": 4.904,
    "out:LCP/MSEK": 0.748,
    "out:ROI% old": 16.06,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2147.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30104.6,
    "out:PV (% sold (El))": 42.93,
    "out:PV (kWh self-consumed)": 40020.82,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -41504,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281840,
    "out:DH kr savings": 314014,
    "out:El kr savings": -70557,
    "out:El kr sold": 38383,
    "out:El kr saved": 68035,
    "out:El kr return": 106419,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 192539,
    "out:% savings (space heating)": 52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 53.93,
    "out:Etvv": 0,
    "out:Ef": 29.13,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.04,
    "out:Electricity (inc PV)": 29.02,
    "out:Total Energy Use Pre PV": 100.04,
    "out:Total Energy Use Post PV": 86.02,
    "out:Primary Energy": 86.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.13,
    "out:CO2 Operational/m2": -23.81,
    "out:Total CO2/m2": 13.31,
    "out:Total CO2 (tons)": 41.75,
    "out:Klimatpaverkan": -157.94,
    "out:Initial Cost/MSEK": 6.11475,
    "out:Running cost/(Apt SEK y)": 20450,
    "out:Running Cost over RSP/MSEK": 4.642,
    "out:LCP/MSEK": 0.932,
    "out:ROI% old": 16.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2141.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30167.29,
    "out:PV (% sold (El))": 43.02,
    "out:PV (kWh self-consumed)": 39958.13,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -41163,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293919,
    "out:DH kr savings": 325432,
    "out:El kr savings": -69977,
    "out:El kr sold": 38463,
    "out:El kr saved": 67929,
    "out:El kr return": 106392,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 178187,
    "out:% savings (space heating)": 55.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 49.47,
    "out:Etvv": 0,
    "out:Ef": 29.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.83,
    "out:Electricity (inc PV)": 57.71,
    "out:Total Energy Use Pre PV": 75.83,
    "out:Total Energy Use Post PV": 71.71,
    "out:Primary Energy": 109.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.64,
    "out:CO2 Operational/m2": 13.2,
    "out:Total CO2/m2": 25.84,
    "out:Total CO2 (tons)": 81.07,
    "out:Klimatpaverkan": -118.62,
    "out:Initial Cost/MSEK": 6.351625,
    "out:Running cost/(Apt SEK y)": 15350,
    "out:Running Cost over RSP/MSEK": 3.452,
    "out:LCP/MSEK": 1.885,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4327.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1049,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -131159,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225213,
    "out:DH kr savings": 448183,
    "out:El kr savings": -222971,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42745,
    "out:% savings (space heating)": 89.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.71,
    "out:Etvv": 0,
    "out:Ef": 57.71,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.19,
    "out:Electricity (inc PV)": 56.88,
    "out:Total Energy Use Pre PV": 72.19,
    "out:Total Energy Use Post PV": 67.88,
    "out:Primary Energy": 106.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.64,
    "out:CO2 Operational/m2": 12.04,
    "out:Total CO2/m2": 24.68,
    "out:Total CO2 (tons)": 77.42,
    "out:Klimatpaverkan": -122.27,
    "out:Initial Cost/MSEK": 6.430125,
    "out:Running cost/(Apt SEK y)": 14379,
    "out:Running Cost over RSP/MSEK": 3.232,
    "out:LCP/MSEK": 2.026,
    "out:ROI% old": 18.79,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4283,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1078,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -128566,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238185,
    "out:DH kr savings": 456747,
    "out:El kr savings": -218562,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33967,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.52,
    "out:Etvv": 0,
    "out:Ef": 56.88,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.42,
    "out:Electricity (inc PV)": 56.83,
    "out:Total Energy Use Pre PV": 72.42,
    "out:Total Energy Use Post PV": 68.83,
    "out:Primary Energy": 107.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.06,
    "out:CO2 Operational/m2": 12.3,
    "out:Total CO2/m2": 25.36,
    "out:Total CO2 (tons)": 79.55,
    "out:Klimatpaverkan": -120.14,
    "out:Initial Cost/MSEK": 6.511,
    "out:Running cost/(Apt SEK y)": 14522,
    "out:Running Cost over RSP/MSEK": 3.265,
    "out:LCP/MSEK": 1.913,
    "out:ROI% old": 18.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4229.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1073,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -128388,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235633,
    "out:DH kr savings": 453893,
    "out:El kr savings": -218260,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36862,
    "out:% savings (space heating)": 90.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.88,
    "out:Etvv": 0,
    "out:Ef": 56.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.72,
    "out:Electricity (inc PV)": 55.95,
    "out:Total Energy Use Pre PV": 68.72,
    "out:Total Energy Use Post PV": 64.95,
    "out:Primary Energy": 104.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.06,
    "out:CO2 Operational/m2": 11.12,
    "out:Total CO2/m2": 24.19,
    "out:Total CO2 (tons)": 75.87,
    "out:Klimatpaverkan": -123.82,
    "out:Initial Cost/MSEK": 6.5895,
    "out:Running cost/(Apt SEK y)": 13541,
    "out:Running Cost over RSP/MSEK": 3.043,
    "out:LCP/MSEK": 2.056,
    "out:ROI% old": 18.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4180.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1102,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -125638,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248873,
    "out:DH kr savings": 462457,
    "out:El kr savings": -213584,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28685,
    "out:% savings (space heating)": 92.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.86,
    "out:Etvv": 0,
    "out:Ef": 55.95,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.83,
    "out:Electricity (inc PV)": 47.1,
    "out:Total Energy Use Pre PV": 75.83,
    "out:Total Energy Use Post PV": 61.1,
    "out:Primary Energy": 90.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25,
    "out:CO2 Operational/m2": 10.07,
    "out:Total CO2/m2": 35.07,
    "out:Total CO2 (tons)": 110.02,
    "out:Klimatpaverkan": -89.67,
    "out:Initial Cost/MSEK": 7.00425,
    "out:Running cost/(Apt SEK y)": 13312,
    "out:Running Cost over RSP/MSEK": 3.003,
    "out:LCP/MSEK": 1.682,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3611.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1109,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1182.3,
    "out:PV (% sold (El))": 3.37,
    "out:PV (kWh self-consumed)": 33880.41,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -97883,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283289,
    "out:DH kr savings": 448183,
    "out:El kr savings": -166402,
    "out:El kr sold": 1507,
    "out:El kr saved": 57597,
    "out:El kr return": 59104,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42745,
    "out:% savings (space heating)": 89.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.71,
    "out:Etvv": 0,
    "out:Ef": 47.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.19,
    "out:Electricity (inc PV)": 46.32,
    "out:Total Energy Use Pre PV": 72.19,
    "out:Total Energy Use Post PV": 57.32,
    "out:Primary Energy": 87.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25,
    "out:CO2 Operational/m2": 8.78,
    "out:Total CO2/m2": 33.78,
    "out:Total CO2 (tons)": 105.97,
    "out:Klimatpaverkan": -93.72,
    "out:Initial Cost/MSEK": 7.082625,
    "out:Running cost/(Apt SEK y)": 12342,
    "out:Running Cost over RSP/MSEK": 2.783,
    "out:LCP/MSEK": 1.823,
    "out:ROI% old": 17.96,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3568.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1137,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1266.91,
    "out:PV (% sold (El))": 3.61,
    "out:PV (kWh self-consumed)": 33795.8,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -95428,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296134,
    "out:DH kr savings": 456747,
    "out:El kr savings": -162228,
    "out:El kr sold": 1615,
    "out:El kr saved": 57453,
    "out:El kr return": 59068,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33967,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.52,
    "out:Etvv": 0,
    "out:Ef": 46.32,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.42,
    "out:Electricity (inc PV)": 46.27,
    "out:Total Energy Use Pre PV": 72.42,
    "out:Total Energy Use Post PV": 58.27,
    "out:Primary Energy": 88.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.42,
    "out:CO2 Operational/m2": 8.88,
    "out:Total CO2/m2": 34.3,
    "out:Total CO2 (tons)": 107.59,
    "out:Klimatpaverkan": -92.1,
    "out:Initial Cost/MSEK": 7.163625,
    "out:Running cost/(Apt SEK y)": 12487,
    "out:Running Cost over RSP/MSEK": 2.816,
    "out:LCP/MSEK": 1.709,
    "out:ROI% old": 17.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3516.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1133,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1373.51,
    "out:PV (% sold (El))": 3.92,
    "out:PV (kWh self-consumed)": 33689.2,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -95260,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293702,
    "out:DH kr savings": 453893,
    "out:El kr savings": -161942,
    "out:El kr sold": 1751,
    "out:El kr saved": 57272,
    "out:El kr return": 59023,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36862,
    "out:% savings (space heating)": 90.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.88,
    "out:Etvv": 0,
    "out:Ef": 46.27,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.72,
    "out:Electricity (inc PV)": 45.44,
    "out:Total Energy Use Pre PV": 68.72,
    "out:Total Energy Use Post PV": 54.44,
    "out:Primary Energy": 85.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.42,
    "out:CO2 Operational/m2": 7.56,
    "out:Total CO2/m2": 32.97,
    "out:Total CO2 (tons)": 103.43,
    "out:Klimatpaverkan": -96.26,
    "out:Initial Cost/MSEK": 7.242,
    "out:Running cost/(Apt SEK y)": 11507,
    "out:Running Cost over RSP/MSEK": 2.594,
    "out:LCP/MSEK": 1.853,
    "out:ROI% old": 17.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3468.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1161,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1475.12,
    "out:PV (% sold (El))": 4.21,
    "out:PV (kWh self-consumed)": 33587.58,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -92663,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306810,
    "out:DH kr savings": 462457,
    "out:El kr savings": -157527,
    "out:El kr sold": 1881,
    "out:El kr saved": 57099,
    "out:El kr return": 58980,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28685,
    "out:% savings (space heating)": 92.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.86,
    "out:Etvv": 0,
    "out:Ef": 45.44,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.83,
    "out:Electricity (inc PV)": 42.38,
    "out:Total Energy Use Pre PV": 75.83,
    "out:Total Energy Use Post PV": 56.38,
    "out:Primary Energy": 82.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.35,
    "out:CO2 Operational/m2": -20.65,
    "out:Total CO2/m2": 16.7,
    "out:Total CO2 (tons)": 52.38,
    "out:Klimatpaverkan": -147.31,
    "out:Initial Cost/MSEK": 7.65675,
    "out:Running cost/(Apt SEK y)": 11532,
    "out:Running Cost over RSP/MSEK": 2.609,
    "out:LCP/MSEK": 1.423,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3241.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20018.63,
    "out:PV (% sold (El))": 28.55,
    "out:PV (kWh self-consumed)": 50106.79,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 492509,
    "out:EL kWh savings": -83075,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332480,
    "out:DH kr savings": 448183,
    "out:El kr savings": -141227,
    "out:El kr sold": 25524,
    "out:El kr saved": 85182,
    "out:El kr return": 110705,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 42745,
    "out:% savings (space heating)": 89.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 8.71,
    "out:Etvv": 0,
    "out:Ef": 42.38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.19,
    "out:Electricity (inc PV)": 41.69,
    "out:Total Energy Use Pre PV": 72.19,
    "out:Total Energy Use Post PV": 52.69,
    "out:Primary Energy": 79.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.35,
    "out:CO2 Operational/m2": -22.31,
    "out:Total CO2/m2": 15.04,
    "out:Total CO2 (tons)": 47.19,
    "out:Klimatpaverkan": -152.5,
    "out:Initial Cost/MSEK": 7.735125,
    "out:Running cost/(Apt SEK y)": 10566,
    "out:Running Cost over RSP/MSEK": 2.39,
    "out:LCP/MSEK": 1.563,
    "out:ROI% old": 17.17,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3203.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20325.32,
    "out:PV (% sold (El))": 28.98,
    "out:PV (kWh self-consumed)": 49800.1,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -80900,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 345131,
    "out:DH kr savings": 456747,
    "out:El kr savings": -137531,
    "out:El kr sold": 25915,
    "out:El kr saved": 84660,
    "out:El kr return": 110575,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 33967,
    "out:% savings (space heating)": 91.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.52,
    "out:Etvv": 0,
    "out:Ef": 41.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.42,
    "out:Electricity (inc PV)": 41.64,
    "out:Total Energy Use Pre PV": 72.42,
    "out:Total Energy Use Post PV": 53.64,
    "out:Primary Energy": 80.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.77,
    "out:CO2 Operational/m2": -22.64,
    "out:Total CO2/m2": 15.13,
    "out:Total CO2 (tons)": 47.47,
    "out:Klimatpaverkan": -152.22,
    "out:Initial Cost/MSEK": 7.816125,
    "out:Running cost/(Apt SEK y)": 10715,
    "out:Running Cost over RSP/MSEK": 2.425,
    "out:LCP/MSEK": 1.448,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3157.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1184,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20697.28,
    "out:PV (% sold (El))": 29.51,
    "out:PV (kWh self-consumed)": 49428.14,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 498783,
    "out:EL kWh savings": -80751,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 343004,
    "out:DH kr savings": 453893,
    "out:El kr savings": -137277,
    "out:El kr sold": 26389,
    "out:El kr saved": 84028,
    "out:El kr return": 110417,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 36862,
    "out:% savings (space heating)": 90.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.88,
    "out:Etvv": 0,
    "out:Ef": 41.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.72,
    "out:Electricity (inc PV)": 40.91,
    "out:Total Energy Use Pre PV": 68.72,
    "out:Total Energy Use Post PV": 49.91,
    "out:Primary Energy": 77.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.77,
    "out:CO2 Operational/m2": -24.35,
    "out:Total CO2/m2": 13.42,
    "out:Total CO2 (tons)": 42.09,
    "out:Klimatpaverkan": -157.6,
    "out:Initial Cost/MSEK": 7.8945,
    "out:Running cost/(Apt SEK y)": 9738,
    "out:Running Cost over RSP/MSEK": 2.203,
    "out:LCP/MSEK": 1.591,
    "out:ROI% old": 17.16,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3115.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1212,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21038.3,
    "out:PV (% sold (El))": 30,
    "out:PV (kWh self-consumed)": 49087.11,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -78450,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 355916,
    "out:DH kr savings": 462457,
    "out:El kr savings": -133364,
    "out:El kr sold": 26824,
    "out:El kr saved": 83448,
    "out:El kr return": 110272,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 28685,
    "out:% savings (space heating)": 92.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.86,
    "out:Etvv": 0,
    "out:Ef": 40.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 56.53,
    "out:Total Energy Use Pre PV": 70.94,
    "out:Total Energy Use Post PV": 67.53,
    "out:Primary Energy": 107.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.64,
    "out:CO2 Operational/m2": 11.87,
    "out:Total CO2/m2": 24.52,
    "out:Total CO2 (tons)": 76.9,
    "out:Klimatpaverkan": -122.79,
    "out:Initial Cost/MSEK": 6.42825,
    "out:Running cost/(Apt SEK y)": 14149,
    "out:Running Cost over RSP/MSEK": 3.181,
    "out:LCP/MSEK": 2.08,
    "out:ROI% old": 18.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4195.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1084,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -127451,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240080,
    "out:DH kr savings": 456747,
    "out:El kr savings": -216667,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 35005,
    "out:% savings (space heating)": 91.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.61,
    "out:Etvv": 0,
    "out:Ef": 56.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.22,
    "out:Electricity (inc PV)": 55.63,
    "out:Total Energy Use Pre PV": 68.22,
    "out:Total Energy Use Post PV": 64.63,
    "out:Primary Energy": 104.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.64,
    "out:CO2 Operational/m2": 11.06,
    "out:Total CO2/m2": 23.7,
    "out:Total CO2 (tons)": 74.35,
    "out:Klimatpaverkan": -125.34,
    "out:Initial Cost/MSEK": 6.506625,
    "out:Running cost/(Apt SEK y)": 13449,
    "out:Running Cost over RSP/MSEK": 3.022,
    "out:LCP/MSEK": 2.16,
    "out:ROI% old": 19.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4145.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -124647,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 250557,
    "out:DH kr savings": 462457,
    "out:El kr savings": -211900,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27041,
    "out:% savings (space heating)": 93.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.65,
    "out:Etvv": 0,
    "out:Ef": 55.63,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.77,
    "out:Electricity (inc PV)": 55.8,
    "out:Total Energy Use Pre PV": 68.77,
    "out:Total Energy Use Post PV": 65.8,
    "out:Primary Energy": 105.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.06,
    "out:CO2 Operational/m2": 11.36,
    "out:Total CO2/m2": 24.42,
    "out:Total CO2 (tons)": 76.6,
    "out:Klimatpaverkan": -123.09,
    "out:Initial Cost/MSEK": 6.587625,
    "out:Running cost/(Apt SEK y)": 13649,
    "out:Running Cost over RSP/MSEK": 3.068,
    "out:LCP/MSEK": 2.033,
    "out:ROI% old": 18.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4113.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1099,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -125174,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246806,
    "out:DH kr savings": 459602,
    "out:El kr savings": -212796,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 30772,
    "out:% savings (space heating)": 92.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.01,
    "out:Etvv": 0,
    "out:Ef": 55.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 54.87,
    "out:Total Energy Use Pre PV": 64.99,
    "out:Total Energy Use Post PV": 61.87,
    "out:Primary Energy": 102.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.06,
    "out:CO2 Operational/m2": 10.18,
    "out:Total CO2/m2": 23.24,
    "out:Total CO2 (tons)": 72.9,
    "out:Klimatpaverkan": -126.79,
    "out:Initial Cost/MSEK": 6.666,
    "out:Running cost/(Apt SEK y)": 12654,
    "out:Running Cost over RSP/MSEK": 2.843,
    "out:LCP/MSEK": 2.18,
    "out:ROI% old": 18.96,
    "out:Payback discounted": 6,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4059.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1128,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -122240,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260358,
    "out:DH kr savings": 468166,
    "out:El kr savings": -207808,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 23359,
    "out:% savings (space heating)": 94.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 54.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 45.98,
    "out:Total Energy Use Pre PV": 70.94,
    "out:Total Energy Use Post PV": 56.98,
    "out:Primary Energy": 88.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25,
    "out:CO2 Operational/m2": 8.35,
    "out:Total CO2/m2": 33.35,
    "out:Total CO2 (tons)": 104.62,
    "out:Klimatpaverkan": -95.07,
    "out:Initial Cost/MSEK": 7.08075,
    "out:Running cost/(Apt SEK y)": 12115,
    "out:Running Cost over RSP/MSEK": 2.732,
    "out:LCP/MSEK": 1.876,
    "out:ROI% old": 18.07,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3483.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1143,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1442.73,
    "out:PV (% sold (El))": 4.11,
    "out:PV (kWh self-consumed)": 33619.98,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -94375,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298149,
    "out:DH kr savings": 456747,
    "out:El kr savings": -160438,
    "out:El kr sold": 1839,
    "out:El kr saved": 57154,
    "out:El kr return": 58993,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 35005,
    "out:% savings (space heating)": 91.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.61,
    "out:Etvv": 0,
    "out:Ef": 45.98,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.22,
    "out:Electricity (inc PV)": 45.14,
    "out:Total Energy Use Pre PV": 68.22,
    "out:Total Energy Use Post PV": 54.14,
    "out:Primary Energy": 85.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25,
    "out:CO2 Operational/m2": 7.38,
    "out:Total CO2/m2": 32.37,
    "out:Total CO2 (tons)": 101.55,
    "out:Klimatpaverkan": -98.14,
    "out:Initial Cost/MSEK": 7.159125,
    "out:Running cost/(Apt SEK y)": 11416,
    "out:Running Cost over RSP/MSEK": 2.574,
    "out:LCP/MSEK": 1.956,
    "out:ROI% old": 18.19,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3435.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1550.21,
    "out:PV (% sold (El))": 4.42,
    "out:PV (kWh self-consumed)": 33512.5,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -91730,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308493,
    "out:DH kr savings": 462457,
    "out:El kr savings": -155940,
    "out:El kr sold": 1977,
    "out:El kr saved": 56971,
    "out:El kr return": 58948,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27041,
    "out:% savings (space heating)": 93.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.65,
    "out:Etvv": 0,
    "out:Ef": 45.14,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.77,
    "out:Electricity (inc PV)": 45.3,
    "out:Total Energy Use Pre PV": 68.77,
    "out:Total Energy Use Post PV": 55.3,
    "out:Primary Energy": 86.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.42,
    "out:CO2 Operational/m2": 7.57,
    "out:Total CO2/m2": 32.99,
    "out:Total CO2 (tons)": 103.48,
    "out:Klimatpaverkan": -96.21,
    "out:Initial Cost/MSEK": 7.240125,
    "out:Running cost/(Apt SEK y)": 11618,
    "out:Running Cost over RSP/MSEK": 2.62,
    "out:LCP/MSEK": 1.829,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3404.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1158,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1619.53,
    "out:PV (% sold (El))": 4.62,
    "out:PV (kWh self-consumed)": 33443.18,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -92226,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304882,
    "out:DH kr savings": 459602,
    "out:El kr savings": -156785,
    "out:El kr sold": 2065,
    "out:El kr saved": 56853,
    "out:El kr return": 58918,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 30772,
    "out:% savings (space heating)": 92.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.01,
    "out:Etvv": 0,
    "out:Ef": 45.3,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 44.42,
    "out:Total Energy Use Pre PV": 64.99,
    "out:Total Energy Use Post PV": 51.42,
    "out:Primary Energy": 83.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.42,
    "out:CO2 Operational/m2": 6.2,
    "out:Total CO2/m2": 31.62,
    "out:Total CO2 (tons)": 99.18,
    "out:Klimatpaverkan": -100.51,
    "out:Initial Cost/MSEK": 7.3185,
    "out:Running cost/(Apt SEK y)": 10625,
    "out:Running Cost over RSP/MSEK": 2.395,
    "out:LCP/MSEK": 1.976,
    "out:ROI% old": 18.14,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3352.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1187,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1741.22,
    "out:PV (% sold (El))": 4.97,
    "out:PV (kWh self-consumed)": 33321.49,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -89465,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318296,
    "out:DH kr savings": 468166,
    "out:El kr savings": -152090,
    "out:El kr sold": 2220,
    "out:El kr saved": 56647,
    "out:El kr return": 58867,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 23359,
    "out:% savings (space heating)": 94.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 44.42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.94,
    "out:Electricity (inc PV)": 41.39,
    "out:Total Energy Use Pre PV": 70.94,
    "out:Total Energy Use Post PV": 52.39,
    "out:Primary Energy": 79.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.35,
    "out:CO2 Operational/m2": -23.43,
    "out:Total CO2/m2": 13.92,
    "out:Total CO2 (tons)": 43.66,
    "out:Klimatpaverkan": -156.03,
    "out:Initial Cost/MSEK": 7.73325,
    "out:Running cost/(Apt SEK y)": 10345,
    "out:Running Cost over RSP/MSEK": 2.341,
    "out:LCP/MSEK": 1.615,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3128.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1195,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20930.93,
    "out:PV (% sold (El))": 29.85,
    "out:PV (kWh self-consumed)": 49194.49,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -79967,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347491,
    "out:DH kr savings": 456747,
    "out:El kr savings": -135944,
    "out:El kr sold": 26687,
    "out:El kr saved": 83631,
    "out:El kr return": 110318,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 35005,
    "out:% savings (space heating)": 91.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.61,
    "out:Etvv": 0,
    "out:Ef": 41.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.22,
    "out:Electricity (inc PV)": 40.64,
    "out:Total Energy Use Pre PV": 68.22,
    "out:Total Energy Use Post PV": 49.64,
    "out:Primary Energy": 77.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.35,
    "out:CO2 Operational/m2": -24.81,
    "out:Total CO2/m2": 12.54,
    "out:Total CO2 (tons)": 39.34,
    "out:Klimatpaverkan": -160.35,
    "out:Initial Cost/MSEK": 7.81175,
    "out:Running cost/(Apt SEK y)": 9650,
    "out:Running Cost over RSP/MSEK": 2.183,
    "out:LCP/MSEK": 1.694,
    "out:ROI% old": 17.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3085.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1215,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21285.17,
    "out:PV (% sold (El))": 30.35,
    "out:PV (kWh self-consumed)": 48840.25,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -77622,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 357639,
    "out:DH kr savings": 462457,
    "out:El kr savings": -131957,
    "out:El kr sold": 27139,
    "out:El kr saved": 83028,
    "out:El kr return": 110167,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27041,
    "out:% savings (space heating)": 93.26,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.65,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.77,
    "out:Electricity (inc PV)": 40.78,
    "out:Total Energy Use Pre PV": 68.77,
    "out:Total Energy Use Post PV": 50.78,
    "out:Primary Energy": 78.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.77,
    "out:CO2 Operational/m2": -24.87,
    "out:Total CO2/m2": 12.9,
    "out:Total CO2 (tons)": 40.47,
    "out:Klimatpaverkan": -159.22,
    "out:Initial Cost/MSEK": 7.892625,
    "out:Running cost/(Apt SEK y)": 9854,
    "out:Running Cost over RSP/MSEK": 2.23,
    "out:LCP/MSEK": 1.566,
    "out:ROI% old": 17.12,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3058.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1209,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21511.18,
    "out:PV (% sold (El))": 30.68,
    "out:PV (kWh self-consumed)": 48614.24,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 505057,
    "out:EL kWh savings": -78062,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354323,
    "out:DH kr savings": 459602,
    "out:El kr savings": -132706,
    "out:El kr sold": 27427,
    "out:El kr saved": 82644,
    "out:El kr return": 110071,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 30772,
    "out:% savings (space heating)": 92.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 7.01,
    "out:Etvv": 0,
    "out:Ef": 40.78,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 40,
    "out:Total Energy Use Pre PV": 64.99,
    "out:Total Energy Use Post PV": 47,
    "out:Primary Energy": 75.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.77,
    "out:CO2 Operational/m2": -26.66,
    "out:Total CO2/m2": 11.1,
    "out:Total CO2 (tons)": 34.84,
    "out:Klimatpaverkan": -164.85,
    "out:Initial Cost/MSEK": 7.971125,
    "out:Running cost/(Apt SEK y)": 8865,
    "out:Running Cost over RSP/MSEK": 2.006,
    "out:LCP/MSEK": 1.712,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3012.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1238,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21898.46,
    "out:PV (% sold (El))": 31.23,
    "out:PV (kWh self-consumed)": 48226.96,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -75612,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 367546,
    "out:DH kr savings": 468166,
    "out:El kr savings": -128540,
    "out:El kr sold": 27921,
    "out:El kr saved": 81986,
    "out:El kr return": 109906,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 23359,
    "out:% savings (space heating)": 94.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.18,
    "out:Etvv": 0,
    "out:Ef": 40,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 168.9,
    "out:Primary Energy": 122.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 57.18,
    "out:Total CO2/m2": 67.86,
    "out:Total CO2 (tons)": 212.88,
    "out:Klimatpaverkan": 13.19,
    "out:Initial Cost/MSEK": 2.4105,
    "out:Running cost/(Apt SEK y)": 46422,
    "out:Running Cost over RSP/MSEK": 10.527,
    "out:LCP/MSEK": -1.249,
    "out:ROI% old": 6.88,
    "out:Payback discounted": 18,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 148,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51384,
    "out:DH kr savings": 51384,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 119.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 66.42,
    "out:Total CO2 (tons)": 208.36,
    "out:Klimatpaverkan": 8.67,
    "out:Initial Cost/MSEK": 2.488875,
    "out:Running cost/(Apt SEK y)": 45284,
    "out:Running Cost over RSP/MSEK": 10.269,
    "out:LCP/MSEK": -1.069,
    "out:ROI% old": 8.15,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 62803,
    "out:DH kr savings": 62803,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 120.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 66.12,
    "out:Total CO2 (tons)": 207.42,
    "out:Klimatpaverkan": 7.73,
    "out:Initial Cost/MSEK": 2.569875,
    "out:Running cost/(Apt SEK y)": 44716,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": -1.021,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68512,
    "out:DH kr savings": 68512,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 117.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 64.32,
    "out:Total CO2 (tons)": 201.77,
    "out:Klimatpaverkan": 2.08,
    "out:Initial Cost/MSEK": 2.64825,
    "out:Running cost/(Apt SEK y)": 43294,
    "out:Running Cost over RSP/MSEK": 9.818,
    "out:LCP/MSEK": -0.777,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 239,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82785,
    "out:DH kr savings": 82785,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 163.73,
    "out:Primary Energy": 113.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.04,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 49.22,
    "out:Total CO2 (tons)": 154.4,
    "out:Klimatpaverkan": -45.29,
    "out:Initial Cost/MSEK": 3.063,
    "out:Running cost/(Apt SEK y)": 44642,
    "out:Running Cost over RSP/MSEK": 10.135,
    "out:LCP/MSEK": -1.509,
    "out:ROI% old": 7.25,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 200,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102984,
    "out:DH kr savings": 51384,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 110.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.04,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 47.78,
    "out:Total CO2 (tons)": 149.88,
    "out:Klimatpaverkan": -49.81,
    "out:Initial Cost/MSEK": 3.1415,
    "out:Running cost/(Apt SEK y)": 43505,
    "out:Running Cost over RSP/MSEK": 9.877,
    "out:LCP/MSEK": -1.329,
    "out:ROI% old": 8.24,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114403,
    "out:DH kr savings": 62803,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 111.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.46,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 47.48,
    "out:Total CO2 (tons)": 148.94,
    "out:Klimatpaverkan": -50.75,
    "out:Initial Cost/MSEK": 3.222375,
    "out:Running cost/(Apt SEK y)": 42937,
    "out:Running Cost over RSP/MSEK": 9.747,
    "out:LCP/MSEK": -1.281,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120112,
    "out:DH kr savings": 68512,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 107.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.46,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 45.68,
    "out:Total CO2 (tons)": 143.3,
    "out:Klimatpaverkan": -56.39,
    "out:Initial Cost/MSEK": 3.300875,
    "out:Running cost/(Apt SEK y)": 41515,
    "out:Running Cost over RSP/MSEK": 9.425,
    "out:LCP/MSEK": -1.037,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134385,
    "out:DH kr savings": 82785,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 162.88,
    "out:Primary Energy": 112.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": -26.5,
    "out:Total CO2/m2": 8.89,
    "out:Total CO2 (tons)": 27.9,
    "out:Klimatpaverkan": -171.79,
    "out:Initial Cost/MSEK": 3.7155,
    "out:Running cost/(Apt SEK y)": 43062,
    "out:Running Cost over RSP/MSEK": 9.786,
    "out:LCP/MSEK": -1.813,
    "out:ROI% old": 7.32,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 246,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 56466,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148821,
    "out:DH kr savings": 51384,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 108.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": 7.45,
    "out:Total CO2 (tons)": 23.38,
    "out:Klimatpaverkan": -176.31,
    "out:Initial Cost/MSEK": 3.794,
    "out:Running cost/(Apt SEK y)": 41925,
    "out:Running Cost over RSP/MSEK": 9.528,
    "out:LCP/MSEK": -1.633,
    "out:ROI% old": 8.14,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 69014,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160239,
    "out:DH kr savings": 62803,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 109.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.81,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": 7.15,
    "out:Total CO2 (tons)": 22.44,
    "out:Klimatpaverkan": -177.25,
    "out:Initial Cost/MSEK": 3.874875,
    "out:Running cost/(Apt SEK y)": 41356,
    "out:Running Cost over RSP/MSEK": 9.399,
    "out:LCP/MSEK": -1.585,
    "out:ROI% old": 8.44,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165949,
    "out:DH kr savings": 68512,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 106.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.81,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 5.35,
    "out:Total CO2 (tons)": 16.79,
    "out:Klimatpaverkan": -182.9,
    "out:Initial Cost/MSEK": 3.953375,
    "out:Running cost/(Apt SEK y)": 39934,
    "out:Running Cost over RSP/MSEK": 9.076,
    "out:LCP/MSEK": -1.341,
    "out:ROI% old": 9.44,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180222,
    "out:DH kr savings": 82785,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 160.9,
    "out:Primary Energy": 119.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 54.3,
    "out:Total CO2/m2": 64.98,
    "out:Total CO2 (tons)": 203.85,
    "out:Klimatpaverkan": 4.16,
    "out:Initial Cost/MSEK": 2.487,
    "out:Running cost/(Apt SEK y)": 44147,
    "out:Running Cost over RSP/MSEK": 10.011,
    "out:LCP/MSEK": -0.809,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81562,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74221,
    "out:DH kr savings": 74221,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 116.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.68,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 63.18,
    "out:Total CO2 (tons)": 198.2,
    "out:Klimatpaverkan": -1.49,
    "out:Initial Cost/MSEK": 2.5655,
    "out:Running cost/(Apt SEK y)": 42726,
    "out:Running Cost over RSP/MSEK": 9.689,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 97247,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88495,
    "out:DH kr savings": 88495,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 117.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 63.24,
    "out:Total CO2 (tons)": 198.39,
    "out:Klimatpaverkan": -1.3,
    "out:Initial Cost/MSEK": 2.646375,
    "out:Running cost/(Apt SEK y)": 42441,
    "out:Running Cost over RSP/MSEK": 9.624,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91349,
    "out:DH kr savings": 91349,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 114.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 61.8,
    "out:Total CO2 (tons)": 193.87,
    "out:Klimatpaverkan": -5.82,
    "out:Initial Cost/MSEK": 2.724875,
    "out:Running cost/(Apt SEK y)": 41304,
    "out:Running Cost over RSP/MSEK": 9.366,
    "out:LCP/MSEK": -0.402,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102768,
    "out:DH kr savings": 102768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 155.73,
    "out:Primary Energy": 110.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.04,
    "out:CO2 Operational/m2": 23.3,
    "out:Total CO2/m2": 46.34,
    "out:Total CO2 (tons)": 145.37,
    "out:Klimatpaverkan": -54.32,
    "out:Initial Cost/MSEK": 3.139625,
    "out:Running cost/(Apt SEK y)": 42368,
    "out:Running Cost over RSP/MSEK": 9.618,
    "out:LCP/MSEK": -1.069,
    "out:ROI% old": 9.42,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 266,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81562,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125821,
    "out:DH kr savings": 74221,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 106.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.04,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 44.54,
    "out:Total CO2 (tons)": 139.72,
    "out:Klimatpaverkan": -59.97,
    "out:Initial Cost/MSEK": 3.218,
    "out:Running cost/(Apt SEK y)": 40946,
    "out:Running Cost over RSP/MSEK": 9.296,
    "out:LCP/MSEK": -0.825,
    "out:ROI% old": 10.62,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 97247,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140095,
    "out:DH kr savings": 88495,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.46,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 44.6,
    "out:Total CO2 (tons)": 139.91,
    "out:Klimatpaverkan": -59.78,
    "out:Initial Cost/MSEK": 3.299,
    "out:Running cost/(Apt SEK y)": 40662,
    "out:Running Cost over RSP/MSEK": 9.231,
    "out:LCP/MSEK": -0.842,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142949,
    "out:DH kr savings": 91349,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 104.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.46,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 43.16,
    "out:Total CO2 (tons)": 135.39,
    "out:Klimatpaverkan": -64.3,
    "out:Initial Cost/MSEK": 3.377375,
    "out:Running cost/(Apt SEK y)": 39525,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": -0.662,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154368,
    "out:DH kr savings": 102768,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 160.9,
    "out:Total Energy Use Post PV": 154.88,
    "out:Primary Energy": 108.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": -29.38,
    "out:Total CO2/m2": 6.01,
    "out:Total CO2 (tons)": 18.86,
    "out:Klimatpaverkan": -180.83,
    "out:Initial Cost/MSEK": 3.792125,
    "out:Running cost/(Apt SEK y)": 40787,
    "out:Running Cost over RSP/MSEK": 9.27,
    "out:LCP/MSEK": -1.373,
    "out:ROI% old": 9.11,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 81562,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171658,
    "out:DH kr savings": 74221,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 105.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 4.21,
    "out:Total CO2 (tons)": 13.22,
    "out:Klimatpaverkan": -186.47,
    "out:Initial Cost/MSEK": 3.8705,
    "out:Running cost/(Apt SEK y)": 39366,
    "out:Running Cost over RSP/MSEK": 8.947,
    "out:LCP/MSEK": -1.129,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 97247,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185931,
    "out:DH kr savings": 88495,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.78,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 106.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.81,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 4.27,
    "out:Total CO2 (tons)": 13.4,
    "out:Klimatpaverkan": -186.29,
    "out:Initial Cost/MSEK": 3.9515,
    "out:Running cost/(Apt SEK y)": 39082,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -1.146,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188786,
    "out:DH kr savings": 91349,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 344369,
    "out:% savings (space heating)": 14.15,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 105.06,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 103.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.81,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 2.83,
    "out:Total CO2 (tons)": 8.89,
    "out:Klimatpaverkan": -190.8,
    "out:Initial Cost/MSEK": 4.029875,
    "out:Running cost/(Apt SEK y)": 37944,
    "out:Running Cost over RSP/MSEK": 8.625,
    "out:LCP/MSEK": -0.966,
    "out:ROI% old": 10.86,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200205,
    "out:DH kr savings": 102768,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 329572,
    "out:% savings (space heating)": 17.84,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 42.49,
    "out:Total Energy Use Pre PV": 110.64,
    "out:Total Energy Use Post PV": 108.49,
    "out:Primary Energy": 114.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 29.65,
    "out:Total CO2/m2": 44.15,
    "out:Total CO2 (tons)": 138.49,
    "out:Klimatpaverkan": -61.2,
    "out:Initial Cost/MSEK": 5.132875,
    "out:Running cost/(Apt SEK y)": 26974,
    "out:Running Cost over RSP/MSEK": 6.099,
    "out:LCP/MSEK": 0.457,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 3125.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -83405,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157951,
    "out:DH kr savings": 299740,
    "out:El kr savings": -141789,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 207064,
    "out:% savings (space heating)": 48.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.63,
    "out:Etvv": 0,
    "out:Ef": 42.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 42.37,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 104.37,
    "out:Primary Energy": 111.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 28.2,
    "out:Total CO2/m2": 42.7,
    "out:Total CO2 (tons)": 133.94,
    "out:Klimatpaverkan": -65.75,
    "out:Initial Cost/MSEK": 5.21125,
    "out:Running cost/(Apt SEK y)": 25823,
    "out:Running Cost over RSP/MSEK": 5.838,
    "out:LCP/MSEK": 0.64,
    "out:ROI% old": 16.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3120.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 746,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -83037,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169996,
    "out:DH kr savings": 311159,
    "out:El kr savings": -141163,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 193011,
    "out:% savings (space heating)": 51.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50.3,
    "out:Etvv": 0,
    "out:Ef": 42.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.92,
    "out:Electricity (inc PV)": 42.01,
    "out:Total Energy Use Pre PV": 105.92,
    "out:Total Energy Use Post PV": 104.01,
    "out:Primary Energy": 112.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 28.12,
    "out:Total CO2/m2": 43.03,
    "out:Total CO2 (tons)": 134.98,
    "out:Klimatpaverkan": -64.71,
    "out:Initial Cost/MSEK": 5.29225,
    "out:Running cost/(Apt SEK y)": 25703,
    "out:Running Cost over RSP/MSEK": 5.811,
    "out:LCP/MSEK": 0.586,
    "out:ROI% old": 15.87,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 3074.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -81922,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171892,
    "out:DH kr savings": 311159,
    "out:El kr savings": -139267,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 194123,
    "out:% savings (space heating)": 51.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.44,
    "out:Etvv": 0,
    "out:Ef": 42.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 41.89,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 98.89,
    "out:Primary Energy": 109.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 26.31,
    "out:Total CO2/m2": 41.22,
    "out:Total CO2 (tons)": 129.3,
    "out:Klimatpaverkan": -70.39,
    "out:Initial Cost/MSEK": 5.370625,
    "out:Running cost/(Apt SEK y)": 24265,
    "out:Running Cost over RSP/MSEK": 5.485,
    "out:LCP/MSEK": 0.833,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3068.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 791,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -81525,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186840,
    "out:DH kr savings": 325432,
    "out:El kr savings": -138592,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 180219,
    "out:% savings (space heating)": 55.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 41.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 33.04,
    "out:Total Energy Use Pre PV": 110.64,
    "out:Total Energy Use Post PV": 99.04,
    "out:Primary Energy": 97.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.85,
    "out:CO2 Operational/m2": 21.02,
    "out:Total CO2/m2": 47.86,
    "out:Total CO2 (tons)": 150.15,
    "out:Klimatpaverkan": -49.54,
    "out:Initial Cost/MSEK": 5.785375,
    "out:Running cost/(Apt SEK y)": 24988,
    "out:Running Cost over RSP/MSEK": 5.661,
    "out:LCP/MSEK": 0.243,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2475,
    "out:Return %": 13,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4749.23,
    "out:PV (% sold (El))": 13.54,
    "out:PV (kWh self-consumed)": 30313.48,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -53765,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214395,
    "out:DH kr savings": 299740,
    "out:El kr savings": -91400,
    "out:El kr sold": 6055,
    "out:El kr saved": 51533,
    "out:El kr return": 57588,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 207064,
    "out:% savings (space heating)": 48.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.63,
    "out:Etvv": 0,
    "out:Ef": 33.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 32.93,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 94.93,
    "out:Primary Energy": 94.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.85,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 46.38,
    "out:Total CO2 (tons)": 145.5,
    "out:Klimatpaverkan": -54.19,
    "out:Initial Cost/MSEK": 5.863875,
    "out:Running cost/(Apt SEK y)": 23838,
    "out:Running Cost over RSP/MSEK": 5.4,
    "out:LCP/MSEK": 0.425,
    "out:ROI% old": 15.32,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2470.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4770.69,
    "out:PV (% sold (El))": 13.61,
    "out:PV (kWh self-consumed)": 30292.01,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -53435,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226403,
    "out:DH kr savings": 311159,
    "out:El kr savings": -90839,
    "out:El kr sold": 6083,
    "out:El kr saved": 51496,
    "out:El kr return": 57579,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 193011,
    "out:% savings (space heating)": 51.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50.3,
    "out:Etvv": 0,
    "out:Ef": 32.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.92,
    "out:Electricity (inc PV)": 32.61,
    "out:Total Energy Use Pre PV": 105.92,
    "out:Total Energy Use Post PV": 94.61,
    "out:Primary Energy": 95.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.27,
    "out:CO2 Operational/m2": 19.14,
    "out:Total CO2/m2": 46.4,
    "out:Total CO2 (tons)": 145.57,
    "out:Klimatpaverkan": -54.12,
    "out:Initial Cost/MSEK": 5.94475,
    "out:Running cost/(Apt SEK y)": 23720,
    "out:Running Cost over RSP/MSEK": 5.373,
    "out:LCP/MSEK": 0.371,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2428.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4970.21,
    "out:PV (% sold (El))": 14.18,
    "out:PV (kWh self-consumed)": 30092.5,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -52435,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228357,
    "out:DH kr savings": 311159,
    "out:El kr savings": -89139,
    "out:El kr sold": 6337,
    "out:El kr saved": 51157,
    "out:El kr return": 57494,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 194123,
    "out:% savings (space heating)": 51.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.44,
    "out:Etvv": 0,
    "out:Ef": 32.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 32.5,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 89.5,
    "out:Primary Energy": 92.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.27,
    "out:CO2 Operational/m2": 17.28,
    "out:Total CO2/m2": 44.55,
    "out:Total CO2 (tons)": 139.76,
    "out:Klimatpaverkan": -59.93,
    "out:Initial Cost/MSEK": 6.02325,
    "out:Running cost/(Apt SEK y)": 22283,
    "out:Running Cost over RSP/MSEK": 5.047,
    "out:LCP/MSEK": 0.618,
    "out:ROI% old": 15.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2422.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4996.91,
    "out:PV (% sold (El))": 14.25,
    "out:PV (kWh self-consumed)": 30065.79,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -52080,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243268,
    "out:DH kr savings": 325432,
    "out:El kr savings": -88536,
    "out:El kr sold": 6371,
    "out:El kr saved": 51112,
    "out:El kr return": 57483,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 180219,
    "out:% savings (space heating)": 55.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 32.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.64,
    "out:Electricity (inc PV)": 29.86,
    "out:Total Energy Use Pre PV": 110.64,
    "out:Total Energy Use Post PV": 95.86,
    "out:Primary Energy": 91.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -18.81,
    "out:Total CO2/m2": 20.39,
    "out:Total CO2 (tons)": 63.96,
    "out:Klimatpaverkan": -135.73,
    "out:Initial Cost/MSEK": 6.438,
    "out:Running cost/(Apt SEK y)": 23291,
    "out:Running Cost over RSP/MSEK": 5.286,
    "out:LCP/MSEK": -0.035,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2233.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29195.27,
    "out:PV (% sold (El))": 41.63,
    "out:PV (kWh self-consumed)": 40930.14,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 329385,
    "out:EL kWh savings": -43784,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262532,
    "out:DH kr savings": 299740,
    "out:El kr savings": -74432,
    "out:El kr sold": 37224,
    "out:El kr saved": 69581,
    "out:El kr return": 106805,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 207064,
    "out:% savings (space heating)": 48.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 54.63,
    "out:Etvv": 0,
    "out:Ef": 29.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.57,
    "out:Electricity (inc PV)": 29.76,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 91.76,
    "out:Primary Energy": 88.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -20.33,
    "out:Total CO2/m2": 18.87,
    "out:Total CO2 (tons)": 59.2,
    "out:Klimatpaverkan": -140.49,
    "out:Initial Cost/MSEK": 6.516375,
    "out:Running cost/(Apt SEK y)": 22141,
    "out:Running Cost over RSP/MSEK": 5.025,
    "out:LCP/MSEK": 0.147,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2229.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29238.21,
    "out:PV (% sold (El))": 41.69,
    "out:PV (kWh self-consumed)": 40887.2,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -43488,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274508,
    "out:DH kr savings": 311159,
    "out:El kr savings": -73930,
    "out:El kr sold": 37279,
    "out:El kr saved": 69508,
    "out:El kr return": 106787,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 193011,
    "out:% savings (space heating)": 51.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50.3,
    "out:Etvv": 0,
    "out:Ef": 29.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.92,
    "out:Electricity (inc PV)": 29.48,
    "out:Total Energy Use Pre PV": 105.92,
    "out:Total Energy Use Post PV": 91.48,
    "out:Primary Energy": 89.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.62,
    "out:CO2 Operational/m2": -21.04,
    "out:Total CO2/m2": 18.58,
    "out:Total CO2 (tons)": 58.27,
    "out:Klimatpaverkan": -141.42,
    "out:Initial Cost/MSEK": 6.597375,
    "out:Running cost/(Apt SEK y)": 22026,
    "out:Running Cost over RSP/MSEK": 4.999,
    "out:LCP/MSEK": 0.092,
    "out:ROI% old": 14.48,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 2192.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29631.72,
    "out:PV (% sold (El))": 42.26,
    "out:PV (kWh self-consumed)": 40493.7,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 341933,
    "out:EL kWh savings": -42592,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276533,
    "out:DH kr savings": 311159,
    "out:El kr savings": -72406,
    "out:El kr sold": 37780,
    "out:El kr saved": 68839,
    "out:El kr return": 106620,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 194123,
    "out:% savings (space heating)": 51.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 52.44,
    "out:Etvv": 0,
    "out:Ef": 29.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.83,
    "out:Electricity (inc PV)": 29.38,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 86.38,
    "out:Primary Energy": 86.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.62,
    "out:CO2 Operational/m2": -22.94,
    "out:Total CO2/m2": 16.68,
    "out:Total CO2 (tons)": 52.33,
    "out:Klimatpaverkan": -147.36,
    "out:Initial Cost/MSEK": 6.67575,
    "out:Running cost/(Apt SEK y)": 20590,
    "out:Running Cost over RSP/MSEK": 4.673,
    "out:LCP/MSEK": 0.34,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2187.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 898,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29683.86,
    "out:PV (% sold (El))": 42.33,
    "out:PV (kWh self-consumed)": 40441.56,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -42274,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291414,
    "out:DH kr savings": 325432,
    "out:El kr savings": -71865,
    "out:El kr sold": 37847,
    "out:El kr saved": 68751,
    "out:El kr return": 106598,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 180219,
    "out:% savings (space heating)": 55.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 29.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.67,
    "out:Electricity (inc PV)": 41.85,
    "out:Total Energy Use Pre PV": 104.67,
    "out:Total Energy Use Post PV": 102.85,
    "out:Primary Energy": 111.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 27.72,
    "out:Total CO2/m2": 42.22,
    "out:Total CO2 (tons)": 132.44,
    "out:Klimatpaverkan": -67.25,
    "out:Initial Cost/MSEK": 5.209375,
    "out:Running cost/(Apt SEK y)": 25372,
    "out:Running Cost over RSP/MSEK": 5.736,
    "out:LCP/MSEK": 0.743,
    "out:ROI% old": 16.32,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 3056.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 759,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -81420,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175600,
    "out:DH kr savings": 314014,
    "out:El kr savings": -138414,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189894,
    "out:% savings (space heating)": 52.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.71,
    "out:Etvv": 0,
    "out:Ef": 41.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.58,
    "out:Electricity (inc PV)": 41.73,
    "out:Total Energy Use Pre PV": 99.58,
    "out:Total Energy Use Post PV": 97.73,
    "out:Primary Energy": 108.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 25.91,
    "out:Total CO2/m2": 40.41,
    "out:Total CO2 (tons)": 126.75,
    "out:Klimatpaverkan": -72.94,
    "out:Initial Cost/MSEK": 5.287875,
    "out:Running cost/(Apt SEK y)": 23934,
    "out:Running Cost over RSP/MSEK": 5.41,
    "out:LCP/MSEK": 0.991,
    "out:ROI% old": 16.96,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3050.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 801,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -81014,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190563,
    "out:DH kr savings": 328287,
    "out:El kr savings": -137724,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 176046,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 47.42,
    "out:Etvv": 0,
    "out:Ef": 41.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 41.47,
    "out:Total Energy Use Pre PV": 100.05,
    "out:Total Energy Use Post PV": 98.47,
    "out:Primary Energy": 109.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 41.12,
    "out:Total CO2 (tons)": 128.98,
    "out:Klimatpaverkan": -70.71,
    "out:Initial Cost/MSEK": 5.36875,
    "out:Running cost/(Apt SEK y)": 24121,
    "out:Running Cost over RSP/MSEK": 5.453,
    "out:LCP/MSEK": 0.867,
    "out:ROI% old": 16.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 3013.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 795,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -80203,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189088,
    "out:DH kr savings": 325432,
    "out:El kr savings": -136344,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 179923,
    "out:% savings (space heating)": 55.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50,
    "out:Etvv": 0,
    "out:Ef": 41.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.94,
    "out:Electricity (inc PV)": 41.33,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 94.33,
    "out:Primary Energy": 106.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.91,
    "out:CO2 Operational/m2": 24.75,
    "out:Total CO2/m2": 39.66,
    "out:Total CO2 (tons)": 124.42,
    "out:Klimatpaverkan": -75.27,
    "out:Initial Cost/MSEK": 5.44725,
    "out:Running cost/(Apt SEK y)": 22965,
    "out:Running Cost over RSP/MSEK": 5.19,
    "out:LCP/MSEK": 1.051,
    "out:ROI% old": 17.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3006.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -79771,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201240,
    "out:DH kr savings": 336851,
    "out:El kr savings": -135611,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 166205,
    "out:% savings (space heating)": 58.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.73,
    "out:Etvv": 0,
    "out:Ef": 41.33,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.67,
    "out:Electricity (inc PV)": 32.47,
    "out:Total Energy Use Pre PV": 104.67,
    "out:Total Energy Use Post PV": 93.47,
    "out:Primary Energy": 94.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.85,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 45.47,
    "out:Total CO2 (tons)": 142.64,
    "out:Klimatpaverkan": -57.05,
    "out:Initial Cost/MSEK": 5.862,
    "out:Running cost/(Apt SEK y)": 23391,
    "out:Running Cost over RSP/MSEK": 5.299,
    "out:LCP/MSEK": 0.528,
    "out:ROI% old": 15.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2412.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5048.03,
    "out:PV (% sold (El))": 14.4,
    "out:PV (kWh self-consumed)": 30014.68,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -51986,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232074,
    "out:DH kr savings": 314014,
    "out:El kr savings": -88376,
    "out:El kr sold": 6436,
    "out:El kr saved": 51025,
    "out:El kr return": 57461,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189894,
    "out:% savings (space heating)": 52.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.71,
    "out:Etvv": 0,
    "out:Ef": 32.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.58,
    "out:Electricity (inc PV)": 32.36,
    "out:Total Energy Use Pre PV": 99.58,
    "out:Total Energy Use Post PV": 88.36,
    "out:Primary Energy": 91.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.85,
    "out:CO2 Operational/m2": 16.77,
    "out:Total CO2/m2": 43.61,
    "out:Total CO2 (tons)": 136.81,
    "out:Klimatpaverkan": -62.88,
    "out:Initial Cost/MSEK": 5.940375,
    "out:Running cost/(Apt SEK y)": 21953,
    "out:Running Cost over RSP/MSEK": 4.972,
    "out:LCP/MSEK": 0.776,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2406.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5076.73,
    "out:PV (% sold (El))": 14.48,
    "out:PV (kWh self-consumed)": 29985.98,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -51623,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247002,
    "out:DH kr savings": 328287,
    "out:El kr savings": -87758,
    "out:El kr sold": 6473,
    "out:El kr saved": 50976,
    "out:El kr return": 57449,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 176046,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 47.42,
    "out:Etvv": 0,
    "out:Ef": 32.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 32.12,
    "out:Total Energy Use Pre PV": 100.05,
    "out:Total Energy Use Post PV": 89.12,
    "out:Primary Energy": 92.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.27,
    "out:CO2 Operational/m2": 16.8,
    "out:Total CO2/m2": 44.06,
    "out:Total CO2 (tons)": 138.23,
    "out:Klimatpaverkan": -61.46,
    "out:Initial Cost/MSEK": 6.021375,
    "out:Running cost/(Apt SEK y)": 22143,
    "out:Running Cost over RSP/MSEK": 5.016,
    "out:LCP/MSEK": 0.652,
    "out:ROI% old": 15.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2372.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5242.75,
    "out:PV (% sold (El))": 14.95,
    "out:PV (kWh self-consumed)": 29819.96,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -50897,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245592,
    "out:DH kr savings": 325432,
    "out:El kr savings": -86525,
    "out:El kr sold": 6685,
    "out:El kr saved": 50694,
    "out:El kr return": 57378,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 179923,
    "out:% savings (space heating)": 55.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50,
    "out:Etvv": 0,
    "out:Ef": 32.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.94,
    "out:Electricity (inc PV)": 32,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 85,
    "out:Primary Energy": 89.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.27,
    "out:CO2 Operational/m2": 15.29,
    "out:Total CO2/m2": 42.56,
    "out:Total CO2 (tons)": 133.5,
    "out:Klimatpaverkan": -66.19,
    "out:Initial Cost/MSEK": 6.09975,
    "out:Running cost/(Apt SEK y)": 20987,
    "out:Running Cost over RSP/MSEK": 4.754,
    "out:LCP/MSEK": 0.835,
    "out:ROI% old": 16.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2366.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5275.93,
    "out:PV (% sold (El))": 15.05,
    "out:PV (kWh self-consumed)": 29786.77,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -50512,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257708,
    "out:DH kr savings": 336851,
    "out:El kr savings": -85870,
    "out:El kr sold": 6727,
    "out:El kr saved": 50638,
    "out:El kr return": 57364,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 166205,
    "out:% savings (space heating)": 58.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.73,
    "out:Etvv": 0,
    "out:Ef": 32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.67,
    "out:Electricity (inc PV)": 29.35,
    "out:Total Energy Use Pre PV": 104.67,
    "out:Total Energy Use Post PV": 90.35,
    "out:Primary Energy": 89.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -21.68,
    "out:Total CO2/m2": 17.52,
    "out:Total CO2 (tons)": 54.96,
    "out:Klimatpaverkan": -144.73,
    "out:Initial Cost/MSEK": 6.5145,
    "out:Running cost/(Apt SEK y)": 21698,
    "out:Running Cost over RSP/MSEK": 4.925,
    "out:LCP/MSEK": 0.249,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2177.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29783.34,
    "out:PV (% sold (El))": 42.47,
    "out:PV (kWh self-consumed)": 40342.08,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 345070,
    "out:EL kWh savings": -42189,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280265,
    "out:DH kr savings": 314014,
    "out:El kr savings": -71722,
    "out:El kr sold": 37974,
    "out:El kr saved": 68582,
    "out:El kr return": 106555,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189894,
    "out:% savings (space heating)": 52.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 51.71,
    "out:Etvv": 0,
    "out:Ef": 29.35,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.58,
    "out:Electricity (inc PV)": 29.25,
    "out:Total Energy Use Pre PV": 99.58,
    "out:Total Energy Use Post PV": 85.25,
    "out:Primary Energy": 85.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -23.58,
    "out:Total CO2/m2": 15.62,
    "out:Total CO2 (tons)": 49,
    "out:Klimatpaverkan": -150.69,
    "out:Initial Cost/MSEK": 6.592875,
    "out:Running cost/(Apt SEK y)": 20260,
    "out:Running Cost over RSP/MSEK": 4.599,
    "out:LCP/MSEK": 0.497,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2172.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29838.95,
    "out:PV (% sold (El))": 42.55,
    "out:PV (kWh self-consumed)": 40286.46,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -41864,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295163,
    "out:DH kr savings": 328287,
    "out:El kr savings": -71169,
    "out:El kr sold": 38045,
    "out:El kr saved": 68487,
    "out:El kr return": 106532,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 176046,
    "out:% savings (space heating)": 56.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 47.42,
    "out:Etvv": 0,
    "out:Ef": 29.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 29.04,
    "out:Total Energy Use Pre PV": 100.05,
    "out:Total Energy Use Post PV": 86.04,
    "out:Primary Energy": 87.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.62,
    "out:CO2 Operational/m2": -23.8,
    "out:Total CO2/m2": 15.82,
    "out:Total CO2 (tons)": 49.62,
    "out:Klimatpaverkan": -150.07,
    "out:Initial Cost/MSEK": 6.673875,
    "out:Running cost/(Apt SEK y)": 20453,
    "out:Running Cost over RSP/MSEK": 4.643,
    "out:LCP/MSEK": 0.372,
    "out:ROI% old": 15.08,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 2142.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30159.74,
    "out:PV (% sold (El))": 43.01,
    "out:PV (kWh self-consumed)": 39965.68,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 357618,
    "out:EL kWh savings": -41214,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293823,
    "out:DH kr savings": 325432,
    "out:El kr savings": -70063,
    "out:El kr sold": 38454,
    "out:El kr saved": 67942,
    "out:El kr return": 106395,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 179923,
    "out:% savings (space heating)": 55.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 50,
    "out:Etvv": 0,
    "out:Ef": 29.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.94,
    "out:Electricity (inc PV)": 28.93,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 81.93,
    "out:Primary Energy": 84.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.62,
    "out:CO2 Operational/m2": -25.36,
    "out:Total CO2/m2": 14.26,
    "out:Total CO2 (tons)": 44.74,
    "out:Klimatpaverkan": -154.95,
    "out:Initial Cost/MSEK": 6.75225,
    "out:Running cost/(Apt SEK y)": 19297,
    "out:Running Cost over RSP/MSEK": 4.38,
    "out:LCP/MSEK": 0.556,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2136.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.74,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30223.53,
    "out:PV (% sold (El))": 43.1,
    "out:PV (kWh self-consumed)": 39901.88,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -40868,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305911,
    "out:DH kr savings": 336851,
    "out:El kr savings": -69476,
    "out:El kr sold": 38535,
    "out:El kr saved": 67833,
    "out:El kr return": 106368,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 166205,
    "out:% savings (space heating)": 58.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.73,
    "out:Etvv": 0,
    "out:Ef": 28.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.29,
    "out:Electricity (inc PV)": 57,
    "out:Total Energy Use Pre PV": 72.29,
    "out:Total Energy Use Post PV": 68,
    "out:Primary Energy": 107.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.14,
    "out:CO2 Operational/m2": 12.05,
    "out:Total CO2/m2": 27.19,
    "out:Total CO2 (tons)": 85.29,
    "out:Klimatpaverkan": -114.4,
    "out:Initial Cost/MSEK": 6.98925,
    "out:Running cost/(Apt SEK y)": 14398,
    "out:Running Cost over RSP/MSEK": 3.237,
    "out:LCP/MSEK": 1.463,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4290.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1077,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -128930,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237567,
    "out:DH kr savings": 456747,
    "out:El kr savings": -219180,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 34770,
    "out:% savings (space heating)": 91.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.74,
    "out:Etvv": 0,
    "out:Ef": 57,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.61,
    "out:Electricity (inc PV)": 56.1,
    "out:Total Energy Use Pre PV": 69.61,
    "out:Total Energy Use Post PV": 65.1,
    "out:Primary Energy": 104.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.14,
    "out:CO2 Operational/m2": 11.24,
    "out:Total CO2/m2": 26.38,
    "out:Total CO2 (tons)": 82.75,
    "out:Klimatpaverkan": -116.94,
    "out:Initial Cost/MSEK": 7.067625,
    "out:Running cost/(Apt SEK y)": 13704,
    "out:Running Cost over RSP/MSEK": 3.079,
    "out:LCP/MSEK": 1.542,
    "out:ROI% old": 17.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4242.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1097,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -126096,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248094,
    "out:DH kr savings": 462457,
    "out:El kr savings": -214362,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27009,
    "out:% savings (space heating)": 93.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5,
    "out:Etvv": 0,
    "out:Ef": 56.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 56.07,
    "out:Total Energy Use Pre PV": 68.83,
    "out:Total Energy Use Post PV": 65.07,
    "out:Primary Energy": 105.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.56,
    "out:CO2 Operational/m2": 11.14,
    "out:Total CO2/m2": 26.69,
    "out:Total CO2 (tons)": 83.74,
    "out:Klimatpaverkan": -115.95,
    "out:Initial Cost/MSEK": 7.148625,
    "out:Running cost/(Apt SEK y)": 13560,
    "out:Running Cost over RSP/MSEK": 3.047,
    "out:LCP/MSEK": 1.493,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4187.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -126010,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248240,
    "out:DH kr savings": 462457,
    "out:El kr savings": -214217,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 29435,
    "out:% savings (space heating)": 92.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.06,
    "out:Etvv": 0,
    "out:Ef": 56.07,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.08,
    "out:Electricity (inc PV)": 55.12,
    "out:Total Energy Use Pre PV": 66.08,
    "out:Total Energy Use Post PV": 62.12,
    "out:Primary Energy": 102.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.56,
    "out:CO2 Operational/m2": 10.32,
    "out:Total CO2/m2": 25.88,
    "out:Total CO2 (tons)": 81.17,
    "out:Klimatpaverkan": -118.52,
    "out:Initial Cost/MSEK": 7.227,
    "out:Running cost/(Apt SEK y)": 12854,
    "out:Running Cost over RSP/MSEK": 2.887,
    "out:LCP/MSEK": 1.574,
    "out:ROI% old": 17.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4135.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -123030,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259015,
    "out:DH kr savings": 468166,
    "out:El kr savings": -209151,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 22374,
    "out:% savings (space heating)": 94.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.48,
    "out:Etvv": 0,
    "out:Ef": 55.12,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.29,
    "out:Electricity (inc PV)": 46.43,
    "out:Total Energy Use Pre PV": 72.29,
    "out:Total Energy Use Post PV": 57.43,
    "out:Primary Energy": 88.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.49,
    "out:CO2 Operational/m2": 8.82,
    "out:Total CO2/m2": 36.31,
    "out:Total CO2 (tons)": 113.9,
    "out:Klimatpaverkan": -85.79,
    "out:Initial Cost/MSEK": 7.64175,
    "out:Running cost/(Apt SEK y)": 12361,
    "out:Running Cost over RSP/MSEK": 2.787,
    "out:LCP/MSEK": 1.26,
    "out:ROI% old": 16.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3575,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1136,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1253.14,
    "out:PV (% sold (El))": 3.57,
    "out:PV (kWh self-consumed)": 33809.57,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -95772,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295532,
    "out:DH kr savings": 456747,
    "out:El kr savings": -162813,
    "out:El kr sold": 1598,
    "out:El kr saved": 57476,
    "out:El kr return": 59074,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 34770,
    "out:% savings (space heating)": 91.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.74,
    "out:Etvv": 0,
    "out:Ef": 46.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.61,
    "out:Electricity (inc PV)": 45.58,
    "out:Total Energy Use Pre PV": 69.61,
    "out:Total Energy Use Post PV": 54.58,
    "out:Primary Energy": 85.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.49,
    "out:CO2 Operational/m2": 7.87,
    "out:Total CO2/m2": 35.36,
    "out:Total CO2 (tons)": 110.92,
    "out:Klimatpaverkan": -88.77,
    "out:Initial Cost/MSEK": 7.72025,
    "out:Running cost/(Apt SEK y)": 11669,
    "out:Running Cost over RSP/MSEK": 2.63,
    "out:LCP/MSEK": 1.338,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3529,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1346.43,
    "out:PV (% sold (El))": 3.84,
    "out:PV (kWh self-consumed)": 33716.28,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -93095,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305912,
    "out:DH kr savings": 462457,
    "out:El kr savings": -158262,
    "out:El kr sold": 1717,
    "out:El kr saved": 57318,
    "out:El kr return": 59034,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27009,
    "out:% savings (space heating)": 93.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5,
    "out:Etvv": 0,
    "out:Ef": 45.58,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 45.55,
    "out:Total Energy Use Pre PV": 68.83,
    "out:Total Energy Use Post PV": 54.55,
    "out:Primary Energy": 86.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.91,
    "out:CO2 Operational/m2": 7.59,
    "out:Total CO2/m2": 35.51,
    "out:Total CO2 (tons)": 111.38,
    "out:Klimatpaverkan": -88.31,
    "out:Initial Cost/MSEK": 7.801125,
    "out:Running cost/(Apt SEK y)": 11526,
    "out:Running Cost over RSP/MSEK": 2.599,
    "out:LCP/MSEK": 1.289,
    "out:ROI% old": 16.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3476.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1459.16,
    "out:PV (% sold (El))": 4.16,
    "out:PV (kWh self-consumed)": 33603.55,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -93014,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306193,
    "out:DH kr savings": 462457,
    "out:El kr savings": -158124,
    "out:El kr sold": 1860,
    "out:El kr saved": 57126,
    "out:El kr return": 58986,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 29435,
    "out:% savings (space heating)": 92.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.06,
    "out:Etvv": 0,
    "out:Ef": 45.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.08,
    "out:Electricity (inc PV)": 44.66,
    "out:Total Energy Use Pre PV": 66.08,
    "out:Total Energy Use Post PV": 51.66,
    "out:Primary Energy": 83.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.91,
    "out:CO2 Operational/m2": 6.61,
    "out:Total CO2/m2": 34.52,
    "out:Total CO2 (tons)": 108.28,
    "out:Klimatpaverkan": -91.41,
    "out:Initial Cost/MSEK": 7.879625,
    "out:Running cost/(Apt SEK y)": 10822,
    "out:Running Cost over RSP/MSEK": 2.439,
    "out:LCP/MSEK": 1.37,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3425.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1571.46,
    "out:PV (% sold (El))": 4.48,
    "out:PV (kWh self-consumed)": 33491.25,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -90208,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 316817,
    "out:DH kr savings": 468166,
    "out:El kr savings": -153353,
    "out:El kr sold": 2004,
    "out:El kr saved": 56935,
    "out:El kr return": 58939,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 22374,
    "out:% savings (space heating)": 94.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.48,
    "out:Etvv": 0,
    "out:Ef": 44.66,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.29,
    "out:Electricity (inc PV)": 41.79,
    "out:Total Energy Use Pre PV": 72.29,
    "out:Total Energy Use Post PV": 52.79,
    "out:Primary Energy": 79.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.84,
    "out:CO2 Operational/m2": -22.21,
    "out:Total CO2/m2": 17.63,
    "out:Total CO2 (tons)": 55.31,
    "out:Klimatpaverkan": -144.38,
    "out:Initial Cost/MSEK": 8.294375,
    "out:Running cost/(Apt SEK y)": 10584,
    "out:Running Cost over RSP/MSEK": 2.395,
    "out:LCP/MSEK": 1,
    "out:ROI% old": 16.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3209.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20276.05,
    "out:PV (% sold (El))": 28.91,
    "out:PV (kWh self-consumed)": 49849.37,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501920,
    "out:EL kWh savings": -81205,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 344551,
    "out:DH kr savings": 456747,
    "out:El kr savings": -138049,
    "out:El kr sold": 25852,
    "out:El kr saved": 84744,
    "out:El kr return": 110596,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 34770,
    "out:% savings (space heating)": 91.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.74,
    "out:Etvv": 0,
    "out:Ef": 41.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.61,
    "out:Electricity (inc PV)": 41.03,
    "out:Total Energy Use Pre PV": 69.61,
    "out:Total Energy Use Post PV": 50.03,
    "out:Primary Energy": 77.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.84,
    "out:CO2 Operational/m2": -23.54,
    "out:Total CO2/m2": 16.3,
    "out:Total CO2 (tons)": 51.13,
    "out:Klimatpaverkan": -148.56,
    "out:Initial Cost/MSEK": 8.37275,
    "out:Running cost/(Apt SEK y)": 9895,
    "out:Running Cost over RSP/MSEK": 2.239,
    "out:LCP/MSEK": 1.077,
    "out:ROI% old": 16.12,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3168.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20604.6,
    "out:PV (% sold (El))": 29.38,
    "out:PV (kWh self-consumed)": 49520.82,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -78832,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354712,
    "out:DH kr savings": 462457,
    "out:El kr savings": -134015,
    "out:El kr sold": 26271,
    "out:El kr saved": 84185,
    "out:El kr return": 110456,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27009,
    "out:% savings (space heating)": 93.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5,
    "out:Etvv": 0,
    "out:Ef": 41.03,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.83,
    "out:Electricity (inc PV)": 41.01,
    "out:Total Energy Use Pre PV": 68.83,
    "out:Total Energy Use Post PV": 50.01,
    "out:Primary Energy": 78.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.26,
    "out:CO2 Operational/m2": -24.25,
    "out:Total CO2/m2": 16.01,
    "out:Total CO2 (tons)": 50.22,
    "out:Klimatpaverkan": -149.47,
    "out:Initial Cost/MSEK": 8.45375,
    "out:Running cost/(Apt SEK y)": 9757,
    "out:Running Cost over RSP/MSEK": 2.208,
    "out:LCP/MSEK": 1.027,
    "out:ROI% old": 16.02,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3121.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.63,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20985.54,
    "out:PV (% sold (El))": 29.93,
    "out:PV (kWh self-consumed)": 49139.88,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -78761,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355320,
    "out:DH kr savings": 462457,
    "out:El kr savings": -133893,
    "out:El kr sold": 26757,
    "out:El kr saved": 83538,
    "out:El kr return": 110294,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 29435,
    "out:% savings (space heating)": 92.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 6.06,
    "out:Etvv": 0,
    "out:Ef": 41.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.08,
    "out:Electricity (inc PV)": 40.21,
    "out:Total Energy Use Pre PV": 66.08,
    "out:Total Energy Use Post PV": 47.21,
    "out:Primary Energy": 75.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.26,
    "out:CO2 Operational/m2": -25.66,
    "out:Total CO2/m2": 14.61,
    "out:Total CO2 (tons)": 45.82,
    "out:Klimatpaverkan": -153.87,
    "out:Initial Cost/MSEK": 8.532125,
    "out:Running cost/(Apt SEK y)": 9056,
    "out:Running Cost over RSP/MSEK": 2.049,
    "out:LCP/MSEK": 1.108,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3077.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1232,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21354.65,
    "out:PV (% sold (El))": 30.45,
    "out:PV (kWh self-consumed)": 48770.77,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -76271,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365732,
    "out:DH kr savings": 468166,
    "out:El kr savings": -129661,
    "out:El kr sold": 27227,
    "out:El kr saved": 82910,
    "out:El kr return": 110137,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 22374,
    "out:% savings (space heating)": 94.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.48,
    "out:Etvv": 0,
    "out:Ef": 40.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.33,
    "out:Electricity (inc PV)": 55.75,
    "out:Total Energy Use Pre PV": 68.33,
    "out:Total Energy Use Post PV": 64.75,
    "out:Primary Energy": 104.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.14,
    "out:CO2 Operational/m2": 11.07,
    "out:Total CO2/m2": 26.21,
    "out:Total CO2 (tons)": 82.22,
    "out:Klimatpaverkan": -117.47,
    "out:Initial Cost/MSEK": 7.06575,
    "out:Running cost/(Apt SEK y)": 13469,
    "out:Running Cost over RSP/MSEK": 3.027,
    "out:LCP/MSEK": 1.596,
    "out:ROI% old": 17.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4152.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1104,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -125021,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249920,
    "out:DH kr savings": 462457,
    "out:El kr savings": -212536,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27780,
    "out:% savings (space heating)": 93.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.85,
    "out:Etvv": 0,
    "out:Ef": 55.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 54.79,
    "out:Total Energy Use Pre PV": 65.56,
    "out:Total Energy Use Post PV": 61.79,
    "out:Primary Energy": 101.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.14,
    "out:CO2 Operational/m2": 10.25,
    "out:Total CO2/m2": 25.39,
    "out:Total CO2 (tons)": 79.64,
    "out:Klimatpaverkan": -120.05,
    "out:Initial Cost/MSEK": 7.14425,
    "out:Running cost/(Apt SEK y)": 12758,
    "out:Running Cost over RSP/MSEK": 2.866,
    "out:LCP/MSEK": 1.679,
    "out:ROI% old": 17.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4098.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -121997,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260772,
    "out:DH kr savings": 468166,
    "out:El kr savings": -207394,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20975,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 54.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 54.99,
    "out:Total Energy Use Pre PV": 66.1,
    "out:Total Energy Use Post PV": 62.99,
    "out:Primary Energy": 102.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.56,
    "out:CO2 Operational/m2": 10.55,
    "out:Total CO2/m2": 26.11,
    "out:Total CO2 (tons)": 81.9,
    "out:Klimatpaverkan": -117.79,
    "out:Initial Cost/MSEK": 7.225125,
    "out:Running cost/(Apt SEK y)": 12959,
    "out:Running Cost over RSP/MSEK": 2.912,
    "out:LCP/MSEK": 1.552,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4067.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1119,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -122620,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256856,
    "out:DH kr savings": 465311,
    "out:El kr savings": -208455,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24061,
    "out:% savings (space heating)": 94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.37,
    "out:Etvv": 0,
    "out:Ef": 54.99,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.26,
    "out:Electricity (inc PV)": 53.99,
    "out:Total Energy Use Pre PV": 63.26,
    "out:Total Energy Use Post PV": 59.99,
    "out:Primary Energy": 99.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.56,
    "out:CO2 Operational/m2": 9.72,
    "out:Total CO2/m2": 25.28,
    "out:Total CO2 (tons)": 79.29,
    "out:Klimatpaverkan": -120.4,
    "out:Initial Cost/MSEK": 7.303625,
    "out:Running cost/(Apt SEK y)": 12236,
    "out:Running Cost over RSP/MSEK": 2.748,
    "out:LCP/MSEK": 1.637,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4008.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -119497,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267875,
    "out:DH kr savings": 471021,
    "out:El kr savings": -203146,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 17962,
    "out:% savings (space heating)": 95.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 53.99,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.33,
    "out:Electricity (inc PV)": 45.25,
    "out:Total Energy Use Pre PV": 68.33,
    "out:Total Energy Use Post PV": 54.25,
    "out:Primary Energy": 85.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.49,
    "out:CO2 Operational/m2": 7.42,
    "out:Total CO2/m2": 34.91,
    "out:Total CO2 (tons)": 109.5,
    "out:Klimatpaverkan": -90.19,
    "out:Initial Cost/MSEK": 7.718375,
    "out:Running cost/(Apt SEK y)": 11436,
    "out:Running Cost over RSP/MSEK": 2.578,
    "out:LCP/MSEK": 1.392,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3442.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1533.75,
    "out:PV (% sold (El))": 4.37,
    "out:PV (kWh self-consumed)": 33528.96,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -92082,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307872,
    "out:DH kr savings": 462457,
    "out:El kr savings": -156540,
    "out:El kr sold": 1956,
    "out:El kr saved": 56999,
    "out:El kr return": 58955,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27780,
    "out:% savings (space heating)": 93.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.85,
    "out:Etvv": 0,
    "out:Ef": 45.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 44.35,
    "out:Total Energy Use Pre PV": 65.56,
    "out:Total Energy Use Post PV": 51.35,
    "out:Primary Energy": 82.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.49,
    "out:CO2 Operational/m2": 6.41,
    "out:Total CO2/m2": 33.9,
    "out:Total CO2 (tons)": 106.35,
    "out:Klimatpaverkan": -93.34,
    "out:Initial Cost/MSEK": 7.79675,
    "out:Running cost/(Apt SEK y)": 10727,
    "out:Running Cost over RSP/MSEK": 2.418,
    "out:LCP/MSEK": 1.474,
    "out:ROI% old": 16.99,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3390.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1184,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1652.16,
    "out:PV (% sold (El))": 4.71,
    "out:PV (kWh self-consumed)": 33410.55,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -89236,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318571,
    "out:DH kr savings": 468166,
    "out:El kr savings": -151701,
    "out:El kr sold": 2107,
    "out:El kr saved": 56798,
    "out:El kr return": 58904,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20975,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 44.35,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 44.53,
    "out:Total Energy Use Pre PV": 66.1,
    "out:Total Energy Use Post PV": 52.53,
    "out:Primary Energy": 83.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.91,
    "out:CO2 Operational/m2": 6.6,
    "out:Total CO2/m2": 34.51,
    "out:Total CO2 (tons)": 108.27,
    "out:Klimatpaverkan": -91.42,
    "out:Initial Cost/MSEK": 7.87775,
    "out:Running cost/(Apt SEK y)": 10929,
    "out:Running Cost over RSP/MSEK": 2.464,
    "out:LCP/MSEK": 1.347,
    "out:ROI% old": 16.73,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3360.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1723.28,
    "out:PV (% sold (El))": 4.91,
    "out:PV (kWh self-consumed)": 33339.43,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -89823,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314810,
    "out:DH kr savings": 465311,
    "out:El kr savings": -152698,
    "out:El kr sold": 2197,
    "out:El kr saved": 56677,
    "out:El kr return": 58874,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24061,
    "out:% savings (space heating)": 94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.37,
    "out:Etvv": 0,
    "out:Ef": 44.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.26,
    "out:Electricity (inc PV)": 43.6,
    "out:Total Energy Use Pre PV": 63.26,
    "out:Total Energy Use Post PV": 49.6,
    "out:Primary Energy": 81.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.91,
    "out:CO2 Operational/m2": 5.56,
    "out:Total CO2/m2": 33.47,
    "out:Total CO2 (tons)": 105,
    "out:Klimatpaverkan": -94.69,
    "out:Initial Cost/MSEK": 7.956125,
    "out:Running cost/(Apt SEK y)": 10208,
    "out:Running Cost over RSP/MSEK": 2.301,
    "out:LCP/MSEK": 1.432,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3303.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1199,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1860.84,
    "out:PV (% sold (El))": 5.31,
    "out:PV (kWh self-consumed)": 33201.87,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -86891,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325678,
    "out:DH kr savings": 471021,
    "out:El kr savings": -147715,
    "out:El kr sold": 2373,
    "out:El kr saved": 56443,
    "out:El kr return": 58816,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 17962,
    "out:% savings (space heating)": 95.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 43.6,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.33,
    "out:Electricity (inc PV)": 40.74,
    "out:Total Energy Use Pre PV": 68.33,
    "out:Total Energy Use Post PV": 49.74,
    "out:Primary Energy": 77.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.84,
    "out:CO2 Operational/m2": -24.71,
    "out:Total CO2/m2": 15.13,
    "out:Total CO2 (tons)": 47.48,
    "out:Klimatpaverkan": -152.21,
    "out:Initial Cost/MSEK": 8.370875,
    "out:Running cost/(Apt SEK y)": 9669,
    "out:Running Cost over RSP/MSEK": 2.188,
    "out:LCP/MSEK": 1.13,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3092.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21231.31,
    "out:PV (% sold (El))": 30.28,
    "out:PV (kWh self-consumed)": 48894.11,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 508194,
    "out:EL kWh savings": -77934,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 357038,
    "out:DH kr savings": 462457,
    "out:El kr savings": -132488,
    "out:El kr sold": 27070,
    "out:El kr saved": 83120,
    "out:El kr return": 110190,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 27780,
    "out:% savings (space heating)": 93.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.85,
    "out:Etvv": 0,
    "out:Ef": 40.74,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.56,
    "out:Electricity (inc PV)": 39.94,
    "out:Total Energy Use Pre PV": 65.56,
    "out:Total Energy Use Post PV": 46.94,
    "out:Primary Energy": 74.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.84,
    "out:CO2 Operational/m2": -26.14,
    "out:Total CO2/m2": 13.7,
    "out:Total CO2 (tons)": 42.99,
    "out:Klimatpaverkan": -156.7,
    "out:Initial Cost/MSEK": 8.44925,
    "out:Running cost/(Apt SEK y)": 8964,
    "out:Running Cost over RSP/MSEK": 2.028,
    "out:LCP/MSEK": 1.211,
    "out:ROI% old": 16.33,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3046.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21616.1,
    "out:PV (% sold (El))": 30.82,
    "out:PV (kWh self-consumed)": 48509.32,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514468,
    "out:EL kWh savings": -75409,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 367531,
    "out:DH kr savings": 468166,
    "out:El kr savings": -128195,
    "out:El kr sold": 27561,
    "out:El kr saved": 82466,
    "out:El kr return": 110026,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20975,
    "out:% savings (space heating)": 94.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.31,
    "out:Etvv": 0,
    "out:Ef": 39.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.1,
    "out:Electricity (inc PV)": 40.1,
    "out:Total Energy Use Pre PV": 66.1,
    "out:Total Energy Use Post PV": 48.1,
    "out:Primary Energy": 75.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.26,
    "out:CO2 Operational/m2": -26.2,
    "out:Total CO2/m2": 14.06,
    "out:Total CO2 (tons)": 44.12,
    "out:Klimatpaverkan": -155.57,
    "out:Initial Cost/MSEK": 8.53025,
    "out:Running cost/(Apt SEK y)": 9169,
    "out:Running Cost over RSP/MSEK": 2.075,
    "out:LCP/MSEK": 1.084,
    "out:ROI% old": 16.1,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3019.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21842.04,
    "out:PV (% sold (El))": 31.15,
    "out:PV (kWh self-consumed)": 48283.38,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -75930,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 364080,
    "out:DH kr savings": 465311,
    "out:El kr savings": -129080,
    "out:El kr sold": 27849,
    "out:El kr saved": 82082,
    "out:El kr return": 109930,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24061,
    "out:% savings (space heating)": 94,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 5.37,
    "out:Etvv": 0,
    "out:Ef": 40.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.26,
    "out:Electricity (inc PV)": 39.27,
    "out:Total Energy Use Pre PV": 63.26,
    "out:Total Energy Use Post PV": 45.27,
    "out:Primary Energy": 73.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 40.26,
    "out:CO2 Operational/m2": -27.71,
    "out:Total CO2/m2": 12.56,
    "out:Total CO2 (tons)": 39.39,
    "out:Klimatpaverkan": -160.3,
    "out:Initial Cost/MSEK": 8.608625,
    "out:Running cost/(Apt SEK y)": 8452,
    "out:Running Cost over RSP/MSEK": 1.912,
    "out:LCP/MSEK": 1.168,
    "out:ROI% old": 16.22,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2969.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1250,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22266.58,
    "out:PV (% sold (El))": 31.75,
    "out:PV (kWh self-consumed)": 47858.84,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -73325,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374757,
    "out:DH kr savings": 471021,
    "out:El kr savings": -124653,
    "out:El kr sold": 28390,
    "out:El kr saved": 81360,
    "out:El kr return": 109750,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 17962,
    "out:% savings (space heating)": 95.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.94,
    "out:Etvv": 0,
    "out:Ef": 39.27,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 115.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.95,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 66.17,
    "out:Total CO2 (tons)": 207.58,
    "out:Klimatpaverkan": 7.89,
    "out:Initial Cost/MSEK": 2.924625,
    "out:Running cost/(Apt SEK y)": 43294,
    "out:Running Cost over RSP/MSEK": 9.818,
    "out:LCP/MSEK": -1.053,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 239,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82785,
    "out:DH kr savings": 82785,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 112.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.95,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 64.73,
    "out:Total CO2 (tons)": 203.06,
    "out:Klimatpaverkan": 3.37,
    "out:Initial Cost/MSEK": 3.003,
    "out:Running cost/(Apt SEK y)": 42157,
    "out:Running Cost over RSP/MSEK": 9.56,
    "out:LCP/MSEK": -0.874,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103521,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 94204,
    "out:DH kr savings": 94204,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 112.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.37,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 64.43,
    "out:Total CO2 (tons)": 202.12,
    "out:Klimatpaverkan": 2.43,
    "out:Initial Cost/MSEK": 3.084,
    "out:Running cost/(Apt SEK y)": 41589,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": -0.826,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99913,
    "out:DH kr savings": 99913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 109.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.37,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 62.99,
    "out:Total CO2 (tons)": 197.6,
    "out:Klimatpaverkan": -2.09,
    "out:Initial Cost/MSEK": 3.162375,
    "out:Running cost/(Apt SEK y)": 40451,
    "out:Running Cost over RSP/MSEK": 9.172,
    "out:LCP/MSEK": -0.646,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111332,
    "out:DH kr savings": 111332,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 105.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.31,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 47.53,
    "out:Total CO2 (tons)": 149.1,
    "out:Klimatpaverkan": -50.59,
    "out:Initial Cost/MSEK": 3.577125,
    "out:Running cost/(Apt SEK y)": 41515,
    "out:Running Cost over RSP/MSEK": 9.425,
    "out:LCP/MSEK": -1.313,
    "out:ROI% old": 9.04,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 291,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134385,
    "out:DH kr savings": 82785,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 102.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.31,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 46.09,
    "out:Total CO2 (tons)": 144.58,
    "out:Klimatpaverkan": -55.11,
    "out:Initial Cost/MSEK": 3.6555,
    "out:Running cost/(Apt SEK y)": 40378,
    "out:Running Cost over RSP/MSEK": 9.167,
    "out:LCP/MSEK": -1.134,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 324,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 103521,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145804,
    "out:DH kr savings": 94204,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 103.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.73,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 45.79,
    "out:Total CO2 (tons)": 143.64,
    "out:Klimatpaverkan": -56.05,
    "out:Initial Cost/MSEK": 3.7365,
    "out:Running cost/(Apt SEK y)": 39809,
    "out:Running Cost over RSP/MSEK": 9.038,
    "out:LCP/MSEK": -1.086,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151513,
    "out:DH kr savings": 99913,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 100.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.73,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 44.35,
    "out:Total CO2 (tons)": 139.12,
    "out:Klimatpaverkan": -60.57,
    "out:Initial Cost/MSEK": 3.814875,
    "out:Running cost/(Apt SEK y)": 38672,
    "out:Running Cost over RSP/MSEK": 8.78,
    "out:LCP/MSEK": -0.906,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162932,
    "out:DH kr savings": 111332,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 104.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.66,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 7.2,
    "out:Total CO2 (tons)": 22.6,
    "out:Klimatpaverkan": -177.09,
    "out:Initial Cost/MSEK": 4.229625,
    "out:Running cost/(Apt SEK y)": 39934,
    "out:Running Cost over RSP/MSEK": 9.076,
    "out:LCP/MSEK": -1.617,
    "out:ROI% old": 8.82,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180222,
    "out:DH kr savings": 82785,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 101.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.66,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 5.76,
    "out:Total CO2 (tons)": 18.08,
    "out:Klimatpaverkan": -181.61,
    "out:Initial Cost/MSEK": 4.308125,
    "out:Running cost/(Apt SEK y)": 38797,
    "out:Running Cost over RSP/MSEK": 8.818,
    "out:LCP/MSEK": -1.438,
    "out:ROI% old": 9.52,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 103521,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191641,
    "out:DH kr savings": 94204,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 101.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.08,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 5.46,
    "out:Total CO2 (tons)": 17.14,
    "out:Klimatpaverkan": -182.55,
    "out:Initial Cost/MSEK": 4.389,
    "out:Running cost/(Apt SEK y)": 38229,
    "out:Running Cost over RSP/MSEK": 8.689,
    "out:LCP/MSEK": -1.39,
    "out:ROI% old": 9.76,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197350,
    "out:DH kr savings": 99913,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 98.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.08,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 4.02,
    "out:Total CO2 (tons)": 12.62,
    "out:Klimatpaverkan": -187.07,
    "out:Initial Cost/MSEK": 4.4675,
    "out:Running cost/(Apt SEK y)": 37091,
    "out:Running Cost over RSP/MSEK": 8.431,
    "out:LCP/MSEK": -1.21,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208769,
    "out:DH kr savings": 111332,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 149.9,
    "out:Primary Energy": 111.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.95,
    "out:CO2 Operational/m2": 50.34,
    "out:Total CO2/m2": 63.29,
    "out:Total CO2 (tons)": 198.54,
    "out:Klimatpaverkan": -1.15,
    "out:Initial Cost/MSEK": 3.001125,
    "out:Running cost/(Apt SEK y)": 41020,
    "out:Running Cost over RSP/MSEK": 9.301,
    "out:LCP/MSEK": -0.614,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105623,
    "out:DH kr savings": 105623,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 108.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.95,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 61.49,
    "out:Total CO2 (tons)": 192.9,
    "out:Klimatpaverkan": -6.79,
    "out:Initial Cost/MSEK": 3.079625,
    "out:Running cost/(Apt SEK y)": 39598,
    "out:Running Cost over RSP/MSEK": 8.979,
    "out:LCP/MSEK": -0.37,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119896,
    "out:DH kr savings": 119896,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 109.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.37,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 61.55,
    "out:Total CO2 (tons)": 193.08,
    "out:Klimatpaverkan": -6.61,
    "out:Initial Cost/MSEK": 3.1605,
    "out:Running cost/(Apt SEK y)": 39314,
    "out:Running Cost over RSP/MSEK": 8.914,
    "out:LCP/MSEK": -0.386,
    "out:ROI% old": 12.54,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122751,
    "out:DH kr savings": 122751,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 139.9,
    "out:Primary Energy": 106.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.37,
    "out:CO2 Operational/m2": 46.74,
    "out:Total CO2/m2": 60.11,
    "out:Total CO2 (tons)": 188.57,
    "out:Klimatpaverkan": -11.12,
    "out:Initial Cost/MSEK": 3.239,
    "out:Running cost/(Apt SEK y)": 38177,
    "out:Running Cost over RSP/MSEK": 8.656,
    "out:LCP/MSEK": -0.206,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134170,
    "out:DH kr savings": 134170,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 144.73,
    "out:Primary Energy": 102.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.31,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 44.65,
    "out:Total CO2 (tons)": 140.07,
    "out:Klimatpaverkan": -59.62,
    "out:Initial Cost/MSEK": 3.653625,
    "out:Running cost/(Apt SEK y)": 39241,
    "out:Running Cost over RSP/MSEK": 8.909,
    "out:LCP/MSEK": -0.874,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157223,
    "out:DH kr savings": 105623,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 99.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.31,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 42.85,
    "out:Total CO2 (tons)": 134.42,
    "out:Klimatpaverkan": -65.27,
    "out:Initial Cost/MSEK": 3.732125,
    "out:Running cost/(Apt SEK y)": 37819,
    "out:Running Cost over RSP/MSEK": 8.586,
    "out:LCP/MSEK": -0.629,
    "out:ROI% old": 11.88,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171496,
    "out:DH kr savings": 119896,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 100.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.73,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 42.91,
    "out:Total CO2 (tons)": 134.61,
    "out:Klimatpaverkan": -65.08,
    "out:Initial Cost/MSEK": 3.813,
    "out:Running cost/(Apt SEK y)": 37535,
    "out:Running Cost over RSP/MSEK": 8.522,
    "out:LCP/MSEK": -0.646,
    "out:ROI% old": 11.87,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174351,
    "out:DH kr savings": 122751,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 134.73,
    "out:Primary Energy": 97.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.73,
    "out:CO2 Operational/m2": 15.74,
    "out:Total CO2/m2": 41.47,
    "out:Total CO2 (tons)": 130.09,
    "out:Klimatpaverkan": -69.6,
    "out:Initial Cost/MSEK": 3.8915,
    "out:Running cost/(Apt SEK y)": 36398,
    "out:Running Cost over RSP/MSEK": 8.263,
    "out:LCP/MSEK": -0.466,
    "out:ROI% old": 12.57,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 185769,
    "out:DH kr savings": 134170,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 143.88,
    "out:Primary Energy": 100.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.66,
    "out:CO2 Operational/m2": -33.34,
    "out:Total CO2/m2": 4.32,
    "out:Total CO2 (tons)": 13.56,
    "out:Klimatpaverkan": -186.13,
    "out:Initial Cost/MSEK": 4.30625,
    "out:Running cost/(Apt SEK y)": 37660,
    "out:Running Cost over RSP/MSEK": 8.56,
    "out:LCP/MSEK": -1.178,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203059,
    "out:DH kr savings": 105623,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 97.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.66,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 2.52,
    "out:Total CO2 (tons)": 7.91,
    "out:Klimatpaverkan": -191.78,
    "out:Initial Cost/MSEK": 4.384625,
    "out:Running cost/(Apt SEK y)": 36239,
    "out:Running Cost over RSP/MSEK": 8.238,
    "out:LCP/MSEK": -0.933,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217333,
    "out:DH kr savings": 119896,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 98.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.08,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 2.58,
    "out:Total CO2 (tons)": 8.1,
    "out:Klimatpaverkan": -191.59,
    "out:Initial Cost/MSEK": 4.465625,
    "out:Running cost/(Apt SEK y)": 35954,
    "out:Running Cost over RSP/MSEK": 8.173,
    "out:LCP/MSEK": -0.95,
    "out:ROI% old": 11.25,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220187,
    "out:DH kr savings": 122751,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 309786,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.54,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 133.88,
    "out:Primary Energy": 95.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.08,
    "out:CO2 Operational/m2": -36.94,
    "out:Total CO2/m2": 1.14,
    "out:Total CO2 (tons)": 3.58,
    "out:Klimatpaverkan": -196.11,
    "out:Initial Cost/MSEK": 4.544,
    "out:Running cost/(Apt SEK y)": 34817,
    "out:Running Cost over RSP/MSEK": 7.915,
    "out:LCP/MSEK": -0.77,
    "out:ROI% old": 11.86,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231606,
    "out:DH kr savings": 134170,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 296560,
    "out:% savings (space heating)": 26.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.35,
    "out:Electricity (inc PV)": 42.05,
    "out:Total Energy Use Pre PV": 100.35,
    "out:Total Energy Use Post PV": 98.05,
    "out:Primary Energy": 107.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 26.01,
    "out:Total CO2/m2": 42.78,
    "out:Total CO2 (tons)": 134.19,
    "out:Klimatpaverkan": -65.5,
    "out:Initial Cost/MSEK": 5.647,
    "out:Running cost/(Apt SEK y)": 24076,
    "out:Running Cost over RSP/MSEK": 5.442,
    "out:LCP/MSEK": 0.6,
    "out:ROI% old": 15.8,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3104.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -82033,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188831,
    "out:DH kr savings": 328287,
    "out:El kr savings": -139456,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175544,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.05,
    "out:Etvv": 0,
    "out:Ef": 42.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 41.93,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 93.93,
    "out:Primary Energy": 104.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 24.56,
    "out:Total CO2/m2": 41.33,
    "out:Total CO2 (tons)": 129.64,
    "out:Klimatpaverkan": -70.05,
    "out:Initial Cost/MSEK": 5.725375,
    "out:Running cost/(Apt SEK y)": 22925,
    "out:Running Cost over RSP/MSEK": 5.18,
    "out:LCP/MSEK": 0.783,
    "out:ROI% old": 16.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3099.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -81657,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200889,
    "out:DH kr savings": 339706,
    "out:El kr savings": -138817,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163108,
    "out:% savings (space heating)": 59.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 41.22,
    "out:Etvv": 0,
    "out:Ef": 41.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.57,
    "out:Electricity (inc PV)": 41.55,
    "out:Total Energy Use Pre PV": 95.57,
    "out:Total Energy Use Post PV": 93.55,
    "out:Primary Energy": 104.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.18,
    "out:CO2 Operational/m2": 24.47,
    "out:Total CO2/m2": 41.65,
    "out:Total CO2 (tons)": 130.67,
    "out:Klimatpaverkan": -69.02,
    "out:Initial Cost/MSEK": 5.806375,
    "out:Running cost/(Apt SEK y)": 22796,
    "out:Running Cost over RSP/MSEK": 5.151,
    "out:LCP/MSEK": 0.731,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3050,
    "out:Return %": 14,
    "out:Return/kSEK/y": 834,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -80465,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202915,
    "out:DH kr savings": 339706,
    "out:El kr savings": -136791,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163129,
    "out:% savings (space heating)": 59.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.94,
    "out:Etvv": 0,
    "out:Ef": 41.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.48,
    "out:Electricity (inc PV)": 41.42,
    "out:Total Energy Use Pre PV": 91.48,
    "out:Total Energy Use Post PV": 89.42,
    "out:Primary Energy": 101.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.18,
    "out:CO2 Operational/m2": 23.02,
    "out:Total CO2/m2": 40.2,
    "out:Total CO2 (tons)": 126.11,
    "out:Klimatpaverkan": -73.58,
    "out:Initial Cost/MSEK": 5.88475,
    "out:Running cost/(Apt SEK y)": 21642,
    "out:Running Cost over RSP/MSEK": 4.89,
    "out:LCP/MSEK": 0.914,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3043.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -80052,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215036,
    "out:DH kr savings": 351124,
    "out:El kr savings": -136088,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 150841,
    "out:% savings (space heating)": 62.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.16,
    "out:Etvv": 0,
    "out:Ef": 41.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.35,
    "out:Electricity (inc PV)": 32.65,
    "out:Total Energy Use Pre PV": 100.35,
    "out:Total Energy Use Post PV": 88.65,
    "out:Primary Energy": 90.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 17.24,
    "out:Total CO2/m2": 46.36,
    "out:Total CO2 (tons)": 145.42,
    "out:Klimatpaverkan": -54.27,
    "out:Initial Cost/MSEK": 6.2995,
    "out:Running cost/(Apt SEK y)": 22092,
    "out:Running Cost over RSP/MSEK": 5.004,
    "out:LCP/MSEK": 0.386,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2456.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4838.02,
    "out:PV (% sold (El))": 13.8,
    "out:PV (kWh self-consumed)": 30224.69,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -52535,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245146,
    "out:DH kr savings": 328287,
    "out:El kr savings": -89309,
    "out:El kr sold": 6168,
    "out:El kr saved": 51382,
    "out:El kr return": 57550,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175544,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.05,
    "out:Etvv": 0,
    "out:Ef": 32.65,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 32.54,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 84.54,
    "out:Primary Energy": 87.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 15.75,
    "out:Total CO2/m2": 44.87,
    "out:Total CO2 (tons)": 140.76,
    "out:Klimatpaverkan": -58.93,
    "out:Initial Cost/MSEK": 6.378,
    "out:Running cost/(Apt SEK y)": 20941,
    "out:Running Cost over RSP/MSEK": 4.742,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2451.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 887,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4861.2,
    "out:PV (% sold (El))": 13.86,
    "out:PV (kWh self-consumed)": 30201.51,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -52198,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257168,
    "out:DH kr savings": 339706,
    "out:El kr savings": -88736,
    "out:El kr sold": 6198,
    "out:El kr saved": 51343,
    "out:El kr return": 57541,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163108,
    "out:% savings (space heating)": 59.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 41.22,
    "out:Etvv": 0,
    "out:Ef": 32.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.57,
    "out:Electricity (inc PV)": 32.2,
    "out:Total Energy Use Pre PV": 95.57,
    "out:Total Energy Use Post PV": 84.2,
    "out:Primary Energy": 88.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": 15.32,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 140.73,
    "out:Klimatpaverkan": -58.96,
    "out:Initial Cost/MSEK": 6.458875,
    "out:Running cost/(Apt SEK y)": 20815,
    "out:Running Cost over RSP/MSEK": 4.714,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2406.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5078.14,
    "out:PV (% sold (El))": 14.48,
    "out:PV (kWh self-consumed)": 29984.57,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -51132,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259257,
    "out:DH kr savings": 339706,
    "out:El kr savings": -86924,
    "out:El kr sold": 6475,
    "out:El kr saved": 50974,
    "out:El kr return": 57448,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163129,
    "out:% savings (space heating)": 59.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.94,
    "out:Etvv": 0,
    "out:Ef": 32.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.48,
    "out:Electricity (inc PV)": 32.08,
    "out:Total Energy Use Pre PV": 91.48,
    "out:Total Energy Use Post PV": 80.08,
    "out:Primary Energy": 85.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": 13.83,
    "out:Total CO2/m2": 43.36,
    "out:Total CO2 (tons)": 136.03,
    "out:Klimatpaverkan": -63.66,
    "out:Initial Cost/MSEK": 6.537375,
    "out:Running cost/(Apt SEK y)": 19662,
    "out:Running Cost over RSP/MSEK": 4.452,
    "out:LCP/MSEK": 0.699,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2400.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5106.54,
    "out:PV (% sold (El))": 14.56,
    "out:PV (kWh self-consumed)": 29956.17,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -50763,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271339,
    "out:DH kr savings": 351124,
    "out:El kr savings": -86296,
    "out:El kr sold": 6511,
    "out:El kr saved": 50925,
    "out:El kr return": 57436,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 150841,
    "out:% savings (space heating)": 62.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.16,
    "out:Etvv": 0,
    "out:Ef": 32.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.35,
    "out:Electricity (inc PV)": 29.51,
    "out:Total Energy Use Pre PV": 100.35,
    "out:Total Energy Use Post PV": 85.51,
    "out:Primary Energy": 84.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.47,
    "out:CO2 Operational/m2": -22.73,
    "out:Total CO2/m2": 18.74,
    "out:Total CO2 (tons)": 58.78,
    "out:Klimatpaverkan": -140.91,
    "out:Initial Cost/MSEK": 6.952,
    "out:Running cost/(Apt SEK y)": 20396,
    "out:Running Cost over RSP/MSEK": 4.629,
    "out:LCP/MSEK": 0.108,
    "out:ROI% old": 14.51,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2216.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29372.29,
    "out:PV (% sold (El))": 41.89,
    "out:PV (kWh self-consumed)": 40753.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -42682,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293178,
    "out:DH kr savings": 328287,
    "out:El kr savings": -72559,
    "out:El kr sold": 37450,
    "out:El kr saved": 69280,
    "out:El kr return": 106730,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175544,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 45.05,
    "out:Etvv": 0,
    "out:Ef": 29.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.27,
    "out:Electricity (inc PV)": 29.41,
    "out:Total Energy Use Pre PV": 96.27,
    "out:Total Energy Use Post PV": 81.41,
    "out:Primary Energy": 81.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.47,
    "out:CO2 Operational/m2": -24.26,
    "out:Total CO2/m2": 17.21,
    "out:Total CO2 (tons)": 54,
    "out:Klimatpaverkan": -145.69,
    "out:Initial Cost/MSEK": 7.0305,
    "out:Running cost/(Apt SEK y)": 19245,
    "out:Running Cost over RSP/MSEK": 4.368,
    "out:LCP/MSEK": 0.29,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2212.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 937,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29418.01,
    "out:PV (% sold (El))": 41.95,
    "out:PV (kWh self-consumed)": 40707.41,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -42379,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305169,
    "out:DH kr savings": 339706,
    "out:El kr savings": -72045,
    "out:El kr sold": 37508,
    "out:El kr saved": 69203,
    "out:El kr return": 106711,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163108,
    "out:% savings (space heating)": 59.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 41.22,
    "out:Etvv": 0,
    "out:Ef": 29.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.57,
    "out:Electricity (inc PV)": 29.1,
    "out:Total Energy Use Pre PV": 95.57,
    "out:Total Energy Use Post PV": 81.1,
    "out:Primary Energy": 82.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.89,
    "out:CO2 Operational/m2": -25.02,
    "out:Total CO2/m2": 16.86,
    "out:Total CO2 (tons)": 52.9,
    "out:Klimatpaverkan": -146.79,
    "out:Initial Cost/MSEK": 7.111375,
    "out:Running cost/(Apt SEK y)": 19122,
    "out:Running Cost over RSP/MSEK": 4.341,
    "out:LCP/MSEK": 0.237,
    "out:ROI% old": 14.76,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2172.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29841.67,
    "out:PV (% sold (El))": 42.55,
    "out:PV (kWh self-consumed)": 40283.74,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -41424,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307333,
    "out:DH kr savings": 339706,
    "out:El kr savings": -70421,
    "out:El kr sold": 38048,
    "out:El kr saved": 68482,
    "out:El kr return": 106530,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 163129,
    "out:% savings (space heating)": 59.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.94,
    "out:Etvv": 0,
    "out:Ef": 29.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.48,
    "out:Electricity (inc PV)": 29,
    "out:Total Energy Use Pre PV": 91.48,
    "out:Total Energy Use Post PV": 77,
    "out:Primary Energy": 79.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.89,
    "out:CO2 Operational/m2": -26.56,
    "out:Total CO2/m2": 15.33,
    "out:Total CO2 (tons)": 48.07,
    "out:Klimatpaverkan": -151.62,
    "out:Initial Cost/MSEK": 7.189875,
    "out:Running cost/(Apt SEK y)": 17969,
    "out:Running Cost over RSP/MSEK": 4.079,
    "out:LCP/MSEK": 0.42,
    "out:ROI% old": 15.12,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2167.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29896.63,
    "out:PV (% sold (El))": 42.63,
    "out:PV (kWh self-consumed)": 40228.79,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -41093,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 319385,
    "out:DH kr savings": 351124,
    "out:El kr savings": -69858,
    "out:El kr sold": 38118,
    "out:El kr saved": 68389,
    "out:El kr return": 106507,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 150841,
    "out:% savings (space heating)": 62.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.16,
    "out:Etvv": 0,
    "out:Ef": 29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 41.38,
    "out:Total Energy Use Pre PV": 94.3,
    "out:Total Energy Use Post PV": 92.38,
    "out:Primary Energy": 104.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 24.08,
    "out:Total CO2/m2": 40.84,
    "out:Total CO2 (tons)": 128.11,
    "out:Klimatpaverkan": -71.58,
    "out:Initial Cost/MSEK": 5.7235,
    "out:Running cost/(Apt SEK y)": 22463,
    "out:Running Cost over RSP/MSEK": 5.076,
    "out:LCP/MSEK": 0.889,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 3031.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -79933,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206675,
    "out:DH kr savings": 342560,
    "out:El kr savings": -135886,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 159071,
    "out:% savings (space heating)": 60.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.25,
    "out:Etvv": 0,
    "out:Ef": 41.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.21,
    "out:Electricity (inc PV)": 41.25,
    "out:Total Energy Use Pre PV": 90.21,
    "out:Total Energy Use Post PV": 88.25,
    "out:Primary Energy": 101.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.76,
    "out:CO2 Operational/m2": 22.62,
    "out:Total CO2/m2": 39.39,
    "out:Total CO2 (tons)": 123.56,
    "out:Klimatpaverkan": -76.13,
    "out:Initial Cost/MSEK": 5.802,
    "out:Running cost/(Apt SEK y)": 21308,
    "out:Running Cost over RSP/MSEK": 4.814,
    "out:LCP/MSEK": 1.073,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3024.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -79509,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218813,
    "out:DH kr savings": 353979,
    "out:El kr savings": -135166,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 146840,
    "out:% savings (space heating)": 63.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.48,
    "out:Etvv": 0,
    "out:Ef": 41.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 90.65,
    "out:Total Energy Use Post PV": 88.97,
    "out:Primary Energy": 102.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.18,
    "out:CO2 Operational/m2": 22.91,
    "out:Total CO2/m2": 40.09,
    "out:Total CO2 (tons)": 125.77,
    "out:Klimatpaverkan": -73.92,
    "out:Initial Cost/MSEK": 5.882875,
    "out:Running cost/(Apt SEK y)": 21489,
    "out:Running Cost over RSP/MSEK": 4.855,
    "out:LCP/MSEK": 0.951,
    "out:ROI% old": 16.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2985.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -78640,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217436,
    "out:DH kr savings": 351124,
    "out:El kr savings": -133688,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149512,
    "out:% savings (space heating)": 62.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.61,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.54,
    "out:Electricity (inc PV)": 40.83,
    "out:Total Energy Use Pre PV": 86.54,
    "out:Total Energy Use Post PV": 84.83,
    "out:Primary Energy": 99.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.18,
    "out:CO2 Operational/m2": 21.45,
    "out:Total CO2/m2": 38.64,
    "out:Total CO2 (tons)": 121.21,
    "out:Klimatpaverkan": -78.48,
    "out:Initial Cost/MSEK": 5.961375,
    "out:Running cost/(Apt SEK y)": 20331,
    "out:Running Cost over RSP/MSEK": 4.593,
    "out:LCP/MSEK": 1.135,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2977.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 905,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -78191,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229618,
    "out:DH kr savings": 362543,
    "out:El kr savings": -132925,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137409,
    "out:% savings (space heating)": 65.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 40.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 32.05,
    "out:Total Energy Use Pre PV": 94.3,
    "out:Total Energy Use Post PV": 83.05,
    "out:Primary Energy": 87.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 14.8,
    "out:Total CO2/m2": 43.91,
    "out:Total CO2 (tons)": 137.76,
    "out:Klimatpaverkan": -61.93,
    "out:Initial Cost/MSEK": 6.376,
    "out:Running cost/(Apt SEK y)": 20483,
    "out:Running Cost over RSP/MSEK": 4.639,
    "out:LCP/MSEK": 0.674,
    "out:ROI% old": 15.8,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2389.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5162.02,
    "out:PV (% sold (El))": 14.72,
    "out:PV (kWh self-consumed)": 29900.68,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -50656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263026,
    "out:DH kr savings": 342560,
    "out:El kr savings": -86116,
    "out:El kr sold": 6582,
    "out:El kr saved": 50831,
    "out:El kr return": 57413,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 159071,
    "out:% savings (space heating)": 60.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.25,
    "out:Etvv": 0,
    "out:Ef": 32.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.21,
    "out:Electricity (inc PV)": 31.93,
    "out:Total Energy Use Pre PV": 90.21,
    "out:Total Energy Use Post PV": 78.93,
    "out:Primary Energy": 84.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 13.3,
    "out:Total CO2/m2": 42.41,
    "out:Total CO2 (tons)": 133.05,
    "out:Klimatpaverkan": -66.64,
    "out:Initial Cost/MSEK": 6.4545,
    "out:Running cost/(Apt SEK y)": 19328,
    "out:Running Cost over RSP/MSEK": 4.377,
    "out:LCP/MSEK": 0.857,
    "out:ROI% old": 16.18,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2383.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5192.29,
    "out:PV (% sold (El))": 14.81,
    "out:PV (kWh self-consumed)": 29870.41,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -50278,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275126,
    "out:DH kr savings": 353979,
    "out:El kr savings": -85473,
    "out:El kr sold": 6620,
    "out:El kr saved": 50780,
    "out:El kr return": 57400,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 146840,
    "out:% savings (space heating)": 63.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.48,
    "out:Etvv": 0,
    "out:Ef": 31.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 31.68,
    "out:Total Energy Use Pre PV": 90.65,
    "out:Total Energy Use Post PV": 79.68,
    "out:Primary Energy": 85.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": 13.3,
    "out:Total CO2/m2": 42.84,
    "out:Total CO2 (tons)": 134.38,
    "out:Klimatpaverkan": -65.31,
    "out:Initial Cost/MSEK": 6.535375,
    "out:Running cost/(Apt SEK y)": 19512,
    "out:Running Cost over RSP/MSEK": 4.419,
    "out:LCP/MSEK": 0.734,
    "out:ROI% old": 15.89,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2347.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5371.46,
    "out:PV (% sold (El))": 15.32,
    "out:PV (kWh self-consumed)": 29691.25,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -49503,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273818,
    "out:DH kr savings": 351124,
    "out:El kr savings": -84155,
    "out:El kr sold": 6849,
    "out:El kr saved": 50475,
    "out:El kr return": 57324,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149512,
    "out:% savings (space heating)": 62.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.61,
    "out:Etvv": 0,
    "out:Ef": 31.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.54,
    "out:Electricity (inc PV)": 31.55,
    "out:Total Energy Use Pre PV": 86.54,
    "out:Total Energy Use Post PV": 75.55,
    "out:Primary Energy": 82.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.54,
    "out:CO2 Operational/m2": 11.79,
    "out:Total CO2/m2": 41.33,
    "out:Total CO2 (tons)": 129.64,
    "out:Klimatpaverkan": -70.05,
    "out:Initial Cost/MSEK": 6.613875,
    "out:Running cost/(Apt SEK y)": 18355,
    "out:Running Cost over RSP/MSEK": 4.156,
    "out:LCP/MSEK": 0.918,
    "out:ROI% old": 16.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2340.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5406.34,
    "out:PV (% sold (El))": 15.42,
    "out:PV (kWh self-consumed)": 29656.37,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -49103,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285962,
    "out:DH kr savings": 362543,
    "out:El kr savings": -83474,
    "out:El kr sold": 6893,
    "out:El kr saved": 50416,
    "out:El kr return": 57309,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137409,
    "out:% savings (space heating)": 65.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 31.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.3,
    "out:Electricity (inc PV)": 28.97,
    "out:Total Energy Use Pre PV": 94.3,
    "out:Total Energy Use Post PV": 79.97,
    "out:Primary Energy": 81.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.47,
    "out:CO2 Operational/m2": -25.68,
    "out:Total CO2/m2": 15.79,
    "out:Total CO2 (tons)": 49.54,
    "out:Klimatpaverkan": -150.15,
    "out:Initial Cost/MSEK": 7.028625,
    "out:Running cost/(Apt SEK y)": 18791,
    "out:Running Cost over RSP/MSEK": 4.266,
    "out:LCP/MSEK": 0.395,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2157.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 950,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.1,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30003.92,
    "out:PV (% sold (El))": 42.79,
    "out:PV (kWh self-consumed)": 40121.5,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -40997,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311120,
    "out:DH kr savings": 342560,
    "out:El kr savings": -69696,
    "out:El kr sold": 38255,
    "out:El kr saved": 68207,
    "out:El kr return": 106462,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 159071,
    "out:% savings (space heating)": 60.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.25,
    "out:Etvv": 0,
    "out:Ef": 28.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.21,
    "out:Electricity (inc PV)": 28.86,
    "out:Total Energy Use Pre PV": 90.21,
    "out:Total Energy Use Post PV": 75.86,
    "out:Primary Energy": 78.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.47,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 14.25,
    "out:Total CO2 (tons)": 44.69,
    "out:Klimatpaverkan": -155,
    "out:Initial Cost/MSEK": 7.107,
    "out:Running cost/(Apt SEK y)": 17637,
    "out:Running Cost over RSP/MSEK": 4.004,
    "out:LCP/MSEK": 0.578,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2151.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 983,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30062.44,
    "out:PV (% sold (El))": 42.87,
    "out:PV (kWh self-consumed)": 40062.97,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -40658,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323189,
    "out:DH kr savings": 353979,
    "out:El kr savings": -69119,
    "out:El kr sold": 38330,
    "out:El kr saved": 68107,
    "out:El kr return": 106437,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 146840,
    "out:% savings (space heating)": 63.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.48,
    "out:Etvv": 0,
    "out:Ef": 28.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.65,
    "out:Electricity (inc PV)": 28.64,
    "out:Total Energy Use Pre PV": 90.65,
    "out:Total Energy Use Post PV": 76.64,
    "out:Primary Energy": 79.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.89,
    "out:CO2 Operational/m2": -27.49,
    "out:Total CO2/m2": 14.4,
    "out:Total CO2 (tons)": 45.18,
    "out:Klimatpaverkan": -154.51,
    "out:Initial Cost/MSEK": 7.188,
    "out:Running cost/(Apt SEK y)": 17823,
    "out:Running Cost over RSP/MSEK": 4.046,
    "out:LCP/MSEK": 0.455,
    "out:ROI% old": 15.19,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2119.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 978,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30406.78,
    "out:PV (% sold (El))": 43.36,
    "out:PV (kWh self-consumed)": 39718.64,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -39963,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 321956,
    "out:DH kr savings": 351124,
    "out:El kr savings": -67937,
    "out:El kr sold": 38769,
    "out:El kr saved": 67522,
    "out:El kr return": 106290,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149512,
    "out:% savings (space heating)": 62.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.61,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.54,
    "out:Electricity (inc PV)": 28.52,
    "out:Total Energy Use Pre PV": 86.54,
    "out:Total Energy Use Post PV": 72.52,
    "out:Primary Energy": 77.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.89,
    "out:CO2 Operational/m2": -29.05,
    "out:Total CO2/m2": 12.84,
    "out:Total CO2 (tons)": 40.28,
    "out:Klimatpaverkan": -159.41,
    "out:Initial Cost/MSEK": 7.266375,
    "out:Running cost/(Apt SEK y)": 16667,
    "out:Running Cost over RSP/MSEK": 3.784,
    "out:LCP/MSEK": 0.639,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2113.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30473.5,
    "out:PV (% sold (El))": 43.46,
    "out:PV (kWh self-consumed)": 39651.91,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -39604,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334070,
    "out:DH kr savings": 362543,
    "out:El kr savings": -67326,
    "out:El kr sold": 38854,
    "out:El kr saved": 67408,
    "out:El kr return": 106262,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137409,
    "out:% savings (space heating)": 65.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.87,
    "out:Etvv": 0,
    "out:Ef": 28.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 54.65,
    "out:Total Energy Use Pre PV": 65.43,
    "out:Total Energy Use Post PV": 60.65,
    "out:Primary Energy": 100.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 10.01,
    "out:Total CO2/m2": 27.41,
    "out:Total CO2 (tons)": 85.99,
    "out:Klimatpaverkan": -113.7,
    "out:Initial Cost/MSEK": 7.503375,
    "out:Running cost/(Apt SEK y)": 12635,
    "out:Running Cost over RSP/MSEK": 2.837,
    "out:LCP/MSEK": 1.348,
    "out:ROI% old": 16.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4160.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1128,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -121552,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264381,
    "out:DH kr savings": 471021,
    "out:El kr savings": -206639,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 19379,
    "out:% savings (space heating)": 95.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 54.65,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 53.67,
    "out:Total Energy Use Pre PV": 63.63,
    "out:Total Energy Use Post PV": 58.67,
    "out:Primary Energy": 98.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 9.54,
    "out:Total CO2/m2": 26.95,
    "out:Total CO2 (tons)": 84.53,
    "out:Klimatpaverkan": -115.16,
    "out:Initial Cost/MSEK": 7.58175,
    "out:Running cost/(Apt SEK y)": 12204,
    "out:Running Cost over RSP/MSEK": 2.74,
    "out:LCP/MSEK": 1.367,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4104.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -118498,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272428,
    "out:DH kr savings": 473875,
    "out:El kr savings": -201447,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14431,
    "out:% savings (space heating)": 96.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 53.67,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.8,
    "out:Electricity (inc PV)": 53.63,
    "out:Total Energy Use Pre PV": 62.8,
    "out:Total Energy Use Post PV": 58.63,
    "out:Primary Energy": 98.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 9.43,
    "out:Total CO2/m2": 27.26,
    "out:Total CO2 (tons)": 85.5,
    "out:Klimatpaverkan": -114.19,
    "out:Initial Cost/MSEK": 7.66275,
    "out:Running cost/(Apt SEK y)": 12050,
    "out:Running Cost over RSP/MSEK": 2.706,
    "out:LCP/MSEK": 1.32,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4046,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1145,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -118364,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272657,
    "out:DH kr savings": 473875,
    "out:El kr savings": -201219,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15833,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 53.63,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.92,
    "out:Electricity (inc PV)": 52.63,
    "out:Total Energy Use Pre PV": 60.92,
    "out:Total Energy Use Post PV": 56.63,
    "out:Primary Energy": 96.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 8.95,
    "out:Total CO2/m2": 26.78,
    "out:Total CO2 (tons)": 84.01,
    "out:Klimatpaverkan": -115.68,
    "out:Initial Cost/MSEK": 7.741125,
    "out:Running cost/(Apt SEK y)": 11605,
    "out:Running Cost over RSP/MSEK": 2.605,
    "out:LCP/MSEK": 1.343,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3984.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1158,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -115237,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280827,
    "out:DH kr savings": 476730,
    "out:El kr savings": -195903,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11673,
    "out:% savings (space heating)": 97.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.15,
    "out:Etvv": 0,
    "out:Ef": 52.63,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 44.21,
    "out:Total Energy Use Pre PV": 65.43,
    "out:Total Energy Use Post PV": 50.21,
    "out:Primary Energy": 82.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.76,
    "out:CO2 Operational/m2": 6.37,
    "out:Total CO2/m2": 36.14,
    "out:Total CO2 (tons)": 113.36,
    "out:Klimatpaverkan": -86.33,
    "out:Initial Cost/MSEK": 8.155875,
    "out:Running cost/(Apt SEK y)": 10602,
    "out:Running Cost over RSP/MSEK": 2.389,
    "out:LCP/MSEK": 1.144,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3449.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1187,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1517.6,
    "out:PV (% sold (El))": 4.33,
    "out:PV (kWh self-consumed)": 33545.11,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -88819,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 321964,
    "out:DH kr savings": 471021,
    "out:El kr savings": -150992,
    "out:El kr sold": 1935,
    "out:El kr saved": 57027,
    "out:El kr return": 58962,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 19379,
    "out:% savings (space heating)": 95.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 44.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 43.3,
    "out:Total Energy Use Pre PV": 63.63,
    "out:Total Energy Use Post PV": 48.3,
    "out:Primary Energy": 79.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.76,
    "out:CO2 Operational/m2": 5.72,
    "out:Total CO2/m2": 35.48,
    "out:Total CO2 (tons)": 111.31,
    "out:Klimatpaverkan": -88.38,
    "out:Initial Cost/MSEK": 8.234375,
    "out:Running cost/(Apt SEK y)": 10173,
    "out:Running Cost over RSP/MSEK": 2.292,
    "out:LCP/MSEK": 1.163,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3395.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1200,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1639.76,
    "out:PV (% sold (El))": 4.68,
    "out:PV (kWh self-consumed)": 33422.95,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -85956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329841,
    "out:DH kr savings": 473875,
    "out:El kr savings": -146125,
    "out:El kr sold": 2091,
    "out:El kr saved": 56819,
    "out:El kr return": 58910,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14431,
    "out:% savings (space heating)": 96.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 43.3,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.8,
    "out:Electricity (inc PV)": 43.26,
    "out:Total Energy Use Pre PV": 62.8,
    "out:Total Energy Use Post PV": 48.26,
    "out:Primary Energy": 80.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.18,
    "out:CO2 Operational/m2": 5.41,
    "out:Total CO2/m2": 35.59,
    "out:Total CO2 (tons)": 111.64,
    "out:Klimatpaverkan": -88.05,
    "out:Initial Cost/MSEK": 8.31525,
    "out:Running cost/(Apt SEK y)": 10021,
    "out:Running Cost over RSP/MSEK": 2.258,
    "out:LCP/MSEK": 1.116,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3339.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1772.36,
    "out:PV (% sold (El))": 5.05,
    "out:PV (kWh self-consumed)": 33290.35,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -85830,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330224,
    "out:DH kr savings": 473875,
    "out:El kr savings": -145911,
    "out:El kr sold": 2260,
    "out:El kr saved": 56594,
    "out:El kr return": 58853,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15833,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 43.26,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.92,
    "out:Electricity (inc PV)": 42.33,
    "out:Total Energy Use Pre PV": 60.92,
    "out:Total Energy Use Post PV": 46.33,
    "out:Primary Energy": 77.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.18,
    "out:CO2 Operational/m2": 4.71,
    "out:Total CO2/m2": 34.89,
    "out:Total CO2 (tons)": 109.45,
    "out:Klimatpaverkan": -90.24,
    "out:Initial Cost/MSEK": 8.39375,
    "out:Running cost/(Apt SEK y)": 9578,
    "out:Running Cost over RSP/MSEK": 2.158,
    "out:LCP/MSEK": 1.137,
    "out:ROI% old": 16.22,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3280.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1918.27,
    "out:PV (% sold (El))": 5.47,
    "out:PV (kWh self-consumed)": 33144.44,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -82908,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338232,
    "out:DH kr savings": 476730,
    "out:El kr savings": -140944,
    "out:El kr sold": 2446,
    "out:El kr saved": 56346,
    "out:El kr return": 58791,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11673,
    "out:% savings (space heating)": 97.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.15,
    "out:Etvv": 0,
    "out:Ef": 42.33,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 39.82,
    "out:Total Energy Use Pre PV": 65.43,
    "out:Total Energy Use Post PV": 45.82,
    "out:Primary Energy": 74.11,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.11,
    "out:CO2 Operational/m2": -25.69,
    "out:Total CO2/m2": 16.42,
    "out:Total CO2 (tons)": 51.52,
    "out:Klimatpaverkan": -148.17,
    "out:Initial Cost/MSEK": 8.808375,
    "out:Running cost/(Apt SEK y)": 8835,
    "out:Running Cost over RSP/MSEK": 1.998,
    "out:LCP/MSEK": 0.882,
    "out:ROI% old": 15.72,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3098.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1238,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21178.31,
    "out:PV (% sold (El))": 30.2,
    "out:PV (kWh self-consumed)": 48947.11,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -75039,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370457,
    "out:DH kr savings": 471021,
    "out:El kr savings": -127566,
    "out:El kr sold": 27002,
    "out:El kr saved": 83210,
    "out:El kr return": 110212,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 19379,
    "out:% savings (space heating)": 95.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.47,
    "out:Etvv": 0,
    "out:Ef": 39.82,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.63,
    "out:Electricity (inc PV)": 39.01,
    "out:Total Energy Use Pre PV": 63.63,
    "out:Total Energy Use Post PV": 44.01,
    "out:Primary Energy": 71.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.11,
    "out:CO2 Operational/m2": -26.79,
    "out:Total CO2/m2": 15.33,
    "out:Total CO2 (tons)": 48.08,
    "out:Klimatpaverkan": -151.61,
    "out:Initial Cost/MSEK": 8.886875,
    "out:Running cost/(Apt SEK y)": 8409,
    "out:Running Cost over RSP/MSEK": 1.902,
    "out:LCP/MSEK": 0.9,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3050.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1251,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21576.3,
    "out:PV (% sold (El))": 30.77,
    "out:PV (kWh self-consumed)": 48549.11,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -72494,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378146,
    "out:DH kr savings": 473875,
    "out:El kr savings": -123239,
    "out:El kr sold": 27510,
    "out:El kr saved": 82533,
    "out:El kr return": 110043,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14431,
    "out:% savings (space heating)": 96.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.47,
    "out:Etvv": 0,
    "out:Ef": 39.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.8,
    "out:Electricity (inc PV)": 38.97,
    "out:Total Energy Use Pre PV": 62.8,
    "out:Total Energy Use Post PV": 43.97,
    "out:Primary Energy": 72.31,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.53,
    "out:CO2 Operational/m2": -27.56,
    "out:Total CO2/m2": 14.97,
    "out:Total CO2 (tons)": 46.96,
    "out:Klimatpaverkan": -152.73,
    "out:Initial Cost/MSEK": 8.96775,
    "out:Running cost/(Apt SEK y)": 8262,
    "out:Running Cost over RSP/MSEK": 1.869,
    "out:LCP/MSEK": 0.852,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3001.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1255,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21995.77,
    "out:PV (% sold (El))": 31.37,
    "out:PV (kWh self-consumed)": 48129.65,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -72382,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378871,
    "out:DH kr savings": 473875,
    "out:El kr savings": -123049,
    "out:El kr sold": 28045,
    "out:El kr saved": 81820,
    "out:El kr return": 109865,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15833,
    "out:% savings (space heating)": 96.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 38.97,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.92,
    "out:Electricity (inc PV)": 38.15,
    "out:Total Energy Use Pre PV": 60.92,
    "out:Total Energy Use Post PV": 42.15,
    "out:Primary Energy": 70.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.53,
    "out:CO2 Operational/m2": -28.75,
    "out:Total CO2/m2": 13.79,
    "out:Total CO2 (tons)": 43.25,
    "out:Klimatpaverkan": -156.44,
    "out:Initial Cost/MSEK": 9.04625,
    "out:Running cost/(Apt SEK y)": 7823,
    "out:Running Cost over RSP/MSEK": 1.77,
    "out:LCP/MSEK": 0.873,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2949.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1268,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.72,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22439.36,
    "out:PV (% sold (El))": 32,
    "out:PV (kWh self-consumed)": 47686.06,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -69784,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 386707,
    "out:DH kr savings": 476730,
    "out:El kr savings": -118633,
    "out:El kr sold": 28610,
    "out:El kr saved": 81066,
    "out:El kr return": 109676,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11673,
    "out:% savings (space heating)": 97.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.15,
    "out:Etvv": 0,
    "out:Ef": 38.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.24,
    "out:Electricity (inc PV)": 53.29,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 58.29,
    "out:Primary Energy": 97.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 9.36,
    "out:Total CO2/m2": 26.76,
    "out:Total CO2 (tons)": 83.96,
    "out:Klimatpaverkan": -115.73,
    "out:Initial Cost/MSEK": 7.579875,
    "out:Running cost/(Apt SEK y)": 11948,
    "out:Running Cost over RSP/MSEK": 2.682,
    "out:LCP/MSEK": 1.426,
    "out:ROI% old": 16.97,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4006.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1148,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -117294,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274475,
    "out:DH kr savings": 473875,
    "out:El kr savings": -199400,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14801,
    "out:% savings (space heating)": 96.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 53.29,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.34,
    "out:Electricity (inc PV)": 52.29,
    "out:Total Energy Use Pre PV": 59.34,
    "out:Total Energy Use Post PV": 55.29,
    "out:Primary Energy": 95.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.41,
    "out:CO2 Operational/m2": 8.52,
    "out:Total CO2/m2": 25.93,
    "out:Total CO2 (tons)": 81.33,
    "out:Klimatpaverkan": -118.36,
    "out:Initial Cost/MSEK": 7.658375,
    "out:Running cost/(Apt SEK y)": 11214,
    "out:Running Cost over RSP/MSEK": 2.516,
    "out:LCP/MSEK": 1.514,
    "out:ROI% old": 17.11,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3944,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1169,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -114144,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285540,
    "out:DH kr savings": 479585,
    "out:El kr savings": -194044,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 10862,
    "out:% savings (space heating)": 97.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 52.29,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.87,
    "out:Electricity (inc PV)": 52.47,
    "out:Total Energy Use Pre PV": 59.87,
    "out:Total Energy Use Post PV": 56.47,
    "out:Primary Energy": 96.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 8.82,
    "out:Total CO2/m2": 26.64,
    "out:Total CO2 (tons)": 83.58,
    "out:Klimatpaverkan": -116.11,
    "out:Initial Cost/MSEK": 7.73925,
    "out:Running cost/(Apt SEK y)": 11412,
    "out:Running Cost over RSP/MSEK": 2.562,
    "out:LCP/MSEK": 1.388,
    "out:ROI% old": 16.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3911,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -114712,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281719,
    "out:DH kr savings": 476730,
    "out:El kr savings": -195011,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12591,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 52.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.93,
    "out:Electricity (inc PV)": 51.45,
    "out:Total Energy Use Pre PV": 57.93,
    "out:Total Energy Use Post PV": 54.45,
    "out:Primary Energy": 93.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 8.33,
    "out:Total CO2/m2": 26.16,
    "out:Total CO2 (tons)": 82.06,
    "out:Klimatpaverkan": -117.63,
    "out:Initial Cost/MSEK": 7.81775,
    "out:Running cost/(Apt SEK y)": 10954,
    "out:Running Cost over RSP/MSEK": 2.458,
    "out:LCP/MSEK": 1.413,
    "out:ROI% old": 16.87,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3845.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1177,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -111510,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290018,
    "out:DH kr savings": 479585,
    "out:El kr savings": -189566,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9108,
    "out:% savings (space heating)": 97.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 51.45,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.24,
    "out:Electricity (inc PV)": 42.94,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 47.94,
    "out:Primary Energy": 79.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.76,
    "out:CO2 Operational/m2": 5.19,
    "out:Total CO2/m2": 34.95,
    "out:Total CO2 (tons)": 109.65,
    "out:Klimatpaverkan": -90.04,
    "out:Initial Cost/MSEK": 8.232375,
    "out:Running cost/(Apt SEK y)": 9919,
    "out:Running Cost over RSP/MSEK": 2.235,
    "out:LCP/MSEK": 1.221,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3302.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1207,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1864.88,
    "out:PV (% sold (El))": 5.32,
    "out:PV (kWh self-consumed)": 33197.83,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -84829,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 332043,
    "out:DH kr savings": 473875,
    "out:El kr savings": -144210,
    "out:El kr sold": 2378,
    "out:El kr saved": 56436,
    "out:El kr return": 58814,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14801,
    "out:% savings (space heating)": 96.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 42.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.34,
    "out:Electricity (inc PV)": 42,
    "out:Total Energy Use Pre PV": 59.34,
    "out:Total Energy Use Post PV": 45,
    "out:Primary Energy": 77.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.76,
    "out:CO2 Operational/m2": 4.12,
    "out:Total CO2/m2": 33.88,
    "out:Total CO2 (tons)": 106.28,
    "out:Klimatpaverkan": -93.41,
    "out:Initial Cost/MSEK": 8.310875,
    "out:Running cost/(Apt SEK y)": 9188,
    "out:Running Cost over RSP/MSEK": 2.07,
    "out:LCP/MSEK": 1.308,
    "out:ROI% old": 16.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3242.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1228,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2018.65,
    "out:PV (% sold (El))": 5.76,
    "out:PV (kWh self-consumed)": 33044.06,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -81889,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342946,
    "out:DH kr savings": 479585,
    "out:El kr savings": -139212,
    "out:El kr sold": 2574,
    "out:El kr saved": 56175,
    "out:El kr return": 58749,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 10862,
    "out:% savings (space heating)": 97.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.87,
    "out:Electricity (inc PV)": 42.17,
    "out:Total Energy Use Pre PV": 59.87,
    "out:Total Energy Use Post PV": 46.17,
    "out:Primary Energy": 77.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.18,
    "out:CO2 Operational/m2": 4.29,
    "out:Total CO2/m2": 34.47,
    "out:Total CO2 (tons)": 108.12,
    "out:Klimatpaverkan": -91.57,
    "out:Initial Cost/MSEK": 8.39175,
    "out:Running cost/(Apt SEK y)": 9387,
    "out:Running Cost over RSP/MSEK": 2.115,
    "out:LCP/MSEK": 1.182,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3210.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2102.35,
    "out:PV (% sold (El))": 6,
    "out:PV (kWh self-consumed)": 32960.36,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -82419,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339298,
    "out:DH kr savings": 476730,
    "out:El kr savings": -140113,
    "out:El kr sold": 2680,
    "out:El kr saved": 56033,
    "out:El kr return": 58713,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12591,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 42.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.93,
    "out:Electricity (inc PV)": 41.22,
    "out:Total Energy Use Pre PV": 57.93,
    "out:Total Energy Use Post PV": 44.22,
    "out:Primary Energy": 75.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.18,
    "out:CO2 Operational/m2": 3.54,
    "out:Total CO2/m2": 33.72,
    "out:Total CO2 (tons)": 105.77,
    "out:Klimatpaverkan": -93.92,
    "out:Initial Cost/MSEK": 8.47025,
    "out:Running cost/(Apt SEK y)": 8932,
    "out:Running Cost over RSP/MSEK": 2.012,
    "out:LCP/MSEK": 1.206,
    "out:ROI% old": 16.32,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3147.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1236,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2275.61,
    "out:PV (% sold (El))": 6.49,
    "out:PV (kWh self-consumed)": 32787.09,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -79439,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 347440,
    "out:DH kr savings": 479585,
    "out:El kr savings": -135046,
    "out:El kr sold": 2901,
    "out:El kr saved": 55738,
    "out:El kr return": 58639,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9108,
    "out:% savings (space heating)": 97.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 41.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.24,
    "out:Electricity (inc PV)": 38.69,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 43.69,
    "out:Primary Energy": 71.71,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.11,
    "out:CO2 Operational/m2": -28.09,
    "out:Total CO2/m2": 14.02,
    "out:Total CO2 (tons)": 44,
    "out:Klimatpaverkan": -155.69,
    "out:Initial Cost/MSEK": 8.885,
    "out:Running cost/(Apt SEK y)": 8163,
    "out:Running Cost over RSP/MSEK": 1.847,
    "out:LCP/MSEK": 0.957,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2968,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22278.81,
    "out:PV (% sold (El))": 31.77,
    "out:PV (kWh self-consumed)": 47846.61,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -71493,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380743,
    "out:DH kr savings": 473875,
    "out:El kr savings": -121538,
    "out:El kr sold": 28405,
    "out:El kr saved": 81339,
    "out:El kr return": 109745,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14801,
    "out:% savings (space heating)": 96.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 38.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.34,
    "out:Electricity (inc PV)": 37.86,
    "out:Total Energy Use Pre PV": 59.34,
    "out:Total Energy Use Post PV": 40.86,
    "out:Primary Energy": 69.58,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.11,
    "out:CO2 Operational/m2": -29.66,
    "out:Total CO2/m2": 12.46,
    "out:Total CO2 (tons)": 39.08,
    "out:Klimatpaverkan": -160.61,
    "out:Initial Cost/MSEK": 8.963375,
    "out:Running cost/(Apt SEK y)": 7436,
    "out:Running Cost over RSP/MSEK": 1.682,
    "out:LCP/MSEK": 1.043,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2914.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1279,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22735.63,
    "out:PV (% sold (El))": 32.42,
    "out:PV (kWh self-consumed)": 47389.79,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -68878,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391481,
    "out:DH kr savings": 479585,
    "out:El kr savings": -117092,
    "out:El kr sold": 28988,
    "out:El kr saved": 80563,
    "out:El kr return": 109551,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 10862,
    "out:% savings (space heating)": 97.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 37.86,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.87,
    "out:Electricity (inc PV)": 38.01,
    "out:Total Energy Use Pre PV": 59.87,
    "out:Total Energy Use Post PV": 42.01,
    "out:Primary Energy": 70.28,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.53,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 12.79,
    "out:Total CO2 (tons)": 40.12,
    "out:Klimatpaverkan": -159.57,
    "out:Initial Cost/MSEK": 9.044375,
    "out:Running cost/(Apt SEK y)": 7638,
    "out:Running Cost over RSP/MSEK": 1.728,
    "out:LCP/MSEK": 0.916,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2887,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.67,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22977.75,
    "out:PV (% sold (El))": 32.77,
    "out:PV (kWh self-consumed)": 47147.67,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -69349,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388133,
    "out:DH kr savings": 476730,
    "out:El kr savings": -117894,
    "out:El kr sold": 29297,
    "out:El kr saved": 80151,
    "out:El kr return": 109448,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12591,
    "out:% savings (space heating)": 96.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 38.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.93,
    "out:Electricity (inc PV)": 37.16,
    "out:Total Energy Use Pre PV": 57.93,
    "out:Total Energy Use Post PV": 40.16,
    "out:Primary Energy": 68.16,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.53,
    "out:CO2 Operational/m2": -31.01,
    "out:Total CO2/m2": 11.53,
    "out:Total CO2 (tons)": 36.16,
    "out:Klimatpaverkan": -163.53,
    "out:Initial Cost/MSEK": 9.12275,
    "out:Running cost/(Apt SEK y)": 7187,
    "out:Running Cost over RSP/MSEK": 1.627,
    "out:LCP/MSEK": 0.939,
    "out:ROI% old": 15.76,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2831.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.73,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23464.55,
    "out:PV (% sold (El))": 33.46,
    "out:PV (kWh self-consumed)": 46660.87,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -66697,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396118,
    "out:DH kr savings": 479585,
    "out:El kr savings": -113384,
    "out:El kr sold": 29917,
    "out:El kr saved": 79323,
    "out:El kr return": 109241,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9108,
    "out:% savings (space heating)": 97.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.82,
    "out:Etvv": 0,
    "out:Ef": 37.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 112.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.87,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 71.01,
    "out:Total CO2 (tons)": 222.76,
    "out:Klimatpaverkan": 23.07,
    "out:Initial Cost/MSEK": 4.183375,
    "out:Running cost/(Apt SEK y)": 42441,
    "out:Running Cost over RSP/MSEK": 9.624,
    "out:LCP/MSEK": -2.119,
    "out:ROI% old": 7.05,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91349,
    "out:DH kr savings": 91349,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 110.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.87,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 69.57,
    "out:Total CO2 (tons)": 218.25,
    "out:Klimatpaverkan": 18.56,
    "out:Initial Cost/MSEK": 4.261875,
    "out:Running cost/(Apt SEK y)": 41304,
    "out:Running Cost over RSP/MSEK": 9.366,
    "out:LCP/MSEK": -1.939,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102768,
    "out:DH kr savings": 102768,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 110.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 68.91,
    "out:Total CO2 (tons)": 216.17,
    "out:Klimatpaverkan": 16.48,
    "out:Initial Cost/MSEK": 4.34275,
    "out:Running cost/(Apt SEK y)": 40451,
    "out:Running Cost over RSP/MSEK": 9.172,
    "out:LCP/MSEK": -1.826,
    "out:ROI% old": 8.28,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111332,
    "out:DH kr savings": 111332,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 107.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 67.47,
    "out:Total CO2 (tons)": 211.66,
    "out:Klimatpaverkan": 11.97,
    "out:Initial Cost/MSEK": 4.42125,
    "out:Running cost/(Apt SEK y)": 39314,
    "out:Running Cost over RSP/MSEK": 8.914,
    "out:LCP/MSEK": -1.647,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 122751,
    "out:DH kr savings": 122751,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 103.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 164.28,
    "out:Klimatpaverkan": -35.41,
    "out:Initial Cost/MSEK": 4.835875,
    "out:Running cost/(Apt SEK y)": 40662,
    "out:Running Cost over RSP/MSEK": 9.231,
    "out:LCP/MSEK": -2.378,
    "out:ROI% old": 7.26,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142949,
    "out:DH kr savings": 91349,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 100.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 50.93,
    "out:Total CO2 (tons)": 159.77,
    "out:Klimatpaverkan": -39.92,
    "out:Initial Cost/MSEK": 4.914375,
    "out:Running cost/(Apt SEK y)": 39525,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": -2.199,
    "out:ROI% old": 7.89,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154368,
    "out:DH kr savings": 102768,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 101.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.65,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 157.69,
    "out:Klimatpaverkan": -42,
    "out:Initial Cost/MSEK": 4.995375,
    "out:Running cost/(Apt SEK y)": 38672,
    "out:Running Cost over RSP/MSEK": 8.78,
    "out:LCP/MSEK": -2.086,
    "out:ROI% old": 8.32,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162932,
    "out:DH kr savings": 111332,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 98.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.65,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 153.18,
    "out:Klimatpaverkan": -46.51,
    "out:Initial Cost/MSEK": 5.07375,
    "out:Running cost/(Apt SEK y)": 37535,
    "out:Running Cost over RSP/MSEK": 8.522,
    "out:LCP/MSEK": -1.907,
    "out:ROI% old": 8.92,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174351,
    "out:DH kr savings": 122751,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 102.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 43.58,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 12.04,
    "out:Total CO2 (tons)": 37.78,
    "out:Klimatpaverkan": -161.91,
    "out:Initial Cost/MSEK": 5.4885,
    "out:Running cost/(Apt SEK y)": 39082,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -2.683,
    "out:ROI% old": 7.3,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188786,
    "out:DH kr savings": 91349,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 99.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.58,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 10.6,
    "out:Total CO2 (tons)": 33.26,
    "out:Klimatpaverkan": -166.43,
    "out:Initial Cost/MSEK": 5.566875,
    "out:Running cost/(Apt SEK y)": 37944,
    "out:Running Cost over RSP/MSEK": 8.625,
    "out:LCP/MSEK": -2.503,
    "out:ROI% old": 7.86,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112932,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200205,
    "out:DH kr savings": 102768,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 99.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 9.94,
    "out:Total CO2 (tons)": 31.19,
    "out:Klimatpaverkan": -168.5,
    "out:Initial Cost/MSEK": 5.647875,
    "out:Running cost/(Apt SEK y)": 37091,
    "out:Running Cost over RSP/MSEK": 8.431,
    "out:LCP/MSEK": -2.39,
    "out:ROI% old": 8.24,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208769,
    "out:DH kr savings": 111332,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 96.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 8.5,
    "out:Total CO2 (tons)": 26.67,
    "out:Klimatpaverkan": -173.02,
    "out:Initial Cost/MSEK": 5.72625,
    "out:Running cost/(Apt SEK y)": 35954,
    "out:Running Cost over RSP/MSEK": 8.173,
    "out:LCP/MSEK": -2.211,
    "out:ROI% old": 8.77,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220187,
    "out:DH kr savings": 122751,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 145.9,
    "out:Primary Energy": 109.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.87,
    "out:CO2 Operational/m2": 48.9,
    "out:Total CO2/m2": 67.77,
    "out:Total CO2 (tons)": 212.6,
    "out:Klimatpaverkan": 12.91,
    "out:Initial Cost/MSEK": 4.26,
    "out:Running cost/(Apt SEK y)": 39883,
    "out:Running Cost over RSP/MSEK": 9.043,
    "out:LCP/MSEK": -1.615,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117041,
    "out:DH kr savings": 117041,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 106.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.87,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 66.33,
    "out:Total CO2 (tons)": 208.08,
    "out:Klimatpaverkan": 8.39,
    "out:Initial Cost/MSEK": 4.338375,
    "out:Running cost/(Apt SEK y)": 38746,
    "out:Running Cost over RSP/MSEK": 8.785,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128460,
    "out:DH kr savings": 128460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 107.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 66.39,
    "out:Total CO2 (tons)": 208.27,
    "out:Klimatpaverkan": 8.58,
    "out:Initial Cost/MSEK": 4.419375,
    "out:Running cost/(Apt SEK y)": 38461,
    "out:Running Cost over RSP/MSEK": 8.721,
    "out:LCP/MSEK": -1.451,
    "out:ROI% old": 9.59,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131315,
    "out:DH kr savings": 131315,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 136.9,
    "out:Primary Energy": 104.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.29,
    "out:CO2 Operational/m2": 45.66,
    "out:Total CO2/m2": 64.95,
    "out:Total CO2 (tons)": 203.75,
    "out:Klimatpaverkan": 4.06,
    "out:Initial Cost/MSEK": 4.49775,
    "out:Running cost/(Apt SEK y)": 37324,
    "out:Running Cost over RSP/MSEK": 8.463,
    "out:LCP/MSEK": -1.272,
    "out:ROI% old": 10.25,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156850,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142734,
    "out:DH kr savings": 142734,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 140.73,
    "out:Primary Energy": 100.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 17.9,
    "out:Total CO2/m2": 49.13,
    "out:Total CO2 (tons)": 154.12,
    "out:Klimatpaverkan": -45.57,
    "out:Initial Cost/MSEK": 4.9125,
    "out:Running cost/(Apt SEK y)": 38103,
    "out:Running Cost over RSP/MSEK": 8.651,
    "out:LCP/MSEK": -1.874,
    "out:ROI% old": 8.83,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168641,
    "out:DH kr savings": 117041,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 97.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.23,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 47.69,
    "out:Total CO2 (tons)": 149.6,
    "out:Klimatpaverkan": -50.09,
    "out:Initial Cost/MSEK": 4.990875,
    "out:Running cost/(Apt SEK y)": 36966,
    "out:Running Cost over RSP/MSEK": 8.393,
    "out:LCP/MSEK": -1.695,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180060,
    "out:DH kr savings": 128460,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 98.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.65,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 47.75,
    "out:Total CO2 (tons)": 149.79,
    "out:Klimatpaverkan": -49.9,
    "out:Initial Cost/MSEK": 5.071875,
    "out:Running cost/(Apt SEK y)": 36682,
    "out:Running Cost over RSP/MSEK": 8.328,
    "out:LCP/MSEK": -1.711,
    "out:ROI% old": 9.47,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182915,
    "out:DH kr savings": 131315,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 131.73,
    "out:Primary Energy": 95.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.65,
    "out:CO2 Operational/m2": 14.66,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 145.27,
    "out:Klimatpaverkan": -54.42,
    "out:Initial Cost/MSEK": 5.15025,
    "out:Running cost/(Apt SEK y)": 35545,
    "out:Running Cost over RSP/MSEK": 8.07,
    "out:LCP/MSEK": -1.531,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156850,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194333,
    "out:DH kr savings": 142734,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 139.88,
    "out:Primary Energy": 98.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.58,
    "out:CO2 Operational/m2": -34.78,
    "out:Total CO2/m2": 8.8,
    "out:Total CO2 (tons)": 27.62,
    "out:Klimatpaverkan": -172.07,
    "out:Initial Cost/MSEK": 5.565,
    "out:Running cost/(Apt SEK y)": 36523,
    "out:Running Cost over RSP/MSEK": 8.302,
    "out:LCP/MSEK": -2.178,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214478,
    "out:DH kr savings": 117041,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 95.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.58,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 7.36,
    "out:Total CO2 (tons)": 23.1,
    "out:Klimatpaverkan": -176.59,
    "out:Initial Cost/MSEK": 5.643375,
    "out:Running cost/(Apt SEK y)": 35386,
    "out:Running Cost over RSP/MSEK": 8.044,
    "out:LCP/MSEK": -1.999,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225897,
    "out:DH kr savings": 128460,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 96.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 7.42,
    "out:Total CO2 (tons)": 23.29,
    "out:Klimatpaverkan": -176.4,
    "out:Initial Cost/MSEK": 5.724375,
    "out:Running cost/(Apt SEK y)": 35101,
    "out:Running Cost over RSP/MSEK": 7.98,
    "out:LCP/MSEK": -2.015,
    "out:ROI% old": 9.26,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 477,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228751,
    "out:DH kr savings": 131315,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 299597,
    "out:% savings (space heating)": 25.31,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 130.88,
    "out:Primary Energy": 93.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44,
    "out:CO2 Operational/m2": -38.02,
    "out:Total CO2/m2": 5.98,
    "out:Total CO2 (tons)": 18.77,
    "out:Klimatpaverkan": -180.92,
    "out:Initial Cost/MSEK": 5.80275,
    "out:Running cost/(Apt SEK y)": 33964,
    "out:Running Cost over RSP/MSEK": 7.721,
    "out:LCP/MSEK": -1.835,
    "out:ROI% old": 9.77,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156850,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240170,
    "out:DH kr savings": 142734,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 286835,
    "out:% savings (space heating)": 28.49,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 41.99,
    "out:Total Energy Use Pre PV": 97.32,
    "out:Total Energy Use Post PV": 94.99,
    "out:Primary Energy": 104.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.68,
    "out:CO2 Operational/m2": 24.93,
    "out:Total CO2/m2": 47.61,
    "out:Total CO2 (tons)": 149.36,
    "out:Klimatpaverkan": -50.33,
    "out:Initial Cost/MSEK": 6.90575,
    "out:Running cost/(Apt SEK y)": 23218,
    "out:Running Cost over RSP/MSEK": 5.247,
    "out:LCP/MSEK": -0.464,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3102.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 821,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -81845,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197715,
    "out:DH kr savings": 336851,
    "out:El kr savings": -139136,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 165678,
    "out:% savings (space heating)": 58.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42,
    "out:Etvv": 0,
    "out:Ef": 41.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 41.87,
    "out:Total Energy Use Pre PV": 93.24,
    "out:Total Energy Use Post PV": 90.87,
    "out:Primary Energy": 102.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.68,
    "out:CO2 Operational/m2": 23.48,
    "out:Total CO2/m2": 46.16,
    "out:Total CO2 (tons)": 144.81,
    "out:Klimatpaverkan": -54.88,
    "out:Initial Cost/MSEK": 6.98425,
    "out:Running cost/(Apt SEK y)": 22066,
    "out:Running Cost over RSP/MSEK": 4.985,
    "out:LCP/MSEK": -0.281,
    "out:ROI% old": 13.71,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3096.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 855,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -81453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209800,
    "out:DH kr savings": 348270,
    "out:El kr savings": -138470,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153728,
    "out:% savings (space heating)": 61.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 41.87,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.53,
    "out:Electricity (inc PV)": 41.48,
    "out:Total Energy Use Pre PV": 92.53,
    "out:Total Energy Use Post PV": 90.48,
    "out:Primary Energy": 102.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.1,
    "out:CO2 Operational/m2": 23.39,
    "out:Total CO2/m2": 46.49,
    "out:Total CO2 (tons)": 145.84,
    "out:Klimatpaverkan": -53.85,
    "out:Initial Cost/MSEK": 7.065125,
    "out:Running cost/(Apt SEK y)": 21936,
    "out:Running Cost over RSP/MSEK": 4.956,
    "out:LCP/MSEK": -0.333,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3047.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -80249,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211847,
    "out:DH kr savings": 348270,
    "out:El kr savings": -136423,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153360,
    "out:% savings (space heating)": 61.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.92,
    "out:Etvv": 0,
    "out:Ef": 41.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 41.35,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 86.35,
    "out:Primary Energy": 99.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.1,
    "out:CO2 Operational/m2": 21.93,
    "out:Total CO2/m2": 45.04,
    "out:Total CO2 (tons)": 141.28,
    "out:Klimatpaverkan": -58.41,
    "out:Initial Cost/MSEK": 7.143625,
    "out:Running cost/(Apt SEK y)": 20781,
    "out:Running Cost over RSP/MSEK": 4.694,
    "out:LCP/MSEK": -0.149,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 3040.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -79826,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223984,
    "out:DH kr savings": 359688,
    "out:El kr savings": -135704,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 141560,
    "out:% savings (space heating)": 64.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.3,
    "out:Etvv": 0,
    "out:Ef": 41.35,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 32.59,
    "out:Total Energy Use Pre PV": 97.32,
    "out:Total Energy Use Post PV": 85.59,
    "out:Primary Energy": 88.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.04,
    "out:CO2 Operational/m2": 16.14,
    "out:Total CO2/m2": 51.18,
    "out:Total CO2 (tons)": 160.55,
    "out:Klimatpaverkan": -39.14,
    "out:Initial Cost/MSEK": 7.558375,
    "out:Running cost/(Apt SEK y)": 21233,
    "out:Running Cost over RSP/MSEK": 4.809,
    "out:LCP/MSEK": -0.678,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2454.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4847.47,
    "out:PV (% sold (El))": 13.83,
    "out:PV (kWh self-consumed)": 30215.24,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -52366,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254009,
    "out:DH kr savings": 336851,
    "out:El kr savings": -89022,
    "out:El kr sold": 6181,
    "out:El kr saved": 51366,
    "out:El kr return": 57546,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 165678,
    "out:% savings (space heating)": 58.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42,
    "out:Etvv": 0,
    "out:Ef": 32.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 32.48,
    "out:Total Energy Use Pre PV": 93.24,
    "out:Total Energy Use Post PV": 81.48,
    "out:Primary Energy": 85.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.04,
    "out:CO2 Operational/m2": 14.65,
    "out:Total CO2/m2": 49.69,
    "out:Total CO2 (tons)": 155.88,
    "out:Klimatpaverkan": -43.81,
    "out:Initial Cost/MSEK": 7.63675,
    "out:Running cost/(Apt SEK y)": 20082,
    "out:Running Cost over RSP/MSEK": 4.548,
    "out:LCP/MSEK": -0.496,
    "out:ROI% old": 13.36,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2449,
    "out:Return %": 12,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4871.33,
    "out:PV (% sold (El))": 13.89,
    "out:PV (kWh self-consumed)": 30191.37,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -52015,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266054,
    "out:DH kr savings": 348270,
    "out:El kr savings": -88426,
    "out:El kr sold": 6211,
    "out:El kr saved": 51325,
    "out:El kr return": 57536,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153728,
    "out:% savings (space heating)": 61.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 32.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.53,
    "out:Electricity (inc PV)": 32.14,
    "out:Total Energy Use Pre PV": 92.53,
    "out:Total Energy Use Post PV": 81.14,
    "out:Primary Energy": 85.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.46,
    "out:CO2 Operational/m2": 14.22,
    "out:Total CO2/m2": 49.67,
    "out:Total CO2 (tons)": 155.83,
    "out:Klimatpaverkan": -43.86,
    "out:Initial Cost/MSEK": 7.71775,
    "out:Running cost/(Apt SEK y)": 19955,
    "out:Running Cost over RSP/MSEK": 4.519,
    "out:LCP/MSEK": -0.548,
    "out:ROI% old": 13.27,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2403.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5090.84,
    "out:PV (% sold (El))": 14.52,
    "out:PV (kWh self-consumed)": 29971.86,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -50939,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268165,
    "out:DH kr savings": 348270,
    "out:El kr savings": -86596,
    "out:El kr sold": 6491,
    "out:El kr saved": 50952,
    "out:El kr return": 57443,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153360,
    "out:% savings (space heating)": 61.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.92,
    "out:Etvv": 0,
    "out:Ef": 32.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 32.02,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 77.02,
    "out:Primary Energy": 83.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.46,
    "out:CO2 Operational/m2": 12.72,
    "out:Total CO2/m2": 48.18,
    "out:Total CO2 (tons)": 151.13,
    "out:Klimatpaverkan": -48.56,
    "out:Initial Cost/MSEK": 7.796125,
    "out:Running cost/(Apt SEK y)": 18801,
    "out:Running Cost over RSP/MSEK": 4.257,
    "out:LCP/MSEK": -0.365,
    "out:ROI% old": 13.62,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2397.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5120,
    "out:PV (% sold (El))": 14.6,
    "out:PV (kWh self-consumed)": 29942.71,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -50561,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280263,
    "out:DH kr savings": 359688,
    "out:El kr savings": -85954,
    "out:El kr sold": 6528,
    "out:El kr saved": 50903,
    "out:El kr return": 57431,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 141560,
    "out:% savings (space heating)": 64.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.3,
    "out:Etvv": 0,
    "out:Ef": 32.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.32,
    "out:Electricity (inc PV)": 29.46,
    "out:Total Energy Use Pre PV": 97.32,
    "out:Total Energy Use Post PV": 82.46,
    "out:Primary Energy": 82.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.39,
    "out:CO2 Operational/m2": -23.85,
    "out:Total CO2/m2": 23.54,
    "out:Total CO2 (tons)": 73.85,
    "out:Klimatpaverkan": -125.84,
    "out:Initial Cost/MSEK": 8.210875,
    "out:Running cost/(Apt SEK y)": 19537,
    "out:Running Cost over RSP/MSEK": 4.434,
    "out:LCP/MSEK": -0.957,
    "out:ROI% old": 12.62,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2214.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 928,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29390.96,
    "out:PV (% sold (El))": 41.91,
    "out:PV (kWh self-consumed)": 40734.46,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 370166,
    "out:EL kWh savings": -42530,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 302023,
    "out:DH kr savings": 336851,
    "out:El kr savings": -72301,
    "out:El kr sold": 37473,
    "out:El kr saved": 69249,
    "out:El kr return": 106722,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 165678,
    "out:% savings (space heating)": 58.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42,
    "out:Etvv": 0,
    "out:Ef": 29.46,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.24,
    "out:Electricity (inc PV)": 29.36,
    "out:Total Energy Use Pre PV": 93.24,
    "out:Total Energy Use Post PV": 78.36,
    "out:Primary Energy": 79.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.39,
    "out:CO2 Operational/m2": -25.37,
    "out:Total CO2/m2": 22.02,
    "out:Total CO2 (tons)": 69.07,
    "out:Klimatpaverkan": -130.62,
    "out:Initial Cost/MSEK": 8.28925,
    "out:Running cost/(Apt SEK y)": 18387,
    "out:Running Cost over RSP/MSEK": 4.173,
    "out:LCP/MSEK": -0.774,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2210.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29437.98,
    "out:PV (% sold (El))": 41.98,
    "out:PV (kWh self-consumed)": 40687.43,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -42216,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314036,
    "out:DH kr savings": 348270,
    "out:El kr savings": -71767,
    "out:El kr sold": 37533,
    "out:El kr saved": 69169,
    "out:El kr return": 106702,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153728,
    "out:% savings (space heating)": 61.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 29.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.53,
    "out:Electricity (inc PV)": 29.05,
    "out:Total Energy Use Pre PV": 92.53,
    "out:Total Energy Use Post PV": 78.05,
    "out:Primary Energy": 80.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.81,
    "out:CO2 Operational/m2": -26.15,
    "out:Total CO2/m2": 21.66,
    "out:Total CO2 (tons)": 67.95,
    "out:Klimatpaverkan": -131.74,
    "out:Initial Cost/MSEK": 8.37025,
    "out:Running cost/(Apt SEK y)": 18262,
    "out:Running Cost over RSP/MSEK": 4.145,
    "out:LCP/MSEK": -0.827,
    "out:ROI% old": 12.87,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2170,
    "out:Return %": 12,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.33,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29866.26,
    "out:PV (% sold (El))": 42.59,
    "out:PV (kWh self-consumed)": 40259.16,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 382714,
    "out:EL kWh savings": -41251,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 316223,
    "out:DH kr savings": 348270,
    "out:El kr savings": -70126,
    "out:El kr sold": 38079,
    "out:El kr saved": 68441,
    "out:El kr return": 106520,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 153360,
    "out:% savings (space heating)": 61.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.92,
    "out:Etvv": 0,
    "out:Ef": 29.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 28.94,
    "out:Total Energy Use Pre PV": 88.44,
    "out:Total Energy Use Post PV": 73.94,
    "out:Primary Energy": 77.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.81,
    "out:CO2 Operational/m2": -27.69,
    "out:Total CO2/m2": 20.12,
    "out:Total CO2 (tons)": 63.11,
    "out:Klimatpaverkan": -136.58,
    "out:Initial Cost/MSEK": 8.448625,
    "out:Running cost/(Apt SEK y)": 17109,
    "out:Running Cost over RSP/MSEK": 3.884,
    "out:LCP/MSEK": -0.644,
    "out:ROI% old": 13.2,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2164.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29922.67,
    "out:PV (% sold (El))": 42.67,
    "out:PV (kWh self-consumed)": 40202.75,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -40912,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328289,
    "out:DH kr savings": 359688,
    "out:El kr savings": -69551,
    "out:El kr sold": 38151,
    "out:El kr saved": 68345,
    "out:El kr return": 106496,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 141560,
    "out:% savings (space heating)": 64.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.3,
    "out:Etvv": 0,
    "out:Ef": 28.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.26,
    "out:Electricity (inc PV)": 41.31,
    "out:Total Energy Use Pre PV": 91.26,
    "out:Total Energy Use Post PV": 89.31,
    "out:Primary Energy": 101.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.68,
    "out:CO2 Operational/m2": 22.99,
    "out:Total CO2/m2": 45.67,
    "out:Total CO2 (tons)": 143.28,
    "out:Klimatpaverkan": -56.41,
    "out:Initial Cost/MSEK": 6.982375,
    "out:Running cost/(Apt SEK y)": 21602,
    "out:Running Cost over RSP/MSEK": 4.881,
    "out:LCP/MSEK": -0.174,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 3028.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 868,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -79709,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215619,
    "out:DH kr savings": 351124,
    "out:El kr savings": -135506,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149339,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.24,
    "out:Etvv": 0,
    "out:Ef": 41.31,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 41.17,
    "out:Total Energy Use Pre PV": 87.16,
    "out:Total Energy Use Post PV": 85.17,
    "out:Primary Energy": 99.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.68,
    "out:CO2 Operational/m2": 21.54,
    "out:Total CO2/m2": 44.22,
    "out:Total CO2 (tons)": 138.72,
    "out:Klimatpaverkan": -60.97,
    "out:Initial Cost/MSEK": 7.06075,
    "out:Running cost/(Apt SEK y)": 20446,
    "out:Running Cost over RSP/MSEK": 4.618,
    "out:LCP/MSEK": 0.01,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 3021.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -79276,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227774,
    "out:DH kr savings": 362543,
    "out:El kr savings": -134769,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137599,
    "out:% savings (space heating)": 65.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.63,
    "out:Etvv": 0,
    "out:Ef": 41.17,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 40.89,
    "out:Total Energy Use Pre PV": 87.59,
    "out:Total Energy Use Post PV": 85.89,
    "out:Primary Energy": 99.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.1,
    "out:CO2 Operational/m2": 21.82,
    "out:Total CO2/m2": 44.93,
    "out:Total CO2 (tons)": 140.93,
    "out:Klimatpaverkan": -58.76,
    "out:Initial Cost/MSEK": 7.14175,
    "out:Running cost/(Apt SEK y)": 20626,
    "out:Running Cost over RSP/MSEK": 4.659,
    "out:LCP/MSEK": -0.112,
    "out:ROI% old": 14.06,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2981.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -78399,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226410,
    "out:DH kr savings": 359688,
    "out:El kr savings": -133278,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 139866,
    "out:% savings (space heating)": 65.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.62,
    "out:Etvv": 0,
    "out:Ef": 40.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 40.74,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 81.74,
    "out:Primary Energy": 97.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.1,
    "out:CO2 Operational/m2": 20.37,
    "out:Total CO2/m2": 43.47,
    "out:Total CO2 (tons)": 136.37,
    "out:Klimatpaverkan": -63.32,
    "out:Initial Cost/MSEK": 7.220125,
    "out:Running cost/(Apt SEK y)": 19468,
    "out:Running Cost over RSP/MSEK": 4.397,
    "out:LCP/MSEK": 0.072,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2973.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -77933,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238622,
    "out:DH kr savings": 371107,
    "out:El kr savings": -132486,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 128252,
    "out:% savings (space heating)": 68.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.04,
    "out:Etvv": 0,
    "out:Ef": 40.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.26,
    "out:Electricity (inc PV)": 31.98,
    "out:Total Energy Use Pre PV": 91.26,
    "out:Total Energy Use Post PV": 79.98,
    "out:Primary Energy": 85.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.04,
    "out:CO2 Operational/m2": 13.69,
    "out:Total CO2/m2": 48.73,
    "out:Total CO2 (tons)": 152.85,
    "out:Klimatpaverkan": -46.84,
    "out:Initial Cost/MSEK": 7.634875,
    "out:Running cost/(Apt SEK y)": 19622,
    "out:Running Cost over RSP/MSEK": 4.444,
    "out:LCP/MSEK": -0.39,
    "out:ROI% old": 13.56,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2386.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5175.91,
    "out:PV (% sold (El))": 14.76,
    "out:PV (kWh self-consumed)": 29886.8,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -50457,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271947,
    "out:DH kr savings": 351124,
    "out:El kr savings": -85776,
    "out:El kr sold": 6599,
    "out:El kr saved": 50808,
    "out:El kr return": 57407,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149339,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.24,
    "out:Etvv": 0,
    "out:Ef": 31.98,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 31.86,
    "out:Total Energy Use Pre PV": 87.16,
    "out:Total Energy Use Post PV": 75.86,
    "out:Primary Energy": 82.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.04,
    "out:CO2 Operational/m2": 12.19,
    "out:Total CO2/m2": 47.22,
    "out:Total CO2 (tons)": 148.14,
    "out:Klimatpaverkan": -51.55,
    "out:Initial Cost/MSEK": 7.71325,
    "out:Running cost/(Apt SEK y)": 18467,
    "out:Running Cost over RSP/MSEK": 4.182,
    "out:LCP/MSEK": -0.206,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2380.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5207.07,
    "out:PV (% sold (El))": 14.85,
    "out:PV (kWh self-consumed)": 29855.64,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -50070,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284064,
    "out:DH kr savings": 362543,
    "out:El kr savings": -85118,
    "out:El kr sold": 6639,
    "out:El kr saved": 50755,
    "out:El kr return": 57394,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137599,
    "out:% savings (space heating)": 65.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.63,
    "out:Etvv": 0,
    "out:Ef": 31.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 31.61,
    "out:Total Energy Use Pre PV": 87.59,
    "out:Total Energy Use Post PV": 76.61,
    "out:Primary Energy": 83.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.46,
    "out:CO2 Operational/m2": 12.19,
    "out:Total CO2/m2": 47.64,
    "out:Total CO2 (tons)": 149.46,
    "out:Klimatpaverkan": -50.23,
    "out:Initial Cost/MSEK": 7.79425,
    "out:Running cost/(Apt SEK y)": 18650,
    "out:Running Cost over RSP/MSEK": 4.223,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2343.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 954,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5388.28,
    "out:PV (% sold (El))": 15.37,
    "out:PV (kWh self-consumed)": 29674.43,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -49288,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282769,
    "out:DH kr savings": 359688,
    "out:El kr savings": -83789,
    "out:El kr sold": 6870,
    "out:El kr saved": 50447,
    "out:El kr return": 57317,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 139866,
    "out:% savings (space heating)": 65.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.62,
    "out:Etvv": 0,
    "out:Ef": 31.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 31.48,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 72.48,
    "out:Primary Energy": 80.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.46,
    "out:CO2 Operational/m2": 10.68,
    "out:Total CO2/m2": 46.13,
    "out:Total CO2 (tons)": 144.72,
    "out:Klimatpaverkan": -54.97,
    "out:Initial Cost/MSEK": 7.872625,
    "out:Running cost/(Apt SEK y)": 17493,
    "out:Running Cost over RSP/MSEK": 3.961,
    "out:LCP/MSEK": -0.145,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2336.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5424.04,
    "out:PV (% sold (El))": 15.47,
    "out:PV (kWh self-consumed)": 29638.67,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -48873,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294940,
    "out:DH kr savings": 371107,
    "out:El kr savings": -83083,
    "out:El kr sold": 6916,
    "out:El kr saved": 50386,
    "out:El kr return": 57301,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 128252,
    "out:% savings (space heating)": 68.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.04,
    "out:Etvv": 0,
    "out:Ef": 31.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.26,
    "out:Electricity (inc PV)": 28.91,
    "out:Total Energy Use Pre PV": 91.26,
    "out:Total Energy Use Post PV": 76.91,
    "out:Primary Energy": 79.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.39,
    "out:CO2 Operational/m2": -26.81,
    "out:Total CO2/m2": 20.58,
    "out:Total CO2 (tons)": 64.57,
    "out:Klimatpaverkan": -135.12,
    "out:Initial Cost/MSEK": 8.287375,
    "out:Running cost/(Apt SEK y)": 17931,
    "out:Running Cost over RSP/MSEK": 4.07,
    "out:LCP/MSEK": -0.669,
    "out:ROI% old": 13.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2154.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 975,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30030.78,
    "out:PV (% sold (El))": 42.82,
    "out:PV (kWh self-consumed)": 40094.63,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -40818,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320022,
    "out:DH kr savings": 351124,
    "out:El kr savings": -69391,
    "out:El kr sold": 38289,
    "out:El kr saved": 68161,
    "out:El kr return": 106450,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149339,
    "out:% savings (space heating)": 62.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.24,
    "out:Etvv": 0,
    "out:Ef": 28.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 28.8,
    "out:Total Energy Use Pre PV": 87.16,
    "out:Total Energy Use Post PV": 72.8,
    "out:Primary Energy": 76.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.39,
    "out:CO2 Operational/m2": -28.36,
    "out:Total CO2/m2": 19.03,
    "out:Total CO2 (tons)": 59.71,
    "out:Klimatpaverkan": -139.98,
    "out:Initial Cost/MSEK": 8.365875,
    "out:Running cost/(Apt SEK y)": 16776,
    "out:Running Cost over RSP/MSEK": 3.808,
    "out:LCP/MSEK": -0.485,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2149,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30090.96,
    "out:PV (% sold (El))": 42.91,
    "out:PV (kWh self-consumed)": 40034.45,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398399,
    "out:EL kWh savings": -40471,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332108,
    "out:DH kr savings": 362543,
    "out:El kr savings": -68801,
    "out:El kr sold": 38366,
    "out:El kr saved": 68059,
    "out:El kr return": 106425,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 137599,
    "out:% savings (space heating)": 65.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.63,
    "out:Etvv": 0,
    "out:Ef": 28.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.59,
    "out:Electricity (inc PV)": 28.58,
    "out:Total Energy Use Pre PV": 87.59,
    "out:Total Energy Use Post PV": 73.58,
    "out:Primary Energy": 77.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.81,
    "out:CO2 Operational/m2": -28.63,
    "out:Total CO2/m2": 19.18,
    "out:Total CO2 (tons)": 60.18,
    "out:Klimatpaverkan": -139.51,
    "out:Initial Cost/MSEK": 8.44675,
    "out:Running cost/(Apt SEK y)": 16961,
    "out:Running Cost over RSP/MSEK": 3.85,
    "out:LCP/MSEK": -0.608,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2116.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30439.02,
    "out:PV (% sold (El))": 43.41,
    "out:PV (kWh self-consumed)": 39686.4,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -39770,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330889,
    "out:DH kr savings": 359688,
    "out:El kr savings": -67609,
    "out:El kr sold": 38810,
    "out:El kr saved": 67467,
    "out:El kr return": 106277,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 139866,
    "out:% savings (space heating)": 65.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.62,
    "out:Etvv": 0,
    "out:Ef": 28.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 28.46,
    "out:Total Energy Use Pre PV": 83.48,
    "out:Total Energy Use Post PV": 69.46,
    "out:Primary Energy": 75.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.81,
    "out:CO2 Operational/m2": -30.19,
    "out:Total CO2/m2": 17.62,
    "out:Total CO2 (tons)": 55.27,
    "out:Klimatpaverkan": -144.42,
    "out:Initial Cost/MSEK": 8.52525,
    "out:Running cost/(Apt SEK y)": 15805,
    "out:Running Cost over RSP/MSEK": 3.588,
    "out:LCP/MSEK": -0.425,
    "out:ROI% old": 13.57,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2110.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1036,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30507.29,
    "out:PV (% sold (El))": 43.5,
    "out:PV (kWh self-consumed)": 39618.13,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -39397,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343029,
    "out:DH kr savings": 371107,
    "out:El kr savings": -66975,
    "out:El kr sold": 38897,
    "out:El kr saved": 67351,
    "out:El kr return": 106248,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 128252,
    "out:% savings (space heating)": 68.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.04,
    "out:Etvv": 0,
    "out:Ef": 28.46,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.84,
    "out:Electricity (inc PV)": 53.91,
    "out:Total Energy Use Pre PV": 63.84,
    "out:Total Energy Use Post PV": 58.91,
    "out:Primary Energy": 98.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 9.57,
    "out:Total CO2/m2": 32.9,
    "out:Total CO2 (tons)": 103.19,
    "out:Klimatpaverkan": -96.5,
    "out:Initial Cost/MSEK": 8.762125,
    "out:Running cost/(Apt SEK y)": 12242,
    "out:Running Cost over RSP/MSEK": 2.749,
    "out:LCP/MSEK": 0.178,
    "out:ROI% old": 14.58,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4119,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -119240,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271167,
    "out:DH kr savings": 473875,
    "out:El kr savings": -202708,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15314,
    "out:% savings (space heating)": 96.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 53.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 52.93,
    "out:Total Energy Use Pre PV": 62,
    "out:Total Energy Use Post PV": 56.93,
    "out:Primary Energy": 96.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 9.1,
    "out:Total CO2/m2": 32.42,
    "out:Total CO2 (tons)": 101.71,
    "out:Klimatpaverkan": -97.98,
    "out:Initial Cost/MSEK": 8.840625,
    "out:Running cost/(Apt SEK y)": 11803,
    "out:Running Cost over RSP/MSEK": 2.649,
    "out:LCP/MSEK": 0.199,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 4059.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1152,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -116163,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279253,
    "out:DH kr savings": 476730,
    "out:El kr savings": -197477,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11304,
    "out:% savings (space heating)": 97.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.83,
    "out:Etvv": 0,
    "out:Ef": 52.93,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.14,
    "out:Electricity (inc PV)": 52.88,
    "out:Total Energy Use Pre PV": 61.14,
    "out:Total Energy Use Post PV": 56.88,
    "out:Primary Energy": 96.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 8.98,
    "out:Total CO2/m2": 32.73,
    "out:Total CO2 (tons)": 102.68,
    "out:Klimatpaverkan": -97.01,
    "out:Initial Cost/MSEK": 8.9215,
    "out:Running cost/(Apt SEK y)": 11646,
    "out:Running Cost over RSP/MSEK": 2.614,
    "out:LCP/MSEK": 0.153,
    "out:ROI% old": 14.53,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 4000.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1157,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -115993,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279542,
    "out:DH kr savings": 476730,
    "out:El kr savings": -197188,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12427,
    "out:% savings (space heating)": 96.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.32,
    "out:Etvv": 0,
    "out:Ef": 52.88,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.24,
    "out:Electricity (inc PV)": 51.87,
    "out:Total Energy Use Pre PV": 59.24,
    "out:Total Energy Use Post PV": 54.87,
    "out:Primary Energy": 94.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 8.5,
    "out:Total CO2/m2": 32.25,
    "out:Total CO2 (tons)": 101.17,
    "out:Klimatpaverkan": -98.52,
    "out:Initial Cost/MSEK": 9,
    "out:Running cost/(Apt SEK y)": 11195,
    "out:Running Cost over RSP/MSEK": 2.512,
    "out:LCP/MSEK": 0.177,
    "out:ROI% old": 14.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3936.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1170,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -112852,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287735,
    "out:DH kr savings": 479585,
    "out:El kr savings": -191849,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9022,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.56,
    "out:Etvv": 0,
    "out:Ef": 51.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.84,
    "out:Electricity (inc PV)": 43.52,
    "out:Total Energy Use Pre PV": 63.84,
    "out:Total Energy Use Post PV": 48.52,
    "out:Primary Energy": 80.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": 5.8,
    "out:Total CO2/m2": 41.48,
    "out:Total CO2 (tons)": 130.12,
    "out:Klimatpaverkan": -69.57,
    "out:Initial Cost/MSEK": 9.41475,
    "out:Running cost/(Apt SEK y)": 10210,
    "out:Running Cost over RSP/MSEK": 2.3,
    "out:LCP/MSEK": -0.026,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3409.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1199,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1607.58,
    "out:PV (% sold (El))": 4.58,
    "out:PV (kWh self-consumed)": 33455.13,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -86650,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328619,
    "out:DH kr savings": 473875,
    "out:El kr savings": -147306,
    "out:El kr sold": 2050,
    "out:El kr saved": 56874,
    "out:El kr return": 58923,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15314,
    "out:% savings (space heating)": 96.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 43.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 42.6,
    "out:Total Energy Use Pre PV": 62,
    "out:Total Energy Use Post PV": 46.6,
    "out:Primary Energy": 77.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": 5.12,
    "out:Total CO2/m2": 40.8,
    "out:Total CO2 (tons)": 128,
    "out:Klimatpaverkan": -71.69,
    "out:Initial Cost/MSEK": 9.493125,
    "out:Running cost/(Apt SEK y)": 9773,
    "out:Running Cost over RSP/MSEK": 2.201,
    "out:LCP/MSEK": -0.006,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3352.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1211,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1740.42,
    "out:PV (% sold (El))": 4.96,
    "out:PV (kWh self-consumed)": 33322.29,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -83773,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336535,
    "out:DH kr savings": 476730,
    "out:El kr savings": -142414,
    "out:El kr sold": 2219,
    "out:El kr saved": 56648,
    "out:El kr return": 58867,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11304,
    "out:% savings (space heating)": 97.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.83,
    "out:Etvv": 0,
    "out:Ef": 42.6,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.14,
    "out:Electricity (inc PV)": 42.55,
    "out:Total Energy Use Pre PV": 61.14,
    "out:Total Energy Use Post PV": 46.55,
    "out:Primary Energy": 78.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": 4.79,
    "out:Total CO2/m2": 40.9,
    "out:Total CO2 (tons)": 128.29,
    "out:Klimatpaverkan": -71.4,
    "out:Initial Cost/MSEK": 9.574125,
    "out:Running cost/(Apt SEK y)": 9618,
    "out:Running Cost over RSP/MSEK": 2.167,
    "out:LCP/MSEK": -0.052,
    "out:ROI% old": 14.21,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3295.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1216,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1880.96,
    "out:PV (% sold (El))": 5.36,
    "out:PV (kWh self-consumed)": 33181.75,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -83614,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336985,
    "out:DH kr savings": 476730,
    "out:El kr savings": -142144,
    "out:El kr sold": 2398,
    "out:El kr saved": 56409,
    "out:El kr return": 58807,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12427,
    "out:% savings (space heating)": 96.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.32,
    "out:Etvv": 0,
    "out:Ef": 42.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.24,
    "out:Electricity (inc PV)": 41.62,
    "out:Total Energy Use Pre PV": 59.24,
    "out:Total Energy Use Post PV": 44.62,
    "out:Primary Energy": 76.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": 4.08,
    "out:Total CO2/m2": 40.18,
    "out:Total CO2 (tons)": 126.04,
    "out:Klimatpaverkan": -73.65,
    "out:Initial Cost/MSEK": 9.6525,
    "out:Running cost/(Apt SEK y)": 9170,
    "out:Running Cost over RSP/MSEK": 2.065,
    "out:LCP/MSEK": -0.029,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3235.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1229,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2036.74,
    "out:PV (% sold (El))": 5.81,
    "out:PV (kWh self-consumed)": 33025.97,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -80688,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 345013,
    "out:DH kr savings": 479585,
    "out:El kr savings": -137169,
    "out:El kr sold": 2597,
    "out:El kr saved": 56144,
    "out:El kr return": 58741,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9022,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.56,
    "out:Etvv": 0,
    "out:Ef": 41.62,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.84,
    "out:Electricity (inc PV)": 39.21,
    "out:Total Energy Use Pre PV": 63.84,
    "out:Total Energy Use Post PV": 44.21,
    "out:Primary Energy": 72.43,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.03,
    "out:CO2 Operational/m2": -26.6,
    "out:Total CO2/m2": 21.44,
    "out:Total CO2 (tons)": 67.25,
    "out:Klimatpaverkan": -132.44,
    "out:Initial Cost/MSEK": 10.06725,
    "out:Running cost/(Apt SEK y)": 8446,
    "out:Running Cost over RSP/MSEK": 1.91,
    "out:LCP/MSEK": -0.289,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3063.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1250,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21472.44,
    "out:PV (% sold (El))": 30.62,
    "out:PV (kWh self-consumed)": 48652.98,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -73111,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376964,
    "out:DH kr savings": 473875,
    "out:El kr savings": -124289,
    "out:El kr sold": 27377,
    "out:El kr saved": 82710,
    "out:El kr return": 110087,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15314,
    "out:% savings (space heating)": 96.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.66,
    "out:Etvv": 0,
    "out:Ef": 39.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58,
    "out:Electricity (inc PV)": 38.39,
    "out:Total Energy Use Pre PV": 62,
    "out:Total Energy Use Post PV": 42.39,
    "out:Primary Energy": 70.38,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.03,
    "out:CO2 Operational/m2": -27.74,
    "out:Total CO2/m2": 20.29,
    "out:Total CO2 (tons)": 63.66,
    "out:Klimatpaverkan": -136.03,
    "out:Initial Cost/MSEK": 10.145625,
    "out:Running cost/(Apt SEK y)": 8013,
    "out:Running Cost over RSP/MSEK": 1.812,
    "out:LCP/MSEK": -0.269,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3012.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1262,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21895.95,
    "out:PV (% sold (El))": 31.22,
    "out:PV (kWh self-consumed)": 48229.47,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -70553,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384707,
    "out:DH kr savings": 476730,
    "out:El kr savings": -119941,
    "out:El kr sold": 27917,
    "out:El kr saved": 81990,
    "out:El kr return": 109907,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11304,
    "out:% savings (space heating)": 97.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.83,
    "out:Etvv": 0,
    "out:Ef": 38.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.14,
    "out:Electricity (inc PV)": 38.35,
    "out:Total Energy Use Pre PV": 61.14,
    "out:Total Energy Use Post PV": 42.35,
    "out:Primary Energy": 70.65,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.45,
    "out:CO2 Operational/m2": -28.54,
    "out:Total CO2/m2": 19.91,
    "out:Total CO2 (tons)": 62.47,
    "out:Klimatpaverkan": -137.22,
    "out:Initial Cost/MSEK": 10.226625,
    "out:Running cost/(Apt SEK y)": 7862,
    "out:Running Cost over RSP/MSEK": 1.779,
    "out:LCP/MSEK": -0.317,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2962.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22327.43,
    "out:PV (% sold (El))": 31.84,
    "out:PV (kWh self-consumed)": 47797.99,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -70412,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 385497,
    "out:DH kr savings": 476730,
    "out:El kr savings": -119700,
    "out:El kr sold": 28467,
    "out:El kr saved": 81257,
    "out:El kr return": 109724,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12427,
    "out:% savings (space heating)": 96.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.32,
    "out:Etvv": 0,
    "out:Ef": 38.35,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.24,
    "out:Electricity (inc PV)": 37.52,
    "out:Total Energy Use Pre PV": 59.24,
    "out:Total Energy Use Post PV": 40.52,
    "out:Primary Energy": 68.62,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.45,
    "out:CO2 Operational/m2": -29.75,
    "out:Total CO2/m2": 18.7,
    "out:Total CO2 (tons)": 58.66,
    "out:Klimatpaverkan": -141.03,
    "out:Initial Cost/MSEK": 10.305,
    "out:Running cost/(Apt SEK y)": 7418,
    "out:Running Cost over RSP/MSEK": 1.678,
    "out:LCP/MSEK": -0.295,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2908.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1280,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22788.27,
    "out:PV (% sold (El))": 32.5,
    "out:PV (kWh self-consumed)": 47337.15,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -67808,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 393366,
    "out:DH kr savings": 479585,
    "out:El kr savings": -115273,
    "out:El kr sold": 29055,
    "out:El kr saved": 80473,
    "out:El kr return": 109528,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9022,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.56,
    "out:Etvv": 0,
    "out:Ef": 37.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.57,
    "out:Electricity (inc PV)": 52.53,
    "out:Total Energy Use Pre PV": 60.57,
    "out:Total Energy Use Post PV": 56.53,
    "out:Primary Energy": 96.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 8.91,
    "out:Total CO2/m2": 32.23,
    "out:Total CO2 (tons)": 101.12,
    "out:Klimatpaverkan": -98.57,
    "out:Initial Cost/MSEK": 8.83875,
    "out:Running cost/(Apt SEK y)": 11539,
    "out:Running Cost over RSP/MSEK": 2.59,
    "out:LCP/MSEK": 0.26,
    "out:ROI% old": 14.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3959.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -114904,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281394,
    "out:DH kr savings": 476730,
    "out:El kr savings": -195336,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11581,
    "out:% savings (space heating)": 97.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.22,
    "out:Etvv": 0,
    "out:Ef": 52.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.65,
    "out:Electricity (inc PV)": 51.52,
    "out:Total Energy Use Pre PV": 58.65,
    "out:Total Energy Use Post PV": 54.52,
    "out:Primary Energy": 93.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.33,
    "out:CO2 Operational/m2": 8.43,
    "out:Total CO2/m2": 31.75,
    "out:Total CO2 (tons)": 99.61,
    "out:Klimatpaverkan": -100.08,
    "out:Initial Cost/MSEK": 8.917125,
    "out:Running cost/(Apt SEK y)": 11086,
    "out:Running Cost over RSP/MSEK": 2.488,
    "out:LCP/MSEK": 0.284,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3895.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -111743,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289622,
    "out:DH kr savings": 479585,
    "out:El kr savings": -189962,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 8345,
    "out:% savings (space heating)": 97.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 51.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.16,
    "out:Electricity (inc PV)": 51.69,
    "out:Total Energy Use Pre PV": 58.16,
    "out:Total Energy Use Post PV": 54.69,
    "out:Primary Energy": 94.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 8.36,
    "out:Total CO2/m2": 32.11,
    "out:Total CO2 (tons)": 100.73,
    "out:Klimatpaverkan": -98.96,
    "out:Initial Cost/MSEK": 8.998125,
    "out:Running cost/(Apt SEK y)": 10997,
    "out:Running Cost over RSP/MSEK": 2.468,
    "out:LCP/MSEK": 0.223,
    "out:ROI% old": 14.64,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3861.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -112278,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288711,
    "out:DH kr savings": 479585,
    "out:El kr savings": -190873,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9751,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.98,
    "out:Etvv": 0,
    "out:Ef": 51.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.21,
    "out:Electricity (inc PV)": 50.67,
    "out:Total Energy Use Pre PV": 56.21,
    "out:Total Energy Use Post PV": 52.67,
    "out:Primary Energy": 92.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.75,
    "out:CO2 Operational/m2": 7.88,
    "out:Total CO2/m2": 31.62,
    "out:Total CO2 (tons)": 99.2,
    "out:Klimatpaverkan": -100.49,
    "out:Initial Cost/MSEK": 9.0765,
    "out:Running cost/(Apt SEK y)": 10537,
    "out:Running Cost over RSP/MSEK": 2.364,
    "out:LCP/MSEK": 0.248,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3794.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1189,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -109066,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297028,
    "out:DH kr savings": 482439,
    "out:El kr savings": -185412,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6867,
    "out:% savings (space heating)": 98.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.29,
    "out:Etvv": 0,
    "out:Ef": 50.67,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.57,
    "out:Electricity (inc PV)": 42.23,
    "out:Total Energy Use Pre PV": 60.57,
    "out:Total Energy Use Post PV": 46.23,
    "out:Primary Energy": 77.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": 4.57,
    "out:Total CO2/m2": 40.25,
    "out:Total CO2 (tons)": 126.26,
    "out:Klimatpaverkan": -73.43,
    "out:Initial Cost/MSEK": 9.49125,
    "out:Running cost/(Apt SEK y)": 9513,
    "out:Running Cost over RSP/MSEK": 2.143,
    "out:LCP/MSEK": 0.054,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3257,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1219,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1979.65,
    "out:PV (% sold (El))": 5.65,
    "out:PV (kWh self-consumed)": 33083.06,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -82598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338838,
    "out:DH kr savings": 476730,
    "out:El kr savings": -140416,
    "out:El kr sold": 2524,
    "out:El kr saved": 56241,
    "out:El kr return": 58765,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11581,
    "out:% savings (space heating)": 97.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.22,
    "out:Etvv": 0,
    "out:Ef": 42.23,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.65,
    "out:Electricity (inc PV)": 41.29,
    "out:Total Energy Use Pre PV": 58.65,
    "out:Total Energy Use Post PV": 44.29,
    "out:Primary Energy": 75.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.68,
    "out:CO2 Operational/m2": 3.83,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 123.96,
    "out:Klimatpaverkan": -75.73,
    "out:Initial Cost/MSEK": 9.569625,
    "out:Running cost/(Apt SEK y)": 9062,
    "out:Running Cost over RSP/MSEK": 2.041,
    "out:LCP/MSEK": 0.078,
    "out:ROI% old": 14.4,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3195.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1232,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2143.21,
    "out:PV (% sold (El))": 6.11,
    "out:PV (kWh self-consumed)": 32919.5,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -79656,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346903,
    "out:DH kr savings": 479585,
    "out:El kr savings": -135415,
    "out:El kr sold": 2733,
    "out:El kr saved": 55963,
    "out:El kr return": 58696,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 8345,
    "out:% savings (space heating)": 97.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 41.29,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.16,
    "out:Electricity (inc PV)": 41.45,
    "out:Total Energy Use Pre PV": 58.16,
    "out:Total Energy Use Post PV": 44.45,
    "out:Primary Energy": 76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": 3.63,
    "out:Total CO2/m2": 39.73,
    "out:Total CO2 (tons)": 124.65,
    "out:Klimatpaverkan": -75.04,
    "out:Initial Cost/MSEK": 9.650625,
    "out:Running cost/(Apt SEK y)": 8974,
    "out:Running Cost over RSP/MSEK": 2.021,
    "out:LCP/MSEK": 0.017,
    "out:ROI% old": 14.31,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3163.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1234,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2232.45,
    "out:PV (% sold (El))": 6.37,
    "out:PV (kWh self-consumed)": 32830.26,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -80154,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346170,
    "out:DH kr savings": 479585,
    "out:El kr savings": -136261,
    "out:El kr sold": 2846,
    "out:El kr saved": 55811,
    "out:El kr return": 58658,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9751,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.98,
    "out:Etvv": 0,
    "out:Ef": 41.45,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.21,
    "out:Electricity (inc PV)": 40.5,
    "out:Total Energy Use Pre PV": 56.21,
    "out:Total Energy Use Post PV": 42.5,
    "out:Primary Energy": 73.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.1,
    "out:CO2 Operational/m2": 2.87,
    "out:Total CO2/m2": 38.97,
    "out:Total CO2 (tons)": 122.24,
    "out:Klimatpaverkan": -77.45,
    "out:Initial Cost/MSEK": 9.729,
    "out:Running cost/(Apt SEK y)": 8517,
    "out:Running Cost over RSP/MSEK": 1.918,
    "out:LCP/MSEK": 0.042,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3099.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1248,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2414.03,
    "out:PV (% sold (El))": 6.88,
    "out:PV (kWh self-consumed)": 32648.68,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -77171,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354326,
    "out:DH kr savings": 482439,
    "out:El kr savings": -131191,
    "out:El kr sold": 3078,
    "out:El kr saved": 55503,
    "out:El kr return": 58581,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6867,
    "out:% savings (space heating)": 98.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.29,
    "out:Etvv": 0,
    "out:Ef": 40.5,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.57,
    "out:Electricity (inc PV)": 38.06,
    "out:Total Energy Use Pre PV": 60.57,
    "out:Total Energy Use Post PV": 42.06,
    "out:Primary Energy": 70.06,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.03,
    "out:CO2 Operational/m2": -29.08,
    "out:Total CO2/m2": 18.95,
    "out:Total CO2 (tons)": 59.45,
    "out:Klimatpaverkan": -140.24,
    "out:Initial Cost/MSEK": 10.14375,
    "out:Running cost/(Apt SEK y)": 7760,
    "out:Running Cost over RSP/MSEK": 1.756,
    "out:LCP/MSEK": -0.211,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2928.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1270,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22621.15,
    "out:PV (% sold (El))": 32.26,
    "out:PV (kWh self-consumed)": 47504.26,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -69508,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 387408,
    "out:DH kr savings": 476730,
    "out:El kr savings": -118164,
    "out:El kr sold": 28842,
    "out:El kr saved": 80757,
    "out:El kr return": 109599,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11581,
    "out:% savings (space heating)": 97.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.22,
    "out:Etvv": 0,
    "out:Ef": 38.06,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.65,
    "out:Electricity (inc PV)": 37.22,
    "out:Total Energy Use Pre PV": 58.65,
    "out:Total Energy Use Post PV": 40.22,
    "out:Primary Energy": 68.03,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.03,
    "out:CO2 Operational/m2": -30.32,
    "out:Total CO2/m2": 17.71,
    "out:Total CO2 (tons)": 55.57,
    "out:Klimatpaverkan": -144.12,
    "out:Initial Cost/MSEK": 10.22225,
    "out:Running cost/(Apt SEK y)": 7314,
    "out:Running Cost over RSP/MSEK": 1.655,
    "out:LCP/MSEK": -0.188,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2873.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1283,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23093.7,
    "out:PV (% sold (El))": 32.93,
    "out:PV (kWh self-consumed)": 47031.72,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -66889,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 395317,
    "out:DH kr savings": 479585,
    "out:El kr savings": -113712,
    "out:El kr sold": 29444,
    "out:El kr saved": 79954,
    "out:El kr return": 109398,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 8345,
    "out:% savings (space heating)": 97.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 37.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.16,
    "out:Electricity (inc PV)": 37.36,
    "out:Total Energy Use Pre PV": 58.16,
    "out:Total Energy Use Post PV": 40.36,
    "out:Primary Energy": 68.64,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.45,
    "out:CO2 Operational/m2": -30.78,
    "out:Total CO2/m2": 17.67,
    "out:Total CO2 (tons)": 55.43,
    "out:Klimatpaverkan": -144.26,
    "out:Initial Cost/MSEK": 10.303125,
    "out:Running cost/(Apt SEK y)": 7228,
    "out:Running Cost over RSP/MSEK": 1.636,
    "out:LCP/MSEK": -0.25,
    "out:ROI% old": 13.94,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2845.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1285,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23344.52,
    "out:PV (% sold (El))": 33.29,
    "out:PV (kWh self-consumed)": 46780.9,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -67333,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 394883,
    "out:DH kr savings": 479585,
    "out:El kr savings": -114465,
    "out:El kr sold": 29764,
    "out:El kr saved": 79528,
    "out:El kr return": 109292,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9751,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.98,
    "out:Etvv": 0,
    "out:Ef": 37.36,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.21,
    "out:Electricity (inc PV)": 36.52,
    "out:Total Energy Use Pre PV": 56.21,
    "out:Total Energy Use Post PV": 38.52,
    "out:Primary Energy": 66.64,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.45,
    "out:CO2 Operational/m2": -32.06,
    "out:Total CO2/m2": 16.39,
    "out:Total CO2 (tons)": 51.42,
    "out:Klimatpaverkan": -148.27,
    "out:Initial Cost/MSEK": 10.381625,
    "out:Running cost/(Apt SEK y)": 6776,
    "out:Running Cost over RSP/MSEK": 1.533,
    "out:LCP/MSEK": -0.226,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2789,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1298,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.01,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23841.03,
    "out:PV (% sold (El))": 34,
    "out:PV (kWh self-consumed)": 46284.39,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -64678,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 402883,
    "out:DH kr savings": 482439,
    "out:El kr savings": -109953,
    "out:El kr sold": 30397,
    "out:El kr saved": 78683,
    "out:El kr return": 109081,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6867,
    "out:% savings (space heating)": 98.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.29,
    "out:Etvv": 0,
    "out:Ef": 36.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 118.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 68.12,
    "out:Total CO2 (tons)": 213.7,
    "out:Klimatpaverkan": 14.01,
    "out:Initial Cost/MSEK": 2.96075,
    "out:Running cost/(Apt SEK y)": 44716,
    "out:Running Cost over RSP/MSEK": 10.14,
    "out:LCP/MSEK": -1.412,
    "out:ROI% old": 7.47,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 68512,
    "out:DH kr savings": 68512,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 158.9,
    "out:Primary Energy": 115.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 53.58,
    "out:Total CO2/m2": 66.68,
    "out:Total CO2 (tons)": 209.18,
    "out:Klimatpaverkan": 9.49,
    "out:Initial Cost/MSEK": 3.039125,
    "out:Running cost/(Apt SEK y)": 43579,
    "out:Running Cost over RSP/MSEK": 9.882,
    "out:LCP/MSEK": -1.233,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 87836,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 79931,
    "out:DH kr savings": 79931,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 116.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.53,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 66.38,
    "out:Total CO2 (tons)": 208.24,
    "out:Klimatpaverkan": 8.55,
    "out:Initial Cost/MSEK": 3.120125,
    "out:Running cost/(Apt SEK y)": 43010,
    "out:Running Cost over RSP/MSEK": 9.753,
    "out:LCP/MSEK": -1.184,
    "out:ROI% old": 8.86,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 247,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 94110,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85640,
    "out:DH kr savings": 85640,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 113.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.53,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 64.58,
    "out:Total CO2 (tons)": 202.59,
    "out:Klimatpaverkan": 2.9,
    "out:Initial Cost/MSEK": 3.1985,
    "out:Running cost/(Apt SEK y)": 41589,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": -0.94,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99913,
    "out:DH kr savings": 99913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 109.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 49.48,
    "out:Total CO2 (tons)": 155.22,
    "out:Klimatpaverkan": -44.47,
    "out:Initial Cost/MSEK": 3.61325,
    "out:Running cost/(Apt SEK y)": 42937,
    "out:Running Cost over RSP/MSEK": 9.747,
    "out:LCP/MSEK": -1.672,
    "out:ROI% old": 7.68,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120112,
    "out:DH kr savings": 68512,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 153.73,
    "out:Primary Energy": 106.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 150.7,
    "out:Klimatpaverkan": -48.99,
    "out:Initial Cost/MSEK": 3.69175,
    "out:Running cost/(Apt SEK y)": 41799,
    "out:Running Cost over RSP/MSEK": 9.489,
    "out:LCP/MSEK": -1.492,
    "out:ROI% old": 8.51,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87836,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131531,
    "out:DH kr savings": 79931,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 106.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.88,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 47.74,
    "out:Total CO2 (tons)": 149.76,
    "out:Klimatpaverkan": -49.93,
    "out:Initial Cost/MSEK": 3.772625,
    "out:Running cost/(Apt SEK y)": 41231,
    "out:Running Cost over RSP/MSEK": 9.36,
    "out:LCP/MSEK": -1.444,
    "out:ROI% old": 8.82,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 299,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94110,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137240,
    "out:DH kr savings": 85640,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 103.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.88,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 45.94,
    "out:Total CO2 (tons)": 144.11,
    "out:Klimatpaverkan": -55.58,
    "out:Initial Cost/MSEK": 3.851125,
    "out:Running cost/(Apt SEK y)": 39809,
    "out:Running Cost over RSP/MSEK": 9.038,
    "out:LCP/MSEK": -1.2,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151513,
    "out:DH kr savings": 99913,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 107.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.81,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": 9.15,
    "out:Total CO2 (tons)": 28.72,
    "out:Klimatpaverkan": -170.97,
    "out:Initial Cost/MSEK": 4.26575,
    "out:Running cost/(Apt SEK y)": 41356,
    "out:Running Cost over RSP/MSEK": 9.399,
    "out:LCP/MSEK": -1.976,
    "out:ROI% old": 7.67,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 295,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 75288,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165949,
    "out:DH kr savings": 68512,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 152.88,
    "out:Primary Energy": 104.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.81,
    "out:CO2 Operational/m2": -30.1,
    "out:Total CO2/m2": 7.71,
    "out:Total CO2 (tons)": 24.2,
    "out:Klimatpaverkan": -175.49,
    "out:Initial Cost/MSEK": 4.34425,
    "out:Running cost/(Apt SEK y)": 40219,
    "out:Running Cost over RSP/MSEK": 9.141,
    "out:LCP/MSEK": -1.796,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 87836,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177367,
    "out:DH kr savings": 79931,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 105.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.23,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 7.41,
    "out:Total CO2 (tons)": 23.26,
    "out:Klimatpaverkan": -176.43,
    "out:Initial Cost/MSEK": 4.425125,
    "out:Running cost/(Apt SEK y)": 39650,
    "out:Running Cost over RSP/MSEK": 9.012,
    "out:LCP/MSEK": -1.748,
    "out:ROI% old": 8.64,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 345,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 94110,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183077,
    "out:DH kr savings": 85640,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 102.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.23,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 5.61,
    "out:Total CO2 (tons)": 17.61,
    "out:Klimatpaverkan": -182.08,
    "out:Initial Cost/MSEK": 4.503625,
    "out:Running cost/(Apt SEK y)": 38229,
    "out:Running Cost over RSP/MSEK": 8.689,
    "out:LCP/MSEK": -1.504,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197350,
    "out:DH kr savings": 99913,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 115.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 65.24,
    "out:Total CO2 (tons)": 204.67,
    "out:Klimatpaverkan": 4.98,
    "out:Initial Cost/MSEK": 3.03725,
    "out:Running cost/(Apt SEK y)": 42441,
    "out:Running Cost over RSP/MSEK": 9.624,
    "out:LCP/MSEK": -0.973,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91349,
    "out:DH kr savings": 91349,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 149.9,
    "out:Primary Energy": 112.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 50.34,
    "out:Total CO2/m2": 63.44,
    "out:Total CO2 (tons)": 199.02,
    "out:Klimatpaverkan": -0.67,
    "out:Initial Cost/MSEK": 3.11575,
    "out:Running cost/(Apt SEK y)": 41020,
    "out:Running Cost over RSP/MSEK": 9.301,
    "out:LCP/MSEK": -0.728,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105623,
    "out:DH kr savings": 105623,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 113.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.53,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 63.5,
    "out:Total CO2 (tons)": 199.21,
    "out:Klimatpaverkan": -0.48,
    "out:Initial Cost/MSEK": 3.196625,
    "out:Running cost/(Apt SEK y)": 40736,
    "out:Running Cost over RSP/MSEK": 9.237,
    "out:LCP/MSEK": -0.745,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108477,
    "out:DH kr savings": 108477,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 110.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.53,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 62.06,
    "out:Total CO2 (tons)": 194.69,
    "out:Klimatpaverkan": -5,
    "out:Initial Cost/MSEK": 3.275125,
    "out:Running cost/(Apt SEK y)": 39598,
    "out:Running Cost over RSP/MSEK": 8.979,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119896,
    "out:DH kr savings": 119896,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 105.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 46.6,
    "out:Total CO2 (tons)": 146.19,
    "out:Klimatpaverkan": -53.5,
    "out:Initial Cost/MSEK": 3.689875,
    "out:Running cost/(Apt SEK y)": 40662,
    "out:Running Cost over RSP/MSEK": 9.231,
    "out:LCP/MSEK": -1.232,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 315,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142949,
    "out:DH kr savings": 91349,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 144.73,
    "out:Primary Energy": 102.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 44.8,
    "out:Total CO2 (tons)": 140.54,
    "out:Klimatpaverkan": -59.15,
    "out:Initial Cost/MSEK": 3.76825,
    "out:Running cost/(Apt SEK y)": 39241,
    "out:Running Cost over RSP/MSEK": 8.909,
    "out:LCP/MSEK": -0.988,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157223,
    "out:DH kr savings": 105623,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 103.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.88,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 44.86,
    "out:Total CO2 (tons)": 140.73,
    "out:Klimatpaverkan": -58.96,
    "out:Initial Cost/MSEK": 3.84925,
    "out:Running cost/(Apt SEK y)": 38956,
    "out:Running Cost over RSP/MSEK": 8.844,
    "out:LCP/MSEK": -1.005,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160077,
    "out:DH kr savings": 108477,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 100.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.88,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 43.42,
    "out:Total CO2 (tons)": 136.21,
    "out:Klimatpaverkan": -63.48,
    "out:Initial Cost/MSEK": 3.927625,
    "out:Running cost/(Apt SEK y)": 37819,
    "out:Running Cost over RSP/MSEK": 8.586,
    "out:LCP/MSEK": -0.825,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171496,
    "out:DH kr savings": 119896,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 104.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.81,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 6.27,
    "out:Total CO2 (tons)": 19.68,
    "out:Klimatpaverkan": -180.01,
    "out:Initial Cost/MSEK": 4.342375,
    "out:Running cost/(Apt SEK y)": 39082,
    "out:Running Cost over RSP/MSEK": 8.883,
    "out:LCP/MSEK": -1.536,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 100384,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188786,
    "out:DH kr savings": 91349,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 143.88,
    "out:Primary Energy": 101.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.81,
    "out:CO2 Operational/m2": -33.34,
    "out:Total CO2/m2": 4.47,
    "out:Total CO2 (tons)": 14.04,
    "out:Klimatpaverkan": -185.65,
    "out:Initial Cost/MSEK": 4.42075,
    "out:Running cost/(Apt SEK y)": 37660,
    "out:Running Cost over RSP/MSEK": 8.56,
    "out:LCP/MSEK": -1.292,
    "out:ROI% old": 10.11,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203059,
    "out:DH kr savings": 105623,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 102.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 38.23,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 4.53,
    "out:Total CO2 (tons)": 14.22,
    "out:Klimatpaverkan": -185.47,
    "out:Initial Cost/MSEK": 4.50175,
    "out:Running cost/(Apt SEK y)": 37376,
    "out:Running Cost over RSP/MSEK": 8.496,
    "out:LCP/MSEK": -1.309,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205914,
    "out:DH kr savings": 108477,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 325483,
    "out:% savings (space heating)": 18.86,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 99.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.23,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 3.09,
    "out:Total CO2 (tons)": 9.71,
    "out:Klimatpaverkan": -189.98,
    "out:Initial Cost/MSEK": 4.580125,
    "out:Running cost/(Apt SEK y)": 36239,
    "out:Running Cost over RSP/MSEK": 8.238,
    "out:LCP/MSEK": -1.129,
    "out:ROI% old": 10.76,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217333,
    "out:DH kr savings": 119896,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 311544,
    "out:% savings (space heating)": 22.33,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 42.39,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 102.39,
    "out:Primary Energy": 110.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.91,
    "out:CO2 Operational/m2": 27.49,
    "out:Total CO2/m2": 44.4,
    "out:Total CO2 (tons)": 139.29,
    "out:Klimatpaverkan": -60.4,
    "out:Initial Cost/MSEK": 5.683125,
    "out:Running cost/(Apt SEK y)": 25259,
    "out:Running Cost over RSP/MSEK": 5.71,
    "out:LCP/MSEK": 0.296,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 3121.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -83097,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175604,
    "out:DH kr savings": 316868,
    "out:El kr savings": -141265,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188686,
    "out:% savings (space heating)": 52.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.93,
    "out:Etvv": 0,
    "out:Ef": 42.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 42.27,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 98.27,
    "out:Primary Energy": 107.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.91,
    "out:CO2 Operational/m2": 26.04,
    "out:Total CO2/m2": 42.95,
    "out:Total CO2 (tons)": 134.74,
    "out:Klimatpaverkan": -64.95,
    "out:Initial Cost/MSEK": 5.7615,
    "out:Running cost/(Apt SEK y)": 24108,
    "out:Running Cost over RSP/MSEK": 5.449,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 15.47,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3116.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -82717,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187668,
    "out:DH kr savings": 328287,
    "out:El kr savings": -140619,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175526,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.89,
    "out:Etvv": 0,
    "out:Ef": 42.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.85,
    "out:Electricity (inc PV)": 41.91,
    "out:Total Energy Use Pre PV": 99.85,
    "out:Total Energy Use Post PV": 97.91,
    "out:Primary Energy": 108.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.33,
    "out:CO2 Operational/m2": 25.95,
    "out:Total CO2/m2": 43.28,
    "out:Total CO2 (tons)": 135.78,
    "out:Klimatpaverkan": -63.91,
    "out:Initial Cost/MSEK": 5.8425,
    "out:Running cost/(Apt SEK y)": 23985,
    "out:Running Cost over RSP/MSEK": 5.421,
    "out:LCP/MSEK": 0.425,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3069.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -81583,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189596,
    "out:DH kr savings": 328287,
    "out:El kr savings": -138691,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175914,
    "out:% savings (space heating)": 56.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.77,
    "out:Etvv": 0,
    "out:Ef": 41.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.76,
    "out:Electricity (inc PV)": 41.78,
    "out:Total Energy Use Pre PV": 95.76,
    "out:Total Energy Use Post PV": 93.78,
    "out:Primary Energy": 105.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.33,
    "out:CO2 Operational/m2": 24.5,
    "out:Total CO2/m2": 41.83,
    "out:Total CO2 (tons)": 131.22,
    "out:Klimatpaverkan": -68.47,
    "out:Initial Cost/MSEK": 5.920875,
    "out:Running cost/(Apt SEK y)": 22831,
    "out:Running Cost over RSP/MSEK": 5.159,
    "out:LCP/MSEK": 0.609,
    "out:ROI% old": 15.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3063.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 833,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -81173,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201711,
    "out:DH kr savings": 339706,
    "out:El kr savings": -137995,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 162915,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.76,
    "out:Etvv": 0,
    "out:Ef": 41.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 32.95,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 92.95,
    "out:Primary Energy": 93.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.27,
    "out:CO2 Operational/m2": 18.83,
    "out:Total CO2/m2": 48.1,
    "out:Total CO2 (tons)": 150.88,
    "out:Klimatpaverkan": -48.81,
    "out:Initial Cost/MSEK": 6.335625,
    "out:Running cost/(Apt SEK y)": 23273,
    "out:Running Cost over RSP/MSEK": 5.272,
    "out:LCP/MSEK": 0.081,
    "out:ROI% old": 14.47,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2471.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 820,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4763.86,
    "out:PV (% sold (El))": 13.59,
    "out:PV (kWh self-consumed)": 30298.85,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -53488,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232012,
    "out:DH kr savings": 316868,
    "out:El kr savings": -90930,
    "out:El kr sold": 6074,
    "out:El kr saved": 51508,
    "out:El kr return": 57582,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188686,
    "out:% savings (space heating)": 52.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.93,
    "out:Etvv": 0,
    "out:Ef": 32.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 32.84,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 88.84,
    "out:Primary Energy": 90.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.27,
    "out:CO2 Operational/m2": 17.34,
    "out:Total CO2/m2": 46.61,
    "out:Total CO2 (tons)": 146.22,
    "out:Klimatpaverkan": -53.47,
    "out:Initial Cost/MSEK": 6.414125,
    "out:Running cost/(Apt SEK y)": 22122,
    "out:Running Cost over RSP/MSEK": 5.01,
    "out:LCP/MSEK": 0.264,
    "out:ROI% old": 14.87,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2467.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4786.41,
    "out:PV (% sold (El))": 13.65,
    "out:PV (kWh self-consumed)": 30276.3,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -53148,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244038,
    "out:DH kr savings": 328287,
    "out:El kr savings": -90351,
    "out:El kr sold": 6103,
    "out:El kr saved": 51470,
    "out:El kr return": 57572,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175526,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.89,
    "out:Etvv": 0,
    "out:Ef": 32.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.85,
    "out:Electricity (inc PV)": 32.52,
    "out:Total Energy Use Pre PV": 99.85,
    "out:Total Energy Use Post PV": 88.52,
    "out:Primary Energy": 91.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.69,
    "out:CO2 Operational/m2": 16.94,
    "out:Total CO2/m2": 46.62,
    "out:Total CO2 (tons)": 146.26,
    "out:Klimatpaverkan": -53.43,
    "out:Initial Cost/MSEK": 6.495,
    "out:Running cost/(Apt SEK y)": 22003,
    "out:Running Cost over RSP/MSEK": 4.984,
    "out:LCP/MSEK": 0.21,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2424.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4990.45,
    "out:PV (% sold (El))": 14.23,
    "out:PV (kWh self-consumed)": 30072.26,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -52132,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246026,
    "out:DH kr savings": 328287,
    "out:El kr savings": -88624,
    "out:El kr sold": 6363,
    "out:El kr saved": 51123,
    "out:El kr return": 57486,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175914,
    "out:% savings (space heating)": 56.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.77,
    "out:Etvv": 0,
    "out:Ef": 32.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.76,
    "out:Electricity (inc PV)": 32.4,
    "out:Total Energy Use Pre PV": 95.76,
    "out:Total Energy Use Post PV": 84.4,
    "out:Primary Energy": 88.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.69,
    "out:CO2 Operational/m2": 15.44,
    "out:Total CO2/m2": 45.13,
    "out:Total CO2 (tons)": 141.57,
    "out:Klimatpaverkan": -58.12,
    "out:Initial Cost/MSEK": 6.5735,
    "out:Running cost/(Apt SEK y)": 20849,
    "out:Running Cost over RSP/MSEK": 4.722,
    "out:LCP/MSEK": 0.393,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2418.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 890,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5018.61,
    "out:PV (% sold (El))": 14.31,
    "out:PV (kWh self-consumed)": 30044.1,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -51765,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258104,
    "out:DH kr savings": 339706,
    "out:El kr savings": -88001,
    "out:El kr sold": 6399,
    "out:El kr saved": 51075,
    "out:El kr return": 57474,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 162915,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.76,
    "out:Etvv": 0,
    "out:Ef": 32.4,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.6,
    "out:Electricity (inc PV)": 29.78,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 89.78,
    "out:Primary Energy": 87.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.62,
    "out:CO2 Operational/m2": -21.02,
    "out:Total CO2/m2": 20.6,
    "out:Total CO2 (tons)": 64.61,
    "out:Klimatpaverkan": -135.08,
    "out:Initial Cost/MSEK": 6.98825,
    "out:Running cost/(Apt SEK y)": 21576,
    "out:Running Cost over RSP/MSEK": 4.897,
    "out:LCP/MSEK": -0.196,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2230.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 869,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29224.56,
    "out:PV (% sold (El))": 41.67,
    "out:PV (kWh self-consumed)": 40900.86,
    "out:PV (ratio sold/self-consumed)": 0.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 348207,
    "out:EL kWh savings": -43536,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280119,
    "out:DH kr savings": 316868,
    "out:El kr savings": -74011,
    "out:El kr sold": 37261,
    "out:El kr saved": 69531,
    "out:El kr return": 106793,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 188686,
    "out:% savings (space heating)": 52.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 48.93,
    "out:Etvv": 0,
    "out:Ef": 29.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.52,
    "out:Electricity (inc PV)": 29.68,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 85.68,
    "out:Primary Energy": 84.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.62,
    "out:CO2 Operational/m2": -22.55,
    "out:Total CO2/m2": 19.07,
    "out:Total CO2 (tons)": 59.84,
    "out:Klimatpaverkan": -139.85,
    "out:Initial Cost/MSEK": 7.066625,
    "out:Running cost/(Apt SEK y)": 20426,
    "out:Running Cost over RSP/MSEK": 4.636,
    "out:LCP/MSEK": -0.014,
    "out:ROI% old": 14.26,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2226.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29269.59,
    "out:PV (% sold (El))": 41.74,
    "out:PV (kWh self-consumed)": 40855.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -43231,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292113,
    "out:DH kr savings": 328287,
    "out:El kr savings": -73493,
    "out:El kr sold": 37319,
    "out:El kr saved": 69455,
    "out:El kr return": 106774,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175526,
    "out:% savings (space heating)": 56.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.89,
    "out:Etvv": 0,
    "out:Ef": 29.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.85,
    "out:Electricity (inc PV)": 29.39,
    "out:Total Energy Use Pre PV": 99.85,
    "out:Total Energy Use Post PV": 85.39,
    "out:Primary Energy": 85.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.04,
    "out:CO2 Operational/m2": -23.28,
    "out:Total CO2/m2": 18.76,
    "out:Total CO2 (tons)": 58.86,
    "out:Klimatpaverkan": -140.83,
    "out:Initial Cost/MSEK": 7.147625,
    "out:Running cost/(Apt SEK y)": 20309,
    "out:Running Cost over RSP/MSEK": 4.61,
    "out:LCP/MSEK": -0.069,
    "out:ROI% old": 14.15,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2188.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29671.24,
    "out:PV (% sold (El))": 42.31,
    "out:PV (kWh self-consumed)": 40454.17,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 360755,
    "out:EL kWh savings": -42320,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294173,
    "out:DH kr savings": 328287,
    "out:El kr savings": -71945,
    "out:El kr sold": 37831,
    "out:El kr saved": 68772,
    "out:El kr return": 106603,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175914,
    "out:% savings (space heating)": 56.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.77,
    "out:Etvv": 0,
    "out:Ef": 29.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.76,
    "out:Electricity (inc PV)": 29.29,
    "out:Total Energy Use Pre PV": 95.76,
    "out:Total Energy Use Post PV": 81.29,
    "out:Primary Energy": 82.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.04,
    "out:CO2 Operational/m2": -24.82,
    "out:Total CO2/m2": 17.23,
    "out:Total CO2 (tons)": 54.04,
    "out:Klimatpaverkan": -145.65,
    "out:Initial Cost/MSEK": 7.226,
    "out:Running cost/(Apt SEK y)": 19156,
    "out:Running Cost over RSP/MSEK": 4.348,
    "out:LCP/MSEK": 0.115,
    "out:ROI% old": 14.51,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2183.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29726.18,
    "out:PV (% sold (El))": 42.39,
    "out:PV (kWh self-consumed)": 40399.24,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -41992,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306221,
    "out:DH kr savings": 339706,
    "out:El kr savings": -71386,
    "out:El kr sold": 37901,
    "out:El kr saved": 68679,
    "out:El kr return": 106580,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 162915,
    "out:% savings (space heating)": 59.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.76,
    "out:Etvv": 0,
    "out:Ef": 29.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.6,
    "out:Electricity (inc PV)": 41.74,
    "out:Total Energy Use Pre PV": 98.6,
    "out:Total Energy Use Post PV": 96.74,
    "out:Primary Energy": 107.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.91,
    "out:CO2 Operational/m2": 25.55,
    "out:Total CO2/m2": 42.47,
    "out:Total CO2 (tons)": 133.23,
    "out:Klimatpaverkan": -66.46,
    "out:Initial Cost/MSEK": 5.759625,
    "out:Running cost/(Apt SEK y)": 23653,
    "out:Running Cost over RSP/MSEK": 5.346,
    "out:LCP/MSEK": 0.583,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 3051.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 363892,
    "out:EL kWh savings": -81072,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193320,
    "out:DH kr savings": 331142,
    "out:El kr savings": -137822,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 171748,
    "out:% savings (space heating)": 57.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.06,
    "out:Etvv": 0,
    "out:Ef": 41.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.5,
    "out:Electricity (inc PV)": 41.61,
    "out:Total Energy Use Pre PV": 94.5,
    "out:Total Energy Use Post PV": 92.61,
    "out:Primary Energy": 104.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.91,
    "out:CO2 Operational/m2": 24.1,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 128.67,
    "out:Klimatpaverkan": -71.02,
    "out:Initial Cost/MSEK": 5.838125,
    "out:Running cost/(Apt SEK y)": 22499,
    "out:Running Cost over RSP/MSEK": 5.084,
    "out:LCP/MSEK": 0.767,
    "out:ROI% old": 16.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 3045,
    "out:Return %": 14,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -80649,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205456,
    "out:DH kr savings": 342560,
    "out:El kr savings": -137104,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 158803,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.06,
    "out:Etvv": 0,
    "out:Ef": 41.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.96,
    "out:Electricity (inc PV)": 41.35,
    "out:Total Energy Use Pre PV": 94.96,
    "out:Total Energy Use Post PV": 93.35,
    "out:Primary Energy": 105.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.33,
    "out:CO2 Operational/m2": 24.39,
    "out:Total CO2/m2": 41.73,
    "out:Total CO2 (tons)": 130.89,
    "out:Klimatpaverkan": -68.8,
    "out:Initial Cost/MSEK": 5.919,
    "out:Running cost/(Apt SEK y)": 22684,
    "out:Running Cost over RSP/MSEK": 5.126,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3007.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 837,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -79826,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204001,
    "out:DH kr savings": 339706,
    "out:El kr savings": -135705,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 161915,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.37,
    "out:Etvv": 0,
    "out:Ef": 41.35,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 41.2,
    "out:Total Energy Use Pre PV": 90.85,
    "out:Total Energy Use Post PV": 89.2,
    "out:Primary Energy": 102.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.33,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 40.27,
    "out:Total CO2 (tons)": 126.33,
    "out:Klimatpaverkan": -73.36,
    "out:Initial Cost/MSEK": 5.9975,
    "out:Running cost/(Apt SEK y)": 21527,
    "out:Running Cost over RSP/MSEK": 4.864,
    "out:LCP/MSEK": 0.827,
    "out:ROI% old": 16.26,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2999.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -79374,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216189,
    "out:DH kr savings": 351124,
    "out:El kr savings": -134936,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.4,
    "out:Etvv": 0,
    "out:Ef": 41.2,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.6,
    "out:Electricity (inc PV)": 32.37,
    "out:Total Energy Use Pre PV": 98.6,
    "out:Total Energy Use Post PV": 87.37,
    "out:Primary Energy": 90.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.27,
    "out:CO2 Operational/m2": 16.42,
    "out:Total CO2/m2": 45.69,
    "out:Total CO2 (tons)": 143.32,
    "out:Klimatpaverkan": -56.37,
    "out:Initial Cost/MSEK": 6.41225,
    "out:Running cost/(Apt SEK y)": 21672,
    "out:Running Cost over RSP/MSEK": 4.909,
    "out:LCP/MSEK": 0.368,
    "out:ROI% old": 15.11,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2407.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5070.42,
    "out:PV (% sold (El))": 14.46,
    "out:PV (kWh self-consumed)": 29992.29,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 363892,
    "out:EL kWh savings": -51674,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249761,
    "out:DH kr savings": 331142,
    "out:El kr savings": -87846,
    "out:El kr sold": 6465,
    "out:El kr saved": 50987,
    "out:El kr return": 57452,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 171748,
    "out:% savings (space heating)": 57.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.06,
    "out:Etvv": 0,
    "out:Ef": 32.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.5,
    "out:Electricity (inc PV)": 32.25,
    "out:Total Energy Use Pre PV": 94.5,
    "out:Total Energy Use Post PV": 83.25,
    "out:Primary Energy": 87.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.27,
    "out:CO2 Operational/m2": 14.92,
    "out:Total CO2/m2": 44.19,
    "out:Total CO2 (tons)": 138.62,
    "out:Klimatpaverkan": -61.07,
    "out:Initial Cost/MSEK": 6.490625,
    "out:Running cost/(Apt SEK y)": 20518,
    "out:Running Cost over RSP/MSEK": 4.647,
    "out:LCP/MSEK": 0.551,
    "out:ROI% old": 15.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2401.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 900,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5100.52,
    "out:PV (% sold (El))": 14.55,
    "out:PV (kWh self-consumed)": 29962.19,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -51296,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261860,
    "out:DH kr savings": 342560,
    "out:El kr savings": -87204,
    "out:El kr sold": 6503,
    "out:El kr saved": 50936,
    "out:El kr return": 57439,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 158803,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.06,
    "out:Etvv": 0,
    "out:Ef": 32.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.96,
    "out:Electricity (inc PV)": 32.02,
    "out:Total Energy Use Pre PV": 94.96,
    "out:Total Energy Use Post PV": 84.02,
    "out:Primary Energy": 88.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.69,
    "out:CO2 Operational/m2": 14.94,
    "out:Total CO2/m2": 44.63,
    "out:Total CO2 (tons)": 140.01,
    "out:Klimatpaverkan": -59.68,
    "out:Initial Cost/MSEK": 6.571625,
    "out:Running cost/(Apt SEK y)": 20706,
    "out:Running Cost over RSP/MSEK": 4.69,
    "out:LCP/MSEK": 0.427,
    "out:ROI% old": 15.21,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2367.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 894,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5269.73,
    "out:PV (% sold (El))": 15.03,
    "out:PV (kWh self-consumed)": 29792.98,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -50561,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260471,
    "out:DH kr savings": 339706,
    "out:El kr savings": -85954,
    "out:El kr sold": 6719,
    "out:El kr saved": 50648,
    "out:El kr return": 57367,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 161915,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.37,
    "out:Etvv": 0,
    "out:Ef": 32.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 31.89,
    "out:Total Energy Use Pre PV": 90.85,
    "out:Total Energy Use Post PV": 79.89,
    "out:Primary Energy": 85.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.69,
    "out:CO2 Operational/m2": 13.43,
    "out:Total CO2/m2": 43.12,
    "out:Total CO2 (tons)": 135.27,
    "out:Klimatpaverkan": -64.42,
    "out:Initial Cost/MSEK": 6.65,
    "out:Running cost/(Apt SEK y)": 19549,
    "out:Running Cost over RSP/MSEK": 4.427,
    "out:LCP/MSEK": 0.611,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2360.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 928,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5304.53,
    "out:PV (% sold (El))": 15.13,
    "out:PV (kWh self-consumed)": 29758.18,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -50157,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272620,
    "out:DH kr savings": 351124,
    "out:El kr savings": -85267,
    "out:El kr sold": 6763,
    "out:El kr saved": 50589,
    "out:El kr return": 57352,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.4,
    "out:Etvv": 0,
    "out:Ef": 31.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.6,
    "out:Electricity (inc PV)": 29.26,
    "out:Total Energy Use Pre PV": 98.6,
    "out:Total Energy Use Post PV": 84.26,
    "out:Primary Energy": 84.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.62,
    "out:CO2 Operational/m2": -23.92,
    "out:Total CO2/m2": 17.7,
    "out:Total CO2 (tons)": 55.54,
    "out:Klimatpaverkan": -144.15,
    "out:Initial Cost/MSEK": 7.06475,
    "out:Running cost/(Apt SEK y)": 19980,
    "out:Running Cost over RSP/MSEK": 4.535,
    "out:LCP/MSEK": 0.089,
    "out:ROI% old": 14.47,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2173.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.4,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29826.72,
    "out:PV (% sold (El))": 42.53,
    "out:PV (kWh self-consumed)": 40298.69,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 363892,
    "out:EL kWh savings": -41910,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297924,
    "out:DH kr savings": 331142,
    "out:El kr savings": -71247,
    "out:El kr sold": 38029,
    "out:El kr saved": 68508,
    "out:El kr return": 106537,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 171748,
    "out:% savings (space heating)": 57.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 46.06,
    "out:Etvv": 0,
    "out:Ef": 29.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.5,
    "out:Electricity (inc PV)": 29.15,
    "out:Total Energy Use Pre PV": 94.5,
    "out:Total Energy Use Post PV": 80.15,
    "out:Primary Energy": 81.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.62,
    "out:CO2 Operational/m2": -25.46,
    "out:Total CO2/m2": 16.16,
    "out:Total CO2 (tons)": 50.69,
    "out:Klimatpaverkan": -149,
    "out:Initial Cost/MSEK": 7.143125,
    "out:Running cost/(Apt SEK y)": 18826,
    "out:Running Cost over RSP/MSEK": 4.273,
    "out:LCP/MSEK": 0.272,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2168.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 25.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29884.99,
    "out:PV (% sold (El))": 42.62,
    "out:PV (kWh self-consumed)": 40240.43,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 376440,
    "out:EL kWh savings": -41572,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309992,
    "out:DH kr savings": 342560,
    "out:El kr savings": -70672,
    "out:El kr sold": 38103,
    "out:El kr saved": 68409,
    "out:El kr return": 106512,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 158803,
    "out:% savings (space heating)": 60.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 42.06,
    "out:Etvv": 0,
    "out:Ef": 29.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.96,
    "out:Electricity (inc PV)": 28.94,
    "out:Total Energy Use Pre PV": 94.96,
    "out:Total Energy Use Post PV": 80.94,
    "out:Primary Energy": 83.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.04,
    "out:CO2 Operational/m2": -25.69,
    "out:Total CO2/m2": 16.35,
    "out:Total CO2 (tons)": 51.28,
    "out:Klimatpaverkan": -148.41,
    "out:Initial Cost/MSEK": 7.224125,
    "out:Running cost/(Apt SEK y)": 19016,
    "out:Running Cost over RSP/MSEK": 4.317,
    "out:LCP/MSEK": 0.148,
    "out:ROI% old": 14.58,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2137.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 943,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.76,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30211.61,
    "out:PV (% sold (El))": 43.08,
    "out:PV (kWh self-consumed)": 39913.8,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 373303,
    "out:EL kWh savings": -40912,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308675,
    "out:DH kr savings": 339706,
    "out:El kr savings": -69551,
    "out:El kr sold": 38520,
    "out:El kr saved": 67853,
    "out:El kr return": 106373,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 161915,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 44.37,
    "out:Etvv": 0,
    "out:Ef": 28.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 28.83,
    "out:Total Energy Use Pre PV": 90.85,
    "out:Total Energy Use Post PV": 76.83,
    "out:Primary Energy": 80.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.04,
    "out:CO2 Operational/m2": -27.26,
    "out:Total CO2/m2": 14.79,
    "out:Total CO2 (tons)": 46.38,
    "out:Klimatpaverkan": -153.31,
    "out:Initial Cost/MSEK": 7.3025,
    "out:Running cost/(Apt SEK y)": 17860,
    "out:Running Cost over RSP/MSEK": 4.054,
    "out:LCP/MSEK": 0.332,
    "out:ROI% old": 14.94,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2131.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 977,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30278.43,
    "out:PV (% sold (El))": 43.18,
    "out:PV (kWh self-consumed)": 39846.99,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385851,
    "out:EL kWh savings": -40550,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320794,
    "out:DH kr savings": 351124,
    "out:El kr savings": -68935,
    "out:El kr sold": 38605,
    "out:El kr saved": 67740,
    "out:El kr return": 106345,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 149105,
    "out:% savings (space heating)": 62.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 40.4,
    "out:Etvv": 0,
    "out:Ef": 28.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.46,
    "out:Electricity (inc PV)": 55.85,
    "out:Total Energy Use Pre PV": 68.46,
    "out:Total Energy Use Post PV": 63.85,
    "out:Primary Energy": 103.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.56,
    "out:CO2 Operational/m2": 10.86,
    "out:Total CO2/m2": 28.42,
    "out:Total CO2 (tons)": 89.15,
    "out:Klimatpaverkan": -110.54,
    "out:Initial Cost/MSEK": 7.5395,
    "out:Running cost/(Apt SEK y)": 13392,
    "out:Running Cost over RSP/MSEK": 3.009,
    "out:LCP/MSEK": 1.141,
    "out:ROI% old": 16.45,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4232,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1106,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -125332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252247,
    "out:DH kr savings": 465311,
    "out:El kr savings": -213064,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24481,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 55.85,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.7,
    "out:Electricity (inc PV)": 54.88,
    "out:Total Energy Use Pre PV": 65.7,
    "out:Total Energy Use Post PV": 60.88,
    "out:Primary Energy": 101.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.56,
    "out:CO2 Operational/m2": 10.04,
    "out:Total CO2/m2": 27.6,
    "out:Total CO2 (tons)": 86.58,
    "out:Klimatpaverkan": -113.11,
    "out:Initial Cost/MSEK": 7.617875,
    "out:Running cost/(Apt SEK y)": 12684,
    "out:Running Cost over RSP/MSEK": 2.848,
    "out:LCP/MSEK": 1.223,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4178.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1127,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -122267,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263166,
    "out:DH kr savings": 471021,
    "out:El kr savings": -207854,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18417,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.28,
    "out:Etvv": 0,
    "out:Ef": 54.88,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.9,
    "out:Electricity (inc PV)": 54.86,
    "out:Total Energy Use Pre PV": 64.9,
    "out:Total Energy Use Post PV": 60.86,
    "out:Primary Energy": 101.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.98,
    "out:CO2 Operational/m2": 9.93,
    "out:Total CO2/m2": 27.91,
    "out:Total CO2 (tons)": 87.56,
    "out:Klimatpaverkan": -112.13,
    "out:Initial Cost/MSEK": 7.698875,
    "out:Running cost/(Apt SEK y)": 12537,
    "out:Running Cost over RSP/MSEK": 2.815,
    "out:LCP/MSEK": 1.175,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4122.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -122222,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263243,
    "out:DH kr savings": 471021,
    "out:El kr savings": -207778,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20171,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 54.86,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.05,
    "out:Electricity (inc PV)": 53.85,
    "out:Total Energy Use Pre PV": 63.05,
    "out:Total Energy Use Post PV": 58.85,
    "out:Primary Energy": 98.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.98,
    "out:CO2 Operational/m2": 9.46,
    "out:Total CO2/m2": 27.44,
    "out:Total CO2 (tons)": 86.08,
    "out:Klimatpaverkan": -113.61,
    "out:Initial Cost/MSEK": 7.77725,
    "out:Running cost/(Apt SEK y)": 12096,
    "out:Running Cost over RSP/MSEK": 2.716,
    "out:LCP/MSEK": 1.196,
    "out:ROI% old": 16.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4063.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -119064,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271466,
    "out:DH kr savings": 473875,
    "out:El kr savings": -202409,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15027,
    "out:% savings (space heating)": 96.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.9,
    "out:Etvv": 0,
    "out:Ef": 53.85,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.46,
    "out:Electricity (inc PV)": 45.35,
    "out:Total Energy Use Pre PV": 68.46,
    "out:Total Energy Use Post PV": 53.35,
    "out:Primary Energy": 84.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.91,
    "out:CO2 Operational/m2": 7.46,
    "out:Total CO2/m2": 37.37,
    "out:Total CO2 (tons)": 117.22,
    "out:Klimatpaverkan": -82.47,
    "out:Initial Cost/MSEK": 8.192,
    "out:Running cost/(Apt SEK y)": 11357,
    "out:Running Cost over RSP/MSEK": 2.56,
    "out:LCP/MSEK": 0.937,
    "out:ROI% old": 15.92,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3518.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1367.78,
    "out:PV (% sold (El))": 3.9,
    "out:PV (kWh self-consumed)": 33694.93,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -92375,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310018,
    "out:DH kr savings": 465311,
    "out:El kr savings": -157038,
    "out:El kr sold": 1744,
    "out:El kr saved": 57281,
    "out:El kr return": 59025,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24481,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 45.35,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.7,
    "out:Electricity (inc PV)": 44.43,
    "out:Total Energy Use Pre PV": 65.7,
    "out:Total Energy Use Post PV": 50.43,
    "out:Primary Energy": 82.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.91,
    "out:CO2 Operational/m2": 6.47,
    "out:Total CO2/m2": 36.38,
    "out:Total CO2 (tons)": 114.13,
    "out:Klimatpaverkan": -85.56,
    "out:Initial Cost/MSEK": 8.2705,
    "out:Running cost/(Apt SEK y)": 10650,
    "out:Running Cost over RSP/MSEK": 2.4,
    "out:LCP/MSEK": 1.019,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3467.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1478.35,
    "out:PV (% sold (El))": 4.22,
    "out:PV (kWh self-consumed)": 33584.36,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -89490,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320772,
    "out:DH kr savings": 471021,
    "out:El kr savings": -152134,
    "out:El kr sold": 1885,
    "out:El kr saved": 57093,
    "out:El kr return": 58978,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18417,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.28,
    "out:Etvv": 0,
    "out:Ef": 44.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.9,
    "out:Electricity (inc PV)": 44.41,
    "out:Total Energy Use Pre PV": 64.9,
    "out:Total Energy Use Post PV": 50.41,
    "out:Primary Energy": 82.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.33,
    "out:CO2 Operational/m2": 6.18,
    "out:Total CO2/m2": 36.51,
    "out:Total CO2 (tons)": 114.53,
    "out:Klimatpaverkan": -85.16,
    "out:Initial Cost/MSEK": 8.351375,
    "out:Running cost/(Apt SEK y)": 10505,
    "out:Running Cost over RSP/MSEK": 2.367,
    "out:LCP/MSEK": 0.97,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3413.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1599.31,
    "out:PV (% sold (El))": 4.56,
    "out:PV (kWh self-consumed)": 33463.4,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -89448,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320998,
    "out:DH kr savings": 471021,
    "out:El kr savings": -152062,
    "out:El kr sold": 2039,
    "out:El kr saved": 56888,
    "out:El kr return": 58927,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20171,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 44.41,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.05,
    "out:Electricity (inc PV)": 43.47,
    "out:Total Energy Use Pre PV": 63.05,
    "out:Total Energy Use Post PV": 48.47,
    "out:Primary Energy": 80.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.33,
    "out:CO2 Operational/m2": 5.5,
    "out:Total CO2/m2": 35.83,
    "out:Total CO2 (tons)": 112.41,
    "out:Klimatpaverkan": -87.28,
    "out:Initial Cost/MSEK": 8.429875,
    "out:Running cost/(Apt SEK y)": 10066,
    "out:Running Cost over RSP/MSEK": 2.268,
    "out:LCP/MSEK": 0.991,
    "out:ROI% old": 15.97,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3356.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1732.43,
    "out:PV (% sold (El))": 4.94,
    "out:PV (kWh self-consumed)": 33330.28,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -86486,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329058,
    "out:DH kr savings": 473875,
    "out:El kr savings": -147026,
    "out:El kr sold": 2209,
    "out:El kr saved": 56661,
    "out:El kr return": 58870,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15027,
    "out:% savings (space heating)": 96.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.9,
    "out:Etvv": 0,
    "out:Ef": 43.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.46,
    "out:Electricity (inc PV)": 40.83,
    "out:Total Energy Use Pre PV": 68.46,
    "out:Total Energy Use Post PV": 48.83,
    "out:Primary Energy": 76.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.27,
    "out:CO2 Operational/m2": -24.04,
    "out:Total CO2/m2": 18.22,
    "out:Total CO2 (tons)": 57.17,
    "out:Klimatpaverkan": -142.52,
    "out:Initial Cost/MSEK": 8.844625,
    "out:Running cost/(Apt SEK y)": 9584,
    "out:Running Cost over RSP/MSEK": 2.168,
    "out:LCP/MSEK": 0.676,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3159.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.26,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 20677.84,
    "out:PV (% sold (El))": 29.49,
    "out:PV (kWh self-consumed)": 49447.58,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 511331,
    "out:EL kWh savings": -78194,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 358746,
    "out:DH kr savings": 465311,
    "out:El kr savings": -132930,
    "out:El kr sold": 26364,
    "out:El kr saved": 84061,
    "out:El kr return": 110425,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 24481,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.5,
    "out:Etvv": 0,
    "out:Ef": 40.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.7,
    "out:Electricity (inc PV)": 40.01,
    "out:Total Energy Use Pre PV": 65.7,
    "out:Total Energy Use Post PV": 46.01,
    "out:Primary Energy": 74.32,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.27,
    "out:CO2 Operational/m2": -25.45,
    "out:Total CO2/m2": 16.81,
    "out:Total CO2 (tons)": 52.74,
    "out:Klimatpaverkan": -146.95,
    "out:Initial Cost/MSEK": 8.923,
    "out:Running cost/(Apt SEK y)": 8881,
    "out:Running Cost over RSP/MSEK": 2.009,
    "out:LCP/MSEK": 0.757,
    "out:ROI% old": 15.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3114.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1237,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.5,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21048.95,
    "out:PV (% sold (El))": 30.02,
    "out:PV (kWh self-consumed)": 49076.47,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -75635,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369279,
    "out:DH kr savings": 471021,
    "out:El kr savings": -128579,
    "out:El kr sold": 26837,
    "out:El kr saved": 83430,
    "out:El kr return": 110267,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18417,
    "out:% savings (space heating)": 95.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.28,
    "out:Etvv": 0,
    "out:Ef": 40.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.9,
    "out:Electricity (inc PV)": 40,
    "out:Total Energy Use Pre PV": 64.9,
    "out:Total Energy Use Post PV": 46,
    "out:Primary Energy": 74.81,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.68,
    "out:CO2 Operational/m2": -26.19,
    "out:Total CO2/m2": 16.5,
    "out:Total CO2 (tons)": 51.76,
    "out:Klimatpaverkan": -147.93,
    "out:Initial Cost/MSEK": 9.004,
    "out:Running cost/(Apt SEK y)": 8740,
    "out:Running Cost over RSP/MSEK": 1.977,
    "out:LCP/MSEK": 0.708,
    "out:ROI% old": 15.41,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3066.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21445.47,
    "out:PV (% sold (El))": 30.58,
    "out:PV (kWh self-consumed)": 48679.95,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -75597,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369848,
    "out:DH kr savings": 471021,
    "out:El kr savings": -128515,
    "out:El kr sold": 27343,
    "out:El kr saved": 82756,
    "out:El kr return": 110099,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 20171,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 40,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.05,
    "out:Electricity (inc PV)": 39.16,
    "out:Total Energy Use Pre PV": 63.05,
    "out:Total Energy Use Post PV": 44.16,
    "out:Primary Energy": 72.52,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.68,
    "out:CO2 Operational/m2": -27.33,
    "out:Total CO2/m2": 15.35,
    "out:Total CO2 (tons)": 48.16,
    "out:Klimatpaverkan": -151.53,
    "out:Initial Cost/MSEK": 9.082375,
    "out:Running cost/(Apt SEK y)": 8306,
    "out:Running Cost over RSP/MSEK": 1.879,
    "out:LCP/MSEK": 0.727,
    "out:ROI% old": 15.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3015.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21870.83,
    "out:PV (% sold (El))": 31.19,
    "out:PV (kWh self-consumed)": 48254.59,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -72965,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 377720,
    "out:DH kr savings": 473875,
    "out:El kr savings": -124040,
    "out:El kr sold": 27885,
    "out:El kr saved": 82033,
    "out:El kr return": 109918,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 15027,
    "out:% savings (space heating)": 96.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.9,
    "out:Etvv": 0,
    "out:Ef": 39.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.36,
    "out:Electricity (inc PV)": 54.53,
    "out:Total Energy Use Pre PV": 64.36,
    "out:Total Energy Use Post PV": 60.53,
    "out:Primary Energy": 100.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.56,
    "out:CO2 Operational/m2": 9.86,
    "out:Total CO2/m2": 27.42,
    "out:Total CO2 (tons)": 86.02,
    "out:Klimatpaverkan": -113.67,
    "out:Initial Cost/MSEK": 7.616,
    "out:Running cost/(Apt SEK y)": 12438,
    "out:Running Cost over RSP/MSEK": 2.793,
    "out:LCP/MSEK": 1.28,
    "out:ROI% old": 16.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4085.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -121176,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265022,
    "out:DH kr savings": 471021,
    "out:El kr savings": -205999,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18902,
    "out:% savings (space heating)": 95.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.87,
    "out:Etvv": 0,
    "out:Ef": 54.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.48,
    "out:Electricity (inc PV)": 53.51,
    "out:Total Energy Use Pre PV": 61.48,
    "out:Total Energy Use Post PV": 57.51,
    "out:Primary Energy": 98.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.56,
    "out:CO2 Operational/m2": 9.03,
    "out:Total CO2/m2": 26.59,
    "out:Total CO2 (tons)": 83.4,
    "out:Klimatpaverkan": -116.29,
    "out:Initial Cost/MSEK": 7.6945,
    "out:Running cost/(Apt SEK y)": 11708,
    "out:Running Cost over RSP/MSEK": 2.628,
    "out:LCP/MSEK": 1.366,
    "out:ROI% old": 16.82,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 4023.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1155,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -117990,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276148,
    "out:DH kr savings": 476730,
    "out:El kr savings": -200582,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14035,
    "out:% savings (space heating)": 96.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 53.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.04,
    "out:Electricity (inc PV)": 53.72,
    "out:Total Energy Use Pre PV": 62.04,
    "out:Total Energy Use Post PV": 58.72,
    "out:Primary Energy": 99.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.98,
    "out:CO2 Operational/m2": 9.33,
    "out:Total CO2/m2": 27.31,
    "out:Total CO2 (tons)": 85.67,
    "out:Klimatpaverkan": -114.02,
    "out:Initial Cost/MSEK": 7.775375,
    "out:Running cost/(Apt SEK y)": 11911,
    "out:Running Cost over RSP/MSEK": 2.674,
    "out:LCP/MSEK": 1.239,
    "out:ROI% old": 16.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3992.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1149,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -118647,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272176,
    "out:DH kr savings": 473875,
    "out:El kr savings": -201699,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 16185,
    "out:% savings (space heating)": 95.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 53.72,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 52.69,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 56.69,
    "out:Primary Energy": 96.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.98,
    "out:CO2 Operational/m2": 8.85,
    "out:Total CO2/m2": 26.82,
    "out:Total CO2 (tons)": 84.15,
    "out:Klimatpaverkan": -115.54,
    "out:Initial Cost/MSEK": 7.853875,
    "out:Running cost/(Apt SEK y)": 11455,
    "out:Running Cost over RSP/MSEK": 2.571,
    "out:LCP/MSEK": 1.264,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3927.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1162,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -115398,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280554,
    "out:DH kr savings": 476730,
    "out:El kr savings": -196176,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11916,
    "out:% savings (space heating)": 97.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 52.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.36,
    "out:Electricity (inc PV)": 44.1,
    "out:Total Energy Use Pre PV": 64.36,
    "out:Total Energy Use Post PV": 50.1,
    "out:Primary Energy": 82.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.91,
    "out:CO2 Operational/m2": 5.98,
    "out:Total CO2/m2": 35.89,
    "out:Total CO2 (tons)": 112.59,
    "out:Klimatpaverkan": -87.1,
    "out:Initial Cost/MSEK": 8.268625,
    "out:Running cost/(Apt SEK y)": 10407,
    "out:Running Cost over RSP/MSEK": 2.345,
    "out:LCP/MSEK": 1.075,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3377.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1682.7,
    "out:PV (% sold (El))": 4.8,
    "out:PV (kWh self-consumed)": 33380.01,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -88465,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322776,
    "out:DH kr savings": 471021,
    "out:El kr savings": -150391,
    "out:El kr sold": 2145,
    "out:El kr saved": 56746,
    "out:El kr return": 58891,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18902,
    "out:% savings (space heating)": 95.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.87,
    "out:Etvv": 0,
    "out:Ef": 44.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.48,
    "out:Electricity (inc PV)": 43.15,
    "out:Total Energy Use Pre PV": 61.48,
    "out:Total Energy Use Post PV": 47.15,
    "out:Primary Energy": 79.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.91,
    "out:CO2 Operational/m2": 4.93,
    "out:Total CO2/m2": 34.84,
    "out:Total CO2 (tons)": 109.29,
    "out:Klimatpaverkan": -90.4,
    "out:Initial Cost/MSEK": 8.347,
    "out:Running cost/(Apt SEK y)": 9679,
    "out:Running Cost over RSP/MSEK": 2.18,
    "out:LCP/MSEK": 1.161,
    "out:ROI% old": 16.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3318.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1214,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1824.26,
    "out:PV (% sold (El))": 5.2,
    "out:PV (kWh self-consumed)": 33238.45,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -85480,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333741,
    "out:DH kr savings": 476730,
    "out:El kr savings": -145315,
    "out:El kr sold": 2326,
    "out:El kr saved": 56505,
    "out:El kr return": 58831,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14035,
    "out:% savings (space heating)": 96.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 43.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.04,
    "out:Electricity (inc PV)": 43.34,
    "out:Total Energy Use Pre PV": 62.04,
    "out:Total Energy Use Post PV": 48.34,
    "out:Primary Energy": 80.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.33,
    "out:CO2 Operational/m2": 5.11,
    "out:Total CO2/m2": 35.45,
    "out:Total CO2 (tons)": 111.19,
    "out:Klimatpaverkan": -88.5,
    "out:Initial Cost/MSEK": 8.428,
    "out:Running cost/(Apt SEK y)": 9883,
    "out:Running Cost over RSP/MSEK": 2.227,
    "out:LCP/MSEK": 1.034,
    "out:ROI% old": 16.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3288.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1898.4,
    "out:PV (% sold (El))": 5.41,
    "out:PV (kWh self-consumed)": 33164.31,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -86094,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329935,
    "out:DH kr savings": 473875,
    "out:El kr savings": -146360,
    "out:El kr sold": 2420,
    "out:El kr saved": 56379,
    "out:El kr return": 58800,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 16185,
    "out:% savings (space heating)": 95.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 43.34,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 42.38,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 46.38,
    "out:Primary Energy": 78.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.33,
    "out:CO2 Operational/m2": 4.38,
    "out:Total CO2/m2": 34.71,
    "out:Total CO2 (tons)": 108.9,
    "out:Klimatpaverkan": -90.79,
    "out:Initial Cost/MSEK": 8.506375,
    "out:Running cost/(Apt SEK y)": 9430,
    "out:Running Cost over RSP/MSEK": 2.124,
    "out:LCP/MSEK": 1.058,
    "out:ROI% old": 16.06,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3226.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1221,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2060.35,
    "out:PV (% sold (El))": 5.88,
    "out:PV (kWh self-consumed)": 33002.36,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -83058,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338158,
    "out:DH kr savings": 476730,
    "out:El kr savings": -141199,
    "out:El kr sold": 2627,
    "out:El kr saved": 56104,
    "out:El kr return": 58731,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11916,
    "out:% savings (space heating)": 97.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 42.38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.36,
    "out:Electricity (inc PV)": 39.72,
    "out:Total Energy Use Pre PV": 64.36,
    "out:Total Energy Use Post PV": 45.72,
    "out:Primary Energy": 74.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.27,
    "out:CO2 Operational/m2": -26.68,
    "out:Total CO2/m2": 15.58,
    "out:Total CO2 (tons)": 48.88,
    "out:Klimatpaverkan": -150.81,
    "out:Initial Cost/MSEK": 8.921125,
    "out:Running cost/(Apt SEK y)": 8645,
    "out:Running Cost over RSP/MSEK": 1.956,
    "out:LCP/MSEK": 0.812,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3034.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21713.56,
    "out:PV (% sold (El))": 30.96,
    "out:PV (kWh self-consumed)": 48411.86,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 517605,
    "out:EL kWh savings": -74725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371674,
    "out:DH kr savings": 471021,
    "out:El kr savings": -127032,
    "out:El kr sold": 27685,
    "out:El kr saved": 82300,
    "out:El kr return": 109985,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 18902,
    "out:% savings (space heating)": 95.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.87,
    "out:Etvv": 0,
    "out:Ef": 39.72,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.48,
    "out:Electricity (inc PV)": 38.87,
    "out:Total Energy Use Pre PV": 61.48,
    "out:Total Energy Use Post PV": 42.87,
    "out:Primary Energy": 71.92,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.27,
    "out:CO2 Operational/m2": -28.22,
    "out:Total CO2/m2": 14.05,
    "out:Total CO2 (tons)": 44.06,
    "out:Klimatpaverkan": -155.63,
    "out:Initial Cost/MSEK": 8.9995,
    "out:Running cost/(Apt SEK y)": 7922,
    "out:Running Cost over RSP/MSEK": 1.792,
    "out:LCP/MSEK": 0.897,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2982.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1265,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22155.3,
    "out:PV (% sold (El))": 31.59,
    "out:PV (kWh self-consumed)": 47970.12,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -72071,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382458,
    "out:DH kr savings": 476730,
    "out:El kr savings": -122520,
    "out:El kr sold": 28248,
    "out:El kr saved": 81549,
    "out:El kr return": 109797,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 14035,
    "out:% savings (space heating)": 96.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 38.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.04,
    "out:Electricity (inc PV)": 39.05,
    "out:Total Energy Use Pre PV": 62.04,
    "out:Total Energy Use Post PV": 44.05,
    "out:Primary Energy": 72.76,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.68,
    "out:CO2 Operational/m2": -28.28,
    "out:Total CO2/m2": 14.41,
    "out:Total CO2 (tons)": 45.2,
    "out:Klimatpaverkan": -154.49,
    "out:Initial Cost/MSEK": 9.0805,
    "out:Running cost/(Apt SEK y)": 8128,
    "out:Running Cost over RSP/MSEK": 1.839,
    "out:LCP/MSEK": 0.769,
    "out:ROI% old": 15.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2956.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.84,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22379.88,
    "out:PV (% sold (El))": 31.91,
    "out:PV (kWh self-consumed)": 47745.53,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 520742,
    "out:EL kWh savings": -72617,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378961,
    "out:DH kr savings": 473875,
    "out:El kr savings": -123449,
    "out:El kr sold": 28534,
    "out:El kr saved": 81167,
    "out:El kr return": 109702,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 16185,
    "out:% savings (space heating)": 95.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 39.05,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.11,
    "out:Electricity (inc PV)": 38.19,
    "out:Total Energy Use Pre PV": 60.11,
    "out:Total Energy Use Post PV": 42.19,
    "out:Primary Energy": 70.49,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.68,
    "out:CO2 Operational/m2": -29.52,
    "out:Total CO2/m2": 13.16,
    "out:Total CO2 (tons)": 41.29,
    "out:Klimatpaverkan": -158.4,
    "out:Initial Cost/MSEK": 9.158875,
    "out:Running cost/(Apt SEK y)": 7679,
    "out:Running Cost over RSP/MSEK": 1.738,
    "out:LCP/MSEK": 0.792,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2900.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22856.84,
    "out:PV (% sold (El))": 32.59,
    "out:PV (kWh self-consumed)": 47268.57,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -69918,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 387012,
    "out:DH kr savings": 476730,
    "out:El kr savings": -118860,
    "out:El kr sold": 29142,
    "out:El kr saved": 80357,
    "out:El kr return": 109499,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 11916,
    "out:% savings (space heating)": 97.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 38.19,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 110.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 66.43,
    "out:Total CO2 (tons)": 208.4,
    "out:Klimatpaverkan": 8.71,
    "out:Initial Cost/MSEK": 3.474875,
    "out:Running cost/(Apt SEK y)": 41589,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": -1.217,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 289,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99913,
    "out:DH kr savings": 99913,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 108.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 64.99,
    "out:Total CO2 (tons)": 203.88,
    "out:Klimatpaverkan": 4.19,
    "out:Initial Cost/MSEK": 3.55325,
    "out:Running cost/(Apt SEK y)": 40451,
    "out:Running Cost over RSP/MSEK": 9.172,
    "out:LCP/MSEK": -1.037,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111332,
    "out:DH kr savings": 111332,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 145.9,
    "out:Primary Energy": 108.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.79,
    "out:CO2 Operational/m2": 48.9,
    "out:Total CO2/m2": 64.69,
    "out:Total CO2 (tons)": 202.94,
    "out:Klimatpaverkan": 3.25,
    "out:Initial Cost/MSEK": 3.63425,
    "out:Running cost/(Apt SEK y)": 39883,
    "out:Running Cost over RSP/MSEK": 9.043,
    "out:LCP/MSEK": -0.989,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117041,
    "out:DH kr savings": 117041,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 105.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.79,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 63.25,
    "out:Total CO2 (tons)": 198.42,
    "out:Klimatpaverkan": -1.27,
    "out:Initial Cost/MSEK": 3.712625,
    "out:Running cost/(Apt SEK y)": 38746,
    "out:Running Cost over RSP/MSEK": 8.785,
    "out:LCP/MSEK": -0.809,
    "out:ROI% old": 11.17,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128460,
    "out:DH kr savings": 128460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 101.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.73,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 47.79,
    "out:Total CO2 (tons)": 149.92,
    "out:Klimatpaverkan": -49.77,
    "out:Initial Cost/MSEK": 4.127375,
    "out:Running cost/(Apt SEK y)": 39809,
    "out:Running Cost over RSP/MSEK": 9.038,
    "out:LCP/MSEK": -1.476,
    "out:ROI% old": 9.18,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151513,
    "out:DH kr savings": 99913,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 98.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.73,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 46.35,
    "out:Total CO2 (tons)": 145.4,
    "out:Klimatpaverkan": -54.29,
    "out:Initial Cost/MSEK": 4.20575,
    "out:Running cost/(Apt SEK y)": 38672,
    "out:Running Cost over RSP/MSEK": 8.78,
    "out:LCP/MSEK": -1.297,
    "out:ROI% old": 9.88,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162932,
    "out:DH kr savings": 111332,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 140.73,
    "out:Primary Energy": 98.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.15,
    "out:CO2 Operational/m2": 17.9,
    "out:Total CO2/m2": 46.05,
    "out:Total CO2 (tons)": 144.46,
    "out:Klimatpaverkan": -55.23,
    "out:Initial Cost/MSEK": 4.28675,
    "out:Running cost/(Apt SEK y)": 38103,
    "out:Running Cost over RSP/MSEK": 8.651,
    "out:LCP/MSEK": -1.249,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168641,
    "out:DH kr savings": 117041,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 96.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.15,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 44.61,
    "out:Total CO2 (tons)": 139.94,
    "out:Klimatpaverkan": -59.75,
    "out:Initial Cost/MSEK": 4.365125,
    "out:Running cost/(Apt SEK y)": 36966,
    "out:Running Cost over RSP/MSEK": 8.393,
    "out:LCP/MSEK": -1.069,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180060,
    "out:DH kr savings": 128460,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 100.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.08,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 7.46,
    "out:Total CO2 (tons)": 23.41,
    "out:Klimatpaverkan": -176.28,
    "out:Initial Cost/MSEK": 4.779875,
    "out:Running cost/(Apt SEK y)": 38229,
    "out:Running Cost over RSP/MSEK": 8.689,
    "out:LCP/MSEK": -1.78,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 386,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 197350,
    "out:DH kr savings": 99913,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 97.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.08,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 6.02,
    "out:Total CO2 (tons)": 18.9,
    "out:Klimatpaverkan": -180.79,
    "out:Initial Cost/MSEK": 4.858375,
    "out:Running cost/(Apt SEK y)": 37091,
    "out:Running Cost over RSP/MSEK": 8.431,
    "out:LCP/MSEK": -1.601,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208769,
    "out:DH kr savings": 111332,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 145.9,
    "out:Total Energy Use Post PV": 139.88,
    "out:Primary Energy": 97.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.5,
    "out:CO2 Operational/m2": -34.78,
    "out:Total CO2/m2": 5.72,
    "out:Total CO2 (tons)": 17.95,
    "out:Klimatpaverkan": -181.74,
    "out:Initial Cost/MSEK": 4.93925,
    "out:Running cost/(Apt SEK y)": 36523,
    "out:Running Cost over RSP/MSEK": 8.302,
    "out:LCP/MSEK": -1.553,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214478,
    "out:DH kr savings": 117041,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 94.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.5,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 4.28,
    "out:Total CO2 (tons)": 13.44,
    "out:Klimatpaverkan": -186.25,
    "out:Initial Cost/MSEK": 5.01775,
    "out:Running cost/(Apt SEK y)": 35386,
    "out:Running Cost over RSP/MSEK": 8.044,
    "out:LCP/MSEK": -1.373,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225897,
    "out:DH kr savings": 128460,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 142.9,
    "out:Primary Energy": 107.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 47.82,
    "out:Total CO2/m2": 63.19,
    "out:Total CO2 (tons)": 198.23,
    "out:Klimatpaverkan": -1.46,
    "out:Initial Cost/MSEK": 3.551375,
    "out:Running cost/(Apt SEK y)": 39030,
    "out:Running Cost over RSP/MSEK": 8.85,
    "out:LCP/MSEK": -0.712,
    "out:ROI% old": 11.42,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 125605,
    "out:DH kr savings": 125605,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.38,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 61.75,
    "out:Total CO2 (tons)": 193.72,
    "out:Klimatpaverkan": -5.97,
    "out:Initial Cost/MSEK": 3.629875,
    "out:Running cost/(Apt SEK y)": 37893,
    "out:Running Cost over RSP/MSEK": 8.592,
    "out:LCP/MSEK": -0.533,
    "out:ROI% old": 12.19,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137024,
    "out:DH kr savings": 137024,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 137.9,
    "out:Primary Energy": 105.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.79,
    "out:CO2 Operational/m2": 46.02,
    "out:Total CO2/m2": 61.81,
    "out:Total CO2 (tons)": 193.9,
    "out:Klimatpaverkan": -5.79,
    "out:Initial Cost/MSEK": 3.71075,
    "out:Running cost/(Apt SEK y)": 37608,
    "out:Running Cost over RSP/MSEK": 8.527,
    "out:LCP/MSEK": -0.549,
    "out:ROI% old": 12.17,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139879,
    "out:DH kr savings": 139879,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 133.9,
    "out:Primary Energy": 102.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.79,
    "out:CO2 Operational/m2": 44.58,
    "out:Total CO2/m2": 60.37,
    "out:Total CO2 (tons)": 189.39,
    "out:Klimatpaverkan": -10.3,
    "out:Initial Cost/MSEK": 3.78925,
    "out:Running cost/(Apt SEK y)": 36471,
    "out:Running Cost over RSP/MSEK": 8.269,
    "out:LCP/MSEK": -0.37,
    "out:ROI% old": 12.89,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151298,
    "out:DH kr savings": 151298,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 137.73,
    "out:Primary Energy": 98.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.73,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 44.55,
    "out:Total CO2 (tons)": 139.75,
    "out:Klimatpaverkan": -59.94,
    "out:Initial Cost/MSEK": 4.203875,
    "out:Running cost/(Apt SEK y)": 37251,
    "out:Running Cost over RSP/MSEK": 8.457,
    "out:LCP/MSEK": -0.972,
    "out:ROI% old": 10.98,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177205,
    "out:DH kr savings": 125605,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 95.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.73,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 43.11,
    "out:Total CO2 (tons)": 135.24,
    "out:Klimatpaverkan": -64.45,
    "out:Initial Cost/MSEK": 4.282375,
    "out:Running cost/(Apt SEK y)": 36113,
    "out:Running Cost over RSP/MSEK": 8.199,
    "out:LCP/MSEK": -0.793,
    "out:ROI% old": 11.64,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188624,
    "out:DH kr savings": 137024,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 132.73,
    "out:Primary Energy": 96.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.15,
    "out:CO2 Operational/m2": 15.02,
    "out:Total CO2/m2": 43.17,
    "out:Total CO2 (tons)": 135.42,
    "out:Klimatpaverkan": -64.27,
    "out:Initial Cost/MSEK": 4.36325,
    "out:Running cost/(Apt SEK y)": 35829,
    "out:Running Cost over RSP/MSEK": 8.134,
    "out:LCP/MSEK": -0.809,
    "out:ROI% old": 11.64,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191479,
    "out:DH kr savings": 139879,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 128.73,
    "out:Primary Energy": 93.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.15,
    "out:CO2 Operational/m2": 13.58,
    "out:Total CO2/m2": 41.73,
    "out:Total CO2 (tons)": 130.91,
    "out:Klimatpaverkan": -68.78,
    "out:Initial Cost/MSEK": 4.44175,
    "out:Running cost/(Apt SEK y)": 34692,
    "out:Running Cost over RSP/MSEK": 7.876,
    "out:LCP/MSEK": -0.629,
    "out:ROI% old": 12.26,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202898,
    "out:DH kr savings": 151298,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 142.9,
    "out:Total Energy Use Post PV": 136.88,
    "out:Primary Energy": 96.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.08,
    "out:CO2 Operational/m2": -35.86,
    "out:Total CO2/m2": 4.22,
    "out:Total CO2 (tons)": 13.25,
    "out:Klimatpaverkan": -186.44,
    "out:Initial Cost/MSEK": 4.8565,
    "out:Running cost/(Apt SEK y)": 35670,
    "out:Running Cost over RSP/MSEK": 8.109,
    "out:LCP/MSEK": -1.276,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 460,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223042,
    "out:DH kr savings": 125605,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.08,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 2.78,
    "out:Total CO2 (tons)": 8.73,
    "out:Klimatpaverkan": -190.96,
    "out:Initial Cost/MSEK": 4.934875,
    "out:Running cost/(Apt SEK y)": 34533,
    "out:Running Cost over RSP/MSEK": 7.85,
    "out:LCP/MSEK": -1.097,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234461,
    "out:DH kr savings": 137024,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 131.88,
    "out:Primary Energy": 94.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.5,
    "out:CO2 Operational/m2": -37.66,
    "out:Total CO2/m2": 2.84,
    "out:Total CO2 (tons)": 8.92,
    "out:Klimatpaverkan": -190.77,
    "out:Initial Cost/MSEK": 5.015875,
    "out:Running cost/(Apt SEK y)": 34248,
    "out:Running Cost over RSP/MSEK": 7.786,
    "out:LCP/MSEK": -1.113,
    "out:ROI% old": 11.12,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237315,
    "out:DH kr savings": 139879,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 290381,
    "out:% savings (space heating)": 27.61,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 127.88,
    "out:Primary Energy": 91.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.5,
    "out:CO2 Operational/m2": -39.1,
    "out:Total CO2/m2": 1.4,
    "out:Total CO2 (tons)": 4.4,
    "out:Klimatpaverkan": -195.29,
    "out:Initial Cost/MSEK": 5.09425,
    "out:Running cost/(Apt SEK y)": 33111,
    "out:Running Cost over RSP/MSEK": 7.528,
    "out:LCP/MSEK": -0.933,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 534,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248734,
    "out:DH kr savings": 151298,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 278038,
    "out:% savings (space heating)": 30.68,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.26,
    "out:Electricity (inc PV)": 41.9,
    "out:Total Energy Use Pre PV": 94.26,
    "out:Total Energy Use Post PV": 91.9,
    "out:Primary Energy": 102.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.18,
    "out:CO2 Operational/m2": 23.84,
    "out:Total CO2/m2": 43.03,
    "out:Total CO2 (tons)": 134.98,
    "out:Klimatpaverkan": -64.71,
    "out:Initial Cost/MSEK": 6.19725,
    "out:Running cost/(Apt SEK y)": 22355,
    "out:Running Cost over RSP/MSEK": 5.051,
    "out:LCP/MSEK": 0.441,
    "out:ROI% old": 15.3,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 3098.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 379577,
    "out:EL kWh savings": -81571,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206745,
    "out:DH kr savings": 345415,
    "out:El kr savings": -138670,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 157020,
    "out:% savings (space heating)": 60.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.35,
    "out:Etvv": 0,
    "out:Ef": 41.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 41.78,
    "out:Total Energy Use Pre PV": 90.18,
    "out:Total Energy Use Post PV": 87.78,
    "out:Primary Energy": 100.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.18,
    "out:CO2 Operational/m2": 22.39,
    "out:Total CO2/m2": 41.58,
    "out:Total CO2 (tons)": 130.43,
    "out:Klimatpaverkan": -69.26,
    "out:Initial Cost/MSEK": 6.275625,
    "out:Running cost/(Apt SEK y)": 21203,
    "out:Running Cost over RSP/MSEK": 4.79,
    "out:LCP/MSEK": 0.624,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 3092.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -81172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218841,
    "out:DH kr savings": 356834,
    "out:El kr savings": -137992,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 145506,
    "out:% savings (space heating)": 63.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.83,
    "out:Etvv": 0,
    "out:Ef": 41.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 41.39,
    "out:Total Energy Use Pre PV": 89.47,
    "out:Total Energy Use Post PV": 87.39,
    "out:Primary Energy": 100.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.6,
    "out:CO2 Operational/m2": 22.3,
    "out:Total CO2/m2": 41.9,
    "out:Total CO2 (tons)": 131.44,
    "out:Klimatpaverkan": -68.25,
    "out:Initial Cost/MSEK": 6.356625,
    "out:Running cost/(Apt SEK y)": 21071,
    "out:Running Cost over RSP/MSEK": 4.76,
    "out:LCP/MSEK": 0.572,
    "out:ROI% old": 15.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 3042.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -79952,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220915,
    "out:DH kr savings": 356834,
    "out:El kr savings": -135918,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 144817,
    "out:% savings (space heating)": 63.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.3,
    "out:Etvv": 0,
    "out:Ef": 41.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 41.25,
    "out:Total Energy Use Pre PV": 86.37,
    "out:Total Energy Use Post PV": 84.25,
    "out:Primary Energy": 97.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.6,
    "out:CO2 Operational/m2": 21.2,
    "out:Total CO2/m2": 40.81,
    "out:Total CO2 (tons)": 128.02,
    "out:Klimatpaverkan": -71.67,
    "out:Initial Cost/MSEK": 6.435,
    "out:Running cost/(Apt SEK y)": 20200,
    "out:Running Cost over RSP/MSEK": 4.562,
    "out:LCP/MSEK": 0.691,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3035.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -79520,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230213,
    "out:DH kr savings": 365398,
    "out:El kr savings": -135185,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 133458,
    "out:% savings (space heating)": 66.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.82,
    "out:Etvv": 0,
    "out:Ef": 41.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.26,
    "out:Electricity (inc PV)": 32.51,
    "out:Total Energy Use Pre PV": 94.26,
    "out:Total Energy Use Post PV": 82.51,
    "out:Primary Energy": 86.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.54,
    "out:CO2 Operational/m2": 15.03,
    "out:Total CO2/m2": 46.57,
    "out:Total CO2 (tons)": 146.08,
    "out:Klimatpaverkan": -53.61,
    "out:Initial Cost/MSEK": 6.84975,
    "out:Running cost/(Apt SEK y)": 20371,
    "out:Running Cost over RSP/MSEK": 4.613,
    "out:LCP/MSEK": 0.226,
    "out:ROI% old": 14.76,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2450.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4863.87,
    "out:PV (% sold (El))": 13.87,
    "out:PV (kWh self-consumed)": 30198.84,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 379577,
    "out:EL kWh savings": -52121,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263011,
    "out:DH kr savings": 345415,
    "out:El kr savings": -88605,
    "out:El kr sold": 6201,
    "out:El kr saved": 51338,
    "out:El kr return": 57539,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 157020,
    "out:% savings (space heating)": 60.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.35,
    "out:Etvv": 0,
    "out:Ef": 32.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 32.4,
    "out:Total Energy Use Pre PV": 90.18,
    "out:Total Energy Use Post PV": 78.4,
    "out:Primary Energy": 83.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.54,
    "out:CO2 Operational/m2": 13.54,
    "out:Total CO2/m2": 45.08,
    "out:Total CO2 (tons)": 141.41,
    "out:Klimatpaverkan": -58.28,
    "out:Initial Cost/MSEK": 6.92825,
    "out:Running cost/(Apt SEK y)": 19219,
    "out:Running Cost over RSP/MSEK": 4.352,
    "out:LCP/MSEK": 0.409,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2445.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 937,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4888.29,
    "out:PV (% sold (El))": 13.94,
    "out:PV (kWh self-consumed)": 30174.41,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -51764,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275068,
    "out:DH kr savings": 356834,
    "out:El kr savings": -87999,
    "out:El kr sold": 6233,
    "out:El kr saved": 51297,
    "out:El kr return": 57529,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 145506,
    "out:% savings (space heating)": 63.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.83,
    "out:Etvv": 0,
    "out:Ef": 32.4,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 32.05,
    "out:Total Energy Use Pre PV": 89.47,
    "out:Total Energy Use Post PV": 78.05,
    "out:Primary Energy": 83.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.96,
    "out:CO2 Operational/m2": 13.1,
    "out:Total CO2/m2": 45.06,
    "out:Total CO2 (tons)": 141.34,
    "out:Klimatpaverkan": -58.35,
    "out:Initial Cost/MSEK": 7.009125,
    "out:Running cost/(Apt SEK y)": 19090,
    "out:Running Cost over RSP/MSEK": 4.323,
    "out:LCP/MSEK": 0.357,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2399.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 941,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5111.23,
    "out:PV (% sold (El))": 14.58,
    "out:PV (kWh self-consumed)": 29951.48,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -50673,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277206,
    "out:DH kr savings": 356834,
    "out:El kr savings": -86145,
    "out:El kr sold": 6517,
    "out:El kr saved": 50918,
    "out:El kr return": 57434,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 144817,
    "out:% savings (space heating)": 63.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.3,
    "out:Etvv": 0,
    "out:Ef": 32.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 31.93,
    "out:Total Energy Use Pre PV": 86.37,
    "out:Total Energy Use Post PV": 74.93,
    "out:Primary Energy": 81.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.96,
    "out:CO2 Operational/m2": 11.96,
    "out:Total CO2/m2": 43.92,
    "out:Total CO2 (tons)": 137.76,
    "out:Klimatpaverkan": -61.93,
    "out:Initial Cost/MSEK": 7.087625,
    "out:Running cost/(Apt SEK y)": 18220,
    "out:Running Cost over RSP/MSEK": 4.125,
    "out:LCP/MSEK": 0.476,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2393.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 966,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5141.1,
    "out:PV (% sold (El))": 14.66,
    "out:PV (kWh self-consumed)": 29921.61,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -50288,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286463,
    "out:DH kr savings": 365398,
    "out:El kr savings": -85490,
    "out:El kr sold": 6555,
    "out:El kr saved": 50867,
    "out:El kr return": 57422,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 133458,
    "out:% savings (space heating)": 66.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.82,
    "out:Etvv": 0,
    "out:Ef": 31.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.26,
    "out:Electricity (inc PV)": 29.39,
    "out:Total Energy Use Pre PV": 94.26,
    "out:Total Energy Use Post PV": 79.39,
    "out:Primary Energy": 80.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.89,
    "out:CO2 Operational/m2": -24.99,
    "out:Total CO2/m2": 18.91,
    "out:Total CO2 (tons)": 59.31,
    "out:Klimatpaverkan": -140.38,
    "out:Initial Cost/MSEK": 7.50225,
    "out:Running cost/(Apt SEK y)": 18675,
    "out:Running Cost over RSP/MSEK": 4.239,
    "out:LCP/MSEK": -0.052,
    "out:ROI% old": 14.19,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2211.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 953,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29423.27,
    "out:PV (% sold (El))": 41.96,
    "out:PV (kWh self-consumed)": 40702.15,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 379577,
    "out:EL kWh savings": -42310,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311002,
    "out:DH kr savings": 345415,
    "out:El kr savings": -71928,
    "out:El kr sold": 37515,
    "out:El kr saved": 69194,
    "out:El kr return": 106708,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 157020,
    "out:% savings (space heating)": 60.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 39.35,
    "out:Etvv": 0,
    "out:Ef": 29.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.18,
    "out:Electricity (inc PV)": 29.29,
    "out:Total Energy Use Pre PV": 90.18,
    "out:Total Energy Use Post PV": 75.29,
    "out:Primary Energy": 77.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.89,
    "out:CO2 Operational/m2": -26.51,
    "out:Total CO2/m2": 17.38,
    "out:Total CO2 (tons)": 54.51,
    "out:Klimatpaverkan": -145.18,
    "out:Initial Cost/MSEK": 7.58075,
    "out:Running cost/(Apt SEK y)": 17524,
    "out:Running Cost over RSP/MSEK": 3.977,
    "out:LCP/MSEK": 0.131,
    "out:ROI% old": 14.53,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2207.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29471.34,
    "out:PV (% sold (El))": 42.03,
    "out:PV (kWh self-consumed)": 40654.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -41991,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323026,
    "out:DH kr savings": 356834,
    "out:El kr savings": -71384,
    "out:El kr sold": 37576,
    "out:El kr saved": 69112,
    "out:El kr return": 106688,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 145506,
    "out:% savings (space heating)": 63.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.83,
    "out:Etvv": 0,
    "out:Ef": 29.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.47,
    "out:Electricity (inc PV)": 28.97,
    "out:Total Energy Use Pre PV": 89.47,
    "out:Total Energy Use Post PV": 74.97,
    "out:Primary Energy": 78.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.31,
    "out:CO2 Operational/m2": -27.3,
    "out:Total CO2/m2": 17.01,
    "out:Total CO2 (tons)": 53.36,
    "out:Klimatpaverkan": -146.33,
    "out:Initial Cost/MSEK": 7.661625,
    "out:Running cost/(Apt SEK y)": 17398,
    "out:Running Cost over RSP/MSEK": 3.949,
    "out:LCP/MSEK": 0.078,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2166.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 990,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29905.71,
    "out:PV (% sold (El))": 42.65,
    "out:PV (kWh self-consumed)": 40219.71,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392125,
    "out:EL kWh savings": -41013,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325242,
    "out:DH kr savings": 356834,
    "out:El kr savings": -69722,
    "out:El kr sold": 38130,
    "out:El kr saved": 68374,
    "out:El kr return": 106503,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 144817,
    "out:% savings (space heating)": 63.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 37.3,
    "out:Etvv": 0,
    "out:Ef": 28.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.37,
    "out:Electricity (inc PV)": 28.86,
    "out:Total Energy Use Pre PV": 86.37,
    "out:Total Energy Use Post PV": 71.86,
    "out:Primary Energy": 75.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.31,
    "out:CO2 Operational/m2": -28.49,
    "out:Total CO2/m2": 15.83,
    "out:Total CO2 (tons)": 49.64,
    "out:Klimatpaverkan": -150.05,
    "out:Initial Cost/MSEK": 7.740125,
    "out:Running cost/(Apt SEK y)": 16529,
    "out:Running Cost over RSP/MSEK": 3.752,
    "out:LCP/MSEK": 0.197,
    "out:ROI% old": 14.65,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2160.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29963.48,
    "out:PV (% sold (El))": 42.73,
    "out:PV (kWh self-consumed)": 40161.94,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -40667,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334467,
    "out:DH kr savings": 365398,
    "out:El kr savings": -69134,
    "out:El kr sold": 38203,
    "out:El kr saved": 68275,
    "out:El kr return": 106479,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 133458,
    "out:% savings (space heating)": 66.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.82,
    "out:Etvv": 0,
    "out:Ef": 28.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 41.21,
    "out:Total Energy Use Pre PV": 88.19,
    "out:Total Energy Use Post PV": 86.21,
    "out:Primary Energy": 99.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.18,
    "out:CO2 Operational/m2": 21.9,
    "out:Total CO2/m2": 41.09,
    "out:Total CO2 (tons)": 128.88,
    "out:Klimatpaverkan": -70.81,
    "out:Initial Cost/MSEK": 6.27375,
    "out:Running cost/(Apt SEK y)": 20736,
    "out:Running Cost over RSP/MSEK": 4.684,
    "out:LCP/MSEK": 0.731,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 3023.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 893,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -79404,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224701,
    "out:DH kr savings": 359688,
    "out:El kr savings": -134988,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 140839,
    "out:% savings (space heating)": 64.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.62,
    "out:Etvv": 0,
    "out:Ef": 41.21,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.09,
    "out:Electricity (inc PV)": 41.07,
    "out:Total Energy Use Pre PV": 84.09,
    "out:Total Energy Use Post PV": 82.07,
    "out:Primary Energy": 97.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.18,
    "out:CO2 Operational/m2": 20.45,
    "out:Total CO2/m2": 39.63,
    "out:Total CO2 (tons)": 124.33,
    "out:Klimatpaverkan": -75.36,
    "out:Initial Cost/MSEK": 6.35225,
    "out:Running cost/(Apt SEK y)": 19580,
    "out:Running Cost over RSP/MSEK": 4.422,
    "out:LCP/MSEK": 0.915,
    "out:ROI% old": 16.34,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 3016.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 927,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -78959,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236876,
    "out:DH kr savings": 371107,
    "out:El kr savings": -134231,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 129535,
    "out:% savings (space heating)": 67.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.16,
    "out:Etvv": 0,
    "out:Ef": 41.07,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 40.79,
    "out:Total Energy Use Pre PV": 84.51,
    "out:Total Energy Use Post PV": 82.79,
    "out:Primary Energy": 97.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.6,
    "out:CO2 Operational/m2": 20.73,
    "out:Total CO2/m2": 40.34,
    "out:Total CO2 (tons)": 126.53,
    "out:Klimatpaverkan": -73.16,
    "out:Initial Cost/MSEK": 6.433125,
    "out:Running cost/(Apt SEK y)": 19759,
    "out:Running Cost over RSP/MSEK": 4.463,
    "out:LCP/MSEK": 0.793,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2976,
    "out:Return %": 14,
    "out:Return/kSEK/y": 922,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -78073,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235529,
    "out:DH kr savings": 368252,
    "out:El kr savings": -132723,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131459,
    "out:% savings (space heating)": 67.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.03,
    "out:Etvv": 0,
    "out:Ef": 40.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.4,
    "out:Electricity (inc PV)": 40.63,
    "out:Total Energy Use Pre PV": 80.4,
    "out:Total Energy Use Post PV": 78.63,
    "out:Primary Energy": 95.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.6,
    "out:CO2 Operational/m2": 19.28,
    "out:Total CO2/m2": 38.88,
    "out:Total CO2 (tons)": 121.97,
    "out:Klimatpaverkan": -77.72,
    "out:Initial Cost/MSEK": 6.511625,
    "out:Running cost/(Apt SEK y)": 18601,
    "out:Running Cost over RSP/MSEK": 4.2,
    "out:LCP/MSEK": 0.977,
    "out:ROI% old": 16.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2968.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -77587,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247773,
    "out:DH kr savings": 379671,
    "out:El kr savings": -131898,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120281,
    "out:% savings (space heating)": 70.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 31.6,
    "out:Etvv": 0,
    "out:Ef": 40.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 31.9,
    "out:Total Energy Use Pre PV": 88.19,
    "out:Total Energy Use Post PV": 76.9,
    "out:Primary Energy": 83.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.54,
    "out:CO2 Operational/m2": 12.57,
    "out:Total CO2/m2": 44.1,
    "out:Total CO2 (tons)": 138.35,
    "out:Klimatpaverkan": -61.34,
    "out:Initial Cost/MSEK": 6.92625,
    "out:Running cost/(Apt SEK y)": 18757,
    "out:Running Cost over RSP/MSEK": 4.247,
    "out:LCP/MSEK": 0.515,
    "out:ROI% old": 15.35,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2382,
    "out:Return %": 14,
    "out:Return/kSEK/y": 951,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5197.74,
    "out:PV (% sold (El))": 14.82,
    "out:PV (kWh self-consumed)": 29864.97,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -50185,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281002,
    "out:DH kr savings": 359688,
    "out:El kr savings": -85314,
    "out:El kr sold": 6627,
    "out:El kr saved": 50770,
    "out:El kr return": 57398,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 140839,
    "out:% savings (space heating)": 64.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.62,
    "out:Etvv": 0,
    "out:Ef": 31.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.09,
    "out:Electricity (inc PV)": 31.77,
    "out:Total Energy Use Pre PV": 84.09,
    "out:Total Energy Use Post PV": 72.77,
    "out:Primary Energy": 80.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.54,
    "out:CO2 Operational/m2": 11.06,
    "out:Total CO2/m2": 42.6,
    "out:Total CO2 (tons)": 133.64,
    "out:Klimatpaverkan": -66.05,
    "out:Initial Cost/MSEK": 7.00475,
    "out:Running cost/(Apt SEK y)": 17601,
    "out:Running Cost over RSP/MSEK": 3.985,
    "out:LCP/MSEK": 0.699,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2375.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5229.49,
    "out:PV (% sold (El))": 14.91,
    "out:PV (kWh self-consumed)": 29833.22,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -49788,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293136,
    "out:DH kr savings": 371107,
    "out:El kr savings": -84639,
    "out:El kr sold": 6668,
    "out:El kr saved": 50716,
    "out:El kr return": 57384,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 129535,
    "out:% savings (space heating)": 67.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.16,
    "out:Etvv": 0,
    "out:Ef": 31.77,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 31.52,
    "out:Total Energy Use Pre PV": 84.51,
    "out:Total Energy Use Post PV": 73.52,
    "out:Primary Energy": 81.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.96,
    "out:CO2 Operational/m2": 11.06,
    "out:Total CO2/m2": 43.01,
    "out:Total CO2 (tons)": 134.94,
    "out:Klimatpaverkan": -64.75,
    "out:Initial Cost/MSEK": 7.085625,
    "out:Running cost/(Apt SEK y)": 17783,
    "out:Running Cost over RSP/MSEK": 4.027,
    "out:LCP/MSEK": 0.577,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2339,
    "out:Return %": 14,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5413.47,
    "out:PV (% sold (El))": 15.44,
    "out:PV (kWh self-consumed)": 29649.23,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -48997,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291860,
    "out:DH kr savings": 368252,
    "out:El kr savings": -83295,
    "out:El kr sold": 6902,
    "out:El kr saved": 50404,
    "out:El kr return": 57306,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131459,
    "out:% savings (space heating)": 67.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.03,
    "out:Etvv": 0,
    "out:Ef": 31.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.4,
    "out:Electricity (inc PV)": 31.38,
    "out:Total Energy Use Pre PV": 80.4,
    "out:Total Energy Use Post PV": 69.38,
    "out:Primary Energy": 78.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.96,
    "out:CO2 Operational/m2": 9.54,
    "out:Total CO2/m2": 41.5,
    "out:Total CO2 (tons)": 130.19,
    "out:Klimatpaverkan": -69.5,
    "out:Initial Cost/MSEK": 7.164125,
    "out:Running cost/(Apt SEK y)": 16625,
    "out:Running Cost over RSP/MSEK": 3.764,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 15.8,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2331.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1012,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5449.82,
    "out:PV (% sold (El))": 15.54,
    "out:PV (kWh self-consumed)": 29612.89,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -48565,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304059,
    "out:DH kr savings": 379671,
    "out:El kr savings": -82560,
    "out:El kr sold": 6949,
    "out:El kr saved": 50342,
    "out:El kr return": 57290,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120281,
    "out:% savings (space heating)": 70.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 31.6,
    "out:Etvv": 0,
    "out:Ef": 31.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.19,
    "out:Electricity (inc PV)": 28.83,
    "out:Total Energy Use Pre PV": 88.19,
    "out:Total Energy Use Post PV": 73.83,
    "out:Primary Energy": 77.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.89,
    "out:CO2 Operational/m2": -27.96,
    "out:Total CO2/m2": 15.93,
    "out:Total CO2 (tons)": 49.96,
    "out:Klimatpaverkan": -149.73,
    "out:Initial Cost/MSEK": 7.578875,
    "out:Running cost/(Apt SEK y)": 17066,
    "out:Running Cost over RSP/MSEK": 3.874,
    "out:LCP/MSEK": 0.236,
    "out:ROI% old": 14.73,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2150.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1000,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30072.96,
    "out:PV (% sold (El))": 42.88,
    "out:PV (kWh self-consumed)": 40052.46,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395262,
    "out:EL kWh savings": -40575,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329055,
    "out:DH kr savings": 359688,
    "out:El kr savings": -68977,
    "out:El kr sold": 38343,
    "out:El kr saved": 68089,
    "out:El kr return": 106432,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 140839,
    "out:% savings (space heating)": 64.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.62,
    "out:Etvv": 0,
    "out:Ef": 28.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.09,
    "out:Electricity (inc PV)": 28.72,
    "out:Total Energy Use Pre PV": 84.09,
    "out:Total Energy Use Post PV": 69.72,
    "out:Primary Energy": 74.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.89,
    "out:CO2 Operational/m2": -29.51,
    "out:Total CO2/m2": 14.38,
    "out:Total CO2 (tons)": 45.1,
    "out:Klimatpaverkan": -154.59,
    "out:Initial Cost/MSEK": 7.65725,
    "out:Running cost/(Apt SEK y)": 15911,
    "out:Running Cost over RSP/MSEK": 3.612,
    "out:LCP/MSEK": 0.42,
    "out:ROI% old": 15.07,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2144.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30134.21,
    "out:PV (% sold (El))": 42.97,
    "out:PV (kWh self-consumed)": 39991.21,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407810,
    "out:EL kWh savings": -40218,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341157,
    "out:DH kr savings": 371107,
    "out:El kr savings": -68371,
    "out:El kr sold": 38421,
    "out:El kr saved": 67985,
    "out:El kr return": 106406,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 129535,
    "out:% savings (space heating)": 67.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.16,
    "out:Etvv": 0,
    "out:Ef": 28.72,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 28.49,
    "out:Total Energy Use Pre PV": 84.51,
    "out:Total Energy Use Post PV": 70.49,
    "out:Primary Energy": 75.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.31,
    "out:CO2 Operational/m2": -29.79,
    "out:Total CO2/m2": 14.52,
    "out:Total CO2 (tons)": 45.54,
    "out:Klimatpaverkan": -154.15,
    "out:Initial Cost/MSEK": 7.73825,
    "out:Running cost/(Apt SEK y)": 16095,
    "out:Running Cost over RSP/MSEK": 3.654,
    "out:LCP/MSEK": 0.297,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2112.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1028,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30487.12,
    "out:PV (% sold (El))": 43.48,
    "out:PV (kWh self-consumed)": 39638.3,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -39509,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339958,
    "out:DH kr savings": 368252,
    "out:El kr savings": -67165,
    "out:El kr sold": 38871,
    "out:El kr saved": 67385,
    "out:El kr return": 106256,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131459,
    "out:% savings (space heating)": 67.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 35.03,
    "out:Etvv": 0,
    "out:Ef": 28.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.4,
    "out:Electricity (inc PV)": 28.37,
    "out:Total Energy Use Pre PV": 80.4,
    "out:Total Energy Use Post PV": 66.37,
    "out:Primary Energy": 73.19,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.31,
    "out:CO2 Operational/m2": -31.36,
    "out:Total CO2/m2": 12.95,
    "out:Total CO2 (tons)": 40.63,
    "out:Klimatpaverkan": -159.06,
    "out:Initial Cost/MSEK": 7.816625,
    "out:Running cost/(Apt SEK y)": 14938,
    "out:Running Cost over RSP/MSEK": 3.391,
    "out:LCP/MSEK": 0.481,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2105.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.2,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30556.48,
    "out:PV (% sold (El))": 43.57,
    "out:PV (kWh self-consumed)": 39568.93,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -39121,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352125,
    "out:DH kr savings": 379671,
    "out:El kr savings": -66506,
    "out:El kr sold": 38960,
    "out:El kr saved": 67267,
    "out:El kr return": 106227,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120281,
    "out:% savings (space heating)": 70.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 31.6,
    "out:Etvv": 0,
    "out:Ef": 28.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.24,
    "out:Electricity (inc PV)": 53.2,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 57.2,
    "out:Primary Energy": 97.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.83,
    "out:CO2 Operational/m2": 9.13,
    "out:Total CO2/m2": 28.96,
    "out:Total CO2 (tons)": 90.84,
    "out:Klimatpaverkan": -108.85,
    "out:Initial Cost/MSEK": 8.053625,
    "out:Running cost/(Apt SEK y)": 11847,
    "out:Running Cost over RSP/MSEK": 2.659,
    "out:LCP/MSEK": 0.976,
    "out:ROI% old": 16.02,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 4076.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1151,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -117017,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277800,
    "out:DH kr savings": 476730,
    "out:El kr savings": -198930,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12360,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.06,
    "out:Etvv": 0,
    "out:Ef": 53.2,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.37,
    "out:Electricity (inc PV)": 52.22,
    "out:Total Energy Use Pre PV": 60.37,
    "out:Total Energy Use Post PV": 55.22,
    "out:Primary Energy": 94.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.83,
    "out:CO2 Operational/m2": 8.65,
    "out:Total CO2/m2": 28.48,
    "out:Total CO2 (tons)": 89.35,
    "out:Klimatpaverkan": -110.34,
    "out:Initial Cost/MSEK": 8.132,
    "out:Running cost/(Apt SEK y)": 11403,
    "out:Running Cost over RSP/MSEK": 2.559,
    "out:LCP/MSEK": 0.998,
    "out:ROI% old": 16.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 4015.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -113939,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285889,
    "out:DH kr savings": 479585,
    "out:El kr savings": -193696,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9016,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 52.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 52.15,
    "out:Total Energy Use Pre PV": 59.49,
    "out:Total Energy Use Post PV": 55.15,
    "out:Primary Energy": 95.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 8.54,
    "out:Total CO2/m2": 28.79,
    "out:Total CO2 (tons)": 90.3,
    "out:Klimatpaverkan": -109.39,
    "out:Initial Cost/MSEK": 8.213,
    "out:Running cost/(Apt SEK y)": 11242,
    "out:Running Cost over RSP/MSEK": 2.523,
    "out:LCP/MSEK": 0.953,
    "out:ROI% old": 15.94,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3954.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1169,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -113723,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286256,
    "out:DH kr savings": 479585,
    "out:El kr savings": -193329,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9936,
    "out:% savings (space heating)": 97.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.77,
    "out:Etvv": 0,
    "out:Ef": 52.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.58,
    "out:Electricity (inc PV)": 51.15,
    "out:Total Energy Use Pre PV": 57.58,
    "out:Total Energy Use Post PV": 53.15,
    "out:Primary Energy": 92.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 8.06,
    "out:Total CO2/m2": 28.3,
    "out:Total CO2 (tons)": 88.79,
    "out:Klimatpaverkan": -110.9,
    "out:Initial Cost/MSEK": 8.291375,
    "out:Running cost/(Apt SEK y)": 10789,
    "out:Running Cost over RSP/MSEK": 2.42,
    "out:LCP/MSEK": 0.977,
    "out:ROI% old": 15.97,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3890.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1182,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -110579,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294454,
    "out:DH kr savings": 482439,
    "out:El kr savings": -187985,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7066,
    "out:% savings (space heating)": 98.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.14,
    "out:Etvv": 0,
    "out:Ef": 51.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.24,
    "out:Electricity (inc PV)": 42.86,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 46.86,
    "out:Primary Energy": 78.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.18,
    "out:CO2 Operational/m2": 5.21,
    "out:Total CO2/m2": 37.4,
    "out:Total CO2 (tons)": 117.31,
    "out:Klimatpaverkan": -82.38,
    "out:Initial Cost/MSEK": 8.706125,
    "out:Running cost/(Apt SEK y)": 9816,
    "out:Running Cost over RSP/MSEK": 2.211,
    "out:LCP/MSEK": 0.771,
    "out:ROI% old": 15.55,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3369.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1210,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1702.04,
    "out:PV (% sold (El))": 4.85,
    "out:PV (kWh self-consumed)": 33360.67,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -84571,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335130,
    "out:DH kr savings": 476730,
    "out:El kr savings": -143770,
    "out:El kr sold": 2170,
    "out:El kr saved": 56713,
    "out:El kr return": 58883,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12360,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.06,
    "out:Etvv": 0,
    "out:Ef": 42.86,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.37,
    "out:Electricity (inc PV)": 41.94,
    "out:Total Energy Use Pre PV": 60.37,
    "out:Total Energy Use Post PV": 44.94,
    "out:Primary Energy": 76.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.18,
    "out:CO2 Operational/m2": 4.52,
    "out:Total CO2/m2": 36.7,
    "out:Total CO2 (tons)": 115.14,
    "out:Klimatpaverkan": -84.55,
    "out:Initial Cost/MSEK": 8.784625,
    "out:Running cost/(Apt SEK y)": 9374,
    "out:Running Cost over RSP/MSEK": 2.111,
    "out:LCP/MSEK": 0.793,
    "out:ROI% old": 15.58,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3310.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1223,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1843.47,
    "out:PV (% sold (El))": 5.26,
    "out:PV (kWh self-consumed)": 33219.24,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -81699,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343047,
    "out:DH kr savings": 479585,
    "out:El kr savings": -138888,
    "out:El kr sold": 2350,
    "out:El kr saved": 56473,
    "out:El kr return": 58823,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9016,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 41.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 41.88,
    "out:Total Energy Use Pre PV": 59.49,
    "out:Total Energy Use Post PV": 44.88,
    "out:Primary Energy": 76.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 4.18,
    "out:Total CO2/m2": 36.78,
    "out:Total CO2 (tons)": 115.38,
    "out:Klimatpaverkan": -84.31,
    "out:Initial Cost/MSEK": 8.8655,
    "out:Running cost/(Apt SEK y)": 9216,
    "out:Running Cost over RSP/MSEK": 2.076,
    "out:LCP/MSEK": 0.748,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3252.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1227,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1992.21,
    "out:PV (% sold (El))": 5.68,
    "out:PV (kWh self-consumed)": 33070.5,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -81498,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 343579,
    "out:DH kr savings": 479585,
    "out:El kr savings": -138546,
    "out:El kr sold": 2540,
    "out:El kr saved": 56220,
    "out:El kr return": 58760,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9936,
    "out:% savings (space heating)": 97.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.77,
    "out:Etvv": 0,
    "out:Ef": 41.88,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.58,
    "out:Electricity (inc PV)": 40.95,
    "out:Total Energy Use Pre PV": 57.58,
    "out:Total Energy Use Post PV": 42.95,
    "out:Primary Energy": 74.51,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 3.45,
    "out:Total CO2/m2": 36.05,
    "out:Total CO2 (tons)": 113.08,
    "out:Klimatpaverkan": -86.61,
    "out:Initial Cost/MSEK": 8.944,
    "out:Running cost/(Apt SEK y)": 8765,
    "out:Running Cost over RSP/MSEK": 1.974,
    "out:LCP/MSEK": 0.771,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3191.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1240,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2155.49,
    "out:PV (% sold (El))": 6.15,
    "out:PV (kWh self-consumed)": 32907.22,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -78575,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351610,
    "out:DH kr savings": 482439,
    "out:El kr savings": -133578,
    "out:El kr sold": 2748,
    "out:El kr saved": 55942,
    "out:El kr return": 58691,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7066,
    "out:% savings (space heating)": 98.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.14,
    "out:Etvv": 0,
    "out:Ef": 40.95,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.24,
    "out:Electricity (inc PV)": 38.62,
    "out:Total Energy Use Pre PV": 62.24,
    "out:Total Energy Use Post PV": 42.62,
    "out:Primary Energy": 70.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.53,
    "out:CO2 Operational/m2": -27.52,
    "out:Total CO2/m2": 17.02,
    "out:Total CO2 (tons)": 53.39,
    "out:Klimatpaverkan": -146.3,
    "out:Initial Cost/MSEK": 9.358625,
    "out:Running cost/(Apt SEK y)": 8055,
    "out:Running Cost over RSP/MSEK": 1.822,
    "out:LCP/MSEK": 0.508,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3027.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1261,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.04,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 21775.03,
    "out:PV (% sold (El))": 31.05,
    "out:PV (kWh self-consumed)": 48350.38,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 523879,
    "out:EL kWh savings": -71263,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 383346,
    "out:DH kr savings": 476730,
    "out:El kr savings": -121147,
    "out:El kr sold": 27763,
    "out:El kr saved": 82196,
    "out:El kr return": 109959,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 12360,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 2.06,
    "out:Etvv": 0,
    "out:Ef": 38.62,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.37,
    "out:Electricity (inc PV)": 37.8,
    "out:Total Energy Use Pre PV": 60.37,
    "out:Total Energy Use Post PV": 40.8,
    "out:Primary Energy": 69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.53,
    "out:CO2 Operational/m2": -28.69,
    "out:Total CO2/m2": 15.85,
    "out:Total CO2 (tons)": 49.71,
    "out:Klimatpaverkan": -149.98,
    "out:Initial Cost/MSEK": 9.437125,
    "out:Running cost/(Apt SEK y)": 7617,
    "out:Running Cost over RSP/MSEK": 1.723,
    "out:LCP/MSEK": 0.529,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2975.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1274,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22213.75,
    "out:PV (% sold (El))": 31.68,
    "out:PV (kWh self-consumed)": 47911.66,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -68708,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391104,
    "out:DH kr savings": 479585,
    "out:El kr savings": -116803,
    "out:El kr sold": 28323,
    "out:El kr saved": 81450,
    "out:El kr return": 109772,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9016,
    "out:% savings (space heating)": 97.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.36,
    "out:Etvv": 0,
    "out:Ef": 37.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 37.75,
    "out:Total Energy Use Pre PV": 59.49,
    "out:Total Energy Use Post PV": 40.75,
    "out:Primary Energy": 69.18,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -29.51,
    "out:Total CO2/m2": 15.44,
    "out:Total CO2 (tons)": 48.44,
    "out:Klimatpaverkan": -151.25,
    "out:Initial Cost/MSEK": 9.518,
    "out:Running cost/(Apt SEK y)": 7463,
    "out:Running Cost over RSP/MSEK": 1.688,
    "out:LCP/MSEK": 0.482,
    "out:ROI% old": 15.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2923.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1278,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22658.07,
    "out:PV (% sold (El))": 32.31,
    "out:PV (kWh self-consumed)": 47467.35,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -68529,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 391974,
    "out:DH kr savings": 479585,
    "out:El kr savings": -116499,
    "out:El kr sold": 28889,
    "out:El kr saved": 80694,
    "out:El kr return": 109584,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9936,
    "out:% savings (space heating)": 97.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.77,
    "out:Etvv": 0,
    "out:Ef": 37.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.58,
    "out:Electricity (inc PV)": 36.92,
    "out:Total Energy Use Pre PV": 57.58,
    "out:Total Energy Use Post PV": 38.92,
    "out:Primary Energy": 67.25,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -30.74,
    "out:Total CO2/m2": 14.21,
    "out:Total CO2 (tons)": 44.58,
    "out:Klimatpaverkan": -155.11,
    "out:Initial Cost/MSEK": 9.5965,
    "out:Running cost/(Apt SEK y)": 7017,
    "out:Running Cost over RSP/MSEK": 1.588,
    "out:LCP/MSEK": 0.505,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2869.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1291,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23128.37,
    "out:PV (% sold (El))": 32.98,
    "out:PV (kWh self-consumed)": 46997.04,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -65928,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 399851,
    "out:DH kr savings": 482439,
    "out:El kr savings": -112077,
    "out:El kr sold": 29489,
    "out:El kr saved": 79895,
    "out:El kr return": 109384,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7066,
    "out:% savings (space heating)": 98.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.14,
    "out:Etvv": 0,
    "out:Ef": 36.92,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.9,
    "out:Electricity (inc PV)": 51.8,
    "out:Total Energy Use Pre PV": 58.9,
    "out:Total Energy Use Post PV": 54.8,
    "out:Primary Energy": 94.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.83,
    "out:CO2 Operational/m2": 8.46,
    "out:Total CO2/m2": 28.29,
    "out:Total CO2 (tons)": 88.74,
    "out:Klimatpaverkan": -110.95,
    "out:Initial Cost/MSEK": 8.130125,
    "out:Running cost/(Apt SEK y)": 11133,
    "out:Running Cost over RSP/MSEK": 2.498,
    "out:LCP/MSEK": 1.06,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3913.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1172,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -112620,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288131,
    "out:DH kr savings": 479585,
    "out:El kr savings": -191453,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9226,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.68,
    "out:Etvv": 0,
    "out:Ef": 51.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.98,
    "out:Electricity (inc PV)": 50.79,
    "out:Total Energy Use Pre PV": 56.98,
    "out:Total Energy Use Post PV": 52.79,
    "out:Primary Energy": 92.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.83,
    "out:CO2 Operational/m2": 7.98,
    "out:Total CO2/m2": 27.81,
    "out:Total CO2 (tons)": 87.23,
    "out:Klimatpaverkan": -112.46,
    "out:Initial Cost/MSEK": 8.208625,
    "out:Running cost/(Apt SEK y)": 10678,
    "out:Running Cost over RSP/MSEK": 2.396,
    "out:LCP/MSEK": 1.085,
    "out:ROI% old": 16.17,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3848.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1185,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -109453,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296369,
    "out:DH kr savings": 482439,
    "out:El kr savings": -186070,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6491,
    "out:% savings (space heating)": 98.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 50.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 50.95,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 52.95,
    "out:Primary Energy": 92.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 7.91,
    "out:Total CO2/m2": 28.16,
    "out:Total CO2 (tons)": 88.33,
    "out:Klimatpaverkan": -111.36,
    "out:Initial Cost/MSEK": 8.2895,
    "out:Running cost/(Apt SEK y)": 10586,
    "out:Running Cost over RSP/MSEK": 2.375,
    "out:LCP/MSEK": 1.024,
    "out:ROI% old": 16.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3813.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -109960,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295508,
    "out:DH kr savings": 482439,
    "out:El kr savings": -186931,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7662,
    "out:% savings (space heating)": 98.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 50.95,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.52,
    "out:Electricity (inc PV)": 49.93,
    "out:Total Energy Use Pre PV": 55.52,
    "out:Total Energy Use Post PV": 51.93,
    "out:Primary Energy": 90.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.25,
    "out:CO2 Operational/m2": 7.78,
    "out:Total CO2/m2": 28.03,
    "out:Total CO2 (tons)": 87.94,
    "out:Klimatpaverkan": -111.75,
    "out:Initial Cost/MSEK": 8.368,
    "out:Running cost/(Apt SEK y)": 10410,
    "out:Running Cost over RSP/MSEK": 2.335,
    "out:LCP/MSEK": 0.985,
    "out:ROI% old": 15.97,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3746.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1193,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -106737,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300986,
    "out:DH kr savings": 482439,
    "out:El kr savings": -181453,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5234,
    "out:% savings (space heating)": 98.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 49.93,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.9,
    "out:Electricity (inc PV)": 41.55,
    "out:Total Energy Use Pre PV": 58.9,
    "out:Total Energy Use Post PV": 44.55,
    "out:Primary Energy": 75.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.18,
    "out:CO2 Operational/m2": 3.94,
    "out:Total CO2/m2": 36.12,
    "out:Total CO2 (tons)": 113.31,
    "out:Klimatpaverkan": -86.38,
    "out:Initial Cost/MSEK": 8.782625,
    "out:Running cost/(Apt SEK y)": 9109,
    "out:Running Cost over RSP/MSEK": 2.052,
    "out:LCP/MSEK": 0.854,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3212.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1230,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2096.51,
    "out:PV (% sold (El))": 5.98,
    "out:PV (kWh self-consumed)": 32966.2,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -80471,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 345457,
    "out:DH kr savings": 479585,
    "out:El kr savings": -136801,
    "out:El kr sold": 2673,
    "out:El kr saved": 56043,
    "out:El kr return": 58716,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9226,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.68,
    "out:Etvv": 0,
    "out:Ef": 41.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.98,
    "out:Electricity (inc PV)": 40.61,
    "out:Total Energy Use Pre PV": 56.98,
    "out:Total Energy Use Post PV": 42.61,
    "out:Primary Energy": 73.86,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.18,
    "out:CO2 Operational/m2": 3.2,
    "out:Total CO2/m2": 35.38,
    "out:Total CO2 (tons)": 110.98,
    "out:Klimatpaverkan": -88.71,
    "out:Initial Cost/MSEK": 8.861125,
    "out:Running cost/(Apt SEK y)": 8656,
    "out:Running Cost over RSP/MSEK": 1.949,
    "out:LCP/MSEK": 0.878,
    "out:ROI% old": 15.7,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3150.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2267.11,
    "out:PV (% sold (El))": 6.47,
    "out:PV (kWh self-consumed)": 32795.6,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -77530,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 353529,
    "out:DH kr savings": 482439,
    "out:El kr savings": -131801,
    "out:El kr sold": 2891,
    "out:El kr saved": 55753,
    "out:El kr return": 58643,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6491,
    "out:% savings (space heating)": 98.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 40.61,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 40.76,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 42.76,
    "out:Primary Energy": 74.41,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 2.98,
    "out:Total CO2/m2": 35.58,
    "out:Total CO2 (tons)": 111.62,
    "out:Klimatpaverkan": -88.07,
    "out:Initial Cost/MSEK": 8.942,
    "out:Running cost/(Apt SEK y)": 8565,
    "out:Running Cost over RSP/MSEK": 1.929,
    "out:LCP/MSEK": 0.818,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3117.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1246,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2362.38,
    "out:PV (% sold (El))": 6.74,
    "out:PV (kWh self-consumed)": 32700.33,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -78000,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352851,
    "out:DH kr savings": 482439,
    "out:El kr savings": -132600,
    "out:El kr sold": 3012,
    "out:El kr saved": 55591,
    "out:El kr return": 58603,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7662,
    "out:% savings (space heating)": 98.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 40.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.52,
    "out:Electricity (inc PV)": 39.81,
    "out:Total Energy Use Pre PV": 55.52,
    "out:Total Energy Use Post PV": 41.81,
    "out:Primary Energy": 72.32,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 32.6,
    "out:CO2 Operational/m2": 2.56,
    "out:Total CO2/m2": 35.17,
    "out:Total CO2 (tons)": 110.31,
    "out:Klimatpaverkan": -89.38,
    "out:Initial Cost/MSEK": 9.0205,
    "out:Running cost/(Apt SEK y)": 8392,
    "out:Running Cost over RSP/MSEK": 1.89,
    "out:LCP/MSEK": 0.778,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3053.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1251,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2552.06,
    "out:PV (% sold (El))": 7.28,
    "out:PV (kWh self-consumed)": 32510.65,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -75015,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358167,
    "out:DH kr savings": 482439,
    "out:El kr savings": -127526,
    "out:El kr sold": 3254,
    "out:El kr saved": 55268,
    "out:El kr return": 58522,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5234,
    "out:% savings (space heating)": 98.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 39.81,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.9,
    "out:Electricity (inc PV)": 37.45,
    "out:Total Energy Use Pre PV": 58.9,
    "out:Total Energy Use Post PV": 40.45,
    "out:Primary Energy": 68.6,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.53,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 14.46,
    "out:Total CO2 (tons)": 45.36,
    "out:Klimatpaverkan": -154.33,
    "out:Initial Cost/MSEK": 9.43525,
    "out:Running cost/(Apt SEK y)": 7359,
    "out:Running Cost over RSP/MSEK": 1.665,
    "out:LCP/MSEK": 0.588,
    "out:ROI% old": 15.18,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2888.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1281,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.7,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22961.02,
    "out:PV (% sold (El))": 32.74,
    "out:PV (kWh self-consumed)": 47164.39,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -67615,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 393914,
    "out:DH kr savings": 479585,
    "out:El kr savings": -114946,
    "out:El kr sold": 29275,
    "out:El kr saved": 80179,
    "out:El kr return": 109455,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9226,
    "out:% savings (space heating)": 97.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.68,
    "out:Etvv": 0,
    "out:Ef": 37.45,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.98,
    "out:Electricity (inc PV)": 36.62,
    "out:Total Energy Use Pre PV": 56.98,
    "out:Total Energy Use Post PV": 38.62,
    "out:Primary Energy": 66.67,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.53,
    "out:CO2 Operational/m2": -31.32,
    "out:Total CO2/m2": 13.21,
    "out:Total CO2 (tons)": 41.45,
    "out:Klimatpaverkan": -158.24,
    "out:Initial Cost/MSEK": 9.513625,
    "out:Running cost/(Apt SEK y)": 6911,
    "out:Running Cost over RSP/MSEK": 1.564,
    "out:LCP/MSEK": 0.611,
    "out:ROI% old": 15.2,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2834.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1294,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23440.98,
    "out:PV (% sold (El))": 33.43,
    "out:PV (kWh self-consumed)": 46684.44,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -64998,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 401830,
    "out:DH kr savings": 482439,
    "out:El kr savings": -110497,
    "out:El kr sold": 29887,
    "out:El kr saved": 79364,
    "out:El kr return": 109251,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6491,
    "out:% savings (space heating)": 98.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.08,
    "out:Etvv": 0,
    "out:Ef": 36.62,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 36.75,
    "out:Total Energy Use Pre PV": 56.47,
    "out:Total Energy Use Post PV": 38.75,
    "out:Primary Energy": 67.19,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -31.81,
    "out:Total CO2/m2": 13.15,
    "out:Total CO2 (tons)": 41.25,
    "out:Klimatpaverkan": -158.44,
    "out:Initial Cost/MSEK": 9.594625,
    "out:Running cost/(Apt SEK y)": 6822,
    "out:Running Cost over RSP/MSEK": 1.544,
    "out:LCP/MSEK": 0.55,
    "out:ROI% old": 15.1,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2804.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1297,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23702.52,
    "out:PV (% sold (El))": 33.8,
    "out:PV (kWh self-consumed)": 46422.9,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -65416,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 401453,
    "out:DH kr savings": 482439,
    "out:El kr savings": -111207,
    "out:El kr sold": 30221,
    "out:El kr saved": 78919,
    "out:El kr return": 109140,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7662,
    "out:% savings (space heating)": 98.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.48,
    "out:Etvv": 0,
    "out:Ef": 36.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.52,
    "out:Electricity (inc PV)": 35.91,
    "out:Total Energy Use Pre PV": 55.52,
    "out:Total Energy Use Post PV": 37.91,
    "out:Primary Energy": 65.29,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.95,
    "out:CO2 Operational/m2": -32.73,
    "out:Total CO2/m2": 12.22,
    "out:Total CO2 (tons)": 38.34,
    "out:Klimatpaverkan": -161.35,
    "out:Initial Cost/MSEK": 9.673,
    "out:Running cost/(Apt SEK y)": 6654,
    "out:Running Cost over RSP/MSEK": 1.506,
    "out:LCP/MSEK": 0.51,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2748.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1302,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 24204.8,
    "out:PV (% sold (El))": 34.52,
    "out:PV (kWh self-consumed)": 45920.62,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -62760,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 406609,
    "out:DH kr savings": 482439,
    "out:El kr savings": -106691,
    "out:El kr sold": 30861,
    "out:El kr saved": 78065,
    "out:El kr return": 108926,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5234,
    "out:% savings (space heating)": 98.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.94,
    "out:Etvv": 0,
    "out:Ef": 35.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 108.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 71.27,
    "out:Total CO2 (tons)": 223.58,
    "out:Klimatpaverkan": 23.89,
    "out:Initial Cost/MSEK": 4.733625,
    "out:Running cost/(Apt SEK y)": 40736,
    "out:Running Cost over RSP/MSEK": 9.237,
    "out:LCP/MSEK": -2.282,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108477,
    "out:DH kr savings": 108477,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 105.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 69.83,
    "out:Total CO2 (tons)": 219.06,
    "out:Klimatpaverkan": 19.37,
    "out:Initial Cost/MSEK": 4.812125,
    "out:Running cost/(Apt SEK y)": 39598,
    "out:Running Cost over RSP/MSEK": 8.979,
    "out:LCP/MSEK": -2.102,
    "out:ROI% old": 8.04,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119896,
    "out:DH kr savings": 119896,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 105.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 69.17,
    "out:Total CO2 (tons)": 216.99,
    "out:Klimatpaverkan": 17.3,
    "out:Initial Cost/MSEK": 4.893,
    "out:Running cost/(Apt SEK y)": 38746,
    "out:Running Cost over RSP/MSEK": 8.785,
    "out:LCP/MSEK": -1.99,
    "out:ROI% old": 8.48,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128460,
    "out:DH kr savings": 128460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 137.9,
    "out:Primary Energy": 103.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 46.02,
    "out:Total CO2/m2": 67.73,
    "out:Total CO2 (tons)": 212.48,
    "out:Klimatpaverkan": 12.79,
    "out:Initial Cost/MSEK": 4.9715,
    "out:Running cost/(Apt SEK y)": 37608,
    "out:Running Cost over RSP/MSEK": 8.527,
    "out:LCP/MSEK": -1.81,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139879,
    "out:DH kr savings": 139879,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 99.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.65,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 52.63,
    "out:Total CO2 (tons)": 165.1,
    "out:Klimatpaverkan": -34.59,
    "out:Initial Cost/MSEK": 5.386125,
    "out:Running cost/(Apt SEK y)": 38956,
    "out:Running Cost over RSP/MSEK": 8.844,
    "out:LCP/MSEK": -2.542,
    "out:ROI% old": 7.54,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 365,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160077,
    "out:DH kr savings": 108477,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 96.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.65,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 51.19,
    "out:Total CO2 (tons)": 160.59,
    "out:Klimatpaverkan": -39.1,
    "out:Initial Cost/MSEK": 5.464625,
    "out:Running cost/(Apt SEK y)": 37819,
    "out:Running Cost over RSP/MSEK": 8.586,
    "out:LCP/MSEK": -2.362,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171496,
    "out:DH kr savings": 119896,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 96.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 50.53,
    "out:Total CO2 (tons)": 158.51,
    "out:Klimatpaverkan": -41.18,
    "out:Initial Cost/MSEK": 5.545625,
    "out:Running cost/(Apt SEK y)": 36966,
    "out:Running Cost over RSP/MSEK": 8.393,
    "out:LCP/MSEK": -2.249,
    "out:ROI% old": 8.49,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 423,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180060,
    "out:DH kr savings": 128460,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 132.73,
    "out:Primary Energy": 94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": 15.02,
    "out:Total CO2/m2": 49.09,
    "out:Total CO2 (tons)": 154,
    "out:Klimatpaverkan": -45.69,
    "out:Initial Cost/MSEK": 5.624,
    "out:Running cost/(Apt SEK y)": 35829,
    "out:Running Cost over RSP/MSEK": 8.134,
    "out:LCP/MSEK": -2.07,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 456,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191479,
    "out:DH kr savings": 139879,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 97.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 12.3,
    "out:Total CO2 (tons)": 38.6,
    "out:Klimatpaverkan": -161.09,
    "out:Initial Cost/MSEK": 6.03875,
    "out:Running cost/(Apt SEK y)": 37376,
    "out:Running Cost over RSP/MSEK": 8.496,
    "out:LCP/MSEK": -2.846,
    "out:ROI% old": 7.55,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119206,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205914,
    "out:DH kr savings": 108477,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 95.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 10.86,
    "out:Total CO2 (tons)": 34.08,
    "out:Klimatpaverkan": -165.61,
    "out:Initial Cost/MSEK": 6.117125,
    "out:Running cost/(Apt SEK y)": 36239,
    "out:Running Cost over RSP/MSEK": 8.238,
    "out:LCP/MSEK": -2.666,
    "out:ROI% old": 8.06,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217333,
    "out:DH kr savings": 119896,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 95.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.42,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 10.2,
    "out:Total CO2 (tons)": 32.01,
    "out:Klimatpaverkan": -167.68,
    "out:Initial Cost/MSEK": 6.198125,
    "out:Running cost/(Apt SEK y)": 35386,
    "out:Running Cost over RSP/MSEK": 8.044,
    "out:LCP/MSEK": -2.553,
    "out:ROI% old": 8.4,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 141165,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225897,
    "out:DH kr savings": 128460,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 137.9,
    "out:Total Energy Use Post PV": 131.88,
    "out:Primary Energy": 92.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.42,
    "out:CO2 Operational/m2": -37.66,
    "out:Total CO2/m2": 8.76,
    "out:Total CO2 (tons)": 27.49,
    "out:Klimatpaverkan": -172.2,
    "out:Initial Cost/MSEK": 6.2765,
    "out:Running cost/(Apt SEK y)": 34248,
    "out:Running Cost over RSP/MSEK": 7.786,
    "out:LCP/MSEK": -2.374,
    "out:ROI% old": 8.88,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 501,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237315,
    "out:DH kr savings": 139879,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 139.9,
    "out:Primary Energy": 105.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 46.74,
    "out:Total CO2/m2": 68.03,
    "out:Total CO2 (tons)": 213.42,
    "out:Klimatpaverkan": 13.73,
    "out:Initial Cost/MSEK": 4.81025,
    "out:Running cost/(Apt SEK y)": 38177,
    "out:Running Cost over RSP/MSEK": 8.656,
    "out:LCP/MSEK": -1.778,
    "out:ROI% old": 9.01,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 387,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134170,
    "out:DH kr savings": 134170,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 135.9,
    "out:Primary Energy": 102.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.3,
    "out:CO2 Operational/m2": 45.3,
    "out:Total CO2/m2": 66.59,
    "out:Total CO2 (tons)": 208.9,
    "out:Klimatpaverkan": 9.21,
    "out:Initial Cost/MSEK": 4.888625,
    "out:Running cost/(Apt SEK y)": 37040,
    "out:Running Cost over RSP/MSEK": 8.398,
    "out:LCP/MSEK": -1.598,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 420,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145588,
    "out:DH kr savings": 145588,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 134.9,
    "out:Primary Energy": 102.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 44.94,
    "out:Total CO2/m2": 66.65,
    "out:Total CO2 (tons)": 209.09,
    "out:Klimatpaverkan": 9.4,
    "out:Initial Cost/MSEK": 4.969625,
    "out:Running cost/(Apt SEK y)": 36755,
    "out:Running Cost over RSP/MSEK": 8.334,
    "out:LCP/MSEK": -1.614,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163124,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148443,
    "out:DH kr savings": 148443,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 130.9,
    "out:Total Energy Use Post PV": 130.9,
    "out:Primary Energy": 100.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.72,
    "out:CO2 Operational/m2": 43.5,
    "out:Total CO2/m2": 65.21,
    "out:Total CO2 (tons)": 204.57,
    "out:Klimatpaverkan": 4.88,
    "out:Initial Cost/MSEK": 5.048,
    "out:Running cost/(Apt SEK y)": 35618,
    "out:Running Cost over RSP/MSEK": 8.076,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159862,
    "out:DH kr savings": 159862,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 134.73,
    "out:Primary Energy": 95.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.65,
    "out:CO2 Operational/m2": 15.74,
    "out:Total CO2/m2": 49.39,
    "out:Total CO2 (tons)": 154.94,
    "out:Klimatpaverkan": -44.75,
    "out:Initial Cost/MSEK": 5.46275,
    "out:Running cost/(Apt SEK y)": 36398,
    "out:Running Cost over RSP/MSEK": 8.263,
    "out:LCP/MSEK": -2.037,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185769,
    "out:DH kr savings": 134170,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 130.73,
    "out:Primary Energy": 93.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.65,
    "out:CO2 Operational/m2": 14.3,
    "out:Total CO2/m2": 47.95,
    "out:Total CO2 (tons)": 150.42,
    "out:Klimatpaverkan": -49.27,
    "out:Initial Cost/MSEK": 5.541125,
    "out:Running cost/(Apt SEK y)": 35260,
    "out:Running Cost over RSP/MSEK": 8.005,
    "out:LCP/MSEK": -1.858,
    "out:ROI% old": 9.5,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 472,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197188,
    "out:DH kr savings": 145588,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 129.73,
    "out:Primary Energy": 93.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 48.01,
    "out:Total CO2 (tons)": 150.61,
    "out:Klimatpaverkan": -49.08,
    "out:Initial Cost/MSEK": 5.622125,
    "out:Running cost/(Apt SEK y)": 34976,
    "out:Running Cost over RSP/MSEK": 7.941,
    "out:LCP/MSEK": -1.874,
    "out:ROI% old": 9.52,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163124,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200043,
    "out:DH kr savings": 148443,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 130.9,
    "out:Total Energy Use Post PV": 125.73,
    "out:Primary Energy": 91.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.07,
    "out:CO2 Operational/m2": 12.5,
    "out:Total CO2/m2": 46.57,
    "out:Total CO2 (tons)": 146.09,
    "out:Klimatpaverkan": -53.6,
    "out:Initial Cost/MSEK": 5.7005,
    "out:Running cost/(Apt SEK y)": 33839,
    "out:Running Cost over RSP/MSEK": 7.683,
    "out:LCP/MSEK": -1.695,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 18839.11,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 16223.6,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 16224,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211462,
    "out:DH kr savings": 159862,
    "out:El kr savings": 27580,
    "out:El kr sold": 24020,
    "out:El kr saved": 27580,
    "out:El kr return": 51600,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 133.88,
    "out:Primary Energy": 94.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46,
    "out:CO2 Operational/m2": -36.94,
    "out:Total CO2/m2": 9.06,
    "out:Total CO2 (tons)": 28.43,
    "out:Klimatpaverkan": -171.26,
    "out:Initial Cost/MSEK": 6.11525,
    "out:Running cost/(Apt SEK y)": 34817,
    "out:Running Cost over RSP/MSEK": 7.915,
    "out:LCP/MSEK": -2.341,
    "out:ROI% old": 8.82,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 485,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231606,
    "out:DH kr savings": 134170,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 129.88,
    "out:Primary Energy": 91.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46,
    "out:CO2 Operational/m2": -38.38,
    "out:Total CO2/m2": 7.62,
    "out:Total CO2 (tons)": 23.92,
    "out:Klimatpaverkan": -175.77,
    "out:Initial Cost/MSEK": 6.193625,
    "out:Running cost/(Apt SEK y)": 33680,
    "out:Running Cost over RSP/MSEK": 7.657,
    "out:LCP/MSEK": -2.162,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243025,
    "out:DH kr savings": 145588,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.23,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 128.88,
    "out:Primary Energy": 92.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.42,
    "out:CO2 Operational/m2": -38.74,
    "out:Total CO2/m2": 7.68,
    "out:Total CO2 (tons)": 24.1,
    "out:Klimatpaverkan": -175.59,
    "out:Initial Cost/MSEK": 6.274625,
    "out:Running cost/(Apt SEK y)": 33396,
    "out:Running Cost over RSP/MSEK": 7.592,
    "out:LCP/MSEK": -2.178,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 526,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163124,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245879,
    "out:DH kr savings": 148443,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 279912,
    "out:% savings (space heating)": 30.22,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 130.9,
    "out:Total Energy Use Post PV": 124.88,
    "out:Primary Energy": 89.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.42,
    "out:CO2 Operational/m2": -40.18,
    "out:Total CO2/m2": 6.24,
    "out:Total CO2 (tons)": 19.59,
    "out:Klimatpaverkan": -180.1,
    "out:Initial Cost/MSEK": 6.353,
    "out:Running cost/(Apt SEK y)": 32258,
    "out:Running Cost over RSP/MSEK": 7.334,
    "out:LCP/MSEK": -1.999,
    "out:ROI% old": 9.79,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 51239.3,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 18886.12,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 18886,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257298,
    "out:DH kr savings": 159862,
    "out:El kr savings": 32106,
    "out:El kr sold": 65330,
    "out:El kr saved": 32106,
    "out:El kr return": 97437,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 268045,
    "out:% savings (space heating)": 33.18,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 41.82,
    "out:Total Energy Use Pre PV": 91.22,
    "out:Total Energy Use Post PV": 88.82,
    "out:Primary Energy": 100.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 22.76,
    "out:Total CO2/m2": 47.86,
    "out:Total CO2 (tons)": 150.14,
    "out:Klimatpaverkan": -49.55,
    "out:Initial Cost/MSEK": 7.456,
    "out:Running cost/(Apt SEK y)": 21493,
    "out:Running Cost over RSP/MSEK": 4.856,
    "out:LCP/MSEK": -0.623,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 3095.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -81306,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215758,
    "out:DH kr savings": 353979,
    "out:El kr savings": -138221,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 147028,
    "out:% savings (space heating)": 63.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.29,
    "out:Etvv": 0,
    "out:Ef": 41.82,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.14,
    "out:Electricity (inc PV)": 41.69,
    "out:Total Energy Use Pre PV": 87.14,
    "out:Total Energy Use Post PV": 84.69,
    "out:Primary Energy": 98.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 21.31,
    "out:Total CO2/m2": 46.41,
    "out:Total CO2 (tons)": 145.59,
    "out:Klimatpaverkan": -54.1,
    "out:Initial Cost/MSEK": 7.5345,
    "out:Running cost/(Apt SEK y)": 20341,
    "out:Running Cost over RSP/MSEK": 4.594,
    "out:LCP/MSEK": -0.44,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3089.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 905,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -80897,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227873,
    "out:DH kr savings": 365398,
    "out:El kr savings": -137525,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 136004,
    "out:% savings (space heating)": 66.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 41.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.41,
    "out:Electricity (inc PV)": 41.29,
    "out:Total Energy Use Pre PV": 86.41,
    "out:Total Energy Use Post PV": 84.29,
    "out:Primary Energy": 98.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 21.21,
    "out:Total CO2/m2": 46.73,
    "out:Total CO2 (tons)": 146.6,
    "out:Klimatpaverkan": -53.09,
    "out:Initial Cost/MSEK": 7.615375,
    "out:Running cost/(Apt SEK y)": 20207,
    "out:Running Cost over RSP/MSEK": 4.564,
    "out:LCP/MSEK": -0.491,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 3038.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -79661,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229975,
    "out:DH kr savings": 365398,
    "out:El kr savings": -135423,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 134946,
    "out:% savings (space heating)": 66.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.27,
    "out:Etvv": 0,
    "out:Ef": 41.29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 41.15,
    "out:Total Energy Use Pre PV": 83.31,
    "out:Total Energy Use Post PV": 81.15,
    "out:Primary Energy": 95.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 20.12,
    "out:Total CO2/m2": 45.64,
    "out:Total CO2 (tons)": 143.18,
    "out:Klimatpaverkan": -56.51,
    "out:Initial Cost/MSEK": 7.693875,
    "out:Running cost/(Apt SEK y)": 19336,
    "out:Running Cost over RSP/MSEK": 4.366,
    "out:LCP/MSEK": -0.372,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 3031.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 410947,
    "out:EL kWh savings": -79208,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239308,
    "out:DH kr savings": 373962,
    "out:El kr savings": -134654,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 124089,
    "out:% savings (space heating)": 69.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.96,
    "out:Etvv": 0,
    "out:Ef": 41.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 32.44,
    "out:Total Energy Use Pre PV": 91.22,
    "out:Total Energy Use Post PV": 79.44,
    "out:Primary Energy": 83.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.46,
    "out:CO2 Operational/m2": 13.92,
    "out:Total CO2/m2": 51.38,
    "out:Total CO2 (tons)": 161.17,
    "out:Klimatpaverkan": -38.52,
    "out:Initial Cost/MSEK": 8.108625,
    "out:Running cost/(Apt SEK y)": 19509,
    "out:Running Cost over RSP/MSEK": 4.418,
    "out:LCP/MSEK": -0.837,
    "out:ROI% old": 12.81,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2447.6,
    "out:Return %": 11,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4877.91,
    "out:PV (% sold (El))": 13.91,
    "out:PV (kWh self-consumed)": 30184.8,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -51884,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271995,
    "out:DH kr savings": 353979,
    "out:El kr savings": -88203,
    "out:El kr sold": 6219,
    "out:El kr saved": 51314,
    "out:El kr return": 57533,
    "out:% solar/total": 61,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 147028,
    "out:% savings (space heating)": 63.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.29,
    "out:Etvv": 0,
    "out:Ef": 32.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.14,
    "out:Electricity (inc PV)": 32.32,
    "out:Total Energy Use Pre PV": 87.14,
    "out:Total Energy Use Post PV": 75.32,
    "out:Primary Energy": 81.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.46,
    "out:CO2 Operational/m2": 12.43,
    "out:Total CO2/m2": 49.89,
    "out:Total CO2 (tons)": 156.5,
    "out:Klimatpaverkan": -43.19,
    "out:Initial Cost/MSEK": 8.187,
    "out:Running cost/(Apt SEK y)": 18358,
    "out:Running Cost over RSP/MSEK": 4.156,
    "out:LCP/MSEK": -0.655,
    "out:ROI% old": 13.14,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2442.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 4902.82,
    "out:PV (% sold (El))": 13.98,
    "out:PV (kWh self-consumed)": 30159.89,
    "out:PV (ratio sold/self-consumed)": 0.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -51518,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284068,
    "out:DH kr savings": 365398,
    "out:El kr savings": -87581,
    "out:El kr sold": 6251,
    "out:El kr saved": 51272,
    "out:El kr return": 57523,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 136004,
    "out:% savings (space heating)": 66.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 32.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.41,
    "out:Electricity (inc PV)": 31.97,
    "out:Total Energy Use Pre PV": 86.41,
    "out:Total Energy Use Post PV": 74.97,
    "out:Primary Energy": 81.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 11.98,
    "out:Total CO2/m2": 49.86,
    "out:Total CO2 (tons)": 156.41,
    "out:Klimatpaverkan": -43.28,
    "out:Initial Cost/MSEK": 8.268,
    "out:Running cost/(Apt SEK y)": 18227,
    "out:Running Cost over RSP/MSEK": 4.127,
    "out:LCP/MSEK": -0.706,
    "out:ROI% old": 13.07,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2395.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 966,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5129.38,
    "out:PV (% sold (El))": 14.63,
    "out:PV (kWh self-consumed)": 29933.33,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -50413,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286235,
    "out:DH kr savings": 365398,
    "out:El kr savings": -85702,
    "out:El kr sold": 6540,
    "out:El kr saved": 50887,
    "out:El kr return": 57427,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 134946,
    "out:% savings (space heating)": 66.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.27,
    "out:Etvv": 0,
    "out:Ef": 31.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 31.84,
    "out:Total Energy Use Pre PV": 83.31,
    "out:Total Energy Use Post PV": 71.84,
    "out:Primary Energy": 78.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 10.84,
    "out:Total CO2/m2": 48.72,
    "out:Total CO2 (tons)": 152.83,
    "out:Klimatpaverkan": -46.86,
    "out:Initial Cost/MSEK": 8.346375,
    "out:Running cost/(Apt SEK y)": 17357,
    "out:Running Cost over RSP/MSEK": 3.929,
    "out:LCP/MSEK": -0.587,
    "out:ROI% old": 13.28,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2389.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 991,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5160.1,
    "out:PV (% sold (El))": 14.72,
    "out:PV (kWh self-consumed)": 29902.61,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410947,
    "out:EL kWh savings": -50010,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295525,
    "out:DH kr savings": 373962,
    "out:El kr savings": -85016,
    "out:El kr sold": 6579,
    "out:El kr saved": 50834,
    "out:El kr return": 57414,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 124089,
    "out:% savings (space heating)": 69.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.96,
    "out:Etvv": 0,
    "out:Ef": 31.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.22,
    "out:Electricity (inc PV)": 29.32,
    "out:Total Energy Use Pre PV": 91.22,
    "out:Total Energy Use Post PV": 76.32,
    "out:Primary Energy": 78.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -26.12,
    "out:Total CO2/m2": 23.7,
    "out:Total CO2 (tons)": 74.33,
    "out:Klimatpaverkan": -125.36,
    "out:Initial Cost/MSEK": 8.761125,
    "out:Running cost/(Apt SEK y)": 17814,
    "out:Running Cost over RSP/MSEK": 4.043,
    "out:LCP/MSEK": -1.116,
    "out:ROI% old": 12.47,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2209.2,
    "out:Return %": 11,
    "out:Return/kSEK/y": 978,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 26.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29450.93,
    "out:PV (% sold (El))": 42,
    "out:PV (kWh self-consumed)": 40674.49,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 388988,
    "out:EL kWh savings": -42098,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 319962,
    "out:DH kr savings": 353979,
    "out:El kr savings": -71567,
    "out:El kr sold": 37550,
    "out:El kr saved": 69147,
    "out:El kr return": 106697,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 147028,
    "out:% savings (space heating)": 63.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 36.29,
    "out:Etvv": 0,
    "out:Ef": 29.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 44.14,
    "out:Electricity (inc PV)": 29.22,
    "out:Total Energy Use Pre PV": 87.14,
    "out:Total Energy Use Post PV": 72.22,
    "out:Primary Energy": 75.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -27.65,
    "out:Total CO2/m2": 22.17,
    "out:Total CO2 (tons)": 69.54,
    "out:Klimatpaverkan": -130.15,
    "out:Initial Cost/MSEK": 8.8395,
    "out:Running cost/(Apt SEK y)": 16663,
    "out:Running Cost over RSP/MSEK": 3.782,
    "out:LCP/MSEK": -0.933,
    "out:ROI% old": 12.78,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2204.6,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29499.89,
    "out:PV (% sold (El))": 42.07,
    "out:PV (kWh self-consumed)": 40625.53,
    "out:PV (ratio sold/self-consumed)": 0.73,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -41770,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332001,
    "out:DH kr savings": 365398,
    "out:El kr savings": -71009,
    "out:El kr sold": 37612,
    "out:El kr saved": 69063,
    "out:El kr return": 106676,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 136004,
    "out:% savings (space heating)": 66.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.93,
    "out:Etvv": 0,
    "out:Ef": 29.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.41,
    "out:Electricity (inc PV)": 28.9,
    "out:Total Energy Use Pre PV": 86.41,
    "out:Total Energy Use Post PV": 71.9,
    "out:Primary Energy": 76.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -28.44,
    "out:Total CO2/m2": 21.79,
    "out:Total CO2 (tons)": 68.34,
    "out:Klimatpaverkan": -131.35,
    "out:Initial Cost/MSEK": 8.9205,
    "out:Running cost/(Apt SEK y)": 16535,
    "out:Running Cost over RSP/MSEK": 3.753,
    "out:LCP/MSEK": -0.985,
    "out:ROI% old": 12.71,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2163,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.21,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 29940.82,
    "out:PV (% sold (El))": 42.7,
    "out:PV (kWh self-consumed)": 40184.6,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401536,
    "out:EL kWh savings": -40779,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334247,
    "out:DH kr savings": 365398,
    "out:El kr savings": -69325,
    "out:El kr sold": 38175,
    "out:El kr saved": 68314,
    "out:El kr return": 106488,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 134946,
    "out:% savings (space heating)": 66.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 34.27,
    "out:Etvv": 0,
    "out:Ef": 28.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 39.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.31,
    "out:Electricity (inc PV)": 28.78,
    "out:Total Energy Use Pre PV": 83.31,
    "out:Total Energy Use Post PV": 68.78,
    "out:Primary Energy": 73.48,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -29.63,
    "out:Total CO2/m2": 20.6,
    "out:Total CO2 (tons)": 64.62,
    "out:Klimatpaverkan": -135.07,
    "out:Initial Cost/MSEK": 8.998875,
    "out:Running cost/(Apt SEK y)": 15665,
    "out:Running Cost over RSP/MSEK": 3.556,
    "out:LCP/MSEK": -0.866,
    "out:ROI% old": 12.91,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2157.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1040,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 25.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30000.19,
    "out:PV (% sold (El))": 42.78,
    "out:PV (kWh self-consumed)": 40125.23,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410947,
    "out:EL kWh savings": -40418,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343502,
    "out:DH kr savings": 373962,
    "out:El kr savings": -68710,
    "out:El kr sold": 38250,
    "out:El kr saved": 68213,
    "out:El kr return": 106463,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 124089,
    "out:% savings (space heating)": 69.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.96,
    "out:Etvv": 0,
    "out:Ef": 28.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.13,
    "out:Electricity (inc PV)": 41.12,
    "out:Total Energy Use Pre PV": 85.13,
    "out:Total Energy Use Post PV": 83.12,
    "out:Primary Energy": 97.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 20.81,
    "out:Total CO2/m2": 45.92,
    "out:Total CO2 (tons)": 144.04,
    "out:Klimatpaverkan": -55.65,
    "out:Initial Cost/MSEK": 7.532625,
    "out:Running cost/(Apt SEK y)": 19872,
    "out:Running Cost over RSP/MSEK": 4.488,
    "out:LCP/MSEK": -0.332,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 3019,
    "out:Return %": 12,
    "out:Return/kSEK/y": 918,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -79102,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233779,
    "out:DH kr savings": 368252,
    "out:El kr savings": -134474,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131015,
    "out:% savings (space heating)": 67.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.61,
    "out:Etvv": 0,
    "out:Ef": 41.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 81.03,
    "out:Total Energy Use Post PV": 78.97,
    "out:Primary Energy": 94.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.1,
    "out:CO2 Operational/m2": 19.36,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 139.48,
    "out:Klimatpaverkan": -60.21,
    "out:Initial Cost/MSEK": 7.611,
    "out:Running cost/(Apt SEK y)": 18716,
    "out:Running Cost over RSP/MSEK": 4.226,
    "out:LCP/MSEK": -0.148,
    "out:ROI% old": 14.01,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 3011.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 952,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -78632,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245996,
    "out:DH kr savings": 379671,
    "out:El kr savings": -133675,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120204,
    "out:% savings (space heating)": 70.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.32,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.44,
    "out:Electricity (inc PV)": 40.68,
    "out:Total Energy Use Pre PV": 81.44,
    "out:Total Energy Use Post PV": 79.68,
    "out:Primary Energy": 95.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 19.64,
    "out:Total CO2/m2": 45.17,
    "out:Total CO2 (tons)": 141.69,
    "out:Klimatpaverkan": -58,
    "out:Initial Cost/MSEK": 7.692,
    "out:Running cost/(Apt SEK y)": 18893,
    "out:Running Cost over RSP/MSEK": 4.266,
    "out:LCP/MSEK": -0.269,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2971,
    "out:Return %": 12,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 414084,
    "out:EL kWh savings": -77736,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244664,
    "out:DH kr savings": 376816,
    "out:El kr savings": -132152,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 121734,
    "out:% savings (space heating)": 69.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.04,
    "out:Etvv": 0,
    "out:Ef": 40.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.32,
    "out:Electricity (inc PV)": 40.52,
    "out:Total Energy Use Pre PV": 77.32,
    "out:Total Energy Use Post PV": 75.52,
    "out:Primary Energy": 93.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.52,
    "out:CO2 Operational/m2": 18.19,
    "out:Total CO2/m2": 43.71,
    "out:Total CO2 (tons)": 137.12,
    "out:Klimatpaverkan": -62.57,
    "out:Initial Cost/MSEK": 7.770375,
    "out:Running cost/(Apt SEK y)": 17734,
    "out:Running Cost over RSP/MSEK": 4.003,
    "out:LCP/MSEK": -0.085,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2962.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 980,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426632,
    "out:EL kWh savings": -77229,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256946,
    "out:DH kr savings": 388235,
    "out:El kr savings": -131289,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 111088,
    "out:% savings (space heating)": 72.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 28.79,
    "out:Etvv": 0,
    "out:Ef": 40.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.13,
    "out:Electricity (inc PV)": 31.81,
    "out:Total Energy Use Pre PV": 85.13,
    "out:Total Energy Use Post PV": 73.81,
    "out:Primary Energy": 80.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.46,
    "out:CO2 Operational/m2": 11.45,
    "out:Total CO2/m2": 48.9,
    "out:Total CO2 (tons)": 153.41,
    "out:Klimatpaverkan": -46.28,
    "out:Initial Cost/MSEK": 8.185125,
    "out:Running cost/(Apt SEK y)": 17893,
    "out:Running Cost over RSP/MSEK": 4.051,
    "out:LCP/MSEK": -0.548,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2378,
    "out:Return %": 12,
    "out:Return/kSEK/y": 976,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5217.44,
    "out:PV (% sold (El))": 14.88,
    "out:PV (kWh self-consumed)": 29845.27,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -49915,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 290049,
    "out:DH kr savings": 368252,
    "out:El kr savings": -84855,
    "out:El kr sold": 6652,
    "out:El kr saved": 50737,
    "out:El kr return": 57389,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131015,
    "out:% savings (space heating)": 67.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.61,
    "out:Etvv": 0,
    "out:Ef": 31.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 31.68,
    "out:Total Energy Use Pre PV": 81.03,
    "out:Total Energy Use Post PV": 69.68,
    "out:Primary Energy": 78.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.46,
    "out:CO2 Operational/m2": 9.94,
    "out:Total CO2/m2": 47.4,
    "out:Total CO2 (tons)": 148.7,
    "out:Klimatpaverkan": -50.99,
    "out:Initial Cost/MSEK": 8.2635,
    "out:Running cost/(Apt SEK y)": 16737,
    "out:Running Cost over RSP/MSEK": 3.789,
    "out:LCP/MSEK": -0.364,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2371.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1009,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5249.83,
    "out:PV (% sold (El))": 14.97,
    "out:PV (kWh self-consumed)": 29812.87,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -49496,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302222,
    "out:DH kr savings": 379671,
    "out:El kr savings": -84143,
    "out:El kr sold": 6694,
    "out:El kr saved": 50682,
    "out:El kr return": 57375,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120204,
    "out:% savings (space heating)": 70.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.32,
    "out:Etvv": 0,
    "out:Ef": 31.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.44,
    "out:Electricity (inc PV)": 31.42,
    "out:Total Energy Use Pre PV": 81.44,
    "out:Total Energy Use Post PV": 70.42,
    "out:Primary Energy": 78.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 9.93,
    "out:Total CO2/m2": 47.81,
    "out:Total CO2 (tons)": 149.98,
    "out:Klimatpaverkan": -49.71,
    "out:Initial Cost/MSEK": 8.3445,
    "out:Running cost/(Apt SEK y)": 16917,
    "out:Running Cost over RSP/MSEK": 3.83,
    "out:LCP/MSEK": -0.486,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2334.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1004,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5436.67,
    "out:PV (% sold (El))": 15.51,
    "out:PV (kWh self-consumed)": 29626.04,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 414084,
    "out:EL kWh savings": -48698,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300962,
    "out:DH kr savings": 376816,
    "out:El kr savings": -82786,
    "out:El kr sold": 6932,
    "out:El kr saved": 50364,
    "out:El kr return": 57296,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 121734,
    "out:% savings (space heating)": 69.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.04,
    "out:Etvv": 0,
    "out:Ef": 31.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.32,
    "out:Electricity (inc PV)": 31.28,
    "out:Total Energy Use Pre PV": 77.32,
    "out:Total Energy Use Post PV": 66.28,
    "out:Primary Energy": 76.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.88,
    "out:CO2 Operational/m2": 8.42,
    "out:Total CO2/m2": 46.29,
    "out:Total CO2 (tons)": 145.22,
    "out:Klimatpaverkan": -54.47,
    "out:Initial Cost/MSEK": 8.422875,
    "out:Running cost/(Apt SEK y)": 15759,
    "out:Running Cost over RSP/MSEK": 3.567,
    "out:LCP/MSEK": -0.301,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2326.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1038,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 5474.89,
    "out:PV (% sold (El))": 15.61,
    "out:PV (kWh self-consumed)": 29587.82,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426632,
    "out:EL kWh savings": -48246,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313198,
    "out:DH kr savings": 388235,
    "out:El kr savings": -82018,
    "out:El kr sold": 6980,
    "out:El kr saved": 50299,
    "out:El kr return": 57280,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 111088,
    "out:% savings (space heating)": 72.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 28.79,
    "out:Etvv": 0,
    "out:Ef": 31.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.13,
    "out:Electricity (inc PV)": 28.76,
    "out:Total Energy Use Pre PV": 85.13,
    "out:Total Energy Use Post PV": 70.76,
    "out:Primary Energy": 75.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -29.11,
    "out:Total CO2/m2": 20.7,
    "out:Total CO2 (tons)": 64.93,
    "out:Klimatpaverkan": -134.76,
    "out:Initial Cost/MSEK": 8.837625,
    "out:Running cost/(Apt SEK y)": 16202,
    "out:Running Cost over RSP/MSEK": 3.678,
    "out:LCP/MSEK": -0.827,
    "out:ROI% old": 12.95,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2147.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1025,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30110.98,
    "out:PV (% sold (El))": 42.94,
    "out:PV (kWh self-consumed)": 40014.44,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 404673,
    "out:EL kWh savings": -40333,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338078,
    "out:DH kr savings": 368252,
    "out:El kr savings": -68566,
    "out:El kr sold": 38391,
    "out:El kr saved": 68025,
    "out:El kr return": 106416,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 131015,
    "out:% savings (space heating)": 67.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 33.61,
    "out:Etvv": 0,
    "out:Ef": 28.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.03,
    "out:Electricity (inc PV)": 28.64,
    "out:Total Energy Use Pre PV": 81.03,
    "out:Total Energy Use Post PV": 66.64,
    "out:Primary Energy": 72.77,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.81,
    "out:CO2 Operational/m2": -30.67,
    "out:Total CO2/m2": 19.15,
    "out:Total CO2 (tons)": 60.06,
    "out:Klimatpaverkan": -139.63,
    "out:Initial Cost/MSEK": 8.916125,
    "out:Running cost/(Apt SEK y)": 15047,
    "out:Running Cost over RSP/MSEK": 3.416,
    "out:LCP/MSEK": -0.643,
    "out:ROI% old": 13.26,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2141.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1058,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.83,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30173.38,
    "out:PV (% sold (El))": 43.03,
    "out:PV (kWh self-consumed)": 39952.04,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 417221,
    "out:EL kWh savings": -39957,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350216,
    "out:DH kr savings": 379671,
    "out:El kr savings": -67926,
    "out:El kr sold": 38471,
    "out:El kr saved": 67918,
    "out:El kr return": 106390,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 120204,
    "out:% savings (space heating)": 70.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 30.32,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 38.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.44,
    "out:Electricity (inc PV)": 28.41,
    "out:Total Energy Use Pre PV": 81.44,
    "out:Total Energy Use Post PV": 67.41,
    "out:Primary Energy": 73.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -30.95,
    "out:Total CO2/m2": 19.28,
    "out:Total CO2 (tons)": 60.47,
    "out:Klimatpaverkan": -139.22,
    "out:Initial Cost/MSEK": 8.997,
    "out:Running cost/(Apt SEK y)": 15229,
    "out:Running Cost over RSP/MSEK": 3.457,
    "out:LCP/MSEK": -0.766,
    "out:ROI% old": 13.07,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 39,
    "out:El bought/kWh/m2": 2108.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1053,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30531.41,
    "out:PV (% sold (El))": 43.54,
    "out:PV (kWh self-consumed)": 39594.01,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 414084,
    "out:EL kWh savings": -39240,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349035,
    "out:DH kr savings": 376816,
    "out:El kr savings": -66709,
    "out:El kr sold": 38928,
    "out:El kr saved": 67310,
    "out:El kr return": 106237,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 121734,
    "out:% savings (space heating)": 69.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 32.04,
    "out:Etvv": 0,
    "out:Ef": 28.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 35.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.32,
    "out:Electricity (inc PV)": 28.28,
    "out:Total Energy Use Pre PV": 77.32,
    "out:Total Energy Use Post PV": 63.28,
    "out:Primary Energy": 71.06,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.23,
    "out:CO2 Operational/m2": -32.52,
    "out:Total CO2/m2": 17.71,
    "out:Total CO2 (tons)": 55.54,
    "out:Klimatpaverkan": -144.15,
    "out:Initial Cost/MSEK": 9.0755,
    "out:Running cost/(Apt SEK y)": 14071,
    "out:Running Cost over RSP/MSEK": 3.195,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 35,
    "out:El bought/kWh/m2": 2101.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1087,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.13,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 30604.12,
    "out:PV (% sold (El))": 43.64,
    "out:PV (kWh self-consumed)": 39521.3,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426632,
    "out:EL kWh savings": -38835,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361236,
    "out:DH kr savings": 388235,
    "out:El kr savings": -66019,
    "out:El kr sold": 39020,
    "out:El kr saved": 67186,
    "out:El kr return": 106206,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 111088,
    "out:% savings (space heating)": 72.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 28.79,
    "out:Etvv": 0,
    "out:Ef": 28.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 52.37,
    "out:Total Energy Use Pre PV": 60.51,
    "out:Total Energy Use Post PV": 55.37,
    "out:Primary Energy": 95.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.75,
    "out:CO2 Operational/m2": 8.67,
    "out:Total CO2/m2": 34.42,
    "out:Total CO2 (tons)": 107.98,
    "out:Klimatpaverkan": -91.71,
    "out:Initial Cost/MSEK": 9.312375,
    "out:Running cost/(Apt SEK y)": 11429,
    "out:Running Cost over RSP/MSEK": 2.565,
    "out:LCP/MSEK": -0.188,
    "out:ROI% old": 14,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 4025.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -114422,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285067,
    "out:DH kr savings": 479585,
    "out:El kr savings": -194517,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9426,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.45,
    "out:Etvv": 0,
    "out:Ef": 52.37,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.63,
    "out:Electricity (inc PV)": 51.39,
    "out:Total Energy Use Pre PV": 58.63,
    "out:Total Energy Use Post PV": 53.39,
    "out:Primary Energy": 93.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.75,
    "out:CO2 Operational/m2": 8.2,
    "out:Total CO2/m2": 33.94,
    "out:Total CO2 (tons)": 106.48,
    "out:Klimatpaverkan": -93.21,
    "out:Initial Cost/MSEK": 9.390875,
    "out:Running cost/(Apt SEK y)": 10982,
    "out:Running Cost over RSP/MSEK": 2.464,
    "out:LCP/MSEK": -0.166,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3963.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -111329,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293180,
    "out:DH kr savings": 482439,
    "out:El kr savings": -189259,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6701,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.92,
    "out:Etvv": 0,
    "out:Ef": 51.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.73,
    "out:Electricity (inc PV)": 51.31,
    "out:Total Energy Use Pre PV": 57.73,
    "out:Total Energy Use Post PV": 53.31,
    "out:Primary Energy": 93.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 8.08,
    "out:Total CO2/m2": 34.24,
    "out:Total CO2 (tons)": 107.42,
    "out:Klimatpaverkan": -92.27,
    "out:Initial Cost/MSEK": 9.47175,
    "out:Running cost/(Apt SEK y)": 10816,
    "out:Running Cost over RSP/MSEK": 2.426,
    "out:LCP/MSEK": -0.209,
    "out:ROI% old": 13.97,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3900.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1181,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.53,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -111071,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293619,
    "out:DH kr savings": 482439,
    "out:El kr savings": -188820,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7431,
    "out:% savings (space heating)": 98.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.23,
    "out:Etvv": 0,
    "out:Ef": 51.31,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.8,
    "out:Electricity (inc PV)": 50.3,
    "out:Total Energy Use Pre PV": 56.8,
    "out:Total Energy Use Post PV": 52.3,
    "out:Primary Energy": 91.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 7.95,
    "out:Total CO2/m2": 34.12,
    "out:Total CO2 (tons)": 107.04,
    "out:Klimatpaverkan": -92.65,
    "out:Initial Cost/MSEK": 9.55025,
    "out:Running cost/(Apt SEK y)": 10647,
    "out:Running Cost over RSP/MSEK": 2.389,
    "out:LCP/MSEK": -0.25,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3836.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1186,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -107916,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298982,
    "out:DH kr savings": 482439,
    "out:El kr savings": -183457,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5092,
    "out:% savings (space heating)": 98.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.76,
    "out:Etvv": 0,
    "out:Ef": 50.3,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 42.09,
    "out:Total Energy Use Pre PV": 60.51,
    "out:Total Energy Use Post PV": 45.09,
    "out:Primary Energy": 76.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.1,
    "out:CO2 Operational/m2": 4.58,
    "out:Total CO2/m2": 42.68,
    "out:Total CO2 (tons)": 133.88,
    "out:Klimatpaverkan": -65.81,
    "out:Initial Cost/MSEK": 9.965,
    "out:Running cost/(Apt SEK y)": 9400,
    "out:Running Cost over RSP/MSEK": 2.117,
    "out:LCP/MSEK": -0.393,
    "out:ROI% old": 13.72,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3320.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1819.94,
    "out:PV (% sold (El))": 5.19,
    "out:PV (kWh self-consumed)": 33242.77,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -82149,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342252,
    "out:DH kr savings": 479585,
    "out:El kr savings": -139653,
    "out:El kr sold": 2320,
    "out:El kr saved": 56513,
    "out:El kr return": 58833,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9426,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.45,
    "out:Etvv": 0,
    "out:Ef": 42.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.63,
    "out:Electricity (inc PV)": 41.17,
    "out:Total Energy Use Pre PV": 58.63,
    "out:Total Energy Use Post PV": 43.17,
    "out:Primary Energy": 74.75,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.1,
    "out:CO2 Operational/m2": 3.87,
    "out:Total CO2/m2": 41.97,
    "out:Total CO2 (tons)": 131.67,
    "out:Klimatpaverkan": -68.02,
    "out:Initial Cost/MSEK": 10.043375,
    "out:Running cost/(Apt SEK y)": 8955,
    "out:Running Cost over RSP/MSEK": 2.017,
    "out:LCP/MSEK": -0.371,
    "out:ROI% old": 13.76,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3261.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 1969.29,
    "out:PV (% sold (El))": 5.62,
    "out:PV (kWh self-consumed)": 33093.42,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -79271,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350189,
    "out:DH kr savings": 482439,
    "out:El kr savings": -134761,
    "out:El kr sold": 2511,
    "out:El kr saved": 56259,
    "out:El kr return": 58770,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6701,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.92,
    "out:Etvv": 0,
    "out:Ef": 41.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.73,
    "out:Electricity (inc PV)": 41.09,
    "out:Total Energy Use Pre PV": 57.73,
    "out:Total Energy Use Post PV": 43.09,
    "out:Primary Energy": 74.83,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": 3.51,
    "out:Total CO2/m2": 42.03,
    "out:Total CO2 (tons)": 131.84,
    "out:Klimatpaverkan": -67.85,
    "out:Initial Cost/MSEK": 10.124375,
    "out:Running cost/(Apt SEK y)": 8792,
    "out:Running Cost over RSP/MSEK": 1.98,
    "out:LCP/MSEK": -0.416,
    "out:ROI% old": 13.7,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3200.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1240,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.53,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2128.89,
    "out:PV (% sold (El))": 6.07,
    "out:PV (kWh self-consumed)": 32933.82,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -79031,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 350800,
    "out:DH kr savings": 482439,
    "out:El kr savings": -134353,
    "out:El kr sold": 2714,
    "out:El kr saved": 55987,
    "out:El kr return": 58702,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7431,
    "out:% savings (space heating)": 98.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.23,
    "out:Etvv": 0,
    "out:Ef": 41.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.8,
    "out:Electricity (inc PV)": 40.16,
    "out:Total Energy Use Pre PV": 56.8,
    "out:Total Energy Use Post PV": 42.16,
    "out:Primary Energy": 72.82,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": 3.12,
    "out:Total CO2/m2": 41.64,
    "out:Total CO2 (tons)": 130.64,
    "out:Klimatpaverkan": -69.05,
    "out:Initial Cost/MSEK": 10.20275,
    "out:Running cost/(Apt SEK y)": 8625,
    "out:Running Cost over RSP/MSEK": 1.942,
    "out:LCP/MSEK": -0.456,
    "out:ROI% old": 13.65,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3139.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1245,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2299.24,
    "out:PV (% sold (El))": 6.56,
    "out:PV (kWh self-consumed)": 32763.47,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -76106,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 355991,
    "out:DH kr savings": 482439,
    "out:El kr savings": -129380,
    "out:El kr sold": 2932,
    "out:El kr saved": 55698,
    "out:El kr return": 58629,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5092,
    "out:% savings (space heating)": 98.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.76,
    "out:Etvv": 0,
    "out:Ef": 40.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.51,
    "out:Electricity (inc PV)": 37.93,
    "out:Total Energy Use Pre PV": 60.51,
    "out:Total Energy Use Post PV": 40.93,
    "out:Primary Energy": 69.29,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": -28.56,
    "out:Total CO2/m2": 21.9,
    "out:Total CO2 (tons)": 68.7,
    "out:Klimatpaverkan": -130.99,
    "out:Initial Cost/MSEK": 10.6175,
    "out:Running cost/(Apt SEK y)": 7642,
    "out:Running Cost over RSP/MSEK": 1.729,
    "out:LCP/MSEK": -0.657,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2984,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22142.15,
    "out:PV (% sold (El))": 31.58,
    "out:PV (kWh self-consumed)": 47983.27,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 527016,
    "out:EL kWh savings": -69108,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 390332,
    "out:DH kr savings": 479585,
    "out:El kr savings": -117484,
    "out:El kr sold": 28231,
    "out:El kr saved": 81572,
    "out:El kr return": 109803,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 9426,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.45,
    "out:Etvv": 0,
    "out:Ef": 37.93,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.63,
    "out:Electricity (inc PV)": 37.11,
    "out:Total Energy Use Pre PV": 58.63,
    "out:Total Energy Use Post PV": 39.11,
    "out:Primary Energy": 67.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": -29.75,
    "out:Total CO2/m2": 20.71,
    "out:Total CO2 (tons)": 64.96,
    "out:Klimatpaverkan": -134.73,
    "out:Initial Cost/MSEK": 10.695875,
    "out:Running cost/(Apt SEK y)": 7202,
    "out:Running Cost over RSP/MSEK": 1.629,
    "out:LCP/MSEK": -0.636,
    "out:ROI% old": 13.44,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2931.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 22590.6,
    "out:PV (% sold (El))": 32.21,
    "out:PV (kWh self-consumed)": 47534.81,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -66547,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398112,
    "out:DH kr savings": 482439,
    "out:El kr savings": -113130,
    "out:El kr sold": 28803,
    "out:El kr saved": 80809,
    "out:El kr return": 109612,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6701,
    "out:% savings (space heating)": 98.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.92,
    "out:Etvv": 0,
    "out:Ef": 37.11,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.37,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.73,
    "out:Electricity (inc PV)": 37.05,
    "out:Total Energy Use Pre PV": 57.73,
    "out:Total Energy Use Post PV": 39.05,
    "out:Primary Energy": 67.54,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.87,
    "out:CO2 Operational/m2": -30.6,
    "out:Total CO2/m2": 20.27,
    "out:Total CO2 (tons)": 63.59,
    "out:Klimatpaverkan": -136.1,
    "out:Initial Cost/MSEK": 10.776875,
    "out:Running cost/(Apt SEK y)": 7043,
    "out:Running Cost over RSP/MSEK": 1.593,
    "out:LCP/MSEK": -0.682,
    "out:ROI% old": 13.38,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2878.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1290,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.53,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23053.12,
    "out:PV (% sold (El))": 32.87,
    "out:PV (kWh self-consumed)": 47072.29,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -66334,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 399065,
    "out:DH kr savings": 482439,
    "out:El kr savings": -112767,
    "out:El kr sold": 29393,
    "out:El kr saved": 80023,
    "out:El kr return": 109416,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 7431,
    "out:% savings (space heating)": 98.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.23,
    "out:Etvv": 0,
    "out:Ef": 37.05,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.8,
    "out:Electricity (inc PV)": 36.22,
    "out:Total Energy Use Pre PV": 56.8,
    "out:Total Energy Use Post PV": 38.22,
    "out:Primary Energy": 65.72,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.87,
    "out:CO2 Operational/m2": -31.49,
    "out:Total CO2/m2": 19.39,
    "out:Total CO2 (tons)": 60.82,
    "out:Klimatpaverkan": -138.87,
    "out:Initial Cost/MSEK": 10.85525,
    "out:Running cost/(Apt SEK y)": 6881,
    "out:Running Cost over RSP/MSEK": 1.557,
    "out:LCP/MSEK": -0.724,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2824.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1295,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23529.96,
    "out:PV (% sold (El))": 33.55,
    "out:PV (kWh self-consumed)": 46595.45,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -63731,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 404098,
    "out:DH kr savings": 482439,
    "out:El kr savings": -108342,
    "out:El kr sold": 30001,
    "out:El kr saved": 79212,
    "out:El kr return": 109213,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5092,
    "out:% savings (space heating)": 98.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.76,
    "out:Etvv": 0,
    "out:Ef": 36.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.12,
    "out:Electricity (inc PV)": 50.95,
    "out:Total Energy Use Pre PV": 57.12,
    "out:Total Energy Use Post PV": 52.95,
    "out:Primary Energy": 92.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.75,
    "out:CO2 Operational/m2": 8,
    "out:Total CO2/m2": 33.75,
    "out:Total CO2 (tons)": 105.86,
    "out:Klimatpaverkan": -93.83,
    "out:Initial Cost/MSEK": 9.389,
    "out:Running cost/(Apt SEK y)": 10705,
    "out:Running Cost over RSP/MSEK": 2.402,
    "out:LCP/MSEK": -0.102,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3858.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1184,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -109948,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295528,
    "out:DH kr savings": 482439,
    "out:El kr savings": -186911,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6837,
    "out:% savings (space heating)": 98.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 50.95,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.19,
    "out:Electricity (inc PV)": 49.94,
    "out:Total Energy Use Pre PV": 55.19,
    "out:Total Energy Use Post PV": 50.94,
    "out:Primary Energy": 90.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.75,
    "out:CO2 Operational/m2": 7.51,
    "out:Total CO2/m2": 33.26,
    "out:Total CO2 (tons)": 104.34,
    "out:Klimatpaverkan": -95.35,
    "out:Initial Cost/MSEK": 9.467375,
    "out:Running cost/(Apt SEK y)": 10250,
    "out:Running Cost over RSP/MSEK": 2.299,
    "out:LCP/MSEK": -0.077,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3793.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1197,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -106774,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303778,
    "out:DH kr savings": 485294,
    "out:El kr savings": -181516,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 4615,
    "out:% savings (space heating)": 98.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.71,
    "out:Etvv": 0,
    "out:Ef": 49.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.67,
    "out:Electricity (inc PV)": 50.09,
    "out:Total Energy Use Pre PV": 55.67,
    "out:Total Energy Use Post PV": 52.09,
    "out:Primary Energy": 90.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 7.8,
    "out:Total CO2/m2": 33.97,
    "out:Total CO2 (tons)": 106.57,
    "out:Klimatpaverkan": -93.12,
    "out:Initial Cost/MSEK": 9.548375,
    "out:Running cost/(Apt SEK y)": 10437,
    "out:Running Cost over RSP/MSEK": 2.342,
    "out:LCP/MSEK": -0.201,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3756.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1192,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -107239,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300133,
    "out:DH kr savings": 482439,
    "out:El kr savings": -182306,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5547,
    "out:% savings (space heating)": 98.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.01,
    "out:Etvv": 0,
    "out:Ef": 50.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.71,
    "out:Electricity (inc PV)": 49.06,
    "out:Total Energy Use Pre PV": 53.71,
    "out:Total Energy Use Post PV": 50.06,
    "out:Primary Energy": 88.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.17,
    "out:CO2 Operational/m2": 7.32,
    "out:Total CO2/m2": 33.49,
    "out:Total CO2 (tons)": 105.05,
    "out:Klimatpaverkan": -94.64,
    "out:Initial Cost/MSEK": 9.62675,
    "out:Running cost/(Apt SEK y)": 9977,
    "out:Running Cost over RSP/MSEK": 2.238,
    "out:LCP/MSEK": -0.176,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3689.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1205,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -104026,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308450,
    "out:DH kr savings": 485294,
    "out:El kr savings": -176844,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 3612,
    "out:% savings (space heating)": 99.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.6,
    "out:Etvv": 0,
    "out:Ef": 49.06,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.12,
    "out:Electricity (inc PV)": 40.76,
    "out:Total Energy Use Pre PV": 57.12,
    "out:Total Energy Use Post PV": 42.76,
    "out:Primary Energy": 74.18,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.1,
    "out:CO2 Operational/m2": 3.26,
    "out:Total CO2/m2": 41.36,
    "out:Total CO2 (tons)": 129.74,
    "out:Klimatpaverkan": -69.95,
    "out:Initial Cost/MSEK": 10.0415,
    "out:Running cost/(Apt SEK y)": 8683,
    "out:Running Cost over RSP/MSEK": 1.955,
    "out:LCP/MSEK": -0.308,
    "out:ROI% old": 13.85,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3160.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2239.57,
    "out:PV (% sold (El))": 6.39,
    "out:PV (kWh self-consumed)": 32823.14,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -77989,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352714,
    "out:DH kr savings": 482439,
    "out:El kr savings": -132581,
    "out:El kr sold": 2855,
    "out:El kr saved": 55799,
    "out:El kr return": 58655,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6837,
    "out:% savings (space heating)": 98.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 40.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.19,
    "out:Electricity (inc PV)": 39.82,
    "out:Total Energy Use Pre PV": 55.19,
    "out:Total Energy Use Post PV": 40.82,
    "out:Primary Energy": 72.18,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.1,
    "out:CO2 Operational/m2": 2.5,
    "out:Total CO2/m2": 40.6,
    "out:Total CO2 (tons)": 127.37,
    "out:Klimatpaverkan": -72.32,
    "out:Initial Cost/MSEK": 10.119875,
    "out:Running cost/(Apt SEK y)": 8230,
    "out:Running Cost over RSP/MSEK": 1.853,
    "out:LCP/MSEK": -0.284,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3098.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1256,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2417.13,
    "out:PV (% sold (El))": 6.89,
    "out:PV (kWh self-consumed)": 32645.58,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -75050,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 360791,
    "out:DH kr savings": 485294,
    "out:El kr savings": -127585,
    "out:El kr sold": 3082,
    "out:El kr saved": 55497,
    "out:El kr return": 58579,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 4615,
    "out:% savings (space heating)": 98.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.71,
    "out:Etvv": 0,
    "out:Ef": 39.82,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.67,
    "out:Electricity (inc PV)": 39.96,
    "out:Total Energy Use Pre PV": 55.67,
    "out:Total Energy Use Post PV": 41.96,
    "out:Primary Energy": 72.63,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": 2.63,
    "out:Total CO2/m2": 41.15,
    "out:Total CO2 (tons)": 129.1,
    "out:Klimatpaverkan": -70.59,
    "out:Initial Cost/MSEK": 10.200875,
    "out:Running cost/(Apt SEK y)": 8419,
    "out:Running Cost over RSP/MSEK": 1.896,
    "out:LCP/MSEK": -0.408,
    "out:ROI% old": 13.71,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3063.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1250,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2521.65,
    "out:PV (% sold (El))": 7.19,
    "out:PV (kWh self-consumed)": 32541.06,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -75479,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 357339,
    "out:DH kr savings": 482439,
    "out:El kr savings": -128315,
    "out:El kr sold": 3215,
    "out:El kr saved": 55320,
    "out:El kr return": 58535,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5547,
    "out:% savings (space heating)": 98.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.01,
    "out:Etvv": 0,
    "out:Ef": 39.96,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.71,
    "out:Electricity (inc PV)": 39.02,
    "out:Total Energy Use Pre PV": 53.71,
    "out:Total Energy Use Post PV": 40.02,
    "out:Primary Energy": 70.65,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 38.52,
    "out:CO2 Operational/m2": 1.84,
    "out:Total CO2/m2": 40.36,
    "out:Total CO2 (tons)": 126.61,
    "out:Klimatpaverkan": -73.08,
    "out:Initial Cost/MSEK": 10.27925,
    "out:Running cost/(Apt SEK y)": 7961,
    "out:Running Cost over RSP/MSEK": 1.793,
    "out:LCP/MSEK": -0.383,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 3000.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1264,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 2718.72,
    "out:PV (% sold (El))": 7.75,
    "out:PV (kWh self-consumed)": 32343.99,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -72512,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365490,
    "out:DH kr savings": 485294,
    "out:El kr savings": -123270,
    "out:El kr sold": 3466,
    "out:El kr saved": 54985,
    "out:El kr return": 58451,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 3612,
    "out:% savings (space heating)": 99.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.6,
    "out:Etvv": 0,
    "out:Ef": 39.02,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.12,
    "out:Electricity (inc PV)": 36.75,
    "out:Total Energy Use Pre PV": 57.12,
    "out:Total Energy Use Post PV": 38.75,
    "out:Primary Energy": 66.96,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 19.28,
    "out:Total CO2 (tons)": 60.47,
    "out:Klimatpaverkan": -139.22,
    "out:Initial Cost/MSEK": 10.694,
    "out:Running cost/(Apt SEK y)": 6937,
    "out:Running Cost over RSP/MSEK": 1.57,
    "out:LCP/MSEK": -0.575,
    "out:ROI% old": 13.52,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2842.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1293,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23364.36,
    "out:PV (% sold (El))": 33.32,
    "out:PV (kWh self-consumed)": 46761.06,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -65406,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 401039,
    "out:DH kr savings": 482439,
    "out:El kr savings": -111190,
    "out:El kr sold": 29790,
    "out:El kr saved": 79494,
    "out:El kr return": 109283,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 6837,
    "out:% savings (space heating)": 98.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.16,
    "out:Etvv": 0,
    "out:Ef": 36.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.19,
    "out:Electricity (inc PV)": 35.92,
    "out:Total Energy Use Pre PV": 55.19,
    "out:Total Energy Use Post PV": 36.92,
    "out:Primary Energy": 65.15,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.46,
    "out:CO2 Operational/m2": -32.44,
    "out:Total CO2/m2": 18.02,
    "out:Total CO2 (tons)": 56.52,
    "out:Klimatpaverkan": -143.17,
    "out:Initial Cost/MSEK": 10.7725,
    "out:Running cost/(Apt SEK y)": 6489,
    "out:Running Cost over RSP/MSEK": 1.468,
    "out:LCP/MSEK": -0.552,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 405.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2788,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1306,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 23849.27,
    "out:PV (% sold (El))": 34.01,
    "out:PV (kWh self-consumed)": 46276.15,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -62790,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 408959,
    "out:DH kr savings": 485294,
    "out:El kr savings": -106743,
    "out:El kr sold": 30408,
    "out:El kr saved": 78669,
    "out:El kr return": 109077,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 4615,
    "out:% savings (space heating)": 98.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.71,
    "out:Etvv": 0,
    "out:Ef": 35.92,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.67,
    "out:Electricity (inc PV)": 36.04,
    "out:Total Energy Use Pre PV": 55.67,
    "out:Total Energy Use Post PV": 38.04,
    "out:Primary Energy": 65.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.87,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 18.29,
    "out:Total CO2 (tons)": 57.37,
    "out:Klimatpaverkan": -142.32,
    "out:Initial Cost/MSEK": 10.853375,
    "out:Running cost/(Apt SEK y)": 6680,
    "out:Running Cost over RSP/MSEK": 1.512,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 13.39,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2757.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1301,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 24125.06,
    "out:PV (% sold (El))": 34.4,
    "out:PV (kWh self-consumed)": 46000.36,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 530153,
    "out:EL kWh savings": -63173,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 405805,
    "out:DH kr savings": 482439,
    "out:El kr savings": -107394,
    "out:El kr sold": 30759,
    "out:El kr saved": 78201,
    "out:El kr return": 108960,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 5547,
    "out:% savings (space heating)": 98.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 1.01,
    "out:Etvv": 0,
    "out:Ef": 36.04,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.71,
    "out:Electricity (inc PV)": 35.2,
    "out:Total Energy Use Pre PV": 53.71,
    "out:Total Energy Use Post PV": 36.2,
    "out:Primary Energy": 63.77,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.87,
    "out:CO2 Operational/m2": -33.88,
    "out:Total CO2/m2": 16.99,
    "out:Total CO2 (tons)": 53.3,
    "out:Klimatpaverkan": -146.39,
    "out:Initial Cost/MSEK": 10.931875,
    "out:Running cost/(Apt SEK y)": 6227,
    "out:Running Cost over RSP/MSEK": 1.41,
    "out:LCP/MSEK": -0.653,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 36,
    "out:Energy use kWh/m2": 36,
    "out:Energy savings %": 419.4,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 1,
    "out:El bought/kWh/m2": 2700.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1314,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 24634.35,
    "out:PV (% sold (El))": 35.13,
    "out:PV (kWh self-consumed)": 45491.07,
    "out:PV (ratio sold/self-consumed)": 0.54,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 533290,
    "out:EL kWh savings": -60529,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 413803,
    "out:DH kr savings": 485294,
    "out:El kr savings": -102900,
    "out:El kr sold": 31409,
    "out:El kr saved": 77335,
    "out:El kr return": 108744,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 3612,
    "out:% savings (space heating)": 99.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 0.6,
    "out:Etvv": 0,
    "out:Ef": 35.2,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 123.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.68,
    "out:CO2 Operational/m2": 53.82,
    "out:Total CO2/m2": 55.5,
    "out:Total CO2 (tons)": 174.12,
    "out:Klimatpaverkan": -25.57,
    "out:Initial Cost/MSEK": 2.128,
    "out:Running cost/(Apt SEK y)": 44140,
    "out:Running Cost over RSP/MSEK": 10.007,
    "out:LCP/MSEK": -0.446,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 58259,
    "out:DH kr savings": 82785,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 119.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.68,
    "out:CO2 Operational/m2": 52.02,
    "out:Total CO2/m2": 53.7,
    "out:Total CO2 (tons)": 168.47,
    "out:Klimatpaverkan": -31.22,
    "out:Initial Cost/MSEK": 2.206375,
    "out:Running cost/(Apt SEK y)": 42719,
    "out:Running Cost over RSP/MSEK": 9.685,
    "out:LCP/MSEK": -0.202,
    "out:ROI% old": 12.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 106658,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72533,
    "out:DH kr savings": 97059,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 156.5,
    "out:Primary Energy": 121.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.1,
    "out:CO2 Operational/m2": 51.66,
    "out:Total CO2/m2": 53.76,
    "out:Total CO2 (tons)": 168.66,
    "out:Klimatpaverkan": -31.03,
    "out:Initial Cost/MSEK": 2.287375,
    "out:Running cost/(Apt SEK y)": 42434,
    "out:Running Cost over RSP/MSEK": 9.62,
    "out:LCP/MSEK": -0.219,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75387,
    "out:DH kr savings": 99913,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 150.5,
    "out:Primary Energy": 117.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.1,
    "out:CO2 Operational/m2": 49.5,
    "out:Total CO2/m2": 51.6,
    "out:Total CO2 (tons)": 161.88,
    "out:Klimatpaverkan": -37.81,
    "out:Initial Cost/MSEK": 2.36575,
    "out:Running cost/(Apt SEK y)": 40728,
    "out:Running Cost over RSP/MSEK": 9.233,
    "out:LCP/MSEK": 0.09,
    "out:ROI% old": 14.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 314,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 92515,
    "out:DH kr savings": 117041,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 156.33,
    "out:Primary Energy": 112.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.03,
    "out:CO2 Operational/m2": 27.81,
    "out:Total CO2/m2": 41.84,
    "out:Total CO2 (tons)": 131.26,
    "out:Klimatpaverkan": -68.43,
    "out:Initial Cost/MSEK": 2.7805,
    "out:Running cost/(Apt SEK y)": 42315,
    "out:Running Cost over RSP/MSEK": 9.604,
    "out:LCP/MSEK": -0.696,
    "out:ROI% old": 10.71,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111187,
    "out:DH kr savings": 82785,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 151.33,
    "out:Primary Energy": 108.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.03,
    "out:CO2 Operational/m2": 26.01,
    "out:Total CO2/m2": 40.04,
    "out:Total CO2 (tons)": 125.61,
    "out:Klimatpaverkan": -74.08,
    "out:Initial Cost/MSEK": 2.859,
    "out:Running cost/(Apt SEK y)": 40893,
    "out:Running Cost over RSP/MSEK": 9.282,
    "out:LCP/MSEK": -0.452,
    "out:ROI% old": 12.03,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 106658,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125461,
    "out:DH kr savings": 97059,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 150.33,
    "out:Primary Energy": 109.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 25.65,
    "out:Total CO2/m2": 40.1,
    "out:Total CO2 (tons)": 125.8,
    "out:Klimatpaverkan": -73.89,
    "out:Initial Cost/MSEK": 2.939875,
    "out:Running cost/(Apt SEK y)": 40609,
    "out:Running Cost over RSP/MSEK": 9.217,
    "out:LCP/MSEK": -0.468,
    "out:ROI% old": 12.01,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128315,
    "out:DH kr savings": 99913,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 144.33,
    "out:Primary Energy": 106.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 23.49,
    "out:Total CO2/m2": 37.94,
    "out:Total CO2 (tons)": 119.03,
    "out:Klimatpaverkan": -80.66,
    "out:Initial Cost/MSEK": 3.018375,
    "out:Running cost/(Apt SEK y)": 38903,
    "out:Running Cost over RSP/MSEK": 8.83,
    "out:LCP/MSEK": -0.16,
    "out:ROI% old": 13.53,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 145443,
    "out:DH kr savings": 117041,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 162.5,
    "out:Total Energy Use Post PV": 155.09,
    "out:Primary Energy": 110.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": -22.87,
    "out:Total CO2/m2": 3.52,
    "out:Total CO2 (tons)": 11.03,
    "out:Klimatpaverkan": -188.66,
    "out:Initial Cost/MSEK": 3.433125,
    "out:Running cost/(Apt SEK y)": 40716,
    "out:Running Cost over RSP/MSEK": 9.252,
    "out:LCP/MSEK": -0.996,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 314,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 90973,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157548,
    "out:DH kr savings": 82785,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 157.5,
    "out:Total Energy Use Post PV": 150.09,
    "out:Primary Energy": 106.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": -24.67,
    "out:Total CO2/m2": 1.72,
    "out:Total CO2 (tons)": 5.38,
    "out:Klimatpaverkan": -194.31,
    "out:Initial Cost/MSEK": 3.5115,
    "out:Running cost/(Apt SEK y)": 39295,
    "out:Running Cost over RSP/MSEK": 8.929,
    "out:LCP/MSEK": -0.752,
    "out:ROI% old": 11.23,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 106658,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171821,
    "out:DH kr savings": 97059,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 156.5,
    "out:Total Energy Use Post PV": 149.09,
    "out:Primary Energy": 107.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": -25.03,
    "out:Total CO2/m2": 1.77,
    "out:Total CO2 (tons)": 5.57,
    "out:Klimatpaverkan": -194.12,
    "out:Initial Cost/MSEK": 3.5925,
    "out:Running cost/(Apt SEK y)": 39011,
    "out:Running Cost over RSP/MSEK": 8.865,
    "out:LCP/MSEK": -0.768,
    "out:ROI% old": 11.23,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109795,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174676,
    "out:DH kr savings": 99913,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 143.09,
    "out:Primary Energy": 103.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": -27.19,
    "out:Total CO2/m2": -0.39,
    "out:Total CO2 (tons)": -1.21,
    "out:Klimatpaverkan": -200.9,
    "out:Initial Cost/MSEK": 3.670875,
    "out:Running cost/(Apt SEK y)": 37305,
    "out:Running Cost over RSP/MSEK": 8.477,
    "out:LCP/MSEK": -0.46,
    "out:ROI% old": 12.5,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 128617,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191804,
    "out:DH kr savings": 117041,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 154.5,
    "out:Primary Energy": 120.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.68,
    "out:CO2 Operational/m2": 50.94,
    "out:Total CO2/m2": 52.62,
    "out:Total CO2 (tons)": 165.08,
    "out:Klimatpaverkan": -34.61,
    "out:Initial Cost/MSEK": 2.2045,
    "out:Running cost/(Apt SEK y)": 41866,
    "out:Running Cost over RSP/MSEK": 9.491,
    "out:LCP/MSEK": -0.007,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 281,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 81097,
    "out:DH kr savings": 105623,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 148.5,
    "out:Primary Energy": 116.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.68,
    "out:CO2 Operational/m2": 48.78,
    "out:Total CO2/m2": 50.46,
    "out:Total CO2 (tons)": 158.31,
    "out:Klimatpaverkan": -41.38,
    "out:Initial Cost/MSEK": 2.283,
    "out:Running cost/(Apt SEK y)": 40160,
    "out:Running Cost over RSP/MSEK": 9.104,
    "out:LCP/MSEK": 0.302,
    "out:ROI% old": 16.17,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 98225,
    "out:DH kr savings": 122751,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 118.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.1,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 160.75,
    "out:Klimatpaverkan": -38.94,
    "out:Initial Cost/MSEK": 2.363875,
    "out:Running cost/(Apt SEK y)": 40444,
    "out:Running Cost over RSP/MSEK": 9.168,
    "out:LCP/MSEK": 0.156,
    "out:ROI% old": 15.23,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95370,
    "out:DH kr savings": 119896,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 143.5,
    "out:Primary Energy": 114.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.1,
    "out:CO2 Operational/m2": 46.98,
    "out:Total CO2/m2": 49.08,
    "out:Total CO2 (tons)": 153.98,
    "out:Klimatpaverkan": -45.71,
    "out:Initial Cost/MSEK": 2.442375,
    "out:Running cost/(Apt SEK y)": 38738,
    "out:Running Cost over RSP/MSEK": 8.781,
    "out:LCP/MSEK": 0.465,
    "out:ROI% old": 17.01,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112498,
    "out:DH kr savings": 137024,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 148.33,
    "out:Primary Energy": 109.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.03,
    "out:CO2 Operational/m2": 24.93,
    "out:Total CO2/m2": 38.96,
    "out:Total CO2 (tons)": 122.23,
    "out:Klimatpaverkan": -77.46,
    "out:Initial Cost/MSEK": 2.857125,
    "out:Running cost/(Apt SEK y)": 40041,
    "out:Running Cost over RSP/MSEK": 9.088,
    "out:LCP/MSEK": -0.257,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134025,
    "out:DH kr savings": 105623,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 142.33,
    "out:Primary Energy": 105.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.03,
    "out:CO2 Operational/m2": 22.77,
    "out:Total CO2/m2": 36.8,
    "out:Total CO2 (tons)": 115.45,
    "out:Klimatpaverkan": -84.24,
    "out:Initial Cost/MSEK": 2.9355,
    "out:Running cost/(Apt SEK y)": 38335,
    "out:Running Cost over RSP/MSEK": 8.701,
    "out:LCP/MSEK": 0.052,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 383,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151153,
    "out:DH kr savings": 122751,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 106.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 37.58,
    "out:Total CO2 (tons)": 117.9,
    "out:Klimatpaverkan": -81.79,
    "out:Initial Cost/MSEK": 3.0165,
    "out:Running cost/(Apt SEK y)": 38619,
    "out:Running Cost over RSP/MSEK": 8.766,
    "out:LCP/MSEK": -0.093,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148298,
    "out:DH kr savings": 119896,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 137.33,
    "out:Primary Energy": 103.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.45,
    "out:CO2 Operational/m2": 20.97,
    "out:Total CO2/m2": 35.42,
    "out:Total CO2 (tons)": 111.12,
    "out:Klimatpaverkan": -88.57,
    "out:Initial Cost/MSEK": 3.094875,
    "out:Running cost/(Apt SEK y)": 36913,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": 0.215,
    "out:ROI% old": 15.28,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165426,
    "out:DH kr savings": 137024,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 147.09,
    "out:Primary Energy": 106.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": -25.75,
    "out:Total CO2/m2": 0.64,
    "out:Total CO2 (tons)": 1.99,
    "out:Klimatpaverkan": -197.7,
    "out:Initial Cost/MSEK": 3.509625,
    "out:Running cost/(Apt SEK y)": 38442,
    "out:Running Cost over RSP/MSEK": 8.736,
    "out:LCP/MSEK": -0.556,
    "out:ROI% old": 12.02,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 116069,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180385,
    "out:DH kr savings": 105623,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 148.5,
    "out:Total Energy Use Post PV": 141.09,
    "out:Primary Energy": 102.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.39,
    "out:CO2 Operational/m2": -27.91,
    "out:Total CO2/m2": -1.52,
    "out:Total CO2 (tons)": -4.78,
    "out:Klimatpaverkan": -204.47,
    "out:Initial Cost/MSEK": 3.588,
    "out:Running cost/(Apt SEK y)": 36736,
    "out:Running Cost over RSP/MSEK": 8.348,
    "out:LCP/MSEK": -0.248,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 134891,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 197513,
    "out:DH kr savings": 122751,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 104.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": -0.75,
    "out:Total CO2 (tons)": -2.34,
    "out:Klimatpaverkan": -202.03,
    "out:Initial Cost/MSEK": 3.669,
    "out:Running cost/(Apt SEK y)": 37020,
    "out:Running Cost over RSP/MSEK": 8.413,
    "out:LCP/MSEK": -0.393,
    "out:ROI% old": 12.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194658,
    "out:DH kr savings": 119896,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 310541,
    "out:% savings (space heating)": 22.58,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 127.87,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.45,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 136.09,
    "out:Primary Energy": 100.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": -29.71,
    "out:Total CO2/m2": -2.91,
    "out:Total CO2 (tons)": -9.11,
    "out:Klimatpaverkan": -208.8,
    "out:Initial Cost/MSEK": 3.747375,
    "out:Running cost/(Apt SEK y)": 35315,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -0.084,
    "out:ROI% old": 13.96,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.84,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211786,
    "out:DH kr savings": 137024,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 293163,
    "out:% savings (space heating)": 26.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 122.33,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 116.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.95,
    "out:CO2 Operational/m2": 50.22,
    "out:Total CO2/m2": 54.17,
    "out:Total CO2 (tons)": 169.94,
    "out:Klimatpaverkan": -29.75,
    "out:Initial Cost/MSEK": 2.642125,
    "out:Running cost/(Apt SEK y)": 41297,
    "out:Running Cost over RSP/MSEK": 9.362,
    "out:LCP/MSEK": -0.315,
    "out:ROI% old": 12.58,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 297,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86806,
    "out:DH kr savings": 111332,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 112.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.95,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 52.37,
    "out:Total CO2 (tons)": 164.3,
    "out:Klimatpaverkan": -35.39,
    "out:Initial Cost/MSEK": 2.7205,
    "out:Running cost/(Apt SEK y)": 39876,
    "out:Running Cost over RSP/MSEK": 9.039,
    "out:LCP/MSEK": -0.071,
    "out:ROI% old": 13.91,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101079,
    "out:DH kr savings": 125605,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 113.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 47.7,
    "out:Total CO2/m2": 52.07,
    "out:Total CO2 (tons)": 163.36,
    "out:Klimatpaverkan": -36.33,
    "out:Initial Cost/MSEK": 2.8015,
    "out:Running cost/(Apt SEK y)": 39307,
    "out:Running Cost over RSP/MSEK": 8.91,
    "out:LCP/MSEK": -0.023,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 106789,
    "out:DH kr savings": 131315,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 110.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 157.71,
    "out:Klimatpaverkan": -41.98,
    "out:Initial Cost/MSEK": 2.879875,
    "out:Running cost/(Apt SEK y)": 37885,
    "out:Running Cost over RSP/MSEK": 8.588,
    "out:LCP/MSEK": 0.221,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121062,
    "out:DH kr savings": 145588,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 146.33,
    "out:Primary Energy": 105.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 24.21,
    "out:Total CO2/m2": 40.51,
    "out:Total CO2 (tons)": 127.09,
    "out:Klimatpaverkan": -72.6,
    "out:Initial Cost/MSEK": 3.294625,
    "out:Running cost/(Apt SEK y)": 39472,
    "out:Running Cost over RSP/MSEK": 8.959,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 11.84,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139734,
    "out:DH kr savings": 111332,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 101.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 38.71,
    "out:Total CO2 (tons)": 121.44,
    "out:Klimatpaverkan": -78.25,
    "out:Initial Cost/MSEK": 3.373125,
    "out:Running cost/(Apt SEK y)": 38050,
    "out:Running Cost over RSP/MSEK": 8.636,
    "out:LCP/MSEK": -0.321,
    "out:ROI% old": 12.93,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154007,
    "out:DH kr savings": 125605,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 139.33,
    "out:Primary Energy": 102.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.72,
    "out:CO2 Operational/m2": 21.69,
    "out:Total CO2/m2": 38.41,
    "out:Total CO2 (tons)": 120.5,
    "out:Klimatpaverkan": -79.19,
    "out:Initial Cost/MSEK": 3.454,
    "out:Running cost/(Apt SEK y)": 37482,
    "out:Running Cost over RSP/MSEK": 8.507,
    "out:LCP/MSEK": -0.273,
    "out:ROI% old": 13.16,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159717,
    "out:DH kr savings": 131315,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 98.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.72,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 36.61,
    "out:Total CO2 (tons)": 114.85,
    "out:Klimatpaverkan": -84.84,
    "out:Initial Cost/MSEK": 3.5325,
    "out:Running cost/(Apt SEK y)": 36060,
    "out:Running Cost over RSP/MSEK": 8.185,
    "out:LCP/MSEK": -0.029,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173990,
    "out:DH kr savings": 145588,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 145.09,
    "out:Primary Energy": 102.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.66,
    "out:CO2 Operational/m2": -26.47,
    "out:Total CO2/m2": 2.18,
    "out:Total CO2 (tons)": 6.85,
    "out:Klimatpaverkan": -192.84,
    "out:Initial Cost/MSEK": 3.947125,
    "out:Running cost/(Apt SEK y)": 37873,
    "out:Running Cost over RSP/MSEK": 8.607,
    "out:LCP/MSEK": -0.865,
    "out:ROI% old": 11.16,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122343,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186094,
    "out:DH kr savings": 111332,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 99.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.66,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 0.38,
    "out:Total CO2 (tons)": 1.21,
    "out:Klimatpaverkan": -198.48,
    "out:Initial Cost/MSEK": 4.025625,
    "out:Running cost/(Apt SEK y)": 36452,
    "out:Running Cost over RSP/MSEK": 8.284,
    "out:LCP/MSEK": -0.621,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 138028,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200368,
    "out:DH kr savings": 125605,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 138.09,
    "out:Primary Energy": 100.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -28.99,
    "out:Total CO2/m2": 0.08,
    "out:Total CO2 (tons)": 0.26,
    "out:Klimatpaverkan": -199.43,
    "out:Initial Cost/MSEK": 4.1065,
    "out:Running cost/(Apt SEK y)": 35883,
    "out:Running Cost over RSP/MSEK": 8.155,
    "out:LCP/MSEK": -0.573,
    "out:ROI% old": 12.29,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144302,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206077,
    "out:DH kr savings": 131315,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 96.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": -1.72,
    "out:Total CO2 (tons)": -5.38,
    "out:Klimatpaverkan": -205.07,
    "out:Initial Cost/MSEK": 4.185,
    "out:Running cost/(Apt SEK y)": 34462,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": -0.329,
    "out:ROI% old": 13.16,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220350,
    "out:DH kr savings": 145588,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 143.5,
    "out:Primary Energy": 112.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.95,
    "out:CO2 Operational/m2": 46.98,
    "out:Total CO2/m2": 50.93,
    "out:Total CO2 (tons)": 159.78,
    "out:Klimatpaverkan": -39.91,
    "out:Initial Cost/MSEK": 2.718625,
    "out:Running cost/(Apt SEK y)": 38738,
    "out:Running Cost over RSP/MSEK": 8.781,
    "out:LCP/MSEK": 0.189,
    "out:ROI% old": 15.28,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112498,
    "out:DH kr savings": 137024,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 109.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.95,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 49.13,
    "out:Total CO2 (tons)": 154.13,
    "out:Klimatpaverkan": -45.56,
    "out:Initial Cost/MSEK": 2.797125,
    "out:Running cost/(Apt SEK y)": 37317,
    "out:Running Cost over RSP/MSEK": 8.459,
    "out:LCP/MSEK": 0.433,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126772,
    "out:DH kr savings": 151298,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 110.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 49.55,
    "out:Total CO2 (tons)": 155.45,
    "out:Klimatpaverkan": -44.24,
    "out:Initial Cost/MSEK": 2.878,
    "out:Running cost/(Apt SEK y)": 37317,
    "out:Running Cost over RSP/MSEK": 8.459,
    "out:LCP/MSEK": 0.352,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126772,
    "out:DH kr savings": 151298,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 107.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 47.75,
    "out:Total CO2 (tons)": 149.8,
    "out:Klimatpaverkan": -49.89,
    "out:Initial Cost/MSEK": 2.9565,
    "out:Running cost/(Apt SEK y)": 35895,
    "out:Running Cost over RSP/MSEK": 8.136,
    "out:LCP/MSEK": 0.596,
    "out:ROI% old": 17.17,
    "out:Payback discounted": 7,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141045,
    "out:DH kr savings": 165571,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 137.33,
    "out:Primary Energy": 101.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 20.97,
    "out:Total CO2/m2": 37.27,
    "out:Total CO2 (tons)": 116.92,
    "out:Klimatpaverkan": -82.77,
    "out:Initial Cost/MSEK": 3.371125,
    "out:Running cost/(Apt SEK y)": 36913,
    "out:Running Cost over RSP/MSEK": 8.378,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 14.03,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165426,
    "out:DH kr savings": 137024,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 98.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 35.47,
    "out:Total CO2 (tons)": 111.28,
    "out:Klimatpaverkan": -88.41,
    "out:Initial Cost/MSEK": 3.449625,
    "out:Running cost/(Apt SEK y)": 35492,
    "out:Running Cost over RSP/MSEK": 8.056,
    "out:LCP/MSEK": 0.183,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179699,
    "out:DH kr savings": 151298,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 99.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.72,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 35.89,
    "out:Total CO2 (tons)": 112.59,
    "out:Klimatpaverkan": -87.1,
    "out:Initial Cost/MSEK": 3.5305,
    "out:Running cost/(Apt SEK y)": 35492,
    "out:Running Cost over RSP/MSEK": 8.056,
    "out:LCP/MSEK": 0.102,
    "out:ROI% old": 14.7,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179699,
    "out:DH kr savings": 151298,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 95.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.72,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 34.09,
    "out:Total CO2 (tons)": 106.95,
    "out:Klimatpaverkan": -92.74,
    "out:Initial Cost/MSEK": 3.609,
    "out:Running cost/(Apt SEK y)": 34070,
    "out:Running Cost over RSP/MSEK": 7.733,
    "out:LCP/MSEK": 0.347,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193973,
    "out:DH kr savings": 165571,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 136.09,
    "out:Primary Energy": 99.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.66,
    "out:CO2 Operational/m2": -29.71,
    "out:Total CO2/m2": -1.06,
    "out:Total CO2 (tons)": -3.31,
    "out:Klimatpaverkan": -203,
    "out:Initial Cost/MSEK": 4.02375,
    "out:Running cost/(Apt SEK y)": 35315,
    "out:Running Cost over RSP/MSEK": 8.026,
    "out:LCP/MSEK": -0.361,
    "out:ROI% old": 13,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 471,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150576,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211786,
    "out:DH kr savings": 137024,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 95.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.66,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": -2.85,
    "out:Total CO2 (tons)": -8.96,
    "out:Klimatpaverkan": -208.65,
    "out:Initial Cost/MSEK": 4.102125,
    "out:Running cost/(Apt SEK y)": 33893,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -0.117,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226060,
    "out:DH kr savings": 151298,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 97.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": -2.44,
    "out:Total CO2 (tons)": -7.64,
    "out:Klimatpaverkan": -207.33,
    "out:Initial Cost/MSEK": 4.183125,
    "out:Running cost/(Apt SEK y)": 33893,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -0.198,
    "out:ROI% old": 13.61,
    "out:Payback discounted": 9,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226060,
    "out:DH kr savings": 151298,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 277089,
    "out:% savings (space heating)": 30.92,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 117.2,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.27,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 93.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": -4.24,
    "out:Total CO2 (tons)": -13.29,
    "out:Klimatpaverkan": -212.98,
    "out:Initial Cost/MSEK": 4.2615,
    "out:Running cost/(Apt SEK y)": 32472,
    "out:Running Cost over RSP/MSEK": 7.381,
    "out:LCP/MSEK": 0.047,
    "out:ROI% old": 14.44,
    "out:Payback discounted": 8,
    "out:Atemp": 3137,
    "out:Umean": 0.7,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240333,
    "out:DH kr savings": 165571,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 261233,
    "out:% savings (space heating)": 34.87,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 112.15,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 114.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.87,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 59.01,
    "out:Total CO2 (tons)": 185.13,
    "out:Klimatpaverkan": -14.56,
    "out:Initial Cost/MSEK": 3.900875,
    "out:Running cost/(Apt SEK y)": 40444,
    "out:Running Cost over RSP/MSEK": 9.168,
    "out:LCP/MSEK": -1.381,
    "out:ROI% old": 9.23,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95370,
    "out:DH kr savings": 119896,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 110.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.87,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 57.21,
    "out:Total CO2 (tons)": 179.48,
    "out:Klimatpaverkan": -20.21,
    "out:Initial Cost/MSEK": 3.979375,
    "out:Running cost/(Apt SEK y)": 39023,
    "out:Running Cost over RSP/MSEK": 8.846,
    "out:LCP/MSEK": -1.136,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109643,
    "out:DH kr savings": 134170,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 111.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.29,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 56.91,
    "out:Total CO2 (tons)": 178.54,
    "out:Klimatpaverkan": -21.15,
    "out:Initial Cost/MSEK": 4.06025,
    "out:Running cost/(Apt SEK y)": 38454,
    "out:Running Cost over RSP/MSEK": 8.717,
    "out:LCP/MSEK": -1.088,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115353,
    "out:DH kr savings": 139879,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 107.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.29,
    "out:CO2 Operational/m2": 44.82,
    "out:Total CO2/m2": 55.11,
    "out:Total CO2 (tons)": 172.89,
    "out:Klimatpaverkan": -26.8,
    "out:Initial Cost/MSEK": 4.13875,
    "out:Running cost/(Apt SEK y)": 37033,
    "out:Running Cost over RSP/MSEK": 8.394,
    "out:LCP/MSEK": -0.844,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 169398,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 129626,
    "out:DH kr savings": 154152,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 102.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.22,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 45.35,
    "out:Total CO2 (tons)": 142.27,
    "out:Klimatpaverkan": -57.42,
    "out:Initial Cost/MSEK": 4.5535,
    "out:Running cost/(Apt SEK y)": 38619,
    "out:Running Cost over RSP/MSEK": 8.766,
    "out:LCP/MSEK": -1.63,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148298,
    "out:DH kr savings": 119896,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 99.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.22,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 43.55,
    "out:Total CO2 (tons)": 136.63,
    "out:Klimatpaverkan": -63.06,
    "out:Initial Cost/MSEK": 4.631875,
    "out:Running cost/(Apt SEK y)": 37198,
    "out:Running Cost over RSP/MSEK": 8.443,
    "out:LCP/MSEK": -1.386,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162571,
    "out:DH kr savings": 134170,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 100.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.64,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 43.25,
    "out:Total CO2 (tons)": 135.68,
    "out:Klimatpaverkan": -64.01,
    "out:Initial Cost/MSEK": 4.712875,
    "out:Running cost/(Apt SEK y)": 36629,
    "out:Running Cost over RSP/MSEK": 8.314,
    "out:LCP/MSEK": -1.338,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168281,
    "out:DH kr savings": 139879,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 131.33,
    "out:Primary Energy": 96.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.64,
    "out:CO2 Operational/m2": 18.81,
    "out:Total CO2/m2": 41.45,
    "out:Total CO2 (tons)": 130.04,
    "out:Klimatpaverkan": -69.65,
    "out:Initial Cost/MSEK": 4.79125,
    "out:Running cost/(Apt SEK y)": 35207,
    "out:Running Cost over RSP/MSEK": 7.991,
    "out:LCP/MSEK": -1.094,
    "out:ROI% old": 11.02,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 474,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169398,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182554,
    "out:DH kr savings": 154152,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 100.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.58,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": 7.03,
    "out:Total CO2 (tons)": 22.04,
    "out:Klimatpaverkan": -177.65,
    "out:Initial Cost/MSEK": 5.206,
    "out:Running cost/(Apt SEK y)": 37020,
    "out:Running Cost over RSP/MSEK": 8.413,
    "out:LCP/MSEK": -1.93,
    "out:ROI% old": 8.99,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 131754,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194658,
    "out:DH kr savings": 119896,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 97.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.58,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": 5.23,
    "out:Total CO2 (tons)": 16.39,
    "out:Klimatpaverkan": -183.3,
    "out:Initial Cost/MSEK": 5.284375,
    "out:Running cost/(Apt SEK y)": 35599,
    "out:Running Cost over RSP/MSEK": 8.09,
    "out:LCP/MSEK": -1.686,
    "out:ROI% old": 9.73,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208932,
    "out:DH kr savings": 134170,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 98.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 4.92,
    "out:Total CO2 (tons)": 15.45,
    "out:Klimatpaverkan": -184.24,
    "out:Initial Cost/MSEK": 5.365375,
    "out:Running cost/(Apt SEK y)": 35030,
    "out:Running Cost over RSP/MSEK": 7.961,
    "out:LCP/MSEK": -1.638,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153713,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214641,
    "out:DH kr savings": 139879,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 137.5,
    "out:Total Energy Use Post PV": 130.09,
    "out:Primary Energy": 94.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35,
    "out:CO2 Operational/m2": -31.87,
    "out:Total CO2/m2": 3.12,
    "out:Total CO2 (tons)": 9.8,
    "out:Klimatpaverkan": -189.89,
    "out:Initial Cost/MSEK": 5.44375,
    "out:Running cost/(Apt SEK y)": 33609,
    "out:Running Cost over RSP/MSEK": 7.639,
    "out:LCP/MSEK": -1.394,
    "out:ROI% old": 10.63,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169398,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228914,
    "out:DH kr savings": 154152,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 110.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.87,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 174.97,
    "out:Klimatpaverkan": -24.72,
    "out:Initial Cost/MSEK": 3.9775,
    "out:Running cost/(Apt SEK y)": 37885,
    "out:Running Cost over RSP/MSEK": 8.588,
    "out:LCP/MSEK": -0.876,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 121062,
    "out:DH kr savings": 145588,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 107.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.87,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 169.32,
    "out:Klimatpaverkan": -30.37,
    "out:Initial Cost/MSEK": 4.055875,
    "out:Running cost/(Apt SEK y)": 36464,
    "out:Running Cost over RSP/MSEK": 8.265,
    "out:LCP/MSEK": -0.632,
    "out:ROI% old": 12.06,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135336,
    "out:DH kr savings": 159862,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 108.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.29,
    "out:CO2 Operational/m2": 44.1,
    "out:Total CO2/m2": 54.39,
    "out:Total CO2 (tons)": 170.63,
    "out:Klimatpaverkan": -29.06,
    "out:Initial Cost/MSEK": 4.136875,
    "out:Running cost/(Apt SEK y)": 36464,
    "out:Running Cost over RSP/MSEK": 8.265,
    "out:LCP/MSEK": -0.713,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135336,
    "out:DH kr savings": 159862,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 105.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.29,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 52.59,
    "out:Total CO2 (tons)": 164.99,
    "out:Klimatpaverkan": -34.7,
    "out:Initial Cost/MSEK": 4.21525,
    "out:Running cost/(Apt SEK y)": 35042,
    "out:Running Cost over RSP/MSEK": 7.943,
    "out:LCP/MSEK": -0.469,
    "out:ROI% old": 12.7,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149609,
    "out:DH kr savings": 174135,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 99.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.22,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 42.11,
    "out:Total CO2 (tons)": 132.11,
    "out:Klimatpaverkan": -67.58,
    "out:Initial Cost/MSEK": 4.63,
    "out:Running cost/(Apt SEK y)": 36060,
    "out:Running Cost over RSP/MSEK": 8.185,
    "out:LCP/MSEK": -1.126,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173990,
    "out:DH kr savings": 145588,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 96.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.22,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 40.31,
    "out:Total CO2 (tons)": 126.46,
    "out:Klimatpaverkan": -73.23,
    "out:Initial Cost/MSEK": 4.708375,
    "out:Running cost/(Apt SEK y)": 34639,
    "out:Running Cost over RSP/MSEK": 7.862,
    "out:LCP/MSEK": -0.882,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188263,
    "out:DH kr savings": 159862,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 129.33,
    "out:Primary Energy": 97.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.64,
    "out:CO2 Operational/m2": 18.09,
    "out:Total CO2/m2": 40.73,
    "out:Total CO2 (tons)": 127.78,
    "out:Klimatpaverkan": -71.91,
    "out:Initial Cost/MSEK": 4.789375,
    "out:Running cost/(Apt SEK y)": 34639,
    "out:Running Cost over RSP/MSEK": 7.862,
    "out:LCP/MSEK": -0.963,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 490,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188263,
    "out:DH kr savings": 159862,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 93.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.64,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 38.93,
    "out:Total CO2 (tons)": 122.13,
    "out:Klimatpaverkan": -77.56,
    "out:Initial Cost/MSEK": 4.86775,
    "out:Running cost/(Apt SEK y)": 33217,
    "out:Running Cost over RSP/MSEK": 7.54,
    "out:LCP/MSEK": -0.719,
    "out:ROI% old": 12.18,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202537,
    "out:DH kr savings": 174135,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 97.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.58,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": 3.79,
    "out:Total CO2 (tons)": 11.87,
    "out:Klimatpaverkan": -187.82,
    "out:Initial Cost/MSEK": 5.2825,
    "out:Running cost/(Apt SEK y)": 34462,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": -1.426,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220350,
    "out:DH kr savings": 145588,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 93.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.58,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 1.99,
    "out:Total CO2 (tons)": 6.23,
    "out:Klimatpaverkan": -193.46,
    "out:Initial Cost/MSEK": 5.361,
    "out:Running cost/(Apt SEK y)": 33040,
    "out:Running Cost over RSP/MSEK": 7.51,
    "out:LCP/MSEK": -1.182,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234624,
    "out:DH kr savings": 159862,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 135.5,
    "out:Total Energy Use Post PV": 128.09,
    "out:Primary Energy": 95.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35,
    "out:CO2 Operational/m2": -32.59,
    "out:Total CO2/m2": 2.4,
    "out:Total CO2 (tons)": 7.54,
    "out:Klimatpaverkan": -192.15,
    "out:Initial Cost/MSEK": 5.441875,
    "out:Running cost/(Apt SEK y)": 33040,
    "out:Running Cost over RSP/MSEK": 7.51,
    "out:LCP/MSEK": -1.263,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 175672,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234624,
    "out:DH kr savings": 159862,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 267342,
    "out:% savings (space heating)": 33.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 114.1,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.31,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 91.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 0.61,
    "out:Total CO2 (tons)": 1.9,
    "out:Klimatpaverkan": -197.79,
    "out:Initial Cost/MSEK": 5.520375,
    "out:Running cost/(Apt SEK y)": 31619,
    "out:Running Cost over RSP/MSEK": 7.187,
    "out:LCP/MSEK": -1.019,
    "out:ROI% old": 11.65,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.66,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248897,
    "out:DH kr savings": 174135,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 251928,
    "out:% savings (space heating)": 37.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.18,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 111.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 59.71,
    "out:Total CO2 (tons)": 187.31,
    "out:Klimatpaverkan": -12.38,
    "out:Initial Cost/MSEK": 4.5385,
    "out:Running cost/(Apt SEK y)": 39023,
    "out:Running Cost over RSP/MSEK": 8.846,
    "out:LCP/MSEK": -1.696,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 363,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109643,
    "out:DH kr savings": 134170,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 107.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 58.27,
    "out:Total CO2 (tons)": 182.79,
    "out:Klimatpaverkan": -16.9,
    "out:Initial Cost/MSEK": 4.616875,
    "out:Running cost/(Apt SEK y)": 37885,
    "out:Running Cost over RSP/MSEK": 8.588,
    "out:LCP/MSEK": -1.516,
    "out:ROI% old": 9.6,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 396,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121062,
    "out:DH kr savings": 145588,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 108.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 57.97,
    "out:Total CO2 (tons)": 181.85,
    "out:Klimatpaverkan": -17.84,
    "out:Initial Cost/MSEK": 4.697875,
    "out:Running cost/(Apt SEK y)": 37317,
    "out:Running Cost over RSP/MSEK": 8.459,
    "out:LCP/MSEK": -1.468,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126772,
    "out:DH kr savings": 151298,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 105.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 56.17,
    "out:Total CO2 (tons)": 176.2,
    "out:Klimatpaverkan": -23.49,
    "out:Initial Cost/MSEK": 4.77625,
    "out:Running cost/(Apt SEK y)": 35895,
    "out:Running Cost over RSP/MSEK": 8.136,
    "out:LCP/MSEK": -1.224,
    "out:ROI% old": 10.63,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141045,
    "out:DH kr savings": 165571,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 99.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.72,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 46.05,
    "out:Total CO2 (tons)": 144.45,
    "out:Klimatpaverkan": -55.24,
    "out:Initial Cost/MSEK": 5.191,
    "out:Running cost/(Apt SEK y)": 37198,
    "out:Running Cost over RSP/MSEK": 8.443,
    "out:LCP/MSEK": -1.945,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 416,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162571,
    "out:DH kr savings": 134170,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 96.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.72,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 44.61,
    "out:Total CO2 (tons)": 139.94,
    "out:Klimatpaverkan": -59.75,
    "out:Initial Cost/MSEK": 5.2695,
    "out:Running cost/(Apt SEK y)": 36060,
    "out:Running Cost over RSP/MSEK": 8.185,
    "out:LCP/MSEK": -1.766,
    "out:ROI% old": 9.5,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173990,
    "out:DH kr savings": 145588,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 97.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.14,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 44.31,
    "out:Total CO2 (tons)": 138.99,
    "out:Klimatpaverkan": -60.7,
    "out:Initial Cost/MSEK": 5.350375,
    "out:Running cost/(Apt SEK y)": 35492,
    "out:Running Cost over RSP/MSEK": 8.056,
    "out:LCP/MSEK": -1.717,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179699,
    "out:DH kr savings": 151298,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.14,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 42.51,
    "out:Total CO2 (tons)": 133.35,
    "out:Klimatpaverkan": -66.34,
    "out:Initial Cost/MSEK": 5.428875,
    "out:Running cost/(Apt SEK y)": 34070,
    "out:Running Cost over RSP/MSEK": 7.733,
    "out:LCP/MSEK": -1.473,
    "out:ROI% old": 10.41,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193973,
    "out:DH kr savings": 165571,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 97.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": 7.72,
    "out:Total CO2 (tons)": 24.22,
    "out:Klimatpaverkan": -175.47,
    "out:Initial Cost/MSEK": 5.843625,
    "out:Running cost/(Apt SEK y)": 35599,
    "out:Running Cost over RSP/MSEK": 8.09,
    "out:LCP/MSEK": -2.245,
    "out:ROI% old": 8.8,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 147439,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208932,
    "out:DH kr savings": 134170,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 94.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": 6.28,
    "out:Total CO2 (tons)": 19.7,
    "out:Klimatpaverkan": -179.99,
    "out:Initial Cost/MSEK": 5.922,
    "out:Running cost/(Apt SEK y)": 34462,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": -2.066,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 159987,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220350,
    "out:DH kr savings": 145588,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 95.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.49,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 5.98,
    "out:Total CO2 (tons)": 18.76,
    "out:Klimatpaverkan": -180.93,
    "out:Initial Cost/MSEK": 6.003,
    "out:Running cost/(Apt SEK y)": 33893,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -2.017,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226060,
    "out:DH kr savings": 151298,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 91.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.49,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 4.18,
    "out:Total CO2 (tons)": 13.11,
    "out:Klimatpaverkan": -186.58,
    "out:Initial Cost/MSEK": 6.081375,
    "out:Running cost/(Apt SEK y)": 32472,
    "out:Running Cost over RSP/MSEK": 7.381,
    "out:LCP/MSEK": -1.773,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240333,
    "out:DH kr savings": 165571,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 107.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 56.83,
    "out:Total CO2 (tons)": 178.27,
    "out:Klimatpaverkan": -21.42,
    "out:Initial Cost/MSEK": 4.615,
    "out:Running cost/(Apt SEK y)": 36748,
    "out:Running Cost over RSP/MSEK": 8.33,
    "out:LCP/MSEK": -1.256,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 172535,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132481,
    "out:DH kr savings": 157007,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 104.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.37,
    "out:CO2 Operational/m2": 42.66,
    "out:Total CO2/m2": 55.03,
    "out:Total CO2 (tons)": 172.63,
    "out:Klimatpaverkan": -27.06,
    "out:Initial Cost/MSEK": 4.6935,
    "out:Running cost/(Apt SEK y)": 35327,
    "out:Running Cost over RSP/MSEK": 8.007,
    "out:LCP/MSEK": -1.012,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 188220,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146754,
    "out:DH kr savings": 171280,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 105.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 55.09,
    "out:Total CO2 (tons)": 172.81,
    "out:Klimatpaverkan": -26.88,
    "out:Initial Cost/MSEK": 4.774375,
    "out:Running cost/(Apt SEK y)": 35042,
    "out:Running Cost over RSP/MSEK": 7.943,
    "out:LCP/MSEK": -1.028,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149609,
    "out:DH kr savings": 174135,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 102.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.78,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 53.65,
    "out:Total CO2 (tons)": 168.3,
    "out:Klimatpaverkan": -31.39,
    "out:Initial Cost/MSEK": 4.852875,
    "out:Running cost/(Apt SEK y)": 33905,
    "out:Running Cost over RSP/MSEK": 7.684,
    "out:LCP/MSEK": -0.849,
    "out:ROI% old": 11.79,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161028,
    "out:DH kr savings": 185554,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 96.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.72,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 43.17,
    "out:Total CO2 (tons)": 135.42,
    "out:Klimatpaverkan": -64.27,
    "out:Initial Cost/MSEK": 5.267625,
    "out:Running cost/(Apt SEK y)": 34923,
    "out:Running Cost over RSP/MSEK": 7.927,
    "out:LCP/MSEK": -1.506,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 172535,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185409,
    "out:DH kr savings": 157007,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 125.33,
    "out:Primary Energy": 93.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.72,
    "out:CO2 Operational/m2": 16.65,
    "out:Total CO2/m2": 41.37,
    "out:Total CO2 (tons)": 129.77,
    "out:Klimatpaverkan": -69.92,
    "out:Initial Cost/MSEK": 5.346,
    "out:Running cost/(Apt SEK y)": 33502,
    "out:Running Cost over RSP/MSEK": 7.604,
    "out:LCP/MSEK": -1.261,
    "out:ROI% old": 10.91,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 523,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188220,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199682,
    "out:DH kr savings": 171280,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 94.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.14,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 41.43,
    "out:Total CO2 (tons)": 129.96,
    "out:Klimatpaverkan": -69.73,
    "out:Initial Cost/MSEK": 5.427,
    "out:Running cost/(Apt SEK y)": 33217,
    "out:Running Cost over RSP/MSEK": 7.54,
    "out:LCP/MSEK": -1.278,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202537,
    "out:DH kr savings": 174135,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 91.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.14,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 39.99,
    "out:Total CO2 (tons)": 125.44,
    "out:Klimatpaverkan": -74.25,
    "out:Initial Cost/MSEK": 5.505375,
    "out:Running cost/(Apt SEK y)": 32080,
    "out:Running Cost over RSP/MSEK": 7.282,
    "out:LCP/MSEK": -1.098,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213955,
    "out:DH kr savings": 185554,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 94.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 4.84,
    "out:Total CO2 (tons)": 15.18,
    "out:Klimatpaverkan": -184.51,
    "out:Initial Cost/MSEK": 5.920125,
    "out:Running cost/(Apt SEK y)": 33325,
    "out:Running Cost over RSP/MSEK": 7.574,
    "out:LCP/MSEK": -1.806,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 172535,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231769,
    "out:DH kr savings": 157007,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 124.09,
    "out:Primary Energy": 90.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": -34.03,
    "out:Total CO2/m2": 3.04,
    "out:Total CO2 (tons)": 9.54,
    "out:Klimatpaverkan": -190.15,
    "out:Initial Cost/MSEK": 5.9985,
    "out:Running cost/(Apt SEK y)": 31903,
    "out:Running Cost over RSP/MSEK": 7.252,
    "out:LCP/MSEK": -1.561,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 188220,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246042,
    "out:DH kr savings": 171280,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.9,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 92.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.49,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 3.1,
    "out:Total CO2 (tons)": 9.72,
    "out:Klimatpaverkan": -189.97,
    "out:Initial Cost/MSEK": 6.0795,
    "out:Running cost/(Apt SEK y)": 31619,
    "out:Running Cost over RSP/MSEK": 7.187,
    "out:LCP/MSEK": -1.578,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248897,
    "out:DH kr savings": 174135,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 253792,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 109.78,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.19,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 88.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.49,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 1.66,
    "out:Total CO2 (tons)": 5.21,
    "out:Klimatpaverkan": -194.48,
    "out:Initial Cost/MSEK": 6.157875,
    "out:Running cost/(Apt SEK y)": 30482,
    "out:Running Cost over RSP/MSEK": 6.929,
    "out:LCP/MSEK": -1.398,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 611,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260316,
    "out:DH kr savings": 185554,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 238994,
    "out:% savings (space heating)": 40.42,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 105.06,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 103.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.63,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 58.02,
    "out:Total CO2 (tons)": 182.01,
    "out:Klimatpaverkan": -17.68,
    "out:Initial Cost/MSEK": 5.052625,
    "out:Running cost/(Apt SEK y)": 35895,
    "out:Running Cost over RSP/MSEK": 8.136,
    "out:LCP/MSEK": -1.5,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 454,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141045,
    "out:DH kr savings": 165571,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 100.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.63,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 56.58,
    "out:Total CO2 (tons)": 177.49,
    "out:Klimatpaverkan": -22.2,
    "out:Initial Cost/MSEK": 5.131,
    "out:Running cost/(Apt SEK y)": 34758,
    "out:Running Cost over RSP/MSEK": 7.878,
    "out:LCP/MSEK": -1.32,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194494,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152464,
    "out:DH kr savings": 176990,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 100.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.05,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 56.28,
    "out:Total CO2 (tons)": 176.55,
    "out:Klimatpaverkan": -23.14,
    "out:Initial Cost/MSEK": 5.212,
    "out:Running cost/(Apt SEK y)": 34190,
    "out:Running Cost over RSP/MSEK": 7.749,
    "out:LCP/MSEK": -1.272,
    "out:ROI% old": 10.8,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158173,
    "out:DH kr savings": 182699,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 97.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.05,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 54.84,
    "out:Total CO2 (tons)": 172.03,
    "out:Klimatpaverkan": -27.66,
    "out:Initial Cost/MSEK": 5.290375,
    "out:Running cost/(Apt SEK y)": 33052,
    "out:Running Cost over RSP/MSEK": 7.491,
    "out:LCP/MSEK": -1.092,
    "out:ROI% old": 11.34,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169592,
    "out:DH kr savings": 194118,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 92.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.99,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 44.36,
    "out:Total CO2 (tons)": 139.15,
    "out:Klimatpaverkan": -60.54,
    "out:Initial Cost/MSEK": 5.705125,
    "out:Running cost/(Apt SEK y)": 34070,
    "out:Running Cost over RSP/MSEK": 7.733,
    "out:LCP/MSEK": -1.75,
    "out:ROI% old": 9.9,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193973,
    "out:DH kr savings": 165571,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 89.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.99,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 42.92,
    "out:Total CO2 (tons)": 134.63,
    "out:Klimatpaverkan": -65.06,
    "out:Initial Cost/MSEK": 5.783625,
    "out:Running cost/(Apt SEK y)": 32933,
    "out:Running Cost over RSP/MSEK": 7.475,
    "out:LCP/MSEK": -1.57,
    "out:ROI% old": 10.41,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194494,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205391,
    "out:DH kr savings": 176990,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 89.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.41,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 42.62,
    "out:Total CO2 (tons)": 133.69,
    "out:Klimatpaverkan": -66,
    "out:Initial Cost/MSEK": 5.8645,
    "out:Running cost/(Apt SEK y)": 32364,
    "out:Running Cost over RSP/MSEK": 7.346,
    "out:LCP/MSEK": -1.522,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211101,
    "out:DH kr savings": 182699,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 86.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.41,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 41.18,
    "out:Total CO2 (tons)": 129.17,
    "out:Klimatpaverkan": -70.52,
    "out:Initial Cost/MSEK": 5.943,
    "out:Running cost/(Apt SEK y)": 31227,
    "out:Running Cost over RSP/MSEK": 7.088,
    "out:LCP/MSEK": -1.342,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222519,
    "out:DH kr savings": 194118,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 89.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.34,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 6.03,
    "out:Total CO2 (tons)": 18.92,
    "out:Klimatpaverkan": -180.77,
    "out:Initial Cost/MSEK": 6.357625,
    "out:Running cost/(Apt SEK y)": 32472,
    "out:Running Cost over RSP/MSEK": 7.381,
    "out:LCP/MSEK": -2.049,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 181946,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240333,
    "out:DH kr savings": 165571,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 87.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.34,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 4.59,
    "out:Total CO2 (tons)": 14.4,
    "out:Klimatpaverkan": -185.29,
    "out:Initial Cost/MSEK": 6.436125,
    "out:Running cost/(Apt SEK y)": 31334,
    "out:Running Cost over RSP/MSEK": 7.123,
    "out:LCP/MSEK": -1.87,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194494,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251752,
    "out:DH kr savings": 176990,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 87.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.76,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 4.29,
    "out:Total CO2 (tons)": 13.46,
    "out:Klimatpaverkan": -186.23,
    "out:Initial Cost/MSEK": 6.517,
    "out:Running cost/(Apt SEK y)": 30766,
    "out:Running Cost over RSP/MSEK": 6.993,
    "out:LCP/MSEK": -1.822,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257461,
    "out:DH kr savings": 182699,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 84.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.76,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 2.85,
    "out:Total CO2 (tons)": 8.94,
    "out:Klimatpaverkan": -190.75,
    "out:Initial Cost/MSEK": 6.5955,
    "out:Running cost/(Apt SEK y)": 29629,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -1.642,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268880,
    "out:DH kr savings": 194118,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 99.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.63,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 55.14,
    "out:Total CO2 (tons)": 172.97,
    "out:Klimatpaverkan": -26.72,
    "out:Initial Cost/MSEK": 5.129125,
    "out:Running cost/(Apt SEK y)": 33621,
    "out:Running Cost over RSP/MSEK": 7.62,
    "out:LCP/MSEK": -1.06,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163882,
    "out:DH kr savings": 188408,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 96.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.63,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 53.34,
    "out:Total CO2 (tons)": 167.33,
    "out:Klimatpaverkan": -32.36,
    "out:Initial Cost/MSEK": 5.207625,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -0.816,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 97.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.05,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 167.51,
    "out:Klimatpaverkan": -32.18,
    "out:Initial Cost/MSEK": 5.2885,
    "out:Running cost/(Apt SEK y)": 31915,
    "out:Running Cost over RSP/MSEK": 7.233,
    "out:LCP/MSEK": -0.833,
    "out:ROI% old": 12.04,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181010,
    "out:DH kr savings": 205536,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 94.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.05,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 51.96,
    "out:Total CO2 (tons)": 163,
    "out:Klimatpaverkan": -36.69,
    "out:Initial Cost/MSEK": 5.367,
    "out:Running cost/(Apt SEK y)": 30778,
    "out:Running Cost over RSP/MSEK": 6.975,
    "out:LCP/MSEK": -0.653,
    "out:ROI% old": 12.55,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192429,
    "out:DH kr savings": 216955,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 88.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.99,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 41.48,
    "out:Total CO2 (tons)": 130.12,
    "out:Klimatpaverkan": -69.57,
    "out:Initial Cost/MSEK": 5.781625,
    "out:Running cost/(Apt SEK y)": 31796,
    "out:Running Cost over RSP/MSEK": 7.217,
    "out:LCP/MSEK": -1.31,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216810,
    "out:DH kr savings": 188408,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 85.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.99,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 39.68,
    "out:Total CO2 (tons)": 124.47,
    "out:Klimatpaverkan": -75.22,
    "out:Initial Cost/MSEK": 5.860125,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -1.066,
    "out:ROI% old": 11.69,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 86.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.41,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 39.74,
    "out:Total CO2 (tons)": 124.66,
    "out:Klimatpaverkan": -75.03,
    "out:Initial Cost/MSEK": 5.941,
    "out:Running cost/(Apt SEK y)": 30090,
    "out:Running Cost over RSP/MSEK": 6.83,
    "out:LCP/MSEK": -1.082,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233938,
    "out:DH kr savings": 205536,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 83.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.41,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 38.3,
    "out:Total CO2 (tons)": 120.14,
    "out:Klimatpaverkan": -79.55,
    "out:Initial Cost/MSEK": 6.0195,
    "out:Running cost/(Apt SEK y)": 28953,
    "out:Running Cost over RSP/MSEK": 6.572,
    "out:LCP/MSEK": -0.903,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245357,
    "out:DH kr savings": 216955,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 86.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.34,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 3.15,
    "out:Total CO2 (tons)": 9.88,
    "out:Klimatpaverkan": -189.81,
    "out:Initial Cost/MSEK": 6.43425,
    "out:Running cost/(Apt SEK y)": 30197,
    "out:Running Cost over RSP/MSEK": 6.864,
    "out:LCP/MSEK": -1.61,
    "out:ROI% old": 10.71,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263170,
    "out:DH kr savings": 188408,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 83.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.34,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 1.35,
    "out:Total CO2 (tons)": 4.24,
    "out:Klimatpaverkan": -195.45,
    "out:Initial Cost/MSEK": 6.512625,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -1.366,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 84.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.76,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 1.41,
    "out:Total CO2 (tons)": 4.42,
    "out:Klimatpaverkan": -195.27,
    "out:Initial Cost/MSEK": 6.593625,
    "out:Running cost/(Apt SEK y)": 28491,
    "out:Running Cost over RSP/MSEK": 6.477,
    "out:LCP/MSEK": -1.382,
    "out:ROI% old": 11.29,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280298,
    "out:DH kr savings": 205536,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 219208,
    "out:% savings (space heating)": 45.35,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 98.75,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.66,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 81.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.76,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": -0.03,
    "out:Total CO2 (tons)": -0.1,
    "out:Klimatpaverkan": -199.79,
    "out:Initial Cost/MSEK": 6.672,
    "out:Running cost/(Apt SEK y)": 27354,
    "out:Running Cost over RSP/MSEK": 6.219,
    "out:LCP/MSEK": -1.202,
    "out:ROI% old": 11.71,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 701,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291717,
    "out:DH kr savings": 216955,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 205983,
    "out:% savings (space heating)": 48.65,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 94.54,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 101.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.56,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 62.86,
    "out:Total CO2 (tons)": 197.19,
    "out:Klimatpaverkan": -2.5,
    "out:Initial Cost/MSEK": 6.311375,
    "out:Running cost/(Apt SEK y)": 35042,
    "out:Running Cost over RSP/MSEK": 7.943,
    "out:LCP/MSEK": -2.565,
    "out:ROI% old": 8.48,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149609,
    "out:DH kr savings": 174135,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 98.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.56,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 61.42,
    "out:Total CO2 (tons)": 192.67,
    "out:Klimatpaverkan": -7.02,
    "out:Initial Cost/MSEK": 6.389875,
    "out:Running cost/(Apt SEK y)": 33905,
    "out:Running Cost over RSP/MSEK": 7.684,
    "out:LCP/MSEK": -2.386,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161028,
    "out:DH kr savings": 185554,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 98.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.97,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 61.12,
    "out:Total CO2 (tons)": 191.73,
    "out:Klimatpaverkan": -7.96,
    "out:Initial Cost/MSEK": 6.47075,
    "out:Running cost/(Apt SEK y)": 33337,
    "out:Running Cost over RSP/MSEK": 7.555,
    "out:LCP/MSEK": -2.337,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166737,
    "out:DH kr savings": 191263,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 95.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.97,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 59.68,
    "out:Total CO2 (tons)": 187.21,
    "out:Klimatpaverkan": -12.48,
    "out:Initial Cost/MSEK": 6.54925,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -2.158,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 89.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.91,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 49.2,
    "out:Total CO2 (tons)": 154.33,
    "out:Klimatpaverkan": -45.36,
    "out:Initial Cost/MSEK": 6.964,
    "out:Running cost/(Apt SEK y)": 33217,
    "out:Running Cost over RSP/MSEK": 7.54,
    "out:LCP/MSEK": -2.815,
    "out:ROI% old": 8.51,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202537,
    "out:DH kr savings": 174135,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 87.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.91,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 47.76,
    "out:Total CO2 (tons)": 149.82,
    "out:Klimatpaverkan": -49.87,
    "out:Initial Cost/MSEK": 7.042375,
    "out:Running cost/(Apt SEK y)": 32080,
    "out:Running Cost over RSP/MSEK": 7.282,
    "out:LCP/MSEK": -2.635,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213955,
    "out:DH kr savings": 185554,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 87.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.33,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 47.46,
    "out:Total CO2 (tons)": 148.87,
    "out:Klimatpaverkan": -50.82,
    "out:Initial Cost/MSEK": 7.123375,
    "out:Running cost/(Apt SEK y)": 31512,
    "out:Running Cost over RSP/MSEK": 7.152,
    "out:LCP/MSEK": -2.587,
    "out:ROI% old": 9.1,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219665,
    "out:DH kr savings": 191263,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 84.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.33,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 46.02,
    "out:Total CO2 (tons)": 144.36,
    "out:Klimatpaverkan": -55.33,
    "out:Initial Cost/MSEK": 7.20175,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -2.407,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 87.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.26,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 10.87,
    "out:Total CO2 (tons)": 34.1,
    "out:Klimatpaverkan": -165.59,
    "out:Initial Cost/MSEK": 7.6165,
    "out:Running cost/(Apt SEK y)": 31619,
    "out:Running Cost over RSP/MSEK": 7.187,
    "out:LCP/MSEK": -3.115,
    "out:ROI% old": 8.44,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248897,
    "out:DH kr savings": 174135,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 84.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.26,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 9.43,
    "out:Total CO2 (tons)": 29.58,
    "out:Klimatpaverkan": -170.11,
    "out:Initial Cost/MSEK": 7.694875,
    "out:Running cost/(Apt SEK y)": 30482,
    "out:Running Cost over RSP/MSEK": 6.929,
    "out:LCP/MSEK": -2.935,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 611,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203905,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260316,
    "out:DH kr savings": 185554,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 85.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.68,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 9.13,
    "out:Total CO2 (tons)": 28.64,
    "out:Klimatpaverkan": -171.05,
    "out:Initial Cost/MSEK": 7.775875,
    "out:Running cost/(Apt SEK y)": 29913,
    "out:Running Cost over RSP/MSEK": 6.8,
    "out:LCP/MSEK": -2.887,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266025,
    "out:DH kr savings": 191263,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 82.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.68,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 7.69,
    "out:Total CO2 (tons)": 24.12,
    "out:Klimatpaverkan": -175.57,
    "out:Initial Cost/MSEK": 7.85425,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -2.707,
    "out:ROI% old": 9.36,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 97.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.56,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 59.62,
    "out:Total CO2 (tons)": 187.03,
    "out:Klimatpaverkan": -12.66,
    "out:Initial Cost/MSEK": 6.388,
    "out:Running cost/(Apt SEK y)": 32484,
    "out:Running Cost over RSP/MSEK": 7.362,
    "out:LCP/MSEK": -2.061,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175301,
    "out:DH kr savings": 199827,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 94.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.56,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 58.18,
    "out:Total CO2 (tons)": 182.51,
    "out:Klimatpaverkan": -17.18,
    "out:Initial Cost/MSEK": 6.466375,
    "out:Running cost/(Apt SEK y)": 31347,
    "out:Running Cost over RSP/MSEK": 7.104,
    "out:LCP/MSEK": -1.881,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186720,
    "out:DH kr savings": 211246,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 95.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.97,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 58.24,
    "out:Total CO2 (tons)": 182.7,
    "out:Klimatpaverkan": -16.99,
    "out:Initial Cost/MSEK": 6.547375,
    "out:Running cost/(Apt SEK y)": 31062,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -1.898,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189574,
    "out:DH kr savings": 214100,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 92.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.97,
    "out:CO2 Operational/m2": 35.82,
    "out:Total CO2/m2": 56.8,
    "out:Total CO2 (tons)": 178.18,
    "out:Klimatpaverkan": -21.51,
    "out:Initial Cost/MSEK": 6.62575,
    "out:Running cost/(Apt SEK y)": 29925,
    "out:Running Cost over RSP/MSEK": 6.781,
    "out:LCP/MSEK": -1.718,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247823,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200993,
    "out:DH kr savings": 225519,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 86.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.91,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 45.96,
    "out:Total CO2 (tons)": 144.17,
    "out:Klimatpaverkan": -55.52,
    "out:Initial Cost/MSEK": 7.0405,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 6.959,
    "out:LCP/MSEK": -2.311,
    "out:ROI% old": 9.6,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228229,
    "out:DH kr savings": 199827,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 83.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.91,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 44.52,
    "out:Total CO2 (tons)": 139.65,
    "out:Klimatpaverkan": -60.04,
    "out:Initial Cost/MSEK": 7.118875,
    "out:Running cost/(Apt SEK y)": 29522,
    "out:Running Cost over RSP/MSEK": 6.701,
    "out:LCP/MSEK": -2.131,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239647,
    "out:DH kr savings": 211246,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 84.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.33,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 44.58,
    "out:Total CO2 (tons)": 139.84,
    "out:Klimatpaverkan": -59.85,
    "out:Initial Cost/MSEK": 7.199875,
    "out:Running cost/(Apt SEK y)": 29237,
    "out:Running Cost over RSP/MSEK": 6.636,
    "out:LCP/MSEK": -2.147,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242502,
    "out:DH kr savings": 214100,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 106.33,
    "out:Primary Energy": 81.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.33,
    "out:CO2 Operational/m2": 9.81,
    "out:Total CO2/m2": 43.14,
    "out:Total CO2 (tons)": 135.32,
    "out:Klimatpaverkan": -64.37,
    "out:Initial Cost/MSEK": 7.27825,
    "out:Running cost/(Apt SEK y)": 28100,
    "out:Running Cost over RSP/MSEK": 6.378,
    "out:LCP/MSEK": -1.968,
    "out:ROI% old": 10.42,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 247823,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253921,
    "out:DH kr savings": 225519,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 84.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.26,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 7.63,
    "out:Total CO2 (tons)": 23.94,
    "out:Klimatpaverkan": -175.75,
    "out:Initial Cost/MSEK": 7.693,
    "out:Running cost/(Apt SEK y)": 29060,
    "out:Running Cost over RSP/MSEK": 6.606,
    "out:LCP/MSEK": -2.611,
    "out:ROI% old": 9.44,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274589,
    "out:DH kr savings": 199827,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 81.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.26,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 6.19,
    "out:Total CO2 (tons)": 19.42,
    "out:Klimatpaverkan": -180.27,
    "out:Initial Cost/MSEK": 7.7715,
    "out:Running cost/(Apt SEK y)": 27923,
    "out:Running Cost over RSP/MSEK": 6.348,
    "out:LCP/MSEK": -2.431,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286008,
    "out:DH kr savings": 211246,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 82.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.68,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 6.25,
    "out:Total CO2 (tons)": 19.61,
    "out:Klimatpaverkan": -180.08,
    "out:Initial Cost/MSEK": 7.852375,
    "out:Running cost/(Apt SEK y)": 27639,
    "out:Running Cost over RSP/MSEK": 6.284,
    "out:LCP/MSEK": -2.447,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288862,
    "out:DH kr savings": 214100,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 209020,
    "out:% savings (space heating)": 47.89,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.5,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 105.09,
    "out:Primary Energy": 79.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.68,
    "out:CO2 Operational/m2": -40.87,
    "out:Total CO2/m2": 4.81,
    "out:Total CO2 (tons)": 15.09,
    "out:Klimatpaverkan": -184.6,
    "out:Initial Cost/MSEK": 7.930875,
    "out:Running cost/(Apt SEK y)": 26501,
    "out:Running Cost over RSP/MSEK": 6.026,
    "out:LCP/MSEK": -2.268,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247823,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300281,
    "out:DH kr savings": 225519,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 196257,
    "out:% savings (space heating)": 51.07,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 91.44,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 106.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 59.97,
    "out:Total CO2 (tons)": 188.13,
    "out:Klimatpaverkan": -11.56,
    "out:Initial Cost/MSEK": 5.08875,
    "out:Running cost/(Apt SEK y)": 37317,
    "out:Running Cost over RSP/MSEK": 8.459,
    "out:LCP/MSEK": -1.859,
    "out:ROI% old": 9.07,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126772,
    "out:DH kr savings": 151298,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 103.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 58.53,
    "out:Total CO2 (tons)": 183.61,
    "out:Klimatpaverkan": -16.08,
    "out:Initial Cost/MSEK": 5.167125,
    "out:Running cost/(Apt SEK y)": 36180,
    "out:Running Cost over RSP/MSEK": 8.201,
    "out:LCP/MSEK": -1.679,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138190,
    "out:DH kr savings": 162716,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 104.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.21,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 58.23,
    "out:Total CO2 (tons)": 182.67,
    "out:Klimatpaverkan": -17.02,
    "out:Initial Cost/MSEK": 5.248125,
    "out:Running cost/(Apt SEK y)": 35611,
    "out:Running Cost over RSP/MSEK": 8.072,
    "out:LCP/MSEK": -1.631,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 462,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185083,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143900,
    "out:DH kr savings": 168426,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 101.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.21,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 56.43,
    "out:Total CO2 (tons)": 177.02,
    "out:Klimatpaverkan": -22.67,
    "out:Initial Cost/MSEK": 5.3265,
    "out:Running cost/(Apt SEK y)": 34190,
    "out:Running Cost over RSP/MSEK": 7.749,
    "out:LCP/MSEK": -1.387,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158173,
    "out:DH kr savings": 182699,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 95.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 145.27,
    "out:Klimatpaverkan": -54.42,
    "out:Initial Cost/MSEK": 5.74125,
    "out:Running cost/(Apt SEK y)": 35492,
    "out:Running Cost over RSP/MSEK": 8.056,
    "out:LCP/MSEK": -2.108,
    "out:ROI% old": 9.04,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179699,
    "out:DH kr savings": 151298,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 92.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 44.87,
    "out:Total CO2 (tons)": 140.75,
    "out:Klimatpaverkan": -58.94,
    "out:Initial Cost/MSEK": 5.81975,
    "out:Running cost/(Apt SEK y)": 34355,
    "out:Running Cost over RSP/MSEK": 7.798,
    "out:LCP/MSEK": -1.929,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191118,
    "out:DH kr savings": 162716,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 93.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 44.57,
    "out:Total CO2 (tons)": 139.81,
    "out:Klimatpaverkan": -59.88,
    "out:Initial Cost/MSEK": 5.900625,
    "out:Running cost/(Apt SEK y)": 33786,
    "out:Running Cost over RSP/MSEK": 7.669,
    "out:LCP/MSEK": -1.881,
    "out:ROI% old": 9.73,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 185083,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196827,
    "out:DH kr savings": 168426,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 89.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 42.77,
    "out:Total CO2 (tons)": 134.17,
    "out:Klimatpaverkan": -65.52,
    "out:Initial Cost/MSEK": 5.979125,
    "out:Running cost/(Apt SEK y)": 32364,
    "out:Running Cost over RSP/MSEK": 7.346,
    "out:LCP/MSEK": -1.636,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211101,
    "out:DH kr savings": 182699,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 93.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.49,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 7.98,
    "out:Total CO2 (tons)": 25.04,
    "out:Klimatpaverkan": -174.65,
    "out:Initial Cost/MSEK": 6.393875,
    "out:Running cost/(Apt SEK y)": 33893,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -2.408,
    "out:ROI% old": 8.9,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 166261,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226060,
    "out:DH kr savings": 151298,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 90.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.49,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 6.54,
    "out:Total CO2 (tons)": 20.52,
    "out:Klimatpaverkan": -179.17,
    "out:Initial Cost/MSEK": 6.47225,
    "out:Running cost/(Apt SEK y)": 32756,
    "out:Running Cost over RSP/MSEK": 7.445,
    "out:LCP/MSEK": -2.229,
    "out:ROI% old": 9.37,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237478,
    "out:DH kr savings": 162716,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 90.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.91,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 6.24,
    "out:Total CO2 (tons)": 19.58,
    "out:Klimatpaverkan": -180.11,
    "out:Initial Cost/MSEK": 6.55325,
    "out:Running cost/(Apt SEK y)": 32187,
    "out:Running Cost over RSP/MSEK": 7.316,
    "out:LCP/MSEK": -2.181,
    "out:ROI% old": 9.53,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 185083,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243188,
    "out:DH kr savings": 168426,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 87.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.91,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 4.44,
    "out:Total CO2 (tons)": 13.93,
    "out:Klimatpaverkan": -185.76,
    "out:Initial Cost/MSEK": 6.631625,
    "out:Running cost/(Apt SEK y)": 30766,
    "out:Running Cost over RSP/MSEK": 6.993,
    "out:LCP/MSEK": -1.936,
    "out:ROI% old": 10.11,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257461,
    "out:DH kr savings": 182699,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 103.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 57.09,
    "out:Total CO2 (tons)": 179.09,
    "out:Klimatpaverkan": -20.6,
    "out:Initial Cost/MSEK": 5.16525,
    "out:Running cost/(Apt SEK y)": 35042,
    "out:Running Cost over RSP/MSEK": 7.943,
    "out:LCP/MSEK": -1.419,
    "out:ROI% old": 10.36,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149609,
    "out:DH kr savings": 174135,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 100.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.79,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 55.29,
    "out:Total CO2 (tons)": 173.45,
    "out:Klimatpaverkan": -26.24,
    "out:Initial Cost/MSEK": 5.24375,
    "out:Running cost/(Apt SEK y)": 33621,
    "out:Running Cost over RSP/MSEK": 7.62,
    "out:LCP/MSEK": -1.175,
    "out:ROI% old": 11.08,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163882,
    "out:DH kr savings": 188408,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.21,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 173.63,
    "out:Klimatpaverkan": -26.06,
    "out:Initial Cost/MSEK": 5.324625,
    "out:Running cost/(Apt SEK y)": 33337,
    "out:Running Cost over RSP/MSEK": 7.555,
    "out:LCP/MSEK": -1.191,
    "out:ROI% old": 11.09,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166737,
    "out:DH kr savings": 191263,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 98.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.21,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 53.91,
    "out:Total CO2 (tons)": 169.12,
    "out:Klimatpaverkan": -30.57,
    "out:Initial Cost/MSEK": 5.403125,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -1.012,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 92.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 43.43,
    "out:Total CO2 (tons)": 136.24,
    "out:Klimatpaverkan": -63.45,
    "out:Initial Cost/MSEK": 5.817875,
    "out:Running cost/(Apt SEK y)": 33217,
    "out:Running Cost over RSP/MSEK": 7.54,
    "out:LCP/MSEK": -1.669,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202537,
    "out:DH kr savings": 174135,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 89.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.14,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 41.63,
    "out:Total CO2 (tons)": 130.59,
    "out:Klimatpaverkan": -69.1,
    "out:Initial Cost/MSEK": 5.89625,
    "out:Running cost/(Apt SEK y)": 31796,
    "out:Running Cost over RSP/MSEK": 7.217,
    "out:LCP/MSEK": -1.425,
    "out:ROI% old": 10.83,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216810,
    "out:DH kr savings": 188408,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 90.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 41.69,
    "out:Total CO2 (tons)": 130.78,
    "out:Klimatpaverkan": -68.91,
    "out:Initial Cost/MSEK": 5.97725,
    "out:Running cost/(Apt SEK y)": 31512,
    "out:Running Cost over RSP/MSEK": 7.152,
    "out:LCP/MSEK": -1.441,
    "out:ROI% old": 10.84,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219665,
    "out:DH kr savings": 191263,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.56,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 40.25,
    "out:Total CO2 (tons)": 126.26,
    "out:Klimatpaverkan": -73.43,
    "out:Initial Cost/MSEK": 6.055625,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -1.261,
    "out:ROI% old": 11.31,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.49,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 5.1,
    "out:Total CO2 (tons)": 16,
    "out:Klimatpaverkan": -183.69,
    "out:Initial Cost/MSEK": 6.470375,
    "out:Running cost/(Apt SEK y)": 31619,
    "out:Running Cost over RSP/MSEK": 7.187,
    "out:LCP/MSEK": -1.969,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248897,
    "out:DH kr savings": 174135,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 86.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.49,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 3.3,
    "out:Total CO2 (tons)": 10.36,
    "out:Klimatpaverkan": -189.33,
    "out:Initial Cost/MSEK": 6.54875,
    "out:Running cost/(Apt SEK y)": 30197,
    "out:Running Cost over RSP/MSEK": 6.864,
    "out:LCP/MSEK": -1.724,
    "out:ROI% old": 10.52,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263170,
    "out:DH kr savings": 188408,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.88,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.91,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 3.36,
    "out:Total CO2 (tons)": 10.54,
    "out:Klimatpaverkan": -189.15,
    "out:Initial Cost/MSEK": 6.62975,
    "out:Running cost/(Apt SEK y)": 29913,
    "out:Running Cost over RSP/MSEK": 6.8,
    "out:LCP/MSEK": -1.741,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266025,
    "out:DH kr savings": 191263,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 234905,
    "out:% savings (space heating)": 41.44,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 103.76,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 84.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.91,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 1.92,
    "out:Total CO2 (tons)": 6.03,
    "out:Klimatpaverkan": -193.66,
    "out:Initial Cost/MSEK": 6.708125,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -1.561,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 220966,
    "out:% savings (space heating)": 44.91,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.31,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 98.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.06,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 58.28,
    "out:Total CO2 (tons)": 182.83,
    "out:Klimatpaverkan": -16.86,
    "out:Initial Cost/MSEK": 5.602875,
    "out:Running cost/(Apt SEK y)": 34190,
    "out:Running Cost over RSP/MSEK": 7.749,
    "out:LCP/MSEK": -1.663,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 503,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158173,
    "out:DH kr savings": 182699,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 96.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.06,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 56.84,
    "out:Total CO2 (tons)": 178.31,
    "out:Klimatpaverkan": -21.38,
    "out:Initial Cost/MSEK": 5.68125,
    "out:Running cost/(Apt SEK y)": 33052,
    "out:Running Cost over RSP/MSEK": 7.491,
    "out:LCP/MSEK": -1.483,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169592,
    "out:DH kr savings": 194118,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 96.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 56.54,
    "out:Total CO2 (tons)": 177.37,
    "out:Klimatpaverkan": -22.32,
    "out:Initial Cost/MSEK": 5.76225,
    "out:Running cost/(Apt SEK y)": 32484,
    "out:Running Cost over RSP/MSEK": 7.362,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175301,
    "out:DH kr savings": 199827,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 93.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 55.1,
    "out:Total CO2 (tons)": 172.85,
    "out:Klimatpaverkan": -26.84,
    "out:Initial Cost/MSEK": 5.840625,
    "out:Running cost/(Apt SEK y)": 31347,
    "out:Running Cost over RSP/MSEK": 7.104,
    "out:LCP/MSEK": -1.256,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186720,
    "out:DH kr savings": 211246,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.41,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 44.62,
    "out:Total CO2 (tons)": 139.97,
    "out:Klimatpaverkan": -59.72,
    "out:Initial Cost/MSEK": 6.255375,
    "out:Running cost/(Apt SEK y)": 32364,
    "out:Running Cost over RSP/MSEK": 7.346,
    "out:LCP/MSEK": -1.913,
    "out:ROI% old": 9.92,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211101,
    "out:DH kr savings": 182699,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 85.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.41,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 43.18,
    "out:Total CO2 (tons)": 135.45,
    "out:Klimatpaverkan": -64.24,
    "out:Initial Cost/MSEK": 6.333875,
    "out:Running cost/(Apt SEK y)": 31227,
    "out:Running Cost over RSP/MSEK": 7.088,
    "out:LCP/MSEK": -1.733,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222519,
    "out:DH kr savings": 194118,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.83,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 42.88,
    "out:Total CO2 (tons)": 134.51,
    "out:Klimatpaverkan": -65.18,
    "out:Initial Cost/MSEK": 6.41475,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 6.959,
    "out:LCP/MSEK": -1.685,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228229,
    "out:DH kr savings": 199827,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 82.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.83,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 41.44,
    "out:Total CO2 (tons)": 129.99,
    "out:Klimatpaverkan": -69.7,
    "out:Initial Cost/MSEK": 6.49325,
    "out:Running cost/(Apt SEK y)": 29522,
    "out:Running Cost over RSP/MSEK": 6.701,
    "out:LCP/MSEK": -1.505,
    "out:ROI% old": 10.97,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239647,
    "out:DH kr savings": 211246,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 85.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.76,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 6.29,
    "out:Total CO2 (tons)": 19.73,
    "out:Klimatpaverkan": -179.96,
    "out:Initial Cost/MSEK": 6.907875,
    "out:Running cost/(Apt SEK y)": 30766,
    "out:Running Cost over RSP/MSEK": 6.993,
    "out:LCP/MSEK": -2.213,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 200768,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257461,
    "out:DH kr savings": 182699,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 82.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.76,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 4.85,
    "out:Total CO2 (tons)": 15.22,
    "out:Klimatpaverkan": -184.47,
    "out:Initial Cost/MSEK": 6.986375,
    "out:Running cost/(Apt SEK y)": 29629,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -2.033,
    "out:ROI% old": 10.13,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268880,
    "out:DH kr savings": 194118,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 83.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.18,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 4.55,
    "out:Total CO2 (tons)": 14.28,
    "out:Klimatpaverkan": -185.41,
    "out:Initial Cost/MSEK": 7.06725,
    "out:Running cost/(Apt SEK y)": 29060,
    "out:Running Cost over RSP/MSEK": 6.606,
    "out:LCP/MSEK": -1.985,
    "out:ROI% old": 10.27,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274589,
    "out:DH kr savings": 199827,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.18,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 3.11,
    "out:Total CO2 (tons)": 9.76,
    "out:Klimatpaverkan": -189.93,
    "out:Initial Cost/MSEK": 7.14575,
    "out:Running cost/(Apt SEK y)": 27923,
    "out:Running Cost over RSP/MSEK": 6.348,
    "out:LCP/MSEK": -1.805,
    "out:ROI% old": 10.68,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286008,
    "out:DH kr savings": 211246,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 95.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.06,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 55.04,
    "out:Total CO2 (tons)": 172.66,
    "out:Klimatpaverkan": -27.03,
    "out:Initial Cost/MSEK": 5.679375,
    "out:Running cost/(Apt SEK y)": 31631,
    "out:Running Cost over RSP/MSEK": 7.168,
    "out:LCP/MSEK": -1.159,
    "out:ROI% old": 11.37,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 229001,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183865,
    "out:DH kr savings": 208391,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.06,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 53.96,
    "out:Total CO2 (tons)": 169.27,
    "out:Klimatpaverkan": -30.42,
    "out:Initial Cost/MSEK": 5.757875,
    "out:Running cost/(Apt SEK y)": 30778,
    "out:Running Cost over RSP/MSEK": 6.975,
    "out:LCP/MSEK": -1.044,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192429,
    "out:DH kr savings": 216955,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 93.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 53.66,
    "out:Total CO2 (tons)": 168.33,
    "out:Klimatpaverkan": -31.36,
    "out:Initial Cost/MSEK": 5.83875,
    "out:Running cost/(Apt SEK y)": 30209,
    "out:Running Cost over RSP/MSEK": 6.846,
    "out:LCP/MSEK": -0.996,
    "out:ROI% old": 11.85,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198138,
    "out:DH kr savings": 222664,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 90.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 52.22,
    "out:Total CO2 (tons)": 163.81,
    "out:Klimatpaverkan": -35.88,
    "out:Initial Cost/MSEK": 5.91725,
    "out:Running cost/(Apt SEK y)": 29072,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -0.816,
    "out:ROI% old": 12.32,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209557,
    "out:DH kr savings": 234083,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 84.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.41,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 41.38,
    "out:Total CO2 (tons)": 129.81,
    "out:Klimatpaverkan": -69.88,
    "out:Initial Cost/MSEK": 6.331875,
    "out:Running cost/(Apt SEK y)": 29806,
    "out:Running Cost over RSP/MSEK": 6.765,
    "out:LCP/MSEK": -1.409,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 630,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229001,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236793,
    "out:DH kr savings": 208391,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.41,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 40.3,
    "out:Total CO2 (tons)": 126.42,
    "out:Klimatpaverkan": -73.27,
    "out:Initial Cost/MSEK": 6.410375,
    "out:Running cost/(Apt SEK y)": 28953,
    "out:Running Cost over RSP/MSEK": 6.572,
    "out:LCP/MSEK": -1.293,
    "out:ROI% old": 11.4,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245357,
    "out:DH kr savings": 216955,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 82.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.83,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 40,
    "out:Total CO2 (tons)": 125.48,
    "out:Klimatpaverkan": -74.21,
    "out:Initial Cost/MSEK": 6.49125,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 6.443,
    "out:LCP/MSEK": -1.245,
    "out:ROI% old": 11.55,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251066,
    "out:DH kr savings": 222664,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 79.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.83,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 38.56,
    "out:Total CO2 (tons)": 120.96,
    "out:Klimatpaverkan": -78.73,
    "out:Initial Cost/MSEK": 6.56975,
    "out:Running cost/(Apt SEK y)": 27247,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -1.066,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262485,
    "out:DH kr savings": 234083,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 82.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.76,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 3.05,
    "out:Total CO2 (tons)": 9.57,
    "out:Klimatpaverkan": -190.12,
    "out:Initial Cost/MSEK": 6.9845,
    "out:Running cost/(Apt SEK y)": 28207,
    "out:Running Cost over RSP/MSEK": 6.413,
    "out:LCP/MSEK": -1.709,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 677,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 229001,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283153,
    "out:DH kr savings": 208391,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.76,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 1.97,
    "out:Total CO2 (tons)": 6.18,
    "out:Klimatpaverkan": -193.51,
    "out:Initial Cost/MSEK": 7.062875,
    "out:Running cost/(Apt SEK y)": 27354,
    "out:Running Cost over RSP/MSEK": 6.219,
    "out:LCP/MSEK": -1.593,
    "out:ROI% old": 11.06,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 701,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291717,
    "out:DH kr savings": 216955,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.69,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 80.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.18,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 1.67,
    "out:Total CO2 (tons)": 5.24,
    "out:Klimatpaverkan": -194.45,
    "out:Initial Cost/MSEK": 7.143875,
    "out:Running cost/(Apt SEK y)": 26786,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -1.545,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297426,
    "out:DH kr savings": 222664,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 199804,
    "out:% savings (space heating)": 50.19,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 92.57,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 77.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.18,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 0.23,
    "out:Total CO2 (tons)": 0.72,
    "out:Klimatpaverkan": -198.97,
    "out:Initial Cost/MSEK": 7.22225,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 5.832,
    "out:LCP/MSEK": -1.366,
    "out:ROI% old": 11.58,
    "out:Payback discounted": 10,
    "out:Atemp": 3137,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308845,
    "out:DH kr savings": 234083,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 187460,
    "out:% savings (space heating)": 53.27,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.63,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 96.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.98,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 63.12,
    "out:Total CO2 (tons)": 198.01,
    "out:Klimatpaverkan": -1.68,
    "out:Initial Cost/MSEK": 6.861625,
    "out:Running cost/(Apt SEK y)": 33337,
    "out:Running Cost over RSP/MSEK": 7.555,
    "out:LCP/MSEK": -2.728,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166737,
    "out:DH kr savings": 191263,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.98,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 61.68,
    "out:Total CO2 (tons)": 193.49,
    "out:Klimatpaverkan": -6.2,
    "out:Initial Cost/MSEK": 6.940125,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -2.549,
    "out:ROI% old": 9.04,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 94.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.4,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 191.42,
    "out:Klimatpaverkan": -8.27,
    "out:Initial Cost/MSEK": 7.021,
    "out:Running cost/(Apt SEK y)": 31347,
    "out:Running Cost over RSP/MSEK": 7.104,
    "out:LCP/MSEK": -2.436,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186720,
    "out:DH kr savings": 211246,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 91.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.4,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 188.03,
    "out:Klimatpaverkan": -11.66,
    "out:Initial Cost/MSEK": 7.0995,
    "out:Running cost/(Apt SEK y)": 30494,
    "out:Running Cost over RSP/MSEK": 6.91,
    "out:LCP/MSEK": -2.321,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195284,
    "out:DH kr savings": 219810,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 85.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.33,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 49.46,
    "out:Total CO2 (tons)": 155.15,
    "out:Klimatpaverkan": -44.54,
    "out:Initial Cost/MSEK": 7.51425,
    "out:Running cost/(Apt SEK y)": 31512,
    "out:Running Cost over RSP/MSEK": 7.152,
    "out:LCP/MSEK": -2.978,
    "out:ROI% old": 8.62,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219665,
    "out:DH kr savings": 191263,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 82.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.33,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 150.64,
    "out:Klimatpaverkan": -49.05,
    "out:Initial Cost/MSEK": 7.592625,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -2.798,
    "out:ROI% old": 9.02,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 82.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 47.36,
    "out:Total CO2 (tons)": 148.56,
    "out:Klimatpaverkan": -51.13,
    "out:Initial Cost/MSEK": 7.673625,
    "out:Running cost/(Apt SEK y)": 29522,
    "out:Running Cost over RSP/MSEK": 6.701,
    "out:LCP/MSEK": -2.686,
    "out:ROI% old": 9.29,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 639,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239647,
    "out:DH kr savings": 211246,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 80.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 46.28,
    "out:Total CO2 (tons)": 145.18,
    "out:Klimatpaverkan": -54.51,
    "out:Initial Cost/MSEK": 7.752,
    "out:Running cost/(Apt SEK y)": 28669,
    "out:Running Cost over RSP/MSEK": 6.507,
    "out:LCP/MSEK": -2.571,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248211,
    "out:DH kr savings": 219810,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 83.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.68,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 11.13,
    "out:Total CO2 (tons)": 34.92,
    "out:Klimatpaverkan": -164.77,
    "out:Initial Cost/MSEK": 8.16675,
    "out:Running cost/(Apt SEK y)": 29913,
    "out:Running Cost over RSP/MSEK": 6.8,
    "out:LCP/MSEK": -3.278,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 210179,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266025,
    "out:DH kr savings": 191263,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 80.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.68,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 9.69,
    "out:Total CO2 (tons)": 30.4,
    "out:Klimatpaverkan": -169.29,
    "out:Initial Cost/MSEK": 8.245125,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -3.098,
    "out:ROI% old": 8.92,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 80.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 9.03,
    "out:Total CO2 (tons)": 28.33,
    "out:Klimatpaverkan": -171.36,
    "out:Initial Cost/MSEK": 8.326125,
    "out:Running cost/(Apt SEK y)": 27923,
    "out:Running Cost over RSP/MSEK": 6.348,
    "out:LCP/MSEK": -2.986,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 232138,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286008,
    "out:DH kr savings": 211246,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 78.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 7.95,
    "out:Total CO2 (tons)": 24.94,
    "out:Klimatpaverkan": -174.75,
    "out:Initial Cost/MSEK": 8.4045,
    "out:Running cost/(Apt SEK y)": 27070,
    "out:Running Cost over RSP/MSEK": 6.155,
    "out:LCP/MSEK": -2.87,
    "out:ROI% old": 9.41,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294572,
    "out:DH kr savings": 219810,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 93.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.98,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 59.88,
    "out:Total CO2 (tons)": 187.85,
    "out:Klimatpaverkan": -11.84,
    "out:Initial Cost/MSEK": 6.93825,
    "out:Running cost/(Apt SEK y)": 30778,
    "out:Running Cost over RSP/MSEK": 6.975,
    "out:LCP/MSEK": -2.224,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 602,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192429,
    "out:DH kr savings": 216955,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 90.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.98,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 58.44,
    "out:Total CO2 (tons)": 183.33,
    "out:Klimatpaverkan": -16.36,
    "out:Initial Cost/MSEK": 7.016625,
    "out:Running cost/(Apt SEK y)": 29641,
    "out:Running Cost over RSP/MSEK": 6.717,
    "out:LCP/MSEK": -2.044,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203848,
    "out:DH kr savings": 228374,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 110.5,
    "out:Primary Energy": 91.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.4,
    "out:CO2 Operational/m2": 35.1,
    "out:Total CO2/m2": 58.5,
    "out:Total CO2 (tons)": 183.52,
    "out:Klimatpaverkan": -16.17,
    "out:Initial Cost/MSEK": 7.097625,
    "out:Running cost/(Apt SEK y)": 29357,
    "out:Running Cost over RSP/MSEK": 6.652,
    "out:LCP/MSEK": -2.061,
    "out:ROI% old": 10.14,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206702,
    "out:DH kr savings": 231228,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 88.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.4,
    "out:CO2 Operational/m2": 33.66,
    "out:Total CO2/m2": 57.06,
    "out:Total CO2 (tons)": 179,
    "out:Klimatpaverkan": -20.69,
    "out:Initial Cost/MSEK": 7.176,
    "out:Running cost/(Apt SEK y)": 28219,
    "out:Running Cost over RSP/MSEK": 6.394,
    "out:LCP/MSEK": -1.881,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218121,
    "out:DH kr savings": 242647,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 82.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.33,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 46.22,
    "out:Total CO2 (tons)": 144.99,
    "out:Klimatpaverkan": -54.7,
    "out:Initial Cost/MSEK": 7.59075,
    "out:Running cost/(Apt SEK y)": 28953,
    "out:Running Cost over RSP/MSEK": 6.572,
    "out:LCP/MSEK": -2.474,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245357,
    "out:DH kr savings": 216955,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 79.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.33,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 44.78,
    "out:Total CO2 (tons)": 140.47,
    "out:Klimatpaverkan": -59.22,
    "out:Initial Cost/MSEK": 7.669125,
    "out:Running cost/(Apt SEK y)": 27816,
    "out:Running Cost over RSP/MSEK": 6.314,
    "out:LCP/MSEK": -2.294,
    "out:ROI% old": 10.01,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 688,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256775,
    "out:DH kr savings": 228374,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 104.33,
    "out:Primary Energy": 79.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 44.84,
    "out:Total CO2 (tons)": 140.66,
    "out:Klimatpaverkan": -59.03,
    "out:Initial Cost/MSEK": 7.750125,
    "out:Running cost/(Apt SEK y)": 27531,
    "out:Running Cost over RSP/MSEK": 6.249,
    "out:LCP/MSEK": -2.311,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259630,
    "out:DH kr savings": 231228,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 100.33,
    "out:Primary Energy": 77.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.75,
    "out:CO2 Operational/m2": 7.65,
    "out:Total CO2/m2": 43.4,
    "out:Total CO2 (tons)": 136.14,
    "out:Klimatpaverkan": -63.55,
    "out:Initial Cost/MSEK": 7.8285,
    "out:Running cost/(Apt SEK y)": 26394,
    "out:Running Cost over RSP/MSEK": 5.991,
    "out:LCP/MSEK": -2.131,
    "out:ROI% old": 10.4,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271049,
    "out:DH kr savings": 242647,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.68,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 7.89,
    "out:Total CO2 (tons)": 24.76,
    "out:Klimatpaverkan": -174.93,
    "out:Initial Cost/MSEK": 8.24325,
    "out:Running cost/(Apt SEK y)": 27354,
    "out:Running Cost over RSP/MSEK": 6.219,
    "out:LCP/MSEK": -2.774,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 701,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238412,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291717,
    "out:DH kr savings": 216955,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 77.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.68,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 6.45,
    "out:Total CO2 (tons)": 20.24,
    "out:Klimatpaverkan": -179.45,
    "out:Initial Cost/MSEK": 8.32175,
    "out:Running cost/(Apt SEK y)": 26217,
    "out:Running Cost over RSP/MSEK": 5.961,
    "out:LCP/MSEK": -2.594,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303136,
    "out:DH kr savings": 228374,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.36,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 103.09,
    "out:Primary Energy": 77.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": -41.59,
    "out:Total CO2/m2": 6.51,
    "out:Total CO2 (tons)": 20.43,
    "out:Klimatpaverkan": -179.26,
    "out:Initial Cost/MSEK": 8.402625,
    "out:Running cost/(Apt SEK y)": 25933,
    "out:Running Cost over RSP/MSEK": 5.897,
    "out:LCP/MSEK": -2.61,
    "out:ROI% old": 9.85,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305990,
    "out:DH kr savings": 231228,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 189335,
    "out:% savings (space heating)": 52.8,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 89.23,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.57,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 99.09,
    "out:Primary Energy": 75.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.1,
    "out:CO2 Operational/m2": -43.03,
    "out:Total CO2/m2": 5.07,
    "out:Total CO2 (tons)": 15.91,
    "out:Klimatpaverkan": -183.78,
    "out:Initial Cost/MSEK": 8.481125,
    "out:Running cost/(Apt SEK y)": 24796,
    "out:Running Cost over RSP/MSEK": 5.639,
    "out:LCP/MSEK": -2.431,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 776,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 609021.804251,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317409,
    "out:DH kr savings": 242647,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 177467,
    "out:% savings (space heating)": 55.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 85.45,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 103.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.74,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 79.48,
    "out:Total CO2 (tons)": 249.33,
    "out:Klimatpaverkan": 49.64,
    "out:Initial Cost/MSEK": 7.0815,
    "out:Running cost/(Apt SEK y)": 36180,
    "out:Running Cost over RSP/MSEK": 8.201,
    "out:LCP/MSEK": -3.593,
    "out:ROI% old": 7.04,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 445,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138190,
    "out:DH kr savings": 162716,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 100.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.74,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 78.04,
    "out:Total CO2 (tons)": 244.81,
    "out:Klimatpaverkan": 45.12,
    "out:Initial Cost/MSEK": 7.159875,
    "out:Running cost/(Apt SEK y)": 35042,
    "out:Running Cost over RSP/MSEK": 7.943,
    "out:LCP/MSEK": -3.414,
    "out:ROI% old": 7.47,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149609,
    "out:DH kr savings": 174135,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 101.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.16,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 77.74,
    "out:Total CO2 (tons)": 243.87,
    "out:Klimatpaverkan": 44.18,
    "out:Initial Cost/MSEK": 7.240875,
    "out:Running cost/(Apt SEK y)": 34474,
    "out:Running Cost over RSP/MSEK": 7.813,
    "out:LCP/MSEK": -3.366,
    "out:ROI% old": 7.65,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 495,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 197631,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155318,
    "out:DH kr savings": 179844,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.16,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 75.94,
    "out:Total CO2 (tons)": 238.22,
    "out:Klimatpaverkan": 38.53,
    "out:Initial Cost/MSEK": 7.31925,
    "out:Running cost/(Apt SEK y)": 33052,
    "out:Running Cost over RSP/MSEK": 7.491,
    "out:LCP/MSEK": -3.121,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169592,
    "out:DH kr savings": 194118,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 92.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.09,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 65.82,
    "out:Total CO2 (tons)": 206.47,
    "out:Klimatpaverkan": 6.78,
    "out:Initial Cost/MSEK": 7.734,
    "out:Running cost/(Apt SEK y)": 34355,
    "out:Running Cost over RSP/MSEK": 7.798,
    "out:LCP/MSEK": -3.843,
    "out:ROI% old": 7.19,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191118,
    "out:DH kr savings": 162716,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 89.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.09,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 64.38,
    "out:Total CO2 (tons)": 201.96,
    "out:Klimatpaverkan": 2.27,
    "out:Initial Cost/MSEK": 7.8125,
    "out:Running cost/(Apt SEK y)": 33217,
    "out:Running Cost over RSP/MSEK": 7.54,
    "out:LCP/MSEK": -3.663,
    "out:ROI% old": 7.59,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202537,
    "out:DH kr savings": 174135,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 90.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.51,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 64.08,
    "out:Total CO2 (tons)": 201.01,
    "out:Klimatpaverkan": 1.32,
    "out:Initial Cost/MSEK": 7.893375,
    "out:Running cost/(Apt SEK y)": 32649,
    "out:Running Cost over RSP/MSEK": 7.411,
    "out:LCP/MSEK": -3.615,
    "out:ROI% old": 7.74,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 548,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197631,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208246,
    "out:DH kr savings": 179844,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.51,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 62.28,
    "out:Total CO2 (tons)": 195.37,
    "out:Klimatpaverkan": -4.32,
    "out:Initial Cost/MSEK": 7.971875,
    "out:Running cost/(Apt SEK y)": 31227,
    "out:Running Cost over RSP/MSEK": 7.088,
    "out:LCP/MSEK": -3.371,
    "out:ROI% old": 8.24,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222519,
    "out:DH kr savings": 194118,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 90.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.44,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 27.49,
    "out:Total CO2 (tons)": 86.24,
    "out:Klimatpaverkan": -113.45,
    "out:Initial Cost/MSEK": 8.386625,
    "out:Running cost/(Apt SEK y)": 32756,
    "out:Running Cost over RSP/MSEK": 7.445,
    "out:LCP/MSEK": -4.143,
    "out:ROI% old": 7.23,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 6,
    "out:Return/kSEK/y": 545,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 178809,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237478,
    "out:DH kr savings": 162716,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 87.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.44,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 26.05,
    "out:Total CO2 (tons)": 81.72,
    "out:Klimatpaverkan": -117.97,
    "out:Initial Cost/MSEK": 8.465,
    "out:Running cost/(Apt SEK y)": 31619,
    "out:Running Cost over RSP/MSEK": 7.187,
    "out:LCP/MSEK": -3.963,
    "out:ROI% old": 7.6,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 578,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 191357,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248897,
    "out:DH kr savings": 174135,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 87.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.86,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 25.75,
    "out:Total CO2 (tons)": 80.78,
    "out:Klimatpaverkan": -118.91,
    "out:Initial Cost/MSEK": 8.546,
    "out:Running cost/(Apt SEK y)": 31050,
    "out:Running Cost over RSP/MSEK": 7.058,
    "out:LCP/MSEK": -3.915,
    "out:ROI% old": 7.74,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197631,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254606,
    "out:DH kr savings": 179844,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 84.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.86,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 23.95,
    "out:Total CO2 (tons)": 75.13,
    "out:Klimatpaverkan": -124.56,
    "out:Initial Cost/MSEK": 8.624375,
    "out:Running cost/(Apt SEK y)": 29629,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -3.671,
    "out:ROI% old": 8.2,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268880,
    "out:DH kr savings": 194118,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 100.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.74,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 76.24,
    "out:Total CO2 (tons)": 239.17,
    "out:Klimatpaverkan": 39.48,
    "out:Initial Cost/MSEK": 7.158,
    "out:Running cost/(Apt SEK y)": 33621,
    "out:Running Cost over RSP/MSEK": 7.62,
    "out:LCP/MSEK": -3.089,
    "out:ROI% old": 8.12,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 163882,
    "out:DH kr savings": 188408,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 97.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.74,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 74.8,
    "out:Total CO2 (tons)": 234.65,
    "out:Klimatpaverkan": 34.96,
    "out:Initial Cost/MSEK": 7.2365,
    "out:Running cost/(Apt SEK y)": 32484,
    "out:Running Cost over RSP/MSEK": 7.362,
    "out:LCP/MSEK": -2.91,
    "out:ROI% old": 8.54,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 553,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175301,
    "out:DH kr savings": 199827,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.16,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 74.86,
    "out:Total CO2 (tons)": 234.84,
    "out:Klimatpaverkan": 35.15,
    "out:Initial Cost/MSEK": 7.317375,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -2.926,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 95.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.16,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 73.42,
    "out:Total CO2 (tons)": 230.32,
    "out:Klimatpaverkan": 30.63,
    "out:Initial Cost/MSEK": 7.395875,
    "out:Running cost/(Apt SEK y)": 31062,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -2.746,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189574,
    "out:DH kr savings": 214100,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 89.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.09,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 62.58,
    "out:Total CO2 (tons)": 196.31,
    "out:Klimatpaverkan": -3.38,
    "out:Initial Cost/MSEK": 7.810625,
    "out:Running cost/(Apt SEK y)": 31796,
    "out:Running Cost over RSP/MSEK": 7.217,
    "out:LCP/MSEK": -3.339,
    "out:ROI% old": 8.18,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216810,
    "out:DH kr savings": 188408,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 86.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.09,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 61.14,
    "out:Total CO2 (tons)": 191.79,
    "out:Klimatpaverkan": -7.9,
    "out:Initial Cost/MSEK": 7.889,
    "out:Running cost/(Apt SEK y)": 30659,
    "out:Running Cost over RSP/MSEK": 6.959,
    "out:LCP/MSEK": -3.159,
    "out:ROI% old": 8.56,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 606,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228229,
    "out:DH kr savings": 199827,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 87.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.51,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 61.2,
    "out:Total CO2 (tons)": 191.98,
    "out:Klimatpaverkan": -7.71,
    "out:Initial Cost/MSEK": 7.97,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -3.176,
    "out:ROI% old": 8.59,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 84.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.51,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 59.76,
    "out:Total CO2 (tons)": 187.46,
    "out:Klimatpaverkan": -12.23,
    "out:Initial Cost/MSEK": 8.048375,
    "out:Running cost/(Apt SEK y)": 29237,
    "out:Running Cost over RSP/MSEK": 6.636,
    "out:LCP/MSEK": -2.996,
    "out:ROI% old": 8.97,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242502,
    "out:DH kr savings": 214100,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.44,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 24.25,
    "out:Total CO2 (tons)": 76.08,
    "out:Klimatpaverkan": -123.61,
    "out:Initial Cost/MSEK": 8.463125,
    "out:Running cost/(Apt SEK y)": 30197,
    "out:Running Cost over RSP/MSEK": 6.864,
    "out:LCP/MSEK": -3.639,
    "out:ROI% old": 8.14,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207042,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263170,
    "out:DH kr savings": 188408,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 84.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.44,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 22.81,
    "out:Total CO2 (tons)": 71.56,
    "out:Klimatpaverkan": -128.13,
    "out:Initial Cost/MSEK": 8.5415,
    "out:Running cost/(Apt SEK y)": 29060,
    "out:Running Cost over RSP/MSEK": 6.606,
    "out:LCP/MSEK": -3.459,
    "out:ROI% old": 8.5,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 219590,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274589,
    "out:DH kr savings": 199827,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 70.63,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 84.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.86,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 22.87,
    "out:Total CO2 (tons)": 71.74,
    "out:Klimatpaverkan": -127.95,
    "out:Initial Cost/MSEK": 8.6225,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -3.476,
    "out:ROI% old": 8.53,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 221575,
    "out:% savings (space heating)": 44.76,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 99.51,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 66.38,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 81.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.86,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 21.43,
    "out:Total CO2 (tons)": 67.23,
    "out:Klimatpaverkan": -132.46,
    "out:Initial Cost/MSEK": 8.700875,
    "out:Running cost/(Apt SEK y)": 27639,
    "out:Running Cost over RSP/MSEK": 6.284,
    "out:LCP/MSEK": -3.296,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288862,
    "out:DH kr savings": 214100,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 208242,
    "out:% savings (space heating)": 48.08,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 95.26,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 95.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.01,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 77.79,
    "out:Total CO2 (tons)": 244.03,
    "out:Klimatpaverkan": 44.34,
    "out:Initial Cost/MSEK": 7.595625,
    "out:Running cost/(Apt SEK y)": 33052,
    "out:Running Cost over RSP/MSEK": 7.491,
    "out:LCP/MSEK": -3.398,
    "out:ROI% old": 7.9,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169592,
    "out:DH kr savings": 194118,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 93.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.01,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 76.35,
    "out:Total CO2 (tons)": 239.51,
    "out:Klimatpaverkan": 39.82,
    "out:Initial Cost/MSEK": 7.674,
    "out:Running cost/(Apt SEK y)": 31915,
    "out:Running Cost over RSP/MSEK": 7.233,
    "out:LCP/MSEK": -3.218,
    "out:ROI% old": 8.3,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 569,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181010,
    "out:DH kr savings": 205536,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 93.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.43,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 75.69,
    "out:Total CO2 (tons)": 237.44,
    "out:Klimatpaverkan": 37.75,
    "out:Initial Cost/MSEK": 7.755,
    "out:Running cost/(Apt SEK y)": 31062,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -3.105,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189574,
    "out:DH kr savings": 214100,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 90.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.43,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 74.61,
    "out:Total CO2 (tons)": 234.05,
    "out:Klimatpaverkan": 34.36,
    "out:Initial Cost/MSEK": 7.833375,
    "out:Running cost/(Apt SEK y)": 30209,
    "out:Running Cost over RSP/MSEK": 6.846,
    "out:LCP/MSEK": -2.99,
    "out:ROI% old": 8.83,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198138,
    "out:DH kr savings": 222664,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 84.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.36,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 64.13,
    "out:Total CO2 (tons)": 201.17,
    "out:Klimatpaverkan": 1.48,
    "out:Initial Cost/MSEK": 8.248125,
    "out:Running cost/(Apt SEK y)": 31227,
    "out:Running Cost over RSP/MSEK": 7.088,
    "out:LCP/MSEK": -3.647,
    "out:ROI% old": 7.97,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222519,
    "out:DH kr savings": 194118,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 82.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.36,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 62.69,
    "out:Total CO2 (tons)": 196.65,
    "out:Klimatpaverkan": -3.04,
    "out:Initial Cost/MSEK": 8.326625,
    "out:Running cost/(Apt SEK y)": 30090,
    "out:Running Cost over RSP/MSEK": 6.83,
    "out:LCP/MSEK": -3.468,
    "out:ROI% old": 8.34,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233938,
    "out:DH kr savings": 205536,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 82.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.78,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 62.03,
    "out:Total CO2 (tons)": 194.58,
    "out:Klimatpaverkan": -5.11,
    "out:Initial Cost/MSEK": 8.4075,
    "out:Running cost/(Apt SEK y)": 29237,
    "out:Running Cost over RSP/MSEK": 6.636,
    "out:LCP/MSEK": -3.355,
    "out:ROI% old": 8.58,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 242502,
    "out:DH kr savings": 214100,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 79.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.78,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 60.95,
    "out:Total CO2 (tons)": 191.19,
    "out:Klimatpaverkan": -8.5,
    "out:Initial Cost/MSEK": 8.486,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 6.443,
    "out:LCP/MSEK": -3.24,
    "out:ROI% old": 8.83,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251066,
    "out:DH kr savings": 222664,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 82.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.71,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 25.8,
    "out:Total CO2 (tons)": 80.94,
    "out:Klimatpaverkan": -118.75,
    "out:Initial Cost/MSEK": 8.900625,
    "out:Running cost/(Apt SEK y)": 29629,
    "out:Running Cost over RSP/MSEK": 6.735,
    "out:LCP/MSEK": -3.947,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 213316,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268880,
    "out:DH kr savings": 194118,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 80.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.71,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 24.36,
    "out:Total CO2 (tons)": 76.42,
    "out:Klimatpaverkan": -123.27,
    "out:Initial Cost/MSEK": 8.979125,
    "out:Running cost/(Apt SEK y)": 28491,
    "out:Running Cost over RSP/MSEK": 6.477,
    "out:LCP/MSEK": -3.768,
    "out:ROI% old": 8.29,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 225864,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280298,
    "out:DH kr savings": 205536,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 80.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.13,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 23.7,
    "out:Total CO2 (tons)": 74.35,
    "out:Klimatpaverkan": -125.34,
    "out:Initial Cost/MSEK": 9.06,
    "out:Running cost/(Apt SEK y)": 27639,
    "out:Running Cost over RSP/MSEK": 6.284,
    "out:LCP/MSEK": -3.655,
    "out:ROI% old": 8.52,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288862,
    "out:DH kr savings": 214100,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 77.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.13,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 22.62,
    "out:Total CO2 (tons)": 70.96,
    "out:Klimatpaverkan": -128.73,
    "out:Initial Cost/MSEK": 9.1385,
    "out:Running cost/(Apt SEK y)": 26786,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -3.54,
    "out:ROI% old": 8.75,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297426,
    "out:DH kr savings": 222664,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 92.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.01,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 74.55,
    "out:Total CO2 (tons)": 233.86,
    "out:Klimatpaverkan": 34.17,
    "out:Initial Cost/MSEK": 7.672125,
    "out:Running cost/(Apt SEK y)": 30494,
    "out:Running Cost over RSP/MSEK": 6.91,
    "out:LCP/MSEK": -2.894,
    "out:ROI% old": 8.9,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195284,
    "out:DH kr savings": 219810,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 110.5,
    "out:Primary Energy": 89.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.01,
    "out:CO2 Operational/m2": 35.1,
    "out:Total CO2/m2": 73.11,
    "out:Total CO2 (tons)": 229.35,
    "out:Klimatpaverkan": 29.66,
    "out:Initial Cost/MSEK": 7.750625,
    "out:Running cost/(Apt SEK y)": 29357,
    "out:Running Cost over RSP/MSEK": 6.652,
    "out:LCP/MSEK": -2.714,
    "out:ROI% old": 9.28,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 643,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 206702,
    "out:DH kr savings": 231228,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 90.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.43,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 73.17,
    "out:Total CO2 (tons)": 229.53,
    "out:Klimatpaverkan": 29.84,
    "out:Initial Cost/MSEK": 7.8315,
    "out:Running cost/(Apt SEK y)": 29072,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -2.73,
    "out:ROI% old": 9.31,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209557,
    "out:DH kr savings": 234083,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 87.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.43,
    "out:CO2 Operational/m2": 33.3,
    "out:Total CO2/m2": 71.73,
    "out:Total CO2 (tons)": 225.02,
    "out:Klimatpaverkan": 25.33,
    "out:Initial Cost/MSEK": 7.91,
    "out:Running cost/(Apt SEK y)": 27935,
    "out:Running Cost over RSP/MSEK": 6.329,
    "out:LCP/MSEK": -2.551,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269782,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220976,
    "out:DH kr savings": 245502,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 81.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.36,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 60.89,
    "out:Total CO2 (tons)": 191.01,
    "out:Klimatpaverkan": -8.68,
    "out:Initial Cost/MSEK": 8.324625,
    "out:Running cost/(Apt SEK y)": 28669,
    "out:Running Cost over RSP/MSEK": 6.507,
    "out:LCP/MSEK": -3.143,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248211,
    "out:DH kr savings": 219810,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 104.33,
    "out:Primary Energy": 78.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.36,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 59.45,
    "out:Total CO2 (tons)": 186.49,
    "out:Klimatpaverkan": -13.2,
    "out:Initial Cost/MSEK": 8.403125,
    "out:Running cost/(Apt SEK y)": 27531,
    "out:Running Cost over RSP/MSEK": 6.249,
    "out:LCP/MSEK": -2.964,
    "out:ROI% old": 9.25,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 259630,
    "out:DH kr savings": 231228,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 79.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.78,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 59.51,
    "out:Total CO2 (tons)": 186.68,
    "out:Klimatpaverkan": -13.01,
    "out:Initial Cost/MSEK": 8.484,
    "out:Running cost/(Apt SEK y)": 27247,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -2.98,
    "out:ROI% old": 9.27,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262485,
    "out:DH kr savings": 234083,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 99.33,
    "out:Primary Energy": 76.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 50.78,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 58.07,
    "out:Total CO2 (tons)": 182.16,
    "out:Klimatpaverkan": -17.53,
    "out:Initial Cost/MSEK": 8.5625,
    "out:Running cost/(Apt SEK y)": 26110,
    "out:Running Cost over RSP/MSEK": 5.927,
    "out:LCP/MSEK": -2.8,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 737,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269782,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273903,
    "out:DH kr savings": 245502,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 79.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.71,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 22.56,
    "out:Total CO2 (tons)": 70.77,
    "out:Klimatpaverkan": -128.92,
    "out:Initial Cost/MSEK": 8.97725,
    "out:Running cost/(Apt SEK y)": 27070,
    "out:Running Cost over RSP/MSEK": 6.155,
    "out:LCP/MSEK": -3.443,
    "out:ROI% old": 8.81,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 241549,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294572,
    "out:DH kr savings": 219810,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 103.09,
    "out:Primary Energy": 76.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.71,
    "out:CO2 Operational/m2": -41.59,
    "out:Total CO2/m2": 21.12,
    "out:Total CO2 (tons)": 66.26,
    "out:Klimatpaverkan": -133.43,
    "out:Initial Cost/MSEK": 9.055625,
    "out:Running cost/(Apt SEK y)": 25933,
    "out:Running Cost over RSP/MSEK": 5.897,
    "out:LCP/MSEK": -3.263,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 743,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 254097,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305990,
    "out:DH kr savings": 231228,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 59.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 77.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 63.13,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 21.18,
    "out:Total CO2 (tons)": 66.44,
    "out:Klimatpaverkan": -133.25,
    "out:Initial Cost/MSEK": 9.136625,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 5.832,
    "out:LCP/MSEK": -3.28,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308845,
    "out:DH kr savings": 234083,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 186380,
    "out:% savings (space heating)": 53.53,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 88.29,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 55.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 74.43,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 63.13,
    "out:CO2 Operational/m2": -43.39,
    "out:Total CO2/m2": 19.74,
    "out:Total CO2 (tons)": 61.93,
    "out:Klimatpaverkan": -137.76,
    "out:Initial Cost/MSEK": 9.215,
    "out:Running cost/(Apt SEK y)": 24511,
    "out:Running Cost over RSP/MSEK": 5.574,
    "out:LCP/MSEK": -3.1,
    "out:ROI% old": 9.48,
    "out:Payback discounted": 13,
    "out:Atemp": 3137,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 784,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269782,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320264,
    "out:DH kr savings": 245502,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 174646,
    "out:% savings (space heating)": 56.46,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.55,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 93.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.93,
    "out:CO2 Operational/m2": 38.7,
    "out:Total CO2/m2": 82.63,
    "out:Total CO2 (tons)": 259.21,
    "out:Klimatpaverkan": 59.52,
    "out:Initial Cost/MSEK": 8.854375,
    "out:Running cost/(Apt SEK y)": 32200,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": -4.463,
    "out:ROI% old": 7.09,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 6,
    "out:Return/kSEK/y": 561,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178156,
    "out:DH kr savings": 202682,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 91.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.93,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 81.19,
    "out:Total CO2 (tons)": 254.69,
    "out:Klimatpaverkan": 55,
    "out:Initial Cost/MSEK": 8.932875,
    "out:Running cost/(Apt SEK y)": 31062,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -4.283,
    "out:ROI% old": 7.44,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 594,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189574,
    "out:DH kr savings": 214100,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.35,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 80.53,
    "out:Total CO2 (tons)": 252.62,
    "out:Klimatpaverkan": 52.93,
    "out:Initial Cost/MSEK": 9.01375,
    "out:Running cost/(Apt SEK y)": 30209,
    "out:Running Cost over RSP/MSEK": 6.846,
    "out:LCP/MSEK": -4.171,
    "out:ROI% old": 7.68,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198138,
    "out:DH kr savings": 222664,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 88.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.35,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 79.09,
    "out:Total CO2 (tons)": 248.11,
    "out:Klimatpaverkan": 48.42,
    "out:Initial Cost/MSEK": 9.09225,
    "out:Running cost/(Apt SEK y)": 29072,
    "out:Running Cost over RSP/MSEK": 6.588,
    "out:LCP/MSEK": -3.991,
    "out:ROI% old": 8.01,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209557,
    "out:DH kr savings": 234083,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 114.33,
    "out:Primary Energy": 82.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.28,
    "out:CO2 Operational/m2": 12.69,
    "out:Total CO2/m2": 68.97,
    "out:Total CO2 (tons)": 216.36,
    "out:Klimatpaverkan": 16.67,
    "out:Initial Cost/MSEK": 9.507,
    "out:Running cost/(Apt SEK y)": 30374,
    "out:Running Cost over RSP/MSEK": 6.894,
    "out:LCP/MSEK": -4.713,
    "out:ROI% old": 7.2,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 6,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231083,
    "out:DH kr savings": 202682,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 80.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.28,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 67.53,
    "out:Total CO2 (tons)": 211.84,
    "out:Klimatpaverkan": 12.15,
    "out:Initial Cost/MSEK": 9.585375,
    "out:Running cost/(Apt SEK y)": 29237,
    "out:Running Cost over RSP/MSEK": 6.636,
    "out:LCP/MSEK": -4.533,
    "out:ROI% old": 7.53,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242502,
    "out:DH kr savings": 214100,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 79.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.7,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 66.87,
    "out:Total CO2 (tons)": 209.77,
    "out:Klimatpaverkan": 10.08,
    "out:Initial Cost/MSEK": 9.666375,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 6.443,
    "out:LCP/MSEK": -4.42,
    "out:ROI% old": 7.75,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251066,
    "out:DH kr savings": 222664,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 77.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.7,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 65.43,
    "out:Total CO2 (tons)": 205.25,
    "out:Klimatpaverkan": 5.56,
    "out:Initial Cost/MSEK": 9.74475,
    "out:Running cost/(Apt SEK y)": 27247,
    "out:Running Cost over RSP/MSEK": 6.185,
    "out:LCP/MSEK": -4.241,
    "out:ROI% old": 8.07,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262485,
    "out:DH kr savings": 234083,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 120.5,
    "out:Total Energy Use Post PV": 113.09,
    "out:Primary Energy": 80.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 68.63,
    "out:CO2 Operational/m2": -37.99,
    "out:Total CO2/m2": 30.64,
    "out:Total CO2 (tons)": 96.12,
    "out:Klimatpaverkan": -103.57,
    "out:Initial Cost/MSEK": 10.1595,
    "out:Running cost/(Apt SEK y)": 28776,
    "out:Running Cost over RSP/MSEK": 6.542,
    "out:LCP/MSEK": -5.013,
    "out:ROI% old": 7.24,
    "out:Payback discounted": 17,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 6,
    "out:Return/kSEK/y": 660,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 222727,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277444,
    "out:DH kr savings": 202682,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 77.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 68.63,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 29.2,
    "out:Total CO2 (tons)": 91.6,
    "out:Klimatpaverkan": -108.09,
    "out:Initial Cost/MSEK": 10.237875,
    "out:Running cost/(Apt SEK y)": 27639,
    "out:Running Cost over RSP/MSEK": 6.284,
    "out:LCP/MSEK": -4.833,
    "out:ROI% old": 7.54,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235275,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288862,
    "out:DH kr savings": 214100,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 136460,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 77.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 69.05,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 28.54,
    "out:Total CO2 (tons)": 89.53,
    "out:Klimatpaverkan": -110.16,
    "out:Initial Cost/MSEK": 10.318875,
    "out:Running cost/(Apt SEK y)": 26786,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -4.72,
    "out:ROI% old": 7.75,
    "out:Payback discounted": 16,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 244686,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297426,
    "out:DH kr savings": 222664,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 75.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 69.05,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 27.1,
    "out:Total CO2 (tons)": 85.01,
    "out:Klimatpaverkan": -114.68,
    "out:Initial Cost/MSEK": 10.39725,
    "out:Running cost/(Apt SEK y)": 25648,
    "out:Running Cost over RSP/MSEK": 5.832,
    "out:LCP/MSEK": -4.541,
    "out:ROI% old": 8.05,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 257234,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308845,
    "out:DH kr savings": 234083,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 115991,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 90.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.93,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 79.39,
    "out:Total CO2 (tons)": 249.05,
    "out:Klimatpaverkan": 49.36,
    "out:Initial Cost/MSEK": 8.931,
    "out:Running cost/(Apt SEK y)": 29641,
    "out:Running Cost over RSP/MSEK": 6.717,
    "out:LCP/MSEK": -3.959,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 635,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203848,
    "out:DH kr savings": 228374,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 87.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.93,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 77.95,
    "out:Total CO2 (tons)": 244.53,
    "out:Klimatpaverkan": 44.84,
    "out:Initial Cost/MSEK": 9.009375,
    "out:Running cost/(Apt SEK y)": 28504,
    "out:Running Cost over RSP/MSEK": 6.459,
    "out:LCP/MSEK": -3.779,
    "out:ROI% old": 8.29,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 263508,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215266,
    "out:DH kr savings": 239792,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 88.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.35,
    "out:CO2 Operational/m2": 33.66,
    "out:Total CO2/m2": 78.01,
    "out:Total CO2 (tons)": 244.72,
    "out:Klimatpaverkan": 45.03,
    "out:Initial Cost/MSEK": 9.090375,
    "out:Running cost/(Apt SEK y)": 28219,
    "out:Running Cost over RSP/MSEK": 6.394,
    "out:LCP/MSEK": -3.796,
    "out:ROI% old": 8.32,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218121,
    "out:DH kr savings": 242647,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 102.5,
    "out:Total Energy Use Post PV": 102.5,
    "out:Primary Energy": 85.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.35,
    "out:CO2 Operational/m2": 32.22,
    "out:Total CO2/m2": 76.57,
    "out:Total CO2 (tons)": 240.2,
    "out:Klimatpaverkan": 40.51,
    "out:Initial Cost/MSEK": 9.16875,
    "out:Running cost/(Apt SEK y)": 27082,
    "out:Running Cost over RSP/MSEK": 6.136,
    "out:LCP/MSEK": -3.616,
    "out:ROI% old": 8.65,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 709,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279193,
    "out:EL kWh savings": -14427,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229540,
    "out:DH kr savings": 254066,
    "out:El kr savings": -24526,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 79.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.28,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 65.73,
    "out:Total CO2 (tons)": 206.19,
    "out:Klimatpaverkan": 6.5,
    "out:Initial Cost/MSEK": 9.5835,
    "out:Running cost/(Apt SEK y)": 27816,
    "out:Running Cost over RSP/MSEK": 6.314,
    "out:LCP/MSEK": -4.208,
    "out:ROI% old": 8.01,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 688,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256775,
    "out:DH kr savings": 228374,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 76.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.28,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 64.29,
    "out:Total CO2 (tons)": 201.67,
    "out:Klimatpaverkan": 1.98,
    "out:Initial Cost/MSEK": 9.661875,
    "out:Running cost/(Apt SEK y)": 26679,
    "out:Running Cost over RSP/MSEK": 6.056,
    "out:LCP/MSEK": -4.029,
    "out:ROI% old": 8.33,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 263508,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268194,
    "out:DH kr savings": 239792,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 100.33,
    "out:Primary Energy": 76.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 56.7,
    "out:CO2 Operational/m2": 7.65,
    "out:Total CO2/m2": 64.35,
    "out:Total CO2 (tons)": 201.86,
    "out:Klimatpaverkan": 2.17,
    "out:Initial Cost/MSEK": 9.742875,
    "out:Running cost/(Apt SEK y)": 26394,
    "out:Running Cost over RSP/MSEK": 5.991,
    "out:LCP/MSEK": -4.045,
    "out:ROI% old": 8.35,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271049,
    "out:DH kr savings": 242647,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 102.5,
    "out:Total Energy Use Post PV": 96.33,
    "out:Primary Energy": 74.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 56.7,
    "out:CO2 Operational/m2": 6.21,
    "out:Total CO2/m2": 62.91,
    "out:Total CO2 (tons)": 197.34,
    "out:Klimatpaverkan": -2.35,
    "out:Initial Cost/MSEK": 9.82125,
    "out:Running cost/(Apt SEK y)": 25257,
    "out:Running Cost over RSP/MSEK": 5.733,
    "out:LCP/MSEK": -3.866,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 37.369516,
    "out:PV (m2 panels)": 196,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 35062.71,
    "out:PV (kWh sold)": 15714.86,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 19347.85,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279193,
    "out:EL kWh savings": 4921,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282467,
    "out:DH kr savings": 254066,
    "out:El kr savings": 8365,
    "out:El kr sold": 20036,
    "out:El kr saved": 32891,
    "out:El kr return": 52928,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 76.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 68.63,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 27.4,
    "out:Total CO2 (tons)": 85.96,
    "out:Klimatpaverkan": -113.73,
    "out:Initial Cost/MSEK": 10.236,
    "out:Running cost/(Apt SEK y)": 26217,
    "out:Running Cost over RSP/MSEK": 5.961,
    "out:LCP/MSEK": -4.508,
    "out:ROI% old": 7.99,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 250960,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303136,
    "out:DH kr savings": 228374,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 74.32,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.63,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": 25.96,
    "out:Total CO2 (tons)": 81.44,
    "out:Klimatpaverkan": -118.25,
    "out:Initial Cost/MSEK": 10.3145,
    "out:Running cost/(Apt SEK y)": 25080,
    "out:Running Cost over RSP/MSEK": 5.703,
    "out:LCP/MSEK": -4.329,
    "out:ROI% old": 8.29,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 263508,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314554,
    "out:DH kr savings": 239792,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 109168,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 56.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 106.5,
    "out:Total Energy Use Post PV": 99.09,
    "out:Primary Energy": 74.73,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.05,
    "out:CO2 Operational/m2": -43.03,
    "out:Total CO2/m2": 26.02,
    "out:Total CO2 (tons)": 81.63,
    "out:Klimatpaverkan": -118.06,
    "out:Initial Cost/MSEK": 10.395375,
    "out:Running cost/(Apt SEK y)": 24796,
    "out:Running Cost over RSP/MSEK": 5.639,
    "out:LCP/MSEK": -4.345,
    "out:ROI% old": 8.31,
    "out:Payback discounted": 15,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 776,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 266645,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317409,
    "out:DH kr savings": 242647,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 175988,
    "out:% savings (space heating)": 56.13,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 84.97,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 102.5,
    "out:Total Energy Use Post PV": 95.09,
    "out:Primary Energy": 72.22,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 69.05,
    "out:CO2 Operational/m2": -44.47,
    "out:Total CO2/m2": 24.58,
    "out:Total CO2 (tons)": 77.11,
    "out:Klimatpaverkan": -122.58,
    "out:Initial Cost/MSEK": 10.473875,
    "out:Running cost/(Apt SEK y)": 23658,
    "out:Running Cost over RSP/MSEK": 5.38,
    "out:LCP/MSEK": -4.166,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 3137,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 784.250068,
    "out:Facade surface/m2": 1348.465672,
    "out:Window surface/m2": 277.142694,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 81772.782146,
    "out:EAHP (pipework)": 81522.794595,
    "out:EAHP (natural ventilation grills)": 138058.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 74.739031,
    "out:PV (m2 panels)": 392,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 70125.42,
    "out:PV (kWh sold)": 46882.48,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 23242.94,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1214900,
    "out:FTX (Diffusers)": 192968.75,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153248.640216,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 29,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 358150,
    "out:DH (pipework)": 398750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 662122.726161,
    "out:FTX (Facade)": 3685600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279193,
    "out:EL kWh savings": 8816,
    "out:Sewage heat recovery cost": 159376,
    "out:Balancing hydronic system cost": 78425.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328828,
    "out:DH kr savings": 254066,
    "out:El kr savings": 14987,
    "out:El kr sold": 59775,
    "out:El kr saved": 39513,
    "out:El kr return": 99288,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 49878,
    "out:Total_Space Heating (DH)": 164727,
    "out:% savings (space heating)": 58.93,
    "out:Total_Water Heating (DH)": 92792,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 7,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 823382.604075,
    "out:Eupp": 81.38,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  }
]