import { Typography } from "@mui/material";
import { useFieldValue } from "packages/dataAccess";
import type { InstallationDataset } from "packages/installations";
import { Arrow } from "packages/ui";
import { Fragment, type ReactElement } from "react";
import { breakdownTableRows } from "./breakdownTableRows";

export interface InstallationBreakdownTableProps {
  rawDataset: InstallationDataset;
}

export const InstallationBreakdownTable = ({
  rawDataset,
}: InstallationBreakdownTableProps): ReactElement => {
  const getFieldValue = useFieldValue();

  return (
    <div
      style={{
        marginTop: "20px",
        color: "#000",
        textAlign: "center",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        marginLeft: "37px",
      }}
    >
      {breakdownTableRows.map(({ fieldName, label, formatter }, rowIndex) => (
        <Fragment key={fieldName}>
          {rowIndex !== 0 && (
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #F4F4F4",
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "50px",
              background:
                rowIndex === 0
                  ? "linear-gradient(270deg, #F4F5FB 0%, rgba(244, 245, 251, 0.00) 100%)"
                  : undefined,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Typography fontSize={14} fontWeight={500}>
                  {label}
                </Typography>
              </div>
            </div>
            {rawDataset
              .slice(1, rawDataset.length)
              .map(([, data], columnIndex, all) => (
                <div
                  key={data.ID}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <Typography
                      fontSize={12}
                      fontWeight={rowIndex === 0 ? 700 : undefined}
                    >
                      {formatter(getFieldValue(data, fieldName))}
                    </Typography>
                  </div>
                  {columnIndex < all.length - 1 && <Arrow />}
                </div>
              ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
